import { TLeadState } from "./lead";

export enum CallStatus {
  IDLE = "IDLE",
  CALL_CONNECTED = "CALL_CONNECTED",
  CALL_CREATED = "CALL_CREATED",
  CALL_RINGING = "CALL_RINGING",
  CALL_REJECTED = "CALL_REJECTED",
  CALL_DISCONNECTED = "CALL_DISCONNECTED",
}

export const allowedCallingStates: CallStatus[] = [
  CallStatus.CALL_REJECTED,
  CallStatus.IDLE,
  CallStatus.CALL_DISCONNECTED,
];

export type TCallState = {
  callId: number;
  processId: number;
  callCode: string;
  lead: TLeadState;
  callStatus: CallStatus;
  callOutCome: string;
  call_type: string;
};
