import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, TextField } from "@mui/material";
import DateCalendarIcon from "../../assets/images/DateCalendarIcon";
import { CustomInput } from "./CustomInput";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const CustomDatePicker = ({
  style = {},
  label,
  selectedDate,
  setSelectedDate,
  error = false,
  disabled,
  mobileView = false,
  minDate,
}: {
  style?: React.CSSProperties;
  label?: string;
  selectedDate: any;
  setSelectedDate: any;
  error?: boolean;
  disabled: boolean;
  mobileView?: boolean;
  minDate?: Date;
}) => {
  const [value, setValue] = React.useState<Dayjs | null | Date>(
    dayjs(new Date())
  );
  return (
    <Box sx={{ pr: "20px", ...style }}>
      {/* <DatePicker
        selected={selectedDate}
        onChange={(date: Date) => setSelectedDate(date)}
        disabled={disabled}
        customInput={
          <CustomInput
            label={label}
            value={selectedDate}
            onClick={setSelectedDate}
            Icon={DateCalendarIcon}
            padding={mobileView ? "40px" : "20px"}
            error={error}
          />
        }
        minDate={new Date()}
      /> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label={"Date"}
          value={value}
          inputFormat={"DD/MM/YYYY"}
          minDate={new Date()}
          onChange={(newValue) => {
            //setSelectedDate
            dayjs(newValue).isValid() == true
              ? setSelectedDate(newValue)
              : null;
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDatePicker;
