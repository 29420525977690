import { Box, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

const CustomTimePicker = ({
  style = { width: 200 },
  label,
  selectedTime,
  setSelectedTime,
  error = false,
  disabled,
  minTime,
}: {
  style?: React.CSSProperties;
  label?: string;
  selectedTime: any;
  setSelectedTime: any;
  error?: boolean;
  disabled: boolean;
  minTime?: Date | null;
}) => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(new Date()));
  return (
    <Box sx={style}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={"Time"}
          value={selectedTime}
          onChange={(e) => {
            dayjs(e).isValid() == true ? setSelectedTime(e) : null;
            setValue(e);
          }}
          renderInput={(params) => <TextField {...params} error={error} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomTimePicker;
