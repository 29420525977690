import { Table, TableBody, TableContainer } from "@mui/material";
import { PerformaceGaugeDTO } from "../../services/dto/GetPerformanceGaugeDTO";
import { formatTimeHHHMMSSS } from "../../SupportingFiles/HelpingFunction";
import DataNotFound from "../DataNotfound/DataNotFound";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import TableProgress from "../Table/TableProgress";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "25px" },
  { id: "agenetName", label: "Agent Name", allowSort: true, width: "120px" },
  { id: "ready", label: "Ready", allowSort: true, width: "60px" },
  { id: "manual", label: "Manual", allowSort: true, width: "60px" },
  {
    id: "preview",
    label: "Preview",
    allowSort: true,
    width: "60px",
  },
  { id: "directions", label: "Callback", allowSort: true, width: "60px" },
  { id: "phone", label: "Call", allowSort: true, width: "60px" },
  { id: "action", label: "Dispose", allowSort: true, width: "60px" },
  { id: "processId", label: "Stop", allowSort: true, width: "60px" },
  { id: "campaignId", label: "Pause", allowSort: true, width: "60px" },
  { id: "leadset", label: "Hold", allowSort: true, width: "60px" },
  { id: "talkTime", label: "Mute", allowSort: true, width: "60px" },
  { id: "date", label: "Login Time", allowSort: true, width: "60px" },
  { id: "totalLogin", label: "Total Login", allowSort: true, width: "60px" },
];

const AllLeadTable = ({
  data,
  startIndex,
  recordingLogLoader,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}: {
  data: PerformaceGaugeDTO[];
  startIndex: number;
  recordingLogLoader: boolean;
  order: "asc" | "desc";
  orderBy: string;
  setOrder: Function;
  setOrderBy: Function;
}) => {
  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor="primary.main">
            {headCells.map((cell, i) => (
              <StyledTableCell key={cell.id} minWidth={headCells[i].width}>
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {recordingLogLoader ? (
            <TableProgress colSpans={18} />
          ) : data.length === 0 ? (
            <DataNotFound colSpans={18} />
          ) : (
            <TableBody>
              {data.map((datas: PerformaceGaugeDTO, index: number) => (
                <StyledTableRow key={index} minHeight="46px">
                  <StyledTableCell width={headCells[0].width}>
                    {startIndex == 0 && index < 9
                      ? "0" + (index + 1)
                      : startIndex + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell
                    width={headCells[1].width}
                    sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                    {(datas?.user.firstName?.length === 0
                      ? "No Name"
                      : datas?.user.firstName + " ") +
                      datas?.user.lastName +
                      " (" +
                      datas?.user.username +
                      ")"}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[2].width}>
                    {formatTimeHHHMMSSS(datas?.duration.READY)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[3].width}>
                    {formatTimeHHHMMSSS(datas?.duration.MANUAL)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[4].width}>
                    {formatTimeHHHMMSSS(datas?.duration.PREVIEW)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[5].width}>
                    {formatTimeHHHMMSSS(datas?.duration.CALLBACK)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[6].width}>
                    {formatTimeHHHMMSSS(datas?.duration.CALL)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[7].width}>
                    {formatTimeHHHMMSSS(datas?.duration.DISPOSITION)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[8].width}>
                    {formatTimeHHHMMSSS(datas?.duration.STOP)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[9].width}>
                    {formatTimeHHHMMSSS(datas?.duration.PAUSE)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[10].width}>
                    {formatTimeHHHMMSSS(datas?.duration.HOLD)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[11].width}>
                    {formatTimeHHHMMSSS(datas?.duration.MUTE)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[12].width}>
                    {formatTimeHHHMMSSS(datas?.duration.LOGINTIME)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[13].width}>
                    {formatTimeHHHMMSSS(datas?.duration.TOTALLOGIN)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default AllLeadTable;
