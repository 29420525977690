import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Loader = ({ title }: { title: String }) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <CircularProgress
        sx={{ mr: 1.4, mb: { xs: 0.8, md: 0.5 } }}
        size="1.8rem"
      />
      <Typography
        sx={{
          fontSize: { xs: "14px", md: "32px" },
          fontWeight: 700,
          lineHeight: "14px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Loader;
