const LeadsetDeleteIcon = ({ color = "#EB5757" }: { color?: string }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.4375C7.0146 0.437428 7.51064 0.629729 7.89072 0.976645C8.2708 1.32356 8.50747 1.80003 8.55425 2.3125H11.75C11.8925 2.31254 12.0297 2.36668 12.1338 2.46398C12.238 2.56128 12.3013 2.69447 12.311 2.83666C12.3207 2.97885 12.2761 3.11942 12.1862 3.22997C12.0962 3.34053 11.9677 3.41282 11.8265 3.43225L11.75 3.4375H11.3127L10.3625 11.6725C10.3202 12.038 10.145 12.3753 9.87019 12.6201C9.59541 12.8648 9.24024 13 8.87225 13H4.12775C3.75976 13 3.40459 12.8648 3.12981 12.6201C2.85503 12.3753 2.67982 12.038 2.6375 11.6725L1.6865 3.4375H1.25C1.11407 3.43749 0.982743 3.38827 0.880302 3.29892C0.777861 3.20958 0.711236 3.08617 0.69275 2.9515L0.6875 2.875C0.687506 2.73907 0.736732 2.60774 0.826075 2.5053C0.915419 2.40286 1.03883 2.33624 1.1735 2.31775L1.25 2.3125H4.44575C4.49253 1.80003 4.7292 1.32356 5.10928 0.976645C5.48936 0.629729 5.9854 0.437428 6.5 0.4375ZM5.375 5.125C5.19125 5.125 5.0375 5.24125 5.006 5.39425L5 5.4535V10.0472L5.006 10.1058C5.0375 10.2587 5.19125 10.375 5.375 10.375C5.55875 10.375 5.7125 10.2587 5.744 10.1058L5.75 10.0465V5.45425L5.744 5.39425C5.7125 5.242 5.55875 5.125 5.375 5.125ZM7.625 5.125C7.44125 5.125 7.2875 5.24125 7.256 5.39425L7.25 5.4535V10.0472L7.256 10.1058C7.2875 10.2587 7.44125 10.375 7.625 10.375C7.80875 10.375 7.9625 10.2587 7.994 10.1058L8 10.0465V5.45425L7.994 5.39425C7.9625 5.242 7.80875 5.12575 7.625 5.12575V5.125ZM6.5 1.5625C6.04625 1.5625 5.6675 1.885 5.58125 2.3125H7.41875C7.33175 1.885 6.95375 1.5625 6.5 1.5625Z"
        fill={color}
      />
    </svg>
  );
};

export default LeadsetDeleteIcon;
