import { useEffect, useState } from "react";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../../shared/constants";

export const formatTime = (startTime: Date, endTime: Date) => {
  const dt = startTime.getTime() - endTime.getTime();
  return {
    hours: `00${Math.floor((dt / 1000 / 1000 / 60) % 60)}`.slice(-2),
    minutes: `00${Math.floor((dt / 1000 / 60) % 60)}`.slice(-2),
    seconds: `00${Math.floor((dt / 1000) % 60)}`.slice(-2),
    milliSeconds: `00${Math.round(dt / 10)}`.slice(-2),
  };
};

const useTimer = () => {
  const [timer, setTimer] = useState({
    startTime: new Date(),
    stopTime: new Date(),
    hours: "00",
    minutes: "00",
    seconds: "00",
    milliSeconds: "00",
  });
  const setStartTime = (time: Date) => {
    setTimer((prev) => ({ ...prev, startTime: time }));
  };

  const setStopTimer = (time: Date) => {
    setTimer((prev) => ({
      ...prev,
      stopTime: time,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const localTime = localStorage.getItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.TIMER_STATE
      );
      const existingTimeString = localTime ? JSON.parse(localTime) : null;
      const existingTime: Date =
        existingTimeString !== null
          ? new Date(existingTimeString.startTime)
          : timer.startTime;
      const { hours, minutes, seconds, milliSeconds } = formatTime(
        new Date(),
        existingTime
      );
      setTimer((prev) => ({
        ...prev,
        hours,
        minutes,
        seconds,
        milliSeconds,
      }));
    }, 10);
    return () => clearInterval(interval);
  }, [timer.startTime]);

  return { timer, setStartTime, setStopTimer };
};

export const resetTimer = () => {
  const newDate = new Date();
  const localTime = localStorage.getItem(
    LOCALSOTRAGE_KEYS_CONSTANTS.TIMER_STATE
  );
  const existingTime =
    localTime === null ? newDate : JSON.parse(localTime).startTime;
  const timerState = {
    startTime: newDate,
    stopTime: new Date(existingTime),
  };
  localStorage.setItem(
    LOCALSOTRAGE_KEYS_CONSTANTS.TIMER_STATE,
    JSON.stringify(timerState)
  );
};

export default useTimer;
