import { useAppSelector } from "../../state/hooks";
import { UserStateStatus } from "../../state/types/user";
import CallbackInitiatingLoader from "./CallbackInitiatingLoader";
import Loader from "./Loader";

const ReadyScreenTitle = () => {
  const userStatus = useAppSelector((state) => state.user.status);
  return (
    <>
      {userStatus === UserStateStatus.READY_CALLBACK ? (
        <CallbackInitiatingLoader />
      ) : userStatus === UserStateStatus.READY_AUTOCALLING ? (
        <Loader title="Waiting for new lead to connect..." />
      ) : userStatus === UserStateStatus.PREVIEW ? (
        <Loader title="Waiting for new lead..." />
      ) : (
        <></>
      )}
    </>
  );
};

export default ReadyScreenTitle;
