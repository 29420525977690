import { PayloadAction } from "@reduxjs/toolkit";
import { TClientConfig } from "../types/TClientConfig";

function setClientConfigData(
  state: TClientConfig,
  action: PayloadAction<TClientConfig>
) {
  return action.payload;
}

export const CLIENT_CONFIG_REDUCERS = {
  setClientConfigData,
};

export type TClientConfigReducers = typeof CLIENT_CONFIG_REDUCERS;
