import { Box } from "@mui/material";
import React from "react";

const VerticalTableTile = ({
  children,
  sx,
  onClick,
}: {
  children?: React.ReactNode;
  sx?: React.CSSProperties;
  onClick?: any;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: "#F4F6FE",
        borderRadius: "8px",
        px: "16px",
        pt: "16px",
        "&:nth-of-type(even)": {
          backgroundColor: "white",
          boxShadow: "0 0 4px rgba(0,0,0,0.15)",
        },
        ...sx,
      }}
      my="16px"
    >
      {children}
    </Box>
  );
};

export default VerticalTableTile;
