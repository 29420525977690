import { UA, WebSocketInterface } from "jssip";
import { RTCSession } from "jssip/lib/RTCSession";
import { UAConfiguration } from "jssip/lib/UA";
import { UserAccessLevel } from "../state/types/user";
export class CallProvider {
  private socket: WebSocketInterface;
  private configuration: UAConfiguration;
  public ua: UA;
  private session: RTCSession | null;
  private remoteAudio: any;

  constructor(
    JSSIP_WS: string,
    JSSIP_URI: string,
    webrtc_password: string,
    webrtc_username: string,
    role: UserAccessLevel
  ) {
    this.socket = new WebSocketInterface(JSSIP_WS);
    const password = webrtc_password;
    const JSSIP_URL = `sip:${webrtc_username}@${JSSIP_URI}`;
    this.configuration = {
      sockets: [this.socket],
      uri: JSSIP_URL,
      password,
      connection_recovery_max_interval: 1,
    };
    this.ua = new UA(this.configuration);
    this.session = null;
    if (role === "AGENT") {
      this.ua.on("connecting", () => {
        console.log("connecting");
      });
      this.ua.on("disconnected", () => {
        console.log("disconnected");
      });
      this.ua.on("registered", () => {
        console.log("registered");
      });
      this.ua.on("unregistered", () => {
        console.log("unregistered");
      });
      this.ua.on("registrationFailed", () => {
        console.log("registrationFailed");
      });
      this.ua.on("registrationExpiring", () => {
        console.log("registrationExpiring");
      });
      // ua.on("newMessage", () => {
      //   console.log("newMessage");
      // });
      // ua.on("sipEvent", () => {
      //   console.log("sipEvent");
      // });
    }
  }

  connect(onConnect: () => void = () => {}, role: UserAccessLevel) {
    if (role === "AGENT") {
      this.ua.on("connected", () => {
        console.log("Connected");
        onConnect && onConnect();
      });

      this.ua.start();
    }
  }

  isConnected() {
    return this.ua.isConnected();
  }

  isRegistered() {
    return this.ua.isRegistered();
  }

  joinSelfBridge(bridgeName: string, OTHER_SSP: string) {
    this.ua.on("newRTCSession", () => {
      console.log("newRTCSession");
    });
    this.session = this.ua.call(`3133${OTHER_SSP}-${bridgeName}`, {
      mediaConstraints: { audio: true, video: false },
    });
    this.session.connection.addEventListener("track", (e) => {
      this.remoteAudio = document.getElementById("audio");
      this.remoteAudio.srcObject = e.streams[0];
      this.remoteAudio.play();
    });
    this.session.on("ended", () => {
      this.remoteAudio = document.getElementById("audio");
      this.remoteAudio.srcObject = null;
    });
  }

  hold() {
    console.log("Going to hold");
    this.session?.hold();
  }

  isOnHold() {
    return this.session?.isOnHold().local;
  }

  unHold() {
    console.log("Removing from hold");
    this.session?.unhold();
  }

  isMuted() {
    return this.session?.isMuted().audio;
  }

  mute() {
    console.log("Muting the call");
    this.session?.mute();
  }

  unMute() {
    console.log("Unmuting the call");
    this.session?.unmute();
  }

  sendDTMF(input: number) {
    this.session?.sendDTMF(input);
  }

  terminate() {
    this.session?.terminate();
  }

  disconnect() {
    if (this.session?.isInProgress()) {
      this.session.terminate();
    }
    this.ua.stop();
  }
}
