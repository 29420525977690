import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const DashboardTile = ({
  icon,
  title,
  value,
  sx,
  loader = true,
}: {
  icon: React.ReactNode;
  title: string;
  value: string | number;
  sx?: any;
  loader: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#F1EFFB",
        borderRadius: "20px",
        minHeight: "220px",
        width: isMobile ? "100%" : "220px",
        marginRight: isMobile ? "0px" : "16px",
        marginBottom: "16px",
        p: "20px",
        ...sx,
      }}>
      <Box
        display="flex"
        sx={{
          height: 50,
          width: 50,
          borderRadius: 100,
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}>
        {icon}
      </Box>
      <Box height={"30px"} width="30px" marginBottom="20px">
        {loader ? (
          <Skeleton
            variant="rounded"
            width={"150px"}
            height={"30px"}
            style={{ marginTop: "25px" }}
          />
        ) : (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}>
            {value}
          </Typography>
        )}
      </Box>
      <Typography>{title}</Typography>
    </Box>
  );
};

export default DashboardTile;
