import CloseIcon from "@mui/icons-material/Close";
import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { DarkTooltip } from "../components/DarkToolTip";
import { SearchBar } from "../components/SearchBar";
import { useAppSelector } from "../state/hooks";
import { UserAccessLevel } from "../state/types/user";

function ShortcutDesc({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) {
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<
    Array<{
      sction_name: string;
      data: Array<{ title: string; shortcut: string }>;
    }>
  >(
    userDetails.accessLevel === UserAccessLevel.AGENT
      ? [
          {
            sction_name: "Ready Screen",
            data: [
              { shortcut: "M", title: "Manual Dial" },
              { shortcut: "B", title: "Set Break" },
              { shortcut: "S", title: "Stop Screen" },
            ],
          },
          {
            sction_name: "Call Screen",
            data: [
              { shortcut: "D", title: "Open DTMF" },
              { shortcut: "H", title: "Call Hold" },
              { shortcut: "M", title: "Mute Call" },
              { shortcut: "Shift + H", title: "Hang-up/Disconnect Call" },
            ],
          },
          {
            sction_name: "Perview Screen",
            data: [
              { shortcut: "C", title: "Call Now" },
              { shortcut: "Shift + S", title: "Skip Lead" },
              { shortcut: "M", title: "Manual Dial" },
              { shortcut: "B", title: "Break" },
              { shortcut: "S", title: "Stop" },
            ],
          },
          {
            sction_name: "Callback Screen",
            data: [{ shortcut: "C", title: "Call Now" }],
          },
        ]
      : [
          {
            sction_name: "Admin Dashboard",
            data: [{ shortcut: "Ctrl+Shift+H", title: "Admin Dashboard" }],
          },
          {
            sction_name: "CDR",
            data: [
              { shortcut: "Shift+A", title: "Agent Logger" },
              { shortcut: "Shift+R", title: "Recording CDR" },
            ],
          },
          {
            sction_name: "Reports",
            data: [
              { shortcut: "Shift+C", title: "Callback Logger" },
              { shortcut: "Shift+P", title: "Performance Gauge" },
              { shortcut: "Shift+B", title: "Break Logs" },
            ],
          },
          {
            sction_name: "Operations",
            data: [
              { shortcut: "Shift+U", title: "User Details" },
              { shortcut: "Ctrl+Shift+C", title: "Manage Campaigns" },
              { shortcut: "Shift+L", title: "Manage Leadset" },
              { shortcut: "Shift+D", title: "Manage Dispose" },
            ],
          },
        ]
  );

  const [filteredData, setFilteredData] = useState<
    Array<{
      sction_name: string;
      data: Array<{ title: string; shortcut: string }>;
    }>
  >(data);

  const handleSearch = (text: string) => {
    setSearch(text);
    const filteredData = data.map((section) => ({
      ...section,
      data: section.data.filter(
        (item) =>
          item.title.toLowerCase().includes(text.toLowerCase()) ||
          item.shortcut.toLowerCase().includes(text.toLowerCase())
      ),
    }));
    setFilteredData(filteredData);
  };

  return (
    <Modal open={visible} sx={{ backgroundColor: "#FFFFFF", zIndex: 2001 }}>
      <Box
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#FFFFFF",
          padding: "60px",
        }}>
        <Grid container spacing={3} sx={{ overflow: "auto" }}>
          <Grid xs={4}>
            <h3 style={{ marginBottom: "8px" }}>Keyboard Shortcuts</h3>
            {/* <Link href="">Learn more</Link> */}
          </Grid>
          <Grid xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <SearchBar
              sx={{
                background: "white",
                border: "1px solid #96A1B3",
                width: "300px",
              }}
              text={search}
              onChange={handleSearch}
            />
          </Grid>
          <Grid xs={4} sx={{ display: "flex", justifyContent: "end" }}>
            <DarkTooltip title="Close" sx={{ zIndex: 2001 }} placement="top">
              <Link
                sx={{
                  color: "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setVisible(false);
                }}>
                <CloseIcon />
              </Link>
            </DarkTooltip>
          </Grid>
          {filteredData.map((data, index) => (
            <>
              {data.data.length > 0 ? (
                <Grid xs={4} sx={{ margin: "24px 0px" }}>
                  <h4>{data.sction_name}</h4>
                  <ul
                    style={{
                      width: "80%",
                      fontSize: "14px",
                      paddingLeft: "0px",
                    }}>
                    {data.data.map((data, index) => (
                      <li
                        style={{
                          listStyleType: "none",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "12px",
                        }}>
                        <p>{data.title}</p>
                        <span
                          style={{
                            background: "#eee",
                            padding: "10px 16px",
                            height: "36px",
                            display: "inline-block",
                            borderRadius: "4px",
                          }}>
                          {data.shortcut}
                        </span>
                      </li>
                    ))}
                  </ul>
                </Grid>
              ) : null}
            </>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
}

export default ShortcutDesc;
