import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import PauseStatusIcon from "../../assets/images/status-header/PauseStatusIcon";
import ReadyStatusIcon from "../../assets/images/status-header/ReadyStatusIcon";
import StopStatusIcon from "../../assets/images/status-header/StopStatusIcon";
import { useAppSelector } from "../../state/hooks";
import { UserStateStatus } from "../../state/types/user";
import Timer from "../Stopwatch/Timer";

export const Stopwatch = () => {
  const userState = useAppSelector((state) => state.user.status);
  const breakReason = useAppSelector((state) => state.user.breakReason);
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const statusBarWidth = mobileView ? 197 : 400;
  const showHeader =
    userState === UserStateStatus.PAUSE ||
    userState === UserStateStatus.STOP ||
    userState.includes("READY");
  const backgroundColor = userState.includes("READY")
    ? "#93CE15"
    : "" || userState == UserStateStatus.PAUSE
    ? "#FF7A00"
    : "" || userState == UserStateStatus.STOP
    ? "#E5404F"
    : "";
  return !showHeader ? (
    <></>
  ) : (
    <Box
      sx={{
        zIndex: 2000,
        p:
          userState === UserStateStatus.PAUSE
            ? { xs: "10px 0 2px 20px", md: "10px 0 0 0" }
            : { xs: "10px 0 10px 20px", md: "10px 0 10px 0" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        justifyContent: "center",
        width: statusBarWidth,
        top: 0,
        left: mobileView
          ? `calc(50% - ${statusBarWidth / 2}px)`
          : `calc(50% - ${statusBarWidth / 2}px)`,
        backgroundColor: backgroundColor,
        borderRadius: "0 0 20px 20px",
        ":hover": {
          backgroundColor: backgroundColor,
        },
      }}
    >
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          mb={{ xs: "4px", md: "8px" }}
          height={{ xs: "25px", md: "33px" }}
          width={{ xs: "25px", md: "33px" }}
          pr="10px"
        >
          {userState === UserStateStatus.STOP ? (
            <StopStatusIcon />
          ) : userState.includes("READY") ? (
            <ReadyStatusIcon />
          ) : (
            <PauseStatusIcon />
          )}
        </Box>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "30px" },
            fontWeight: 700,
            color: "white",
            pr: { xs: "8px", md: "20px" },
          }}
        >
          {userState.includes("READY") ? "READY" : userState}
        </Typography>
        <Box>
          <Timer />
        </Box>
      </Box>
      {userState === UserStateStatus.PAUSE && (
        <Typography
          mt={{ xs: "-5px", md: "-10px" }}
          pr="10px"
          sx={{
            color: "white",
            fontWeight: { xs: 700, md: 500 },
            fontSize: { xs: "12px", md: "20px" },
          }}
        >
          {breakReason} Break
        </Typography>
      )}
    </Box>
  );
};
