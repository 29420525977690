import { PayloadAction } from "@reduxjs/toolkit";
import { TDisposeCallState } from "../types/disposeCall";

function setDiposeCall(
  state: TDisposeCallState,
  action: PayloadAction<TDisposeCallState>
) {
  return action.payload;
}

function updateDisposeCall(
  state: TDisposeCallState,
  action: PayloadAction<{ [key: string]: string | number }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

export const DISPOSE_CALL_REDUCERS = {
  setDiposeCall,
  updateDisposeCall,
};

export type TDisposeCallReducer = typeof DISPOSE_CALL_REDUCERS;
