import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import { useState } from "react";
import { toDesiredFormat } from "../../SupportingFiles/HelpingFunction";
import { CALLBACK_SERVICE } from "../../services/CallbackService";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { callbackSlice } from "../../state/callback/callbackSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import CustomAutocomplete from "../Custom/CustomAutocomplete";
import { SelectOption } from "../Custom/CustomSelect";
import DatePicker from "../EVCustoms/DatePicker/DatePicker";
import TimePicker from "../EVCustoms/TimePicker/TimePicker";
import EditCallbackDetailsActions from "./EditCallbackDetailsActions";

const EditCalbackDetailsContent = (
  setOpen: Function,
  agents: GetUserResponseDTO[],
  onAction: Function
) => {
  const [loading, setLoading] = useState(false);
  const callback = useAppSelector((state) => state.callback);
  const dispatch = useAppDispatch();

  const handleInputChange = (value: SelectOption) => {
    if (!value) return;
    dispatch(
      callbackSlice.actions.updateCallback({
        key: "agentId",
        value: value?.value,
      })
    );
  };

  function updateCallback() {
    setLoading(true);
    const onSucces = () => {
      setLoading(false);
      setOpen(false);
      onAction();
    };
    const onError = (err: any) => {
      setLoading(false);
      console.log(err);
    };
    CALLBACK_SERVICE.updateCallback(
      onSucces,
      onError,
      {
        dateTime: callback.dateTime.includes("Z")
          ? callback.dateTime
          : callback.dateTime + "Z",
        agentId: callback.agentId,
      },
      callback.id
    );
  }

  function handleSubmit() {
    updateCallback();
  }

  const updateMasterState = (attrName: any, value: any) => {
    if (dayjs(value).isValid() && value !== null) {
      dispatch(
        callbackSlice.actions.updateCallback({
          key: "dateTime",
          value: value,
        })
      );
    }
  };

  return (
    <>
      <Box display="flex" gap={5}>
        <Typography>
          Agent :{" "}
          {callback.Agent.firstName
            ? callback.Agent.firstName
            : callback.Agent.username}
        </Typography>
        <Typography>Lead : {callback.Lead.id}</Typography>
        <Typography>Phone : {callback.Lead.phone}</Typography>
      </Box>
      <Grid container mt="35px" mb="20px" spacing={3}>
        <Grid xs={4}>
          <DatePicker
            title="Callback Date"
            value={callback.dateTime ? new Date(callback.dateTime) : null}
            attrName=""
            value_update={updateMasterState}
          />
        </Grid>
        <Grid xs={4}>
          <TimePicker
            title={"Callback Time"}
            value={callback.dateTime ? new Date(callback.dateTime) : null}
            attrName=""
            value_update={updateMasterState}
          />
        </Grid>
        <Grid xs={4}>
          <CustomAutocomplete
            label="Re-assign Agent"
            onChange={(ev, value) => handleInputChange(value)}
            limitOptions={3}
            getOptionLabel={(option: any) => option?.label}
            selectedValue={callback.agentId}
            data={
              agents.length === 0
                ? []
                : agents.map((agent) => ({
                    label: agent.firstName ? agent.firstName : agent?.username,
                    value: agent?.id,
                  }))
            }
            sx={{ innerHeight: 56 }}
          />
        </Grid>
      </Grid>
      <EditCallbackDetailsActions
        setOpen={setOpen}
        onClick={handleSubmit}
        isLoading={loading}
      />
    </>
  );
};

export default EditCalbackDetailsContent;
