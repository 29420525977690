function CallDisposedIcon() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.2718 1.53058C28.2915 0.550553 26.9621 0 25.5759 0C24.1898 0 22.8604 0.550553 21.8801 1.53058L12.0843 11.3263C11.9578 11.4525 11.8623 11.6063 11.8054 11.7758L8.92435 20.419C8.85648 20.622 8.84648 20.8399 8.89548 21.0483C8.94447 21.2567 9.05054 21.4473 9.20177 21.5987C9.35301 21.7502 9.54344 21.8566 9.75173 21.9059C9.96002 21.9552 10.1779 21.9456 10.381 21.878L19.0243 18.9969C19.1946 18.9404 19.3493 18.8449 19.4761 18.718L29.2718 8.92232C30.2518 7.94202 30.8024 6.61261 30.8024 5.22645C30.8024 3.84029 30.2518 2.51088 29.2718 1.53058V1.53058ZM26.121 15.3322C26.293 17.6771 25.7435 20.0185 24.5465 22.0421C23.3495 24.0658 21.5621 25.6749 19.4243 26.6535C17.2864 27.6322 14.9004 27.9335 12.5864 27.5171C10.2724 27.1006 8.14108 25.9864 6.47854 24.3238C4.81599 22.6613 3.70175 20.53 3.28532 18.216C2.86889 15.902 3.17021 13.516 4.14884 11.3781C5.12748 9.24026 6.73659 7.4529 8.76026 6.25587C10.7839 5.05884 13.1253 4.50942 15.4702 4.68135L17.5054 2.64384C14.5882 2.02359 11.5487 2.36303 8.84015 3.61153C6.13164 4.86002 3.89932 6.95067 2.47616 9.57162C1.053 12.1926 0.515271 15.2034 0.943181 18.1549C1.37109 21.1065 2.74171 23.8406 4.85059 25.9495C6.95947 28.0584 9.69361 29.429 12.6452 29.8569C15.5967 30.2848 18.6075 29.7471 21.2285 28.3239C23.8494 26.9008 25.9401 24.6684 27.1886 21.9599C28.4371 19.2514 28.7765 16.2119 28.1562 13.2947L26.121 15.3322V15.3322Z"
        fill="#6F5FD9"
      />
    </svg>
  );
}

export default CallDisposedIcon;
