import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import FormActionButton from "../Actions/FormActionButton";
import { CustomPopup } from "../Custom/CustomPopup";

const SimpleConfiramtionPopUpHeader = ({
  title,
  fontSize = 24,
}: {
  title: string;
  fontSize?: number;
}) => {
  return (
    <Typography p="10px 0" textAlign={"center"} sx={{ fontSize: fontSize }}>
      {title}
    </Typography>
  );
};

const SimpleConfirmationPoUpContent = ({
  value,
  setOpen,
  action,
  onAction,
  loading,
  addition_title,
}: {
  value: string;
  setOpen: Function;
  action: string;
  onAction?: any;
  loading: boolean;
  addition_title?: string;
}) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Box
      display={"flex"}
      p="30px 0"
      justifyContent={"center"}
      alignItems="center"
      gap={2}>
      <FormActionButton
        title={action + " " + value}
        onClick={onAction}
        variant="contained"
        loading={loading}
      />
      <FormActionButton
        title="Cancel"
        onClick={() => setOpen(false)}
        disabled={loading}
      />
    </Box>
  );
};

const SimpleConfirmationPopUp = ({
  title = "Are you sure ?",
  value,
  open,
  setOpen,
  action = "Enable",
  onAction,
  loading = false,
  addition_title = "",
  fontSize,
}: {
  title?: string;
  value: any;
  open: boolean;
  setOpen: Function;
  action?: string;
  onAction?: any;
  loading?: boolean;
  addition_title?: string;
  fontSize?: number;
}) => {
  return (
    <CustomPopup
      open={open}
      setOpen={setOpen}
      minWidth="685px"
      Header={() =>
        SimpleConfiramtionPopUpHeader({
          title: title + addition_title,
          fontSize: fontSize,
        })
      }
      Component={() =>
        SimpleConfirmationPoUpContent({
          value: value,
          setOpen: setOpen,
          action: action,
          onAction: onAction,
          loading: loading,
          addition_title: addition_title,
        })
      }
    />
  );
};

export default SimpleConfirmationPopUp;
