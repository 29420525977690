import DialpadIcon from "@mui/icons-material/Dialpad";
import StopIcon from "@mui/icons-material/Stop";
import { Box, Typography } from "@mui/material";
import { Canceler } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreakFloatingIcon from "../assets/images/BreakFloatingIcon";
import { BreakStatusPopup } from "../components/BreakStatus/BreakStatusPopup";
import CallbacksTable from "../components/Callbacks/CallbacksTable";
import FloatingButton from "../components/Custom/FloatingButton";
import DTMFPopup from "../components/DTMF/DTMFPopup";
import ReadyScreenTitle from "../components/ReadyScreen/ReadyScreenTitle";
import { Stopwatch } from "../components/Stopwatch/Stopwatch";
import { AGENT_DASHBOARD_SERVICE } from "../services/AgentDashboardService";
import { CALLBACK_SERVICE } from "../services/CallbackService";
import { PREVIEW_SERVICE } from "../services/PreviewService";
import { GetCallbackDTO } from "../services/dto/GetCallbackDTO";
import {
  AUTO_CALL,
  LOCALSOTRAGE_KEYS_CONSTANTS,
  SOCKET_EVENTS,
} from "../shared/constants";
import { useCallProvider } from "../shared/hooks/useCallProvider";
import { useClientSocket } from "../shared/hooks/useClientSocket";
import { useSocket } from "../shared/hooks/useSocket";
import { callSlice } from "../state/call/callSlice";
import { callbackLogsSlice } from "../state/callbackLogs/callbackLogsSlice";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { leadSlice } from "../state/lead/leadSlice";
import { defaultProcessSlice } from "../state/process/defaultProcessSlice";
import { CallStatus, allowedCallingStates } from "../state/types/call";
import { CallingModeAuto } from "../state/types/defaultProcess";
import { TLeadState } from "../state/types/lead";
import { UserStateStatus } from "../state/types/user";
import { userSlice } from "../state/user/userSlice";

const ReadyScreen = () => {
  const [callbackCallStatus, setCallbackCallStatus] = useState<boolean>(false);
  const location = useLocation();
  const { missedCallbackLogs, upcomingCallbackLogs } = useAppSelector(
    (state) => state.callbackLogs
  );
  const { shortcutKeys } = useAppSelector((state) => state.user);
  const webRTCConnStatus = useAppSelector(
    (state) => state.user.webRTCConnStatus
  );
  const { manualCallStatus } = useAppSelector((state) => state.user);
  const [openDTMF, setOpenDTMF] = useState(false);
  const userStatus = useAppSelector((state) => state.user.status);
  const callStatus = useAppSelector((state) => state.call.callStatus);
  const canCall = !allowedCallingStates.includes(callStatus);
  const defaultProcess = useAppSelector((state) => state.defaultProcess);
  const [openBreakReasons, setOpenBreakReasons] = useState(false);
  const dispatch = useAppDispatch();
  const [callingCallback, setCallingCallback] = useState(false);
  const { clientSocket } = useClientSocket();
  const { socket } = useSocket();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(
    undefined
  );
  const [cancelToken, setCancelToken] = useState<Canceler | null>(null);
  const callProvider = useCallProvider();

  const timeoutCallback = useCallback(() => {
    return setTimeout(() => {
      clientSocket?.emit(SOCKET_EVENTS.PRE_READY, "", (test: any) => {
        console.log("Client backend ackno pre ready", test);
      });
      getMissedCallbacks();
      getUpcomingCallbacks();
    }, 30000);
  }, [clientSocket?.id]);

  useEffect(() => {
    if (shortcutKeys === true) {
      window.addEventListener("keyup", handleKeyPress);
    } else {
      window.removeEventListener("keyup", handleKeyPress);
    }
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [shortcutKeys]);
  
  const handleKeyPress = useCallback((e: any) => {
    const event = e;
    // console.log(event.keyCode);
    if (
      event.target.tagName !== "INPUT" &&
      event.target.tagName !== "TEXTAREA"
    ) {
      if (event.shiftKey) {
        switch (event.keyCode) {
          case 83:
            // skipPreviewLead();
            break;
          default:
            break;
        }
      } else {
        switch (event.keyCode) {
          case 77:
            if (manualCallStatus === true) {
              handleDTMFPopup();
            }
            break;
          case 66:
            setOpenBreakReasons(!openBreakReasons);
            break;
          case 83:
            dispatch(userSlice.actions.goToStop());
            break;
          default:
            break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (webRTCConnStatus === false) {
      dispatch(userSlice.actions.goToStop());
    }
  }, [webRTCConnStatus]);

  useEffect(() => {
    const updateDevices = () => {
      navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
        const microphones = deviceInfos.filter(
          (device) => device.kind === "audioinput"
        );

        if (microphones.length === 0) {
          dispatch(userSlice.actions.goToStop());
        } else {
          if (!callProvider?.isConnected()) {
            dispatch(userSlice.actions.goToStop());
          }
        }
      });
    };

    updateDevices();
    getUserDetails();

    navigator.mediaDevices.ondevicechange = updateDevices;
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);

  function getUserDetails() {
    const onSuccess = (data: any) => {
      const error_status = data.errorStatus;
      if (error_status === false) {
        const response = data.data;
        const default_process = response.callingMode;

        dispatch(
          defaultProcessSlice.actions.updateDefaultProcess({
            key: "callingMode",
            value: default_process,
          })
        );
        let old_default_process: object = {};

        const rawValue = localStorage.getItem(
          LOCALSOTRAGE_KEYS_CONSTANTS.DEFAULT_PROCESS
        );

        if (rawValue) {
          try {
            old_default_process = JSON.parse(rawValue);
          } catch (error) {
            console.error(
              "Error parsing old_default_process from local storage:",
              error
            );
          }
        }

        old_default_process = {
          ...(old_default_process as object),
          callingMode: default_process,
        };

        localStorage.setItem(
          LOCALSOTRAGE_KEYS_CONSTANTS.DEFAULT_PROCESS,
          JSON.stringify(old_default_process)
        );
      }
    };
    const onError = (err: any) => console.log(err);
    AGENT_DASHBOARD_SERVICE.getUserDetails(onSuccess, onError);
  }

  // Use the token in your API call
  useEffect(() => {
    const cancelExecution = () => {
      if (cancelToken) {
        cancelToken();
      }
    };

    return cancelExecution;
  }, [cancelToken]);

  useEffect(() => {
    return () => {
      setCancelToken(null);
    };
  }, []);

  const handleStopStatus = () => {
    dispatch(userSlice.actions.goToStop());
  };

  useEffect(() => {
    console.log("Status", userStatus);
  }, [userStatus]);

  const callConnectedListener = useCallback(
    (data: any) => {
      dispatch(leadSlice.actions.setLead(data.lead));
      // if (userStatus === UserStateStatus.READY_AUTOCALLING) {
      console.log("Status", userStatus);
      dispatch(callSlice.actions.updateLeadId(data.lead.id));
      dispatch(callSlice.actions.updateCallCode(data.callCode));
      // }
      // dispatch(callSlice.actions.setCall(data));
      dispatch(callSlice.actions.updateCallStatus(CallStatus.CALL_CONNECTED));
      dispatch(callSlice.actions.updateProcessId(data.processId));

      if (data?.lead?.id !== undefined) {
        dispatch(userSlice.actions.goToCall());
      }
      clientSocket?.on(SOCKET_EVENTS.CALL_DISCONNECTED, () => {
        dispatch(
          callSlice.actions.updateCallStatus(CallStatus.CALL_DISCONNECTED)
        );
        dispatch(
          userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION)
        );
      });
    },
    [clientSocket?.id]
  );

  const preReadyNextListener = useCallback(
    (data: any) => {
      if (data == UserStateStatus.READY_AUTOCALLING) {
        clientSocket?.emit(SOCKET_EVENTS.READY_AUTOCALLING);
        console.log(data);
        dispatch(
          userSlice.actions.updateUserStatus(UserStateStatus.READY_AUTOCALLING)
        );
      } else if (data == "READY_NEXT") {
        decideNextState();
      }
      return "Disconnected";
    },
    [clientSocket?.id]
  );

  const clearPreReady = () => {
    clientSocket?.emit(SOCKET_EVENTS.EXIT_AUTOCALLING);
    console.log({ emitsToClient: SOCKET_EVENTS.EXIT_AUTOCALLING }, "Umesh");
    clientSocket?.off(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
    clientSocket?.off(SOCKET_EVENTS.PRE_READY_NEXT, preReadyNextListener);
    clearTimeout(timeoutId);
  };

  const setPreReady = () => {
    dispatch(userSlice.actions.goToReady());
    clientSocket?.emit(SOCKET_EVENTS.PRE_READY);
    console.log({ emitsToClient: SOCKET_EVENTS.PRE_READY });
    clientSocket?.on(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
    clientSocket?.on(SOCKET_EVENTS.PRE_READY_NEXT, preReadyNextListener);
  };

  const handleDTMFPopup = () => {
    setOpenDTMF(!openDTMF);
    if (!openDTMF) {
      dispatch(userSlice.actions.updateUserStatus(UserStateStatus.MANUAL));
      clearPreReady();
    } else {
      setPreReady();
    }
  };
  useEffect(() => {
    console.log("UserStateStatus", userStatus);
  }, [userStatus]);

  useEffect(() => {
    if (userStatus === UserStateStatus.MANUAL) setOpenDTMF(true);
  }, [userStatus]);

  function getMissedCallbacks() {
    const onSuccess = ({
      missedCallbacks,
    }: {
      missedCallbacks: GetCallbackDTO[];
    }) => {
      dispatch(
        callbackLogsSlice.actions.updateMissedCallbacks(missedCallbacks)
      );
    };
    const onError = (err: any) => {
      console.log({ err });
    };
    CALLBACK_SERVICE.getMissedCallbacks(onSuccess, onError);
  }

  function getUpcomingCallbacks() {
    const onSuccess = ({
      upcomingCallbacks,
    }: {
      upcomingCallbacks: GetCallbackDTO[];
    }) => {
      dispatch(
        callbackLogsSlice.actions.updateUpcomingCallbacks(upcomingCallbacks)
      );
    };
    const onError = (err: any) => {
      console.log({ err });
    };
    CALLBACK_SERVICE.getUpcomingCallbacks(onSuccess, onError);
  }

  function getPreviewLead(onSuccess: Function) {
    const onError = (err: any) => console.log(err);
    return PREVIEW_SERVICE.getNextLead(onSuccess, onError);
  }

  const callNextCallback = () => {
    const onSuccess = (response: any) => {
      dispatch(callSlice.actions.updateCallType(""));

      if (
        response?.callback !== null &&
        AUTO_CALL.AUTO_CALLING === true
        // &&
        // AUTO_CALL.ORIGIN !== "https://unison.hellovent.com"
      ) {
        if (AUTO_CALL.AUTO_CALLING === true) {
          setCallingCallback(true);
          const callback_id = response.callback.id;

          handleCallCallback(callback_id);
        } else {
          dispatch(
            userSlice.actions.updateUserStatus(UserStateStatus.PRE_READY)
          );
        }
      } else {
        switch (defaultProcess.callingMode) {
          case CallingModeAuto.PREVIEW:
            {
              const onSuccess = (data: any) => {
                const lead: TLeadState = data.lead;
                if (lead !== null) {
                  dispatch(leadSlice.actions.setLead(lead));
                  dispatch(
                    userSlice.actions.updateUserStatus(UserStateStatus.PREVIEW)
                  );
                  dispatch(callSlice.actions.updateCallType("PREVIEW"));
                } else {
                  if (userStatus === UserStateStatus.MANUAL) {
                    dispatch(
                      userSlice.actions.updateUserStatus(
                        UserStateStatus.PRE_READY
                      )
                    );
                  }
                  setTimeoutId(timeoutCallback());
                }
              };

              const cancelToken = getPreviewLead(onSuccess);
              setCancelToken(() => cancelToken);
            }
            break;
          case CallingModeAuto.AUTO:
            {
              clientSocket?.emit(SOCKET_EVENTS.READY_AUTOCALLING);
              dispatch(
                userSlice.actions.updateUserStatus(
                  UserStateStatus.READY_AUTOCALLING
                )
              );
            }
            break;
          default:
            {
              dispatch(
                userSlice.actions.updateUserStatus(UserStateStatus.READY_IDLE)
              );
              setTimeoutId(timeoutCallback());
            }
            break;
        }
      }
    };
    const onError = (error: any) => {
      setCallingCallback(false);
      console.log(error);
    };
    const cancelToken = CALLBACK_SERVICE.callNextCallback(onSuccess, onError);
    setCancelToken(() => cancelToken);
  };

  const handleCallCallback = (id: string | number) => {
    const onSuccess = (data: any) => {
      const response = data.data;
      const lead = response.callback.Lead;
      const leadData = response.leadData;
      const callCode = leadData.callCode;

      const callId = response.callId;
      const processId = response.processId;
      const leadId = response.callback.leadId;

      dispatch(callSlice.actions.updateCallCode(callCode));
      dispatch(callSlice.actions.updateCallId(callId));
      dispatch(callSlice.actions.updateProcessId(processId));
      dispatch(leadSlice.actions.setLead(lead));
      dispatch(callSlice.actions.updateLeadDetails(lead));
      dispatch(callSlice.actions.updateCallType("CALLBACK"));
      // removeCurrentCallbackFromRedux();

      //Remove callback from redux if user called on it
      const dataUpcomingCallbacks = [...upcomingCallbackLogs];
      if (dataUpcomingCallbacks.findIndex((data) => data.callId === id)) {
        const index = dataUpcomingCallbacks.findIndex(
          (data) => data.callId === id
        );
        dataUpcomingCallbacks.splice(index, 1);

        dispatch(
          callbackLogsSlice.actions.updateUpcomingCallbacks(
            dataUpcomingCallbacks
          )
        );
      }

      dispatch(userSlice.actions.goToCall());
    };

    const onError = (err: any) => {
      console.log(err);
    };
    if (callbackCallStatus === false) {
      const cancelToken = CALLBACK_SERVICE.callCallback(onSuccess, onError, id);
      setCancelToken(() => cancelToken);
      setCallbackCallStatus(true);
    }
  };

  const decideNextState = () => {
    callNextCallback();
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setCallbackCallStatus(false);
    getMissedCallbacks();
    getUpcomingCallbacks();
    if (!canCall) callNextCallback();
  }, [location]);

  useEffect(() => {
    const listener = (data: any) => {
      // if (data.callbackId) {
      //   const index = missedCallbacks.findIndex(
      //     (missedCallback) => missedCallback.id === data.callbackId
      //   );
      //   if (index >= 0) {
      //     setMissedCallbacks((prev) => {
      //       return [...prev.slice(0, index), ...prev.slice(index + 1)];
      //     });
      //   } else {
      //     const index = upcomingCallbacks.findIndex(
      //       (upcomingCallback) => upcomingCallback.id === data.callbackId
      //     );
      //     if (index >= 0) {
      //       setUpcomingCallbacks((prev) => [
      //         ...prev.slice(0, index),
      //         ...prev.slice(index + 1),
      //       ]);
      //     }
      //   }
      // }
    };

    clientSocket?.on(SOCKET_EVENTS.CALL_REJECTED, listener);

    return () => {
      clientSocket?.off(SOCKET_EVENTS.CALL_REJECTED, listener);
    };
  }, [clientSocket?.id, upcomingCallbackLogs, missedCallbackLogs]);

  useEffect(() => {
    clientSocket?.on(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
    clientSocket?.on(SOCKET_EVENTS.PRE_READY_NEXT, preReadyNextListener);

    if (userStatus === UserStateStatus.PRE_READY && clientSocket?.connected) {
      clientSocket?.emit(SOCKET_EVENTS.PRE_READY);
      console.log({ emitsToClient: SOCKET_EVENTS.PRE_READY });
    }

    return () => {
      clientSocket?.off(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
      clientSocket?.off(SOCKET_EVENTS.PRE_READY_NEXT, preReadyNextListener);
      clientSocket?.emit(SOCKET_EVENTS.EXIT_AUTOCALLING);
      console.log({ emitsToClient: SOCKET_EVENTS.EXIT_AUTOCALLING });
    };
  }, [clientSocket?.id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      clientSocket?.emit(SOCKET_EVENTS.CLIENT_SOCKET_ID, clientSocket?.id);
      socket?.emit(SOCKET_EVENTS.SERVER_SOCKET_ID, socket?.id);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [clientSocket?.id, socket?.id]);

  return (
    <>
      {userStatus === UserStateStatus.MANUAL ? null : <Stopwatch />}
      <ReadyScreenTitle />
      {/* <Button
        variant="contained"
        onClick={() => {
          sendMessage({ data: "hello" });
          // clientSocket?.emit(
          //   SOCKET_EVENTS.HELLO,
          //   {
          //     callCode: "callCode",
          //   },
          //   (data: any) => {
          //   }
          // );
        }}>
        Send Data via Socket
      </Button> */}
      <Box pt="30px" pb="20px">
        <Typography
          fontFamily={{ xs: "Josefin Sans", md: "Roboto" }}
          pb={{ xs: "0", md: "5px" }}
          sx={{
            fontWeight: 700,
            color: "black",
            fontSize: { xs: 14, md: 18 },
          }}>
          Missed Callbacks
        </Typography>
        <Box display={{ xs: "none", md: "block" }}>
          <CallbacksTable
            color="#6F5FD9"
            callbacks={missedCallbackLogs.slice(0, 6)}
            calling={callingCallback}
            onAction={getMissedCallbacks}
            onOpen={clearPreReady}
            onClose={setPreReady}
            loading={false}
            noDataText="No Missed Callbacks"
          />
        </Box>
        <Box display={{ xs: "block", md: "none" }}>
          {/* <CallbacksTableMobile
            callbacks={missedCallbackLogs}
            calling={callingCallback}
            onAction={getMissedCallbacks}
            onOpen={clearPreReady}
            onClose={setPreReady}
          /> */}
        </Box>
      </Box>
      <Box pt="20px" pb="20px">
        <Typography
          fontFamily={{ xs: "Josefin Sans", md: "Roboto" }}
          pb={{ xs: "0", md: "5px" }}
          sx={{
            fontWeight: 700,
            color: "black",
            fontSize: { xs: 14, md: 18 },
          }}>
          Upcoming Callbacks
        </Typography>
        <Box display={{ xs: "none", md: "block" }}>
          <CallbacksTable
            color="#6F5FD9"
            callbacks={upcomingCallbackLogs.slice(0, 6)}
            calling={callingCallback}
            onAction={getUpcomingCallbacks}
            onOpen={clearPreReady}
            onClose={setPreReady}
            loading={false}
            noDataText="No Upcoming Callbacks"
          />
        </Box>
        <Box display={{ xs: "block", md: "none" }}>
          {/* <CallbacksTableMobile
            callbacks={upcomingCallbackLogs}
            onAction={getUpcomingCallbacks}
            calling={callingCallback}
            onOpen={clearPreReady}
            onClose={setPreReady}
          /> */}
        </Box>
      </Box>
      <Box>
        {manualCallStatus === true ? (
          <FloatingButton
            activeToolTip="Manual Dial (M)"
            onClick={handleDTMFPopup}
            color="#93CE15"
            Icon={DialpadIcon}
            isActive={true}
            loading={false}
          />
        ) : null}
        <FloatingButton
          onClick={() => setOpenBreakReasons(!openBreakReasons)}
          sx={{
            marginTop: { xs: "56px", md: "70px" },
          }}
          activeToolTip="Set Break (B)"
          color="#EFD053"
          Icon={BreakFloatingIcon}
          isActive={true}
        />
        <FloatingButton
          activeToolTip="Stop Calling (S)"
          sx={{ marginTop: { xs: "112px", md: "140px" } }}
          color="#CE3A3A"
          Icon={StopIcon}
          onClick={handleStopStatus}
          isActive={true}
        />
      </Box>

      {openDTMF && <DTMFPopup open={openDTMF} setOpen={handleDTMFPopup} />}
      <BreakStatusPopup
        openPopup={openBreakReasons}
        setOpenPopup={setOpenBreakReasons}
      />
    </>
  );
};

export default ReadyScreen;
