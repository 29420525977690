import { Box } from "@mui/material";
import { useAppSelector } from "../../state/hooks";
import { TLeadState } from "../../state/types/lead";
import ContactInfo from "./ContactInfo";
import PersonalData from "./PersonalData";

const PersonalDetails = ({
  showCallbackDate = true,
  showBoxShadow = false,
  loader,
  sx,
}: {
  hideDateTime?: boolean;
  showCallbackDate?: boolean;
  showBoxShadow?: boolean;
  loader: boolean;
  sx?: any;
}) => {
  const lead = useAppSelector((state) => state.lead);

  return (
    <Box sx={{ width: "100%", maxWidth: 835 }}>
      <Box
        sx={{
          background: showBoxShadow ? "#FFFFFF" : "#F4F6FE",
          boxShadow: showBoxShadow ? "0px 5px 20px rgba(0, 0, 0, 0.1)" : "none",
          padding: { xs: 2, md: 2.5 },
          borderRadius: "16px",
        }}>
        <ContactInfo lead={lead} loader={loader} />
      </Box>
      <Box
        sx={{
          marginX: { md: 2.5, xs: 0 },
          border: "1px solid",
          borderColor: showBoxShadow ? "#E0E0E0" : "rgba(111, 95, 217, 0.5)",
          borderTop: 0,
          borderRadius: { md: "0 0 16px 16px", xs: "2px 2px 16px 16px" },
          paddingTop: { md: "10px", xs: "10px" },
          paddingLeft: { md: "20px", xs: "20px" },
          paddingRight: { md: "20px", xs: "20px" },
          position: "relative",
          top: { md: 0, xs: "-16px" },
          ...sx,
        }}>
        <PersonalData
          lead={lead}
          showCallbackDate={showCallbackDate}
          loader={loader}
        />
      </Box>
    </Box>
  );
};

export default PersonalDetails;
