import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CallbackIcon from "../../assets/images/CallbackIcon";
import CallIconRed from "../../assets/images/CallIconRed";
import DialpadIcon from "../../assets/images/DialpadIcon";
import PauseIcon from "../../assets/images/PauseIcon";
import ReadyIcon from "../../assets/images/ReadyIcon";
import StopIcon from "../../assets/images/StopIcon";
import { AGENT_DASHBOARD_SERVICE } from "../../services/AgentDashboardService";
import { GetTodayTimesDTO } from "../../services/dto/GetTodayTimesDTO";
import LoginTime from "./LoginTime";
import ProductivityStatsTile from "./ProductivityStatsTile";

export type TProductivityStat = {
  time: number;
  key: keyof GetTodayTimesDTO;
  screen: string;
  isScreen?: boolean;
  icon: () => JSX.Element;
  stats?: { title: string; value: string }[];
};

const ProductivityStats = () => {
  const [loginHours, setLoginHours] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [dialingStats, setDialingStats] = useState<TProductivityStat[]>([
    {
      time: 0,
      screen: "READY",
      key: "ready",
      icon: ReadyIcon,
    },
    {
      time: 0,
      screen: "Callback",
      key: "callback",
      icon: CallbackIcon,
    },
    {
      time: 0,
      screen: "STOP",
      key: "stop",
      icon: StopIcon,
    },
    {
      time: 0,
      screen: "Call",
      key: "call",
      icon: CallIconRed,
      isScreen: false,
    },
    {
      time: 0,
      screen: "PAUSE",
      key: "pause",
      icon: PauseIcon,
    },
    {
      time: 0,
      screen: "Dialpad",
      key: "dtmf",
      icon: DialpadIcon,
      isScreen: false,
    },
  ]);

  function getStats() {
    const onSuccess = (data: GetTodayTimesDTO) => {
      setLoader(false);
      setDialingStats((prev) =>
        prev.map((stat) => {
          return {
            ...stat,
            time: data[stat.key],
          };
        })
      );
      setLoginHours(data.login);
    };
    const onError = (error: any) => {
      console.log(error);
      setLoader(false);
    };

    setLoader(true);
    AGENT_DASHBOARD_SERVICE.getTodayTimes(onSuccess, onError);
  }

  useEffect(() => {
    getStats();
  }, []);
  return (
    <>
      <LoginTime time={loginHours} />
      <Box
        sx={{
          backgroundColor: { xs: "#6F5FD917", md: "transparent" },
          borderRadius: "8px",
          p: "0px",
          mt: { xs: "24px", md: 0 },
        }}>
        <Grid
          container
          spacing={0}
          pt={{ xs: "8px", md: "16px" }}
          paddingLeft={{ xs: "16px", md: "60px" }}
          paddingRight={{ xs: "0px", md: "0px" }}
          display={"flex"}
          justifyContent="center">
          {dialingStats.map((stat, index) => (
            <Grid item xs={6} key={index} px={0} py={1.4}>
              <ProductivityStatsTile
                spaceBetween="20px"
                stat={stat}
                loader={loader}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ProductivityStats;
