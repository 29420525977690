import { Box } from "@mui/material";

const FormRow = ({
  children,
  sx = {},
}: {
  children: React.ReactNode;
  sx?: React.CSSProperties | object;
}) => {
  return (
    <Box display={"flex"} justifyContent="space-between" gap={3} sx={sx}>
      {children}
    </Box>
  );
};

export default FormRow;
