function ReadyStatusIcon() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.8607 4.6623C23.9564 4.57312 24.0331 4.46558 24.0864 4.3461C24.1396 4.22661 24.1682 4.09762 24.1705 3.96683C24.1729 3.83604 24.1488 3.70613 24.0998 3.58484C24.0508 3.46355 23.9779 3.35337 23.8854 3.26087C23.7929 3.16837 23.6827 3.09545 23.5614 3.04646C23.4401 2.99747 23.3102 2.97341 23.1794 2.97572C23.0486 2.97803 22.9197 3.00666 22.8002 3.05989C22.6807 3.11313 22.5731 3.18989 22.484 3.2856L15.055 10.7146L12.6263 8.28589C12.4416 8.11382 12.1974 8.02015 11.945 8.0246C11.6927 8.02906 11.4519 8.13129 11.2734 8.30976C11.095 8.48822 10.9927 8.729 10.9883 8.98135C10.9838 9.2337 11.0775 9.47793 11.2496 9.66259L14.3666 12.7797C14.5493 12.9621 14.7968 13.0645 15.055 13.0645C15.3131 13.0645 15.5607 12.9621 15.7433 12.7797L23.8607 4.6623ZM4.46735 0.12325L3.06986 0.570029C1.68277 1.01291 0.621668 2.20129 0.277493 3.69229C-0.53684 7.23795 0.441139 11.5525 3.21143 16.6385C5.97782 21.7167 9.00916 24.7714 12.3068 25.7988C13.7029 26.2351 15.2134 25.8507 16.2706 24.7896L17.3317 23.722C18.3227 22.7284 18.4669 21.1089 17.6694 19.9361L16.068 17.5853C15.3797 16.5748 14.1614 16.1463 13.0419 16.5203L10.0859 17.5048C8.88712 16.59 7.88414 15.4439 7.13633 14.1344C6.34927 12.7811 5.87912 11.4057 5.72716 10.0082L8.18834 7.67304C9.07151 6.83403 9.34165 5.4846 8.85591 4.33778L7.73896 1.70516C7.17789 0.380408 5.77781 -0.294956 4.46735 0.12325Z"
        fill="white"
      />
    </svg>
  );
}

export default ReadyStatusIcon;
