import { Box } from "@mui/material";
import { FORMATTER } from "../../helper/formatter";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import VerticalTableCell from "../Table/VerticalTableCell";
import VerticalTableRow from "../Table/VerticalTableRow";
import VerticalTableTile from "../Table/VerticalTableTile";
import CallbackActions from "./CallbackActions";

function AdvancedCallbacksTableMobile({
  advancedCallbacks,
  onAction,
  calling = false,
  onOpen,
  onClose,
}: {
  advancedCallbacks: GetCallbackDTO[];
  onAction?: () => void;
  calling?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) {
  return (
    <Box display={{ xs: "block", md: "none" }}>
      {advancedCallbacks.length > 0 &&
        advancedCallbacks.map((callback: GetCallbackDTO, index: number) => (
          <VerticalTableTile key={callback.id}>
            <VerticalTableRow>
              <VerticalTableCell
                position="l"
                title={index < 9 ? "0" + `${index + 1}` : `${index}`}
              />
              <VerticalTableCell
                textTransform="lowercase"
                position="r"
                subtitle={FORMATTER.formatTimeStamp(
                  new Date(),
                  new Date(callback.dateTime),
                  "left",
                  "late"
                )}
              />
            </VerticalTableRow>
            <VerticalTableRow>
              <VerticalTableCell
                position="l"
                title={"Name"}
                subtitle={callback.Lead.name}
              />
              <VerticalTableCell
                position="r"
                title={"Disposition"}
                subtitle={callback.ParentCall.CallDetails.Disposition.name}
              />
            </VerticalTableRow>
            <VerticalTableRow>
              <VerticalTableCell
                position="l"
                title={"Campaign"}
                subtitle={callback.ParentCall.Campaign?.name}
              />
              <VerticalTableCell
                position="r"
                title={"Process"}
                subtitle={callback.ParentCall.Process.name}
              />
            </VerticalTableRow>
            <VerticalTableRow>
              <VerticalTableCell
                position="l"
                title={"Callback Date"}
                subtitle={FORMATTER.formatDateTime(callback.dateTime)}
              />
              <CallbackActions
                callback={callback}
                onOpen={onOpen}
                onClose={onClose}
                onAction={onAction}
                calling={calling}
              />
            </VerticalTableRow>
          </VerticalTableTile>
        ))}
    </Box>
  );
}

export default AdvancedCallbacksTableMobile;
