export const ALL_ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  READY: "/ready",
  AGENT_DASHBAORD: "/agent-dashboard",
  ADMIN_DASHBAORD: "/admin-dashboard",
  AGENT_LOGGER: "/agent-logger",
  MISSED_CDR: "/missed-cdr",
  AGENT_RECORDING: "/agent-recording",
  AGENT_CDR: "/agent-cdr",
  DASHBOARD: "/dashboard",
  CALL: "/call",
  CALLBACKS: "/callbacks",
  PREVIEW: "/preview",
  CALL_BACK_LOGGER: "/callback-logger",
  PERFORMANCE_GAUGE: "/performance-gauge",
  BREAK_LOGS: "/break-logs",
  QUEUE_METRIC: "/queue-metric",
  ALL_LEADS: "/all-leads",
  TEST_UI_ELEMENTS: "/test-ui-elements",
  MANAGE_USERS: "/operations/manage-users",
  EDIT_USER_DETAILS: "/operations/manage-users/edit-user",
  ADD_USER: "/operations/manage-users/add-user",
  MANAGE_CAMPAIGNS: "/operations/manage-campaigns",
  PROCESS_DETAILS: "/operations/manage-campaigns/:id/process-details",
  CREATE_CAMPAIGN: "/operations/manage-campaigns/create-campaign",
  CHANGE_PASSWORD: "/change-password",
  EDIT_CAMPAIGN: "/operations/manage-campaigns/edit-campaign",
  CREATE_PROCESS: "/operations/manage-campaigns/:id/create-process",
  EDIT_PROCESS: "/operations/manage-campaigns/:id/edit-process/:processId",
  MANAGE_LEADSET: "/operations/manage-leadset",
  CREATE_LEADSET: "/operations/manage-leadset/create-leadset",
  LEADSET_DETAILS: "/operations/manage-leadset/leadset-details/:id",
  CREATE_LEAD: "/operations/manage-leadset/leadset-details/:id/create-lead",
  UPLOAD_LEAD: "/operations/manage-leadset/leadset-details/:id/upload-lead",
  EDIT_LEAD: "/operations/manage-leadset/leadset-details/edit-lead",
  MANAGE_DISPOSE: "/operations/manage-dispose",
  LEVEL_ONE_DISPOSE: "/operations/manage-dispose/level-1-dispose",
  LEVEL_TWO_DISPOSE: "/operations/manage-dispose/level-2-dispose",
  LEVEL_THREE_DISPOSE: "/operations/manage-dispose/level-3-dispose",
};
