import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { CDRFilters } from "../../services/dto/GetAgentCdrDTO";
import { filterFormSlice } from "../../state/filter-form/filterFormSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { FILTER_KEYS, TFilterForm } from "../../state/types/filterForm";
import { toDesiredFormat } from "../../SupportingFiles/HelpingFunction";
import FormActionButton from "../Actions/FormActionButton";
import CustomAutocomplete from "../Custom/CustomAutocomplete";
import { CustomPopup } from "../Custom/CustomPopup";
import { selectOptionsMapper } from "../Custom/CustomSelect";
import CustomTextField from "../Custom/CustomTextField";
import DatePicker from "../EVCustoms/DatePicker/DatePicker";

const FilterPopupContent = ({
  filters,
  setOpen,
  type,
}: {
  filters?: CDRFilters;
  setOpen: Function;
  type: string;
}) => {
  const params = useAppSelector((state) => state.filterForm);
  const [filterParams, setFilterParams] = useState({ ...params });
  const dispatch = useAppDispatch();
  const styledField = { width: { xs: 200, md: 257, maxHeight: "50px" } };
  const styledAutoComplete = { width: { xs: 200, md: 259, maxHeight: "50px" } };
  const styledDateInput = { width: { xs: 174, md: 232 }, height: "25px" };
  const gridItemPadding = "30px";
  const agents =
    filters && filters[FILTER_KEYS.filterAgent]
      ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterAgent])
      : [];
  const campaigns = filters
    ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterCampaign])
    : [];
  const processes = filters
    ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterProcess])
    : [];
  const leadsets = filters
    ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterLeadset])
    : [];
  const dispositions = filters
    ? selectOptionsMapper(
        "value",
        "id",
        filters?.[FILTER_KEYS.filterDisposition]
      )
    : [];

  const handleInputChange = (key: keyof TFilterForm, value: number) => {
    // if (!value) return;
    setFilterParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getOptionLabel = (option: any) => {
    return option?.label;
  };

  const handleSubmit = () => {
    const isValid = Object.entries(filterParams).some(
      ([key, val]) => val !== ""
    );
    if (!isValid) return;
    dispatch(filterFormSlice.actions.setFilterForm(filterParams));
    setOpen(false);
  };

  const clearFilterForm = () => {
    setFilterParams(filterFormSlice.getInitialState());
  };

  const updateMasterState = (attrName: any, value: any) => {
    switch (attrName) {
      case "date_from":
        setFilterParams((prev) => ({
          ...prev,
          [FILTER_KEYS.filterStartAfter]: toDesiredFormat(value),
        }));
        break;
      case "date_to":
        setFilterParams((prev) => ({
          ...prev,
          [FILTER_KEYS.filterStartBefore]: toDesiredFormat(value),
        }));
        break;
      default:
        handleInputChange(attrName, value);
        break;
    }
  };

  return (
    <>
      <Grid container spacing={2} pt="13px" pl="12px">
        {type === "admin" ? (
          <Grid md={4}>
            <CustomAutocomplete
              id="agent"
              sx={styledAutoComplete}
              onChange={(ev: any, value: any) =>
                handleInputChange(FILTER_KEYS["filterAgent"], value.value)
              }
              data={agents}
              getOptionLabel={getOptionLabel}
              label="Agent"
              selectedValue={filterParams["filter-agent"]}
              limitOptions={3}
            />
          </Grid>
        ) : null}
        <Grid md={4}>
          <CustomTextField
            fullWidth
            label="Customer Name"
            sx={styledField}
            id={FILTER_KEYS.filterName}
            value={filterParams[FILTER_KEYS.filterName]}
            onChange={(event: any) => {
              handleInputChange(
                FILTER_KEYS.filterName,
                event?.target.value.length === 0 ? "" : event?.target.value
              );
            }}
          />
        </Grid>
        <Grid md={4}>
          <CustomTextField
            fullWidth
            label="Phone Number"
            sx={styledField}
            id={FILTER_KEYS.filterPhone}
            value={filterParams[FILTER_KEYS.filterPhone]}
            onChange={(event: any) =>
              handleInputChange(FILTER_KEYS.filterPhone, event?.target.value)
            }
          />
        </Grid>
        <Grid md={4} paddingBottom={gridItemPadding}>
          <CustomAutocomplete
            id="campaign"
            sx={styledAutoComplete}
            onChange={(ev: any, value: any) =>
              handleInputChange(FILTER_KEYS.filterCampaign, value.value)
            }
            data={campaigns}
            getOptionLabel={getOptionLabel}
            label="Campaign"
            selectedValue={filterParams["filter-campaign"]}
            limitOptions={3}
          />
        </Grid>
        <Grid md={4} paddingBottom={gridItemPadding}>
          <CustomAutocomplete
            id="process"
            sx={styledAutoComplete}
            onChange={(ev: any, value: any) =>
              handleInputChange(FILTER_KEYS.filterProcess, value.value)
            }
            data={processes}
            getOptionLabel={getOptionLabel}
            label="Process"
            selectedValue={filterParams["filter-process"]}
            limitOptions={3}
          />
        </Grid>
        <Grid md={4} paddingBottom={gridItemPadding}>
          <CustomAutocomplete
            id="leadset"
            sx={styledAutoComplete}
            onChange={(ev: any, value: any) =>
              handleInputChange(FILTER_KEYS.filterLeadset, value.value)
            }
            data={leadsets}
            getOptionLabel={getOptionLabel}
            label="Leadset"
            selectedValue={filterParams["filter-leadset"]}
            limitOptions={3}
          />
        </Grid>
        <Grid md={4} paddingBottom={gridItemPadding} paddingRight="14px">
          <DatePicker
            title="Date From"
            value={
              filterParams[FILTER_KEYS.filterStartAfter] !== ""
                ? new Date(filterParams[FILTER_KEYS.filterStartAfter])
                : null
            }
            attrName={"date_from"}
            value_update={updateMasterState}
            max_date={new Date()}
            sx={{ height: "57px", borderColor: "rgba(0,0,0,0.1)" }}
          />
        </Grid>
        <Grid md={4} paddingBottom={gridItemPadding} paddingRight="14px">
          <DatePicker
            title="Date To"
            value={
              filterParams[FILTER_KEYS.filterStartBefore] !== ""
                ? new Date(filterParams[FILTER_KEYS.filterStartBefore])
                : null
            }
            attrName={"date_to"}
            value_update={updateMasterState}
            max_date={new Date()}
            sx={{ height: "57px", borderColor: "rgba(0,0,0,0.1)" }}
          />
        </Grid>
        <Grid md={4}>
          <CustomAutocomplete
            id="dispose"
            sx={styledAutoComplete}
            onChange={(ev: any, value: any) =>
              handleInputChange(FILTER_KEYS.filterDisposition, value.value)
            }
            data={dispositions}
            getOptionLabel={getOptionLabel}
            label="Dispose"
            selectedValue={filterParams["filter-disposition"]}
            limitOptions={3}
          />
        </Grid>
        <Grid md={2} />
      </Grid>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          mb: "25px",
        }}>
        <FormActionButton onClick={clearFilterForm} title="Reset" />
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={handleSubmit}
        />
      </Box>
    </>
  );
};

const FilterPopupHeader = () => {
  return (
    <Typography sx={{ textAlign: "center", fontSize: "24px" }}>
      Filter Details
    </Typography>
  );
};

export const FilterPopup = ({
  open,
  setOpen,
  filters,
  type,
}: {
  open: boolean;
  setOpen: Function;
  filters?: CDRFilters;
  type: string;
}) => {
  return (
    <CustomPopup
      maxWidth={872}
      minWidth={872}
      Header={FilterPopupHeader}
      open={open}
      setOpen={setOpen}
      Component={() =>
        FilterPopupContent({ filters, setOpen, type })
      }></CustomPopup>
  );
};
