import { GetUserResponseDTO } from "./GetUserResponseDTO";

export enum CommentType {
  DISPOSITION = "DISPOSITION",
  GENERAL = "GENERAL",
}

export type GetCommentDTO = {
  id: number;
  callId: number;
  comment: string;
  createdBy: number;
  createdByUser: GetUserResponseDTO;
  createdOn: string;
  disposition: string;
  type: CommentType;
};
