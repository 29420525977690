import { PayloadAction } from "@reduxjs/toolkit";
import { TFilterForm } from "../types/filterForm";
import { filterFormSlice } from "./filterFormSlice";

function setFilterForm(state: TFilterForm, action: PayloadAction<TFilterForm>) {
  return action.payload;
}

function updateFilterForm(
  state: TFilterForm,
  action: PayloadAction<{ [key: string]: string | number }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

function resetForm(state: TFilterForm): any {
  return filterFormSlice.getInitialState();
}

export const FILTER_FORM_REDUCERS = {
  setFilterForm,
  updateFilterForm,
  resetForm,
};

export type TFilterFormReducer = typeof FILTER_FORM_REDUCERS;
