import { Autocomplete, Chip, TextField } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Option {
  value: number;
  label: string;
}

export const selectOptionsMapper = (
  label: string,
  value: string,
  array: any[]
) => {
  const options: Option[] = [];
  array.map((item) => {
    options.push({ label: item[label], value: parseInt(item[value]) });
  });
  return options;
};

const CustomMultiSelectFixed = ({
  defaultValues = [],
  options,
  onChange,
  selectedValue,
  onInputChange,
  inputValue,
  label = "Select",
  error,
}: any) => {
  const [value, setValue] = React.useState([
    ...defaultValues,
    ...selectedValue,
  ]);

  if (!options || options.length === 0) {
    // Render a loading spinner or an error message
    return <div>Loading ...</div>;
  }

  return (
    <Autocomplete
      sx={{ marginTop: "25px" }}
      multiple
      fullWidth
      value={[...defaultValues, ...selectedValue]}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={options}
      getOptionLabel={(option) => option.name}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option?.name}
            {...getTagProps({ index })}
            disabled={defaultValues.indexOf(option) !== -1}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="" error={error} />
      )}
    />
  );
};

export default CustomMultiSelectFixed;
