const CallIconGreen = ({
  width = "15",
  height = "14",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 15 14`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2397 8.06119C11.0887 7.92394 10.8902 7.85075 10.6863 7.85705C10.4823 7.86336 10.2887 7.94867 10.1465 8.09499L8.26576 10.029C7.81307 9.94256 6.90297 9.65886 5.96614 8.72447C5.02932 7.78693 4.7456 6.87453 4.66151 6.42502L6.5941 4.54365C6.7406 4.40151 6.82605 4.20794 6.83236 4.00392C6.83867 3.7999 6.76534 3.60142 6.62789 3.45051L3.7239 0.257523C3.5864 0.106131 3.39529 0.0142999 3.19116 0.00153385C2.98704 -0.0112322 2.78598 0.056072 2.63068 0.189152L0.925223 1.65165C0.789346 1.78801 0.708245 1.9695 0.697305 2.16168C0.685516 2.35815 0.460742 7.01206 4.06971 10.6223C7.21812 13.7697 11.1619 14 12.248 14C12.4068 14 12.5043 13.9953 12.5302 13.9937C12.7224 13.983 12.9038 13.9015 13.0395 13.765L14.5013 12.0589C14.6345 11.9037 14.7019 11.7027 14.6893 11.4986C14.6767 11.2945 14.585 11.1034 14.4337 10.9658L11.2397 8.06119Z"
        fill="white"
      />
    </svg>
  );
};

export default CallIconGreen;
