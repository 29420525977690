import { TableRow, styled, useTheme } from "@mui/material";
import React from "react";

const CreateTableRow = styled(TableRow)(({ theme }) => ({}));

const StyledTableRow = ({
  children,
  onClick,
  minHeight = "",
  sx,
  backgroundColor,
}: {
  children: React.ReactNode;
  onClick?: any;
  minHeight?: string;
  sx?: React.CSSProperties;
  backgroundColor?: string;
}) => {
  const theme = useTheme();
  return (
    <CreateTableRow
      sx={{
        height: minHeight,
        ":hover": {
          cursor: onClick && "pointer",
        },
        "&:nth-of-type(even)": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#606060" : "#F1EFFB",
        },
        backgroundColor: backgroundColor,
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </CreateTableRow>
  );
};

export default StyledTableRow;
