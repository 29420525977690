const PauseIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#F2994A" fillOpacity="0.2" />
      <path
        d="M20 7.5C22.2678 7.5 24.3593 8.05881 26.2744 9.17643C28.1896 10.2941 29.7059 11.8104 30.8236 13.7256C31.9412 15.6407 32.5 17.7322 32.5 20C32.5 22.2678 31.9412 24.3593 30.8236 26.2744C29.7059 28.1896 28.1896 29.7059 26.2744 30.8236C24.3593 31.9412 22.2678 32.5 20 32.5C17.7322 32.5 15.6407 31.9412 13.7256 30.8236C11.8104 29.7059 10.2941 28.1896 9.17643 26.2744C8.05881 24.3593 7.5 22.2678 7.5 20C7.5 17.7322 8.05881 15.6407 9.17643 13.7256C10.2941 11.8104 11.8104 10.2941 13.7256 9.17643C15.6407 8.05881 17.7322 7.5 20 7.5ZM20 28.8542C21.6059 28.8542 23.087 28.4581 24.4434 27.666C25.7997 26.8739 26.8739 25.7997 27.666 24.4434C28.4581 23.087 28.8542 21.6059 28.8542 20C28.8542 18.3941 28.4581 16.913 27.666 15.5566C26.8739 14.2003 25.7997 13.1261 24.4434 12.334C23.087 11.5419 21.6059 11.1458 20 11.1458C18.3941 11.1458 16.913 11.5419 15.5566 12.334C14.2003 13.1261 13.1261 14.2003 12.334 15.5566C11.5419 16.913 11.1458 18.3941 11.1458 20C11.1458 21.6059 11.5419 23.087 12.334 24.4434C13.1261 25.7997 14.2003 26.8739 15.5566 27.666C16.913 28.4581 18.3941 28.8542 20 28.8542ZM21.5625 25.2083C21.4106 25.2083 21.2858 25.1595 21.1882 25.0618C21.0905 24.9642 21.0417 24.8394 21.0417 24.6875V15.3125C21.0417 15.1606 21.0905 15.0358 21.1882 14.9382C21.2858 14.8405 21.4106 14.7917 21.5625 14.7917H24.6875C24.8394 14.7917 24.9642 14.8405 25.0618 14.9382C25.1595 15.0358 25.2083 15.1606 25.2083 15.3125V24.6875C25.2083 24.8394 25.1595 24.9642 25.0618 25.0618C24.9642 25.1595 24.8394 25.2083 24.6875 25.2083H21.5625ZM15.3125 25.2083C15.1606 25.2083 15.0358 25.1595 14.9382 25.0618C14.8405 24.9642 14.7917 24.8394 14.7917 24.6875V15.3125C14.7917 15.1606 14.8405 15.0358 14.9382 14.9382C15.0358 14.8405 15.1606 14.7917 15.3125 14.7917H18.4375C18.5894 14.7917 18.7142 14.8405 18.8118 14.9382C18.9095 15.0358 18.9583 15.1606 18.9583 15.3125V24.6875C18.9583 24.8394 18.9095 24.9642 18.8118 25.0618C18.7142 25.1595 18.5894 25.2083 18.4375 25.2083H15.3125Z"
        fill="#F2994A"
      />
    </svg>
  );
};

export default PauseIcon;
