import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React from "react";
import PlayCirlcleIcon from "../../assets/images/PlayCirlceIcon";
import { FORMATTER } from "../../helper/formatter";
import { AgentCDRDTO } from "../../services/dto/GetAgentCdrDTO";
import DataNotFound from "../DataNotfound/DataNotFound";
import MediaPlayer from "../LeadInformation/MediaPlayer";
import LightTooltip from "../LightToolTip";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import TableProgress from "../Table/TableProgress";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "40px" },
  { id: "name", label: "Name", allowSort: false, width: "140xp" },
  { id: "leadset", label: "Leadset", allowSort: false, width: "117px" },
  { id: "leadId", label: "Lead ID", allowSort: false, width: "117px" },
  { id: "createdOn", label: "Date", allowSort: false, width: "117px" },
  {
    id: "callingMode",
    label: "Calling Mode",
    allowSort: false,
    width: "117px",
  },
  { id: "talktime", label: "Talktime", allowSort: false, width: "117px" },
  { id: "campaign", label: "Campaign", allowSort: false, width: "117px" },
  { id: "processName", label: "Process", allowSort: false, width: "117px" },
  {
    id: "disposition",
    label: "Dispose",
    allowSort: false,
    width: "117px",
  },
  { id: "action", label: "Action", allowSort: false, width: "70px" },
];

const AgentCDRTable = ({
  cdrs,
  startIndex,
  loading,
}: {
  cdrs: AgentCDRDTO[];
  startIndex: number;
  loading: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedRecording, setSelectedRecording] =
    React.useState<AgentCDRDTO | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    recording: AgentCDRDTO
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecording(recording);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor="primary.main">
            {headCells.map((cell) => (
              <StyledTableCell key={cell.id} width={cell.width}>
                {cell.label}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {loading ? (
            <TableProgress colSpans={10} />
          ) : !cdrs.length ? (
            <DataNotFound colSpans={10} />
          ) : (
            <TableBody>
              {cdrs.length > 0 &&
                cdrs.map((cdr: AgentCDRDTO, index: number) => (
                  <StyledTableRow key={index} sx={{ height: "56px" }}>
                    <StyledTableCell width={headCells[0].width}>
                      {startIndex == 0 && index < 9
                        ? "0" + (index + 1)
                        : startIndex + (index + 1)}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[1].width}>
                      {cdr.leadName ? cdr.leadName : cdr.agentUserName}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[2].width}>
                      {cdr.leadsetName}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[3].width}>
                      {cdr.leadId}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[4].width}>
                      {FORMATTER.formatDate(cdr.createdOn)}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[5].width}>
                      {cdr.callingMode}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[6].width}>
                      {FORMATTER.formatTimer(cdr.talktime)}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[7].width}>
                      {cdr.campaignName}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[8].width}>
                      {cdr.processName}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[9].width}>
                      {cdr.firstDispose}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[10].width}>
                      <Box
                        gap={"10px"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="flex-start">
                        <LightTooltip title="Play Recording" placement="bottom">
                          <IconButton
                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                              handleClick(event, cdr)
                            }
                            sx={{ padding: "0" }}>
                            <PlayCirlcleIcon width="22" height="22" />
                          </IconButton>
                        </LightTooltip>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Popover
        sx={{
          ".MuiPopover-paper": {
            background: "none",
            boxShadow: "none",
            paddingRight: 5,
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}>
        <MediaPlayer src={selectedRecording?.recordingPath} />
      </Popover>
    </>
  );
};

export default AgentCDRTable;
