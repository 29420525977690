import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { AGENT_DASHBOARD_SERVICE } from "../../services/AgentDashboardService";
import Breaks from "./Breaks";
import BreakStatusHeader from "./BreakStatusHeader";

const BreakStatus = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [totalBreak, setTotalBreak] = useState({
    time: { hours: 0, minutes: 0, seconds: 0 },
  });
  const [breaks, setBreaks] = useState([
    {
      title: "Lunch Break",
      key: "lunch",
      time: { hours: 0, minutes: 0, seconds: 0 },
    },
    {
      title: "Meeting Break",
      key: "meeting",
      time: { hours: 0, minutes: 0, seconds: 0 },
    },
    {
      title: "Tea Break",
      key: "tea",
      time: { hours: 0, minutes: 0, seconds: 0 },
    },
    {
      title: "Bio Break",
      key: "bio",
      time: { hours: 0, minutes: 0, seconds: 0 },
    },
    {
      title: "General Break",
      key: "general",
      time: { hours: 0, minutes: 0, seconds: 0 },
    },
  ]);
  useEffect(() => {
    const onSuccess = (data: any) => {
      setLoader(false);
      setBreaks((prev) =>
        prev.map((breakKeyData) => ({
          ...breakKeyData,
          time: data[breakKeyData.key],
        }))
      );
      setTotalBreak({ time: data.total });
    };
    setLoader(true);
    AGENT_DASHBOARD_SERVICE.getBreakStats(onSuccess, () => {});
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        mt="20px"
        pt="24px"
        pb="15px"
        px="20px"
        sx={{ backgroundColor: "#FFFAE7", borderRadius: "20px 20px 0px 0px" }}>
        <BreakStatusHeader time={totalBreak.time} />
      </Box>
      <Box
        mt="-2px"
        sx={{
          backgroundColor: "#F9F1D3",
          borderRadius: "0px 0px 20px 20px",
        }}>
        <Breaks breaks={breaks} loader={loader} />
      </Box>
    </>
  );
};

export default BreakStatus;
