import DatePicker from "react-datepicker";
import CustomDateInput from "./CustomDateInput";

const CustomDatePicker = ({
  selected,
  setSelected,
  error = false,
  label = "",
}: {
  selected: Date | null;
  setSelected: Function;
  error?: boolean;
  label?: string;
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function getRange(startYear: number) {
    const years = [];
    const currentYear = new Date().getFullYear();
    startYear = startYear || 1950;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }
  return (
    <DatePicker
      selected={selected}
      onChange={(date) => setSelected(date)}
      customInput={
        <CustomDateInput
          label={
            selected === null ? (label ? label : "DOB * / DD-MM-YYYY") : ""
          }
          error={error}
        />
      }
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(parseInt(value))}
          >
            {getRange(1950).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
    />
  );
};

export default CustomDatePicker;
