import { List } from "@mui/material";
import Break from "./Break";

const Breaks = ({ breaks, loader }: { breaks: any; loader: boolean }) => {
  const formatTIme = (hours: number, minutes: number, seconds: number) => {
    const formatNumber = (value: number) => {
      return (`00` + value).slice(-2);
    };
    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(
      seconds
    )}`;
  };
  return (
    <List>
      {breaks.map((breakTime: any) => (
        <Break
          loader={loader}
          title={breakTime.title}
          value={formatTIme(
            breakTime.time.hours < 0 ? 0 : breakTime.time.hours,
            breakTime.time.minutes < 0 ? 0 : breakTime.time.minutes,
            breakTime.time.seconds < 0 ? 0 : breakTime.time.seconds
          )}
          key={breakTime.title}
        />
      ))}
    </List>
  );
};

export default Breaks;
