import { Typography, Box, useTheme } from "@mui/material";
import { forwardRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomDateInput = forwardRef(
  ({ value, onClick, label, error = false }: any, ref) => {
    const theme = useTheme();
    return (
      <Box
        onClick={onClick}
        sx={{
          width: "100%",
          border: "1px solid",
          borderColor: error ? theme.palette.error.main : "rgba(0,0,0,0.1)",
          borderRadius: "6px",
          height: "48px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ":hover": {
            cursor: "pointer",
            borderColor: "black",
          },
        }}
      >
        <Typography p="0 10px">{label ? label : value}</Typography>
        <KeyboardArrowDownIcon sx={{ p: "0 10px" }} />
      </Box>
    );
  }
);

export default CustomDateInput;
