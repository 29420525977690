import { Theme, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import ClockHeader from "../components/ClockHeader";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import EditLeadForm from "../components/Leadset/EditLeadForm";

const EditLead = () => {
  const { state } = useLocation();
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title="Edit Lead"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <EditLeadForm />
    </>
  );
};

export default EditLead;
