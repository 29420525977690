export enum UserStateStatus {
  LOGIN = "LOGIN",
  PRE_READY = "PRE_READY",
  STOP = "STOP",
  PAUSE = "PAUSE",
  MANUAL = "MANUAL",
  MANUAL_PREVIEW = "MANUAL_PREVIEW",
  CALLBACK = "CALLBACK",
  CALL = "CALL",
  DISPOSITION = "DISPOSITION",
  READY_IDLE = "READY_IDLE",
  READY_AUTOCALLING = "READY_AUTOCALLING",
  PREVIEW = "PREVIEW",
  READY_CALLBACK = "READY_CALLBACK",
  REDIAL_CALL = "REDIAL_CALL",
  CALLBACK_PREVIEW = "CALLBACK_PREVIEW",
}

export enum UserAccessLevel {
  AGENT = "AGENT",
  ADMIN = "ADMIN",
  SUPERVISOR = "SUPERVISOR",
}

export type UserDetails = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  accessLevel: UserAccessLevel;
  bridge: string;
  webrtc_username: string;
  webrtc_password: string;
};

export enum UserBreakReason {
  MISC = "Misc.",
  GENERAL = "General",
  LUNCH = "Lunch",
  MEETING = "Meeting",
  TEA = "Tea",
  BIO = "Bio",
  OTHER = "Other",
}

export type TUserState = {
  status: UserStateStatus;
  breakReason: UserBreakReason;
  userDetails: UserDetails;
  manualCallStatus?: boolean;
  micErrorStatus?: boolean;
  webRTCConnStatus?: boolean;
  shortcutKeys: boolean;
};

export type TLeadset = {
  name: string;
  description: string;
};
