import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link, Table, TableBody, TableContainer } from "@mui/material";
import { useTheme } from "@mui/system";
import { useState } from "react";
import { FORMATTER } from "../../helper/formatter";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { assignCallbackSlice } from "../../state/assign-callback/assignCallbackSlice";
import { callbackSlice } from "../../state/callback/callbackSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import CustomCheckBox from "../Custom/CustomCheckBox";
import DataNotFound from "../DataNotfound/DataNotFound";
import EditCallbackDetailsPopUp from "../EditCallbackDetails/EditCallbackDetailsPopUp";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import TableProgress from "../Table/TableProgress";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "26px" },
  { id: "name", label: "Customer Name", allowSort: false, width: "198px" },
  { id: "process", label: "Process", allowSort: true, width: "198px" },
  { id: "phone", label: "Phone", allowSort: true, width: "198px" },
  { id: "agent", label: "Agent", allowSort: true, width: "198px" },
  { id: "campaign", label: "Campaign", allowSort: true, width: "198px" },
  { id: "date", label: "Callback Date", allowSort: true, width: "198px" },
  { id: "action", label: "Action", allowSort: false, width: "114px" },
];

function CallbackLoggerTable({
  callbacks,
  loader,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  agents = [],
  onAction,
}: {
  callbacks: GetCallbackDTO[];
  loader: boolean;
  order: "desc" | "asc";
  orderBy: string;
  setOrder: Function;
  setOrderBy: Function;
  agents?: GetUserResponseDTO[];
  onAction: Function;
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const assignCallbacksForm = useAppSelector((state) => state.assignCallbacks);
  const dispatch = useAppDispatch();

  const handleCheckBox = (event: any, id: number) => {
    const callbacks = assignCallbacksForm.id;
    const updatedCallbacks = [...assignCallbacksForm.id];
    if (!event.target.checked) {
      if (callbacks.includes(id)) {
        const index = callbacks.findIndex((value) => value == id);
        updatedCallbacks.splice(index, 1);
      }
    } else {
      updatedCallbacks.push(id);
    }
    dispatch(assignCallbackSlice.actions.updateCallbacks(updatedCallbacks));
  };
  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor={theme.palette.primary.main}>
            {headCells.map((cell) => (
              <StyledTableCell key={cell.id} width={cell.width}>
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {loader ? (
            <TableProgress colSpans={6} />
          ) : callbacks.length === 0 ? (
            <DataNotFound colSpans={6} />
          ) : (
            <TableBody>
              {callbacks.map((callback: GetCallbackDTO, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell width={headCells[0].width}>
                    <CustomCheckBox
                      checked={assignCallbacksForm.id.includes(callback.id)}
                      onChange={(event) => handleCheckBox(event, callback.id)}
                      paddingBottom="1px"
                    />
                  </StyledTableCell>
                  <StyledTableCell width={headCells[1].width}>
                    {callback.Lead.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[2].width}>
                    {callback.ParentCall.Process.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[3].width}>
                    {callback.Lead.phone}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[4].width}>
                    {callback.Agent.firstName
                      ? callback?.Agent?.firstName
                      : callback?.Agent?.username}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[5].width}>
                    {callback?.ParentCall?.Campaign?.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[6].width}>
                    {FORMATTER.formatDateTime(callback.dateTime)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[7].width}>
                    <Link
                      sx={{ margin: 0 }}
                      onClick={() => {
                        dispatch(callbackSlice.actions.setCallback(callback));
                        setOpen(true);
                      }}>
                      <BorderColorIcon
                        sx={{ color: "#5ECE62", height: 18, margin: 0 }}
                      />
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <EditCallbackDetailsPopUp
        agents={agents}
        open={open}
        setOpen={setOpen}
        onAction={onAction}
      />
    </>
  );
}

export default CallbackLoggerTable;
