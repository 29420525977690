import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { ADMIN_SERVICES } from "../../services/AdminServices";
import { toDesiredFormat } from "../../SupportingFiles/HelpingFunction";
import UGTable from "../EVCustoms/UGTable/UGTable";

const BreakLogsDetailTable = ({
  selectedUser,
  startEndDate,
}: {
  selectedUser: any;
  startEndDate: [Date, Date];
}) => {
  const [loader, setLoader] = useState(true);
  const columns: GridColDef[] = [
    { field: "id", headerName: "Sr", width: 10 },
    {
      field: "title",
      headerName: "Title",
      width: 100,
      headerAlign: "left",
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 200,
      headerAlign: "left",
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Typography style={{ fontSize: "14px" }}>
            {moment(row.startTime)
              .locale("en-IR")
              .format("ddd, DD MMM YYYY HH:mm:ss")}
          </Typography>
        );
      },
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 200,
      headerAlign: "left",
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Typography style={{ fontSize: "14px" }}>
            {moment(row.endTime)
              .locale("en-IR")
              .format("ddd, DD MMM YYYY HH:mm:ss")}
          </Typography>
        );
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 200,
      headerAlign: "left",
    },
  ];

  const [rows, setRows] = useState<
    Array<{ id: number; title: string; startTime: string; endTime: string }>
  >([]);

  useEffect(() => {
    getBreakLogsData();
  }, []);

  function getBreakLogsData() {
    const onSuccess = (data: any) => {
      setLoader(false);
      const response = data;
      const error_status = response.errorStatus;

      if (error_status === false) {
        const responseData = response.data[0].duration;

        const formattedData = [];

        let sr_no = 0;
        for (const key in responseData) {
          const events = responseData[key];
          for (const event of events) {
            sr_no = sr_no + 1;
            formattedData.push({
              id: sr_no,
              title: key,
              startTime: event.startTime,
              endTime: event.endTime,
              comment: event.comment,
            });
          }
        }

        setRows(formattedData);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    setLoader(true);
    const data = {
      startDate:
        toDesiredFormat(startEndDate[0]).substring(0, 10) + "T00:01:00.000Z",
      endDate:
        toDesiredFormat(startEndDate[1]).substring(0, 10) + "T11:59:00.000Z",
      id: selectedUser.id,
    };

    ADMIN_SERVICES.getBreakLogsByAgent(onSuccess, onError, data);
  }

  return <UGTable data={rows} header={columns} loader={loader} />;
};

export default BreakLogsDetailTable;
