import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import InputField from "../Dialpad/InputField";
import { Numpad } from "../Dialpad/Numpad";
import { CustomPopup } from "../Custom/CustomPopup";
import { Box, Theme, useMediaQuery } from "@mui/material";
import CustomSelect from "../Custom/CustomSelect";
import { GetCampaignDTO } from "./../../services/dto/GetCampaignDTO";
import { CALL_SERVICE } from "../../services/CallService";
import { CreateCallDTO } from "../../services/dto/CreateCallDTP";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { callSlice } from "../../state/call/callSlice";
import { allowedCallingStates, CallStatus } from "../../state/types/call";
import Time from "../Stopwatch/Timer";
import { userSlice } from "../../state/user/userSlice";
import { validateMobileNumber } from "../../SupportingFiles/HelpingFunction";
import { leadSlice } from "../../state/lead/leadSlice";

const DTMFContent = () => {
  const [inputNumber, setInputNumber] = useState("");
  const campaigns = useAppSelector((state) => state.campaigns);
  const [processes, setProcesses] = useState<GetCampaignDTO[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [selectedProcess, setSelectedProcess] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const disableCampaigns = campaigns.length === 0;
  const [isLoadingProcess, setIsLoadingProcess] = useState(true);
  const [isCalling, setIsCalling] = useState(false);
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  function handleCampaignSelect(event: any) {
    const campaignId = event.target.value;
    const campaignSelected = campaigns.find(
      (campaign) => campaign.id === campaignId
    );
    if (campaignSelected === undefined) {
      return;
    }
    setSelectedCampaign({
      id: campaignSelected?.id,
      name: campaignSelected?.name,
    });
    setProcesses(campaignSelected.processes);
    setIsLoadingProcess(false);
  }

  function handleProcessSelect(event: any) {
    const processId = event.target.value;
    const processSelected = processes.find(
      (process) => process.id === processId
    );
    if (processSelected === undefined) {
      return;
    }
    setSelectedProcess({
      id: processSelected?.id,
      name: processSelected?.name,
    });
  }

  function handleCall() {
    if (!selectedProcess || !inputNumber) {
      return;
    }

    const onSuccess = (data: any) => {
      const response = data.data;

      const call_code = response.leadData.callCode;
      const leadId = response.leadId;
      const processId = response.processId;

      dispatch(callSlice.actions.updateProcessId(processId));
      dispatch(leadSlice.actions.updateLead({ key: "id", value: leadId }));
      dispatch(callSlice.actions.updateCallId(response.callId));
      dispatch(callSlice.actions.updateCallCode(call_code));
      dispatch(callSlice.actions.updateLeadId(leadId));

      dispatch(callSlice.actions.updateCallStatus(CallStatus.CALL_RINGING));
      dispatch(userSlice.actions.goToCall());
    };
    const onError = () => {
      setIsCalling(false);
      dispatch(callSlice.actions.updateCallStatus(CallStatus.IDLE));
    };

    setIsCalling(true);

    // dispatch(leadSlice.actions.setLead(leadSlice.getInitialState()));
    dispatch(
      leadSlice.actions.updateLead({ key: "phone", value: inputNumber })
    );
    const dto: CreateCallDTO = {
      phoneNumber: inputNumber,
      processId: selectedProcess?.id,
    };
    CALL_SERVICE.createManualCall(onSuccess, onError, dto);
  }

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (validateMobileNumber(inputNumber) === true) {
          handleCall();
        }
      }
    };
    window.addEventListener("keyup", listener);

    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, [handleCall]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          gap: { xs: 1.6, md: 2 },
          marginTop: { xs: 1 },
        }}>
        <CustomSelect
          label="Campaign"
          values={campaigns.map((campaign) => ({
            value: campaign.id,
            label: campaign.name,
          }))}
          minWidth={mobileView ? 156 : 231}
          onChange={handleCampaignSelect}
          disabled={disableCampaigns}
          selectedValue={
            selectedCampaign === null ? "" : `${selectedCampaign?.id}`
          }
          sx={{ height: { xs: "56px", md: "50px" } }}
        />
        <CustomSelect
          label="Process"
          values={processes.map((process) => ({
            value: process.id,
            label: process.name,
          }))}
          minWidth={mobileView ? 156 : 231}
          disabled={isLoadingProcess}
          onChange={handleProcessSelect}
          selectedValue={!selectedProcess ? "" : `${selectedProcess?.id}`}
          sx={{ height: { xs: "56px", md: "50px" } }}
        />
      </Box>
      <InputField
        inputNumber={inputNumber}
        onChange={(e: string) => {
          const valid_value = e.replace(/[^0-9]/g, "");
          if (valid_value.toString().length <= 10) {
            setInputNumber(valid_value);
          }
        }}
        disabled={!selectedProcess}
        onCall={() => {
          if (validateMobileNumber(inputNumber) === true) {
            handleCall();
          } else {
            setIsCalling(false);
          }
        }}
        isCalling={isCalling}
        margin={mobileView ? "16px 0 24px" : "31px 0 41px"}
      />
      <Numpad
        setInputNumber={(value: string) =>
          setInputNumber((prev) => prev + value)
        }
        disabled={!selectedCampaign || !selectedProcess}
      />
    </Box>
  );
};
const DTMFHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "", md: "space-between" },
        alignItems: "center",
        gap: { xs: 2, md: 0 },
      }}>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: { xs: 700, md: 400 },
        }}>
        Manual
      </Typography>
      <Time
        color="primary.main"
        sx={{
          fontFamily: { xs: "Josefin Sans, sans", md: "Roboto, sans " },
          fontSize: { md: "32px", xs: "20px" },
          fontWeight: 700,
        }}
      />
    </Box>
  );
};

const DTMFPopup = ({ open, setOpen }: any) => {
  const callStatus = useAppSelector((state) => state.call.callStatus);
  const isCalling = !allowedCallingStates.includes(callStatus);
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <CustomPopup
      Header={DTMFHeader}
      open={open}
      setOpen={isCalling ? () => {} : setOpen}
      Component={DTMFContent}
      maxWidth={532}
      minWidth={532}
      minHeight={648}
      hideCloseButton={isCalling}
      divider={mobileView ? false : true}
      closeIconWidth={mobileView ? "19.3px" : "17.3px"}
      closeIconHeight={mobileView ? "19.3px" : "17.3px"}
      headerPaddingBottom={mobileView ? "8px" : "16px"}></CustomPopup>
  );
};
export default DTMFPopup;
