export enum FILTER_KEYS {
  filterAgent = "filter-agent",
  filterCampaign = "filter-campaign",
  filterDisposition = "filter-disposition",
  filterLeadset = "filter-leadset",
  filterProcess = "filter-process",
  filterName = "filter-name",
  filterPhone = "filter-phone",
  filterDisconnectedBy = "filter-disconnectedBy",
  filterStartAfter = "filter-startAfter",
  filterStartBefore = "filter-startBefore",
  filterDirection = "filter-direction",
}

export type TFilterForm = {
  [FILTER_KEYS.filterAgent]?: number;
  [FILTER_KEYS.filterName]: string;
  [FILTER_KEYS.filterPhone]: string;
  [FILTER_KEYS.filterCampaign]?: number;
  [FILTER_KEYS.filterProcess]?: number;
  [FILTER_KEYS.filterLeadset]?: number;
  [FILTER_KEYS.filterDisconnectedBy]: string;
  [FILTER_KEYS.filterStartAfter]: string | Date;
  [FILTER_KEYS.filterStartBefore]: string | Date;
  [FILTER_KEYS.filterDirection]: string;
  [FILTER_KEYS.filterDisposition]?: number;
};
