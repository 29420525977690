import { Box, IconButton, Typography } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../state/hooks";
import { UserAccessLevel } from "../../state/types/user";
import { CustomNavLink } from "./SideNavBar";

const SideNavItem = ({
  navLink,
  isExpanded,
  setExpanded,
  userAccessLevel,
  onNavigate = () => {},
}: {
  navLink: CustomNavLink;
  isExpanded: boolean;
  setExpanded: Function;
  userAccessLevel: string;
  onNavigate?: () => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.user.status);
  const isActive = useMemo(() => {
    if (navLink.link.paths?.includes(location.pathname)) return true;
    if (location.pathname == navLink.link.path) return true;
    else return false;
  }, [location]);
  if (!navLink.accessLevel.includes(userAccessLevel)) return <></>;
  if (!navLink.link.allowedStates?.includes(userState)) return <></>;
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p="10px 20px"
        onClick={() => {
          onNavigate();
          navigate(navLink.link.path);
          if (isExpanded) setExpanded(false);
        }}
        sx={{ ":hover": { cursor: "pointer" } }}
      >
        <Box display="flex" alignItems={"center"} flexGrow={1}>
          <IconButton
            sx={{
              height: 45,
              width: 45,
              backgroundColor: isActive ? "primary.main" : "transparent",
              ":hover": {
                backgroundColor: isActive ? "primary.main" : "none",
              },
            }}
          >
            <navLink.Icon
              color={isActive ? "white" : "#A3ACBA"}
              style={{
                fontSize: "25px",
                color: isActive ? "white" : "grey",
              }}
            />
          </IconButton>
          {isExpanded && (
            <Typography
              color={isActive ? "primary.main" : "#000"}
              sx={{
                marginLeft: "10px",
                fontWeight: 600,
                fontSize: { xs: "14px", md: "20px" },
                whiteSpace: "nowrap",
              }}
            >
              {navLink.link.title}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SideNavItem;
