import { createSlice } from "@reduxjs/toolkit";
import { campaignSlice } from "../campaign/campaignSlice";
import { leadSlice } from "../lead/leadSlice";
import { TCallbackState } from "../types/callback";
import { CallingMode } from "../types/defaultProcess";
import { CALLBACK_REDUCERS, TCallbackReducer } from "./callbackReducers";

const agent = {
  id: 0,
  clientId: 0,
  createdBy: 0,
  status: "",
  firstName: "",
  lastName: "",
  username: "",
  password: "",
  panCard: "",
  aadharCard: "",
  accessLevel: "",
  gender: "",
  email: "",
  mobile: "",
  pincode: "",
  nationality: "",
  bloodGroup: "",
  qualification: "",
  dob: "",
};

const process = {
  id: 0,
  clientId: 0,
  campaignId: 0,
  name: "",
  description: "",
  client: "",
  status: "",
  defaultLeadset: { id: 0, name: "" },
  processDetails: {
    processId: 0,
    running: false,
    callingMode: CallingMode.MANUAL,
    ratio: 0,
    startDateTime: "",
    endDateTime: "",
    remainingLeads: 0,
  },
  processAgents: [],
  processLeadsets: [],
  processDispositions: [],
  createdOn: "",
  createdBy: 0,
  updatedOn: "",
  updatedBy: 0,
};

const initialState = {
  id: 0,
  clientId: 0,
  leadId: 0,
  agentId: 0,
  dateTime: "",
  parentCallId: 0,
  Lead: leadSlice.getInitialState(),
  Agent: agent,
  ParentCall: {
    Process: process,
    Campaign: campaignSlice.getInitialState(),
    CallDetails: {
      id: 0,
      Disposition: {
        id: 0,
        clientId: 0,
        name: "",
        status: "",
        callback: false,
        level: 0,
        parentId: 0,
        children: [],
        createdOn: "",
        createdBy: 0,
        updatedOn: "",
        updatedBy: 0,
        disposeType: "",
      },
    },
  },
};

export const callbackSlice = createSlice<TCallbackState, TCallbackReducer>({
  name: "callback",
  initialState: initialState,
  reducers: CALLBACK_REDUCERS,
});
