import { URL_CONSTANTS } from "../shared/urls";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "./API";
import { PostCommentDTO } from "./dto/PostCommentDTO";
import { PostLeadDTO } from "./dto/PostLeadDTO";

function getLeads(
  onSuccess: any,
  onError: any,
  id: number | string,
  query: any
) {
  GetAPI.call(
    `${URL_CONSTANTS.GET_LEADSET_LEADS}/${id}`,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: query },
    onError
  );
}

function postLeads(onSuccess: any, onError: any, dto: any) {
  PostAPI.call(URL_CONSTANTS.POST_LEAD, dto, onSuccess, {}, onError);
}

function postBulkLeads(onSuccess: Function, onError: Function, dto: any) {
  PostAPI.call(URL_CONSTANTS.POST_BULK_LEADS, dto, onSuccess, {}, onError);
}

function deleteLead(onSuccess: any, onError: any, id: number | string) {
  DeleteAPI.call(`${URL_CONSTANTS.DELETE_LEAD}/${id}`, onSuccess, {}, onError);
}

function updateLead(
  onSuccess: any,
  onError: any,
  dto: PostLeadDTO,
  id: string
) {
  PatchAPI.call(URL_CONSTANTS.UPDATE_LEAD(id), dto, onSuccess, {}, onError);
}

function exportLeads(onSuccess: Function, onError: Function, query: any) {
  GetAPI.call(
    URL_CONSTANTS.EXPORT_LEADS,
    onSuccess,
    { responseType: "blob", params: query },
    onError
  );
}

function sampleLeads(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.SAMPLE_FILES,
    onSuccess,
    { responseType: "blob" },
    onError
  );
}

function getComments(
  onSuccess: Function,
  onError: Function,
  id: string,
  query?: any
) {
  GetAPI.call(
    URL_CONSTANTS.GET_COMMENTS(id),
    (data: any) => onSuccess(data.data),
    {
      params: query,
    },
    onError
  );
}

function addComment(
  onSuccess: Function,
  onError: Function,
  dto: PostCommentDTO,
  id: string
) {
  PostAPI.call(URL_CONSTANTS.POST_COMMENT(id), dto, onSuccess, {}, onError);
}

export const LEAD_SERVICE = {
  getLeads,
  postLeads,
  postBulkLeads,
  deleteLead,
  updateLead,
  exportLeads,
  sampleLeads,
  getComments,
  addComment,
};
