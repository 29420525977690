import { createSlice } from "@reduxjs/toolkit";
import { TCampaignState } from "../types/campaign";
import { CAMPAIGN_REDUCERS, TCampaignReducer } from "./campaignReducers";

export const campaignSlice = createSlice<TCampaignState, TCampaignReducer>({
  name: "campaign",
  initialState: {
    id: 0,
    clientId: 0,
    name: "",
    description: "",
    client: "",
    status: "",
    createdOn: "",
    createdBy: 0,
    updatedOn: "",
    updatedBy: 0,
    processes: [],
    supervisorId: 0,
  },
  reducers: CAMPAIGN_REDUCERS,
});
