import { Theme, useMediaQuery } from "@mui/material";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import EditProcessForm from "../components/ProcessDetails/Forms/EditProcessForm";

export const EditProcess = () => {
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <CustomPageHeading
        title="Edit Process"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <EditProcessForm />
    </>
  );
};
