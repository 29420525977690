import {
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import EditIcon from "../../assets/images/EditIcon";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { CAMPAIGN_SERVICE } from "../../services/CampaignService";
import { GetCampaignDTO } from "../../services/dto/GetCampaignDTO";
import { ALL_ROUTES } from "../../shared/routes";
import { campaignSlice } from "../../state/campaign/campaignSlice";
import { useAppDispatch } from "../../state/hooks";
import { TCampaignState } from "../../state/types/campaign";
import DisableCampaignPopUp from "../ConfirmationPopUP/DisableCampaignPopUp";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import DataNotFound from "../DataNotfound/DataNotFound";
import ToggleButton from "../EVCustoms/ToggleButton/ToggleButton";
import LightTooltip from "../LightToolTip";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import TableProgress from "../Table/TableProgress";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
      fontSize: 10,
    },
    "&:before": {
      left: 12,
      content: '"Disable"',
    },
    "&:after": {
      right: 12,
      content: '"Enable"',
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "25px" },
  { id: "name", label: "Name", allowSort: true, width: "189px" },
  { id: "description", label: "Description", allowSort: true, width: "189px" },
  { id: "client", label: "Client", allowSort: true, width: "189px" },
  { id: "status", label: "Status", allowSort: true, width: "189px" },
  // { id: "setting", label: "Setting", allowSort: false, width: "189px" },
  { id: "action", label: "Action", allowSort: false, width: "189px" },
];

const ManageCampaignsTable = ({
  campaigns,
  onAction,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  startIndex,
  loading,
}: {
  campaigns: GetCampaignDTO[];
  onAction: Function;
  order: "asc" | "desc";
  orderBy: string;
  setOrder: any;
  setOrderBy: any;
  startIndex: number;
  loading: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDisbaleCampaign, setOpenDisbaleCampaign] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<any>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const deleteCampaign = () => {
    const onSuccess = () => {
      setLoadingDelete(false);
      setOpenDeleteConfirmation(false);
      onAction();
    };
    const onError = (err: any) => {
      console.log(err);
      setLoadingDelete(false);
    };
    setLoadingDelete(true);
    CAMPAIGN_SERVICE.deleteCampaign(onSuccess, onError, selectedCampaign.id);
  };

  const handleCampaignStatus = () => {
    setLoadingStatus(true);
    if (selectedCampaign.status === "DISABLED") {
      CAMPAIGN_SERVICE.enableCampaign(
        () => {
          setOpenConfirmation(false);
          onAction();
          setLoadingStatus(false);
        },
        () => setLoadingStatus(false),
        selectedCampaign.id
      );
    } else {
      CAMPAIGN_SERVICE.disableCampaign(
        () => {
          setOpenDisbaleCampaign(false);
          onAction();
          setLoadingStatus(false);
        },
        () => setLoadingStatus(false),
        selectedCampaign.id
      );
    }
  };
  const handleEdit = (campaign: TCampaignState) => {
    dispatch(
      campaignSlice.actions.setCampaign(campaignSlice.getInitialState())
    );
    dispatch(campaignSlice.actions.setCampaign(campaign));
    navigate(ALL_ROUTES.EDIT_CAMPAIGN);
  };
  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor={theme.palette.primary.main}>
            {headCells.map((cell) => (
              <StyledTableCell
                key={cell.id}
                align={cell.id == "sr" ? "center" : "left"}
                width={cell.width}
              >
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {loading ? (
            <TableProgress colSpans={7} />
          ) : campaigns.length === 0 ? (
            <DataNotFound colSpans={7} />
          ) : (
            <TableBody>
              {campaigns.map((campaign, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    sx={{ width: headCells[0].width }}
                    align="center"
                  >
                    {startIndex == 0 && index < 9
                      ? "0" + (index + 1)
                      : startIndex + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: "#0066FF",
                      width: headCells[1].width,
                    }}
                    onClick={() => {
                      localStorage.setItem("campaign_name", campaign.name);
                      dispatch(campaignSlice.actions.setCampaign(campaign));
                      navigate(
                        generatePath(ALL_ROUTES.PROCESS_DETAILS, {
                          id: campaign.id.toString(),
                        })
                      );
                    }}
                  >
                    <LightTooltip title="Click to view process list">
                      <span>{campaign.name}</span>
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: headCells[2].width }}>
                    {campaign.description}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: headCells[3].width }}>
                    {campaign.client}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: headCells[4].width }}>
                    {/* <CustomActionButton
                      backgroundColor={
                        campaign.status === "DISABLED" ? "#5ECE62" : "#EB5757"
                      }
                      title={
                        campaign.status === "ENABLED" ? "Disable" : "Enable"
                      }
                      onClick={() => {
                        setSelectedCampaign(campaign);
                        if (campaign.status === "ENABLED")
                          setOpenDisbaleCampaign(true);
                        else setOpenConfirmation(true);
                      }}
                    /> */}
                    <ToggleButton
                      toggleStatus={campaign.status}
                      value_update={(status: string) => {
                        setSelectedCampaign(campaign);
                        if (campaign.status === "ENABLED")
                          setOpenDisbaleCampaign(true);
                        else setOpenConfirmation(true);
                      }}
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell sx={{ width: headCells[5].width }}>
                    <SettingsDropDown />
                  </StyledTableCell> */}
                  <StyledTableCell sx={{ width: headCells[5].width }}>
                    {campaign.status === "ENABLED" ? (
                      <LightTooltip title="Edit The Campaign Info.">
                        <IconButton onClick={() => handleEdit(campaign)}>
                          <EditIcon color="#5ECE62" />
                        </IconButton>
                      </LightTooltip>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setSelectedCampaign(campaign);
                          setOpenDeleteConfirmation(true);
                        }}
                      >
                        <LeadsetDeleteIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <DisableCampaignPopUp
        campaignName={selectedCampaign?.name}
        open={openDisbaleCampaign}
        setOpen={setOpenDisbaleCampaign}
        onClick={handleCampaignStatus}
        loading={loadingStatus}
      />
      <SimpleConfirmationPopUp
        value={selectedCampaign?.name}
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        onAction={handleCampaignStatus}
        action={"Enable"}
        loading={loadingStatus}
      />
      <SimpleConfirmationPopUp
        value={selectedCampaign?.name}
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onAction={deleteCampaign}
        action={"Delete"}
        loading={loadingDelete}
      />
    </>
  );
};

export default ManageCampaignsTable;
