import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import EditIcon from "../../assets/images/EditIcon";
import BirthdayIcon from "../../assets/images/lead-information/BirthdayIcon";
import GenderIcon from "../../assets/images/lead-information/GenderIcon";
import LocationIcon from "../../assets/images/lead-information/LocationIcon";
import { FORMATTER } from "../../helper/formatter";
import { TLeadState } from "../../state/types/lead";
import ContactDetailsPopUp from "../ContactDetails/ContactDetailsPopUp";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DarkTooltip } from "../DarkToolTip";

function getAge(date: Date) {
  const age = new Date().getFullYear() - date.getFullYear();
  return age;
}

const ContactInfo = ({
  lead,
  loader,
}: {
  lead: TLeadState;
  loader: boolean;
}) => {
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const theme = useTheme();
  const styledInfoText = {
    fontSize: { xs: "10px", md: "14px" },
    lineHeight: { xs: "15px", md: "18px" },
    pt: "5px",
  };
  const emptyChar = "-";
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            marginBottom: 3,
            opacity: 0.5,
            fontWeight: { xs: 500, md: 400 },
          }}
        >
          Personal Details
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <DarkTooltip title="Edit Lead Info" placement="top">
            <Button
              startIcon={<EditIcon color={theme.palette.primary.main} />}
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: { xs: 700, md: 400 },
                padding: 0,
                display: "flex",
                justifyContent: "end",
              }}
              onClick={() => setOpenContactDetails(true)}
            >
              Edit
            </Button>
          </DarkTooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: { sm: 3, xs: 2 },
          alignItems: "flex-start",
          flexWrap: { md: "nowrap", xs: "wrap" },
          justifyContent: "flex-start",
        }}
      >
        <img
          style={{ borderRadius: "50%" }}
          src="./images/contactProfileIcon/contactProfile.png"
          alt=""
        />
        <Box minWidth="145px">
          {loader === true ? (
            <>
              <Skeleton baseColor="rgba(0,0,0,0.2)" />
              <Typography
                sx={{
                  color: "#6F5FD9",
                  fontSize: 14,
                  fontWeight: { xs: 500, md: 400 },
                  textAlign: { sm: "left", xs: "left" },
                  marginTop: 0.7,
                  marginBottom: "3px",
                }}
              >
                {!lead.phone ? emptyChar : `+91 - ${lead.phone}`}
              </Typography>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Skeleton
                  style={{ marginBottom: "10px", marginRight: "10px" }}
                  baseColor="rgba(130,130,130,0.2)"
                  width={"30px"}
                />
                <Skeleton
                  style={{ marginBottom: "10px" }}
                  baseColor="rgba(130,130,130,0.2)"
                  width={"90px"}
                />
              </div>
              <Skeleton
                style={{ marginBottom: "10px" }}
                baseColor="rgba(239,208,83,0.2)"
              />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: { xs: 500, md: 400 },
                }}
              >
                {!lead.name ? emptyChar : lead.name}
              </Typography>
              <Typography
                sx={{
                  color: "#6F5FD9",
                  fontSize: 14,
                  fontWeight: { xs: 500, md: 400 },
                  textAlign: { sm: "left", xs: "left" },
                  marginTop: 0.7,
                }}
              >
                {!lead.phone ? emptyChar : `+91 - ${lead.phone}`}
              </Typography>
              <Typography
                sx={{
                  color: "gray",
                  fontSize: 14,
                  fontWeight: { xs: 500, md: 400 },
                  marginTop: 0.7,
                  textAlign: { sm: "left", xs: "left" },
                }}
              >
                {!lead.secondary ? emptyChar : `+91 - ${lead.secondary}`}
              </Typography>
              <Typography
                sx={{
                  color: "#EFD053",
                  fontSize: 14,
                  fontWeight: { xs: 500, md: 400 },
                  textAlign: { sm: "left", xs: "center" },
                  marginTop: 0.7,
                }}
              >
                {!lead.email ? emptyChar : lead.email}
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1.6, md: 2 },
            alignItems: "flex-start",
            flexWrap: { md: "nowrap", xs: "wrap" },
            justifyContent: { xs: "flex-end", md: "flex-start" },
          }}
        >
          <Box
            sx={{
              background: "rgba(111, 95, 217, 0.15)",
              padding: "15px",
              borderRadius: { xs: "8px", md: "16px" },
              textAlign: "center",
              minWidth: { xs: "58px", md: "90px" },
              minHeight: { xs: "66px", md: "68px" },
            }}
          >
            <BirthdayIcon />

            {loader === false ? (
              <Typography sx={{ ...styledInfoText }}>
                {!lead.dob ? "" : getAge(new Date(lead.dob)) + "Yrs"}
              </Typography>
            ) : (
              <div style={{ width: "100%" }}>
                <Skeleton
                  style={{ marginBottom: "10px" }}
                  baseColor="rgba(111, 95, 217, 0.2)"
                  width={"30px"}
                />
              </div>
            )}
            {loader === true ? (
              <Skeleton
                style={{ marginBottom: "10px" }}
                baseColor="rgba(111, 95, 217, 0.2)"
                width={"40px"}
              />
            ) : (
              <Typography sx={styledInfoText}>
                {!lead.dob
                  ? emptyChar
                  : FORMATTER.setZone(lead.dob).toFormat("DD")}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              background: "rgba(226, 96, 108, 0.15)",
              padding: "15px",
              borderRadius: { xs: "8px", md: "16px" },
              textAlign: "center",
              minWidth: { xs: "58px", md: "33px" },
              minHeight: { xs: "66px", md: "44px" },
            }}
          >
            <GenderIcon />
            {loader === true ? (
              <div style={{ width: "100%" }}>
                <Skeleton
                  style={{ marginBottom: "10px" }}
                  baseColor="rgba(226, 96, 108, 0.2)"
                  width={"40px"}
                />
              </div>
            ) : (
              <Typography sx={{ ...styledInfoText }} textTransform="capitalize">
                {!lead.gender ? emptyChar : lead.gender.toLowerCase()}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              background: "rgba(147, 206, 21, 0.15)",
              maxWidth: 188,
              borderRadius: "16px",
              textAlign: "center",
              pt: "15px",
              minWidth: { xs: "189px", md: "159px" },
              minHeight: { xs: "94px" },
            }}
          >
            <LocationIcon />
            {loader === true ? (
              <div style={{ width: "100%" }}>
                <Skeleton
                  count={2}
                  style={{ marginBottom: "10px" }}
                  baseColor="rgba(147, 206, 21, 0.2)"
                  width="80%"
                />
              </div>
            ) : (
              <Typography
                sx={{
                  ...styledInfoText,
                  pl: "15px",
                  pr: "15px",
                  pb: "15px",
                }}
                textAlign={{ xs: "center", md: "left" }}
              >
                {!lead.address ? emptyChar : lead.address}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {openContactDetails && (
        <ContactDetailsPopUp
          open={openContactDetails}
          setOpen={setOpenContactDetails}
          title="Update Details"
        />
      )}
    </Box>
  );
};

export default ContactInfo;
