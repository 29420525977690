function ReportsIcon({ color = "#7E8897" }: { color?: string }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7132 0.425822C12.9729 0.153172 13.3251 0 13.6923 0H18.6154C18.9826 0 19.3348 0.153172 19.5945 0.425822C19.8541 0.698472 20 1.06826 20 1.45385V19.5462C20 19.9317 19.8541 20.3015 19.5945 20.5742C19.3348 20.8468 18.9826 21 18.6154 21H1.38462C1.01739 21 0.665211 20.8468 0.405544 20.5742C0.145878 20.3015 0 19.9317 0 19.5462V15.4538C0 15.0683 0.145878 14.6985 0.405544 14.4258C0.665211 14.1532 1.01739 14 1.38462 14H6.15385V7.91539C6.15385 7.5298 6.29972 7.16001 6.55939 6.88736C6.81906 6.61471 7.17124 6.46154 7.53846 6.46154H12.3077V1.45385C12.3077 1.06826 12.4536 0.698472 12.7132 0.425822ZM13.8462 19.3846H18.4615V1.61538H13.8462V19.3846ZM12.3077 19.3846V8.07692H7.69231V19.3846H12.3077ZM6.15385 19.3846H1.53846V15.6154H6.15385V19.3846Z"
        fill={color}
      />
    </svg>
  );
}

export default ReportsIcon;
