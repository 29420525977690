import { BreakLogsDTO } from "../../services/dto/GetBreakLogsDTO";
import { CustomPopup } from "../Custom/CustomPopup";
import BreakLogsDetailTable from "./BreakLogsDetailTable";

export const BreakLogsDetailPopup = ({
  open,
  setOpen,
  selectedUser,
  startEndDate,
}: {
  open: boolean;
  setOpen: Function;
  selectedUser: BreakLogsDTO;
  startEndDate: any;
}) => {
  const BreakLogsDetailPopupContent = () => {
    return (
      <BreakLogsDetailTable
        selectedUser={selectedUser}
        startEndDate={startEndDate}
      />
    );
  };

  const BreakLogsDetailPopupHeader = () => {
    return <></>;
  };
  return (
    <CustomPopup
      divider={false}
      Header={BreakLogsDetailPopupHeader}
      open={open}
      setOpen={setOpen}
      Component={BreakLogsDetailPopupContent}
      maxWidth={800}
      minWidth="300px"
    />
  );
};
