function UserNameIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.5C8.01109 0.5 7.04439 0.793245 6.22215 1.34265C5.3999 1.89206 4.75904 2.67295 4.3806 3.58658C4.00216 4.50021 3.90315 5.50555 4.09607 6.47545C4.289 7.44536 4.7652 8.33627 5.46447 9.03553C6.16373 9.7348 7.05464 10.211 8.02455 10.4039C8.99445 10.5969 9.99979 10.4978 10.9134 10.1194C11.827 9.74096 12.6079 9.1001 13.1573 8.27785C13.7068 7.45561 14 6.48891 14 5.5C14 4.17392 13.4732 2.90215 12.5355 1.96447C11.5979 1.02678 10.3261 0.5 9 0.5ZM9 8.5C8.40666 8.5 7.82664 8.32405 7.33329 7.99441C6.83994 7.66476 6.45542 7.19623 6.22836 6.64805C6.0013 6.09987 5.94189 5.49667 6.05764 4.91473C6.1734 4.33279 6.45912 3.79824 6.87868 3.37868C7.29824 2.95912 7.83279 2.6734 8.41473 2.55764C8.99667 2.44189 9.59987 2.5013 10.1481 2.72836C10.6962 2.95542 11.1648 3.33994 11.4944 3.83329C11.8241 4.32664 12 4.90666 12 5.5C12 6.29565 11.6839 7.05871 11.1213 7.62132C10.5587 8.18393 9.79565 8.5 9 8.5ZM18 19.5V18.5C18 16.6435 17.2625 14.863 15.9497 13.5503C14.637 12.2375 12.8565 11.5 11 11.5H7C5.14348 11.5 3.36301 12.2375 2.05025 13.5503C0.737498 14.863 0 16.6435 0 18.5V19.5H2V18.5C2 17.1739 2.52678 15.9021 3.46447 14.9645C4.40215 14.0268 5.67392 13.5 7 13.5H11C12.3261 13.5 13.5979 14.0268 14.5355 14.9645C15.4732 15.9021 16 17.1739 16 18.5V19.5H18Z"
        fill="black"
      />
    </svg>
  );
}

export default UserNameIcon;
