import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../state/hooks";
import SideNavItem from "./SideNavItem";
import { SideNavMenu } from "./SideNavMenu";
import ProfileAvatar from "../Appbar/ProfileAvatar";
import AvatarIcon from "../../assets/images/AvatarIcon";
import { CustomNavLink } from "./SideNavBar";
import { useCallback, useEffect } from "react";
import { UserAccessLevel } from "../../state/types/user";

const DrawerMobile = ({ open, toggleDrawerSmall, navLinks, setOpen }: any) => {
  const { accessLevel } = useAppSelector((state) => state.user.userDetails);
  const userDetails = useAppSelector((state) => state.user.userDetails);

  return (
    <Drawer
      sx={{ "& .MuiDrawer-paper": { width: "360px" } }}
      open={open}
      onClose={toggleDrawerSmall}>
      <Box sx={{ overflowY: "auto", background: "white", pt: 6 }}>
        <Box display="flex" alignItems={"center"}>
          <Box flexGrow={1} pl="10px">
            <ProfileAvatar
              name={
                userDetails.firstName !== ""
                  ? userDetails.firstName
                  : userDetails.username
              }
              accessLevel={userDetails.accessLevel}
              avatarIcon={<AvatarIcon />}
            />
          </Box>
          <Typography fontSize={"20px"} fontWeight={700} p="0 20px">
            Menu
          </Typography>
        </Box>
        <List component="nav" sx={{ paddingTop: "60px" }}>
          {navLinks.map((link: CustomNavLink) =>
            link.children ? (
              <SideNavMenu
                userAccessLevel={accessLevel}
                isDrawerOpen={open}
                setDrawerOpen={setOpen}
                key={link.link.id}
                title={link.link.title}
                navLink={link}
              />
            ) : (
              <SideNavItem
                userAccessLevel={accessLevel}
                navLink={link}
                isExpanded={open}
                setExpanded={setOpen}
                key={link.link.id}
                onNavigate={link.link.onNavigate}
              />
            )
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerMobile;
