import { Typography, TypographyVariant } from "@mui/material";
import { FORMATTER } from "../../helper/formatter";

const CustomTime = ({
  timeInMillis,
  sx,
  variant = "h6",
  showMillis = false,
  ...rest
}: {
  timeInMillis: number;
  sx: object;
  variant?: TypographyVariant;
  showMillis?: boolean;
}) => {
  const formattedTime = FORMATTER.formatTimer(timeInMillis, showMillis);
  return (
    <Typography sx={sx} variant={variant} {...rest}>
      {formattedTime}
    </Typography>
  );
};

export default CustomTime;
