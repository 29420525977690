import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, useState } from "react";
import { FORMATTER } from "../../helper/formatter";
import { Gender } from "../../services/dto/GetUserResponseDTO";
import { LEAD_SERVICE } from "../../services/LeadService";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import { TLeadState } from "../../state/types/lead";
import FormActionButton from "../Actions/FormActionButton";
import CustomSelect from "../Custom/CustomSelect";
import CustomTextField from "../Custom/CustomTextField";
import DatePicker from "../EVCustoms/DatePicker/DatePicker";

const ContactDetails = ({
  setOpen,
  showTitle = true,
  onAction,
}: {
  setOpen: Function;
  showTitle?: boolean;
  onAction?: () => void;
}) => {
  const lead = useAppSelector((state) => state.lead);
  const [leadDTO, setLeadDTO] = useState({ ...lead });
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent).target;
    const { name } = (event as SelectChangeEvent).target;
    const { value } = event.target;
    setLeadDTO((prev) => ({
      ...prev,
      [id ?? name]: value,
    }));
  };

  const handleConfigurationChange = (event: any) => {
    const { id, value } = event.target;
    setLeadDTO((prev) => ({
      ...prev,
      configurations: {
        ...prev.configurations,
        [id]: value,
      },
    }));
  };

  function updateLead() {
    setLoading(true);
    const onSuccess = ({ data }: { data: TLeadState }) => {
      dispatch(leadSlice.actions.setLead(data));
      setLoading(false);
      setOpen(false);
      onAction && onAction();
    };
    const onError = (err: any) => {
      setLoading(false);
      console.log(err);
    };
    LEAD_SERVICE.updateLead(onSuccess, onError, leadDTO, leadDTO.id.toString());
  }

  const handleSubmit = () => {
    updateLead();
  };

  const gridItemLargePadding = { xs: "0px", md: "5px" };
  const gridItemSmallPadding = { xs: "8px", md: "5px" };
  const styledTitle = {
    fontSize: { xs: "14px", md: "18px" },
    fontWeight: { xs: 700, md: 600 },
  };

  const updateMasterState = (attName: string, value: Date) => {
    if (dayjs(value).isValid() == true) {
      if (attName === "leadDTO") {
        setLeadDTO((prev) => ({
          ...prev,
          dob: value.toISOString(),
        }));
      }
    }
  };

  return (
    <Box>
      <Box
        style={{ overflowY: "scroll", height: "275px", overflowX: "hidden" }}>
        {showTitle && (
          <Typography sx={styledTitle}>Personal Details</Typography>
        )}
        <Grid container pt="10px">
          <Grid item xs={12} md={6} pr={gridItemLargePadding}>
            <CustomTextField
              fullWidth
              label="Full Name"
              id="name"
              value={leadDTO.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6} pl={gridItemLargePadding}>
            <CustomTextField
              fullWidth
              label="Email"
              id="email"
              value={leadDTO.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6} pr={gridItemSmallPadding}>
            <CustomTextField
              disabled
              fullWidth
              label="Primary Contact"
              id="phone"
              value={leadDTO.phone}
            />
          </Grid>
          <Grid item xs={6} pl={gridItemSmallPadding}>
            <CustomTextField
              fullWidth
              label="Secondary Contact"
              id="secondary"
              value={leadDTO.secondary}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6} pr={gridItemSmallPadding}>
            {/* <CustomDatePicker
            selected={leadDTO.dob ? new Date(leadDTO.dob) : null}
            setSelected={(date: Date) => {
              setLeadDTO((prev) => ({
                ...prev,
                dob: date.toISOString(),
              }));
            }}
            label="DOB"
          /> */}
            <DatePicker
              title="DOB"
              value={leadDTO.dob ? new Date(leadDTO.dob) : null}
              attrName={"leadDTO"}
              value_update={updateMasterState}
              error_message="Please select DOB"
              warn_status={false}
              sx={{ height: "55px" }}
            />
          </Grid>
          <Grid item xs={6} pl={gridItemSmallPadding} pb="20px">
            <CustomSelect
              label="Gender"
              name="gender"
              values={Object.values(Gender)}
              fullWidth
              sx={{ height: "50px" }}
              selectedValue={leadDTO.gender || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              label="Address"
              id="address"
              value={leadDTO.address}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        {showTitle && <Typography sx={styledTitle}>Lead Details</Typography>}
        <Grid container pt={showTitle ? "10px" : "0"}>
          {leadDTO.configurations &&
            Object.entries(leadDTO.configurations).map(
              ([key, value], index) => (
                <Grid
                  item
                  key={key}
                  xs={6}
                  pl={{ xs: "8px", sm: index % 2 === 0 ? "0" : "5px" }}
                  pr={{ xs: "8px", sm: index % 2 === 0 ? "5px" : "0" }}>
                  <CustomTextField
                    fullWidth
                    label={key}
                    id={key}
                    value={value}
                    onChange={handleConfigurationChange}
                  />
                </Grid>
              )
            )}
          <Grid item xs={12}>
            <CustomTextField
              disabled
              fullWidth
              label="Actual Created"
              id="createdOn"
              value={
                !leadDTO.createdOn
                  ? ""
                  : FORMATTER.formatDateTime(leadDTO.createdOn)
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ marginTop: "20px" }}>
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={handleSubmit}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default ContactDetails;
