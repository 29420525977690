import { Box } from "@mui/material";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import "./UGTable.scss";

const UGTable = ({
  header,
  data,
  loader,
  uniqueID = "id",
  sx,
}: {
  header: GridColDef[];
  data: Array<any>;
  loader?: boolean;
  uniqueID?: string;
  sx?: any;
}) => {
  function generateRows(rows: Array<any>) {
    return rows.map((row, index) => {
      return {
        ...row,
        sr_no: index + 1,
      };
    });
  }

  const [sortedRows, setSortedRows] = useState(generateRows(data));

  const handleSort = (sortModel: GridSortModel) => {
    const sortField = sortModel[0]?.field;
    const sortDirection = sortModel[0]?.sort;

    // Sort the rows array based on the sort field and direction
    const sorted_rows = [...sortedRows].sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else if (sortDirection === "desc") {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
      return 0;
    });

    // Generate new rows data with serial numbers based on the sorted data
    const rowsWithSerialNo = generateRows(sorted_rows);

    // Set the sorted rows as the new state
    setSortedRows(rowsWithSerialNo);
  };

  useEffect(() => {
    setSortedRows(generateRows(data));
  }, [data]);

  return (
    <Box sx={{ height: "400px", width: "100%", ...sx }}>
      <DataGrid
        getRowId={(header) => header[uniqueID]}
        rows={sortedRows}
        getRowHeight={() => "auto"}
        columnHeaderHeight={44.5}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          color: "#000",
        }}
        columns={header.map((column) => {
          // Rename the first column to "Sr. No."
          if (column.field === "id") {
            return {
              ...column,
              field: "sr_no",
              headerName: "Sr. No.",
              width: 100,
            };
          }
          return column;
        })}
        loading={loader}
        onSortModelChange={handleSort}
      />
    </Box>
  );
};

export default UGTable;
