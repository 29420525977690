import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MissedCallCDRActions from "../../../components/MissedCallsCDR/MissedCallsCDRActions";
import MissedCallCDRTable from "../../../components/MissedCallsCDR/MissedCallsCDRTable";
import { SearchBar } from "../../../components/SearchBar";
import { ADMIN_SERVICES } from "../../../services/AdminServices";
import { useAppSelector } from "../../../state/hooks";

const MissedCallCDR = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [missed_call_logs_data, set_missed_call_logs_data] = useState<
    Array<any>
  >([]);
  const [search, setSearch] = useState("");
  const params = useAppSelector((state) => state.filterForm);

  const [missedCallLogsData, setMissedCallLogsData] = useState<Array<any>>([]);

  const handleSearch = (text: string) => {
    setSearch(text);
    if (text.toString().length > 0) {
      setMissedCallLogsData(
        missed_call_logs_data.filter(
          (item) =>
            (item?.firstName &&
              item.firstName.toLowerCase().includes(text.toLowerCase())) ||
            (item?.lastName &&
              item.lastName.toLowerCase().includes(text.toLowerCase())) ||
            (item?.username &&
              item.username.toLowerCase().includes(text.toLowerCase()))
        )
      );
    } else {
      setMissedCallLogsData(missed_call_logs_data);
    }
  };

  function getMissedCallCDRData() {
    const onSuccess = (data: any) => {
      setLoader(false);
      const response = data;
      const error_status = response.errorStatus;

      if (error_status === false) {
        const responseData = response.data;

        setMissedCallLogsData(responseData);
        set_missed_call_logs_data(responseData);
      } else {
        set_missed_call_logs_data([]);
        setMissedCallLogsData([]);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data = {};
    setLoader(true);
    ADMIN_SERVICES.getBreakLogs(onSuccess, onError, data);
  }

  useEffect(() => {
    // getMissedCallCDRData();
  }, [params]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
          <Typography variant="h5" marginRight={"20px"}>
            Missed Calls CDR
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <MissedCallCDRActions search={search} type="admin" />
      </Box>
      <Box>
        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            marginTop: "20px",
            width: "100%",
          }}>
          <MissedCallCDRTable loader={loader} data={missedCallLogsData ?? []} />
        </Box>
      </Box>
    </Box>
  );
};
export default MissedCallCDR;
