import { Box } from "@mui/material";
import ContactDetails from "../../ContactDetails/ContactDetailsContent";

const EditTab = () => {
  return (
    <Box mt="-10px" pb="30px">
      <ContactDetails setOpen={() => {}} showTitle={false} />
    </Box>
  );
};

export default EditTab;
