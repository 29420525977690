import { Theme, useMediaQuery } from "@mui/material";
import ClockHeader from "../components/ClockHeader";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import UserDetailsForm from "../components/UserDetails/UserDetailsForm";

const AddUser = () => {
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title="Create User"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <UserDetailsForm />
    </>
  );
};

export default AddUser;
