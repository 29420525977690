import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI } from "./API";

function getDashboardMetrices(onSuccess: any, onError: any) {
  GetAPI.call(URL_CONSTANTS.GET_DASHBOARD_METRICES, onSuccess, {}, onError);
}

export const DASHBOARD_SERVICE = {
  getDashboardMetrices,
};
