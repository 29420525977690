import { createSlice } from "@reduxjs/toolkit";
import { TLeadState } from "../types/lead";
import {
  PENDING_DISPOSITION_LEAD_REDUCERS,
  TPendingLeadReducer,
} from "./pendingDispositionLeadReducers";

export const pendingDispositionLeadSlice = createSlice<
  TLeadState,
  TPendingLeadReducer
>({
  name: "lead",
  initialState: {
    id: 0,
    name: "",
    email: "",
    phone: "",
    secondary: "",
    dob: "",
    gender: "",
    address: "",
    createdOn: "",
    leadsetId: 0,
    configurations: {},
  },
  reducers: PENDING_DISPOSITION_LEAD_REDUCERS,
});
