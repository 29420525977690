import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute";
import AgentLogger from "./pages/CDR/AgentLogger";
import CallbackLogger from "./pages/CallbackLogger";
import CallbackPreview from "./pages/CallbackPreview";
import CallScreen from "./pages/CallScreen";
import CreateCampaign from "./pages/CreateCampaign";
import CreateLead from "./pages/CreateLead";
import CreateLeadset from "./pages/CreateLeadset";
import CreateProcess from "./pages/CreateProcess";
import EditCampaign from "./pages/EditCampaign";
import EditLead from "./pages/EditLead";
import EditUserDetails from "./pages/EditUserDetails";
import LeadsetDetails from "./pages/LeadsetDetails";
import Login from "./pages/Login/Login";
import ManageCampaigns from "./pages/ManageCampaigns";
import ManageLeadset from "./pages/ManageLeadset";
import ManageUsers from "./pages/ManageUsers";
import ProcessDetails from "./pages/ProcessDetails";
import UploadLead from "./pages/UploadLead";
import { ALL_ROUTES } from "./shared/routes";
import store from "./state/Store";
import RecordingLog from "./pages/CDR/RecordingLog";
import AddUser from "./pages/AddUser";
import { AuthProvider } from "./components/AuthProvider";
import SocketProvider from "./components/SocketProvider";
import ManageDispose from "./pages/ManageDispose";
import DisposeLevelOneForm from "./components/Dispose/DisposeLevelOneForm";
import DisposeLevelTwoForm from "./components/Dispose/DisposeLevelTwoForm";
import DisposeLevelThreeForm from "./components/Dispose/DisposeLevelThreeForm";
import CallBackScreen from "./pages/CallbackScreen";
import Logout from "./pages/Logout";
import { EditProcess } from "./pages/EditProcess";
import CallProviderContainer from "./components/CallProviderContainer";
import ClientSocketProvider from "./components/ClientSocketProvider";
import RestrictedRoute from "./components/RestrictedRoute";
import { LoadingProvider } from "./components/LoadingProvider";
import AdminDashboard from "./pages/Dashboard/AdminDashboard";
import AgentDashboard from "./pages/Dashboard/AgentDashboard";
import ReadyScreen from "./pages/ReadyScreen";
import { NotFound } from "./pages/PrevState";
import AgentCDR from "./pages/CDR/AgentCDR";
import PerformanceGauge from "./pages/PerformanceGauge";
import ChangePassword from "./pages/ChangePassword";
import AllLeads from "./pages/AllLeads";
import BreakLogs from "./pages/BreakLogs";
import MissedCallCDR from "./pages/Admin/CDR/MissedCallCDR";
import QueueMetric from "./pages/Reports/QueueMetric/QueueMetric";

function App() {
  return (
    <>
      <Provider store={store}>
        <AuthProvider>
          <SocketProvider>
            <ClientSocketProvider>
              <CallProviderContainer>
                <LoadingProvider>
                  <Routes>
                    <Route path={ALL_ROUTES.LOGIN} element={<Login />} />
                    <Route path={ALL_ROUTES.LOGOUT} element={<Logout />} />
                    <Route element={<ProtectedRoute />}>
                      <Route
                        path={ALL_ROUTES.READY}
                        element={<ReadyScreen />}
                      />
                      <Route
                        path={ALL_ROUTES.AGENT_DASHBAORD}
                        element={<AgentDashboard />}
                      />
                      <Route
                        path={ALL_ROUTES.ADMIN_DASHBAORD}
                        element={<AdminDashboard />}
                      />
                      <Route path={ALL_ROUTES.CALL} element={<CallScreen />} />
                      <Route
                        path={ALL_ROUTES.PREVIEW}
                        element={<CallbackPreview />}
                      />
                      <Route
                        path={ALL_ROUTES.CALLBACKS}
                        element={<CallBackScreen />}
                      />
                      <Route
                        path={ALL_ROUTES.AGENT_CDR}
                        element={<AgentCDR />}
                      />
                      <Route
                        path={ALL_ROUTES.CHANGE_PASSWORD}
                        element={<ChangePassword />}
                      />
                      <Route
                        element={<RestrictedRoute accessLevels={["ADMIN"]} />}>
                        <Route
                          path={ALL_ROUTES.MANAGE_USERS}
                          element={<ManageUsers />}
                        />
                        <Route
                          path={ALL_ROUTES.CALL_BACK_LOGGER}
                          element={<CallbackLogger />}
                        />
                        <Route
                          path={ALL_ROUTES.PERFORMANCE_GAUGE}
                          element={<PerformanceGauge />}
                        />
                        <Route
                          path={ALL_ROUTES.BREAK_LOGS}
                          element={<BreakLogs />}
                        />
                        <Route
                          path={ALL_ROUTES.QUEUE_METRIC}
                          element={<QueueMetric />}
                        />
                        <Route
                          path={ALL_ROUTES.ALL_LEADS}
                          element={<AllLeads />}
                        />
                        <Route
                          path={ALL_ROUTES.EDIT_USER_DETAILS}
                          element={<EditUserDetails />}
                        />
                        <Route
                          path={ALL_ROUTES.ADD_USER}
                          element={<AddUser />}
                        />
                        <Route
                          path={ALL_ROUTES.MANAGE_CAMPAIGNS}
                          element={<ManageCampaigns />}
                        />
                        <Route
                          path={ALL_ROUTES.PROCESS_DETAILS}
                          element={<ProcessDetails />}
                        />
                        <Route
                          path={ALL_ROUTES.CREATE_CAMPAIGN}
                          element={<CreateCampaign />}
                        />
                        <Route
                          path={ALL_ROUTES.EDIT_CAMPAIGN}
                          element={<EditCampaign />}
                        />
                        <Route
                          path={ALL_ROUTES.AGENT_LOGGER}
                          element={<AgentLogger />}
                        />
                        <Route
                          path={ALL_ROUTES.MISSED_CDR}
                          element={<MissedCallCDR />}
                        />
                        <Route
                          path={ALL_ROUTES.AGENT_RECORDING}
                          element={<RecordingLog />}
                        />
                        <Route
                          path={ALL_ROUTES.CREATE_PROCESS}
                          element={<CreateProcess />}
                        />
                        <Route
                          path={ALL_ROUTES.EDIT_PROCESS}
                          element={<EditProcess />}
                        />
                        <Route
                          path={ALL_ROUTES.MANAGE_LEADSET}
                          element={<ManageLeadset />}
                        />
                        <Route
                          path={ALL_ROUTES.CREATE_LEADSET}
                          element={<CreateLeadset />}
                        />
                        <Route
                          path={ALL_ROUTES.LEADSET_DETAILS}
                          element={<LeadsetDetails />}
                        />
                        <Route
                          path={ALL_ROUTES.CREATE_LEAD}
                          element={<CreateLead />}
                        />
                        <Route
                          path={ALL_ROUTES.EDIT_LEAD}
                          element={<EditLead />}
                        />
                        <Route
                          path={ALL_ROUTES.UPLOAD_LEAD}
                          element={<UploadLead />}
                        />
                        <Route
                          path={ALL_ROUTES.MANAGE_DISPOSE}
                          element={<ManageDispose />}
                        />
                        <Route
                          path={ALL_ROUTES.LEVEL_ONE_DISPOSE}
                          element={<DisposeLevelOneForm />}
                        />
                        <Route
                          path={ALL_ROUTES.LEVEL_TWO_DISPOSE}
                          element={<DisposeLevelTwoForm />}
                        />
                        <Route
                          path={ALL_ROUTES.LEVEL_THREE_DISPOSE}
                          element={<DisposeLevelThreeForm />}
                        />
                      </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </LoadingProvider>
              </CallProviderContainer>
            </ClientSocketProvider>
          </SocketProvider>
        </AuthProvider>
      </Provider>
    </>
  );
}

export default App;
