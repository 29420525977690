const CommentsBoxIcon = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5265 21.9156C21.2899 21.9156 23.6722 21.9156 25.1514 20.4352C26.6318 18.956 26.6318 16.5737 26.6318 11.8103C26.6318 7.04697 26.6318 4.66466 25.1514 3.1855C23.6722 1.70508 21.2899 1.70508 16.5265 1.70508H11.4739C6.71055 1.70508 4.32823 1.70508 2.84907 3.1855C1.36865 4.66466 1.36865 7.04697 1.36865 11.8103C1.36865 16.5737 1.36865 18.956 2.84907 20.4352C3.67392 21.2613 4.77918 21.6263 6.42128 21.7868"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5266 21.9162C14.9653 21.9162 13.2449 22.5478 11.6748 23.3625C9.15099 24.6724 7.8891 25.328 7.26762 24.9099C6.64615 24.4931 6.76362 23.1983 6.99983 20.6101L7.05288 20.0215"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CommentsBoxIcon;
