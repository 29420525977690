import React, { useState } from "react";
import { Socket } from "socket.io-client";
import { ClientSocketContext } from "../shared/context/ClientSocketContext";

const ClientSocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [clientSocket, setClientSocket] = useState<Socket | null>(null);
  return (
    <ClientSocketContext.Provider
      value={{
        clientSocket: clientSocket,
        setClientSocket: setClientSocket,
      }}
    >
      {children}
    </ClientSocketContext.Provider>
  );
};

export default ClientSocketProvider;
