function ManageCampaignsIcon({ color = "white" }: { color?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.80833 16.6664C6.98089 16.1789 7.30031 15.7568 7.72265 15.4583C8.14499 15.1597 8.64947 14.9994 9.16667 14.9994C9.68386 14.9994 10.1883 15.1597 10.6107 15.4583C11.033 15.7568 11.3524 16.1789 11.525 16.6664H20V18.333H11.525C11.3524 18.8206 11.033 19.2427 10.6107 19.5412C10.1883 19.8397 9.68386 20 9.16667 20C8.64947 20 8.14499 19.8397 7.72265 19.5412C7.30031 19.2427 6.98089 18.8206 6.80833 18.333H0V16.6664H6.80833ZM11.8083 8.88892C11.9809 8.40137 12.3003 7.97929 12.7226 7.68076C13.145 7.38224 13.6495 7.22194 14.1667 7.22194C14.6839 7.22194 15.1883 7.38224 15.6107 7.68076C16.033 7.97929 16.3524 8.40137 16.525 8.88892H20V10.8333H16.525C16.3524 11.3208 16.033 11.7429 15.6107 12.0415C15.1883 12.34 14.6839 12.5003 14.1667 12.5003C13.6495 12.5003 13.145 12.34 12.7226 12.0415C12.3003 11.7429 11.9809 11.3208 11.8083 10.8333H0V8.88892H11.8083ZM3.475 1.66698C3.64755 1.17943 3.96698 0.757349 4.38931 0.458822C4.81165 0.160295 5.31614 0 5.83333 0C6.35053 0 6.85502 0.160295 7.27735 0.458822C7.69969 0.757349 8.01912 1.17943 8.19167 1.66698H20V3.33358H8.19167C8.01912 3.82113 7.69969 4.24321 7.27735 4.54174C6.85502 4.84027 6.35053 5.00056 5.83333 5.00056C5.31614 5.00056 4.81165 4.84027 4.38931 4.54174C3.96698 4.24321 3.64755 3.82113 3.475 3.33358H0V1.66698H3.475Z"
        fill={color}
      />
    </svg>
  );
}

export default ManageCampaignsIcon;
