import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BreakLogsTable from "../components/BreakLogs/BreakLogsTable";
import UGDateRangePicker from "../components/EVCustoms/UGDateRangePicker/UGDateRangePicker";
import { SearchBar } from "../components/SearchBar";
import { ADMIN_SERVICES } from "../services/AdminServices";
import { BreakLogsDTO } from "../services/dto/GetBreakLogsDTO";
import { useAppSelector } from "../state/hooks";
import { toDesiredFormat } from "../SupportingFiles/HelpingFunction";
const BreakLogs = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [break_logs_data, set_break_logs_data] = useState<Array<any>>([]);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [startEndDate, setStartEndDate] = useState([new Date(), new Date()]);
  const limit = 10;
  const params = useAppSelector((state) => state.filterForm);
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });

  const [brakeLogsData, setBrakeLogsData] = useState<Array<any>>([]);

  const handleSearch = (text: string) => {
    setSearch(text);
    if (text.toString().length > 0) {
      setBrakeLogsData(
        break_logs_data.filter(
          (item) =>
            (item?.firstName &&
              item.firstName.toLowerCase().includes(text.toLowerCase())) ||
            (item?.lastName &&
              item.lastName.toLowerCase().includes(text.toLowerCase())) ||
            (item?.username &&
              item.username.toLowerCase().includes(text.toLowerCase()))
        )
      );
    } else {
      setBrakeLogsData(break_logs_data);
    }
  };

  function getBreakLogsData() {
    const onSuccess = (data: any) => {
      setLoader(false);
      const response = data;
      const error_status = response.errorStatus;

      if (error_status === false) {
        const responseData = response.data;

        const flattenedArray = responseData.map((item: any) => {
          return {
            id: item.user.id,
            firstName: item.user.firstName,
            lastName: item.user.lastName,
            username: item.user.username,
            general: item.duration.general,
            lunch: item.duration.lunch,
            meeting: item.duration.meeting,
            tea: item.duration.tea,
            bio: item.duration.bio,
            refreshment: item.duration.refreshment,
            total: item.duration.total,
          };
        });
        setBrakeLogsData(flattenedArray);
        set_break_logs_data(flattenedArray);
      } else {
        set_break_logs_data([]);
        setBrakeLogsData([]);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data = {
      startDate:
        toDesiredFormat(startEndDate[0]).substring(0, 10) + "T00:00:00.000Z",
      endDate:
        toDesiredFormat(startEndDate[1]).substring(0, 10) + "T23:59:00.000Z",
    };
    setLoader(true);
    ADMIN_SERVICES.getBreakLogs(onSuccess, onError, data);
  }

  useEffect(() => {
    getBreakLogsData();
  }, [params, pagination.page, startEndDate]);

  useEffect(() => {
    const data = brakeLogsData || [];

    if (orderBy === "agenetName") {
      data.sort((a: BreakLogsDTO, b: BreakLogsDTO) => {
        const aValue = a?.user?.firstName ?? "";
        const bValue = b?.user?.firstName ?? "";
        if (aValue === bValue) {
          return 0;
        }
        if (order === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    } else {
      data.sort((a: BreakLogsDTO, b: BreakLogsDTO) => {
        const aValue = a?.duration?.[orderBy.toLocaleUpperCase()] ?? "";
        const bValue = b?.duration?.[orderBy.toLocaleUpperCase()] ?? "";
        if (aValue === bValue) {
          return 0;
        }
        if (order === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    }

    setBrakeLogsData(data);
  }, [orderBy, order]);

  useEffect(() => {
    const count =
      brakeLogsData?.length === undefined ? 0 : brakeLogsData?.length;
    const pages = 1;
    setPagination((prev) => ({
      ...prev,
      count,
      page: 1,
      endCount: count > 10 ? 10 : count,
      pages,
      startCount: 1,
    }));
  }, [brakeLogsData]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h5" marginRight={"20px"}>
            Break Logs
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <UGDateRangePicker value={startEndDate} setValue={setStartEndDate} />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          width: "100%",
        }}
      >
        <BreakLogsTable
          loader={loader}
          startEndDate={startEndDate}
          data={brakeLogsData ?? []}
          startIndex={(pagination.page - 1) * limit}
          recordingLogLoader={false}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Box>
    </Box>
  );
};
export default BreakLogs;
