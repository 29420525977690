import { URL_CONSTANTS } from "../shared/urls";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI, PutAPI } from "./API";
import { PostProcessDTO } from "./dto/PostProcessDTO";

function getProcesses(
  onSuccess: Function,
  onError: Function,
  id: string,
  query?: any
) {
  GetAPI.call(
    `${URL_CONSTANTS.GET_PROCESSESS}/${id}`,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: query },
    onError
  );
}

function postProcess(
  onSuccess: Function,
  onError: Function,
  dto: PostProcessDTO
) {
  PostAPI.call(URL_CONSTANTS.POST_PROCESS, dto, onSuccess, {}, onError);
}

function updateLeadsets(
  onSuccess: Function,
  onError: Function,
  id: string,
  dto: any
) {
  PatchAPI.call(
    `${URL_CONSTANTS.POST_PROCESS}/${id}/leadsets`,
    dto,
    onSuccess,
    {},
    onError
  );
}

function updateAgents(
  onSuccess: Function,
  onError: Function,
  id: string,
  dto: any
) {
  PatchAPI.call(
    `${URL_CONSTANTS.POST_PROCESS}/${id}/agents`,
    dto,
    onSuccess,
    {},
    onError
  );
}

function updateRatio(
  onSuccess: Function,
  onError: Function,
  id: string,
  dto: any
) {
  PatchAPI.call(
    `${URL_CONSTANTS.POST_PROCESS}/${id}/ratio`,
    dto,
    onSuccess,
    {},
    onError
  );
}

function enableProcess(onSuccess: Function, onError: Function, id: string) {
  PatchAPI.call(
    `${URL_CONSTANTS.POST_PROCESS}/${id}/enable`,
    {},
    onSuccess,
    {},
    onError
  );
}

function disableProcess(onSuccess: Function, onError: Function, id: string) {
  PatchAPI.call(
    `${URL_CONSTANTS.POST_PROCESS}/${id}/disable`,
    {},
    onSuccess,
    {},
    onError
  );
}

function updateState(
  onSuccess: Function,
  onError: Function,
  id: string,
  dto: any
) {
  PatchAPI.call(
    `${URL_CONSTANTS.POST_PROCESS}/${id}/running`,
    dto,
    onSuccess,
    {},
    onError
  );
}

function updateProcess(
  onSucess: Function,
  onError: Function,
  id: string,
  dto: PostProcessDTO
) {
  PutAPI.call(
    `${URL_CONSTANTS.POST_PROCESS}/${id}`,
    dto,
    onSucess,
    {},
    onError
  );
}

function exportProcesses(
  onSuccess: Function,
  onError: Function,
  campaignId: string,
  query?: any
) {
  GetAPI.call(
    URL_CONSTANTS.EXPORT_PROCESSES(campaignId),
    onSuccess,
    { responseType: "blob", params: query },
    onError
  );
}

function deleteProcess(
  onSuccess: Function,
  onError: Function,
  id: string | number
) {
  DeleteAPI.call(URL_CONSTANTS.DELETE_PROCESS(id), onSuccess, {}, onError);
}

export const PROCESS_SERVICE = {
  getProcesses,
  postProcess,
  updateLeadsets,
  updateAgents,
  enableProcess,
  disableProcess,
  updateRatio,
  updateState,
  updateProcess,
  deleteProcess,
  exportProcesses,
};
