import { PayloadAction } from "@reduxjs/toolkit";
import { TCallbackState } from "../types/callback";

function setCallback(
  state: TCallbackState,
  action: PayloadAction<TCallbackState>
) {
  return action.payload;
}

function updateCallback(
  state: TCallbackState,
  action: PayloadAction<{ key: string; value: string | number }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

export const CALLBACK_REDUCERS = {
  setCallback,
  updateCallback,
};

export type TCallbackReducer = typeof CALLBACK_REDUCERS;
