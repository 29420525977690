import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Typography } from "@mui/material";

const CreateTextField = styled((props: TextFieldProps) => (
  <TextField
    placeholder={props.placeholder}
    onChange={props.onChange}
    fullWidth
    InputProps={{
      disableUnderline: true,
      autoComplete: props.autoComplete || "off",
    }}
    {...props}
    sx={{
      color: "#000",
      backgroundColor: "#FFF",
    }}
  />
))(({ theme }) => ({}));
const CustomTextField = ({
  label,
  Icon,
  type,
  sx,
  required = false,
  error = false,
  errorText = "",
  multiline = false,
  setValue,
  value,
  marginBottom = "20px",
  placeholder,
  ...rest
}: any) => {
  const theme = useTheme();
  const handleValue = (e: any) => {
    // setValue(e.target.value);
  };
  return (
    <Box width="100%" marginBottom={marginBottom}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: error ? "1px solid red" : "1px solid rgba(0, 0, 0, 0.1)",
          paddingX: Icon && "15px",
          borderRadius: "5px",
          width: { sm: "100%", xs: "auto" },
          ...sx,
        }}>
        {Icon && <Icon sx={{ color: "black" }} />}
        {value ? (
          <CreateTextField
            multiline={multiline}
            rows={multiline ? 4 : 0}
            onChange={handleValue}
            required={required}
            value={value}
            label={label}
            type={type}
            id="custom-input"
            size="small"
            variant="filled"
            placeholder={placeholder}
            {...rest}
          />
        ) : (
          <CreateTextField
            multiline={multiline}
            rows={multiline ? 4 : 0}
            onChange={handleValue}
            required={required}
            label={label}
            type={type}
            id="custom-input"
            size="small"
            variant="filled"
            {...rest}
          />
        )}
      </Box>
      {error && (
        <Typography sx={{ fontSize: 12, color: theme.palette.error.main }}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

export default CustomTextField;
