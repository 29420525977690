import { List, ListItemText } from "@mui/material";
import ConfirmationPopUp from "./ConfirmationPopUp";

const DisableCampaignBody = () => {
  return (
    <List sx={{ px: 1 }}>
      <ListItemText>• This will forcefully STOP the campaign</ListItemText>
      <ListItemText>• User Mapping will be removed</ListItemText>
      <ListItemText>
        • Users won't be able to see this process in their list
      </ListItemText>
      <ListItemText>• Campaign will be Disabled</ListItemText>
    </List>
  );
};

const DisableCampaignPopUp = ({
  open,
  setOpen,
  campaignName,
  onClick,
  loading = false,
}: {
  open: boolean;
  setOpen: Function;
  campaignName: string;
  onClick: any;
  loading?: boolean;
}) => {
  return (
    <ConfirmationPopUp
      title="Unexpected bad things will happen if you don’t read this!"
      open={open}
      setOpen={setOpen}
      campaignName={campaignName}
      body={<DisableCampaignBody />}
      onClick={onClick}
      loading={loading}
    />
  );
};

export default DisableCampaignPopUp;
