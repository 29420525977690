function StopStatusIcon() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.4648 22.1122L16.1911 1.36674C15.9667 0.953295 15.6349 0.608082 15.2306 0.367529C14.8263 0.126975 14.3646 0 13.8942 0C13.4238 0 12.9621 0.126975 12.5578 0.367529C12.1535 0.608082 11.8217 0.953295 11.5973 1.36674L0.331728 22.1122C0.108952 22.5111 -0.00535925 22.9614 0.000193035 23.4183C0.00574532 23.8751 0.130967 24.3226 0.363371 24.7159C0.595776 25.1093 0.927242 25.4348 1.32472 25.6601C1.72219 25.8854 2.17178 26.0026 2.62866 26H25.1678C25.6201 25.9995 26.0644 25.8817 26.4575 25.6581C26.8506 25.4344 27.1789 25.1126 27.4104 24.7241C27.6418 24.3356 27.7685 23.8937 27.7781 23.4416C27.7876 22.9895 27.6797 22.5426 27.4648 22.1447V22.1122ZM12.8553 6.52065C12.8553 6.21928 12.975 5.93026 13.1881 5.71716C13.4012 5.50407 13.6902 5.38435 13.9916 5.38435C14.293 5.38435 14.582 5.50407 14.7951 5.71716C15.0082 5.93026 15.1279 6.21928 15.1279 6.52065V16.2603C15.1279 16.5617 15.0082 16.8507 14.7951 17.0638C14.582 17.2769 14.293 17.3966 13.9916 17.3966C13.6902 17.3966 13.4012 17.2769 13.1881 17.0638C12.975 16.8507 12.8553 16.5617 12.8553 16.2603V6.52065ZM13.9916 22.6317C13.7026 22.6317 13.4202 22.546 13.1799 22.3854C12.9397 22.2249 12.7524 21.9967 12.6419 21.7298C12.5313 21.4628 12.5023 21.1691 12.5587 20.8857C12.6151 20.6023 12.7542 20.342 12.9585 20.1377C13.1629 19.9333 13.4232 19.7942 13.7066 19.7378C13.99 19.6815 14.2837 19.7104 14.5507 19.821C14.8176 19.9315 15.0458 20.1188 15.2063 20.3591C15.3669 20.5993 15.4525 20.8818 15.4525 21.1707C15.4525 21.3626 15.4148 21.5525 15.3413 21.7298C15.2679 21.907 15.1603 22.0681 15.0246 22.2038C14.889 22.3394 14.7279 22.447 14.5507 22.5205C14.3734 22.5939 14.1834 22.6317 13.9916 22.6317Z"
        fill="white"
      />
    </svg>
  );
}

export default StopStatusIcon;
