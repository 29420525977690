import { Box, Typography } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import { SelectOption } from "../components/Custom/CustomSelect";
import PerformaceGaugeActions from "../components/PerformanceGauge/PerformanceGaugeActions";
import PerformaceGaugeTable from "../components/PerformanceGauge/PerformanceGaugeTable";
import { SearchBar } from "../components/SearchBar";
import TablePagination from "../components/Table/TablePagination";
import { PerformaceGaugeDTO } from "../services/dto/GetPerformanceGaugeDTO";
import { PERFORMACNE_GAUGE_SERVICE } from "../services/PerformanceGauageService";
import { useAppSelector } from "../state/hooks";
import { TFilterForm } from "../state/types/filterForm";

const PerformanceGauge = () => {
  let perfGaugeData: Array<PerformaceGaugeDTO>;
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("id");
  const limit = 10;
  const params = useAppSelector((state) => state.filterForm);
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });

  const [performanceGaugeData, setPerformanceGaugeData] =
    useState<Array<PerformaceGaugeDTO> | null>(null);
  const [agentList, setAgentList] = useState<Array<SelectOption>>([]);

  const loadAgentCDR = useCallback((text: string) => {
    setPerformanceGaugeData(
      perfGaugeData.filter(
        (item) =>
          (item.user.firstName &&
            item.user.firstName.toLowerCase().includes(text.toLowerCase())) ||
          (item.user.lastName &&
            item.user.lastName.toLowerCase().includes(text.toLowerCase())) ||
          (item.user.username &&
            item.user.username.toLowerCase().includes(text.toLowerCase()))
      )
    );
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadAgentCDR, 500), [
    loadAgentCDR,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getPerformanceGauageData() {
    const onSuccess = (data: any) => {
      const response = data;
      const error_status = response.errorStatus;
      const performanceGaugeData = response.data;

      const agentData: Array<SelectOption> = [];

      if (performanceGaugeData.length > 0) {
        perfGaugeData = performanceGaugeData;
        setPerformanceGaugeData(performanceGaugeData);

        perfGaugeData.map((value) =>
          agentData.push({
            label: value.user.username ?? "",
            value: value.user.username ?? "",
          })
        );

        getSearchData(params["filter-name"]);
      } else {
        perfGaugeData = [];
        setPerformanceGaugeData([]);
      }

      setAgentList(agentData);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    let data: TFilterForm = params;
    data = {
      ...data,
      "filter-startAfter": data["filter-startAfter"] + "Z",
      "filter-startBefore": params["filter-startBefore"] + "Z",
    };

    PERFORMACNE_GAUGE_SERVICE.getPerformanceGauge(
      onSuccess,
      onError,
      data["filter-startAfter"],
      data["filter-startBefore"]
    );
  }

  useEffect(() => {
    getPerformanceGauageData();
  }, [params, pagination.page]);

  useEffect(() => {
    const data = performanceGaugeData || [];

    if (orderBy === "agenetName") {
      data.sort((a: PerformaceGaugeDTO, b: PerformaceGaugeDTO) => {
        const aValue = a?.user?.firstName ?? "";
        const bValue = b?.user?.firstName ?? "";
        if (aValue === bValue) {
          return 0;
        }
        if (order === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    } else {
      data.sort((a: PerformaceGaugeDTO, b: PerformaceGaugeDTO) => {
        const aValue = a?.duration?.[orderBy.toLocaleUpperCase()] ?? "";
        const bValue = b?.duration?.[orderBy.toLocaleUpperCase()] ?? "";
        if (aValue === bValue) {
          return 0;
        }
        if (order === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    }

    setPerformanceGaugeData(data);
  }, [orderBy, order]);

  useEffect(() => {
    const count =
      performanceGaugeData?.length === undefined
        ? 0
        : performanceGaugeData?.length;
    const pages = 1;
    setPagination((prev) => ({
      ...prev,
      count,
      page: 1,
      endCount: count > 10 ? 10 : count,
      pages,
      startCount: 1,
    }));
  }, [performanceGaugeData]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h5" marginRight={"20px"}>
            Performance Gauge
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <PerformaceGaugeActions
          search={search}
          type="admin"
          agentList={agentList}
        />
      </Box>

      <Box>
        <Box sx={{ marginTop: "20px" }}>
          <PerformaceGaugeTable
            data={performanceGaugeData ?? []}
            startIndex={(pagination.page - 1) * limit}
            recordingLogLoader={false}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
        </Box>
        {/* <Box sx={{ display: { md: "none", xs: "block" } }}>
          <AgentLoggerTableMobile cdrs={agentCDRs?.cdrs ?? []} />
        </Box> */}
      </Box>
      <TablePagination page={pagination} setPage={setPagination} />
    </Box>
  );
};
export default PerformanceGauge;
