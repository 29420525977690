import { Box, Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import AppHeader from "./Appbar/AppHeader";
import SideNavBar from "./SideNavbar/SideNavBar";

const DashboardContainer = () => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawerSmall = () => {
    setOpen(!open);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <SideNavBar open={open} setOpen={setOpen} closeDrawer={closeDrawer} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}>
        <AppHeader open={open} onClickMenu={toggleDrawerSmall} />
        <Container
          sx={{
            mb: 4,
            minWidth: "calc(100% - 30px)",
          }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardContainer;
