import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";

export type RadioOptions = {
  label: string;
  value: string | number;
};

const CustomRadioButtonsGroup = ({
  selectedValue,
  id,
  onChange,
  error = false,
  values,
  title,
  sx,
  gap = 0,
}: {
  id?: string;
  selectedValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  values: RadioOptions[];
  title: string;
  sx?: React.CSSProperties;
  gap?: number;
}) => {
  const theme = useTheme();
  return (
    <FormControl sx={sx}>
      <FormLabel
        sx={{
          color: error ? theme.palette.error.main : theme.palette.common.black,
          lineHeight: "30px",
          "&.Mui-focused": {
            color: "black",
          },
        }}
      >
        {title}
      </FormLabel>
      <RadioGroup row value={selectedValue} onChange={onChange}>
        {values.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                    {
                      color: "#000000",
                    },
                  "& .MuiSvgIcon-root": {
                    fontSize: 15,
                  },
                }}
                id={id}
              />
            }
            label={option.label}
            sx={{
              pr: gap,
              "& .MuiTypography-root": {
                fontSize: "14px",
                lineHeight: "24px",
              },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioButtonsGroup;
