import { CircularProgress, Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import CallIconGreen from "../../assets/images/CallIconGreen";
import CustomIconButton from "../Custom/CustomIconButton";
import LightTooltip from "../LightToolTip";

const CallButton = ({
  sx,
  iconSize,
  onClick,
  disabled = false,
  isCalling = false,
}: {
  sx?: any;
  iconSize?: number;
  onClick?: () => void;
  disabled?: boolean;
  isCalling?: boolean;
}) => {
  const [calling, setCalling] = useState(false);
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const handleClick = () => {
    //setCalling(true);
    onClick && onClick();
  };

  return (
    <>
      {isCalling ? (
        <CircularProgress />
      ) : (
        <LightTooltip title="Call Now">
          <CustomIconButton
            Icon={CallIconGreen}
            IconSize={iconSize ? iconSize : 16}
            backgroundColor="#93CE15"
            disabled={disabled}
            sx={{
              width: mobileView ? 30 : 30,
              height: mobileView ? 30 : 30,
              ...sx,
            }}
            onClick={handleClick}
          />
        </LightTooltip>
      )}
    </>
  );
};

export default CallButton;
