import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";

const ClockHeader = () => {
  return null;
  const [dateTime, setDateTime] = useState(
    format(new Date(Date.now()), `EEEE. MMM dd.yyyy hh:mm:ss a`)
  );
  setInterval(() => {
    setDateTime(format(new Date(Date.now()), `EEEE. MMM dd.yyyy hh:mm:ss a`));
  }, 1000);
  return (
    <Box
      sx={{
        position: "fixed",
        top: "10px",
        left: "0",
        textAlign: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        zIndex: 1300,
      }}
    >
      <Typography
        sx={{
          lineHeight: "14px",
          fontFamily: "AdvancedPixel",
          fontSize: "8px",
        }}
      >
        {dateTime}
      </Typography>
    </Box>
  );
};

export default ClockHeader;
