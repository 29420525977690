import { Box, SelectChangeEvent, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import lodash from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toDesiredFormat } from "../../../SupportingFiles/HelpingFunction";
import { DISPOSITION_SERVICE } from "../../../services/DispositionService";
import { PROCESS_SERVICE } from "../../../services/ProcessService";
import { GetDispositionDTO } from "../../../services/dto/GetDispositionDTO";
import { PostProcessDTO } from "../../../services/dto/PostProcessDTO";
import {
  CallingMode,
  CallingModeAuto,
} from "../../../state/types/defaultProcess";
import FormActionButton from "../../Actions/FormActionButton";
import CustomMultiSelect from "../../Custom/CustomMultiSelect";
import CustomSelect, { selectOptionsMapper } from "../../Custom/CustomSelect";
import DatePicker from "../../EVCustoms/DatePicker/DatePicker";
import RKTextField from "../../EVCustoms/RKTextField/RKTextField";
import TimePicker from "../../EVCustoms/TimePicker/TimePicker";

const ProcessDetailsForm = ({
  submit_button_text = "Save",
}: {
  submit_button_text?: string;
}) => {
  const [disable, setDisable] = useState<boolean>(true);
  const navigate = useNavigate();
  const [dispositions, setDispositions] = useState<GetDispositionDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const startIndex = 0,
    limit = 3;
  const [startDate, setStartDate] = useState<Date | null | string>(null);
  const [endDate, setEndDate] = useState<Date | null | string>(null);
  const [startTime, setStartTime] = useState<Date | null | string>(null);
  const [endTime, setEndTime] = useState<Date | null | string>(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [processForm, setProcessForm] = useState<PostProcessDTO>({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    callingMode: "",
    campaignId: parseInt(id ?? ""),
    client: "",
    ratio: 1,
    dispositionIds: [],
  });
  const [validationError, setValidationError] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    callingMode: "",
    ratio: "",
    dispositionIds: "",
  });
  const mandatoryFields =
    processForm.callingMode == CallingModeAuto.AUTO
      ? [
          "name",
          "callingMode",
          "ratio",
          "startDate",
          "endDate",
          "dispositionIds",
          "description",
        ]
      : [
          "name",
          "callingMode",
          "startDate",
          "endDate",
          "dispositionIds",
          "description",
        ];

  useEffect(() => {
    const onSuccess = ({
      dispositions,
    }: {
      dispositions: GetDispositionDTO[];
    }) => setDispositions(dispositions);
    const onError = (err: any) => console.log(err);
    DISPOSITION_SERVICE.getAllDispositions(onSuccess, onError);
  }, []);

  useEffect(() => {
    let isValid = true;
    for (const [key, value] of Object.entries(processForm)) {
      if (!mandatoryFields.includes(key)) continue;
      if (value.toString() === "" || value.toString() === "0") {
        isValid = false;
      }
    }
    setDisable(!isValid);
  }, [processForm]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { name } = (event as SelectChangeEvent).target;
    const { value } = event.target;
    setProcessForm((prev) => ({
      ...prev,
      [id ?? name]: id === "ratio" ? parseInt(value) : value,
    }));
    if (event.target.value === "" || event.target.value === null) {
      if (mandatoryFields.includes(id ?? name)) {
        setValidationError((prev) => ({
          ...prev,
          [id ?? name]: "Error",
        }));
      } else {
        setValidationError((prev) => ({
          ...prev,
          [id ?? name]: "",
        }));
      }
    } else {
      setValidationError((prev) => ({
        ...prev,
        [id ?? name]: "",
      }));
    }
  };

  const handleDateTimeChange = (id: string, value: string) => {
    setProcessForm((prev) => ({
      ...prev,
      [id ?? name]:
        id === "ratio"
          ? value.length > 0 && parseInt(value)
            ? parseInt(value)
            : ""
          : value,
    }));
    setValidationError({ ...validationError, [id]: "" });
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(processForm)) {
      if (!mandatoryFields.includes(key)) continue;
      if (value.toString() === "" || value.toString() === "0") {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  const createProcess = () => {
    const onSuccess = (data: any) => {
      setLoading(false);
      navigate(-1);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    let data = processForm;
    data = {
      ...data,
      startDate: data.startDate + "Z",
      endDate: data.endDate + "Z",
    };
    PROCESS_SERVICE.postProcess(onSuccess, onError, data);
  };

  const handleSubmit = () => {
    if (validateForm()) createProcess();
  };

  const loadDipositions = useCallback((search: string) => {
    const onSuccess = ({
      dispositions,
    }: {
      dispositions: GetDispositionDTO[];
    }) => setDispositions(dispositions);
    const onError = (err: any) => console.log(err);
    DISPOSITION_SERVICE.getDispositions(onSuccess, onError, {
      startIndex,
      limit,
      search,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadDipositions, 500), [
    loadDipositions,
  ]);

  const handleSearch = (text: string) => {
    if (text === search) return;
    setSearch(text);
    getSearchData(text);
  };

  const handleSelectedDispositions = (value: any) => {
    const newDispositionds = value.map((item: any) => item.value);
    setProcessForm((prev) => ({
      ...prev,
      dispositionIds: newDispositionds,
    }));
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "name") {
      handleDateTimeChange("name", value);
    } else if (attrName === "description") {
      handleDateTimeChange("description", value);
    } else if (attrName === "ratio") {
      handleDateTimeChange("ratio", value);
    } else {
      const data = toDesiredFormat(value);
      if (attrName === setEndDate) {
        setEndDate(data);
        setEndTime(data);
        handleDateTimeChange("endDate", data);
      } else if (attrName === setStartDate) {
        setStartDate(data);
        setStartTime(data);
        handleDateTimeChange("startDate", data);
      } else if (attrName === setStartTime) {
        setStartTime(data);
        handleDateTimeChange("startDate", data);
      } else if (attrName === setEndTime) {
        setEndTime(data);
        handleDateTimeChange("endDate", data);
      } else if (attrName === "name") {
        handleDateTimeChange("name", data);
      }
    }
  };

  return (
    <>
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}>
          Please fill all fields
        </Typography>
      ) : null}
      <Grid container spacing={3} mt={3}>
        <Grid xs={12}>
          <span className={"red-asterisk mandatory"}>
            Please fill mandatory fields
          </span>
        </Grid>
        <Grid xs={6}>
          <RKTextField
            id="name"
            title="Process Name"
            attrName="name"
            value={processForm.name}
            value_update={updateMasterState}
            warn_status={false}
            mandatory={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            id="description"
            title="Description"
            attrName="description"
            value={processForm.description}
            value_update={updateMasterState}
            warn_status={false}
            mandatory={true}
          />
        </Grid>
        <Grid xs={3}>
          <DatePicker
            title="Start Date"
            value={startDate}
            attrName={setStartDate}
            value_update={updateMasterState}
            error_message="Please select Start Date"
            warn_status={validationError.startDate}
            mandatory={true}
          />
        </Grid>
        <Grid xs={3}>
          <DatePicker
            title="End Date"
            value={endDate}
            attrName={setEndDate}
            value_update={updateMasterState}
            error_message="Please select End Date"
            warn_status={validationError.endDate}
            mandatory={true}
          />
        </Grid>
        <Grid xs={3}>
          <TimePicker
            title="Start Time"
            value={startTime}
            attrName={setStartTime}
            value_update={updateMasterState}
            error_message="Please select Start Time"
            warn_status={validationError.startDate}
            mandatory={true}
          />
        </Grid>
        <Grid xs={3}>
          <TimePicker
            title="End Time"
            value={endTime}
            attrName={setEndTime}
            value_update={updateMasterState}
            error_message="Please select End Time"
            warn_status={validationError.endDate}
            mandatory={true}
          />
        </Grid>
        <Grid xs={12}>
          <CustomSelect
            error={Boolean(validationError.callingMode)}
            values={
              window.origin !== "https://unison.hellovent.com"
                ? Object.values(CallingModeAuto)
                : Object.values(CallingMode)
            }
            label="Calling Mode"
            fullWidth
            name="callingMode"
            onChange={handleInputChange}
            selectedValue={processForm.callingMode}
            mandatory={true}
          />
        </Grid>
        {processForm.callingMode === CallingModeAuto.AUTO ? (
          <Grid xs={12}>
            <RKTextField
              id="ratio"
              title="Ratio"
              attrName="ratio"
              value={processForm.ratio}
              value_update={updateMasterState}
              warn_status={false}
              mandatory={true}
            />
          </Grid>
        ) : null}
      </Grid>
      <CustomMultiSelect
        defaultValues={[]}
        options={selectOptionsMapper("name", "id", dispositions)}
        onChange={handleSelectedDispositions}
        onInputChange={handleSearch}
        label={"Select Dispose"}
        error={validationError.dispositionIds}
        mandatory={true}
      />
      <Box display="flex" gap={2} justifyContent="flex-start" p="30px 0">
        <FormActionButton
          title={submit_button_text}
          variant="contained"
          onClick={handleSubmit}
          loading={loading}
          disabled={disable}
        />
        <FormActionButton
          title="Cancel"
          onClick={() => navigate(-1)}
          disabled={loading}
        />
      </Box>
    </>
  );
};

export default ProcessDetailsForm;
