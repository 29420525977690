import { DOMAIN_CONSTANTS } from "./domain";

export const URL_CONSTANTS = {
  CLIENT_CONFIG: `${DOMAIN_CONSTANTS.BASE_URL}/client/domain`,
  POST_LOGIN: `${DOMAIN_CONSTANTS.BASE_URL}/login`,
  POST_USER: `${DOMAIN_CONSTANTS.BASE_URL}/users`,
  EDIT_USER: (id: string) => `${DOMAIN_CONSTANTS.BASE_URL}/users/${id}`,
  GET_USERS: `${DOMAIN_CONSTANTS.BASE_URL}/users`,
  GET_ACTIVE_USERS: `${DOMAIN_CONSTANTS.BASE_URL}/users/activeagent`,
  GET_PROCESS_DATA_AGAINST_USER: (id: number) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/users/${id}/process`,
  GET_DELETED_USERS: `${DOMAIN_CONSTANTS.BASE_URL}/users/deleted`,
  LOGOUT: `${DOMAIN_CONSTANTS.BASE_URL}/logout`,
  ENABLE_USER: `${DOMAIN_CONSTANTS.BASE_URL}/users/enable`,
  DISABLE_USER: `${DOMAIN_CONSTANTS.BASE_URL}/users/disable`,
  LOGOUT_USER: `${DOMAIN_CONSTANTS.BASE_URL}/logout`,
  LOGOUT_USERS: `${DOMAIN_CONSTANTS.BASE_URL}/users/logout`,
  DELETE_USER: (id: string) => `${DOMAIN_CONSTANTS.BASE_URL}/users/${id}`,
  RESTORE_USER: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/users/${id}/restore`,
  EXPORT_USERS: `${DOMAIN_CONSTANTS.BASE_URL}/users/export`,
  GET_DASHBOARD_METRICES: `${DOMAIN_CONSTANTS.BASE_URL}/dashboard/metrices`,
  GET_LEADSETS: `${DOMAIN_CONSTANTS.BASE_URL}/leadsets`,
  GET_ALL_LEADSETS: `${DOMAIN_CONSTANTS.BASE_URL}/leadsets/processleadsets`,
  EXPORT_LEADSETS: `${DOMAIN_CONSTANTS.BASE_URL}/leadsets/export`,
  GET_LEADSET_LEADS: `${DOMAIN_CONSTANTS.BASE_URL}/leads/leadsets`,
  POST_LEAD: `${DOMAIN_CONSTANTS.BASE_URL}/leads`,
  EXPORT_LEADS: `${DOMAIN_CONSTANTS.BASE_URL}/leads/export`,
  SAMPLE_FILES: `${DOMAIN_CONSTANTS.BASE_URL}/leads/download`,
  UPDATE_LEAD: (id: string) => `${DOMAIN_CONSTANTS.BASE_URL}/leads/${id}`,
  DELETE_LEAD: `${DOMAIN_CONSTANTS.BASE_URL}/leads`,
  POST_BULK_LEADS: `${DOMAIN_CONSTANTS.BASE_URL}/leads/upload`,
  GET_CAMPAIGNS: `${DOMAIN_CONSTANTS.BASE_URL}/campaigns`,
  UPDATE_CAMPAIGN: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/campaigns/${id}`,
  EXPORT_CAMPAIGNS: `${DOMAIN_CONSTANTS.BASE_URL}/campaigns/export`,
  GET_PROCESSESS: `${DOMAIN_CONSTANTS.BASE_URL}/process/campaign`,
  POST_PROCESS: `${DOMAIN_CONSTANTS.BASE_URL}/process`,
  EXPORT_PROCESSES: (campaignId: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/process/campaign/${campaignId}/export`,
  DELETE_PROCESS: (id: string | number) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/process/${id}`,
  AGENT_DASHBOARD_BREAK_STATS: `${DOMAIN_CONSTANTS.BASE_URL}/agent-dashboard/break-stats`,
  AGENT_DASHBOARD_CALL_STATS: `${DOMAIN_CONSTANTS.BASE_URL}/agent-dashboard/call-stats`,
  AGENT_DASHBOARD_TODAY_TIMES: `${DOMAIN_CONSTANTS.BASE_URL}/agent-dashboard/today-times`,
  AGENT_DASHBOARD_TODAY_ALERTS: `${DOMAIN_CONSTANTS.BASE_URL}/agent-dashboard/today-alerts`,
  GET_USER_DETAILS: `${DOMAIN_CONSTANTS.BASE_URL}/users/default/process`,
  GET_DISPOSITION: `${DOMAIN_CONSTANTS.BASE_URL_TWO}/disposition`,
  GET_ALL_DISPOSITION: `${DOMAIN_CONSTANTS.BASE_URL_TWO}/disposition/agentprocessdispostions`,
  DELETE_DISPOSITION: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/disposition/${id}`,
  REFRESH_TOKEN: `${DOMAIN_CONSTANTS.BASE_URL}/refresh`,
  DISPOSE_CALL: (callId: string | number) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/call/${callId}/dispose`,
  GET_PENDING_DISPOSITIONS: `${DOMAIN_CONSTANTS.BASE_URL}/call/pendingDisposition`,
  GET_CALLBACKS: `${DOMAIN_CONSTANTS.BASE_URL}/callbacks`,
  GET_MISSED_CALLBACKS: `${DOMAIN_CONSTANTS.BASE_URL_TWO}/callbacks/missed`,
  GET_UPCOMING_CALLBACKS: `${DOMAIN_CONSTANTS.BASE_URL_TWO}/callbacks/upcoming`,
  CALL_NEXT_CALLBACK: `${DOMAIN_CONSTANTS.BASE_URL}/callbacks/callNext`,
  CALL_CALLBACK: (id: string | number) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/callbacks/${id}/call`,
  ASSIGN_CALLBACKS: `${DOMAIN_CONSTANTS.BASE_URL}/callbacks/assign`,
  EDIT_CALLBACK: (id: string | number) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/callbacks/${id}`,
  CREATE_MANUAL_CALL: `${DOMAIN_CONSTANTS.BASE_URL}/call/manual`,
  REDIAL_CALL: `${DOMAIN_CONSTANTS.BASE_URL}/call/redial`,
  GET_RECORDINGS: `${DOMAIN_CONSTANTS.BASE_URL}/recording`,
  POST_COMMENT: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/leads/${id}/comments`,
  GET_COMMENTS: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/leads/${id}/comments`,
  GET_PREVIEW_NEXT_LEAD: `${DOMAIN_CONSTANTS.BASE_URL}/preview/next`,
  CALL_PREVIEW_LEAD: `${DOMAIN_CONSTANTS.BASE_URL}/preview/call`,
  GET_CALL_DETAILS: `${DOMAIN_CONSTANTS.BASE_URL}/call/getCallDetails/new`,
  GET_AGENT_CDR: `${DOMAIN_CONSTANTS.BASE_URL}/call/agent-cdr`,
  GET_ALL_CDR: `${DOMAIN_CONSTANTS.BASE_URL}/call/cdr`,
  EXPORT_AGENT_LOGGER: `${DOMAIN_CONSTANTS.BASE_URL}/call/cdr/export`,
  GET_CAMPAIGNS_MANUAL: `${DOMAIN_CONSTANTS.BASE_URL_TWO}/campaigns/manual`,
  EXPORT_CDR: `${DOMAIN_CONSTANTS.BASE_URL}/call/cdr/export`,
  EXPORT_RECORDINGS: `${DOMAIN_CONSTANTS.BASE_URL}/recording/export`,
  RESET_USER_PASSWORD_BY_ADMIN: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/users/${id}/password/reset`,
  DISCONNECT_CALL: `${DOMAIN_CONSTANTS.BASE_URL}/call/hangup`,
  PERFORMANCE_GAUGE: (startDate: string | Date, endDate: string | Date) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/reports/gauge?startDate=${startDate}&endDate=${endDate}`,
  EXPORT_PERFOMANCE_GAUGE: `${DOMAIN_CONSTANTS.BASE_URL}/reports/gauge/export`,
  CHANGE_PASSWORD: `${DOMAIN_CONSTANTS.BASE_URL}/users/changepassword`,
  SKIP_PREVIEW_LEAD: `${DOMAIN_CONSTANTS.BASE_URL}/preview/skip`,
  ACTIVE_CALL: `${DOMAIN_CONSTANTS.BASE_URL}`,
  DELETE_USER_FROM_PROCESS: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/process/${id}/agents`,
  GET_DISPOSITION_LIST_BY_PROCESS_ID: (id: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/disposition/${id}/process`,
  CHURN_LEADS: (PROCESS_ID: string) =>
    `${DOMAIN_CONSTANTS.BASE_URL}/leads/${PROCESS_ID}/churn`,
  AGENT_DASHBOARD_LEADS_TAKEN: `${DOMAIN_CONSTANTS.BASE_URL}/agent-dashboard/productivity`,
  ADMIN_DASHBOARD_OVERVIEW: `${DOMAIN_CONSTANTS.BASE_URL}/admin-dashboard`,
  BREAK_LOGS: `${DOMAIN_CONSTANTS.BASE_URL}/reports/breaklogs`,
  BREAK_LOGS_BY_AGENT: `${DOMAIN_CONSTANTS.BASE_URL}/reports/breaklogs/agent`,
  UPDATE_USER_TABLE_DAILY_SUMMARY_STATUS: `${DOMAIN_CONSTANTS.BASE_URL}/users/emailsend/status`,
  GET_PROFILE_DETAILS: `${DOMAIN_CONSTANTS.BASE_URL}/users/myprofile`,
  UPDATE_SHORTCUT_STATUS: `${DOMAIN_CONSTANTS.BASE_URL}/users/shortcutstatus`,
};
