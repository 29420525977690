function AverageHandilingIcon() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4 0C23.6852 0 30.4 6.71484 30.4 15C30.4 23.2852 23.6852 30 15.4 30C12.2184 30 9.2594 29.0039 6.82776 27.3105C5.97815 26.666 5.76956 25.5469 6.36487 24.6973C6.95667 23.8477 8.12268 23.6426 8.97229 24.2344C10.7946 25.5059 13.0094 26.25 15.4 26.25C21.611 26.25 26.65 21.2109 26.65 15C26.65 8.73633 21.611 3.75 15.4 3.75C12.2418 3.75 9.48206 5.00742 7.44299 7.04297L9.24768 8.84766C10.1324 9.73242 9.50549 11.25 8.25745 11.25H1.80627C1.02991 11.25 0.400024 10.623 0.400024 9.84375V3.39492C0.400024 2.14219 1.91467 1.51465 2.80061 2.40059L4.79338 4.39336C7.50745 1.68105 11.2574 0 15.3473 0H15.4ZM15.4 7.5C16.1793 7.5 16.8063 8.12695 16.8063 8.90625V14.4199L20.5621 18.2227C21.1598 18.7734 21.1598 19.6641 20.5621 20.1621C20.0641 20.7598 19.1735 20.7598 18.6227 20.1621L14.4039 15.9434C14.1403 15.7324 13.9938 15.375 13.9938 15V8.90625C13.9938 8.12695 14.6207 7.5 15.4 7.5Z"
        fill="#6F5FD9"
      />
    </svg>
  );
}

export default AverageHandilingIcon;
