import { Theme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClockHeader from "../components/ClockHeader";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import LeadsetForm from "../components/Leadset/LeadsetForm";

const CreateLeadset = () => {
  const navigate = useNavigate();
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title="Create Leadset"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <LeadsetForm />
    </>
  );
};

export default CreateLeadset;
