import React from "react";

function RedialIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3317 0C11.1548 0 10.9852 0.0702663 10.8601 0.195341C10.735 0.320415 10.6648 0.490053 10.6648 0.666935C10.6648 0.843817 10.735 1.01345 10.8601 1.13853C10.9852 1.2636 11.1548 1.33387 11.3317 1.33387H13.7233L9.52697 5.53156C9.46327 5.59308 9.41246 5.66667 9.37751 5.74804C9.34256 5.82941 9.32416 5.91692 9.32339 6.00548C9.32262 6.09404 9.33949 6.18186 9.37303 6.26382C9.40656 6.34579 9.45608 6.42025 9.5187 6.48287C9.58132 6.54549 9.65579 6.59501 9.73775 6.62855C9.81972 6.66208 9.90754 6.67895 9.99609 6.67818C10.0846 6.67742 10.1722 6.65902 10.2535 6.62406C10.3349 6.58911 10.4085 6.5383 10.47 6.4746L14.6664 2.27692V4.66854C14.6664 4.84543 14.7366 5.01506 14.8617 5.14014C14.9868 5.26521 15.1564 5.33548 15.3333 5.33548C15.5102 5.33548 15.6798 5.26521 15.8049 5.14014C15.93 5.01506 16.0002 4.84543 16.0002 4.66854V0.666935C16.0002 0.490053 15.93 0.320415 15.8049 0.195341C15.6798 0.0702663 15.5102 0 15.3333 0H11.3317ZM6.08959 1.34187C5.83821 0.813702 5.3977 0.399158 4.85524 0.180299C4.31279 -0.0385597 3.70794 -0.0457814 3.16041 0.160064L2.8096 0.292118C1.00888 0.96839 -0.268968 2.7331 0.0484935 4.74191C0.324604 6.50261 0.910173 8.84222 2.16534 10.811C3.29913 12.5904 4.80374 14.055 6.05758 15.0981C7.73158 16.4893 10.0712 16.1865 11.5731 14.7686L11.968 14.3951C12.1918 14.1837 12.3717 13.93 12.4971 13.6488C12.6225 13.3675 12.691 13.0642 12.6987 12.7563C12.7064 12.4485 12.6531 12.1421 12.5419 11.855C12.4307 11.5678 12.2637 11.3055 12.0507 11.0831L11.0169 10.0027C10.7703 9.74557 10.4596 9.55895 10.1167 9.4621C9.77392 9.36526 9.41145 9.3617 9.06679 9.4518L6.56311 10.1054C6.11993 9.69808 5.73884 9.22801 5.43199 8.71017C5.13303 8.19216 4.90639 7.63566 4.75839 7.05617L6.51376 5.14073C6.78303 4.84707 6.95848 4.4797 7.01766 4.08569C7.07683 3.69168 7.01702 3.28898 6.84589 2.92918L6.08959 1.34187Z"
        fill="#5ECE62"
      />
    </svg>
  );
}

export default RedialIcon;
