import { createSlice } from "@reduxjs/toolkit";
import { leadSlice } from "../lead/leadSlice";
import { CallStatus, TCallState } from "../types/call";
import {
  PENDING_DISPOSITION_CALL_REDUCERS,
  TPenidngCallReducer,
} from "./pendingDispositionCallReducers";

export const pendingDispositionCallSlice = createSlice<
  TCallState,
  TPenidngCallReducer
>({
  name: "pendingCall",
  initialState: {
    callId: 0,
    lead: leadSlice.getInitialState(),
    processId: 0,
    callCode: "",
    callStatus: CallStatus.IDLE,
    callOutCome: "",
    call_type: "",
  },
  reducers: PENDING_DISPOSITION_CALL_REDUCERS,
});
