import { Box, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CallEndIcon from "../../assets/images/CallEndIcon";
import CallIconBlue from "../../assets/images/CallIconBlue";
import TimerIcon from "../../assets/images/TimerIcon";
import { AGENT_DASHBOARD_SERVICE } from "../../services/AgentDashboardService";
import { GetCallStatsDTO, TCallStat } from "../../services/dto/GetCallStatsDTO";
import CustomTime from "../Custom/CustomTime";
import CallingStatusTile from "./CallingStatusTile";
import Skeleton from "react-loading-skeleton";

export type TCallingStat = {
  value: number;
  title: string;
  key: keyof GetCallStatsDTO;
  icon: React.ReactNode;
  stats: TCallStat;
};

const CallingStat = ({
  callingStat,
  loader,
}: {
  callingStat: TCallingStat;
  loader: boolean;
}) => {
  return (
    <>
      <Box key={callingStat.key} display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems={"center"}
          maxHeight={{ xs: "60px", md: "60px" }}
          maxWidth={{ xs: "60px", md: "60px" }}
          pr={"12px"}
        >
          {callingStat.icon}
        </Box>
        <ListItemText>
          {callingStat.key === "count" ? (
            loader === true ? (
              <Skeleton
                style={{ marginBottom: "10px" }}
                baseColor="rgba(0, 0, 0, 0.2)"
                width={"90px"}
                height={22}
              />
            ) : (
              <Typography
                variant="h6"
                sx={{
                  fontWeight: { xs: 700, md: 600 },
                  fontSize: { xs: "20px", md: "22px" },
                  marginTop: { xs: "-4px", md: "0px" },
                }}
              >
                {callingStat.value}
              </Typography>
            )
          ) : loader === true ? (
            <Skeleton
              style={{ marginBottom: "10px" }}
              baseColor="rgba(0, 0, 0, 0.2)"
              width={"90px"}
              height={22}
            />
          ) : (
            <CustomTime
              variant="h6"
              sx={{
                fontWeight: { xs: 700, md: 600 },
                fontSize: { xs: "20x", md: "22px" },
                marginTop: { xs: "-4px", md: "0px" },
              }}
              timeInMillis={callingStat.value < 0 ? 0 : callingStat.value}
            />
          )}
          <Typography
            sx={{
              marginTop: "-4px",
              color: "#96A1B2",
              fontSize: { xs: "12px", md: "16px" },
              fontFamily: {
                xs: "Roboto, san-serif",
                md: "Josefin sans, san-serif",
              },
            }}
          >
            {callingStat.title}
          </Typography>
        </ListItemText>
      </Box>
      <CallingStatusTile
        stat={callingStat.stats}
        showCount={callingStat.key === "count"}
        loader={loader}
      />
    </>
  );
};

const CallingStats = () => {
  const [callingStats, setCallingStats] = useState<TCallingStat[]>([
    {
      value: 0,
      title: "Total Talktime",
      key: "total",
      icon: <CallIconBlue />,
      stats: {
        manual: 0,
        callbacks: 0,
        newLeads: 0,
      },
    },
    {
      value: 0,
      title: "Average Time",
      key: "avg",
      icon: <TimerIcon />,
      stats: {
        manual: 0,
        callbacks: 0,
        newLeads: 0,
      },
    },
    {
      value: 0,
      title: "Total Calls",
      key: "count",
      icon: <CallEndIcon />,
      stats: {
        manual: 0,
        callbacks: 0,
        newLeads: 0,
      },
    },
  ]);
  const [loader, setLoader] = useState<boolean>(true);

  function getCallingStats() {
    const onSuccess = (data: any) => {
      setLoader(false);
      setCallingStats((prev) =>
        prev.map((stat) => ({
          ...stat,
          value: data[stat.key][stat.key] ?? 0,
          stats: {
            manual: data[stat.key].manual,
            callbacks: data[stat.key].callbacks,
            newLeads: data[stat.key].newLeads,
          },
        }))
      );
    };
    const onError = (error: any) => {
      console.log(error);
      setLoader(false);
    };

    setLoader(true);
    AGENT_DASHBOARD_SERVICE.getCallStats(onSuccess, onError);
  }

  useEffect(() => {
    getCallingStats();
  }, []);

  return (
    <Box>
      {callingStats.map((callingStat, index: number) => (
        <Box
          key={callingStat.title}
          sx={{
            borderBottom:
              index === callingStats.length - 1
                ? "none"
                : { xs: "none", md: "1px solid rgba(0,0,0,0.1)" },
            pb: "20px",
            mb: { xs: "4px", md: "10px" },
          }}
        >
          <CallingStat callingStat={callingStat} loader={loader} />
        </Box>
      ))}
    </Box>
  );
};

export default CallingStats;
