import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  useTheme,
} from "@mui/material";
import EditIcon from "../../assets/images/EditIcon";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../shared/routes";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import { useState } from "react";
import { LEAD_SERVICE } from "../../services/LeadService";
import { GetLeadDTO } from "../../services/dto/GetLeadDTO";
import { useAppDispatch } from "../../state/hooks";
import { TLeadState } from "../../state/types/lead";
import { leadSlice } from "../../state/lead/leadSlice";
import TableProgress from "../Table/TableProgress";
import DataNotFound from "../DataNotfound/DataNotFound";
import { FORMATTER } from "../../helper/formatter";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "25px" },
  { id: "name", label: "Name", allowSort: true, width: "305px" },
  { id: "email", label: "Email", allowSort: true, width: "260px" },
  { id: "phone", label: "Phone", allowSort: true, width: "130px" },
  { id: "leadset", label: "Leadset", allowSort: false, width: "140px" },
  { id: "date", label: "Date/Time", allowSort: true, width: "200px" },
  { id: "actions", label: "Actions", allowSort: false, width: "100px" },
];

const LeadsetDetailsTable = ({
  leadsetDetails,
  leadsetName,
  onAction,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  startIndex,
  leadLoader,
}: {
  leadsetDetails: GetLeadDTO[];
  leadsetName: string;
  onAction: Function;
  order: "asc" | "desc";
  orderBy: string;
  setOrder: Function;
  setOrderBy: Function;
  startIndex: number;
  leadLoader: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedLead, setSelecetedLead] = useState<any>();
  const [loadingDelete, setLoadingDelete] = useState(false);

  function deleteLead() {
    const onSuccess = () => {
      setOpenConfirmation(false);
      setLoadingDelete(false);
      onAction();
    };
    const onError = (error: any) => {
      console.log(error);
      setLoadingDelete(false);
    };
    setLoadingDelete(true);
    LEAD_SERVICE.deleteLead(onSuccess, onError, selectedLead?.id);
  }

  const handleEdit = (lead: TLeadState) => {
    dispatch(leadSlice.actions.setLead(lead));
    navigate(ALL_ROUTES.EDIT_LEAD);
  };
  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor={theme.palette.primary.main}>
            {headCells.map((cell) => (
              <StyledTableCell
                align={cell.id === "sr" ? "center" : "left"}
                key={cell.id}
                width={cell.width}
              >
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {leadLoader ? (
            <TableProgress colSpans={7} />
          ) : leadsetDetails.length === 0 ? (
            <DataNotFound colSpans={7} />
          ) : (
            <TableBody>
              {leadsetDetails.map((leadset, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell width={headCells[0].width}>
                    {startIndex == 0 && index < 9
                      ? "0" + (index + 1)
                      : startIndex + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[1].width}>
                    {leadset.name}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ textTransform: "initial" }}
                    width={headCells[2].width}
                  >
                    {leadset.email}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[3].width}>
                    {leadset.phone}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[4].width}>
                    {leadsetName}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[5].width}>
                    {FORMATTER.formatDateTime(leadset.createdOn)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[6].width}>
                    <Box
                      display="flex"
                      justifyContent={"flex-start"}
                      alignItems="center"
                    >
                      <IconButton
                        sx={{ padding: 0.8 }}
                        onClick={() => handleEdit(leadset)}
                      >
                        <EditIcon color="#35CB5D" />
                      </IconButton>
                      <IconButton
                        sx={{ padding: 0.3 }}
                        onClick={() => {
                          setSelecetedLead(leadset);
                          setOpenConfirmation(true);
                        }}
                      >
                        <LeadsetDeleteIcon />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <SimpleConfirmationPopUp
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        value={selectedLead?.name}
        action="Delete"
        onAction={deleteLead}
        loading={loadingDelete}
      />
    </>
  );
};

export default LeadsetDetailsTable;
