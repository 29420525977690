import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI } from "./API";

function getRecordings(onSuccess: Function, onError: Function, query?: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_RECORDINGS,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
}

export const RECORDING_SERVICE = {
  getRecordings,
};
