import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "../../assets/images/AddIcon";
import ForceLougoutIcon from "../../assets/images/ForceLougoutIcon";
import { DOWNLOADER } from "../../helper/downloader";
import { AUTH_SERVICE } from "../../services/AuthService";
import { USER_SERVICE } from "../../services/UserService";
import { ALL_ROUTES } from "../../shared/routes";
import BackdropLoader from "../BackdropLoader";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import RoundedButtons from "../Custom/RoundedButtons";

const Actions = ({
  tabs,
  search,
  userIds,
  selectedTab,
  handleTabChange,
}: {
  tabs: any[];
  search: string;
  userIds: number[];
  selectedTab: number;
  handleTabChange: (index: number) => void;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleLougoutAllUsers = () => {
    const dto = { userIds: userIds };
    AUTH_SERVICE.logutOutUsers(
      () => {},
      () => {},
      dto
    );
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const exportUsers = () => {
    setLoading(true);
    const onSuccess = (data: any) => {
      DOWNLOADER.download(data.data, `users-${new Date().valueOf()}.xlsx`);
      setLoading(false);
    };
    const onError = () => setLoading(false);
    USER_SERVICE.exportUsers(onSuccess, onError, {
      search: search,
      active: selectedTab == 0,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "center",
        }}>
        {/* <CustomTabButton
          tabs={tabs}
          height="30px"
          width="65px"
          btnContainerPadding="2px"
          btnPadding="5px 9px"
          btnContainerWidth="146px"
          btnContainerHeight="36px"
          sx={{
            fontSize: 16,
          }}
          value={selectedTab}
          onChange={handleTabChange}
          color="#5ECE62"
        /> */}
        {/* <CustomTabButtons
          tabs={tabs}
          containerWidth={"146px"}
          borderRadius={"50px"}
          containerPadding={"2px"}
          btnPadding={"5px 12.5px"}
          btnHeight={"30px"}
          btnBgColor={"#5ECE62"}
          btnMinWidth={"65px"}
          fontSize={"16px"}
          containerBorder={"1px solid #96A1B2"}
          onClick={handleTabChange}
          value={selectedTab}
        /> */}
        {/* <RoundedButtons
          title="Export"
          color="#5ECE62"
          Icon={CloudDownloadIcon}
          sx={{ fontSize: "16px", paddingY: "4px" }}
          onClick={exportUsers}
        /> */}
        <RoundedButtons
          tooltip="Add New User"
          title="Add"
          color="#F2994A"
          Icon={AddIcon}
          onClick={() => navigate(ALL_ROUTES.ADD_USER)}
          sx={{ fontSize: "16px", paddingY: "4px" }}
        />
        <RoundedButtons
          title="Force Logout All Agents"
          color="#EB5757"
          Icon={() =>
            ForceLougoutIcon({ color: "#fff", height: "20", width: "20" })
          }
          sx={{ width: 250, fontSize: "16px", padding: "4px" }}
          onClick={() => setOpen(true)}
        />
      </Box>
      <SimpleConfirmationPopUp
        open={open}
        setOpen={setOpen}
        value={"All Agents"}
        action={"Logout"}
        onAction={handleLougoutAllUsers}
      />
      {loading && <BackdropLoader loading={loading} />}
    </>
  );
};

export default Actions;
