import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";

const ProcessCheckList = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start" }} gap={"10%"}>
      <Box>
        <Checkbox
          icon={<CircleOutlinedIcon sx={{ color: "black", fontSize: 20 }} />}
          checkedIcon={
            <CircleIcon
              sx={{
                border: "2px solid black",
                borderRadius: "50%",
                fontSize: 13,
              }}
            />
          }
        />
        Process 1
      </Box>
      <Box>
        <Checkbox
          icon={<CircleOutlinedIcon sx={{ color: "black", fontSize: 20 }} />}
          checkedIcon={
            <CircleIcon
              sx={{
                border: "2px solid black",
                borderRadius: "50%",
                fontSize: 13,
              }}
            />
          }
        />
        Process 2
      </Box>
      <Box>
        <Checkbox
          icon={<CircleOutlinedIcon sx={{ color: "black", fontSize: 20 }} />}
          checkedIcon={
            <CircleIcon
              sx={{
                border: "2px solid black",
                borderRadius: "50%",
                fontSize: 13,
              }}
            />
          }
        />
        Process 3
      </Box>
      <Box>
        <Checkbox
          icon={<CircleOutlinedIcon sx={{ color: "black", fontSize: 20 }} />}
          checkedIcon={
            <CircleIcon
              sx={{
                border: "2px solid black",
                borderRadius: "50%",
                fontSize: 13,
              }}
            />
          }
        />
        Process 4
      </Box>
    </Box>
  );
};

export default ProcessCheckList;
