import { PayloadAction } from "@reduxjs/toolkit";
import { TLeadsetState } from "../types/leadset";

function setLeadset(
  state: TLeadsetState,
  action: PayloadAction<TLeadsetState>
) {
  return action.payload;
}

function updateLeadset(
  state: TLeadsetState,
  action: PayloadAction<{ key: any; value: any }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

export const LEADSET_REDUCERS = {
  setLeadset,
  updateLeadset,
};

export type TLeadsetReducer = typeof LEADSET_REDUCERS;
