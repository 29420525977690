import { Canceler } from "axios";
import { toast } from "react-toastify";
import AxiosApiInstance from "./AxiosAPIInstance";

export class PostAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .post(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class GetAPI {
  static call(
    url: string,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .get(url, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class GetAPIToast {
  static call(
    url: string,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const options = {
      pending: "Hang On ! Checking For New Lead",
      success: "Voila ! New Lead Assigned",
      error: "Uh-oh ! No New Lead Available",
      successDuration: 3000,
      errorDuration: 5000,
      toastId: "get-api-toast",
    };

    const api = new AxiosApiInstance();
    const promise = api.api.get(url, config);

    const requestPromise = toast.promise(promise, options);

    requestPromise
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
        throw err;
      })
      .finally(() => {
        onFinal();
      });

    return api.cancelApi;
  }
}

export class PutAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .put(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class PatchAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .patch(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class PatchAPIToastify {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {},
    toast_options: {
      pending: string;
      success: string;
      error: string;
      successDuration?: number;
      errorDuration?: number;
      toastId?: "get-api-toast";
    } = {
      pending: "Hang On !",
      success: "Voila !",
      error: "Uh-oh !",
      successDuration: 3000,
      errorDuration: 5000,
      toastId: "get-api-toast",
    }
  ): Canceler {
    const options = toast_options;

    const api = new AxiosApiInstance();

    const promise = api.api.patch(url, dto, config);
    const requestPromise = toast.promise(promise, options);

    requestPromise
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
        throw err;
      })
      .finally(() => {
        onFinal();
      });

    return api.cancelApi;
  }
}

export class DeleteAPI {
  static call(
    url: string,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .delete(url, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}
