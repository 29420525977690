import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import Time from "../../Stopwatch/Timer";

const DispositionPopUpHeader = ({ title = "" }: { title?: string }) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="space-between"
        minWidth="100%"
        pb="13px"
      >
        <Box
          sx={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title && (
            <>
              <Typography
                sx={{ fontSize: "14px" }}
                color={"error.main"}
                pr={0.5}
              >
                *
              </Typography>
              <Typography sx={{ fontSize: { xs: "10px", md: "12px" } }}>
                {title}
              </Typography>
            </>
          )}
        </Box>
        <Time
          color="primary.main"
          sx={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: { xs: "20px", md: "32px" },
          }}
        />
      </Box>
      {!mobileView && <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.1)" }} />}
    </>
  );
};

export default DispositionPopUpHeader;
