import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Popover,
  CircularProgress,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import LightTooltip from "../LightToolTip";
import MediaPlayer from "./MediaPlayer";
import React from "react";
import { CommentType, GetCommentDTO } from "../../services/dto/GetCommentDTO";
import { FORMATTER } from "../../helper/formatter";

const headersList = [
  { label: "Sr. ", width: "34px" },
  { label: "Disposition name ", width: "153px" },
  { label: "Date", width: "200px" },
  { label: "Remarks", width: "239px" },
  { label: "Recording", width: "105px" },
];

const CreateTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    border: "none",
    fontWeight: 400,
    fontFamily: `"Josefin Sans", sans-serif`,
    lineHeight: "30px !important",
    padding: 0,
    paddingLeft: "10px",
    color:
      theme.palette.mode === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
}));

const CommentsTableLarge = ({
  comments,
  onScroll,
  loading = false,
}: {
  comments: GetCommentDTO[];
  onScroll: (className: string) => void;
  loading?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const containerClass = "comments-table-large";
  return (
    <TableContainer
      onScroll={() => onScroll(containerClass)}
      className={containerClass}
      sx={{
        boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.1)",
        paddingTop: "18px",
        paddingBottom: "14px",
        paddingX: "23px",
        borderRadius: "16px",
        maxWidth: "-webkit-fill-available",
        width: "100%",
        display: { md: "flex", xs: "none" },
        fontFamily: `"Josefin Sans", sans-serif`,
        marginBottom: "19px",
        height: "200px",
      }}>
      <Table>
        <TableHead>
          <TableRow>
            {headersList.map((comment, index) => (
              <TableCell
                key={index}
                sx={{
                  color: "gray",
                  paddingBottom: 1.25,
                  paddingX: 0,
                  paddingLeft: "10px",
                  paddingTop: 0.1,
                  fontFamily: `"Josefin Sans", sans-serif`,
                  fontWeight: 600,
                  fontSize: "16px",
                  width: comment.width,
                }}>
                {comment.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {comments.map((comment: GetCommentDTO, index: number) => (
            <TableRow
              sx={{
                border: "none !important",
                height: "20px !important",
                gap: "10px",
              }}
              key={index}>
              <CreateTableCell sx={{ width: headersList[0].width }}>
                {index < 9 ? "0" + (index + 1) : index + 1}
              </CreateTableCell>
              <CreateTableCell sx={{ width: headersList[1].width }}>
                {comment.disposition}
              </CreateTableCell>
              <CreateTableCell sx={{ width: headersList[2].width }}>
                {comment.createdOn &&
                  FORMATTER.formatDateTime(comment.createdOn)}
              </CreateTableCell>
              <CreateTableCell sx={{ width: headersList[3].width }}>
                {comment.comment}
              </CreateTableCell>
              {comment.type === CommentType.DISPOSITION && (
                <CreateTableCell sx={{ width: headersList[4].width }}>
                  <LightTooltip title="play Recording" placement="left">
                    <PlayCircleIcon
                      aria-describedby={id}
                      onClick={handleClick}
                      sx={{
                        fontSize: 28,
                        color: "#6F5FD9",
                        marginTop: 1,
                        marginLeft: 2,
                      }}
                    />
                  </LightTooltip>
                  <Popover
                    sx={{
                      ".MuiPopover-paper": {
                        background: "none",
                        boxShadow: "none",
                        paddingRight: 5,
                      },
                    }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}>
                    <MediaPlayer />
                  </Popover>
                </CreateTableCell>
              )}
            </TableRow>
          ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommentsTableLarge;
