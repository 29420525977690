import {
  TableCell,
  styled,
  tableCellClasses,
  TableCellProps,
} from "@mui/material";
import React from "react";

const CreateTableCell = styled(TableCell)(({ theme, sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: "capitalize",
    border: "none",
    color:
      theme.palette.mode === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...sx,
  },
  padding: "10px",
  paddingRight: "0px",
}));

const StyledTableCell = ({
  children,
  props,
  align = "left",
  sx,
  onClick,
  width,
  minWidth,
}: {
  children?: React.ReactNode;
  props?: TableCellProps;
  align?: "left" | "center" | "right";
  sx?: React.CSSProperties;
  onClick?: any;
  width?: string | number;
  minWidth?: string | number;
}) => {
  return (
    <CreateTableCell
      padding="none"
      {...props}
      component="th"
      scope="row"
      align={align}
      onClick={onClick}
      sx={{
        ":hover": {
          cursor: onClick && "pointer",
        },
        minWidth: { minWidth },
        maxWidth: width,
        ...sx,
      }}
      width={width}>
      {children}
    </CreateTableCell>
  );
};

export default StyledTableCell;
