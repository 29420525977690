function ActiveProcessIcon() {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.45374 13.4468C10.6353 13.4468 11.592 14.4048 11.592 15.5851V19.8617C11.592 20.4288 11.3668 20.9727 10.9657 21.3737C10.5647 21.7747 10.0209 22 9.45374 22H2.73827C2.17116 22 1.62728 21.7747 1.22627 21.3737C0.82526 20.9727 0.599976 20.4288 0.599976 19.8617V15.5851C0.599976 14.4048 1.55793 13.4468 2.73827 13.4468H9.45374V13.4468ZM22.8993 13.4468C24.0797 13.4468 25.0376 14.4048 25.0376 15.5851V19.8617C25.0376 20.4288 24.8123 20.9727 24.4113 21.3737C24.0103 21.7747 23.4665 22 22.8993 22H16.1839C15.6168 22 15.0729 21.7747 14.6719 21.3737C14.2709 20.9727 14.0456 20.4288 14.0456 19.8617V15.5851C14.0456 14.4048 15.0023 13.4468 16.1839 13.4468H22.8993V13.4468ZM22.8993 0C24.0797 0 25.0376 0.957956 25.0376 2.13829V8.86232C25.0378 9.14322 24.9826 9.42141 24.8752 9.68098C24.7678 9.94055 24.6104 10.1764 24.4118 10.3751C24.2132 10.5738 23.9774 10.7314 23.7179 10.8389C23.4584 10.9465 23.1802 11.0018 22.8993 11.0018H2.73827C2.17116 11.0018 1.62728 10.7765 1.22627 10.3755C0.82526 9.97453 0.599976 9.43065 0.599976 8.86354V2.13829C0.599996 1.60166 0.801795 1.08464 1.16532 0.689886C1.52884 0.295128 2.0275 0.051489 2.56232 0.00733135L2.73827 0H22.8993V0Z"
        fill="#6F5FD9"
      />
    </svg>
  );
}

export default ActiveProcessIcon;
