import { Box, Popover, Typography } from "@mui/material";
import { useState } from "react";
import PlayCirlcleIcon from "../../assets/images/PlayCirlceIcon";
import { GetCommentDTO } from "../../services/dto/GetCommentDTO";
import MediaPlayer from "../LeadInformation/MediaPlayer";
import { Player } from "../LeadInformation/Player";
import { Timestamp } from "./Timestamp";

const TextBox = ({ comment }: { comment: GetCommentDTO }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget.parentNode);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Box display="flex" marginX={"12px"} sx={{ mb: "12px" }}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Typography fontSize={"10px"} fontFamily="Roboto" color={"#555"}>
            {comment.createdByUser.firstName || comment.createdByUser.username}
          </Typography>
          <Typography
            fontSize={"12px"}
            fontWeight="700"
            fontFamily={"Roboto"}
            color="#555"
          >
            {comment.disposition}
          </Typography>
        </Box>
        <Timestamp date={new Date(comment.createdOn)} />
      </Box>
      <Box
        sx={{
          marginX: "12px",
          marginBottom: 2,
          padding: "6px",
          backgroundColor: "primary.main",
          position: "relative",
          borderRadius: "0px 10px 10px 10px",
          "&::before": {
            content: '""',
            position: "absolute",
            top: "-11.5px",
            left: 0,
            borderRight: "12px solid transparent",
            borderBottom: "12px solid #6F5FD9",
          },
        }}
        id="anchor-el"
      >
        <Box
          sx={{
            position: "absolute",
            top: -5,
            right: -5,
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleClick}
        >
          <PlayCirlcleIcon />
        </Box>
        <Typography
          sx={{
            color: "white",
            fontSize: "13px",
            fontWeight: 400,
            fontFamily: "Roboto",
          }}
        >
          {comment.comment}
        </Typography>
      </Box>
      <Popover
        sx={{
          ".MuiPopover-paper": {
            background: "none",
            boxShadow: "none",
            pr: "20px",
            pb: "10px",
          },
        }}
        anchorReference="anchorEl"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MediaPlayer
          hidden={true}
          sx={{
            backgroundColor: "#222",
          }}
        />
        <Player />
      </Popover>
    </>
  );
};

export default TextBox;
