import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Box, Typography } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "../assets/images/AddIcon";
import BackdropLoader from "../components/BackdropLoader";
import ManageCampaignsTable from "../components/Campaigns/ManageCampaignsTable";
import ManageCampaignsTableMobile from "../components/Campaigns/ManageCampaignsTableMobile";
import RoundedButtons from "../components/Custom/RoundedButtons";
import { SearchBar } from "../components/SearchBar";
import TablePagination from "../components/Table/TablePagination";
import { DOWNLOADER } from "../helper/downloader";
import { CAMPAIGN_SERVICE } from "../services/CampaignService";
import { GetCampaignDTO } from "../services/dto/GetCampaignDTO";
import { ALL_ROUTES } from "../shared/routes";
import { campaignSlice } from "../state/campaign/campaignSlice";
import { useAppDispatch } from "../state/hooks";

const ManageCampaigns = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<GetCampaignDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("name");
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });
  const [compaignLoader, setCompaignLoader] = useState(false);
  const dispatch = useAppDispatch();

  const loadCampaigns = useCallback((text: string) => {
    setCompaignLoader(true);
    const onSuccess = ({
      info,
      campaigns,
    }: {
      info: any;
      campaigns: GetCampaignDTO[];
    }) => {
      setCompaignLoader(false);
      setCampaigns(campaigns);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = () => {
      setCompaignLoader(false);
    };
    CAMPAIGN_SERVICE.getCampaigns(onSuccess, onError, {
      startIndex: 0,
      limit,
      search: text,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadCampaigns, 500), [
    loadCampaigns,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getCampaigns() {
    setCompaignLoader(true);
    const onSuccess = ({
      campaigns,
      info,
    }: {
      campaigns: GetCampaignDTO[];
      info: any;
    }) => {
      setCompaignLoader(false);
      setCampaigns(campaigns);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination({
        ...pagination,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      });
    };
    const onError = (err: any) => {
      setCompaignLoader(false);
      console.log(err);
    };
    const limit = 10;
    CAMPAIGN_SERVICE.getCampaigns(onSuccess, onError, {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search,
      sortDirection: order,
      sort: orderBy,
    });
  }

  const exportCampaigns = () => {
    setLoading(true);
    const onSuccess = (data: any) => {
      DOWNLOADER.download(data.data, `campaigns-${new Date().valueOf()}.xlsx`);
      setLoading(false);
    };
    const onError = () => setLoading(false);
    CAMPAIGN_SERVICE.exportCampaigns(onSuccess, onError, {
      search: search,
    });
  };

  useEffect(() => {
    getCampaigns();
  }, [pagination.page, order, orderBy]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h5" marginRight={"20px"}>
            Manage Campaigns
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <Box display={"flex"} gap={1.2}>
          {/* <RoundedButtons
            title="Export"
            color="#5ECE62"
            tooltip='Export campaign list'
            Icon={CloudDownloadIcon}
            onClick={exportCampaigns}
          /> */}
          <RoundedButtons
            title="Add"
            tooltip="Create new campaign"
            color="#F2994A"
            Icon={AddIcon}
            onClick={() => {
              dispatch(
                campaignSlice.actions.setCampaign(
                  campaignSlice.getInitialState()
                )
              );
              navigate(ALL_ROUTES.CREATE_CAMPAIGN);
            }}
          />
        </Box>
      </Box>
      <Box display={{ xs: "none", md: "flex", marginTop: "20px" }}>
        <ManageCampaignsTable
          campaigns={campaigns}
          onAction={getCampaigns}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          startIndex={(pagination.page - 1) * limit}
          loading={compaignLoader}
        />
      </Box>
      <Box display={{ xs: "flex", md: "none" }} flexDirection="column">
        <ManageCampaignsTableMobile
          campaigns={campaigns}
          onAction={getCampaigns}
          startIndex={(pagination.page - 1) * limit}
        />
      </Box>
      <TablePagination page={pagination} setPage={setPagination} />
      {loading && <BackdropLoader loading={loading} />}
    </>
  );
};

export default ManageCampaigns;
