import { Box, Button } from "@mui/material";
import FormActionButton from "../Actions/FormActionButton";

function EditCallbackDetailsActions({
  setOpen,
  onClick,
  isLoading = false,
}: {
  setOpen: Function;
  onClick?: Function;
  isLoading?: boolean;
}) {
  const styledButton = {
    minWidth: "147px",
    textTransform: "capitalize",
    fontSize: "16px",
  };
  return (
    <Box display="flex" gap={2} justifyContent="center" p="20px 0">
      <FormActionButton
        title="Cancel"
        onClick={() => setOpen(false)}
        disabled={isLoading}
      />
      <FormActionButton
        variant="contained"
        title="Save"
        onClick={() => {
          onClick && onClick();
        }}
        loading={isLoading}
      />
    </Box>
  );
}

export default EditCallbackDetailsActions;
