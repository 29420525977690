function PlayCirlcleIcon({
  width = "20px",
  height = "20px",
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" />
      <path
        d="M10 1C8.21997 1 6.47991 1.52784 4.99987 2.51677C3.51983 3.50571 2.36628 4.91131 1.68509 6.55585C1.0039 8.20038 0.82567 10.01 1.17294 11.7558C1.5202 13.5016 2.37737 15.1053 3.63604 16.364C4.89471 17.6226 6.49836 18.4798 8.24419 18.8271C9.99002 19.1743 11.7996 18.9961 13.4442 18.3149C15.0887 17.6337 16.4943 16.4802 17.4832 15.0001C18.4722 13.5201 19 11.78 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.387 1 10 1ZM14.7874 10.5754L7.07307 14.4325C6.97504 14.4815 6.86611 14.5046 6.75663 14.4996C6.64715 14.4947 6.54076 14.4618 6.44755 14.4042C6.35435 14.3465 6.27743 14.266 6.2241 14.1703C6.17078 14.0745 6.14281 13.9667 6.14286 13.8571V6.14286C6.14292 6.03332 6.17097 5.92561 6.22434 5.82996C6.27771 5.7343 6.35464 5.65388 6.44783 5.5963C6.54101 5.53873 6.64737 5.50592 6.75679 5.50099C6.86622 5.49606 6.97509 5.51918 7.07307 5.56814L14.7874 9.42528C14.894 9.47873 14.9837 9.56079 15.0463 9.66229C15.109 9.76379 15.1422 9.88072 15.1422 10C15.1422 10.1193 15.109 10.2362 15.0463 10.3377C14.9837 10.4392 14.894 10.5219 14.7874 10.5754Z"
        fill="#6F5FD9"
      />
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="white" />
    </svg>
  );
}

export default PlayCirlcleIcon;
