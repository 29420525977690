import { URL_CONSTANTS } from "../shared/urls";
import { DeleteAPI, GetAPI, PostAPI } from "./API";
import { PostDispositionDTO } from "./dto/PostDispositionDTO";

function getDispositions(onSuccess: Function, onError: Function, query: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_DISPOSITION,
    (data: any) => {
      onSuccess(data.data);
    },
    {
      params: query,
    },
    onError
  );
}

function getAllDispositions(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.GET_ALL_DISPOSITION,
    (data: any) => {
      onSuccess(data.data);
    },
    onError
  );
}

function getDispositionsByProcessId(
  onSuccess: Function,
  onError: Function,
  id: number | undefined
) {
  GetAPI.call(
    URL_CONSTANTS.GET_DISPOSITION_LIST_BY_PROCESS_ID(
      id === undefined ? "" : id.toString()
    ),
    (data: any) => {
      onSuccess(data.data);
    },
    onError
  );
}

function postDisposition(
  onSuccess: Function,
  onError: Function,
  dto: PostDispositionDTO
) {
  PostAPI.call(URL_CONSTANTS.GET_DISPOSITION, dto, onSuccess, {}, onError);
}

function deleteDisposition(onSuccess: any, onError: any, id: string) {
  DeleteAPI.call(URL_CONSTANTS.DELETE_DISPOSITION(id), onSuccess, {}, onError);
}

export const DISPOSITION_SERVICE = {
  getDispositions,
  getAllDispositions,
  postDisposition,
  deleteDisposition,
  getDispositionsByProcessId,
};
