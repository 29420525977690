import { createContext, Dispatch, SetStateAction } from "react";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";

type TSocketContext = {
  socket: Socket | null;
  setSocket: Dispatch<
    SetStateAction<Socket<DefaultEventsMap, DefaultEventsMap> | null>
  >;
};

export const SocketContext = createContext<TSocketContext>({
  socket: null,
  setSocket: () => {},
});
