import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import { SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoBlack } from "../../assets/images/Logo";
import PasswordLockIcon from "../../assets/images/PasswordLockIcon";
import UserNameIcon from "../../assets/images/UserNameIcon";
import FormActionButton from "../../components/Actions/FormActionButton";
import CustomTextField from "../../components/Custom/CustomTextField";
import { AUTH_SERVICE } from "../../services/AuthService";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../../shared/constants";
import { useAuth } from "../../shared/hooks/useAuth";
import { useLoading } from "../../shared/hooks/useLoading";
import { ALL_ROUTES } from "../../shared/routes";
import { URL_CONSTANTS } from "../../shared/urls";
import { clientConfigSlice } from "../../state/clientConfig/clientConfigSlice";
import { useAppSelector } from "../../state/hooks";
import { defaultProcessSlice } from "../../state/process/defaultProcessSlice";
import { TDefaultProcess } from "../../state/types/defaultProcess";
import { TClientConfig } from "../../state/types/TClientConfig";
import { UserAccessLevel, UserDetails } from "../../state/types/user";
import { userSlice } from "../../state/user/userSlice";

const Login = () => {
  const navigate = useNavigate();

  const micErrorStatus = useAppSelector((state) => state.user.micErrorStatus);
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });
  const [errorDetails, setErrorDetails] = useState({
    username: "",
    password: "",
  });
  const { authToken, setAuthToken } = useAuth();
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [defaultProcess, setDefaultProcess] = useState<TDefaultProcess | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const { setLoadingContext } = useLoading();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginDetails((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));

    if (event.target.value === "" || event.target.value === null) {
      setErrorDetails((prev) => ({
        ...prev,
        [event.target.id]: "Please fill all fields",
      }));
    } else {
      setErrorDetails((prev) => ({ ...prev, [event.target.id]: "" }));
    }
  };

  const handleLogin = () => {
    setLoadingContext(true);
    if (!validateForm()) return;
    setLoading(true);

    const onSuccess = (data: any) => {
      const authToken = data.token;
      setAuthToken(authToken);
      localStorage.setItem(LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN, authToken);
      localStorage.setItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.SOCKET_TOKEN,
        data.socketCode
      );
      localStorage.setItem(LOCALSOTRAGE_KEYS_CONSTANTS.USER, data.user);
    };

    const onError = (err: any) => {
      setLoadingContext(false);
      setLoading(false);
      if (err?.response?.data?.statusCode === 401) {
        if (err?.response?.data?.message.includes("Invalid")) {
          setErrorDetails((prev) => ({
            ...prev,
            username: "Invalid combination of username & password",
          }));
        }
        if (err?.response?.data?.message.includes("Disabled")) {
          setErrorDetails((prev) => ({
            ...prev,
            username: "User is not active",
          }));
        }
      }
    };

    const onSetUserDetails = ({
      user,
      defaultProcess,
    }: {
      user: SetStateAction<UserDetails | null>;
      defaultProcess: any;
    }) => {
      ``;
      setUserDetails(user);
      localStorage.setItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.USER,
        JSON.stringify(user)
      );
      setDefaultProcess(defaultProcess.ProcessDetails);
      localStorage.setItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.DEFAULT_PROCESS,
        JSON.stringify(defaultProcess?.ProcessDetails)
      );
    };
    AUTH_SERVICE.loginUser(onSuccess, onError, loginDetails, onSetUserDetails);
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleLogin();
      }
    };
    window.addEventListener("keyup", listener);
    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, [handleLogin]);

  function get_client_config() {
    axios.get(URL_CONSTANTS.CLIENT_CONFIG).then((data: any) => {
      const response_data: TClientConfig = data.data;

      dispatch(clientConfigSlice.actions.setClientConfigData(response_data));
      localStorage.setItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.CLIENT_CONFIG,
        JSON.stringify(response_data)
      );
    });
  }

  useEffect(() => {
    const updateDevices = () => {
      navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
        const microphones = deviceInfos.filter(
          (device) => device.kind === "audioinput"
        );

        dispatch(
          userSlice.actions.updateMicErrorStatus(
            microphones.length > 0 ? false : true
          )
        );
      });
    };

    updateDevices();

    navigator.mediaDevices.ondevicechange = updateDevices;

    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);

  const validateForm = () => {
    let isValid = true;
    for (const [key, value] of Object.entries(loginDetails)) {
      if (value === "") {
        setErrorDetails((prev) => ({
          ...prev,
          [key]: "Please fill all fields",
        }));
        isValid = false;
      }
    }
    return isValid;
  };

  useEffect(() => {
    get_client_config();

    const existingAuthToken = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN
    );
    const existingUserDetails = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.USER
    );
    const existingProcess = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.DEFAULT_PROCESS
    );
    const client_config_str = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.CLIENT_CONFIG
    );
    const client_config: TClientConfig = JSON.parse(
      client_config_str === null ? "{}" : client_config_str
    );
    if (authToken && userDetails !== null) {
      dispatch(clientConfigSlice.actions.setClientConfigData(client_config));
      dispatch(userSlice.actions.setUserDetails(userDetails));
      dispatch(
        defaultProcessSlice.actions.setDefaultProcess(
          defaultProcess ?? defaultProcessSlice.getInitialState()
        )
      );
      if (userDetails.accessLevel === UserAccessLevel.ADMIN) {
        navigate(ALL_ROUTES.ADMIN_DASHBAORD);
      } else {
        // navigate(ALL_ROUTES.AGENT_DASHBAORD);
        if (micErrorStatus === false) {
          dispatch(userSlice.actions.goToReady());
        } else {
          dispatch(userSlice.actions.goToStop());
        }
      }
    }
    if (existingAuthToken && existingUserDetails) {
      setAuthToken(existingAuthToken);
      dispatch(
        userSlice.actions.setUserDetails(JSON.parse(existingUserDetails))
      );
      if (existingProcess) {
        dispatch(
          defaultProcessSlice.actions.setDefaultProcess(
            JSON.parse(existingProcess)
          )
        );
      }
      const userDetails = JSON.parse(existingUserDetails);
      if (userDetails?.accessLevel === UserAccessLevel.ADMIN) {
        navigate(ALL_ROUTES.ADMIN_DASHBAORD);
      } else {
        // navigate(ALL_ROUTES.AGENT_DASHBAORD);
        if (micErrorStatus === false) {
          navigate(ALL_ROUTES.READY);
        } else {
          dispatch(userSlice.actions.goToStop());
        }
      }
    }
  }, [authToken]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "flex-start", md: "center" },
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        marginTop: { xs: "80px", md: "0" },
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: mobileView ? "271px" : "289px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: { xs: "28px", md: "52px" },
          }}>
          <LogoBlack
            height={mobileView ? "37" : "65"}
            width={mobileView ? "45.22" : "80"}
          />
          <Typography
            sx={{ marginLeft: { xs: "7px", md: "12px" }, mt: { xs: "5px" } }}
            fontSize={20}
            fontWeight={600}>
            Dialvent
          </Typography>
        </Box>
        <Typography
          fontSize={mobileView ? 20 : 24}
          fontWeight={600}
          textAlign="center">
          Login to your account
        </Typography>
        <Typography
          fontSize={mobileView ? 12 : 14}
          sx={{
            fontFamily: "Roboto",
            color: "rgba(0, 0, 0, 0.5)",
            mt: mobileView ? "0" : "8px",
            mb: "27px",
          }}>
          Please login using Username &amp; Password
        </Typography>
        <CustomTextField
          type="email"
          id="username"
          label="Username/Email"
          Icon={UserNameIcon}
          value={loginDetails.username}
          onChange={handleChange}
          error={errorDetails.username}
          errorText={errorDetails.username}
          sx={{ width: mobileView ? "239px" : "257px" }}
          marginBottom={mobileView ? "10px" : "20px"}
        />
        <CustomTextField
          type="password"
          id="password"
          value={loginDetails.password}
          onChange={handleChange}
          label="Password"
          Icon={PasswordLockIcon}
          error={errorDetails.password}
          errorText={errorDetails.password}
          sx={{ width: mobileView ? "239px" : "257px" }}
          marginBottom="10px"
        />
        <Box
          display="flex"
          justifyContent={"flex-start"}
          width="100%"
          mb={mobileView ? "17px" : "23px"}>
          {/* <Typography
            fontSize={"14px"}
            sx={{ color: "primary.main" }}
            textAlign="left">
            Forgot Password?
          </Typography> */}
        </Box>
        <FormActionButton
          title="Sign In"
          variant="contained"
          onClick={handleLogin}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Login;
