import { URL_CONSTANTS } from "../shared/urls";
import { TDisposeCallState } from "../state/types/disposeCall";
import { GetAPI, PostAPI } from "./API";
import { CreateCallDTO } from "./dto/CreateCallDTP";

const createManualCall = (
  onSuccess: (data: any) => void,
  onError: () => void,
  dto: CreateCallDTO,
  onFinal = () => {}
) => {
  PostAPI.call(
    URL_CONSTANTS.CREATE_MANUAL_CALL,
    dto,
    onSuccess,
    {},
    onError,
    onFinal
  );
};

const disposeCall = (
  onSuccess: Function,
  onError: Function,
  callId: string | number,
  dto: TDisposeCallState
) => {
  PostAPI.call(URL_CONSTANTS.DISPOSE_CALL(callId), dto, onSuccess, {}, onError);
};

const getPendingDispositions = (onSuccess: Function, onError: Function) => {
  GetAPI.call(
    URL_CONSTANTS.GET_PENDING_DISPOSITIONS,
    (data: any) => {
      onSuccess(data.data);
    },
    {},
    onError
  );
};

const getCallDetails = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.GET_CALL_DETAILS,
    dto,
    (data: any) => {
      if (data?.data) onSuccess(data.data);
    },
    {},
    onError
  );
};

const getAgentCDR = (onSuccess: Function, onError: Function, query?: any) => {
  GetAPI.call(
    URL_CONSTANTS.GET_AGENT_CDR,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const getAllCDR = (onSuccess: Function, onError: Function, query?: any) => {
  GetAPI.call(
    URL_CONSTANTS.GET_ALL_CDR,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

function exportAgentLogger(onSuccess: Function, onError: Function, query: any) {
  GetAPI.call(
    URL_CONSTANTS.EXPORT_AGENT_LOGGER,
    onSuccess,
    { responseType: "blob", params: query },
    onError
  );
}

const getActiveCall = (onSuccess: Function, onError: Function, query?: any) => {
  GetAPI.call(
    URL_CONSTANTS.ACTIVE_CALL,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const exportCDRs = (onSuccess: Function, onError: Function, query: any) => {
  GetAPI.call(
    URL_CONSTANTS.EXPORT_CDR,
    onSuccess,
    { responseType: "blob", params: query },
    onError
  );
};

const disconnectCall = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.DISCONNECT_CALL,
    dto,
    (data: any) => onSuccess(data),
    {},
    onError
  );
};

const redialCall = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.REDIAL_CALL,
    dto,
    (data: any) => onSuccess(data),
    {},
    onError
  );
};

export const CALL_SERVICE = {
  createManualCall,
  disposeCall,
  getPendingDispositions,
  getCallDetails,
  getAgentCDR,
  getAllCDR,
  exportCDRs,
  disconnectCall,
  redialCall,
  getActiveCall,
  exportAgentLogger,
};
