import { Box } from "@mui/material";

const CustomActionButton = ({
  backgroundColor,
  title,
  onClick,
  width = "43px",
}: {
  backgroundColor: string;
  title: string;
  onClick?: any;
  width?: number | string;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: { backgroundColor },
        color: "white",
        textAlign: "center",
        borderRadius: "5px",
        paddingX: 0,
        paddingY: 0.2,
        lineHeight: 2,
        fontSize: 10,
        width: width,
        ":hover": { cursor: "pointer" },
      }}
    >
      {title}
    </Box>
  );
};

export default CustomActionButton;
