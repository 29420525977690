import { PayloadAction } from "@reduxjs/toolkit";
import { COMMENT_VISIBILITY } from "../../services/dto/PostCommentDTO";
import { TCommentState } from "../types/comment";

function setComment(
  state: TCommentState,
  action: PayloadAction<TCommentState>
) {
  return action.payload;
}

function updateComment(
  state: TCommentState,
  action: PayloadAction<{ key: any; value: any }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

function resetComment() {
  return {
    comment: "",
    visibility: COMMENT_VISIBILITY.PUBLIC,
    callId: null,
  };
}

export const COMMENT_REDUCERS = {
  setComment,
  resetComment,
  updateComment,
};

export type TCommentReducers = typeof COMMENT_REDUCERS;
