import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import RoundedButtons from "../components/Custom/RoundedButtons";
import { SearchBar } from "../components/SearchBar";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddIcon from "../assets/images/AddIcon";
import { useCallback, useEffect, useState } from "react";
import TablePagination from "../components/Table/TablePagination";
import { useNavigate } from "react-router-dom";
import LeadsetTable from "../components/Leadset/LeadsetTable";
import LeadsetTableMobile from "../components/Leadset/LeadsetTableMobile";
import { ALL_ROUTES } from "../shared/routes";
import { LEADSET_SERVICE } from "./../services/LeadsetService";
import { GetLeadsetDTO } from "../services/dto/GetLeadsetDTO";
import BackdropLoader from "../components/BackdropLoader";
import lodash from "lodash";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import { DOWNLOADER } from "../helper/downloader";

const ManageLeadset = () => {
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });
  const [leadsets, setLeadsets] = useState<GetLeadsetDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [leadsetLoader, setLeadsetLoader] = useState(false);

  const navigate = useNavigate();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const loadLeadsets = useCallback((text: string) => {
    setLeadsetLoader(true);
    const onSuccess = ({
      info,
      leadsets,
    }: {
      info: any;
      leadsets: GetLeadsetDTO[];
    }) => {
      setLeadsetLoader(false);
      setLeadsets(leadsets);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = () => {
      setLeadsetLoader(false);
    };
    LEADSET_SERVICE.getLeadset(onSuccess, onError, {
      startIndex: 0,
      limit,
      search: text,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadLeadsets, 500), [
    loadLeadsets,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getLeadsets() {
    setLeadsetLoader(true);
    const onSuccess = ({
      info,
      leadsets,
    }: {
      info: any;
      leadsets: GetLeadsetDTO[];
    }) => {
      setLeadsetLoader(false);
      setLeadsets(leadsets);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination({
        ...pagination,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      });
    };
    const onError = () => {
      setLeadsetLoader(false);
    };
    LEADSET_SERVICE.getLeadset(onSuccess, onError, {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search,
      sortDirection: order,
      sort: orderBy,
    });
  }

  useEffect(() => {
    getLeadsets();
  }, [pagination.page, order, orderBy]);

  const exportLeadsets = () => {
    setLoading(true);
    const onSuccess = (data: any) => {
      DOWNLOADER.download(data.data, `leadsets-${new Date().valueOf()}.xlsx`);
      setLoading(false);
    };
    const onError = () => setLoading(false);
    LEADSET_SERVICE.exportLeadsets(onSuccess, onError, {
      search: search,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
          <Typography variant="h5" marginRight={"20px"}>
            Manage Leadset
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <Box display={"flex"} gap={1.2}>
          {/* <RoundedButtons
            tooltip="Click to export the leadset list"
            title="Export"
            color="#5ECE62"
            Icon={CloudDownloadIcon}
            onClick={exportLeadsets}
          /> */}
          <RoundedButtons
            tooltip="Click to create new leadset"
            title="Add"
            color="#F2994A"
            Icon={AddIcon}
            onClick={() => {
              navigate(ALL_ROUTES.CREATE_LEADSET);
            }}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <LeadsetTable
          leadsets={leadsets}
          onAction={getLeadsets}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          startIndex={(pagination.page - 1) * limit}
          leadsetLoader={leadsetLoader}
        />
      </Box>
      {/* <Box display={{ xs: "flex", md: "none" }} flexDirection="column">
        <LeadsetTableMobile
          leadsets={leadsets}
          onAction={getLeadsets}
          startIndex={(pagination.page - 1) * limit}
        />
      </Box> */}
      <TablePagination page={pagination} setPage={setPagination} />
      {loading && <BackdropLoader loading={loading} />}
    </>
  );
};

export default ManageLeadset;
