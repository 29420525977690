import React from "react";

const CheckBoxIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" stroke="#798397" />
    </svg>
  );
};

export default CheckBoxIcon;
