import { useMediaQuery, Theme } from "@mui/material";
import ClockHeader from "../components/ClockHeader";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import ProcessDetailsForm from "../components/ProcessDetails/Forms/ProcessDetailsForm";

const CreateProcess = () => {
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title="Create Process"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <ProcessDetailsForm submit_button_text="Create" />
    </>
  );
};

export default CreateProcess;
