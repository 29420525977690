import { Box } from "@mui/material";

const VerticalTableRow = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Box
      display={"flex"}
      gap={2}
      sx={{
        paddingBottom: "16px",
      }}
    >
      {children}
    </Box>
  );
};

export default VerticalTableRow;
