import { Button, useTheme } from "@mui/material";
import LightTooltip from "../LightToolTip";

const RoundedButtons = ({
  sx,
  Icon,
  title,
  color,
  onClick,
  IconColor,
  disabled = false,
  suffixIcon = false,
  tooltip = "",
  ...rest
}: any) => {
  const theme = useTheme();
  return (
    <LightTooltip title={tooltip}>
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={"contained"}
        sx={{
          borderRadius: "20px",
          paddingX: 3,
          color: "white",
          lineHeight: "23px",
          background: color,
          paddingBottom: 0.5,
          textTransform: "capitalize",
          ":hover": {
            backgroundColor: color,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...sx,
        }}
        startIcon={
          !suffixIcon && (
            <Icon sx={{ color: IconColor ? IconColor : "white" }} />
          )
        }
        endIcon={
          suffixIcon && <Icon sx={{ color: IconColor ? IconColor : "white" }} />
        }
        {...rest}>
        {title}
      </Button>
    </LightTooltip>
  );
};

export default RoundedButtons;
