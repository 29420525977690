import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PostLeadDTO } from "../../services/dto/PostLeadDTO";
import { LEAD_SERVICE } from "../../services/LeadService";
import { useAppSelector } from "../../state/hooks";
import FormActionButton from "../Actions/FormActionButton";
import CustomTextField from "../Custom/CustomTextField";

const CreateLeadForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const leadsetDetails = useAppSelector((state) => state.leadset);
  const [loading, setLoading] = useState(false);
  const mandatoryFields = ["phone"];
  const [leadForm, setLeadForm] = useState<PostLeadDTO>({
    name: "",
    email: "",
    phone: "",
    address: "",
    leadsetId: parseInt(id ?? ""),
    configurations: {},
  });
  const [validationError, setValidationError] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [configurationFields, setConfigurationFields] = useState([
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
  ]);

  const handleConfigurations = (event: any, index: number) => {
    const newConfig = [...configurationFields];
    if (event.target.name === "key") {
      newConfig[index].key = event.target.value;
      setConfigurationFields(newConfig);
    } else {
      newConfig[index].value = event.target.value;
      setConfigurationFields(newConfig);
    }
    const configurations = configurationFields.reduce((obj, cur) => {
      if (cur.key === "" || cur.value === "") return { ...obj };
      return { ...obj, [cur.key]: cur.value };
    }, {});
    setLeadForm({ ...leadForm, configurations: configurations });
  };

  const handleInput = (event: any) => {
    const { id, value } = event.target;
    setLeadForm({ ...leadForm, [id]: value });
    if (value === "" || value === null) {
      if (mandatoryFields.includes(id)) {
        setValidationError((prev) => ({
          ...prev,
          [id]: "Error",
        }));
      } else {
        setValidationError((prev) => ({
          ...prev,
          [id]: "",
        }));
      }
    } else {
      setValidationError((prev) => ({ ...prev, [id]: "" }));
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(leadForm)) {
      if (mandatoryFields.includes(key) && value.toString() === "") {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    addLead();
  };

  function addLead() {
    setLoading(true);
    const onSuccess = (res: any) => {
      if (res.status === 201) {
        setLoading(false);
        navigate(-1);
      }
    };
    const onError = (err: any) => {
      setLoading(false);
      if (err.response.status === 400) {
        if (err?.response?.data.message.includes("Phone")) {
          setValidationError((prev) => ({ ...prev, phone: "Already in use" }));
        }
      }
    };
    LEAD_SERVICE.postLeads(onSuccess, onError, leadForm);
  }

  return (
    <>
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}
        >
          Please fill all fields
        </Typography>
      ) : null}
      <Grid
        container
        width={{ xs: "100%", md: "800px" }}
        p={{ xs: "10px 0px 20px", md: "20px 0" }}
      >
        <Grid item sm={6} xs={12} pb="10px" pr="15px">
          <CustomTextField
            label="Name"
            id="name"
            onChange={handleInput}
            value={leadForm.name}
            error={validationError.name}
          />
        </Grid>
        <Grid item sm={6} xs={12} pb="10px" pl="15px">
          <CustomTextField
            label="Email"
            id="email"
            onChange={handleInput}
            value={leadForm.email}
            error={validationError.email}
          />
        </Grid>
        <Grid item sm={6} xs={12} pb="10px" pr="15px">
          <CustomTextField
            label="Phone"
            required
            id="phone"
            onChange={handleInput}
            value={leadForm.phone}
            error={validationError.phone}
            errorText={
              validationError.phone === "Error" ? "" : validationError.phone
            }
          />
        </Grid>
        <Grid item sm={6} xs={12} pb="10px" pl="15px">
          <CustomTextField
            label="Address"
            id="address"
            onChange={handleInput}
            value={leadForm.address}
            error={validationError.address}
          />
        </Grid>
        <Grid item sm={6} xs={12} pb="10px" pr="15px">
          <CustomTextField
            label="Leadset"
            id="leadsetId"
            value={leadsetDetails.name}
            disabled
          />
        </Grid>
        <Grid item sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { sm: "space-between", xs: "center" },
              border: " 1px solid rgba(0, 0, 0, 0.1)",
              padding: 1,
              borderRadius: 1,
              gap: 1,
              mt: "15px",
              mb: "10px",
              flexWrap: { sm: "nowrap", xs: "wrap" },
            }}
          >
            <Box
              display="flex"
              alignItems={"flex-end"}
              justifyContent="space-between"
            >
              <Box>
                <Typography lineHeight={"30px"}>Add Fields</Typography>
                <Typography>Column</Typography>
              </Box>
              <Typography pl="40px">Value</Typography>
              <Button
                onClick={() => {
                  setConfigurationFields([
                    ...configurationFields,
                    { key: "", value: "" },
                  ]);
                }}
                sx={{ color: "#0066FF", fontSize: "16px" }}
              >
                +Add
              </Button>
            </Box>
            {configurationFields.map((field, index: number) => (
              <Box
                display={"flex"}
                justifyContent="space-between"
                key={index}
                gap={"10px"}
              >
                <TextField
                  id={index.toString()}
                  name="key"
                  size="small"
                  fullWidth
                  onChange={(event: any) => handleConfigurations(event, index)}
                />
                <TextField
                  id={index.toString()}
                  name="value"
                  size="small"
                  fullWidth
                  onChange={(event: any) => handleConfigurations(event, index)}
                />
              </Box>
            ))}
          </Box>
        </Grid>

        <Box p=" 0 15px" display={"flex"} justifyContent="flex-start">
          <Box
            display="flex"
            gap={2}
            justifyContent="center"
            p="20px 0"
            flexWrap={"wrap"}
          >
            <FormActionButton
              title="Save"
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
            />
            <FormActionButton
              title="Cancel"
              onClick={() => navigate(-1)}
              disabled={loading}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default CreateLeadForm;
