import { PayloadAction } from "@reduxjs/toolkit";
import { TCampaigns } from "../types/campaigns";

function setCampaigns(state: TCampaigns, action: PayloadAction<TCampaigns>) {
  return action.payload;
}

export const CAMPAIGNS_REDUCERS = {
  setCampaigns,
};

export type TCampaignsReducer = typeof CAMPAIGNS_REDUCERS;
