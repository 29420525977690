import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from "../shared/context/AuthContext";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authToken, setAuthToken] = useState("");
  const [error, setError] = useState("");

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken, error, setError }}>
      <BrowserRouter>{children}</BrowserRouter>
    </AuthContext.Provider>
  );
};
