import React from "react";

const TimerIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H42C46.4183 0 50 3.58172 50 8V42C50 46.4183 46.4183 50 42 50H8C3.58172 50 0 46.4183 0 42V8Z"
        fill="#967FD8"
        fillOpacity="0.2"
      />
      <path
        d="M25 13C21.9291 13 18.8576 14.171 16.5146 16.5141C11.8285 21.2003 11.8285 28.7992 16.5146 33.4853C21.2006 38.1716 28.7994 38.1716 33.4854 33.4853C38.1715 28.7992 38.1715 21.2003 33.4854 16.5141C31.1424 14.171 28.0709 13 25 13ZM25 16.1347C27.2688 16.1347 29.5382 16.9992 31.2692 18.7303C34.7313 22.1925 34.7313 27.8069 31.2692 31.2691C27.8072 34.7312 22.1929 34.7312 18.7308 31.2691C15.2686 27.8069 15.2687 22.1925 18.7308 18.7303C20.4618 16.9992 22.7312 16.1347 25 16.1347ZM23.7725 18.1488V23.7546H20.6657V26.8234H26.8412V18.1488H23.7725Z"
        fill="#967FD8"
      />
    </svg>
  );
};

export default TimerIcon;
