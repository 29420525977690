import DialpadIcon from "@mui/icons-material/Dialpad";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CallIconYellow from "../../assets/images/CallIconYellow";
import MuteIcon from "../../assets/images/MuteIcon";
import SecondaryCallEndIcon from "../../assets/images/SecondaryCallEndIcon";
import { CALL_STATES } from "../../pages/CallScreen";
import { CALL_SERVICE } from "../../services/CallService";
import { useCallProvider } from "../../shared/hooks/useCallProvider";
import { useClientSocket } from "../../shared/hooks/useClientSocket";
import { callSlice } from "../../state/call/callSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { CallStatus } from "../../state/types/call";
import { UserStateStatus } from "../../state/types/user";
import { userSlice } from "../../state/user/userSlice";
import { BreakStatusPopup } from "../BreakStatus/BreakStatusPopup";
import FloatingButton from "../Custom/FloatingButton";
import BasicDTMFPopUp from "../DTMF/BasicDTMFPopUp";
import DispositionPopUp from "../DispositionPopUp/Popup/DispositionPopUp";

const CallActionButtons = ({ callState, setCallState }: any) => {
  const [isMuted, setIsMuted] = useState<boolean | undefined>(false);
  const [isOnHold, setIsOnHold] = useState<boolean | undefined>(false);
  const { callCode } = useAppSelector((state) => state.call);
  const [openBasicDTMF, setOpenBasicDTMF] = useState(false);
  const [openDisposition, setOpenDisposition] = useState(false);
  const [openBreakStatus, setOpenBreakStatus] = useState(false);
  const callProvider = useCallProvider();
  const userStateStatus = useAppSelector((state) => state.user.status);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const toggleState = ({ newState }: { newState: string }) => {
    if (newState === callState) setCallState(CALL_STATES.TALKING);
    else setCallState(newState);
  };
  const { shortcutKeys } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (shortcutKeys === true) {
      window.addEventListener("keyup", handleKeyPress);
    } else {
      window.removeEventListener("keyup", handleKeyPress);
    }
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [shortcutKeys]);

  const handleKeyPress = useCallback((e: any) => {
    const event = e;

    if (
      event.target.tagName !== "INPUT" &&
      event.target.tagName !== "TEXTAREA"
    ) {
      // console.log(event.keyCode);
      // console.log(event.target.tagName);

      if (event.shiftKey) {
        switch (event.keyCode) {
          case 72:
            handleCallDisconnect();
            break;
          default:
            break;
        }
      } else {
        switch (event.keyCode) {
          case 68:
            setOpenBasicDTMF(true);
            break;
          case 72:
            toggleHold();
            break;
          case 32:
            toggleMute();
            break;
          case 77:
            toggleMute();
            break;
          default:
            break;
        }
      }
    }
  }, []);

  const toggleHold = () => {
    if (callProvider?.isOnHold()) callProvider.unHold();
    else callProvider?.hold();

    setIsOnHold(callProvider?.isOnHold());
    dispatch(
      callSlice.actions.updateCallOutcome(
        callProvider?.isOnHold() === true
          ? "On Hold"
          : callProvider?.isMuted() === true
          ? "On Mute"
          : ""
      )
    );
  };

  const toggleMute = () => {
    if (callProvider?.isMuted()) callProvider.unMute();
    else callProvider?.mute();

    setIsMuted(callProvider?.isMuted());
    dispatch(
      callSlice.actions.updateCallOutcome(
        callProvider?.isOnHold() === true
          ? "On Hold"
          : callProvider?.isMuted() === true
          ? "On Mute"
          : ""
      )
    );
  };

  useEffect(() => {
    if (userStateStatus === UserStateStatus.DISPOSITION) {
      setCallState(CALL_STATES.ENDED);
      setOpenDisposition(true);
    } else if (userStateStatus === UserStateStatus.REDIAL_CALL) {
      setOpenDisposition(false);
    }
  }, [userStateStatus]);

  const handleBreak = () => {
    setOpenDisposition(false);
    setOpenBreakStatus(true);
  };

  const handleCloseBreak = () => {
    if (userStateStatus === UserStateStatus.DISPOSITION) {
      dispatch(userSlice.actions.goToReady());
    }
  };

  function handleCallDisconnect() {
    const onSuccess = (data: any) => {
      const response = data.data;
      const errorStatus = response.errorStatus;
      // if (errorStatus === false) {
      //   dispatch(
      //     userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION)
      //   );
      // } else {
      // }
      dispatch(
        callSlice.actions.updateCallStatus(CallStatus.CALL_DISCONNECTED)
      );
      dispatch(userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION));
      dispatch(callSlice.actions.updateCallOutcome(""));
    };
    const onError = () => {};
    const dto = {
      callCode: callCode,
    };

    CALL_SERVICE.disconnectCall(onSuccess, onError, dto);
  }

  return (
    <>
      {userStateStatus === UserStateStatus.DISPOSITION ? null : (
        <Box>
          <FloatingButton
            color="#93CE15"
            sx={{
              color: "white",
              marginTop: { xs: "126px", md: "0" },
            }}
            transform={smallScreen ? "scale(0.9)" : "scale(1)"}
            Icon={DialpadIcon}
            onClick={() => setOpenBasicDTMF(true)}
            isActive={openBasicDTMF}
            tooltip="Open DTMF(D)"
            activeToolTip="Close DTMF (D)"
          />
          <FloatingButton
            sx={{
              marginTop: { xs: "178px", md: "70px" },
            }}
            color="#EFD053"
            transform={smallScreen ? "scale(0.9, 0.85)" : "scale(1.22,1.24)"}
            Icon={CallIconYellow}
            onClick={() => {
              toggleHold();
              // toggleState({ newState: CALL_STATES.ON_HOLD });
            }}
            isActive={isOnHold}
            tooltip="Hold Call (H)"
            activeToolTip="Unhold Call (H)"
          />
          {/* <FloatingButton
          sx={{
            marginTop: { xs: "238px", md: "140px" },
          
          }}
          color="#6F5FD9"
          Icon={CallTransferIcon}
        /> */}
          <FloatingButton
            sx={{
              marginTop: { xs: "230px", md: "140px" },
              color: "black",
            }}
            color={"#CE3A3A"}
            transform={smallScreen ? "scale(0.8)" : "scale(1)"}
            Icon={MuteIcon}
            onClick={() => {
              toggleMute();
              // toggleState({ newState: CALL_STATES.ON_MUTE });
            }}
            isActive={isMuted}
            tooltip="Mute Call (M / Space)"
            activeToolTip="UnMute Call (M / Space)"
          />
          {/* <FloatingButton
          sx={{ marginTop: { xs: "350px", md: "280px" }, }}
          color="#426EC3"
          Icon={ConferenceCallIcon}
        /> */}
          <FloatingButton
            sx={{ marginTop: { xs: "282px", md: "210px" } }}
            color={"#FF0000"}
            transform={smallScreen ? "scale(1, 0.95)" : "scale(1)"}
            Icon={SecondaryCallEndIcon}
            onClick={() => {
              // clientSocket?.emit(SOCKET_EVENTS.CALL_DISCONNECT, {
              //   callCode: callCode,
              // });
              handleCallDisconnect();
            }}
            isActive={true}
            tooltip="Hangup Call (Shift+H)"
            activeToolTip="Hangup Call (Shift+H)"
          />
        </Box>
      )}
      <BasicDTMFPopUp open={openBasicDTMF} setOpen={setOpenBasicDTMF} />

      <DispositionPopUp
        open={openDisposition}
        setOpen={setOpenDisposition}
        handleBreak={handleBreak}
      />
      <BreakStatusPopup
        openPopup={openBreakStatus}
        setOpenPopup={setOpenBreakStatus}
        onClose={handleCloseBreak}
      />
    </>
  );
};

export default CallActionButtons;
