import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box } from "@mui/system";
import { useState } from "react";
import { DOWNLOADER } from "../../helper/downloader";
import { CALL_SERVICE } from "../../services/CallService";
import { CDRFilters } from "../../services/dto/GetAgentCdrDTO";
import { filterFormSlice } from "../../state/filter-form/filterFormSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { UserAccessLevel } from "../../state/types/user";
import BackdropLoader from "../BackdropLoader";
import RoundedButtons from "../Custom/RoundedButtons";
import { FilterPopup } from "../RecordingLog/FilterPopup";

const AgentLoggerActions = ({
  filters,
  search,
  type,
  sort,
  sortDirection,
}: {
  filters?: CDRFilters;
  search?: string;
  type: string;
  sort?: string;
  sortDirection?: string;
}) => {
  const { filterForm } = useAppSelector((state) => state);
  const [filterPopup, setFilterPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { accessLevel } = useAppSelector((state) => state.user.userDetails);
  const isAdmin = accessLevel === UserAccessLevel.ADMIN;

  const clearFilters = () => {
    dispatch(filterFormSlice.actions.resetForm());
  };
  const handleExport = () => {
    if (!isAdmin) return;
    const onSuccess = (data: any) => {
      setLoading(false);
      console.log(data);
      DOWNLOADER.download(
        data.data,
        `AgentLogger-${new Date().valueOf()}.xlsx`
      );
    };
    const onError = (err: any = {}) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    CALL_SERVICE.exportAgentLogger(onSuccess, onError, {
      search: search,
      startIndex: 0,
      limit: 0,
      sortDirection: sortDirection,
      sort: sort,
      "filter-name": filterForm["filter-name"],
      "filter-phone": filterForm["filter-phone"],
      "filter-disconnectedBy": filterForm["filter-disconnectedBy"],
      "filter-startAfter": filterForm["filter-startAfter"],
      "filter-startBefore": filterForm["filter-startBefore"],
      "filter-direction": filterForm["filter-direction"],
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <RoundedButtons
          title="Clear"
          Icon={CloseIcon}
          color="#c1bdbd"
          sx={{
            color: "black",
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.25)",
          }}
          IconColor="black"
          onClick={clearFilters}
        />
        <RoundedButtons
          tooltip={"Set Filter"}
          onClick={() => {
            setFilterPopup(true);
          }}
          title="Filter"
          Icon={FilterAltIcon}
          color="#E2606C"
        />
        {isAdmin && (
          <RoundedButtons
            tooltip="Export current data"
            title="Export"
            Icon={CloudDownloadIcon}
            color="#5ECE62"
            onClick={handleExport}
            disabled={!isAdmin}
          />
        )}
      </Box>
      <FilterPopup
        filters={filters}
        open={filterPopup}
        setOpen={setFilterPopup}
        type={type}
      />
      {loading && <BackdropLoader loading={loading} />}
    </>
  );
};

export default AgentLoggerActions;
