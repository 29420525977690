import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI } from "./API";

function getBreakStats(onSuccess: any, onError: any) {
  GetAPI.call(
    URL_CONSTANTS.AGENT_DASHBOARD_BREAK_STATS,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}

function getCallStats(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.AGENT_DASHBOARD_CALL_STATS,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}

function getTodayTimes(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.AGENT_DASHBOARD_TODAY_TIMES,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}

function getTodayAlerts(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.AGENT_DASHBOARD_TODAY_ALERTS,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}

function getUserDetails(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.GET_USER_DETAILS,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}

function getLeadsTaken(onSuccess: Function, onError: Function, query?: any) {
  GetAPI.call(
    URL_CONSTANTS.AGENT_DASHBOARD_LEADS_TAKEN,
    (res: any) => onSuccess(res.data),
    { params: query },
    onError
  );
}

export const AGENT_DASHBOARD_SERVICE = {
  getBreakStats,
  getCallStats,
  getTodayTimes,
  getTodayAlerts,
  getUserDetails,
  getLeadsTaken,
};
