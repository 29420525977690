import { PayloadAction } from "@reduxjs/toolkit";
import { TDispositions } from "../types/dispositions";
import { GetDispositionDTO } from "../../services/dto/GetDispositionDTO";

function setDispositions(
  state: TDispositions,
  action: PayloadAction<GetDispositionDTO[]>
) {
  return { ...state, disposition: action.payload };
}

function setMessage(state: TDispositions, action: PayloadAction<string>) {
  return { ...state, message: action.payload };
}

type ValueTypes = keyof TDispositions;
function updateDispositionData(
  state: TDispositions,
  action: PayloadAction<
    { key: ValueTypes; value: string | GetDispositionDTO[] }[]
  >
) {
  let updatedState: TDispositions = { ...state };

  action.payload.forEach(({ key, value }) => {
    updatedState = { ...updatedState, [key]: value };
  });

  return updatedState;
}

export const DISPOSITIONS_REDUCERS = {
  setDispositions,
  setMessage,
  updateDispositionData,
};

export type TDispositionsReducer = typeof DISPOSITIONS_REDUCERS;
