import { Height } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DarkTooltip } from "../DarkToolTip";

const FloatingButton = ({
  color,
  Icon,
  sx,
  show = true,
  onClick,
  isActive = true,
  loading = false,
  transform = "scale(1)",
  width = "100px",
  height = "50px",
  text,
  hover_expand = true,
  tooltip,
}: {
  color: any;
  Icon?: any;
  sx?: any;
  show?: boolean;
  onClick?: any;
  isActive?: boolean;
  loading?: boolean;
  transform?: any;
  width?: string;
  height?: string;
  text?: string;
  hover_expand?: boolean;
  tooltip?: string;
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <DarkTooltip title={tooltip}>
      <IconButton
        onClick={() => {
          !loading && onClick && onClick();
        }}
        size="small"
        color="inherit"
        sx={{
          display: show ? "flex" : "none",
          backgroundColor: isActive ? color : theme.palette.common.white,
          border: `1px solid ${color}`,
          position: "fixed",
          top: "180px",
          color: "white",
          justifyContent: "start",
          width: { md: width, xs: 36 },
          height: { md: height, xs: 36 },
          borderRadius: { xs: "10px", md: "10px 0 0 10px" },
          zIndex: 100,
          fontSize: 16,
          padding: { md: 1.5, xs: 1.2 },
          right: { xs: "-4px", md: loading ? "-1px" : "-51px" },
          transition: "all 0.2s ease-in-out",
          ":hover": {
            backgroundColor: isActive ? color : theme.palette.common.white,
            right: hover_expand === true ? "-1px" : "-51px",
          },
          ...sx,
        }}
        style={{ justifyContent: "center" }}>
        <Box
          width={{ xs: "21px", md: "70px" }}
          height={{ xs: "16.5px", md: "25px" }}
          display="flex"
          alignItems="center">
          <Box width="50px" height="50px" display="flex" alignItems="center">
            {loading ? (
              <CircularProgress
                size={smallScreen ? "15px" : "25px"}
                sx={{
                  color: "common.white",
                }}
              />
            ) : (
              <Icon
                color={isActive ? theme.palette.common.white : color}
                // fontSize={smallScreen ? "medium" : "large"}
                sx={{
                  color: isActive ? theme.palette.common.white : color,
                  fontSize: smallScreen ? "22px" : "27px",
                }}
              />
            )}
          </Box>
          <span style={{ fontSize: "18px", paddingLeft: "14px", flex: 1 }}>
            {text}
          </span>
        </Box>
      </IconButton>
    </DarkTooltip>
  );
};

export default FloatingButton;
