import { Box, Typography } from "@mui/material";
import React from "react";

function CallDetailsSquareCard({
  sx,
  value,
  title,
  image_url,
}: {
  sx?: any;
  value: string;
  title: string;
  image_url: string;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "16px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}>
      <Typography sx={{ fontSize: "32px" }}>{value}</Typography>
      <Typography sx={{ fontSize: "16px" }}>{title}</Typography>
      <img
        style={{
          maxWidth: "100%",
          alignSelf: "center",
          marginTop: "10px",
          height: "45%",
        }}
        src={image_url}
      />
    </Box>
  );
}

export default CallDetailsSquareCard;
