import { PayloadAction } from "@reduxjs/toolkit";
import { TUserDetailsFormState } from "../types/userForm";

function setUserDetailsForm(
  state: TUserDetailsFormState,
  action: PayloadAction<TUserDetailsFormState>
) {
  return action.payload;
}

function updateUserDetailsForm(
  state: TUserDetailsFormState,
  action: PayloadAction<{ [key: string]: string }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

export const USER_DETAILS_FORM_REDUCERS = {
  setUserDetailsForm,
  updateUserDetailsForm,
};

export type TUserDetailsFormReducer = typeof USER_DETAILS_FORM_REDUCERS;
