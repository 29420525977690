import { ClickAwayListener, List } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useCallback, useEffect } from "react";
import TitleBox from "./TitleBox";
import { useAppSelector } from "../../state/hooks";
import SideNavItem from "./SideNavItem";
import { SideNavMenu } from "./SideNavMenu";
import { CustomNavLink } from "./SideNavBar";
import { UserAccessLevel } from "../../state/types/user";

const drawerWidth = 350;
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    border: "none",
    width: drawerWidth,
    overflowX: "hidden",
    backgroundColor: "#FFFFFF",
    boxShadow: open ? "10px -1px 20px -9px rgba(0,0,0,0.18)" : "none",
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",

      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(12.5),
      },
    }),
  },
}));

const DrawerLarge = ({ navLinks }: { navLinks: CustomNavLink[] }) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawerLarge = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const { accessLevel } = useAppSelector((state) => state.user.userDetails);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer
        sx={{ display: { sm: "flex", xs: "none" }, mr: 10 }}
        variant="permanent"
        open={open}>
        <TitleBox />
        <List component="nav" sx={{ pt: "13px", ml: "8px" }}>
          <>
            <ChevronRightIcon
              onClick={toggleDrawerLarge}
              sx={{
                fontSize: 40,
                ml: 2.7,
                mb: 1,
                mt: 1,
                color: "gray",
                cursor: "pointer",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "0.3s",
              }}
            />
            {navLinks.map((link: CustomNavLink) =>
              link.children ? (
                <SideNavMenu
                  userAccessLevel={accessLevel}
                  isDrawerOpen={open}
                  setDrawerOpen={setOpen}
                  key={link.link.id}
                  title={link.link.title}
                  navLink={link}
                />
              ) : (
                <SideNavItem
                  userAccessLevel={accessLevel}
                  navLink={link}
                  isExpanded={open}
                  setExpanded={setOpen}
                  key={link.link.id}
                  onNavigate={link.link.onNavigate}
                />
              )
            )}
          </>
        </List>
      </Drawer>
    </ClickAwayListener>
  );
};

export default DrawerLarge;
