import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";

interface TCallState {
  missedCallbackLogs: GetCallbackDTO[];
  upcomingCallbackLogs: GetCallbackDTO[];
}

const initialState: TCallState = {
  missedCallbackLogs: [],
  upcomingCallbackLogs: [],
};

export const callbackLogsSlice = createSlice({
  name: "callbackLogs",
  initialState,
  reducers: {
    updateMissedCallbacks: (state, action) => {
      state.missedCallbackLogs = action.payload;
    },
    updateUpcomingCallbacks: (state, action) => {
      state.upcomingCallbackLogs = action.payload;
    },
  },
});
