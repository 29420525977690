import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import EditIcon from "../../assets/images/EditIcon";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { CAMPAIGN_SERVICE } from "../../services/CampaignService";
import { GetCampaignDTO } from "../../services/dto/GetCampaignDTO";
import { ALL_ROUTES } from "../../shared/routes";
import { campaignSlice } from "../../state/campaign/campaignSlice";
import { useAppDispatch } from "../../state/hooks";
import { TCampaignState } from "../../state/types/campaign";
import DisableCampaignPopUp from "../ConfirmationPopUP/DisableCampaignPopUp";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import CustomActionButton from "../Custom/CustomActionButton";
import VerticalTableCell from "../Table/VerticalTableCell";
import VerticalTableRow from "../Table/VerticalTableRow";
import VerticalTableTile from "../Table/VerticalTableTile";
import SettingsDropDown from "./SettingsDropDown";

const ManageCampaignsTableMobile = ({
  campaigns,
  onAction,
  startIndex,
}: {
  campaigns: GetCampaignDTO[];
  onAction: Function;
  startIndex: number;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDisbaleCampaign, setOpenDisbaleCampaign] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<any>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const deleteCampaign = () => {
    const onSuccess = () => {
      setLoadingDelete(false);
      setOpenDeleteConfirmation(false);
      onAction();
    };
    const onError = (err: any) => {
      console.log(err);
      setLoadingDelete(false);
    };
    setLoadingDelete(true);
    CAMPAIGN_SERVICE.deleteCampaign(onSuccess, onError, selectedCampaign.id);
  };

  const handleCampaignStatus = () => {
    setLoadingStatus(true);
    if (selectedCampaign.status === "DISABLED") {
      CAMPAIGN_SERVICE.enableCampaign(
        () => {
          setOpenConfirmation(false);
          onAction();
          setLoadingStatus(false);
        },
        () => setLoadingStatus(false),
        selectedCampaign.id
      );
    } else {
      CAMPAIGN_SERVICE.disableCampaign(
        () => {
          setOpenDisbaleCampaign(false);
          onAction();
          setLoadingStatus(false);
        },
        () => setLoadingStatus(false),
        selectedCampaign.id
      );
    }
  };

  const handleEdit = (campaign: TCampaignState) => {
    dispatch(
      campaignSlice.actions.setCampaign(campaignSlice.getInitialState())
    );
    dispatch(campaignSlice.actions.setCampaign(campaign));
    navigate(ALL_ROUTES.EDIT_CAMPAIGN);
  };
  return (
    <>
      {campaigns.map((campaign, index) => (
        <VerticalTableTile key={index}>
          <VerticalTableRow>
            <VerticalTableCell
              position="l"
              title={
                startIndex == 0 && index < 9
                  ? "0" + (index + 1)
                  : `${startIndex + (index + 1)}`
              }
            />
          </VerticalTableRow>
          <VerticalTableRow>
            <VerticalTableCell
              position="l"
              title="Name"
              subtitle={campaign.name}
              onClick={() => {
                dispatch(campaignSlice.actions.setCampaign(campaign));
                navigate(
                  generatePath(ALL_ROUTES.PROCESS_DETAILS, {
                    id: campaign.id.toString(),
                  })
                );
              }}
            />
            <VerticalTableCell
              position="r"
              title="Description"
              subtitle={campaign.description}
            />
          </VerticalTableRow>
          <VerticalTableRow>
            <VerticalTableCell
              position="l"
              title="Client"
              subtitle={campaign.client}
            />
            <Box display={"flex"} alignItems="center">
              <CustomActionButton
                backgroundColor={
                  campaign.status === "DISABLED" ? "#5ECE62" : "#EB5757"
                }
                title={campaign.status === "ENABLED" ? "Disable" : "Enable"}
                onClick={() => {
                  setSelectedCampaign(campaign);
                  if (campaign.status === "ENABLED")
                    setOpenDisbaleCampaign(true);
                  else setOpenConfirmation(true);
                }}
              />
              <SettingsDropDown />
              {campaign.status === "ENABLED" ? (
                <IconButton onClick={() => handleEdit(campaign)}>
                  <EditIcon color="#5ECE62" />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setSelectedCampaign(campaign);
                    setOpenDeleteConfirmation(true);
                  }}>
                  <LeadsetDeleteIcon />
                </IconButton>
              )}
            </Box>
          </VerticalTableRow>
        </VerticalTableTile>
      ))}
      <DisableCampaignPopUp
        campaignName={selectedCampaign?.name}
        open={openDisbaleCampaign}
        setOpen={setOpenDisbaleCampaign}
        onClick={handleCampaignStatus}
        loading={loadingStatus}
      />
      <SimpleConfirmationPopUp
        value={selectedCampaign?.name}
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        action={"Enable"}
        onAction={handleCampaignStatus}
        loading={loadingStatus}
      />
      <SimpleConfirmationPopUp
        value={selectedCampaign?.name}
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onAction={deleteCampaign}
        action={"Delete"}
        loading={loadingDelete}
      />
    </>
  );
};

export default ManageCampaignsTableMobile;
