import { Box, Slider, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

export const Player = ({
  isPlaying,
  setIsPlaying,
  currentAudio,
  duration,
  currentTime,
}: {
  isPlaying?: boolean;
  setIsPlaying?: Function;
  currentAudio?: string;
  duration?: any;
  currentTime?: any;
}) => {
  const styledSlider = {
    color: "black",
    height: 4,
    "& .MuiSlider-thumb": {
      color: "#3d70d8",
      "&:before": {
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
      },
      "&:hover, &.Mui-focusVisible": {
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
      },
    },
    "& .MuiSlider-rail": {
      opacity: 1,
    },
    "& .MuiSlider-track": {
      color: "#3d70d8",
    },
  };
  return (
    <Box
      width="190px"
      height="25px"
      display="flex"
      alignItems={"center"}
      gap={1}
      sx={{
        backgroundColor: "#484948",
        borderRadius: "10px 8px 8px 10px",
        "&::after": {
          content: '""',
          position: "absolute",
          top: "0px",
          right: "2px",
          borderTop: "12.5px solid transparent",
          borderLeft: "24px solid #484948",
          borderTopLeftRadius: "7px",
          borderBottomLeftRadius: "7px",
          borderBottom: "12.5px solid transparent",
        },
      }}
    >
      <PlayArrowIcon
        sx={{
          color: "white",
          fontSize: "16px",
          pl: "5px",
        }}
      />
      <Box width="40px" display="flex" alignItems={"center"}>
        <Slider size="small" sx={styledSlider} />
      </Box>
      <Box display="flex">
        <Typography fontSize={"8px"} color="white" fontFamily={"Roboto"}>
          0:00
        </Typography>
        <Typography fontSize={"8px"} color="#999" fontFamily={"Roboto"}>
          &nbsp;/ 0:00
        </Typography>
      </Box>
      <VolumeUpIcon
        sx={{
          color: "white",
          fontSize: "16px",
        }}
      />
      <Box width="30px" display="flex" alignItems={"center"}>
        <Slider
          size="small"
          sx={{
            ...styledSlider,
            "& .MuiSlider-thumb": {
              color: "#fff",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
            },
            "& .MuiSlider-track": {
              color: "#fff",
            },
          }}
        />
      </Box>
    </Box>
  );
};
