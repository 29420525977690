import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} arrow placement="left" {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxShadow: "0 0px 4px rgba(0,0,0,0.25)",
    fontSize: 11,
    padding: 10,
    borderRadius: "5px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
}));
