function ForceLougoutIcon({
  color = "#EB5757",
  height = "16",
  width = "16",
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.26172 15.5C4.11947 15.5 0.761719 12.1423 0.761719 8C0.761719 3.85775 4.11947 0.5 8.26172 0.5C12.404 0.5 15.7617 3.85775 15.7617 8C15.7617 12.1423 12.404 15.5 8.26172 15.5ZM12.0117 11L15.7617 8L12.0117 5V7.25H6.01172V8.75H12.0117V11Z"
        fill={color}
      />
    </svg>
  );
}

export default ForceLougoutIcon;
