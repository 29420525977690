import React from "react";

const ReadyIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#5ECE62" fillOpacity="0.2" />
      <path
        d="M24.6301 19.5376C24.7079 19.5376 24.7838 19.5413 24.8597 19.5469L24.6078 20.1413L24.519 20.3876L24.5041 20.4339C24.1282 21.6599 24.4486 23.0452 25.4171 23.9656L25.982 24.5027C25.7998 24.9837 25.5381 25.4306 25.2079 25.8249L24.6097 25.6231L24.3578 25.5546L24.306 25.5434C23.666 25.4098 23.0007 25.4702 22.3952 25.717C21.7898 25.9638 21.2718 26.3857 20.9077 26.9287L20.0169 28.238L19.8835 28.4603L19.8595 28.501C19.5177 29.1379 19.3818 29.8652 19.4706 30.5826C19.0312 30.6276 18.5899 30.6499 18.1483 30.6493C12.3332 30.6493 8.88855 26.8917 8.88855 23.2415V22.3155C8.88855 21.5788 9.18122 20.8722 9.70218 20.3512C10.2231 19.8303 10.9297 19.5376 11.6665 19.5376H24.6301Z"
        fill="#5ECE62"
      />
      <path
        d="M24.0727 27.3973L25.4431 27.8529L25.6672 27.927C26.3043 27.4454 26.8339 26.8399 27.2599 26.1102C27.6247 25.4842 27.8618 24.849 27.9747 24.2045L28.021 23.8823L26.6932 22.623C26.4782 22.4143 26.3253 22.1502 26.2514 21.8599C26.1775 21.5696 26.1856 21.2645 26.2746 20.9785L26.3302 20.821L26.9339 19.4006C27.0582 19.0859 27.2885 18.8244 27.5851 18.6614C27.8816 18.4985 28.2258 18.4442 28.5581 18.508L28.7007 18.545L29.4544 18.7858C30.2026 19.0265 30.7767 19.6673 30.9619 20.471C31.4008 22.3859 30.873 24.7157 29.3785 27.4603C27.8858 30.2011 26.2487 31.8512 24.469 32.405C24.1294 32.5105 23.7687 32.5285 23.4203 32.4573C23.0719 32.386 22.7472 32.2279 22.4763 31.9975L22.3282 31.8605L21.7559 31.2864C21.5145 31.038 21.3586 30.7192 21.3106 30.3762C21.2627 30.0333 21.3252 29.6839 21.4892 29.3789L21.5744 29.2418L22.4393 27.9714C22.5814 27.7587 22.7771 27.5873 23.0067 27.4745C23.2363 27.3617 23.4915 27.3114 23.7467 27.3288C23.8053 27.3337 23.8634 27.3424 23.9208 27.3547L24.0727 27.3973Z"
        fill="#5ECE62"
      />
      <path
        d="M18.1483 7.5C19.499 7.5 20.7944 8.03657 21.7495 8.99166C22.7045 9.94675 23.2411 11.2421 23.2411 12.5928C23.2411 13.9436 22.7045 15.2389 21.7495 16.194C20.7944 17.1491 19.499 17.6857 18.1483 17.6857C16.7976 17.6857 15.5022 17.1491 14.5471 16.194C13.592 15.2389 13.0554 13.9436 13.0554 12.5928C13.0554 11.2421 13.592 9.94675 14.5471 8.99166C15.5022 8.03657 16.7976 7.5 18.1483 7.5Z"
        fill="#5ECE62"
      />
    </svg>
  );
};

export default ReadyIcon;
