import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../assets/images/EditIcon";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { GetProcessDTO } from "../../services/dto/GetProcessDTO";
import { PROCESS_SERVICE } from "../../services/ProcessService";
import { ALL_ROUTES } from "../../shared/routes";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import CustomActionButton from "../Custom/CustomActionButton";
import DataNotFound from "../DataNotfound/DataNotFound";
import ToggleButton from "../EVCustoms/ToggleButton/ToggleButton";
import { LeadsBifurcationPopup } from "../LeadsBifurcationPopup";
import ChurnProcessPopup from "../Leadset/ChurnProcessPopup";
import LightTooltip from "../LightToolTip";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import TableProgress from "../Table/TableProgress";
import AgentSelectionPopup from "./Actions/AgentSelectionPopup";
import { ChangeRatioPopup } from "./Actions/ChangeRatioPopup";
import LeadsetSelectionPopup from "./Actions/LeadsetSelectionPopup";
import SettingsDropDown from "./Actions/SettingsDropDown";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "50px" },
  { id: "name", label: "Name", allowSort: true, width: "110px" },
  { id: "callingMode", label: "Calling Mode", allowSort: true, width: "130px" },
  { id: "churn", label: "Churning", allowSort: false, width: "80px" },
  { id: "running", label: "State", allowSort: true, width: "130px" },
  { id: "agent", label: "Agent", allowSort: false, width: "80px" },
  { id: "leadset", label: "Leadset", allowSort: false, width: "80px" },
  { id: "ratio", label: "Ratio", allowSort: true, width: "80px" },
  {
    id: "reaminingLeads",
    label: "Remaining Leads",
    allowSort: true,
    width: "150px",
  },
  // { id: "setting", label: "Setting", allowSort: false, width: "68px" },
  { id: "status", label: "Status", allowSort: true, width: "130px" },
  { id: "action", label: "Actions", allowSort: false, width: "75px" },
];

const ProcessDetailsTable = ({
  processDetails,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  onAction,
  onReloadRemaingLeads,
  startIndex,
  loader,
  loaderRemainingLeads,
}: {
  processDetails: GetProcessDTO[];
  order: "asc" | "desc";
  orderBy: string;
  setOrder: any;
  setOrderBy: any;
  onAction: () => void;
  onReloadRemaingLeads: () => void;
  startIndex: number;
  loader: boolean;
  loaderRemainingLeads: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openAgentPopup, setOpenAgentPopup] = useState(false);
  const [openChangeRatio, setOpenChangeRatio] = useState(false);
  const [openLeadset, setOpenLeadset] = useState(false);
  const [openChurnPopup, setOpenChurnPopup] = useState(false);
  const [openLeadsBifurcation, setOpenLeadsBifurcation] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState<GetProcessDTO>();
  const [openStatusConfirmation, setOpenStatusConfirmation] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { id } = useParams();
  const [localLoader, setLocalLoader] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const handleProcessStatus = () => {
    setLoadingStatus(true);
    const onSucess = () => {
      setLoadingStatus(false);
      setOpenStatusConfirmation(false);
      onAction();
    };
    const onError = (err: any) => {
      console.log(err);
      setLoadingStatus(false);
      setOpenStatusConfirmation(false);
    };
    if (selectedProcess?.status === "ENABLED") {
      PROCESS_SERVICE.disableProcess(
        onSucess,
        onError,
        selectedProcess.id.toString()
      );
    } else {
      PROCESS_SERVICE.enableProcess(
        onSucess,
        onError,
        selectedProcess?.id.toString() ?? ""
      );
    }
  };

  const handleDeleteProcess = () => {
    const onSuccess = () => {
      setLoadingDelete(false);
      setOpenDelete(false);
      onAction();
    };
    const onError = (err: any) => {
      console.log(err);
      setLoadingDelete(false);
      setOpenDelete(false);
    };
    setLoadingDelete(true);
    PROCESS_SERVICE.deleteProcess(
      onSuccess,
      onError,
      selectedProcess?.id ?? ""
    );
  };

  const handleState = (process: any, stateValue: boolean) => {
    // if (!selectedProcess) return;
    const onSuccess = (data: any) => {
      setLocalLoader(false);
      onAction && onAction();
    };
    const onError = (err: any) => {
      setLocalLoader(false);
      console.log(err);
    };

    setLocalLoader(true);
    PROCESS_SERVICE.updateState(onSuccess, onError, process?.id.toString(), {
      running: stateValue,
    });
  };

  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor={theme.palette.primary.main}>
            {headCells.map((cell) => (
              <StyledTableCell
                key={cell.id}
                minWidth={cell.width}
                width={cell.width}
              >
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {loader || localLoader ? (
            <TableProgress colSpans={12} />
          ) : processDetails.length === 0 ? (
            <DataNotFound colSpans={12} />
          ) : (
            <TableBody>
              {processDetails.map((process, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell width={headCells[0].width}>
                    {startIndex == 0 && index < 9
                      ? "0" + (index + 1)
                      : startIndex + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[1].width}>
                    {process.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[2].width}>
                    {process.processDetails.callingMode}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[3].width}>
                    <LightTooltip title="Churn Leads" placement="bottom">
                      <IconButton
                        disabled={
                          process.processDetails.callingMode === "MANUAL"
                            ? true
                            : false
                        }
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          setSelectedProcess(process);
                          setOpenChurnPopup(true);
                        }}
                      >
                        <RestorePageIcon
                          width="22"
                          height="22"
                          color={
                            process.processDetails.callingMode === "MANUAL"
                              ? "disabled"
                              : "primary"
                          }
                        />
                      </IconButton>
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell width={headCells[4].width}>
                    <ToggleButton
                      toggleStatus={process.processDetails.running}
                      value_update={(value: boolean) => {
                        handleState(process, value);
                      }}
                      firstOption="Pause"
                      firstOptionColor="#F2994A"
                      firstOptionTooltip="Click to pause the process"
                      secondOptionTooltip="Click to start the process"
                    />
                  </StyledTableCell>
                  <StyledTableCell width={headCells[5].width}>
                    <LightTooltip title="Add/Remove Agent" placement="bottom">
                      <IconButton
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          setSelectedProcess(process);
                          setOpenAgentPopup(true);
                        }}
                      >
                        <GroupAddIcon
                          width="22"
                          height="22"
                          color={"primary"}
                        />
                      </IconButton>
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell width={headCells[6].width}>
                    <LightTooltip title="Add/Remove Leadset" placement="bottom">
                      <IconButton
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          setSelectedProcess(process);
                          setOpenLeadset(true);
                        }}
                      >
                        <FolderSharedIcon
                          width="22"
                          height="22"
                          color={"primary"}
                        />
                      </IconButton>
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell width={headCells[7].width}>
                    <CustomActionButton
                      backgroundColor="#2F80ED"
                      title={
                        process.processDetails.ratio === undefined
                          ? ""
                          : process.processDetails.ratio.toString()
                      }
                      width="25px"
                      onClick={() => {
                        setSelectedProcess(process);
                        setOpenChangeRatio(true);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell width={headCells[8].width}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        onClick={() => {
                          //Commenting temporarily
                          // setSelectedProcess(process);
                          // setOpenLeadsBifurcation(true);
                        }}
                      >
                        {process.processDetails.remainingLeads}
                      </Typography>
                      <IconButton
                        sx={{
                          marginLeft: "20px",
                          height: "40px",
                          width: "40px",
                        }}
                        color="primary"
                        onClick={() => {
                          setSelectedIndex(index);
                          onReloadRemaingLeads();
                        }}
                      >
                        {loaderRemainingLeads === true ? (
                          selectedIndex === index ? (
                            <CircularProgress
                              thickness={5}
                              color={"primary"}
                              size={18}
                            />
                          ) : (
                            <RefreshIcon />
                          )
                        ) : (
                          <RefreshIcon />
                        )}
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                  {/* <StyledTableCell width={headCells[9].width}>
                    <SettingsDropDown />
                  </StyledTableCell> */}
                  <StyledTableCell width={headCells[9].width}>
                    <ToggleButton
                      firstOptionTooltip="Click to deactivate the process"
                      secondOptionTooltip="Click to activate the process"
                      toggleStatus={process.status}
                      value_update={() => {
                        setSelectedProcess(process);
                        setOpenStatusConfirmation(true);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell width={headCells[10].width}>
                    {process.status === "ENABLED" ? (
                      <LightTooltip title="Edit Process Details">
                        <IconButton
                          onClick={() => {
                            navigate(
                              generatePath(ALL_ROUTES.EDIT_PROCESS, {
                                id: id,
                                processId: process.id.toString(),
                              }),
                              {
                                state: process,
                              }
                            );
                          }}
                        >
                          <EditIcon color="#5ECE62" />
                        </IconButton>
                      </LightTooltip>
                    ) : (
                      <LightTooltip title="Delete Process">
                        <IconButton
                          onClick={() => {
                            setSelectedProcess(process);
                            setOpenDelete(true);
                          }}
                        >
                          <LeadsetDeleteIcon />
                        </IconButton>
                      </LightTooltip>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ChurnProcessPopup
        open={openChurnPopup}
        setOpen={setOpenChurnPopup}
        selectedProcess={selectedProcess}
      />
      {selectedProcess && (
        <ChangeRatioPopup
          open={openChangeRatio}
          setOpen={setOpenChangeRatio}
          selectedProcess={selectedProcess}
          onAction={onAction}
        />
      )}
      {openLeadset && (
        <LeadsetSelectionPopup
          open={openLeadset}
          setOpen={setOpenLeadset}
          selectedProcess={selectedProcess}
          onAction={onAction}
        />
      )}
      {openLeadsBifurcation && (
        <LeadsBifurcationPopup
          open={openLeadsBifurcation}
          setOpen={setOpenLeadsBifurcation}
          process={selectedProcess}
        />
      )}
      {openStatusConfirmation && (
        <SimpleConfirmationPopUp
          open={openStatusConfirmation}
          setOpen={setOpenStatusConfirmation}
          value={selectedProcess?.name ?? ""}
          action={selectedProcess?.status === "ENABLED" ? "Disable" : "Enable"}
          onAction={handleProcessStatus}
          loading={loadingStatus}
        />
      )}
      {openDelete && (
        <SimpleConfirmationPopUp
          open={openDelete}
          setOpen={setOpenDelete}
          value={selectedProcess?.name ?? ""}
          action={"Delete"}
          onAction={handleDeleteProcess}
          loading={loadingDelete}
        />
      )}
      {openAgentPopup && (
        <AgentSelectionPopup
          open={openAgentPopup}
          setOpen={setOpenAgentPopup}
          selectedProcess={selectedProcess}
          processId={selectedProcess?.id ?? ""}
          onAction={onAction}
        />
      )}
    </>
  );
};

export default ProcessDetailsTable;
