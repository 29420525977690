import React, { Dispatch, SetStateAction } from "react";

export type TAuthContent = {
  authToken: string;
  setAuthToken: Dispatch<SetStateAction<string>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
};

export const AuthContext = React.createContext<TAuthContent>({
  authToken: "",
  setAuthToken: () => {},
  error: "",
  setError: () => {},
});
