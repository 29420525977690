import {
  Box,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import CloseIcons from "../Actions/CloseIcons";
import FormActionButton from "../Actions/FormActionButton";

const ConfirmationPopUpHeader = ({
  title,
  setOpen,
}: {
  title?: string;
  setOpen: Function;
}) => {
  return (
    <Box>
      <CloseIcons
        sx={{ display: { sm: "none", md: "flex" } }}
        onClick={() => setOpen(false)}
      />
      <Typography pt="30px" textAlign="center" fontSize={24}>
        Are you absolutely sure ?
      </Typography>
      {title && (
        <Box
          mt="23px"
          mb="6px"
          p="10px 0"
          sx={{ backgroundColor: "#EB575780", width: "100%" }}
        >
          <Typography textAlign={"center"}>{title}</Typography>
        </Box>
      )}
    </Box>
  );
};

const ConfirmationPopUpContent = ({
  campaignName = "Campaign Name",
  title,
  body,
  setOpen,
  onClick,
  loading = false,
}: {
  campaignName: string;
  title?: string;
  body?: React.ReactNode;
  setOpen: Function;
  onClick: any;
  loading?: boolean;
}) => {
  const [confirmationText, setConfimationText] = useState("");
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfimationText(event.target.value);
  };
  return (
    <>
      <ConfirmationPopUpHeader title={title} setOpen={setOpen} />
      <Box px={3}>
        {body}
        <Typography py="10px">
          Please type <b>{campaignName}</b> to confirm.
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          size="medium"
          value={confirmationText}
          onChange={handleChange}
        />
        <Box
          display={"flex"}
          p="30px 0"
          justifyContent={"space-around"}
          alignItems="center"
          gap={{ xs: 2, md: 0 }}
        >
          <FormActionButton
            title={
              mobileView ? "Disable" : "I understand the consequences, disable."
            }
            disabled={confirmationText !== campaignName}
            onClick={onClick}
            sx={{ textTransform: "initial" }}
            loading={loading}
            variant="contained"
          />
          {/* <Button
            variant="contained"
            disabled={confirmationText !== campaignName}
            size="large"
            sx={{ textTransform: "initial", minHeight: "50px" }}
            onClick={onClick}
          >
            I understand the consequences, disable.
          </Button> */}
          <FormActionButton
            title="Cancel"
            onClick={() => setOpen(false)}
            disabled={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default ConfirmationPopUpContent;
