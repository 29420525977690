import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Typography } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import BackdropLoader from "../../components/BackdropLoader";
import RoundedButtons from "../../components/Custom/RoundedButtons";
import { FilterPopup } from "../../components/RecordingLog/FilterPopup";
import RecordingLogTable from "../../components/RecordingLog/RecordingLogTable";
import { SearchBar } from "../../components/SearchBar";
import TablePagination from "../../components/Table/TablePagination";
import { GetRecordingDTO } from "../../services/dto/GetRecordingDTO";
import { RECORDING_SERVICE } from "../../services/RecordingService";
import { filterFormSlice } from "../../state/filter-form/filterFormSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { TFilterForm } from "../../state/types/filterForm";

const AgentRecording = ({ type }: { type?: string }) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [recordingLogs, setRecordingLogs] = useState<GetRecordingDTO | null>(
    null
  );
  const [recordingLogLoader, setRecordingLogLoader] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("id");
  const limit = 10;
  const params = useAppSelector((state) => state.filterForm);
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });

  const clearFilters = () => {
    dispatch(filterFormSlice.actions.resetForm());
  };

  function updatePagination(info: any) {
    const count = info.count;
    const startCount = info.startIndex + 1;
    const endCount = info.startIndex + info.limit;
    const pages = Math.ceil(info.count / info.limit);
    return { count, startCount, endCount, pages };
  }

  const loadRecordings = useCallback((text: string) => {
    const onSuccess = (data: GetRecordingDTO) => {
      setRecordingLogLoader(false);
      setRecordingLogs(data);
      const { count, startCount, endCount, pages } = updatePagination(
        data.info
      );
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = (err: any) => {
      console.log(err);
      setRecordingLogLoader(false);
    };

    setRecordingLogLoader(true);
    let dto: TFilterForm = params;
    dto = {
      ...dto,
      "filter-startBefore": dto["filter-startBefore"] + "Z",
      "filter-startAfter": dto["filter-startAfter"] + "Z",
    };

    RECORDING_SERVICE.getRecordings(onSuccess, onError, {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search: text,
      ...dto,
      sortDirection: order,
      sort: orderBy,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadRecordings, 500), [
    loadRecordings,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getRecordings() {
    const onSuccess = (data: GetRecordingDTO) => {
      setRecordingLogLoader(false);
      setRecordingLogs(data);
      const { count, startCount, endCount, pages } = updatePagination(
        data.info
      );
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = (err: any) => {
      console.log(err);
      setRecordingLogLoader(false);
    };
    setRecordingLogLoader(true);
    let dto: TFilterForm = params;
    dto = {
      ...dto,
      "filter-startBefore": dto["filter-startBefore"] + "Z",
      "filter-startAfter": dto["filter-startAfter"] + "Z",
    };

    RECORDING_SERVICE.getRecordings(onSuccess, onError, {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search,
      ...dto,
      sortDirection: order,
      sort: orderBy,
    });
  }

  useEffect(() => {
    getRecordings();
  }, [pagination.page, params, order, orderBy]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h5" marginRight={"20px"}>
            Recording Log
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={(text) => setSearch(text)}
          />
        </Box>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <RoundedButtons
            title="Clear"
            Icon={CloseIcon}
            color="#c1bdbd"
            sx={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid rgba(0,0,0,0.25)",
              marginRight: "20px",
            }}
            IconColor="black"
            onClick={clearFilters}
          />
          <RoundedButtons
            title="Filter"
            tooltip="Set Filter"
            Icon={FilterAltIcon}
            color="#E2606C"
            onClick={() => setOpenFilter(true)}
          />
        </Box>
      </Box>
      <Box style={{ marginTop: "20px" }}>
        <RecordingLogTable
          recordings={recordingLogs?.recordings ?? []}
          startIndex={(pagination.page - 1) * limit}
          recordingLogLoader={recordingLogLoader}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Box>
      <TablePagination page={pagination} setPage={setPagination} />
      {loading && <BackdropLoader loading={loading} />}
      {openFilter && (
        <FilterPopup
          setOpen={setOpenFilter}
          open={openFilter}
          filters={recordingLogs?.filters}
          type={type === undefined ? "agent" : type}
        />
      )}
    </>
  );
};

export default AgentRecording;
