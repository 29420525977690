function MenuIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 0.928432H1.33333C0.875 0.928432 0.5 1.38379 0.5 1.94034C0.5 2.49689 0.875 2.95224 1.33333 2.95224H14.6667C15.125 2.95224 15.5 2.49689 15.5 1.94034C15.5 1.38379 15.125 0.928432 14.6667 0.928432ZM14.6667 5.98796H4.66667C4.20833 5.98796 3.83333 6.44331 3.83333 6.99986C3.83333 7.55641 4.20833 8.01177 4.66667 8.01177H14.6667C15.125 8.01177 15.5 7.55641 15.5 6.99986C15.5 6.44331 15.125 5.98796 14.6667 5.98796ZM15.5 12.0594C15.5 11.5028 15.125 11.0475 14.6667 11.0475H8.83333C8.375 11.0475 8 11.5028 8 12.0594C8 12.6159 8.375 13.0713 8.83333 13.0713H14.6667C15.125 13.0713 15.5 12.6159 15.5 12.0594Z"
        fill="white"
      />
    </svg>
  );
}

export default MenuIcon;
