import MinimizeIcon from "@mui/icons-material/Minimize";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
const CommentHeader = ({ setChecked }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        background: "#F4F6FE",
        pt: "5px",
        borderRadius: "16px 16px 0 0",
        alignItems: "center",
        mb: "16px",
      }}>
      <Typography variant="h6" sx={{ fontSize: "18px", pt: "5px", pl: "19px" }}>
        Timeline
      </Typography>
      <MinimizeIcon
        sx={{ cursor: "pointer", pb: "20px", pr: "11px", color: "#96A1B3" }}
        onClick={() => {
          setChecked(false);
        }}
      />
    </Box>
  );
};

export default CommentHeader;
