import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LEADSET_SERVICE } from "../../services/LeadsetService";
import { TLeadset } from "../../state/types/user";
import FormActionButton from "../Actions/FormActionButton";
import CustomTextField from "../Custom/CustomTextField";

const LeadsetForm = () => {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leadsetForm, setLeadsetForm] = useState<TLeadset>({
    name: "",
    description: "",
  });
  const [validationError, setValidationError] = useState<TLeadset>({
    name: "",
    description: "",
  });

  const handleInputChange = (event: any) => {
    setLeadsetForm({ ...leadsetForm, [event.target.id]: event.target.value });
    if (event.target.value === null || event.target.value === "") {
      setValidationError((prev) => ({
        ...prev,
        [event.target.id]: "Error",
      }));
      setErrorText(true);
    } else {
      setValidationError((prev) => ({
        ...prev,
        [event.target.id]: "",
      }));
      setErrorText(false);
    }
  };

  function addLeadset() {
    const onSuccess = (res: any) => {
      setLoading(false);
      if (res.status === 201) navigate(-1);
    };
    const onError = (error: any) => {
      console.log(error);
      setLoading(false);
    };
    setLoading(true);
    LEADSET_SERVICE.postLeadset(onSuccess, onError, leadsetForm);
  }

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(leadsetForm)) {
      if (value.toString() === "") {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      setErrorText(true);
      return;
    }
    setErrorText(false);
    addLeadset();
  };

  return (
    <>
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}
        >
          Please fill all fields
        </Typography>
      ) : null}
      <Box
        width={{ xs: "100%", md: "400px" }}
        p={{ xs: "10px 0 20px", md: "20px 0" }}
      >
        <CustomTextField
          label="Name"
          id="name"
          required
          onChange={handleInputChange}
          value={leadsetForm.name}
          error={validationError.name}
          sx={{ marginBottom: "30px" }}
        />
        <CustomTextField
          label="Description"
          id="description"
          multiline={true}
          required
          onChange={handleInputChange}
          value={leadsetForm.description}
          error={validationError.description}
        />

        <Box display={"flex"} justifyContent="flex-start">
          <Box
            display="flex"
            gap={2}
            justifyContent="center"
            flexWrap={"wrap"}
            pt="10px"
          >
            <FormActionButton
              title="Save"
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
            />
            <FormActionButton
              title="Cancel"
              onClick={() => navigate(-1)}
              disabled={loading}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LeadsetForm;
