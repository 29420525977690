import { Logout } from "@mui/icons-material";
import PasswordIcon from "@mui/icons-material/Password";
import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../shared/routes";
import { useAppSelector } from "../../state/hooks";
import { UserAccessLevel, UserStateStatus } from "../../state/types/user";

function ProfileAvatar({
  avatarIcon,
  name,
  accessLevel,
}: {
  avatarIcon: React.ReactNode;
  name?: string;
  accessLevel: string;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const { status } = useAppSelector((state) => state.user);
  const disableLogout =
    accessLevel === UserAccessLevel.AGENT &&
    status !== UserStateStatus.PAUSE &&
    status !== UserStateStatus.STOP;

  const handleClick = (event: React.MouseEvent<any>) => {
    if (disableLogout) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    if (disableLogout) return;
    navigate(ALL_ROUTES.LOGOUT);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          textAlign: "left",
          textTransform: "capitalize",
          ".MuiTouchRipple-child": {
            backgroundColor: "gray",
          },
        }}
      >
        {avatarIcon}
        {name && (
          <Box sx={{ pl: "10px" }}>
            <Typography
              sx={{
                color: "black",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                color: "#96A1B2",
                fontWeight: 400,
                fontSize: "14px",
                textTransform: "lowercase",
              }}
            >
              {accessLevel}
            </Typography>
          </Box>
        )}
      </Button>
      {!disableLogout && (
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              navigate(ALL_ROUTES.CHANGE_PASSWORD);
              handleClose();
            }}
            sx={{ minWidth: 180 }}
          >
            <ListItemIcon>
              <PasswordIcon fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
          <MenuItem onClick={handleLogout} sx={{ minWidth: 180 }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default ProfileAvatar;
