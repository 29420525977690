import { TClientConfig } from "../state/types/TClientConfig";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "./constants";

const CLIENT_CONFIG_STR = localStorage.getItem(
  LOCALSOTRAGE_KEYS_CONSTANTS.CLIENT_CONFIG
);
const CLIENT_CONFIG: TClientConfig = JSON.parse(
  CLIENT_CONFIG_STR === null ? "{}" : CLIENT_CONFIG_STR
);

export const DOMAIN_CONSTANTS = {
  BASE_URL: "https://osmbackend.evervent.in",
  BASE_URL_TWO: "https://osmbackend.evervent.in",
  SOCKET_URL: "https://osmsocket.evervent.in",

  JSSIP_WS: CLIENT_CONFIG.JSSIP_WS === undefined ? "" : CLIENT_CONFIG.JSSIP_WS,
  JSSIP_URI:
    CLIENT_CONFIG.JSSIP_URI === undefined ? "" : CLIENT_CONFIG.JSSIP_URI,
  DIALPAD_DOMAIN:
    "@" + CLIENT_CONFIG.DIALPAD_DOMAIN === undefined
      ? ""
      : CLIENT_CONFIG.DIALPAD_DOMAIN,
  CLIENT_SOCKET_PORT:
    CLIENT_CONFIG.CLIENT_SOCKET_PORT === undefined
      ? ""
      : CLIENT_CONFIG.CLIENT_SOCKET_PORT,
  CLIENT_SOCKET_URL:
    CLIENT_CONFIG.CLIENT_SOCKET_URL === undefined
      ? ""
      : CLIENT_CONFIG.CLIENT_SOCKET_URL,
  STASIS_APP:
    CLIENT_CONFIG.OTHER_SSP === undefined ? "" : CLIENT_CONFIG.OTHER_SSP,

  // BASE_URL: "http://172.16.16.128:5000",
  // BASE_URL_TWO: "http://172.16.16.128:5000",
  // SOCKET_URL: "http://172.16.16.128:5001",
};
