import { useEffect, useState } from "react";

import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import {
  Box,
  Link,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { USER_SERVICE } from "../../services/UserService";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import DataNotFound from "../DataNotfound/DataNotFound";
import LightTooltip from "../LightToolTip";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import TableProgress from "../Table/TableProgress";

const ProcessTable = ({
  selectedUser,
}: {
  selectedUser: GetUserResponseDTO | null;
}) => {
  const [tableData, setTableData] = useState<
    Array<{
      name: string;
      description: string;
      process_description: string;
      campaign: string;
      id: string;
      processType: string;
    }>
  >([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedProcess, setSelectedProcess] = useState<{
    name: string;
    description: string;
    process_description: string;
    campaign: string;
    id?: string;
    processType: string;
  }>({
    name: "",
    description: "",
    process_description: "",
    campaign: "",
    id: "",
    processType: "",
  });

  const headCells = [
    { id: "sr", label: "Sr. No.", allowSort: false, width: "50px" },
    { id: "campaign", label: "Campaign", allowSort: false, width: "150px" },
    { id: "process", label: "Process", allowSort: true, width: "300px" },
    {
      id: "processType",
      label: "Process Type",
      allowSort: true,
      width: "300px",
    },
    { id: "actions", label: "Actions", allowSort: true, width: "80px" },
  ];

  function deleteUserFromProcess(process_id: string, user_id: number) {
    const onSuccess = (data: any) => {
      setLoader(false);
      setOpenConfirmation(false);
      getProcessData();
    };

    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };

    setLoader(true);
    USER_SERVICE.deleteUserFromProcess(onSuccess, onError, process_id, user_id);
  }

  useEffect(() => {
    getProcessData();
  }, [selectedUser]);

  function getProcessData() {
    const onSuccess = (data: any) => {
      setLoader(false);
      const errorStatus = data.errorStatus;
      const table_data = [];
      if (errorStatus === false) {
        const response = data.process;

        for (let i = 0; i < response.length; i++) {
          const data = {
            name: response[i].name,
            processType: response[i].processType,
            description: response[i].campaign.description,
            process_description: response[i].description,
            campaign: response[i].campaign.name,
            id: response[i].id,
          };
          table_data.push(data);
        }
      }
      setTableData(table_data);
    };

    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };

    setLoader(true);
    USER_SERVICE.getProcessAgainstUser(
      onSuccess,
      onError,
      selectedUser?.id === undefined ? 0 : selectedUser?.id
    );
  }

  return (
    <Box mt={"20px"}>
      <TableContainer>
        <Table>
          <StyledTableHead headColor={"#6F5FD9"}>
            {headCells.map((cell) => (
              <StyledTableCell
                key={cell.id}
                align={cell.id == "sr" ? "center" : "left"}
                width={cell.width}>
                {cell.label}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {loader ? (
            <TableProgress colSpans={5} />
          ) : tableData.length === 0 ? (
            <DataNotFound
              colSpans={5}
              value="User not assigned to any process. To add the user, please navigate to Campaign > Process > Manage Agents, and assign the user to an appropriate process"
            />
          ) : (
            <TableBody>
              {tableData.map((value, index) => (
                <StyledTableRow minHeight="56px">
                  <StyledTableCell
                    sx={{ width: headCells[0].width }}
                    align="center">
                    {index + 1}
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: headCells[1].width }}>
                    <LightTooltip title={"Description: " + value.description}>
                      <Typography>{value.campaign}</Typography>
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textTransform: "initial",
                      width: headCells[2].width,
                    }}>
                    <LightTooltip
                      title={"Description: " + value.process_description}>
                      <Typography>{value.name}</Typography>
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textTransform: "initial",
                      width: headCells[3].width,
                    }}>
                    <Typography>{value.processType}</Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textTransform: "initial",
                      width: headCells[4].width,
                    }}>
                    <LightTooltip title="Remove user form this process">
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedProcess(value);
                          setOpenConfirmation(true);
                        }}>
                        <PersonRemoveAlt1Icon />
                      </Link>
                    </LightTooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <SimpleConfirmationPopUp
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        value={""}
        action={"Delete"}
        onAction={() => {
          deleteUserFromProcess(
            selectedProcess?.id === undefined ? "" : selectedProcess.id,
            selectedUser?.id === undefined ? 0 : selectedUser.id
          );
        }}
        loading={loader}
        addition_title={` you want to remove user "${selectedUser?.firstName}" from process "${selectedProcess.name}"`}
        fontSize={18}
      />
    </Box>
  );
};

export default ProcessTable;
