import { Socket } from "socket.io-client";
import { createContext, Dispatch, SetStateAction } from "react";
import { DefaultEventsMap } from "socket.io/dist/typed-events";

type TClientSocketContext = {
  clientSocket: Socket | null;
  setClientSocket: Dispatch<
    SetStateAction<Socket<DefaultEventsMap, DefaultEventsMap> | null>
  >;
};

export const ClientSocketContext = createContext<TClientSocketContext>({
  clientSocket: null,
  setClientSocket: () => {},
});
