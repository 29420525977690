import { createSlice } from "@reduxjs/toolkit";
import { COMMENT_VISIBILITY } from "../../services/dto/PostCommentDTO";
import { TCommentState } from "../types/comment";
import { COMMENT_REDUCERS, TCommentReducers } from "./commentReducers";

export const commentSlice = createSlice<TCommentState, TCommentReducers>({
  name: "comment",
  initialState: {
    comment: "",
    visibility: COMMENT_VISIBILITY.PUBLIC,
    callId: null,
  },
  reducers: COMMENT_REDUCERS,
});
