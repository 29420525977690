import { Box, Typography } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import AssignToAgent from "../components/CallbackLogger/AssignToAgent";
import CallbackLoggerTable from "../components/CallbackLogger/CallbackLoggerTable";
import { SearchBar } from "../components/SearchBar";
import TablePagination from "../components/Table/TablePagination";
import { CALLBACK_SERVICE } from "../services/CallbackService";
import { USER_SERVICE } from "../services/UserService";
import { GetCallbackDTO } from "../services/dto/GetCallbackDTO";
import { GetUserResponseDTO } from "../services/dto/GetUserResponseDTO";
import { useAppSelector } from "../state/hooks";
import Grid from "@mui/material/Unstable_Grid2";

const CallbackLogger = () => {
  const [loader, setLoader] = useState(false);
  const [callbacks, setCallbacks] = useState<GetCallbackDTO[]>([]);
  const limit = 10;
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("process");
  const [search, setSearch] = useState("");
  const [agents, setAgents] = useState<GetUserResponseDTO[]>([]);
  const [loadAgents, setLoadAgents] = useState(true);
  const { agentFilterId } = useAppSelector((state) => state.assignCallbacks);
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });

  function updatePagination(info: any) {
    const count = info.count;
    const startCount = info.startIndex + 1;
    const endCount = info.startIndex + info.limit;
    const pages = Math.ceil(info.count / info.limit);
    return { count, startCount, endCount, pages };
  }

  const loadCallbacks = useCallback((text: string) => {
    setLoader(true);
    const onSuccess = ({
      info,
      callbacks,
    }: {
      info: any;
      callbacks: GetCallbackDTO[];
    }) => {
      setLoader(false);
      setCallbacks(callbacks);
      const { count, startCount, endCount, pages } = updatePagination(info);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };
    const params =
      agentFilterId === 0
        ? {
            startIndex: (pagination.page - 1) * limit,
            limit,
            search: text,
            sortDirection: order,
            sort: orderBy,
          }
        : {
            startIndex: (pagination.page - 1) * limit,
            limit,
            search: text,
            sortDirection: order,
            sort: orderBy,
            userId: agentFilterId,
          };
    CALLBACK_SERVICE.getCallbacks(onSuccess, onError, params);
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadCallbacks, 500), [
    loadCallbacks,
  ]);

  const hadleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getCallbacks() {
    setLoader(true);
    const onSuccess = ({
      info,
      callbacks,
    }: {
      info: any;
      callbacks: GetCallbackDTO[];
    }) => {
      setLoader(false);
      setCallbacks(callbacks);
      const { count, startCount, endCount, pages } = updatePagination(info);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };
    const params =
      agentFilterId === 0
        ? {
            startIndex: (pagination.page - 1) * limit,
            limit,
            search,
            sortDirection: order,
            sort: orderBy,
          }
        : {
            startIndex: (pagination.page - 1) * limit,
            limit,
            search,
            sortDirection: order,
            sort: orderBy,
            userId: agentFilterId,
          };
    CALLBACK_SERVICE.getCallbacks(onSuccess, onError, params);
  }

  function getAgents() {
    const onSuccess = ({ users }: { users: GetUserResponseDTO[] }) => {
      setAgents(users);
      setLoadAgents(false);
    };
    const onError = (err: any) => console.log(err);
    USER_SERVICE.getUsers(onSuccess, onError, { accessLevel: "agent" });
  }

  useEffect(() => {
    getAgents();
  }, []);

  useEffect(() => {
    getCallbacks();
  }, [pagination.page, order, orderBy, agentFilterId]);

  return (
    <Box>
      <Grid container>
        <Grid xs={5} sx={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="h5" marginRight={"20px"}>
            Call Back Logger
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={hadleSearch}
          />
        </Grid>
        <Grid xs={7} sx={{ justifyContent: "end" }}>
          <AssignToAgent
            agents={agents}
            disabled={loadAgents}
            onAction={getCallbacks}
          />
        </Grid>
      </Grid>

      <Box p="20px 0">
        <CallbackLoggerTable
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          callbacks={callbacks}
          loader={loader}
          agents={agents}
          onAction={getCallbacks}
        />
        <TablePagination page={pagination} setPage={setPagination} />
      </Box>
    </Box>
  );
};

export default CallbackLogger;
