import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
// import SettingsIcon from "@mui/icons-material/Settings";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyIcon from "@mui/icons-material/Key";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ForceLougoutIcon from "../../assets/images/ForceLougoutIcon";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { UserState } from "../../pages/ManageUsers";
import { AUTH_SERVICE } from "../../services/AuthService";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { USER_SERVICE } from "../../services/UserService";
import { ALL_ROUTES } from "../../shared/routes";
import { useAppDispatch } from "../../state/hooks";
import { TUserDetailsFormState } from "../../state/types/userForm";
import { userDetailsFormSlice } from "../../state/user-form/userFormSlice";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import DataNotFound from "../DataNotfound/DataNotFound";
import ToggleButton from "../EVCustoms/ToggleButton/ToggleButton";
import LightTooltip from "../LightToolTip";
import ResetUserPass from "../ResetUserPass/ResetUserPass";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import TableProgress from "../Table/TableProgress";
import ProcessInfoPopup from "./ProcessInfoPopup";
import Switch from "@mui/material/Switch";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "25px" },
  { id: "firstName", label: "Name", allowSort: true, width: "189px" },
  { id: "username", label: "User Name", allowSort: true, width: "189px" },
  { id: "email", label: "User Email", allowSort: true, width: "189px" },
  { id: "accessLevel", label: "Access Level", allowSort: true, width: "189px" },
  { id: "status", label: "Status", allowSort: true, width: "189px" },
  // { id: "setting", label: "Setting",  width: "189px" },
  { id: "action", label: "User Actions", allowSort: false, width: "189px" },
];

const UsersTable = ({
  userState,
  title = "",
  color,
  users,
  onAction,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  startIndex,
  onUpdate,
  loading,
  getUsersAPI,
}: {
  userState: UserState;
  title?: string;
  color: string;
  users: GetUserResponseDTO[];
  onAction: Function;
  order: "desc" | "asc";
  orderBy: string;
  setOrder: any;
  setOrderBy: any;
  startIndex: number;
  onUpdate: (users: GetUserResponseDTO[]) => void;
  loading: boolean;
  getUsersAPI: Function;
}) => {
  const toastRef = useRef(null);
  const [resetPassStatus, setResetPassStatus] = useState<boolean>(false);
  const [infoPopupStatus, setInfoPopupStatus] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const [openStatusConfirmation, setOpenStatusConfirmation] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openRestoreconfirmation, setOpenRestoreConfirmation] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [restoreLoader, setRestoreLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState<GetUserResponseDTO | null>(
    null
  );

  function handleLogoutUsers(userIds: number[]) {
    setLogoutLoader(true);
    const dto = { userIds: userIds };
    const onSuccess = (data: any) => {
      setOpenLogoutConfirmation(false);
      setLogoutLoader(false);
      updateLoggedOutUser();
    };
    const onError = (err: any) => setLogoutLoader(false);
    AUTH_SERVICE.logutOutUsers(onSuccess, onError, dto);
  }

  const handleUserStatus = ({ user }: { user: GetUserResponseDTO }) => {
    setStatusLoader(true);
    const dto = { userIds: [user.id] };
    const onSuccess = () => {
      setOpenStatusConfirmation(false);
      setStatusLoader(false);
      onAction();
    };
    const onError = () => setStatusLoader(false);
    if (user.status === "ENABLED") {
      AUTH_SERVICE.logutOutUsers(() => {}, onError, dto);
      USER_SERVICE.disableUser(onSuccess, onError, dto);
    } else {
      USER_SERVICE.enableUser(onSuccess, onError, dto);
    }
  };

  const capitalize = (str: string) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
  };

  function handleDeleteUser(id: string) {
    setDeleteLoader(true);
    const onSuccess = () => {
      setOpenDeleteConfirmation(false);
      setDeleteLoader(false);
      onAction();
    };
    const onError = () => setDeleteLoader(false);
    USER_SERVICE.deleteUser(onSuccess, onError, id);
  }

  function handleRestoreUser(id: string) {
    setRestoreLoader(true);
    const onSuccess = () => {
      setOpenRestoreConfirmation(false);
      setRestoreLoader(false);
      onAction();
    };
    const onError = () => setRestoreLoader(false);
    USER_SERVICE.restoreUser(onSuccess, onError, id);
  }

  function updateLoggedOutUser() {
    const updatedUsers = users.map((user) => {
      if (user.id !== selectedUser?.id) {
        return { ...user };
      }
      return { ...user, isOnline: false };
    });
    onUpdate(updatedUsers);
  }

  function updateUserDailySummaryStatus(id: number, status: string) {
    const onSuccess = (data: any) => {
      getUsersAPI();
    };
    const option_toastify = {
      error: "Uh-oh ! Can't Update, Check user email ID",
      pending: "Hang On ! Updating The Status",
      success: "Voila ! Status Updated",
      successDuration: 1000,
      errorDuration: 3000,
    };
    const onError = (err: any) => {
      console.log(err);
      getUsersAPI();
    };

    USER_SERVICE.updateUserDailySummaryStatus(
      onSuccess,
      onError,
      {
        id: id,
        status: status,
      },
      option_toastify
    );
  }

  const handleEdit = (userDetailsForm: TUserDetailsFormState) => {
    dispatch(userDetailsFormSlice.actions.setUserDetailsForm(userDetailsForm));
    navigate(ALL_ROUTES.EDIT_USER_DETAILS);
  };

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const reportlabel = { inputProps: { "aria-label": "Daily Summary Report" } };

  return (
    <>
      <ToastContainer theme="colored" />
      <ResetUserPass
        user={selectedUser}
        open_status={resetPassStatus}
        attrName={setResetPassStatus}
        value_update={updateMasterState}
      />
      <ProcessInfoPopup
        open_status={infoPopupStatus}
        attrName={setInfoPopupStatus}
        value_update={updateMasterState}
        selectedUser={selectedUser}
      />
      <TableContainer sx={{ display: { md: "flex", xs: "none" } }}>
        <Table>
          <StyledTableHead headColor={color}>
            {headCells.map((cell) =>
              userState === UserState.DELETED && cell.id == "status" ? (
                <></>
              ) : (
                <StyledTableCell
                  key={cell.id}
                  align={cell.id == "sr" ? "center" : "left"}
                  width={cell.width}
                >
                  {!cell.allowSort ? (
                    cell.label
                  ) : (
                    <StyledTableSortLabel
                      cell={cell}
                      order={order}
                      setOrder={setOrder}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  )}
                </StyledTableCell>
              )
            )}
          </StyledTableHead>
          {loading ? (
            <TableProgress colSpans={5} />
          ) : users.length === 0 ? (
            <DataNotFound colSpans={5} />
          ) : (
            <TableBody>
              {users.map((user, index) => (
                <StyledTableRow key={index} minHeight="56px">
                  {title === "" && (
                    <StyledTableCell
                      sx={{ width: headCells[0].width }}
                      align="center"
                    >
                      {startIndex == 0 && index < 9
                        ? "0" + (index + 1)
                        : startIndex + (index + 1)}
                    </StyledTableCell>
                  )}
                  <StyledTableCell sx={{ width: headCells[1].width }}>
                    {user.firstName + " " + user.lastName}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textTransform: "initial",
                      width: headCells[2].width,
                    }}
                  >
                    {user.username}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ textTransform: "initial", width: headCells[3].width }}
                  >
                    {user.email}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: headCells[3].width }}>
                    {capitalize(user.accessLevel)}
                  </StyledTableCell>
                  {userState === UserState.ACTIVE && (
                    <>
                      <StyledTableCell
                        sx={{ width: headCells[4].width, padding: 4 }}
                      >
                        <ToggleButton
                          firstOptionTooltip="Click to deactivate"
                          secondOptionTooltip="Click to activate"
                          toggleStatus={user.status}
                          value_update={(status: string) => {
                            setOpenStatusConfirmation(true);
                            setSelectedUser(user);
                          }}
                        />
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell
                    sx={{ width: headCells[5].width, padding: 0 }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      {user.status === "ENABLED" ? (
                        <Box>
                          <LightTooltip title="Edit User">
                            <IconButton
                              onClick={() => {
                                handleEdit(user);
                              }}
                            >
                              <BorderColorIcon
                                sx={{ color: "#5ECE62", height: 18 }}
                              />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip title="Reset password">
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setResetPassStatus(true);
                              }}
                            >
                              <KeyIcon height="18" width="18" />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip title="Click to view user processes">
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setInfoPopupStatus(true);
                              }}
                            >
                              <InfoOutlinedIcon
                                sx={{ color: "#4666FF", height: 18 }}
                              />
                            </IconButton>
                          </LightTooltip>

                          <LightTooltip
                            title={
                              user.summaryStatus === "DISABLED"
                                ? "Enable Daily Summary"
                                : "Disable Daily Summary"
                            }
                          >
                            <Switch
                              defaultChecked={
                                user.summaryStatus === "DISABLED" ? false : true
                              }
                              value={
                                user.summaryStatus === "DISABLED" ? "on" : "off"
                              }
                              {...reportlabel}
                              disabled={
                                user.accessLevel === "ADMIN" ? false : true
                              }
                              onChange={(e) => {
                                updateUserDailySummaryStatus(
                                  user.id,
                                  e.target.value === "off"
                                    ? "DISABLED"
                                    : "ENABLED"
                                );
                              }}
                            />
                          </LightTooltip>

                          {user.accessLevel === "AGENT" ? (
                            <>
                              {user.isOnline ? (
                                <LightTooltip title="Force Logout The User">
                                  <IconButton
                                    onClick={() => {
                                      setSelectedUser(user);
                                      setOpenLogoutConfirmation(true);
                                    }}
                                  >
                                    <ForceLougoutIcon height="18" width="18" />
                                  </IconButton>
                                </LightTooltip>
                              ) : null}
                            </>
                          ) : null}
                        </Box>
                      ) : (
                        <Box>
                          {userState === UserState.DELETED ? (
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setOpenRestoreConfirmation(true);
                              }}
                            >
                              <SettingsBackupRestoreIcon
                                sx={{ fontSize: "18px", color: "#5ECE62" }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setOpenDeleteConfirmation(true);
                              }}
                            >
                              <LeadsetDeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                      )}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {selectedUser && (
          <>
            <SimpleConfirmationPopUp
              loading={logoutLoader}
              open={openLogoutConfirmation}
              setOpen={setOpenLogoutConfirmation}
              value={selectedUser.username}
              action="Logout"
              onAction={() => {
                handleLogoutUsers([selectedUser.id]);
              }}
            />
            <SimpleConfirmationPopUp
              loading={statusLoader}
              action={selectedUser.status === "DISABLED" ? "Enable" : "Disable"}
              value={selectedUser.username}
              open={openStatusConfirmation}
              setOpen={setOpenStatusConfirmation}
              onAction={() => handleUserStatus({ user: selectedUser })}
            />
            <SimpleConfirmationPopUp
              loading={deleteLoader}
              action={"Delete"}
              value={selectedUser.username}
              open={openDeleteConfirmation}
              setOpen={setOpenDeleteConfirmation}
              onAction={() => handleDeleteUser(selectedUser?.id.toString())}
            />
            <SimpleConfirmationPopUp
              loading={restoreLoader}
              action="Restore"
              value={selectedUser.username}
              open={openRestoreconfirmation}
              setOpen={setOpenRestoreConfirmation}
              onAction={() => handleRestoreUser(selectedUser?.id.toString())}
            />
          </>
        )}
      </TableContainer>
    </>
  );
};

export default UsersTable;
