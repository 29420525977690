import { Player } from "@lottiefiles/react-lottie-player";
import CallIcon from "@mui/icons-material/Call";
import NextPlanOutlinedIcon from "@mui/icons-material/NextPlanOutlined";
import {
  Box,
  Dialog,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import DialpadIcon from "@mui/icons-material/Dialpad";
import StopIcon from "@mui/icons-material/Stop";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreakFloatingIcon from "../assets/images/BreakFloatingIcon";
import { BreakStatusPopup } from "../components/BreakStatus/BreakStatusPopup";
import CommentBoxIcon from "../components/CommentBox/CommentBoxIcon";
import FloatingButtonTwo from "../components/Custom/FloatingButton";
import DTMFPopup from "../components/DTMF/DTMFPopup";
import FloatingButton from "../components/EVCustoms/FloatingButton";
import PersonalDetails from "../components/LeadInformation/PersonalDetails";
import Timer from "../components/Stopwatch/Timer";
import { CALLBACK_SERVICE } from "../services/CallbackService";
import { PREVIEW_SERVICE } from "../services/PreviewService";
import { SOCKET_EVENTS } from "../shared/constants";
import { useClientSocket } from "../shared/hooks/useClientSocket";
import { callSlice } from "../state/call/callSlice";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { leadSlice } from "../state/lead/leadSlice";
import { TLeadState } from "../state/types/lead";
import { UserStateStatus } from "../state/types/user";
import { userSlice } from "../state/user/userSlice";
import LightTooltip from "../components/LightToolTip";

const CallbackPreview = () => {
  const toastRef = useRef(null);
  const [openBreakReasons, setOpenBreakReasons] = useState(false);
  const [openDTMF, setOpenDTMF] = useState<boolean>(false);
  const [msgScreen, setMsgScreen] = useState<boolean>(false);
  const [skipCallMsg, setSkipCallMsg] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [callLoader, setCallLoader] = useState<boolean>(false);
  const [skipLoading, setSkipLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const lead = useAppSelector((state) => state.lead);
  const { clientSocket } = useClientSocket();
  const userStatus = useAppSelector((state) => state.user.status);
  const callback = useAppSelector((state) => state.callback);
  const call = useAppSelector((state) => state.call);
  const isPreview = userStatus === UserStateStatus.PREVIEW;
  const isSet = lead.id !== 0;
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  if (isPreview && !isSet) getPreviewLead();
  const { shortcutKeys } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (shortcutKeys === true) {
      window.addEventListener("keyup", handleKeyPress);
    } else {
      window.removeEventListener("keyup", handleKeyPress);
    }
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [shortcutKeys]);

  const handleKeyPress = useCallback((e: any) => {
    const event = e;
    // console.log(event.keyCode);
    if (
      event.target.tagName !== "INPUT" &&
      event.target.tagName !== "TEXTAREA" &&
      event.target.tagName === "BODY"
    ) {
      if (event.shiftKey) {
        switch (event.keyCode) {
          case 83:
            skipPreviewLead();
            break;
          default:
            break;
        }
      } else {
        switch (event.keyCode) {
          case 67:
            callLead();
            break;
          case 83:
            dispatch(userSlice.actions.goToStop());
            break;
          case 77:
            handleDTMFPopup();
            break;
          case 66:
            setOpenBreakReasons(!openBreakReasons);
            break;
          default:
            break;
        }
      }
    }
  }, []);

  function getPreviewLead() {
    const onSuccess = (data: any) => {
      const lead: TLeadState = data.lead;
      if (lead == null) return;
      dispatch(callSlice.actions.updateLeadDetails(lead));
      dispatch(leadSlice.actions.setLead(lead));
    };
    const onError = (err: any) => console.log(err);
    PREVIEW_SERVICE.getNextLead(onSuccess, onError);
  }

  function skipPreviewLead() {
    const onSuccess = (data: any) => {
      setSkipLoading(false);

      const errorstatus = data.errorstatus;
      const message = data.message;

      if (errorstatus === true) {
        // setMsgScreen(true);
        // setSkipCallMsg(message);
      } else {
        const leadData: TLeadState = data.data;

        dispatch(callSlice.actions.updateLeadDetails(leadData));
        dispatch(leadSlice.actions.setLead(leadData));
      }
    };
    const onError = (err: any) => {
      setSkipLoading(false);
      console.log(err);
    };

    setSkipLoading(true);

    PREVIEW_SERVICE.skipPreviewLead(onSuccess, onError);
  }

  useEffect(() => {
    if (msgScreen === true) {
      setTimeout(() => {
        setMsgScreen(false);
      }, 3000);
    }
  }, [msgScreen]);

  useEffect(() => {
    const callConnectedListener = (data: any) => {
      // dispatch(callSlice.actions.setCall(data));
      // dispatch(leadSlice.actions.setLead(data.Lead));
      dispatch(userSlice.actions.goToCall());
      clientSocket?.on(SOCKET_EVENTS.CALL_DISCONNECTED, () => {
        console.log("CALL DISCONNECTED");
        dispatch(
          userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION)
        );
      });
    };
    const callRejectedListener = () => {
      console.log("CALL REJECTED");
      setLoading(false);
    };
    // const callDisconectedListener = () => {
    //   console.log("CALL DISCONNECTED");
    //   dispatch(userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION));
    // };
    clientSocket?.on(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
    clientSocket?.on(SOCKET_EVENTS.CALL_REJECTED, callRejectedListener);
    // clientSocket?.on(SOCKET_EVENTS.CALL_DISCONNECTED, callDisconectedListener);
    return () => {
      clientSocket?.off(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
      clientSocket?.off(SOCKET_EVENTS.CALL_REJECTED, callRejectedListener);
    };
  }, [clientSocket?.id]);

  const callLead = () => {
    const onSuccess = (data: any) => {
      setCallLoader(false);

      const response = data.data;
      const leadData = response.leadData;
      const callCode = leadData.callCode;
      const callId = response.callId;
      const processId = response.processId;

      dispatch(callSlice.actions.updateCallCode(callCode));
      dispatch(userSlice.actions.updateUserStatus(UserStateStatus.CALL));
      dispatch(callSlice.actions.updateCallId(callId));
      dispatch(callSlice.actions.updateProcessId(processId));
    };
    const onError = (err: any) => {
      setCallLoader(false);
      console.log(err);
    };

    setCallLoader(true);

    isPreview
      ? PREVIEW_SERVICE.callPreviewLead(onSuccess, onError)
      : CALLBACK_SERVICE.callCallback(
          onSuccess,
          onError,
          callback.id.toString()
        );
  };

  const handleDTMFPopup = () => {
    dispatch(
      userSlice.actions.updateUserStatus(UserStateStatus.MANUAL_PREVIEW)
    );
    setOpenDTMF(!openDTMF);
  };

  useEffect(() => {
    if (
      userStatus === UserStateStatus.PREVIEW ||
      userStatus === UserStateStatus.MANUAL_PREVIEW
    ) {
      dispatch(
        userSlice.actions.updateUserStatus(
          openDTMF ? UserStateStatus.MANUAL_PREVIEW : UserStateStatus.PREVIEW
        )
      );
    }

    dispatch(callSlice.actions.updateCallType(openDTMF ? "" : "PREVIEW"));
  }, [openDTMF]);

  useEffect(() => {
    console.log("call", call);
  }, [call]);

  return (
    <Box>
      <ToastContainer theme="colored" />
      <Dialog
        open={false}
        sx={{
          "& .css-6wzpae-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: "transparent",
            boxShadow:
              "0px 0px 0px 0px rgb(0 0 0 / 100%), 0px 0px 0px 0px rgb(0 0 0 / 100%), 0px 0px 0px 0px rgb(0 0 0 / 100%)",
          },
          backdropFilter: "blur(3px)",
        }}
      >
        <Player
          autoplay
          loop
          src="./JSON/Loading.json"
          style={{
            height: "170px",
            width: "300px",
          }}
        />
        <span style={{ color: "#fff", width: "100%", textAlign: "center" }}>
          Fetching new lead...
        </span>
      </Dialog>
      <Grid container>
        <Grid item xs={12} sm={6} py="5px">
          <Box
            sx={{
              display: "flex",
              gap: { xs: "10px", md: "30px" },
              alignItems: "center",
              minWidth: { xs: "", lg: "800px" },
            }}
          >
            <Typography variant="h5">
              {isPreview ||
              userStatus === UserStateStatus.MANUAL_PREVIEW ||
              userStatus === UserStateStatus.PAUSE ||
              userStatus === UserStateStatus.STOP
                ? "Preview"
                : "Callback"}
            </Typography>
            <Timer
              color="primary.main"
              sx={{
                fontWeight: 700,
                fontFamily: "Roboto",
                paddingBottom: "5px",
              }}
              stopTimer={
                userStatus === UserStateStatus.MANUAL_PREVIEW ||
                userStatus === UserStateStatus.PAUSE ||
                userStatus === UserStateStatus.STOP
                  ? true
                  : false
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
      <Typography variant="h6" sx={{ marginTop: "18px", marginBottom: "12px" }}>
        Lead Information
      </Typography>
      <FloatingButton
        color="#93CE15"
        Icon={CallIcon}
        transform={mobileView ? "scale(0.88, 0.82)" : "scale(1)"}
        onClick={callLead}
        text="Call"
        loading={callLoader}
        hover_expand={false}
        tooltip="Call Now (C)"
      />
      {/* <FloatingButtonTwo
        color="#93CE15"
        Icon={CallIcon}
        transform={mobileView ? "scale(0.88, 0.82)" : "scale(1)"}
        onClick={callLead}
        loading={false}
      /> */}
      {isPreview || userStatus === UserStateStatus.MANUAL_PREVIEW ? (
        <FloatingButtonTwo
          onClick={skipPreviewLead}
          sx={{
            marginTop: { xs: "56px", md: "70px" },
          }}
          color="#6F5FD9"
          Icon={NextPlanOutlinedIcon}
          isActive={true}
          icon_sx={{ fontSize: "28px" }}
          activeToolTip={"Skip Lead (Shift+S)"}
        />
      ) : null}
      {isPreview || userStatus === UserStateStatus.MANUAL_PREVIEW ? (
        <FloatingButtonTwo
          onClick={handleDTMFPopup}
          sx={{ marginTop: { xs: "112px", md: "140px" } }}
          color="#93CE15"
          Icon={DialpadIcon}
          isActive={true}
          loading={false}
          activeToolTip={"Manual Dial (M)"}
        />
      ) : null}
      {isPreview || userStatus === UserStateStatus.MANUAL_PREVIEW ? (
        <FloatingButtonTwo
          onClick={() => {
            setOpenBreakReasons(!openBreakReasons);
          }}
          sx={{
            marginTop: { xs: "56px", md: "210px" },
          }}
          color="#EFD053"
          Icon={BreakFloatingIcon}
          isActive={true}
          loading={false}
          activeToolTip={"Break (B)"}
        />
      ) : null}
      {isPreview || userStatus === UserStateStatus.MANUAL_PREVIEW ? (
        <FloatingButtonTwo
          sx={{ marginTop: { xs: "112px", md: "280px" } }}
          color="#CE3A3A"
          Icon={StopIcon}
          onClick={() => {
            dispatch(userSlice.actions.goToStop());
          }}
          isActive={true}
          loading={false}
          activeToolTip={"Stop (S)"}
        />
      ) : null}
      <PersonalDetails showCallbackDate loader={loading} />
      <CommentBoxIcon />

      {openDTMF && <DTMFPopup open={openDTMF} setOpen={handleDTMFPopup} />}
      <BreakStatusPopup
        openPopup={openBreakReasons}
        setOpenPopup={setOpenBreakReasons}
      />
    </Box>
  );
};

export default CallbackPreview;
