import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import AdvancedCallbacksTable from "../components/Callbacks/AdvancedCallbacksTable";
import AdvancedCallbacksTableMobile from "../components/Callbacks/AdvancedCallbacksTableMobile";
import ClockHeader from "../components/ClockHeader";
import CustomTabButtons from "../components/Custom/CustomTabButtons";
import { SearchBar } from "../components/SearchBar";
import Timer from "../components/Stopwatch/Timer";
import TablePagination from "../components/Table/TablePagination";
import { CALLBACK_SERVICE } from "../services/CallbackService";
import { GetCallbackDTO } from "../services/dto/GetCallbackDTO";
import { useAppSelector } from "../state/hooks";

enum CallbacksType {
  ALL = "All",
  MISSED = "Missed",
}

const tabs: CallbacksType[] = [CallbacksType.ALL, CallbacksType.MISSED];

const CallBackScreen = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [selectedTab, setSelectedTab] = useState(CallbacksType.ALL);
  const [callbacks, setCallbacks] = useState<GetCallbackDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });
  const handleTabChange = (tabValue: number) => {
    setSelectedTab(tabs[tabValue]);
  };

  const loadCallbacks = useCallback(
    (text: string) => {
      const onSuccess = (data: any) => {
        const { info } = data;
        const callbacks: GetCallbackDTO[] =
          selectedTab == CallbacksType.ALL
            ? data.callbacks
            : data.missedCallbacks;
        setCallbacks(callbacks);
        setLoading(false);
        const count = info.count;
        const startCount = info.startIndex + 1;
        const endCount = info.startIndex + info.limit;
        const pages = Math.ceil(info.count / info.limit);
        setPagination((prev) => ({
          ...prev,
          count,
          startCount,
          endCount: count > endCount ? endCount : count,
          pages,
        }));
      };
      const onError = (err: any) => setLoading(false);
      selectedTab == CallbacksType.ALL
        ? CALLBACK_SERVICE.getAgentCallbacks(
            onSuccess,
            onError,
            userDetails.id,
            {
              startIndex: (pagination.page - 1) * limit,
              limit,
              search: text,
            }
          )
        : CALLBACK_SERVICE.getMissedCallbacks(onSuccess, onError, {
            startIndex: (pagination.page - 1) * limit,
            limit,
            search: text,
          });
    },
    [selectedTab]
  );

  const getSearchData = useCallback(lodash.debounce(loadCallbacks, 500), [
    loadCallbacks,
  ]);
  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getCallbacks(): void {
    setLoading(true);
    const onSuccess = (data: any) => {
      const { info } = data;
      const callbacks: GetCallbackDTO[] =
        selectedTab == CallbacksType.ALL
          ? data.callbacks
          : data.missedCallbacks;
      setCallbacks(callbacks);
      setLoading(false);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = (err: any) => setLoading(false);
    const params = {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search,
    };
    selectedTab == CallbacksType.ALL
      ? CALLBACK_SERVICE.getAgentCallbacks(
          onSuccess,
          onError,
          userDetails.id,
          params
        )
      : CALLBACK_SERVICE.getAgentMissedCallbacks(
          onSuccess,
          onError,
          userDetails.id,
          params
        );
  }

  useEffect(() => {
    getCallbacks();
  }, [pagination.page, selectedTab]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
          <Box
            display="flex"
            style={{ height: "33px" }}
            gap={{ xs: 1, md: 2 }}
            pb={{ xs: "6px", md: "0" }}>
            <Typography
              variant="h5"
              style={{ height: "33px", marginTop: "2px" }}>
              Callbacks
            </Typography>
            <Timer
              color="primary.main"
              sx={{
                fontWeight: 700,
                fontSize: { xs: "20px", md: "32px" },
                marginTop: "2px",
              }}
            />
          </Box>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "3px",
              marginLeft: "30px",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <CustomTabButtons
          tabs={["All Callbacks", "Missed Callbacks"]}
          containerWidth={mobileView ? "100%" : "304px"}
          borderRadius={"100px"}
          containerPadding={"0px"}
          btnPadding={mobileView ? "5px 10px" : "5px 15px"}
          btnHeight={mobileView ? "36px" : "34px"}
          btnBgColor={"#6F5FD9"}
          btnMinWidth={mobileView ? "50%" : "138px"}
          fontSize={mobileView ? " 12px" : "16px"}
          containerBorder={mobileView ? "none" : "1px solid #96A1B2"}
          onClick={handleTabChange}
          containerBgColor={mobileView ? "#F4F6FE" : "transparent"}
        />
      </Box>
      <Box display={{ xs: "none", md: "block", marginTop: "20px" }}>
        <AdvancedCallbacksTable
          color={theme.palette.primary.main}
          advancedCallbacks={callbacks || []}
          maxWidth="100%"
          hideStatus={true}
          showIndex={true}
          loading={loading}
          startIndex={(pagination.page - 1) * limit}
          onAction={getCallbacks}
        />
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <AdvancedCallbacksTableMobile
          advancedCallbacks={callbacks?.slice(0, 2) || []}
          onAction={getCallbacks}
        />
      </Box>
      <TablePagination page={pagination} setPage={setPagination} />
    </>
  );
};

export default CallBackScreen;
