import { Box, Pagination, PaginationItem, Typography } from "@mui/material";
import React from "react";

const TablePagination = ({ page, setPage }: any) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage((prev: any) => ({ ...prev, page: value }));
  };
  const styledButton = {
    fontSize: { xs: "12px", md: "16px" },
    color: "common.black",
    fontFamily: "Roboto",
    mr: { xs: "-4px", md: "16px" },
  };
  function prevButton() {
    return (
      <Typography
        sx={{
          ...styledButton,
          mr: { xs: "-4px", md: "16px" },
        }}
      >
        Prev
      </Typography>
    );
  }
  function nextButton() {
    return (
      <Typography
        sx={{
          ...styledButton,
          ml: { xs: "-4px", md: "16px" },
        }}
      >
        Next
      </Typography>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: 6,
        alignItems: "center",
        justifyContent: { xs: "center", md: "space-between" },
      }}
    >
      <Typography
        variant="body2"
        fontSize={"12px"}
        color="common.black"
        sx={{ opacity: "50%" }}
        display={{ xs: "none", md: "flex" }}
      >
        Showing {page.startCount} to {page.endCount} of {page.count} entries
      </Typography>
      <Pagination
        shape="rounded"
        count={page?.pages || 1}
        page={page?.page || 1}
        color="primary"
        sx={{
          "& .MuiPaginationItem-root": {
            height: { xs: "32px", md: "40px" },
            width: { xs: "32px", md: "40px" },
            borderRadius: "6px",
            fontWeight: { xs: 700, md: 400 },
          },
          "& .MuiPaginationItem-root:disabled": {
            backgroundColor: "transparent !important",
            opacity: 1,
          },
        }}
        renderItem={(item) => (
          <PaginationItem
            components={{
              previous: prevButton,
              next: nextButton,
            }}
            {...item}
          />
        )}
        onChange={handleChange}
      />
    </Box>
  );
};

export default TablePagination;
