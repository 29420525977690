import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CallButton from "../Actions/CallButton";

const CreateTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{
      disableUnderline: true,
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#fff",
  },
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: "6px",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-focused": {
      backgroundColor: "#fff",
    },
    input: {
      paddingTop: "10px",
    },
  },
}));
const InputField = ({
  inputNumber,
  onChange,
  onCall,
  disabled = false,
  margin = "24px 0 16px",
  isCalling = false,
}: {
  inputNumber: string;
  onChange: (event: string) => void;
  onCall?: () => void;
  disabled?: boolean;
  margin?: string;
  isCalling?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: " 1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        height: 50,
        paddingLeft: 1.8,
        boxSizing: "border-box",
        margin: margin,
        width: 280,
      }}>
      <Typography>+91</Typography>
      <Typography
        sx={{
          fontSize: "27px",
          fontWeight: 200,
          marginLeft: 1,
        }}>
        |
      </Typography>
      <CreateTextField
        id="custom-input"
        type="name"
        size="small"
        variant="filled"
        value={inputNumber}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        sx={{
          flex: 1,
          height: 40,
          backgroundColor: "transparent",
        }}
      />
      {inputNumber.toString().length > 0 ? (
        <div
          style={{ height: 50, width: 50 }}
          onClick={() => {
            onChange(inputNumber.slice(0, -1));
          }}>
          <BackspaceOutlinedIcon
            style={{ color: "rgba(0,0,0,0.7)", marginTop: 13 }}
          />
        </div>
      ) : null}

      {/* <Box
        style={{
          height: "50px",
          width: "50px",
          backgroundColor: "#93CE15",
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
        }}></Box> */}
      <CallButton
        onClick={onCall}
        isCalling={isCalling}
        disabled={disabled || !inputNumber}
        sx={{
          // transform: { xs: "scale(1.18,1.23)", md: "scale(1.5)" },
          borderRadius: "0 8px 8px 0",
          padding: { xs: "7.2px 2px", md: "5px" },
          boxSizing: "content-box",
          height: "40px",
          width: "40px",
        }}
      />
    </Box>
  );
};

export default InputField;
