import { Theme, useMediaQuery } from "@mui/material";
import ClockHeader from "../components/ClockHeader";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import EditUserDetailsForm from "../components/UserDetails/EditUserDetailsForm";

function EditUserDetails() {
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title="User Details"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <EditUserDetailsForm />
    </>
  );
}

export default EditUserDetails;
