import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { LogoBlack } from "../../assets/images/Logo";
import { DarkTooltip } from "../DarkToolTip";

const TitleBox = () => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate("/")}
      id="logo-desktop"
      sx={{
        cursor: "pointer",
        display: "flex",
        marginBottom: { lg: 0, xs: 5 },
        alignItems: "center",
        width: 200,
        paddingLeft: 2,
        marginTop: { lg: 3.5, xs: 0 },
      }}
    >
      <LogoBlack height="50" width="60" />
      <Box sx={{ ml: 3.2 }}>
        <Typography
          sx={{
            color: "black",
            fontWeight: 600,
            fontSize: "19px",
          }}
        >
          Dialvent
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontWeight: 500,
            fontSize: "9px",
            lineHeight: "4px",
          }}
        >
          Easy Calling !
        </Typography>
      </Box>
    </Box>
  );
};

export default TitleBox;
