import { Table, TableBody, TableContainer } from "@mui/material";
import { FORMATTER } from "../../helper/formatter";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import DataNotFound from "../DataNotfound/DataNotFound";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import TableProgress from "../Table/TableProgress";
import CallbackActions from "./CallbackActions";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "40px" },
  { id: "name", label: "Name", allowSort: false, width: "263px" },
  { id: "disposition", label: "Disposition", allowSort: false, width: "277px" },
  { id: "campaign", label: "Campaign", allowSort: false, width: "162px" },
  { id: "process", label: "Process", allowSort: false, width: "158px" },
  {
    id: "callback-date",
    label: "Callback Date",
    allowSort: false,
    width: "188px",
  },
  { id: "action", label: "Action", allowSort: false, width: "160px" },
];

const AdvancedCallbacksTable = ({
  color,
  advancedCallbacks,
  maxWidth,
  loading,
  startIndex = 0,
  onAction,
  calling = false,
  onOpen,
  onClose,
}: {
  title?: string;
  color: string;
  advancedCallbacks: GetCallbackDTO[];
  showIndex?: boolean;
  maxWidth?: any;
  hideStatus?: boolean;
  loading: boolean;
  startIndex?: number;
  onAction?: () => void;
  calling?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) => {
  return (
    <TableContainer>
      <Table>
        <StyledTableHead headColor={color}>
          {headCells.map((cell) => (
            <StyledTableCell key={cell.id} width={cell.width}>
              {cell.label}
            </StyledTableCell>
          ))}
        </StyledTableHead>
        {loading ? (
          <TableProgress colSpans={8} />
        ) : advancedCallbacks.length === 0 ? (
          <DataNotFound colSpans={8} />
        ) : (
          <TableBody>
            {advancedCallbacks.length > 0 &&
              advancedCallbacks.map(
                (callback: GetCallbackDTO, index: number) => (
                  <StyledTableRow key={callback.id}>
                    <StyledTableCell width={headCells[0].width} align="center">
                      {startIndex == 0 && index < 9
                        ? "0" + (index + 1)
                        : startIndex + index + 1}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[1].width}>
                      {callback.Lead.name}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[2].width}>
                      {callback.ParentCall.CallDetails.Disposition.name}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[3].width}>
                      {callback.ParentCall.Campaign?.name}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[4].width}>
                      {callback.ParentCall.Process.name}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[5].width}>
                      {FORMATTER.formatDateTime(callback.dateTime)}
                    </StyledTableCell>
                    <StyledTableCell width={headCells[6].width}>
                      <CallbackActions
                        onAction={onAction}
                        onOpen={onOpen}
                        onClose={onClose}
                        callback={callback}
                        calling={calling}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default AdvancedCallbacksTable;
