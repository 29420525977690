import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CallActionButtons from "../components/CallScreen.tsx/CallActionButtons";
import ClockHeader from "../components/ClockHeader";
import CommentBoxIcon from "../components/CommentBox/CommentBoxIcon";
import PersonalDetails from "../components/LeadInformation/PersonalDetails";
import Timer from "../components/Stopwatch/Timer";
import { resetTimer } from "../components/Stopwatch/useTimer";
import { CALL_SERVICE } from "../services/CallService";
import { SOCKET_EVENTS } from "../shared/constants";
import { useClientSocket } from "../shared/hooks/useClientSocket";
import { callSlice } from "../state/call/callSlice";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { leadSlice } from "../state/lead/leadSlice";
import { CallStatus } from "../state/types/call";
import { TLeadState } from "../state/types/lead";
import { UserStateStatus } from "../state/types/user";
import { userSlice } from "../state/user/userSlice";
import { DISPOSITION_SERVICE } from "../services/DispositionService";
import { dispositionsSlice } from "../state/dispositions/dispositionsSlice";

export const CALL_STATES = {
  RINGING: "RINGING",
  TALKING: "Talking",
  ON_HOLD: "On Hold",
  ON_MUTE: "On Mute",
  ENDED: "Disconnected",
};

const CallScreen = () => {
  const dispatch = useAppDispatch();
  const { processId } = useAppSelector((state) => state.call);
  const [loader, setLoader] = useState<boolean>(false);
  const userStatus = useAppSelector((state) => state.user.status);
  const call = useAppSelector((state) => state.call);
  const { id } = useAppSelector((state) => state.lead);
  const [callState, setCallState] = useState(
    call.callStatus === CallStatus.CALL_CREATED
      ? CALL_STATES.RINGING
      : CALL_STATES.TALKING
  );
  const { clientSocket } = useClientSocket();

  const callRejectedListener = useCallback(
    (data: any) => {
      dispatch(callSlice.actions.updateCallStatus(CallStatus.CALL_REJECTED));
      dispatch(userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION));
    },
    [clientSocket?.id]
  );

  useEffect(() => {
    const callConnectedListener = (data: any) => {
      setCallState(CALL_STATES.TALKING);
      dispatch(callSlice.actions.updateCallCode(data.callCode));
      dispatch(callSlice.actions.updateCallStatus(CallStatus.CALL_CONNECTED));

      clientSocket?.on(SOCKET_EVENTS.CALL_DISCONNECTED, () => {
        // clientSocket.off(SOCKET_EVENTS.CALL_REJECTED, callRejectedListener);
        dispatch(
          callSlice.actions.updateCallStatus(CallStatus.CALL_DISCONNECTED)
        );
        dispatch(
          userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION)
        );
      });
      resetTimer();
    };

    clientSocket?.once(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
    clientSocket?.on(SOCKET_EVENTS.CALL_REJECTED, callRejectedListener);

    return () => {
      clientSocket?.off(SOCKET_EVENTS.CALL_CONNECTED, callConnectedListener);
      clientSocket?.off(SOCKET_EVENTS.CALL_REJECTED, callRejectedListener);
    };
  }, [clientSocket?.id]);

  function getCallDetails(id: number) {
    // if (!callCode) return;
    const onSuccess = (data: any) => {
      setLoader(false);
      const lead: TLeadState = data.Lead;
      const leadId = lead.id;

      // dispatch(leadSlice.actions.updateLead({ key: "id", value: leadId }));
      // dispatch(callSlice.actions.updateLeadDetails(lead));
      dispatch(callSlice.actions.updateCallId(data.callId));
      dispatch(leadSlice.actions.setLead(lead));
    };
    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };

    if (call.callCode !== undefined && call.call_type !== "redial") {
      setLoader(true);
      CALL_SERVICE.getCallDetails(onSuccess, onError, {
        leadId: parseInt(id.toString()),
      });
    }
  }

  useEffect(() => {
    console.log("call_type_call_screen", call.lead.id);
    if (call.call_type !== "PREVIEW" && call.call_type !== "CALLBACK") {
      getCallDetails(call.lead.id);
    }
  }, [call.lead.id]);

  useEffect(() => {
    setCallState(
      call.callStatus === CallStatus.CALL_CREATED
        ? CALL_STATES.RINGING
        : call.callStatus === CallStatus.CALL_DISCONNECTED ||
          call.callStatus === CallStatus.CALL_REJECTED
        ? CALL_STATES.ENDED
        : CALL_STATES.TALKING
    );
  }, [call.callStatus]);

  const callStateColor =
    call.callOutCome === "On Hold"
      ? "#F2994A"
      : call.callOutCome === "On Mute"
      ? "#ce3a3a"
      : callState === CALL_STATES.TALKING || callState === CALL_STATES.RINGING
      ? "#5ECE62"
      : "#EB5757";

  useEffect(() => {
    getDispositionListById();
  }, []);

  const getDispositionListById = () => {
    const onSuccess = (data: any) => {
      const errorStatus = data.errorStatus;
      if (errorStatus === false) {
        const dispositions_data = data.dispositions;
        dispatch(
          dispositionsSlice.actions.updateDispositionData([
            { key: "disposition", value: dispositions_data },
            { key: "message", value: "" },
          ])
        );
      } else {
        dispatch(
          dispositionsSlice.actions.updateDispositionData([
            { key: "disposition", value: [] },
            { key: "message", value: data.message },
          ])
        );
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    DISPOSITION_SERVICE.getDispositionsByProcessId(
      onSuccess,
      onError,
      processId
    );
  };

  return (
    <Box>
      <ClockHeader />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: { lg: "initial", xs: "space-between" },
          flexWrap: { sm: "nowrap", xs: "wrap" },
        }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            minWidth: { xs: "", lg: "800px" },
          }}>
          <Typography variant="h5" sx={{ color: callStateColor }}>
            {call.callOutCome !== undefined && call.callOutCome !== ""
              ? call.callOutCome
              : callState === CALL_STATES.TALKING ||
                callState === CALL_STATES.RINGING
              ? "Call"
              : callState}
          </Typography>
          <Timer
            color="black"
            sx={{ fontWeight: 700, fontFamily: "Roboto, sans" }}
            stopTimer={userStatus === UserStateStatus.DISPOSITION}
          />
        </Box>
        {/* Commented until we create manage script functionality */}
        {/* <ScriptButton /> */}
      </Box>
      <Typography
        sx={{
          marginTop: { xs: "28px", md: "58px" },
          marginBottom: { xs: "13px", md: "10px" },
          fontSize: { xs: "14px", md: "16px" },
          fontWeight: 700,
          lineHeight: { xs: "14px", md: "16px" },
        }}>
        Lead Information
      </Typography>
      <PersonalDetails hideDateTime showCallbackDate={false} loader={loader} />
      <CommentBoxIcon />
      <CallActionButtons
        callState={callState}
        setCallState={setCallState}
        callcode={""}
      />
    </Box>
  );
};

export default CallScreen;
