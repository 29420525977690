import { Box, Checkbox, SelectChangeEvent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../Custom/CustomTextField";
import CustomRadioButtonsGroup from "../Custom/CustomRadioButtonsGroup";
import { ChangeEvent, useEffect, useState } from "react";
import FormActionButton from "../Actions/FormActionButton";
import DisposeLevelHeader from "./DisposeLevelHeader";
import CustomSelect from "../Custom/CustomSelect";
import { PostDispositionDTO } from "../../services/dto/PostDispositionDTO";
import { GetDispositionDTO } from "../../services/dto/GetDispositionDTO";
import { DISPOSITION_SERVICE } from "../../services/DispositionService";
import { disposeTypes } from "../../pages/ManageDispose";
import CustomCheckBox from "../Custom/CustomCheckBox";

const dispositionDTO: PostDispositionDTO = {
  name: "",
  isMultiLevel: false,
  callback: false,
  level: 3,
  parentId: null,
  disposeType: "standard",
};

const DisposeLevelThreeForm = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [dispositionForm, setDispositionForm] = useState(dispositionDTO);
  const [parentDispositions, setParentDispositions] = useState<
    GetDispositionDTO[]
  >([]);
  const [validationError, setValidationError] = useState({
    name: "",
    isMultiLevel: "",
    callback: "",
    level: "",
    parentId: "",
    disposeType: "",
  });

  const getDispositions = () => {
    const onSuccess = ({
      dispositions,
    }: {
      dispositions: GetDispositionDTO[];
    }) => {
      setParentDispositions(dispositions);
    };
    const onError = () => {};
    DISPOSITION_SERVICE.getDispositions(onSuccess, onError, { level: 2 });
  };

  useEffect(() => {
    getDispositions();
  }, []);

  const handleParenIdChange = (event: SelectChangeEvent) => {
    parentDispositions.map((disposition) => {
      if (disposition.name == event.target.value) {
        setDispositionForm((prev) => ({ ...prev, parentId: disposition.id }));
      }
    });
    setValidationError((prev) => ({ ...prev, parentId: "" }));
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { value } = event.target;
    setDispositionForm((prev) => ({
      ...prev,
      [id]: value == "" ? null : value,
    }));
    if (value === "" || value === null) {
      setValidationError((prev) => ({
        ...prev,
        [id]: "Error",
      }));
    } else {
      setValidationError((prev) => ({
        ...prev,
        [id]: "",
      }));
    }
    setErrorStatus(false);
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(dispositionForm)) {
      if (value === "" || value === null) {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  function addDisposition(data: any) {
    setLoading(true);
    const onSuccess = (data: any) => {
      const response = data.data;
      const error_status = response.error_status;
      if (error_status === true) {
        const message = response.message;
        setErrorStatus(true);
        setErrorMessage(message);
      } else {
        setErrorStatus(false);
        navigate(-1);
      }
      setLoading(false);
    };
    const onError = (err: any) => setLoading(false);
    DISPOSITION_SERVICE.postDisposition(onSuccess, onError, data);
  }

  const handleSubmit = () => {
    if (!validateForm()) return;
    addDisposition({
      ...dispositionForm,
      callback: dispositionForm.disposeType === "none" ? true : false,
    });
  };

  return (
    <>
      <DisposeLevelHeader disposeLevel="3" />
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}>
          Please fill all fields
        </Typography>
      ) : null}
      {errorStatus === true ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}>
          {errorMessage}
        </Typography>
      ) : null}
      <Box
        maxWidth={{ xs: "100%", sm: "413px" }}
        pt={{ xs: "10px", md: "20px" }}>
        <CustomTextField
          id="name"
          label="Name"
          required
          onChange={handleInputChange}
          value={dispositionForm.name}
          error={validationError.name}
          sx={{ marginBottom: "30px" }}
        />
        <CustomSelect
          name="parentId"
          label="Parent Dispose*"
          minWidth={{ xs: "100%", sm: "415px" }}
          sx={{ height: "52px", marginBottom: "25px" }}
          onChange={handleParenIdChange}
          error={Boolean(validationError.parentId)}
          selectedValue={
            parentDispositions.find(
              (disposition) => disposition.id === dispositionForm.parentId
            )?.name
          }
          values={parentDispositions.map((disposition) => disposition.name)}
        />

        {/* <CustomCheckBox
          checked={dispositionForm.callback}
          id="callback"
          onChange={(event: any) => {
            setDispositionForm((prev) => ({
              ...prev,
              callback: event.target.checked,
            }));
          }}
          title="CallBack"
        /> */}
      </Box>
      <CustomRadioButtonsGroup
        title="Dispose type*"
        id="disposeType"
        values={disposeTypes}
        gap={11}
        onChange={handleInputChange}
        selectedValue={dispositionForm.disposeType}
      />
      <Box display="flex" gap={2} pt="25px">
        <FormActionButton
          loading={loading}
          title="Save"
          variant="contained"
          onClick={handleSubmit}
        />
        <FormActionButton
          disabled={loading}
          title="Cancel"
          variant="outlined"
          onClick={() => navigate(-1)}
        />
      </Box>
    </>
  );
};

export default DisposeLevelThreeForm;
