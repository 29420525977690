import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AgentLoggerActions from "../../components/AgentCDR/AgentLoggerActions";
import AgentLoggerTable from "../../components/AgentCDR/AgentLoggerTable";
import AgentLoggerTableMobile from "../../components/AgentCDR/AgentLoggerTableMobile";
import { SearchBar } from "../../components/SearchBar";
import { CALL_SERVICE } from "../../services/CallService";
import { AgentCDRDTO, GetAgentCDRDTO } from "../../services/dto/GetAgentCdrDTO";
import { PAGINATION_SERVICE } from "../../services/PaginationService";
import { useAppSelector } from "../../state/hooks";

type OrderBy = keyof AgentCDRDTO;

const AgentLogger = () => {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sort, setSort] = useState<OrderBy>("agentId");
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [agentCDRs, setAgentCDRs] = useState<GetAgentCDRDTO | null>(null);
  const params = useAppSelector((state) => state.filterForm);
  const limit = 20;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 0,
    count: 0,
    startCount: 0,
    endCount: 0,
  });

  function getAgentCDR() {
    const onSuccess = (data: GetAgentCDRDTO) => {
      const { count, startCount, endCount, pages } =
        PAGINATION_SERVICE.mapPagination(data.info);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
      setLoader(false);
      setAgentCDRs(data);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };
    setLoader(true);

    let data = { ...params };
    data = {
      ...data,
      "filter-startAfter": data["filter-startAfter"] + "Z",
      "filter-startBefore": data["filter-startBefore"] + "Z",
    };
    CALL_SERVICE.getAllCDR(onSuccess, onError, {
      search,
      startIndex: (pagination.page - 1) * limit,
      limit,
      sortDirection,
      sort,
      ...data,
    });
  }

  useEffect(() => {
    getAgentCDR();
  }, [params, pagination.page, sort, sortDirection, search]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h5" marginRight={"20px"}>
            Agent Logger
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={(text) => setSearch(text)}
          />
        </Box>
        <AgentLoggerActions
          filters={agentCDRs?.filters}
          search={search}
          type="admin"
          sortDirection={sortDirection}
          sort={sort}
        />
      </Box>
      <Box>
        <Box sx={{ display: { marginTop: "20px" } }}>
          <AgentLoggerTable
            cdrs={agentCDRs?.cdrs ?? []}
            loader={loader}
            setOrder={setSortDirection}
            setOrderBy={setSort}
            pagination={pagination}
            setPagination={setPagination}
          />
        </Box>
        {/* <Box sx={{ display: { md: "none", xs: "block" } }}>
          <AgentLoggerTableMobile cdrs={agentCDRs?.cdrs ?? []} />
        </Box> */}
      </Box>
    </Box>
  );
};
export default AgentLogger;
