import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import Papa from "papaparse";

const UploadLeadTable = ({ leads }: { leads: any[] }) => {
  return (
    <Box>
      <Table sx={{ maxWidth: { xs: "100%", md: "55%" } }}>
        <TableHead>
          <TableRow>
            {leads[0] &&
              Object.keys(leads[0]).map((key) => (
                <>
                  {key === "leadsetId" ? null : (
                    <TableCell
                      sx={{ border: "1px solid black", fontWeight: 700 }}
                      key={key}>
                      {key === "configurations" ? "Other Data" : key}
                    </TableCell>
                  )}
                </>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((data, index) => (
            <TableRow key={index}>
              {Object.values(data).map((value, i) =>
                i === 6 ? null : (
                  <TableCell key={i} sx={{ border: "1px solid black" }}>
                    <>{JSON.stringify(value)}</>
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default UploadLeadTable;
