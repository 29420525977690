import { Box, Typography } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import Actions from "../components/ManageUsers/Actions";
import UsersTable from "../components/ManageUsers/UsersTable";
import UsersTableMobile from "../components/ManageUsers/UsersTableMobile";
import { SearchBar } from "../components/SearchBar";
import TablePagination from "../components/Table/TablePagination";
import { GetUserResponseDTO } from "../services/dto/GetUserResponseDTO";
import { USER_SERVICE } from "../services/UserService";

export enum UserState {
  ACTIVE = "Active",
  DELETED = "Deleted",
}
const tabs: UserState[] = [UserState.ACTIVE, UserState.DELETED];

const ManageUsers = () => {
  const [users, setUsers] = useState<GetUserResponseDTO[]>([]);
  const [userIds, setUserIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("firstName");
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });
  const [selectedTab, setSelectedTab] = useState<UserState>(UserState.ACTIVE);
  const handleTabChange = (tabIndex: number) => {
    setSelectedTab(tabs[tabIndex]);
  };

  const loadUsers = useCallback(
    (text: string) => {
      const onSuccess = ({
        info,
        users,
      }: {
        info: any;
        users: GetUserResponseDTO[];
      }) => {
        const users_data: GetUserResponseDTO[] = [];
        for (let i = 0; i < users.length; i++) {
          console.log(users[i].accessLevel);
          if (users[i].accessLevel !== "SUPERVISOR") {
            users_data.push(users[i]);
          }
        }
        setUsers(users_data);
        const count = info.count;
        const startCount = info.startIndex + 1;
        const endCount = info.startIndex + info.limit;
        const pages = Math.ceil(info.count / info.limit);
        setPagination((prev) => ({
          ...prev,
          count,
          startCount,
          endCount: count > endCount ? endCount : count,
          pages,
        }));
      };
      const onError = () => {};
      if (selectedTab === UserState.ACTIVE) {
        USER_SERVICE.getUsers(onSuccess, onError, {
          startIndex: 0,
          limit,
          search: text,
          sort: orderBy,
        });
      } else {
        USER_SERVICE.getDeletedUsers(onSuccess, onError, {
          startIndex: 0,
          limit,
          search: text,
          sort: orderBy,
        });
      }
    },
    [selectedTab]
  );

  const getSearchData = useCallback(lodash.debounce(loadUsers, 500), [
    loadUsers,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getUsers() {
    setLoading(true);
    const onSuccess = ({
      info,
      users,
    }: {
      info: any;
      users: GetUserResponseDTO[];
    }) => {
      const users_data: GetUserResponseDTO[] = [];
      for (let i = 0; i < users.length; i++) {
        // console.log(users[i].accessLevel);
        if (users[i].accessLevel !== "SUPERVISOR") {
          users_data.push(users[i]);
        }
      }
      setUsers(users_data);
      setLoading(false);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination({
        ...pagination,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      });
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    if (selectedTab === UserState.ACTIVE) {
      USER_SERVICE.getUsers(onSuccess, onError, {
        startIndex: (pagination.page - 1) * limit,
        limit,
        search,
        sortDirection: order,
        sort: orderBy,
      });
    } else {
      USER_SERVICE.getDeletedUsers(onSuccess, onError, {
        startIndex: (pagination.page - 1) * limit,
        limit,
        search,
        sortDirection: order,
        sort: orderBy,
      });
    }
  }

  useEffect(() => {
    setUserIds([]);
    users.map((user: GetUserResponseDTO) =>
      setUserIds((userIds) => [...userIds, user.id])
    );
  }, [users]);

  useEffect(() => {
    getUsers();
  }, [pagination.page, order, orderBy, selectedTab]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
          <Typography variant="h5" marginRight={"20px"}>
            User Details
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <Actions
          tabs={tabs}
          userIds={userIds}
          selectedTab={tabs.indexOf(selectedTab)}
          handleTabChange={handleTabChange}
          search={search}
        />
      </Box>
      <Box style={{ marginTop: "20px" }}>
        <UsersTable
          userState={selectedTab}
          color="#6F5FD9"
          users={users}
          onAction={getUsers}
          onUpdate={setUsers}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          startIndex={(pagination.page - 1) * limit}
          loading={loading}
          getUsersAPI={getUsers}
        />
        <UsersTableMobile
          userState={selectedTab}
          onUpdate={setUsers}
          users={users}
          onAction={getUsers}
          startIndex={(pagination.page - 1) * limit}
          getUsersAPI={getUsers}
        />
        <TablePagination page={pagination} setPage={setPagination} />
      </Box>
    </>
  );
};

export default ManageUsers;
