import { createSlice } from "@reduxjs/toolkit";
import { TUserDetailsFormState } from "../types/userForm";
import {
  TUserDetailsFormReducer,
  USER_DETAILS_FORM_REDUCERS,
} from "./userFormReducres";

export const userDetailsFormSlice = createSlice<
  TUserDetailsFormState,
  TUserDetailsFormReducer
>({
  name: "userForm",
  initialState: {
    id: 0,
    clientId: 0,
    createdBy: 0,
    status: "",
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    panCard: "",
    aadharCard: "",
    accessLevel: "",
    gender: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    pincode: "",
    nationality: "",
    bloodGroup: "",
    qualification: "",
    dob: "",
    isOnline: false,
  },
  reducers: USER_DETAILS_FORM_REDUCERS,
});
