import { Box } from "@mui/system";
import RoundedButtons from "../Custom/RoundedButtons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { FilterPopup } from "../RecordingLog/FilterPopup";
import { CDRFilters } from "../../services/dto/GetAgentCdrDTO";
import { filterFormSlice } from "../../state/filter-form/filterFormSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { CALL_SERVICE } from "../../services/CallService";
import BackdropLoader from "../BackdropLoader";
import { UserAccessLevel } from "../../state/types/user";
import { DOWNLOADER } from "../../helper/downloader";
import { PerformanceGaugeFilterPopup } from "./PerformanceGaugeFilterPopup";
import { SelectOption } from "../Custom/CustomSelect";
import LightTooltip from "../LightToolTip";
import { PERFORMACNE_GAUGE_SERVICE } from "../../services/PerformanceGauageService";

const PerformaceGaugeActions = ({
  filters,
  search,
  type,
  agentList,
}: {
  filters?: CDRFilters;
  search?: string;
  type: string;
  agentList: Array<SelectOption>;
}) => {
  const { filterForm } = useAppSelector((state) => state);
  const [filterPopup, setFilterPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { accessLevel } = useAppSelector((state) => state.user.userDetails);
  const isAdmin = accessLevel === UserAccessLevel.ADMIN;

  const clearFilters = () => {
    dispatch(filterFormSlice.actions.resetForm());
  };
  const handleExport = () => {
    if (!isAdmin) return;
    const onSuccess = (data: any) => {
      setLoading(false);
      DOWNLOADER.download(
        data,
        `Perfomance_Gauge-${new Date().valueOf()}.xlsx`
      );
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    PERFORMACNE_GAUGE_SERVICE.exportPerfomaceGauge(onSuccess, onError, {
      startDate: filterForm["filter-startAfter"] + "Z",
      endDate: filterForm["filter-startBefore"] + "Z",
    });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}>
        <RoundedButtons
          title="Clear"
          Icon={CloseIcon}
          color="#c1bdbd"
          sx={{
            color: "black",
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.25)",
          }}
          IconColor="black"
          onClick={clearFilters}
        />

        <RoundedButtons
          onClick={() => {
            setFilterPopup(true);
          }}
          tooltip="Set Filters"
          title="Filter"
          Icon={FilterAltIcon}
          color="#E2606C"
        />
        {isAdmin && (
          <RoundedButtons
            title="Export"
            Icon={CloudDownloadIcon}
            color="#5ECE62"
            onClick={handleExport}
            disabled={!isAdmin}
          />
        )}
      </Box>
      <PerformanceGaugeFilterPopup
        agentList={agentList}
        filters={filters}
        open={filterPopup}
        setOpen={setFilterPopup}
        type={type}
      />
      {loading && <BackdropLoader loading={loading} />}
    </>
  );
};

export default PerformaceGaugeActions;
