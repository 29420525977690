import { IconButton, useTheme } from "@mui/material";
import { forwardRef } from "react";

const CustomIconButton = forwardRef(
  (
    {
      onClick,
      Icon,
      backgroundColor,
      sx,
      IconSize,
      color,
      disabled = false,
      ...rest
    }: {
      onClick?: () => void;
      Icon: any;
      backgroundColor: string;
      sx?: React.CSSProperties;
      IconSize?: number;
      color?: string;
      disabled?: boolean;
    },
    ref?: any
  ) => {
    const theme = useTheme();
    return (
      <IconButton
        ref={ref}
        onClick={() => {
          !disabled && onClick && onClick();
        }}
        size="medium"
        sx={{
          backgroundColor: backgroundColor,
          color: theme.palette.common.white,
          borderRadius: "4px",
          ":hover": {
            backgroundColor: backgroundColor,
          },
          ...sx,
        }}
        {...rest}>
        <Icon color={color} sx={{ fontSize: IconSize }} />
      </IconButton>
    );
  }
);

export default CustomIconButton;
