import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import AlertBox from "../../components/AlertBox/AlertBox";
import BreakStatus from "../../components/BreakStatus/BreakStatus";
import CustomTabButtons from "../../components/Custom/CustomTabButtons";
import CustomTabPanel from "../../components/Custom/CustomTabPanel";
import StartDialingButton from "../../components/Custom/StartDialingButton";
import LeadsPieChart from "../../components/LeadsPieChart/LeadsPieChart";
import CallingStats from "../../components/ProductivityStats/CallingStats";
import ProductivityStats from "../../components/ProductivityStats/ProductivityStats";
import { Stopwatch } from "../../components/Stopwatch/Stopwatch";
import { useAppSelector } from "../../state/hooks";

const AgentDashboard = () => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { micErrorStatus, webRTCConnStatus } = useAppSelector(
    (state) => state.user
  );
  const midSizeView = useMediaQuery("(max-width:500px)");
  const smallSizeView = useMediaQuery("(max-width:350px)");

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (tabValue: number) => setSelectedTab(tabValue);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          xl={8.4}
          sx={{ borderRight: { xs: "none", xl: "1px solid rgba(0,0,0,0.1)" } }}
        >
          <Grid
            container
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} md={4.8}>
              <Typography variant="h5">My Dashboard</Typography>
              <Typography
                variant="body2"
                pt={{ xs: "5px", md: "10px" }}
                fontSize={{ xs: "12px", md: "16px" }}
              >
                Here's an overview of your pipeline for today.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={7.2}
              display={"flex"}
              justifyContent={"center"}
            >
              <StartDialingButton
                disable={micErrorStatus || !webRTCConnStatus}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 0, md: 0 }}
            pt={{ xs: "16px", md: "30px" }}
            display={"flex"}
          >
            <Grid item xs={12} lg={4.8}>
              <AlertBox />
              {mobileView && (
                <Box
                  display={{ xs: "flex" }}
                  justifyContent="center"
                  pt="24px"
                  pb="16px"
                >
                  <CustomTabButtons
                    tabs={["Activity Stats", "Leads Taken", "Dialing Stats"]}
                    containerWidth={mobileView ? "100%" : "328px"}
                    borderRadius={"8px"}
                    containerPadding={"0px"}
                    btnPadding={"10px 13px"}
                    btnHeight={"38px"}
                    btnBgColor={"#6F5FD9"}
                    btnMinWidth={
                      smallSizeView ? "90px" : midSizeView ? "99px" : "32%"
                    }
                    containerBgColor={"#F4F6FE"}
                    fontSize={"14px"}
                    containerBorder={"none"}
                    onClick={handleTabChange}
                  />
                </Box>
              )}
            </Grid>
            <Grid item sm={12} lg={7.2}>
              <Box display={{ xs: "none", md: "block" }}>
                <ProductivityStats />
              </Box>
              <CustomTabPanel
                value={selectedTab}
                index={0}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <ProductivityStats />
              </CustomTabPanel>
            </Grid>
          </Grid>
          <Box display={{ xs: "none", md: "block" }}>
            <LeadsPieChart />
          </Box>
          <CustomTabPanel
            value={selectedTab}
            index={1}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <LeadsPieChart />
          </CustomTabPanel>
        </Grid>
        <Grid item xs={12} xl={3.6}>
          <Box display={{ xs: "none", md: "block" }} p="0 24px">
            <Typography
              variant="h6"
              pb="12px"
              pt="25px"
              display={{ xs: "none", md: "block" }}
            >
              Dialing Stats
            </Typography>
            <CallingStats />
            <BreakStatus />
          </Box>
          <CustomTabPanel
            value={selectedTab}
            index={2}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Typography
              variant="h6"
              pb="12px"
              display={{ xs: "none", md: "block" }}
            >
              Dialing Stats
            </Typography>
            <CallingStats />
            <BreakStatus />
          </CustomTabPanel>
        </Grid>
      </Grid>
      <Stopwatch />
    </>
  );
};

export default AgentDashboard;
