import { CustomPopup } from "../components/Custom/CustomPopup";
import { Box, Typography } from "@mui/material";
import RoundedButtons from "./Custom/RoundedButtons";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FormActionButton from "./Actions/FormActionButton";
import { GetProcessDTO } from "../services/dto/GetProcessDTO";

export const LeadsBifurcationPopup = ({
  open,
  setOpen,
  process,
}: {
  open: boolean;
  setOpen: Function;
  process?: GetProcessDTO;
}) => {
  const LeadsBifurcationPopupContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          mt="10px"
          mb="30px"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <RoundedButtons
            title={`Remaining Leads (${
              process?.processDetails.remainingLeads || 0
            })`}
            color="#F2994A"
            Icon={CloudDownloadIcon}
            suffixIcon
          />
          <RoundedButtons
            title="Fresh (2810)"
            color="#5ECE62"
            Icon={CloudDownloadIcon}
            suffixIcon
          />
        </Box>
        <FormActionButton
          title="done"
          sx={{ textTransform: "initial", marginBottom: "15px" }}
        />
      </Box>
    );
  };

  const LeadsBifurcationPopupHeader = () => {
    return (
      <Typography sx={{ textAlign: "center", fontSize: "24px" }}>
        Leads Bifurcation
      </Typography>
    );
  };
  return (
    <CustomPopup
      Header={LeadsBifurcationPopupHeader}
      open={open}
      setOpen={setOpen}
      Component={LeadsBifurcationPopupContent}
      maxWidth={800}
      minWidth="650px"
    ></CustomPopup>
  );
};
