import TableViewIcon from "@mui/icons-material/TableView";
import { IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { BreakLogsDTO } from "../../services/dto/GetBreakLogsDTO";
import UGTable from "../EVCustoms/UGTable/UGTable";
import LightTooltip from "../LightToolTip";
import { BreakLogsDetailPopup } from "./BreakLogsDetailPopup";

const BreakLogsTable = ({
  data,
  startIndex,
  recordingLogLoader,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  startEndDate,
  loader,
}: {
  data: BreakLogsDTO[];
  startIndex: number;
  recordingLogLoader: boolean;
  order: "asc" | "desc";
  orderBy: string;
  setOrder: Function;
  setOrderBy: Function;
  startEndDate: any;
  loader: boolean;
}) => {
  const [popupStatus, setPopupStatus] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<BreakLogsDTO>({
    duration: {},
    user: {},
  });

  const headCells: GridColDef[] = [
    {
      field: "sr",
      headerName: "Sr.",
      width: 40,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Typography style={{ fontSize: "14px" }}>{row.sr_no}</Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Agent Name",
      width: 220,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Typography style={{ fontSize: "14px" }}>
            {(row.firstName.length === 0 ? "No Name" : row.firstName + " ") +
              row.lastName +
              " (" +
              row.username +
              ")"}
          </Typography>
        );
      },
    },
    { field: "general", headerName: "General", width: 90 },
    { field: "lunch", headerName: "Lunch", width: 90 },
    { field: "meeting", headerName: "Meeting", width: 90 },
    { field: "tea", headerName: "Tea", width: 90 },
    { field: "bio", headerName: "Bio", width: 90 },
    { field: "other", headerName: "Bio", width: 90 },
    {
      field: "refreshment",
      headerName: "Refreshment",
      width: 120,
    },
    { field: "total", headerName: "Total Breaks", width: 120 },
    {
      field: "pauseDetails",
      headerName: "Actions",
      width: 180,
      headerAlign: "left",
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <LightTooltip title="Break Details">
            <IconButton
              onClick={() => {
                setSelectedUser(row);
                setPopupStatus(true);
              }}>
              <TableViewIcon sx={{ color: "#6f5fd9" }} width="22" height="22" />
            </IconButton>
          </LightTooltip>
        );
      },
    },
  ];
  return (
    <>
      <UGTable header={headCells} data={data} loader={loader} />
      <BreakLogsDetailPopup
        startEndDate={startEndDate}
        open={popupStatus}
        setOpen={setPopupStatus}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default BreakLogsTable;
