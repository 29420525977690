function DashboardIcon({ color = "white" }: { color?: string }) {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3462 5.42224H3.65385C2.18817 5.42224 1 6.61041 1 8.07609V18.6915C1 20.1571 2.18817 21.3453 3.65385 21.3453H21.3462C22.8118 21.3453 24 20.1571 24 18.6915V8.07609C24 6.61041 22.8118 5.42224 21.3462 5.42224Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M21.0896 5.42219V3.76353C21.0895 3.35672 20.9996 2.95496 20.8263 2.5869C20.653 2.21884 20.4006 1.89357 20.0872 1.63428C19.7737 1.37499 19.4069 1.18808 19.0128 1.08689C18.6188 0.985697 18.2073 0.972715 17.8077 1.04887L3.24692 3.53409C2.6146 3.65459 2.04415 3.99197 1.63396 4.48806C1.22378 4.98414 0.999564 5.60781 1 6.25151V8.96065"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18.6923 15.1528C18.3424 15.1528 18.0003 15.0491 17.7094 14.8547C17.4184 14.6603 17.1917 14.384 17.0578 14.0607C16.9239 13.7374 16.8888 13.3816 16.9571 13.0385C17.0254 12.6953 17.1939 12.38 17.4413 12.1326C17.6887 11.8851 18.004 11.7166 18.3472 11.6484C18.6904 11.5801 19.0461 11.6151 19.3694 11.7491C19.6927 11.883 19.969 12.1097 20.1634 12.4007C20.3578 12.6916 20.4616 13.0337 20.4616 13.3836C20.4616 13.8528 20.2752 14.3029 19.9434 14.6346C19.6116 14.9664 19.1616 15.1528 18.6923 15.1528Z"
        fill={color}
      />
    </svg>
  );
}

export default DashboardIcon;
