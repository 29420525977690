import { PayloadAction } from "@reduxjs/toolkit";
import { TDefaultProcess } from "../types/defaultProcess";

function setDefaultProcess(
  state: TDefaultProcess,
  action: PayloadAction<TDefaultProcess>
) {
  return action == undefined ? state : action.payload;
}

function updateDefaultProcess(
  state: TDefaultProcess,
  action: PayloadAction<{ [key: string]: string | number }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

export const DEFAULT_PROCESS_REDUCERS = {
  setDefaultProcess,
  updateDefaultProcess,
};

export type TDefaultProcessReducer = typeof DEFAULT_PROCESS_REDUCERS;
