import { URL_CONSTANTS } from "../shared/urls";
import { TUserDetailsFormState } from "../state/types/userForm";
import {
  DeleteAPI,
  GetAPI,
  PatchAPI,
  PatchAPIToastify,
  PostAPI,
  PutAPI,
} from "./API";

function postUser(onSuccess: any, onError: any, dto: any) {
  PostAPI.call(URL_CONSTANTS.POST_USER, dto, onSuccess, {}, onError);
}

function updateUser(
  onSuccess: Function,
  onError: Function,
  dto: TUserDetailsFormState,
  id: string
) {
  PutAPI.call(URL_CONSTANTS.EDIT_USER(id), dto, onSuccess, {}, onError);
}

function getUsers(onSuccess: any, onError: any, query: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_USERS,
    (res: any) => {
      onSuccess(res.data);
    },
    { params: query },
    onError
  );
}

function getActiveUsers(onSuccess: any, onError: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_ACTIVE_USERS,
    (res: any) => {
      onSuccess(res.data);
    },
    onError
  );
}

function getDeletedUsers(onSuccess: any, onError: any, query: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_DELETED_USERS,
    (res: any) => {
      onSuccess(res.data);
    },
    { params: query },
    onError
  );
}

function enableUser(onSuccess: any, onError: any, dto: any) {
  PutAPI.call(URL_CONSTANTS.ENABLE_USER, dto, onSuccess);
}

function disableUser(onSuccess: any, onError: any, dto: any) {
  PutAPI.call(URL_CONSTANTS.DISABLE_USER, dto, onSuccess);
}

function deleteUser(onSuccess: Function, onError: Function, id: string) {
  DeleteAPI.call(URL_CONSTANTS.DELETE_USER(id), onSuccess, {}, onError);
}

function exportUsers(onSuccess: any, onError: any, query: any) {
  GetAPI.call(
    URL_CONSTANTS.EXPORT_USERS,
    onSuccess,
    { responseType: "blob", params: query },
    onError
  );
}

function restoreUser(onSuccess: Function, onError: Function, id: string) {
  PatchAPI.call(URL_CONSTANTS.RESTORE_USER(id), {}, onSuccess, {}, onError);
}

function getProcessAgainstUser(onSuccess: any, onError: any, id: number) {
  GetAPI.call(
    URL_CONSTANTS.GET_PROCESS_DATA_AGAINST_USER(id),
    (res: any) => {
      onSuccess(res.data);
    },
    onError
  );
}

function deleteUserFromProcess(
  onSuccess: Function,
  onError: Function,
  process_id: string,
  id: number
) {
  DeleteAPI.call(
    `${URL_CONSTANTS.DELETE_USER_FROM_PROCESS(process_id)}`,
    onSuccess,
    { userIds: [id] },
    onError
  );
}

function updateUserDailySummaryStatus(
  onSuccess: Function,
  onError: Function,
  dto: any,
  option_toastify: {
    pending: string;
    success: string;
    error: string;
  }
) {
  PatchAPIToastify.call(
    URL_CONSTANTS.UPDATE_USER_TABLE_DAILY_SUMMARY_STATUS,
    dto,
    onSuccess,
    {},
    onError,
    () => {},
    option_toastify
  );
}

export const USER_SERVICE = {
  getUsers,
  getActiveUsers,
  updateUser,
  getDeletedUsers,
  enableUser,
  disableUser,
  postUser,
  deleteUser,
  exportUsers,
  restoreUser,
  getProcessAgainstUser,
  deleteUserFromProcess,
  updateUserDailySummaryStatus,
};
