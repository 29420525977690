import ClockHeader from "../ClockHeader";
import { useMediaQuery, Theme } from "@mui/material";
import BackButton from "../Custom/BackButton";
import { useNavigate } from "react-router-dom";
import CustomPageHeading from "../Custom/CustomPageHeading";

function DisposeLevelHeader({ disposeLevel }: { disposeLevel: string }) {
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title={`Create Level ${disposeLevel} Dispose`}
        marginBottom={mobileview ? "6px" : "10px"}
      />
    </>
  );
}

export default DisposeLevelHeader;
