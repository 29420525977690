import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import VisibilityIcon from "../../assets/images/VisibilityIcon";
import { SelectOption } from "../Custom/CustomSelect";

const VisibilityButton = ({
  options,
  onChange,
}: {
  options: SelectOption[];
  onChange: (event: SelectChangeEvent) => void;
}) => {
  return (
    <Box
      height="20px"
      sx={{
        backgroundColor: "#EEEEEE",
        display: "flex",
        alignItems: "center",
        pl: "10px",
      }}
    >
      <VisibilityIcon />
      <Select
        defaultValue={options[0].value.toString()}
        size="small"
        onChange={onChange}
        sx={{
          border: "none",
          width: "140px",
          display: "flex",
          alignItems: "center",
          ml: "-10px",
          pt: "11px",
          "& .MuiSvgIcon-root": {
            "&.MuiSelect-icon": {
              fontSize: "12px",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        IconComponent={KeyboardArrowDown}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                color: "#4F4F4F",
                lineHeight: "12px",
              }}
            >
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default VisibilityButton;
