import { useMediaQuery } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AgentCDRIcon from "../../assets/images/side-nav/AgentCDRIcon";
import CallScreenIcon from "../../assets/images/side-nav/CallScreenIcon";
import CallbacksIcon from "../../assets/images/side-nav/CallbacksIcon";
import DashboardIcon from "../../assets/images/side-nav/DashboardIcon";
import ManageCampaignsIcon from "../../assets/images/side-nav/ManageCampaignsIcon";
import ReportsIcon from "../../assets/images/side-nav/ReportsIcon";
import { ALL_ROUTES } from "../../shared/routes";
import { defaultTheme } from "../../shared/theme";
import { useAppSelector } from "../../state/hooks";
import { UserAccessLevel, UserStateStatus } from "../../state/types/user";
import { userSlice } from "../../state/user/userSlice";
import DrawerLarge from "./DrawerLarge";
import DrawerMobile from "./DrawerMobile";

type LinkProps = {
  id: number;
  title: string;
  path: string;
  paths?: string[];
  onNavigate?: () => void;
  allowedStates?: UserStateStatus[];
};

export type CustomNavLink = {
  link: LinkProps;
  accessLevel: string[];
  children?: LinkProps[];
  Icon: any;
};

const SideNavBar = ({
  closeDrawer,
  open,
  setOpen,
}: {
  closeDrawer: any;
  open: any;
  setOpen: Function;
}) => {
  const defaultProcess = useAppSelector((state) => state.defaultProcess);
  const lead = useAppSelector((state) => state.lead);
  const call = useAppSelector((state) => state.call);

  const dispatch = useDispatch();
  const navLinks: CustomNavLink[] = [
    {
      link: {
        id: 1,
        title: "Dashboard",
        path: ALL_ROUTES.ADMIN_DASHBAORD,
        paths: [ALL_ROUTES.ADMIN_DASHBAORD],
        onNavigate: () => {},
        allowedStates: [
          UserStateStatus.PRE_READY,
          UserStateStatus.READY_AUTOCALLING,
          UserStateStatus.READY_CALLBACK,
          UserStateStatus.READY_IDLE,
          UserStateStatus.MANUAL,
          UserStateStatus.CALLBACK,
          UserStateStatus.LOGIN,
          UserStateStatus.REDIAL_CALL,
        ],
      },
      accessLevel: [UserAccessLevel.ADMIN],
      Icon: DashboardIcon,
    },
    {
      link: {
        id: 10,
        title: "Dashboard",
        path: ALL_ROUTES.AGENT_DASHBAORD,
        paths: [ALL_ROUTES.AGENT_DASHBAORD],
        onNavigate: () => {},
        allowedStates: [UserStateStatus.STOP, UserStateStatus.PAUSE],
      },
      accessLevel: [UserAccessLevel.ADMIN, UserAccessLevel.AGENT],
      Icon: DashboardIcon,
    },
    defaultProcess.callingMode === "PREVIEW" && call.call_type === "PREVIEW"
      ? {
          link: {
            id: 2,
            title: "Calling Screen",
            path: ALL_ROUTES.PREVIEW,
            paths: [ALL_ROUTES.READY, ALL_ROUTES.PREVIEW],
            onNavigate: () => {
              dispatch(
                userSlice.actions.updateUserStatus(UserStateStatus.PREVIEW)
              );
            },
            allowedStates: [
              UserStateStatus.CALLBACK,
              UserStateStatus.DISPOSITION,
              UserStateStatus.PRE_READY,
              UserStateStatus.READY_AUTOCALLING,
              UserStateStatus.READY_CALLBACK,
              UserStateStatus.READY_IDLE,
              UserStateStatus.MANUAL,
              UserStateStatus.PREVIEW,
              UserStateStatus.REDIAL_CALL,
              UserStateStatus.CALLBACK_PREVIEW,
              UserStateStatus.MANUAL_PREVIEW,
            ],
          },
          accessLevel: [UserAccessLevel.ADMIN, UserAccessLevel.AGENT],
          Icon: CallScreenIcon,
        }
      : {
          link: {
            id: 2,
            title: "Calling Screen",
            path: ALL_ROUTES.READY,
            paths: [ALL_ROUTES.READY, ALL_ROUTES.PREVIEW],
            onNavigate: () => {
              dispatch(userSlice.actions.goToReady());
            },
            allowedStates: [
              UserStateStatus.CALLBACK,
              UserStateStatus.DISPOSITION,
              UserStateStatus.PRE_READY,
              UserStateStatus.READY_AUTOCALLING,
              UserStateStatus.READY_CALLBACK,
              UserStateStatus.READY_IDLE,
              UserStateStatus.MANUAL,
              UserStateStatus.PREVIEW,
              UserStateStatus.CALLBACK_PREVIEW,
              UserStateStatus.MANUAL_PREVIEW,
            ],
          },
          accessLevel: [UserAccessLevel.ADMIN, UserAccessLevel.AGENT],
          Icon: CallScreenIcon,
        },
    {
      link: {
        id: 3,
        title: "Calling Screen",
        path: ALL_ROUTES.CALL,
        paths: [ALL_ROUTES.CALL],
        onNavigate: () => {},
        allowedStates: [
          UserStateStatus.CALL,
          UserStateStatus.DISPOSITION,
          UserStateStatus.REDIAL_CALL,
        ],
      },
      accessLevel: [UserAccessLevel.ADMIN, UserAccessLevel.AGENT],
      Icon: CallScreenIcon,
    },
    // {
    //   link: {
    //     id: 3,
    //     title: "Headset Test",
    //     path: "",
    //     allowedStates: [UserStateStatus.STOP, UserStateStatus.PAUSE],
    //   },
    //   accessLevel: [UserAccessLevel.ADMIN, UserAccessLevel.AGENT],
    //   Icon: HeadsetTestIcon,
    // },
    {
      link: {
        id: 4,
        title: "Call Backs",
        path: ALL_ROUTES.CALLBACKS,
        onNavigate: () => dispatch(userSlice.actions.goToCallback()),
        allowedStates: [
          UserStateStatus.PRE_READY,
          UserStateStatus.READY_AUTOCALLING,
          UserStateStatus.READY_CALLBACK,
          UserStateStatus.READY_IDLE,
          UserStateStatus.MANUAL,
          UserStateStatus.PREVIEW,
          UserStateStatus.CALLBACK,
          UserStateStatus.CALLBACK_PREVIEW,
          UserStateStatus.MANUAL_PREVIEW,
        ],
      },
      accessLevel: [UserAccessLevel.ADMIN, UserAccessLevel.AGENT],
      Icon: CallbacksIcon,
    },
    {
      link: {
        id: 30,
        title: "Agent CDR",
        path: ALL_ROUTES.AGENT_CDR,
        paths: [ALL_ROUTES.AGENT_CDR],
        onNavigate: () => {},
        allowedStates: [UserStateStatus.STOP, UserStateStatus.PAUSE],
      },
      accessLevel: [UserAccessLevel.AGENT],
      Icon: AgentCDRIcon,
    },
    {
      link: {
        id: 5,
        title: "CDR",
        path: ALL_ROUTES.AGENT_LOGGER,
      },
      accessLevel: [UserAccessLevel.ADMIN],
      Icon: AgentCDRIcon,
      children: [
        { id: 51, title: "Agent Logger", path: ALL_ROUTES.AGENT_LOGGER },
        // { id: 52, title: "Missed Calls", path: ALL_ROUTES.MISSED_CDR },
        { id: 52, title: "Recording", path: ALL_ROUTES.AGENT_RECORDING },
      ],
    },
    {
      link: {
        id: 6,
        title: "Reports",
        path: ALL_ROUTES.CALL_BACK_LOGGER,
      },
      accessLevel: [UserAccessLevel.ADMIN],
      Icon: ReportsIcon,
      children: [
        { id: 61, title: "Callback Logger", path: ALL_ROUTES.CALL_BACK_LOGGER },
        {
          id: 62,
          title: "Performance Gauge",
          path: ALL_ROUTES.PERFORMANCE_GAUGE,
        },
        { id: 63, title: "Break Logs", path: ALL_ROUTES.BREAK_LOGS },
        // { id: 64, title: "Queue Metric", path: ALL_ROUTES.QUEUE_METRIC },
        // {
        //   id: 63,
        //   title: "All Leads",
        //   path: ALL_ROUTES.ALL_LEADS,
        // },
      ],
    },
    {
      link: {
        id: 7,
        title: "Operations",
        path: ALL_ROUTES.MANAGE_CAMPAIGNS,
      },
      accessLevel: [UserAccessLevel.ADMIN],
      Icon: ManageCampaignsIcon,
      children: [
        { id: 71, title: "Manage Users", path: ALL_ROUTES.MANAGE_USERS },
        {
          id: 72,
          title: "Manage Campaigns",
          path: ALL_ROUTES.MANAGE_CAMPAIGNS,
        },
        { id: 73, title: "Manage Leadsets", path: ALL_ROUTES.MANAGE_LEADSET },
        { id: 74, title: "Manage Dispose", path: ALL_ROUTES.MANAGE_DISPOSE },
      ],
    },
  ];

  const theme = defaultTheme;
  const isTablet = useMediaQuery(theme.breakpoints.up("lg"));

  const { shortcutKeys } = useAppSelector((state) => state.user);
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails.accessLevel === UserAccessLevel.ADMIN) {
      if (shortcutKeys === true) {
        window.addEventListener("keyup", handleKeyPress);
      } else {
        window.removeEventListener("keyup", handleKeyPress);
      }
    }
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [shortcutKeys]);

  const handleKeyPress = useCallback((e: any) => {
    const event = e;
    if (
      event.target.tagName !== "INPUT" &&
      event.target.tagName !== "TEXTAREA"
    ) {
      if (event.shiftKey) {
        switch (event.keyCode) {
          case 65:
            navigate(ALL_ROUTES.AGENT_LOGGER);
            break;
          case 82:
            navigate(ALL_ROUTES.AGENT_RECORDING);
            break;
          case 67:
            navigate(ALL_ROUTES.CALL_BACK_LOGGER);
            break;
          case 80:
            navigate(ALL_ROUTES.PERFORMANCE_GAUGE);
            break;
          case 66:
            navigate(ALL_ROUTES.BREAK_LOGS);
            break;
          case 85:
            navigate(ALL_ROUTES.MANAGE_USERS);
            break;
          case 68:
            navigate(ALL_ROUTES.MANAGE_DISPOSE);
            break;
          case 76:
            navigate(ALL_ROUTES.MANAGE_LEADSET);
            break;
          default:
            break;
        }
      }
      if (event.ctrlKey && event.shiftKey && event.code === "KeyC") {
        navigate(ALL_ROUTES.MANAGE_CAMPAIGNS);
      }
      if (event.ctrlKey && event.shiftKey && event.code === "KeyH") {
        navigate(ALL_ROUTES.ADMIN_DASHBAORD);
      }
    }
  }, []);

  if (isTablet) {
    return <DrawerLarge navLinks={navLinks} />;
  }

  return (
    <DrawerMobile
      open={open}
      setOpen={setOpen}
      navLinks={navLinks}
      toggleDrawerSmall={closeDrawer}
    />
  );
};

export default SideNavBar;
