import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  useTheme,
} from "@mui/material";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import { generatePath, useNavigate } from "react-router-dom";
import { useState } from "react";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { ALL_ROUTES } from "../../shared/routes";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import { LEADSET_SERVICE } from "../../services/LeadsetService";
import { GetLeadsetDTO } from "../../services/dto/GetLeadsetDTO";
import TableProgress from "../Table/TableProgress";
import DataNotFound from "../DataNotfound/DataNotFound";
import LightTooltip from "../LightToolTip";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "50px" },
  { id: "name", label: "Leadset Name", allowSort: true, width: "200px" },
  { id: "description", label: "Description", allowSort: true, width: "920px" },
  { id: "action", label: "Action", allowSort: false, width: "75px" },
];

const LeadsetTable = ({
  leadsets,
  onAction,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  startIndex,
  leadsetLoader,
}: {
  leadsets: GetLeadsetDTO[];
  onAction: Function;
  order: "desc" | "asc";
  orderBy: string;
  setOrder: any;
  setOrderBy: any;
  startIndex: number;
  leadsetLoader: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const generateRoute = (id: string): string => {
    return generatePath(ALL_ROUTES.LEADSET_DETAILS, { id: id });
  };
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedLeadset, setSelectedLeadset] = useState<any>();

  function deleteLeadset() {
    const onSuccess = () => {
      setOpenConfirmation(false);
      onAction();
      setLoadingDelete(false);
    };
    const onError = (error: any) => {
      console.log(error);
      setLoadingDelete(false);
    };
    setLoadingDelete(true);
    LEADSET_SERVICE.deleteLeadset(onSuccess, onError, selectedLeadset.id);
  }
  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor={theme.palette.primary.main}>
            {headCells.map((cell) => (
              <StyledTableCell
                align={cell.id === "sr" ? "center" : "left"}
                key={cell.id}
                width={cell.width}
              >
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {leadsetLoader ? (
            <TableProgress colSpans={4} />
          ) : leadsets.length === 0 ? (
            <DataNotFound colSpans={4} />
          ) : (
            <TableBody>
              {leadsets.map((leadset, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell width={headCells[0].width} align="center">
                    {startIndex == 0 && index < 9
                      ? "0" + (index + 1)
                      : startIndex + (index + 1)}
                  </StyledTableCell>

                  <StyledTableCell
                    width={headCells[1].width}
                    sx={{ color: "#4666FF" }}
                    onClick={() =>
                      navigate(generateRoute(leadset.id.toString()))
                    }
                  >
                    <LightTooltip title="Click to view leads">
                      <span>{leadset.name}</span>
                    </LightTooltip>
                  </StyledTableCell>

                  <StyledTableCell
                    width={headCells[2].width}
                    onClick={() =>
                      navigate(generateRoute(leadset.id.toString()))
                    }
                  >
                    {leadset.description}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[3].width}>
                    <LightTooltip title="Delete Leadset">
                      <IconButton
                        onClick={() => {
                          setOpenConfirmation(true);
                          setSelectedLeadset(leadset);
                        }}
                      >
                        <LeadsetDeleteIcon />
                      </IconButton>
                    </LightTooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <SimpleConfirmationPopUp
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        value={selectedLeadset?.name ?? ""}
        action={"Delete"}
        onAction={deleteLeadset}
        loading={loadingDelete}
      />
    </>
  );
};

export default LeadsetTable;
