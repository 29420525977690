import React from "react";
import { TableHead, TableRow } from "@mui/material";

const StyledTableHead = ({
  headColor,
  children,
}: {
  headColor: string;
  children: React.ReactNode;
}) => {
  return (
    <TableHead
      sx={{ backgroundColor: headColor, borderRadius: "16px 16px 0 0" }}>
      <TableRow>{children}</TableRow>
    </TableHead>
  );
};

export default StyledTableHead;
