import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
// import DatePicker from "react-datepicker";
import CustomInput from "../Custom/CustomInput";
import { CustomPopup } from "../Custom/CustomPopup";
import { selectOptionsMapper } from "../Custom/CustomSelect";
import CustomTextField from "../Custom/CustomTextField";
import FormActionButton from "../Actions/FormActionButton";
import CalendarIconBlack from "../../assets/images/CalendarIconBlack";
import { CDRFilters } from "../../services/dto/GetAgentCdrDTO";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { filterFormSlice } from "../../state/filter-form/filterFormSlice";
import { FILTER_KEYS, TFilterForm } from "../../state/types/filterForm";
import CustomAutocomplete from "../Custom/CustomAutocomplete";
import RKTextField from "../EVCustoms/RKTextField/RKTextField";
import DatePicker from "../EVCustoms/DatePicker/DatePicker";
import { toDesiredFormat } from "../../SupportingFiles/HelpingFunction";
import SearchableDropdown from "../Custom/SearchableDropdown";

const MissedCallsCDRFilterPopupContent = ({
  filters,
  setOpen,
  type,
}: {
  filters?: CDRFilters;
  setOpen: Function;
  type: string;
}) => {
  const [campaignsData, setCampaignData] = useState<Array<any>>([]);
  const styledField = { width: { xs: 200, md: 257, maxHeight: "50px" } };
  const styledAutoComplete = { width: { xs: 200, md: 259, maxHeight: "50px" } };
  const [formData, setFormData] = useState<{
    campaign: { key: string; value: string };
    process: { key: string; value: string };
    reason: { key: string; value: string };
    date_from: Date | null;
    date_to: Date | null;
    phone_number: string;
    did_number: { key: string; value: string };
    in_queue_time: { key: string; value: string };
  }>({
    campaign: { key: "", value: "" },
    process: { key: "", value: "" },
    reason: { key: "", value: "" },
    date_from: null,
    date_to: null,
    phone_number: "",
    did_number: { key: "", value: "" },
    in_queue_time: { key: "", value: "" },
  });
  const updateMasterState = (attrName: any, value: any) => {
    switch (attrName) {
      case "date_from":
        // setFilterParams((prev) => ({
        //   ...prev,
        //   [FILTER_KEYS.filterStartAfter]: toDesiredFormat(value),
        // }));
        break;
      case "date_to":
        // setFilterParams((prev) => ({
        //   ...prev,
        //   [FILTER_KEYS.filterStartBefore]: toDesiredFormat(value),
        // }));
        break;
      default:
        // handleInputChange(attrName, value);
        break;
    }
  };

  const handleInputChange = (key: keyof TFilterForm, value: number) => {
    // if (!value) return;
    // setFilterParams((prev) => ({
    //   ...prev,
    //   [key]: value,
    // }));
  };

  const clearFilterForm = () => {
    setFormData({
      campaign: { key: "", value: "" },
      process: { key: "", value: "" },
      reason: { key: "", value: "" },
      date_from: null,
      date_to: null,
      phone_number: "",
      did_number: { key: "", value: "" },
      in_queue_time: { key: "", value: "" },
    });
  };

  const handleSubmit = () => {
    // const isValid = Object.entries(filterParams).some(
    //   ([key, val]) => val !== ""
    // );
    // if (!isValid) return;
    // dispatch(filterFormSlice.actions.setFilterForm(filterParams));
    setOpen(false);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        pt="13px"
        style={{ display: "flex", justifyContent: "center" }}>
        <Grid md={4}>
          <SearchableDropdown
            label="Campaign"
            minWidth={{ xs: 307, md: 257 }}
            selectedValue={formData.campaign.key}
            onChange={(value: any) => {}}
            onClear={() => {
              // setSelectedDispositionThree(null);
              // setSelectedDispositionTwo(null);
            }}
          />
        </Grid>
        <Grid md={4}>
          <SearchableDropdown
            label="Process"
            minWidth={{ xs: 307, md: 257 }}
            selectedValue={formData.process.key}
            onChange={(value: any) => {}}
            onClear={() => {
              // setSelectedDispositionThree(null);
              // setSelectedDispositionTwo(null);
            }}
          />
        </Grid>
        <Grid md={4}>
          <SearchableDropdown
            label="Reason"
            minWidth={{ xs: 307, md: 257 }}
            selectedValue={formData.reason.key}
            onChange={(value: any) => {}}
            onClear={() => {
              // setSelectedDispositionThree(null);
              // setSelectedDispositionTwo(null);
            }}
          />
        </Grid>
        <Grid md={4}>
          <DatePicker
            title="Date From"
            value={formData.date_from}
            attrName={"date_from"}
            value_update={updateMasterState}
            max_date={new Date()}
          />
        </Grid>
        <Grid md={4}>
          <DatePicker
            title="Date To"
            value={formData.date_to}
            attrName={"date_from"}
            value_update={updateMasterState}
            max_date={new Date()}
          />
        </Grid>
        <Grid md={4}>
          <RKTextField
            title="Phone Number"
            value={formData.phone_number}
            attrName={"phone_number"}
            value_update={updateMasterState}
          />
        </Grid>
        <Grid md={4}>
          <SearchableDropdown
            label="Did Number"
            minWidth={{ xs: 307, md: 257 }}
            selectedValue={formData.did_number.key}
            onChange={(value: any) => {}}
            onClear={() => {
              // setSelectedDispositionThree(null);
              // setSelectedDispositionTwo(null);
            }}
          />
        </Grid>
        <Grid md={4}>
          <SearchableDropdown
            label="Reason"
            minWidth={{ xs: 307, md: 257 }}
            selectedValue={formData.in_queue_time.key}
            onChange={(value: any) => {}}
            onClear={() => {
              // setSelectedDispositionThree(null);
              // setSelectedDispositionTwo(null);
            }}
          />
        </Grid>
        <Grid md={6} />
      </Grid>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          mb: "25px",
        }}>
        <FormActionButton onClick={clearFilterForm} title="Reset" />
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={handleSubmit}
        />
      </Box>
    </>
  );
};

const MissedCallsCDRFilterPopupHeader = () => {
  return (
    <Typography sx={{ textAlign: "center", fontSize: "24px" }}>
      Filter Details
    </Typography>
  );
};

export const MissedCallsCDRFilterPopup = ({
  open,
  setOpen,
  filters,
  type,
}: {
  open: boolean;
  setOpen: Function;
  filters?: CDRFilters;
  type: string;
}) => {
  return (
    <CustomPopup
      maxWidth={872}
      minWidth={872}
      Header={MissedCallsCDRFilterPopupHeader}
      open={open}
      setOpen={setOpen}
      Component={() =>
        MissedCallsCDRFilterPopupContent({ filters, setOpen, type })
      }></CustomPopup>
  );
};
