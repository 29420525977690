function UsersLoggedInIcon() {
  return (
    <svg
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6405 15.1666C17.788 15.1666 21.1494 11.8092 21.1494 7.66663C21.1494 3.52405 17.788 0.166626 13.6405 0.166626C9.49303 0.166626 6.13164 3.52405 6.13164 7.66663C6.13164 11.8092 9.49303 15.1666 13.6405 15.1666ZM18.8967 17.0416H17.917C16.6147 17.6393 15.1657 17.9791 13.6405 17.9791C12.1153 17.9791 10.6722 17.6393 9.36397 17.0416H8.3843C4.03151 17.0416 0.5 20.569 0.5 24.9166V27.3541C0.5 28.9069 1.76125 30.1666 3.31582 30.1666H23.9652C25.5197 30.1666 26.781 28.9069 26.781 27.3541V24.9166C26.781 20.569 23.2495 17.0416 18.8967 17.0416Z"
        fill="#6F5FD9"
      />
    </svg>
  );
}

export default UsersLoggedInIcon;
