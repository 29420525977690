import { Typography } from "@mui/material";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../../shared/constants";
import useTimer, { formatTime } from "./useTimer";

const Time = ({
  color = "common.white",
  sx,
  stopTimer = false,
}: {
  color?: string;
  sx?: object;
  stopTimer?: boolean;
}) => {
  const { timer } = useTimer();
  const localTime = localStorage.getItem(
    LOCALSOTRAGE_KEYS_CONSTANTS.TIMER_STATE
  );

  // const startTime = localTime
  //   ? new Date(JSON.parse(localTime).startTime)
  //   : timer.startTime;
  const startTime = timer.startTime;
  // const endTime = localTime
  //   ? new Date(JSON.parse(localTime).stopTime)
  //   : timer.stopTime;
  const endTime = timer.stopTime;
  const stopTime = formatTime(startTime, endTime);

  return (
    <Typography
      variant="h5"
      sx={{
        width: "112px",
        color: color,
        fontFamily: "Josefin Sans",
        fontWeight: { xs: 700, md: 500 },
        fontSize: { xs: "16px", md: "30px" },
        ...sx,
      }}>
      {stopTimer
        ? `${stopTime.hours}:${stopTime.minutes}:${stopTime.seconds}`
        : `${timer.hours}:${timer.minutes}:${timer.seconds}`}
    </Typography>
  );
};

export default Time;
