function DialpadIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#F2994A" fillOpacity="0.2" />
      <path
        d="M20 15C21.3807 15 22.5 13.8807 22.5 12.5C22.5 11.1193 21.3807 10 20 10C18.6193 10 17.5 11.1193 17.5 12.5C17.5 13.8807 18.6193 15 20 15Z"
        fill="#F2994A"
      />
      <path
        d="M12.5 15C13.8807 15 15 13.8807 15 12.5C15 11.1193 13.8807 10 12.5 10C11.1193 10 10 11.1193 10 12.5C10 13.8807 11.1193 15 12.5 15Z"
        fill="#F2994A"
      />
      <path
        d="M27.5 15C28.8807 15 30 13.8807 30 12.5C30 11.1193 28.8807 10 27.5 10C26.1193 10 25 11.1193 25 12.5C25 13.8807 26.1193 15 27.5 15Z"
        fill="#F2994A"
      />
      <path
        d="M20 22.5C21.3807 22.5 22.5 21.3807 22.5 20C22.5 18.6193 21.3807 17.5 20 17.5C18.6193 17.5 17.5 18.6193 17.5 20C17.5 21.3807 18.6193 22.5 20 22.5Z"
        fill="#F2994A"
      />
      <path
        d="M12.5 22.5C13.8807 22.5 15 21.3807 15 20C15 18.6193 13.8807 17.5 12.5 17.5C11.1193 17.5 10 18.6193 10 20C10 21.3807 11.1193 22.5 12.5 22.5Z"
        fill="#F2994A"
      />
      <path
        d="M27.5 22.5C28.8807 22.5 30 21.3807 30 20C30 18.6193 28.8807 17.5 27.5 17.5C26.1193 17.5 25 18.6193 25 20C25 21.3807 26.1193 22.5 27.5 22.5Z"
        fill="#F2994A"
      />
      <path
        d="M20 30C21.3807 30 22.5 28.8807 22.5 27.5C22.5 26.1193 21.3807 25 20 25C18.6193 25 17.5 26.1193 17.5 27.5C17.5 28.8807 18.6193 30 20 30Z"
        fill="#F2994A"
      />
    </svg>
  );
}

export default DialpadIcon;
