import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FORMATTER } from "../../helper/formatter";
import { TLeadState } from "../../state/types/lead";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { validURL } from "../../SupportingFiles/HelpingFunction";
import { Link } from "react-router-dom";
import LightTooltip from "../LightToolTip";

const PersonalInfo = ({
  title,
  subtitle,
  sx,
  loader,
}: {
  title: string;
  subtitle: String;
  sx?: any;
  loader: boolean;
}) => {
  return (
    <Box sx={{ paddingRight: "10px" }}>
      {loader === true ? (
        <>
          <Skeleton
            style={{ marginBottom: "10px" }}
            baseColor="rgba(0, 0, 0, 0.1)"
          />
          <Skeleton
            style={{ marginBottom: "10px" }}
            baseColor="rgba(0, 0, 0, 0.2)"
          />
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: { xs: 400, md: 600 },
              fontFamily: { xs: "Roboto", md: "Josefin Sans" },
              lineHeight: { xs: "22px", md: "20px" },
              pb: { xs: "4px", md: "0" },
              textTransform: "capitalize",
              ...sx,
            }}>
            {title}
          </Typography>
          {validURL(subtitle) ? (
            <Typography
              sx={{
                wordWrap: "break-word",
                color: "gray",
                fontSize: "14px",
                fontWeight: { xs: 500, md: 600 },
                textTransform: "capitalize",
                ...sx,
              }}>
              {validURL(subtitle) ? (
                <LightTooltip title="Click to open">
                  <span
                    onClick={() =>
                      window.open(
                        subtitle.toString(),
                        "_blank",
                        "noopener noreferrer"
                      )
                    }
                    style={{ cursor: "pointer", color: "#0000EE" }}>
                    {subtitle}
                  </span>
                </LightTooltip>
              ) : (
                subtitle
              )}
            </Typography>
          ) : (
            <Typography
              sx={{
                wordWrap: "break-word",
                color: "gray",
                fontSize: "14px",
                fontWeight: { xs: 500, md: 600 },
                textTransform: "capitalize",
                ...sx,
              }}>
              {subtitle}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
const PersonalData = ({
  showCallbackDate,
  lead,
  loader,
}: {
  showCallbackDate: boolean;
  lead: TLeadState;
  loader: boolean;
}) => {
  const emptyChar = "-";
  const gridItemAlingment = (number: number) =>
    number % 2 !== 0 ? { xs: "right", md: "left" } : "left";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        mt: { xs: "10px", md: "5px" },
      }}>
      {loader === true ? (
        <Skeleton
          style={{ marginBottom: "10px" }}
          baseColor="rgba(111, 95, 217, 0.2)"
          width={"150px"}
        />
      ) : (
        <Typography
          sx={{
            color: "primary.main",
            width: showCallbackDate ? "30%" : "100%",
            fontSize: "14px",
            fontWeight: 600,
          }}>
          {lead?.id === 0 ? emptyChar : `#${lead?.id}`}
        </Typography>
      )}
      {showCallbackDate && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            display: { md: "none", xs: "flex" },
          }}>
          {lead?.createdOn == ""
            ? emptyChar
            : FORMATTER.formatDateTime(lead.createdOn)}
        </Typography>
      )}
      <Grid container maxHeight={"400px"}>
        {lead?.configurations &&
          Object.entries(lead?.configurations).map(
            ([key, value], index: number) => (
              <Grid item xs={6} md={3} mt="8px" key={key}>
                <PersonalInfo
                  loader={loader}
                  title={key}
                  subtitle={value == "" ? emptyChar : value}
                  sx={{
                    textAlign: gridItemAlingment(index),
                  }}
                />
              </Grid>
            )
          )}
        {lead && (
          <Grid item xs={6} md={3} mt="8px">
            <PersonalInfo
              loader={loader}
              title="Actual Created"
              subtitle={
                lead.createdOn == ""
                  ? emptyChar
                  : FORMATTER.formatDateTime(lead.createdOn)
              }
              sx={{
                textAlign: gridItemAlingment(
                  lead.configurations !== null &&
                    lead.configurations !== undefined
                    ? Object.keys(lead?.configurations)?.length
                    : 0
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PersonalData;
