import { Box, IconButton } from "@mui/material";
import VerticalTableCell from "../Table/VerticalTableCell";
import VerticalTableRow from "../Table/VerticalTableRow";
import VerticalTableTile from "../Table/VerticalTableTile";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import EditIcon from "../../assets/images/EditIcon";
import { ALL_ROUTES } from "../../shared/routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { LEAD_SERVICE } from "../../services/LeadService";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import { FORMATTER } from "../../helper/formatter";
import { TLeadState } from "../../state/types/lead";
import { leadSlice } from "../../state/lead/leadSlice";
import { useAppDispatch } from "../../state/hooks";

const LeadsetDetailsTableMobile = ({
  leadsetDetails,
  leadsetName,
  onAction,
  startIndex,
}: {
  leadsetDetails: any[];
  leadsetName: string;
  onAction: Function;
  startIndex: number;
}) => {
  const navigate = useNavigate();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedLead, setSelecetedLead] = useState<any>();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useAppDispatch();

  function deleteLead() {
    const onSuccess = () => {
      setOpenConfirmation(false);
      onAction();
      setLoadingDelete(false);
    };
    const onError = (error: any) => {
      console.log(error);
      setLoadingDelete(false);
    };
    setLoadingDelete(true);
    LEAD_SERVICE.deleteLead(onSuccess, onError, selectedLead?.id);
  }

  const handleEdit = (lead: TLeadState) => {
    dispatch(leadSlice.actions.setLead(lead));
    navigate(ALL_ROUTES.EDIT_LEAD);
  };

  return (
    <>
      {leadsetDetails.map((leadset, index) => (
        <VerticalTableTile key={index}>
          <VerticalTableRow>
            <VerticalTableCell
              position="l"
              title={
                startIndex == 0 && index < 9
                  ? "0" + (index + 1)
                  : `${startIndex + (index + 1)}`
              }
            />
          </VerticalTableRow>
          <VerticalTableRow>
            <VerticalTableCell
              position="l"
              title="Name"
              subtitle={leadset.name}
            />
            <VerticalTableCell
              position="r"
              title="Email"
              subtitle={leadset.email}
            />
          </VerticalTableRow>
          <VerticalTableRow>
            <VerticalTableCell
              position="l"
              title="Phone"
              subtitle={leadset.phone}
            />
            <VerticalTableCell
              position="r"
              title="Leadset"
              subtitle={leadsetName}
            />
          </VerticalTableRow>
          <VerticalTableRow>
            <VerticalTableCell
              position="l"
              title="Date/Time"
              subtitle={FORMATTER.formatDateTime(leadset.createdOn)}
            />
            <Box display="flex" gap={2}>
              <IconButton
                onClick={() => {
                  handleEdit(leadset);
                }}
              >
                <EditIcon color="#35CB5D" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelecetedLead(leadset);
                  setOpenConfirmation(true);
                }}
              >
                <LeadsetDeleteIcon />
              </IconButton>
            </Box>
          </VerticalTableRow>
        </VerticalTableTile>
      ))}
      <SimpleConfirmationPopUp
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        value={selectedLead?.name}
        action="Delete"
        onAction={deleteLead}
        loading={loadingDelete}
      />
    </>
  );
};

export default LeadsetDetailsTableMobile;
