import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI, PatchAPI } from "./API";

function resetPassword(onSuccess: any, onError: any, dto: any, id: string) {
  PatchAPI.call(
    URL_CONSTANTS.RESET_USER_PASSWORD_BY_ADMIN(id),
    dto,
    onSuccess,
    {},
    onError
  );
}
function getLeadsTaken(onSuccess: Function, onError: Function, query?: any) {
  GetAPI.call(
    URL_CONSTANTS.ADMIN_DASHBOARD_OVERVIEW,
    (res: any) => onSuccess(res.data),
    { params: query },
    onError
  );
}
function getBreakLogs(onSuccess: Function, onError: Function, query: any) {
  GetAPI.call(
    URL_CONSTANTS.BREAK_LOGS,
    (res: any) => onSuccess(res.data),
    { params: query },
    onError
  );
}

function getBreakLogsByAgent(
  onSuccess: Function,
  onError: Function,
  query: any
) {
  GetAPI.call(
    URL_CONSTANTS.BREAK_LOGS_BY_AGENT,
    (res: any) => onSuccess(res.data),
    { params: query },
    onError
  );
}

export const ADMIN_SERVICES = {
  resetPassword,
  getLeadsTaken,
  getBreakLogs,
  getBreakLogsByAgent,
};
