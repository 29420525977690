function MuteIcon({ color = "#CE3A3A" }: { color?: string }) {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0084 12.9973C19.0084 13.9007 18.8578 14.7704 18.5823 15.5808L17.2786 14.2771C17.3639 13.8558 17.4068 13.4271 17.4067 12.9973V11.3957C17.4067 11.1833 17.4911 10.9796 17.6413 10.8294C17.7915 10.6792 17.9952 10.5948 18.2076 10.5948C18.4199 10.5948 18.6236 10.6792 18.7738 10.8294C18.924 10.9796 19.0084 11.1833 19.0084 11.3957V12.9973ZM11 19.404C12.3102 19.404 13.5275 19.0116 14.5429 18.3357L15.6929 19.4873C14.5496 20.3164 13.2063 20.8266 11.8009 20.9656V24.209H16.6059C16.8183 24.209 17.022 24.2934 17.1722 24.4436C17.3223 24.5938 17.4067 24.7975 17.4067 25.0099C17.4067 25.2223 17.3223 25.426 17.1722 25.5761C17.022 25.7263 16.8183 25.8107 16.6059 25.8107H5.3942C5.18181 25.8107 4.97811 25.7263 4.82793 25.5761C4.67774 25.426 4.59337 25.2223 4.59337 25.0099C4.59337 24.7975 4.67774 24.5938 4.82793 24.4436C4.97811 24.2934 5.18181 24.209 5.3942 24.209H10.1992V20.9656C8.22385 20.7671 6.39264 19.8419 5.06086 18.3696C3.72908 16.8972 2.99167 14.9827 2.9917 12.9973V11.3957C2.9917 11.1833 3.07607 10.9796 3.22626 10.8294C3.37644 10.6792 3.58014 10.5948 3.79253 10.5948C4.00493 10.5948 4.20862 10.6792 4.35881 10.8294C4.50899 10.9796 4.59337 11.1833 4.59337 11.3957V12.9973C4.59337 14.6965 5.26835 16.3261 6.46984 17.5276C7.67132 18.729 9.30089 19.404 11 19.404ZM15.805 4.989V12.8035L6.4481 3.4466C6.81479 2.36906 7.55197 1.45662 8.5284 0.871702C9.50483 0.286788 10.6572 0.0673624 11.7802 0.252489C12.9033 0.437616 13.9242 1.01528 14.6612 1.88265C15.3983 2.75001 15.8036 3.85078 15.805 4.989V4.989Z"
        fill={color}
      />
      <path
        d="M13.3803 17.173L6.19524 9.98628V12.9974C6.19499 13.8388 6.41568 14.6655 6.83523 15.3948C7.25477 16.1241 7.85848 16.7305 8.58594 17.1533C9.3134 17.576 10.1391 17.8004 10.9805 17.8038C11.8219 17.8073 12.6494 17.5897 13.3803 17.173ZM0.823242 2.35273L20.0433 21.5728L21.1772 20.4388L1.95722 1.21875L0.823242 2.35273Z"
        fill={color}
      />
    </svg>
  );
}

export default MuteIcon;
