import { createSlice } from "@reduxjs/toolkit";
import { leadSlice } from "../lead/leadSlice";
import { CallStatus, TCallState } from "../types/call";
import { CALL_REDUCERS, TCallReducer } from "./callReducers";

export const callSlice = createSlice<TCallState, TCallReducer>({
  name: "call",
  initialState: {
    callId: 0,
    lead: leadSlice.getInitialState(),
    processId: 0,
    callCode: "",
    callStatus: CallStatus.IDLE,
    callOutCome: "",
    call_type: "",
  },
  reducers: CALL_REDUCERS,
});
