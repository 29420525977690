import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, TextField } from "@mui/material";

export type SelectOption = {
  label: string;
  value: string | number;
};

export const selectOptionsMapper = (
  label: string,
  value: string,
  array: any[]
) => {
  const options: SelectOption[] = [];
  array.map((item) => {
    options.push({ label: item[`${label}`], value: item[`${value}`] });
  });
  return options;
};

const SearchableDropdown = ({
  label,
  name,
  values = [],
  minWidth = 0,
  fullWidth = false,
  backgroundColor = "transparent",
  sx,
  error = false,
  onChange,
  selectedValue = "",
  disabled = false,
  hidden = false,
  onClear,
  customClearButton = false,
  size = "medium",
}: {
  label: string;
  name?: string;
  values?: any[];
  minWidth?: any;
  fullWidth?: boolean;
  backgroundColor?: string;
  sx?: object;
  error?: boolean;
  disabled?: boolean;
  onChange: (event: any) => void;
  onClear?: () => void;
  customClearButton?: boolean;
  selectedValue?: string;
  hidden?: boolean;
  size?: "small" | "medium" | undefined;
}) => {
  const renderClearButton = () => {
    if (selectedValue) {
      return (
        <ClearIcon
          sx={{ color: "action", cursor: "pointer" }}
          onClick={onClear}
        />
      );
    }
    return null;
  };

  return (
    <Autocomplete
      freeSolo={true}
      size={size}
      hidden={hidden}
      disabled={disabled}
      options={values}
      onChange={(event, value) => {
        onChange(value);
      }}
      onInputChange={onClear}
      value={selectedValue}
      sx={{ minWidth: minWidth, ...sx }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          InputProps={
            customClearButton === false
              ? { ...params.InputProps }
              : {
                  ...params.InputProps,
                  endAdornment: renderClearButton(),
                }
          }
          sx={{
            svg: { color: "#000000" },
            "& .MuiInputBase-input": {
              height: "23px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "6px",
              },
            },
          }}
        />
      )}
    />
  );
};

export default SearchableDropdown;
