import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import AddIcon from "../assets/images/AddIcon";
import UploadFileIcon from "../assets/images/UploadFileIcon";
import BackdropLoader from "../components/BackdropLoader";
import ClockHeader from "../components/ClockHeader";
import BackButton from "../components/Custom/BackButton";
import RoundedButtons from "../components/Custom/RoundedButtons";
import LeadsetDetailsTable from "../components/Leadset/LeadsetDetailsTable";
import LeadsetDetailsTableMobile from "../components/Leadset/LeadsetDetailsTableMobile";
import { SearchBar } from "../components/SearchBar";
import TablePagination from "../components/Table/TablePagination";
import { DOWNLOADER } from "../helper/downloader";
import { GetLeadDTO } from "../services/dto/GetLeadDTO";
import { LEAD_SERVICE } from "../services/LeadService";
import { LEADSET_SERVICE } from "../services/LeadsetService";
import { ALL_ROUTES } from "../shared/routes";
import { useAppDispatch } from "../state/hooks";
import { leadsetSlice } from "../state/leadset/leadsetSlice";
import { TLeadset } from "../state/types/user";

const LeadsetDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [leadsetDetails, setLeadsetDetails] = useState<TLeadset>();
  const [leads, setLeads] = useState<GetLeadDTO[]>([]);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [leadLoader, setLeadloader] = useState(false);
  const { id } = useParams();
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const getLeadsetDetails = useMemo(() => {
    const onSuccess = (data: any) => {
      setLeadsetDetails(data.data);
      dispatch(leadsetSlice.actions.setLeadset(data.data));
    };
    const onError = (err: any) => console.log(err);
    LEADSET_SERVICE.getLeadsetDetails(onSuccess, onError, id ?? "");
  }, [id]);

  function getLeads() {
    setLeadloader(true);
    const onSuccess = ({ info, leads }: { info: any; leads: GetLeadDTO[] }) => {
      setLeadloader(false);
      setLeads(leads);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = (error: any) => {
      setLeadloader(false);
      console.log(error);
    };
    LEAD_SERVICE.getLeads(onSuccess, onError, id ?? "", {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search: "",
      sortDirection: order,
      sort: orderBy,
    });
  }

  useEffect(() => {
    getLeads();
  }, [pagination.page, order, orderBy]);

  const loadLeads = useCallback((text: string) => {
    setLeadloader(true);
    const onSuccess = ({ info, leads }: { info: any; leads: GetLeadDTO[] }) => {
      setLeadloader(false);
      setLeads(leads);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.floor(info.count / info.limit) + 1;
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = () => {
      setLeadloader(false);
    };
    const limit = 10;
    LEAD_SERVICE.getLeads(onSuccess, onError, id ?? "", {
      startIndex: 0,
      limit,
      search: text,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadLeads, 500), [
    loadLeads,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  const exportLeads = () => {
    setLoading(true);
    const onSuccess = (data: any) => {
      console.log(data.data);
      DOWNLOADER.download(data.data, `leads-${new Date().valueOf()}.xlsx`);
      setLoading(false);
    };
    const onError = () => setLoading(false);
    LEAD_SERVICE.exportLeads(onSuccess, onError, {
      search: search,
      leadsetId: id,
    });
  };

  return (
    <>
      <ClockHeader />
      <Box
        mb={{ xs: true ? "16px" : "22px", md: "30px" }}
        display={"flex"}
        alignItems="center"
        gap={"10px"}
        style={{ flexDirection: "row", display: "flex" }}
      >
        {true && <BackButton onClick={() => navigate(-1)} />}
        <Typography
          variant="h5"
          sx={{ lineHeight: { xs: "20px", md: "32px" } }}
        >
          Manage Leads
        </Typography>
        <Typography
          variant="h4"
          sx={{ lineHeight: { xs: "20px", md: "32px" } }}
        >
          {"(" + leadsetDetails?.name + ")"}
        </Typography>
      </Box>
      <Box
        sx={{
          p: "0 0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <SearchBar
          sx={{
            backgroundColor: "white",
            border: "1px solid #96A1B3",
            minWidth: mobileView ? "90%" : "365px",
          }}
          text={search}
          onChange={(text) => handleSearch(text)}
        />
        <Box
          display={"flex"}
          gap={1.2}
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <RoundedButtons
            title="Add"
            color="#F2994A"
            Icon={AddIcon}
            onClick={() => {
              navigate(generatePath(ALL_ROUTES.CREATE_LEAD, { id: id }));
            }}
          />
          <RoundedButtons
            title="Upload file"
            color={theme.palette.primary.main}
            Icon={UploadFileIcon}
            onClick={() => {
              navigate(generatePath(ALL_ROUTES.UPLOAD_LEAD, { id: id }));
            }}
          />
          <RoundedButtons
            title="Export"
            color="#5ECE62"
            Icon={CloudDownloadIcon}
            onClick={exportLeads}
          />
        </Box>
      </Box>
      <Box display={{ xs: "none", md: "flex" }}>
        <LeadsetDetailsTable
          leadsetDetails={leads}
          leadsetName={leadsetDetails?.name ?? ""}
          onAction={getLeads}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          startIndex={(pagination.page - 1) * limit}
          leadLoader={leadLoader}
        />
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <LeadsetDetailsTableMobile
          leadsetDetails={leads}
          leadsetName={leadsetDetails?.name ?? ""}
          onAction={getLeads}
          startIndex={(pagination.page - 1) * limit}
        />
      </Box>
      <TablePagination page={pagination} setPage={setPagination} />
      {loading && <BackdropLoader loading={loading} />}
    </>
  );
};

export default LeadsetDetails;
