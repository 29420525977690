import { Box, Theme, Typography, useMediaQuery } from "@mui/material";

function BreakStatusHeader({ time }: any) {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const formatTIme = (hours: number, minutes: number, seconds: number) => {
    const formatNumber = (value: number) => {
      return (`00` + value).slice(-2);
    };
    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(
      seconds
    )}`;
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="h6"
          sx={{ fontWeight: { xs: 700, md: 600 }, flexGrow: 1 }}>
          Break Time
        </Typography>
        <Typography
          sx={{
            fontWeight: { xs: 700, md: 600 },
            fontSize: { xs: "16px", md: "22px" },
            color: "#E2606C",
          }}>
          {formatTIme(time.hours, time.minutes, time.seconds)}
        </Typography>
        <Typography sx={{ fontSize: { xs: "12px", md: "16px" } }}>
          Total Break Time
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <img
          src="/images/break-status.png"
          width={mobileView ? "93px" : "141px"}
          height={mobileView ? "73px" : "110px"}
        />
      </Box>
    </>
  );
}

export default BreakStatusHeader;
