import { createSlice } from "@reduxjs/toolkit";
import { TDispositions } from "../types/dispositions";
import {
  DISPOSITIONS_REDUCERS,
  TDispositionsReducer,
} from "./dispositionsReducers";

export const dispositionsSlice = createSlice<
  TDispositions,
  TDispositionsReducer
>({
  name: "dispositions",
  initialState: { disposition: [], message: "" },
  reducers: DISPOSITIONS_REDUCERS,
});
