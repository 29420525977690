import { Box } from "@mui/system";
import { Button } from "@mui/material";
export const Numpad = ({
  setInputNumber,
  disabled = false,
}: {
  setInputNumber: (value: string) => void;
  disabled?: boolean;
}) => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"];

  return (
    <Box
      sx={{
        display: "flex",
        padding: "0 20px ",
        flexWrap: "wrap",
        justifyContent: "center",
        maxWidth: 320,
        width: "100%",
      }}
    >
      {numbers.map((num) => (
        <Button
          key={num}
          onClick={(e: any) => {
            if (disabled) return;
            setInputNumber(e.target.value);
          }}
          value={num}
          sx={{
            height: 60,
            width: 60,
            textAlign: "center",
            lineHeight: 3.2,
            borderRadius: "10px",
            margin: 1.2,
            fontSize: { xs: "16px", md: "20px" },
            fontWeight: { xs: 700, md: 400 },
            boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.2)",
            color: "black",
          }}
        >
          {num}
        </Button>
      ))}
    </Box>
  );
};
