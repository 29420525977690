import { CustomPopup } from "../../Custom/CustomPopup";
import { Box, Typography } from "@mui/material";
import CustomTextField from "../../Custom/CustomTextField";
import FormActionButton from "../../Actions/FormActionButton";
import { ChangeEvent, useState } from "react";
import { PROCESS_SERVICE } from "../../../services/ProcessService";
import { GetProcessDTO } from "../../../services/dto/GetProcessDTO";

export const ChangeRatioPopup = ({
  open,
  setOpen,
  selectedProcess,
  onAction,
}: {
  open: boolean;
  setOpen: any;
  selectedProcess: GetProcessDTO;
  onAction?: Function;
}) => {
  const ChangeRatioHeader = () => {
    return (
      <Typography sx={{ textAlign: "center", fontSize: "24px" }}>
        Change Ratio
      </Typography>
    );
  };
  return (
    <CustomPopup
      Header={ChangeRatioHeader}
      open={open}
      setOpen={setOpen}
      Component={() =>
        ChangeRatioContent({
          setOpen,
          selectedProcess,
          onAction,
        })
      }
      minWidth="650px"
    ></CustomPopup>
  );
};

const ChangeRatioContent = ({
  setOpen,
  selectedProcess,
  onAction,
}: {
  setOpen: any;
  selectedProcess: GetProcessDTO;
  onAction?: Function;
}) => {
  const [ratio, setRatio] = useState<number>(
    selectedProcess.processDetails.ratio
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRatio(parseInt(e.target.value));
  };

  const handleSubmit = () => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      setOpen(false);
      onAction && onAction();
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
      setOpen(false);
    };
    PROCESS_SERVICE.updateRatio(
      onSuccess,
      onError,
      selectedProcess?.id.toString() ?? "",
      {
        ratio: ratio,
      }
    );
  };

  return (
    <Box
      mt="14px"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CustomTextField
        label="Change Ratio"
        onChange={handleChange}
        value={ratio}
        id="ratio"
      />
      <Box
        mt="10px"
        mb="14px"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={handleSubmit}
          loading={loading}
        />
        <FormActionButton
          title="Cancel"
          onClick={() => {
            setOpen(false);
          }}
          disabled={loading}
        />
      </Box>
    </Box>
  );
};
