import { useEffect, useState } from "react";
import { SOCKET_EVENTS } from "../../shared/constants";

export const AliveBlinker = ({
  socket,
  label,
}: {
  socket: any;
  label: string;
}) => {
  const [alive, setAlive] = useState(false);
  useEffect(() => {
    const listener = () => {
      setAlive(true);
      setTimeout(() => {
        setAlive(false);
      }, 400);
    };
    socket?.on(SOCKET_EVENTS.ALIVE, listener);
    return () => socket?.off(SOCKET_EVENTS.ALIVE, listener);
  }, [socket?.id]);

  return (
    <div style={{ zIndex: 10000, color: "black" }}>
      {label}
      <div
        style={{
          borderRadius: "50px",
          height: "20px",
          width: "20px",
          backgroundColor: alive ? "green" : "gray",
        }}></div>
      {socket?.id}
    </div>
  );
};
