import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user/userSlice";
import { campaignSlice } from "./campaign/campaignSlice";
import { leadSlice } from "./lead/leadSlice";
import { leadsetSlice } from "./leadset/leadsetSlice";
import { callSlice } from "./call/callSlice";
import { disposeCallSlice } from "./dispose/disposeCallSlice";
import { defaultProcessSlice } from "./process/defaultProcessSlice";
import { userDetailsFormSlice } from "./user-form/userFormSlice";
import { callbackSlice } from "./callback/callbackSlice";
import { assignCallbackSlice } from "./assign-callback/assignCallbackSlice";
import { commentSlice } from "./comment/commentSlice";
import { filterFormSlice } from "./filter-form/filterFormSlice";
import { campaignsSlice } from "./campaigns/campaignsSlice";
import { dispositionsSlice } from "./dispositions/dispositionsSlice";
import { callbackLogsSlice } from "./callbackLogs/callbackLogsSlice";
import { pendingDispositionLeadSlice } from "./pendingDispositionLead/pendingDispositionLeadSlice";
import { pendingDispositionCallSlice } from "./pendingDispositionCall/pendingDispositionCallSlice";
import { clientConfigSlice } from "./clientConfig/clientConfigSlice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    campaign: campaignSlice.reducer,
    lead: leadSlice.reducer,
    leadset: leadsetSlice.reducer,
    call: callSlice.reducer,
    callback: callbackSlice.reducer,
    callbackLogs: callbackLogsSlice.reducer,
    disposeCall: disposeCallSlice.reducer,
    defaultProcess: defaultProcessSlice.reducer,
    userDetailsForm: userDetailsFormSlice.reducer,
    assignCallbacks: assignCallbackSlice.reducer,
    comment: commentSlice.reducer,
    filterForm: filterFormSlice.reducer,
    campaigns: campaignsSlice.reducer,
    dispositions: dispositionsSlice.reducer,
    pending_disposition_lead: pendingDispositionLeadSlice.reducer,
    pending_disposition_call: pendingDispositionCallSlice.reducer,
    clientConfig: clientConfigSlice.reducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
