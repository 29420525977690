import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { SelectOption } from "./CustomSelect";

type CustomAutoCompleteProps<T> = {
  id?: string;
  data?: T[];
  label: string;
  error?: boolean;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  selectedValue?: string | number;
  sx?: any;
  getOptionLabel?: (option: any) => string;
  limitOptions?: number;
  disbaled?: boolean;
};

const CustomAutocomplete = <T extends SelectOption | string>({
  id,
  data = [],
  label,
  error = false,
  onChange,
  selectedValue,
  sx,
  getOptionLabel = (option: any) => option,
  limitOptions = 5,
  disbaled = false,
}: CustomAutoCompleteProps<T>) => {
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, limitOptions);
  };

  const getVal = (): SelectOption | string | undefined => {
    if (selectedValue === null) return undefined;
    const selected: SelectOption | string | undefined = data.find((option) => {
      if (typeof option === "object")
        return option.value == selectedValue?.toString();
      else return option == selectedValue;
    });
    return selected;
  };

  return (
    <Autocomplete
      sx={{
        ".MuiOutlinedInput-root": {
          height: "52px",
          borderRadius: "5px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(0,0,0,0.1)",
        },
        "& .MuiInputLabel-root": {
          color: "common.black",
        },
        ...sx,
      }}
      id={id}
      fullWidth
      filterOptions={filterOptions}
      options={data}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      clearIcon={null}
      disableClearable
      isOptionEqualToValue={(option: any, value: any) => {
        if (!value) return true;
        return option?.value == value?.value;
      }}
      renderInput={(params: any) => (
        <TextField error={error} {...params} label={label} variant="outlined" />
      )}
      value={getVal()}
      disabled={disbaled}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: "common.black" }} />}
    />
  );
};

export default CustomAutocomplete;
