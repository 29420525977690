import { createSlice } from "@reduxjs/toolkit";
import { TAssignCallback } from "../types/assignCallback";
import {
  ASSIGN_CALLBACK_REDUCERS,
  TAssignCallbackReducer,
} from "./assignCallbackReducers";

export const assignCallbackSlice = createSlice<
  TAssignCallback,
  TAssignCallbackReducer
>({
  name: "assign-callbacks",
  initialState: {
    id: [],
    agentId: 0,
    agentFilterId: 0,
  },
  reducers: ASSIGN_CALLBACK_REDUCERS,
});
