function CallbackIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#5ECE62" fillOpacity="0.2" />
      <path
        d="M30.7306 9.26849C30.816 9.35367 30.8838 9.45486 30.93 9.56627C30.9762 9.67768 31 9.79711 31 9.91773C31 10.0383 30.9762 10.1578 30.93 10.2692C30.8838 10.3806 30.816 10.4818 30.7306 10.567L24.9597 16.3368H28.2532C28.4964 16.3368 28.7296 16.4334 28.9015 16.6053C29.0735 16.7773 29.1701 17.0106 29.1701 17.2538C29.1701 17.497 29.0735 17.7302 28.9015 17.9022C28.7296 18.0742 28.4964 18.1708 28.2532 18.1708H22.7463C22.5031 18.1708 22.2699 18.0742 22.098 17.9022C21.926 17.7302 21.8294 17.497 21.8294 17.2538V11.7517C21.8294 11.5085 21.926 11.2753 22.098 11.1033C22.2699 10.9313 22.5031 10.8347 22.7463 10.8347C22.9895 10.8347 23.2227 10.9313 23.3946 11.1033C23.5666 11.2753 23.6632 11.5085 23.6632 11.7517V15.0401L29.4341 9.26849C29.6061 9.09657 29.8393 9 30.0824 9C30.3255 9 30.5587 9.09657 30.7306 9.26849V9.26849ZM13.3463 9.2208C14.099 8.93777 14.9306 8.9477 15.6763 9.24862C16.4221 9.54955 17.0277 10.1195 17.3733 10.8457L18.413 13.0282C18.6483 13.5229 18.7305 14.0766 18.6492 14.6184C18.5678 15.1601 18.3266 15.6652 17.9564 16.069L15.5432 18.7045C15.747 19.5 16.0586 20.2639 16.4692 20.975C16.8911 21.687 17.415 22.3333 18.0243 22.8934L21.4663 21.9965C21.9403 21.8729 22.4387 21.878 22.9101 22.0115C23.3814 22.145 23.8085 22.402 24.1473 22.7558L25.5685 24.2395C25.8614 24.5452 26.0909 24.9059 26.2439 25.3008C26.3968 25.6956 26.4701 26.1168 26.4595 26.5401C26.4489 26.9634 26.3547 27.3804 26.1823 27.7671C26.0098 28.1539 25.7626 28.5026 25.4548 28.7934L24.912 29.3069C22.8472 31.2564 19.6307 31.6728 17.3293 29.7599C15.6055 28.3257 13.537 26.3119 11.9783 23.8672C10.2545 21.1584 9.44764 17.9434 9.06621 15.5225C8.6316 12.7604 10.3865 10.3322 12.864 9.40237L13.3463 9.21897V9.2208ZM13.3463 9.2208L13.669 10.0791L13.3463 9.2208Z"
        fill="#5ECE62"
      />
    </svg>
  );
}

export default CallbackIcon;
