import { createSlice } from "@reduxjs/toolkit";
import { TLeadsetState } from "../types/leadset";
import { LEADSET_REDUCERS, TLeadsetReducer } from "./leadsetReducers";

export const leadsetSlice = createSlice<TLeadsetState, TLeadsetReducer>({
  name: "leadset",
  initialState: {
    id: 0,
    name: "",
    description: "",
    leads: [],
    createdOn: "",
    createdBy: 0,
    updatedOn: "",
    updatedBy: 0,
  },
  reducers: LEADSET_REDUCERS,
});
