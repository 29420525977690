import { Outlet } from "react-router-dom";
import { useAppSelector } from "../state/hooks";

const RestrictedRoute = ({ accessLevels }: { accessLevels: string[] }) => {
  const accessLevel = useAppSelector(
    (state) => state.user.userDetails.accessLevel
  );
  return accessLevels.includes(accessLevel) ? <Outlet /> : <></>;
};

export default RestrictedRoute;
