import { URL_CONSTANTS } from "../shared/urls";
import { PatchAPI } from "./API";
import { changePasswordDTO } from "./dto/ChangePasswordDTO";

function setNewPassowrd(
  onSuccess: Function,
  onError: Function,
  dto: changePasswordDTO
) {
  PatchAPI.call(URL_CONSTANTS.CHANGE_PASSWORD, dto, onSuccess, {}, onError);
}

export const CHANGE_PASSWORD_SERVICE = {
  setNewPassowrd,
};
