import { CheckBox } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ADMIN_SERVICES } from "../../services/AdminServices";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import {
  generatePassword,
  validateEmail,
} from "../../SupportingFiles/HelpingFunction";
import FormActionButton from "../Actions/FormActionButton";
import RKTextField from "../EVCustoms/RKTextField/RKTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LightTooltip from "../LightToolTip";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";

interface Props {
  open_status: boolean;
  value_update: Function;
  attrName: any;
  user: GetUserResponseDTO | null;
}

const ResetUserPass: React.FC<Props> = ({
  open_status,
  value_update,
  attrName,
  user,
}) => {
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [autoGenPass, setAutoGenPass] = useState<boolean>(true);
  const [newPassword, setNewPassword] = useState<string>("");
  const [warnNewPass, setWarnNewPass] = useState<boolean>(false);

  const [sendEmailStatus, setSendEmailStatus] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [warnEmail, setWarnEmail] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);

    setWarnEmail(false);
    setWarnNewPass(false);
  };

  useEffect(() => {
    if (autoGenPass === true) {
      setNewPassword(generatePassword());
    } else {
      setNewPassword("");
    }
  }, [autoGenPass]);

  useEffect(() => {
    setConfirmation(false);

    setAutoGenPass(true);
    setNewPassword(generatePassword());
    setWarnNewPass(false);

    setSendEmailStatus(false);
    setEmail("");
    setWarnEmail(false);
  }, [open_status]);

  function validateForm() {
    let warn_email = false;
    let warn_password = false;
    if (autoGenPass === false) {
      warn_password = newPassword.toString().length >= 8 ? false : true;
    }
    if (sendEmailStatus === true) {
      warn_email = !validateEmail(email);
    }

    setWarnEmail(warn_email);
    setWarnNewPass(warn_password);

    if (warn_email === false && warn_password === false) {
      resetPassword();
    }
  }

  function resetPassword() {
    const onSuccess = () => {
      setConfirmation(true);
    };
    const onError = (err: any) => console.log(err);
    ADMIN_SERVICES.resetPassword(
      onSuccess,
      onError,
      {
        password: newPassword,
        email: email,
      },
      user?.id === undefined ? "" : user?.id.toString()
    );
  }

  return (
    <Modal
      open={open_status}
      onClose={() => {
        value_update(attrName, false);
      }}
      sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
    >
      {confirmation === false ? (
        <div
          style={{
            width: 500,
            backgroundColor: "#ffffff",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <Typography
            fontSize={20}
            fontWeight={600}
            sx={{ width: "100%", textAlign: "center" }}
          >
            {"Reset Password (User: " + user?.firstName + ")"}
          </Typography>
          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.1)" }} />
          <Typography fontSize={14} fontWeight={400}>
            {warnEmail === true && warnNewPass === false
              ? "Invalid email address format."
              : warnNewPass === true && warnEmail === false
              ? newPassword.toString().length === 0
                ? "Password can't be blank."
                : "Min. 8 character required."
              : warnEmail === true && warnNewPass === true
              ? "Please check for required fields"
              : ""}
          </Typography>
          <FormGroup sx={{ marginTop: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={true}
                  value={autoGenPass}
                  onClick={() => {
                    updateMasterState(setAutoGenPass, !autoGenPass);
                  }}
                />
              }
              label="Automatically create a password."
            />
          </FormGroup>
          {/* Passwords must be between 8 and 256 characters and use a combination
        of at least three of the following: uppercase letters, lowercase
        letters, numbers, and symbo */}
          {autoGenPass === true ? null : (
            <>
              <Typography fontSize={12} fontWeight={400}>
                Please use a strong password.
              </Typography>
              <RKTextField
                title="New Password*"
                value={newPassword}
                attrName={setNewPassword}
                value_update={updateMasterState}
                warn_status={false}
                error_messg="Please enter valid value"
                sx={{ marginTop: "10px" }}
              />
            </>
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={false}
                  value={sendEmailStatus}
                  onClick={() => {
                    updateMasterState(setSendEmailStatus, !sendEmailStatus);
                  }}
                />
              }
              label="Email the sign-in info."
            />
          </FormGroup>
          {sendEmailStatus === true ? (
            <RKTextField
              title="E-mail*"
              value={email}
              attrName={setEmail}
              value_update={updateMasterState}
              warn_status={false}
              error_messg="Invalid email address format."
              sx={{ marginTop: "10px" }}
            />
          ) : null}
          <Box
            mt="20px"
            mb="5px"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <FormActionButton
              sx={{ cursor: "pointer" }}
              title="Reset password"
              variant="contained"
              onClick={() => {
                validateForm();
              }}
              loading={loading}
            />
            <FormActionButton
              title="Cancel"
              onClick={() => value_update(attrName, false)}
              disabled={loading}
            />
          </Box>
        </div>
      ) : (
        <div
          style={{
            width: 500,
            backgroundColor: "#ffffff",
            borderRadius: 10,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={20}
            fontWeight={600}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              fontSize="large"
              sx={{ color: "#32CD32", marginRight: "25px" }}
            />
            <Typography
              fontSize={20}
              fontWeight={600}
              sx={{ marginTop: "5px" }}
            >
              Password has been reset.
            </Typography>
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            sx={{ display: "flex", alignItems: "center", marginTop: "30px" }}
          >
            You've successfully reset the password for this user.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              width: "70%",
            }}
          >
            <Typography fontSize={16} fontWeight={600} sx={{ flex: 1 }}>
              User
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={600}
              sx={{ flex: 1, justifyContent: "flex-end", display: "flex" }}
            >
              Password
            </Typography>
          </Box>
          <Divider
            sx={{
              borderColor: "rgba(0, 0, 0, 0.1)",
              width: "80%",
              marginTop: "15px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
              width: "70%",
            }}
          >
            <Typography
              fontSize={15}
              fontWeight={400}
              sx={{
                flex: 1,
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "center",
              }}
            >
              {user?.username}
              <LightTooltip title="Copy">
                <IconButton
                  sx={{ padding: 0, marginLeft: "10px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      user?.username === undefined ? "" : user?.username
                    );
                  }}
                >
                  <CopyAllOutlinedIcon
                    style={{ fontSize: 16, color: "#1E90ff" }}
                  />
                </IconButton>
              </LightTooltip>
            </Typography>
            <Typography
              fontSize={15}
              fontWeight={400}
              sx={{
                flex: 1,
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "center",
              }}
            >
              {newPassword}
              <LightTooltip title={"Copy"}>
                <IconButton
                  sx={{ padding: 0, marginLeft: "10px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(newPassword);
                  }}
                >
                  <CopyAllOutlinedIcon
                    style={{ fontSize: 16, color: "#1E90ff" }}
                  />
                </IconButton>
              </LightTooltip>
            </Typography>
          </Box>
          <Box
            mt="20px"
            mb="5px"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <FormActionButton
              title="Close"
              variant="contained"
              onClick={() => {
                value_update(attrName, false);
              }}
              loading={loading}
            />
          </Box>
        </div>
      )}
    </Modal>
  );
};

export default ResetUserPass;
