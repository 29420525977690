function download(data: any, filename: string) {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: "application/octet-stream",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
}

export const DOWNLOADER = {
  download,
};
