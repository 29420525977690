import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI, PatchAPI, PostAPI, PutAPI } from "./API";
import { AssignCallbacksDTO } from "./dto/AssignCallbacksDTO";

function getCallbacks(onSuccess: Function, onError: Function, query?: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_CALLBACKS,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: query },
    onError
  );
}

function getAgentCallbacks(
  onSuccess: Function,
  onError: Function,
  userId: number,
  query?: any
) {
  GetAPI.call(
    URL_CONSTANTS.GET_CALLBACKS,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: { ...query, userId } },
    onError
  );
}

function getMissedCallbacks(
  onSuccess: Function,
  onError: Function,
  query?: any
) {
  GetAPI.call(
    URL_CONSTANTS.GET_MISSED_CALLBACKS,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: query },
    onError
  );
}

function getAgentMissedCallbacks(
  onSuccess: Function,
  onError: Function,
  userId: number,
  query?: any
) {
  GetAPI.call(
    URL_CONSTANTS.GET_MISSED_CALLBACKS,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: { ...query, userId } },
    onError
  );
}

function getUpcomingCallbacks(
  onSuccess: Function,
  onError: Function,
  query?: any
) {
  GetAPI.call(
    URL_CONSTANTS.GET_UPCOMING_CALLBACKS,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: query },
    onError
  );
}

function callNextCallback(onSuccess: Function, onError: Function) {
  return PostAPI.call(
    URL_CONSTANTS.CALL_NEXT_CALLBACK,
    {},
    (data: any) => {
      onSuccess(data.data);
    },
    {},
    onError
  );
}

function callCallback(
  onSuccess: Function,
  onError: Function,
  id: string | number
) {
  PostAPI.call(URL_CONSTANTS.CALL_CALLBACK(id), {}, onSuccess, {}, onError);
}

function assignCallbacks(
  onSuccess: Function,
  onError: Function,
  dto: AssignCallbacksDTO
) {
  PutAPI.call(URL_CONSTANTS.ASSIGN_CALLBACKS, dto, onSuccess, {}, onError);
}

function updateCallback(
  onSuccess: Function,
  onError: Function,
  dto: any,
  id: string | number
) {
  PatchAPI.call(URL_CONSTANTS.EDIT_CALLBACK(id), dto, onSuccess, {}, onError);
}

export const CALLBACK_SERVICE = {
  getCallbacks,
  getAgentCallbacks,
  getMissedCallbacks,
  getAgentMissedCallbacks,
  getUpcomingCallbacks,
  callNextCallback,
  callCallback,
  assignCallbacks,
  updateCallback,
};
