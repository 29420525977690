import { GetDispositionDTO } from "./GetDispositionDTO";
import { GetProcessDTO } from "./GetProcessDTO";

export type PostProcessDTO = {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  callingMode: string;
  campaignId: number;
  client: string;
  ratio: number;
  dispositionIds: number[];
};

export function postProcessDTOMapper(process: GetProcessDTO) {
  const newProcessForm: PostProcessDTO = {
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    callingMode: "",
    campaignId: 0,
    client: "",
    ratio: 0,
    dispositionIds: [],
  };
  newProcessForm.name = process.name;
  newProcessForm.description = process.description;
  newProcessForm.startDate = process.processDetails.startDateTime;
  newProcessForm.endDate = process.processDetails.endDateTime;
  newProcessForm.campaignId = process.campaignId;
  newProcessForm.callingMode = process.processDetails.callingMode;
  newProcessForm.client = process.client;
  newProcessForm.ratio = process.processDetails.ratio;
  newProcessForm.dispositionIds = process.processDispositions.map(
    (process: GetDispositionDTO) => process.id
  );
  return newProcessForm;
}
