import { Box } from "@mui/material";
import { useState } from "react";
import { CALLBACK_SERVICE } from "../../services/CallbackService";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { assignCallbackSlice } from "../../state/assign-callback/assignCallbackSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import FormActionButton from "../Actions/FormActionButton";
import CustomSelect from "../Custom/CustomSelect";
import Grid from "@mui/material/Unstable_Grid2";

const AssignToAgent = ({
  agents,
  disabled = false,
  onAction,
}: {
  agents: GetUserResponseDTO[];
  disabled?: boolean;
  onAction?: Function;
}) => {
  const [laoding, setLoading] = useState(false);
  const [filteredAgents, setFilteredAgents] = useState<GetUserResponseDTO[]>(
    []
  );
  const assignCallbacksForm = useAppSelector((state) => state.assignCallbacks);
  const dispatch = useAppDispatch();
  const isAssignDisabled =
    assignCallbacksForm.agentId <= 0 || assignCallbacksForm.id.length <= 0;

  const handleAgentFilter = (event: any) => {
    const agentId = event.target.value;
    const selectedAgent = agents.find((agent) => agent.id === agentId);
    if (selectedAgent === undefined) return;
    dispatch(assignCallbackSlice.actions.updateAgentFilter(selectedAgent?.id));
    const index = agents.findIndex((agent) => agent.id === agentId);
    if (index >= 0) {
      const filteredList = [...agents];
      filteredList.splice(index, 1);
      setFilteredAgents(filteredList);
    }
    dispatch(assignCallbackSlice.actions.updateAgentId(0));
    dispatch(assignCallbackSlice.actions.updateCallbacks([]));
  };

  const handleSelectedAgent = (event: any) => {
    const agentId = event.target.value;
    const selectedAgent = agents.find((agent) => agent.id === agentId);
    if (selectedAgent === undefined) return;
    dispatch(assignCallbackSlice.actions.updateAgentId(selectedAgent.id));
  };

  function assignCallbacks() {
    if (assignCallbacksForm.agentId === 0) return;
    setLoading(true);
    const onSuccess = (data: any) => setLoading(false);
    const onError = (err: any) => setLoading(false);
    CALLBACK_SERVICE.assignCallbacks(onSuccess, onError, {
      agentId: assignCallbacksForm.agentId,
      id: assignCallbacksForm.id,
    });
  }

  function handleSubmit() {
    assignCallbacks();
    clearFilter();
    onAction && onAction();
  }

  const clearFilter = () => {
    dispatch(
      assignCallbackSlice.actions.set(assignCallbackSlice.getInitialState())
    );
  };

  return (
    <Grid container columnSpacing={3} justifyContent={"end"}>
      <Grid xs={4}>
        <CustomSelect
          fullWidth
          label="Callback Agent"
          disabled={disabled}
          backgroundColor="white"
          sx={{ height: "40px" }}
          values={agents.map((agent) => ({
            value: agent?.id,
            label: agent?.firstName ? agent?.firstName : agent?.username,
          }))}
          onChange={handleAgentFilter}
          selectedValue={
            assignCallbacksForm.agentFilterId === 0
              ? ""
              : `${assignCallbacksForm.agentFilterId}`
          }
          showClear={true}
          handleClear={clearFilter}
        />
      </Grid>
      <Grid xs={4}>
        <CustomSelect
          fullWidth
          label="Assign to Agent"
          disabled={disabled}
          backgroundColor="white"
          sx={{ height: "40px" }}
          values={
            assignCallbacksForm.agentFilterId === 0
              ? agents.map((agent) => ({
                  value: agent.id,
                  label: agent.firstName ? agent.firstName : agent.username,
                }))
              : filteredAgents.map((agent) => ({
                  value: agent.id,
                  label: agent.firstName ? agent.firstName : agent.username,
                }))
          }
          onChange={handleSelectedAgent}
          selectedValue={
            assignCallbacksForm.agentId === 0
              ? ""
              : `${assignCallbacksForm.agentId}`
          }
        />
      </Grid>
      <Grid xs={2}>
        <FormActionButton
          onClick={handleSubmit}
          title="Assign"
          variant="contained"
          loading={laoding}
          disabled={isAssignDisabled || disabled}
          sx={{ minWidth: "100px", width: "100%", height: "40px" }}
        />
      </Grid>
    </Grid>
  );
};

export default AssignToAgent;
