import { createContext, Dispatch, SetStateAction } from "react";

type TLoadingContext = {
  loadingContext: boolean;
  setLoadingContext: Dispatch<SetStateAction<boolean>>;
};

export const LoadingContext = createContext<TLoadingContext>({
  loadingContext: true,
  setLoadingContext: () => {},
});
