import VisibilityIcon from "@mui/icons-material/Visibility";
import { Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import { callSlice } from "../../state/call/callSlice";
import { callbackSlice } from "../../state/callback/callbackSlice";
import { useAppDispatch } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import { UserStateStatus } from "../../state/types/user";
import { userSlice } from "../../state/user/userSlice";
import CustomIconButton from "../Custom/CustomIconButton";
import LeadInformationPopup from "../LeadInformation/LeadInformationPopup";
import LightTooltip from "../LightToolTip";

const ViewLeadInfoButton = ({
  onOpen,
  onClose,
  callback,
}: {
  onOpen?: () => void;
  onClose?: () => void;
  callback: GetCallbackDTO;
}) => {
  const [openLeadInfo, setOpenLeadInfo] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleClick = () => {
    dispatch(leadSlice.actions.setLead(callback.Lead));
    dispatch(callbackSlice.actions.setCallback(callback));
    dispatch(callSlice.actions.updateCallType("CALLBACK"));

    dispatch(
      userSlice.actions.updateUserStatus(UserStateStatus.CALLBACK_PREVIEW)
    );
  };
  return (
    <>
      <LightTooltip title="View Lead Information">
        <CustomIconButton
          Icon={VisibilityIcon}
          backgroundColor="#EFD053"
          onClick={handleClick}
          sx={{
            width: mobileView ? 32 : 30,
            height: mobileView ? 32 : 30,
          }}
          IconSize={16}
        />
      </LightTooltip>
      {openLeadInfo && (
        <LeadInformationPopup
          openPopup={openLeadInfo}
          setOpenPopup={setOpenLeadInfo}
          onClose={onClose}
          callback={callback}
        />
      )}
    </>
  );
};

export default ViewLeadInfoButton;
