import { ListItem, Typography, useTheme } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Break = ({
  title,
  value,
  loader,
}: {
  title: string;
  value: string;
  loader: boolean;
}) => {
  const theme = useTheme();
  return (
    <ListItem sx={{ paddingX: 2, paddingY: 0.5 }}>
      <Typography
        variant="body2"
        color={theme.palette.common.black}
        flexGrow={1}>
        {title}
      </Typography>
      {loader === true ? (
        <Skeleton baseColor="rgba(0,0,0,0.2)" width={"70px"} />
      ) : (
        <Typography
          variant="body2"
          color={theme.palette.common.black}
          fontWeight={700}
          sx={{
            fontFamily: { xs: "Josefin Sans", md: "Roboto" },
            fontSize: { xs: "14px", md: "" },
          }}>
          {value}
        </Typography>
      )}
    </ListItem>
  );
};

export default Break;
