import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CAMPAIGN_SERVICE } from "../../services/CampaignService";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { PostCampaignDTO } from "../../services/dto/PostCampaignDTO";
import { campaignSlice } from "../../state/campaign/campaignSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import FormActionButton from "../Actions/FormActionButton";
import CustomSelect, { selectOptionsMapper } from "../Custom/CustomSelect";
import CustomTextField from "../Custom/CustomTextField";

const postCampaignDTO = {
  name: "",
  description: "",
  supervisorId: 0,
};

const CampaignForm = ({
  supervisors,
}: {
  supervisors: GetUserResponseDTO[];
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const campaign = useAppSelector((state) => state.campaign);
  const [validationError, setValidationError] =
    useState<PostCampaignDTO>(postCampaignDTO);
  const mandatoryFields = ["name", "supervisorId"];

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { name } = (event as SelectChangeEvent).target;
    const { value } = event.target;
    dispatch(
      campaignSlice.actions.update({
        key: id ?? name,
        value: value,
      })
    );
    if (event.target.value === "" || null) {
      if (mandatoryFields.includes(id ?? name)) {
        setValidationError((prev) => ({
          ...prev,
          [id ?? name]: "Error",
        }));
      } else {
        setValidationError((prev) => ({
          ...prev,
          [id ?? name]: "",
        }));
      }
    } else {
      setValidationError((prev) => ({
        ...prev,
        [id ?? name]: "",
      }));
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(campaign)) {
      if (!mandatoryFields.includes(key)) {
        continue;
      }
      if (value.toString() === "" || value === 0) {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  function addCampaign() {
    setLoading(true);
    const onSuccess = (data: any) => {
      setLoading(false);
      if (data.status === 201) {
        navigate(-1);
        dispatch(
          campaignSlice.actions.setCampaign(campaignSlice.getInitialState())
        );
      }
    };
    const onError = (err: any) => setLoading(false);
    CAMPAIGN_SERVICE.postCampaign(onSuccess, onError, campaign);
  }

  const handleSubmit = () => {
    if (!validateForm()) return;
    addCampaign();
  };

  return (
    <>
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}
        >
          Please fill all fields
        </Typography>
      ) : null}
      <Box
        width={{ xs: "100%", md: "400px" }}
        p={{ xs: "10px 0 20px", md: "20px 0" }}
      >
        <CustomTextField
          id="name"
          label="Name"
          required
          onChange={handleInputChange}
          error={Boolean(validationError.name)}
          value={campaign.name}
        />
        <CustomTextField
          id="description"
          label="Description"
          onChange={handleInputChange}
          value={campaign.description}
          error={Boolean(validationError.description)}
        />
        {/* <CustomTextField
          id="client"
          label="Client"
          required
          onChange={handleInputChange}
          error={validationError.client}
        /> */}
        <Box pb="20px">
          <CustomSelect
            error={Boolean(validationError.supervisorId)}
            values={selectOptionsMapper("username", "id", supervisors)}
            label="Choose Supervisor"
            name="supervisorId"
            fullWidth
            selectedValue={
              campaign.supervisorId === 0
                ? ""
                : campaign.supervisorId.toString()
            }
            onChange={handleInputChange}
          />
          {/* <CustomSelect label="Show Comment" fullWidth /> */}
        </Box>
        <CustomTextField label="Prefix" />
        <Box display="flex" gap={2} justifyContent="flex-start" p="10px 0">
          <FormActionButton
            title="Create"
            tooltip="Click to create campaign"
            onClick={handleSubmit}
            variant="contained"
            loading={loading}
          />
          <FormActionButton
            title="Cancel"
            tooltip="Click to cancel and go back"
            onClick={() => navigate(-1)}
            disabled={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default CampaignForm;
