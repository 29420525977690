import { Theme, Typography, useMediaQuery } from "@mui/material";
import { CustomPopup } from "../Custom/CustomPopup";
import ContactDetails from "./ContactDetailsContent";

const ContactDetailsHeader = ({ title }: { title: string }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: "20px", md: "24px" },
        fontWeight: { xs: 700, md: 400 },
        textAlign: { xs: "left", md: "center" },
        mt: "5px",
      }}>
      {title}
    </Typography>
  );
};

const ContactDetailsPopUp = ({
  open,
  setOpen,
  title,
  onAction,
  onClose,
}: {
  open: boolean;
  setOpen: Function;
  title: string;
  onAction?: () => void;
  onClose?: () => void;
}) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <CustomPopup
      open={open}
      setOpen={setOpen}
      Header={() => ContactDetailsHeader({ title: title })}
      Component={() =>
        ContactDetails({
          setOpen,
          onAction: () => {
            onAction && onAction();
            onClose && onClose();
          },
        })
      }
      divider={!mobileView}
      minWidth={850}
      onClose={onClose}
    />
  );
};

export default ContactDetailsPopUp;
