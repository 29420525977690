import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export type SelectOption = {
  label: string;
  value: string | number;
};

export const selectOptionsMapper = (
  label: string,
  value: string,
  array: any[]
) => {
  const options: SelectOption[] = [];
  array.map((item) => {
    options.push({ label: item[`${label}`], value: item[`${value}`] });
  });
  return options;
};

const CustomSelect = ({
  label,
  name,
  values = [],
  minWidth = 125,
  fullWidth = false,
  backgroundColor = "transparent",
  sx,
  error = false,
  onChange,
  selectedValue = "",
  disabled = false,
  hidden = false,
  showClear = false,
  handleClear,
  mandatory = false,
}: {
  label: string;
  name?: string;
  values?: any[];
  minWidth?: any;
  fullWidth?: boolean;
  backgroundColor?: string;
  sx?: object;
  error?: boolean;
  disabled?: boolean;
  onChange?: (event: SelectChangeEvent) => void;
  selectedValue?: string;
  hidden?: boolean;
  showClear?: boolean;
  handleClear?: () => void;
  mandatory?: boolean;
}) => {
  const theme = useTheme();
  const styledBox = fullWidth
    ? { display: hidden ? "none" : "flex" }
    : { minWidth: minWidth, display: hidden ? "none" : "inline-block" };
  const capitalize = (str: string) => {
    if (str === undefined) return "";
    return str.toLowerCase().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
  };
  return (
    <Box sx={styledBox}>
      <FormControl fullWidth error={error}>
        <InputLabel
          sx={{
            color:
              theme.palette.mode === "dark"
                ? theme.palette.common.white
                : theme.palette.common.black,
            "&.MuiInputLabel-root": {
              color: disabled ? "text.disabled" : "common.black",
              "&.Mui-error": {
                color: "common.black",
              },
            },
          }}
        >
          {
            <span className={mandatory ? "red-asterisk mandatory" : ""}>
              {label}
            </span>
          }
        </InputLabel>
        <Select
          disabled={disabled}
          name={name ? name : label}
          value={selectedValue}
          label={label}
          onChange={onChange}
          error={error}
          sx={{
            borderRadius: "6px",
            backgroundColor: backgroundColor,
            borderColor: "white",
            ...sx,
            "& .MuiSvgIcon-root": {
              "&.MuiSelect-icon": {
                color: disabled ? "" : "common.black",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0,0,0,0.1)",
            },
            "& .Mui-disabled": {
              borderColor: "rgba(0,0,0,0.1)",
            },
          }}
          IconComponent={KeyboardArrowDownIcon}
        >
          {showClear && (
            <MenuItem value="all" onClick={handleClear}>
              All
            </MenuItem>
          )}
          {values.map((option: any, index: number) => (
            <MenuItem value={option.value ? option.value : option} key={index}>
              {capitalize(option.label ? option.label : option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
