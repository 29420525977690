import { Autocomplete, Chip, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect } from "react";

export interface Option {
  value: number;
  label: string;
}

const CustomListbox = styled("ul")(({ theme }) => ({
  padding: 0,
  margin: 0,
  backgroundColor: theme.palette.background.paper,
  overflowY: "auto",
  maxHeight: 200,
  "&&&": {
    maxHeight: "200px !important",
  },
}));

export const selectOptionsMapper = (
  label: string,
  value: string,
  array: any[]
) => {
  const options: Option[] = [];
  array.map((item) => {
    options.push({ label: item[label], value: parseInt(item[value]) });
  });
  return options;
};

const CustomMultiSelect = ({
  defaultValues = [],
  options,
  onChange,
  selectedValue,
  onInputChange,
  inputValue,
  label = "Select",
  error,
  mandatory,
}: any) => {
  return (
    <Autocomplete
      sx={{ marginTop: "25px" }}
      multiple
      id="tags-outlined"
      options={options}
      value={selectedValue}
      getOptionLabel={(option: Option) => option.label}
      defaultValue={defaultValues}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterSelectedOptions
      onChange={(event, value) => onChange(value)}
      ListboxProps={{
        style: { maxHeight: 200 },
      }}
      ListboxComponent={CustomListbox}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <span className={mandatory ? "red-asterisk mandatory" : ""}>
              {label}
            </span>
          }
          placeholder=""
          error={error}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={`${option.label} (${option.value})`}
            {...getTagProps({ index })}
            disabled={
              defaultValues.findIndex(
                (dv: { value: number; label: string }) =>
                  dv.value === option.value
              ) !== -1
            }
          />
        ))
      }
    />
  );
};

export default CustomMultiSelect;
