import { Box } from "@mui/material";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { PaginationDTO } from "../../../state/types/PaginationDTO";
import "./UGTableServerSorting.scss";

const UGTableServerSorting = ({
  header,
  data,
  loader,
  uniqueID = "id",
  setOrder,
  setOrderBy,
  pagination,
  height = "400x",
  setPagination,
}: {
  header: GridColDef[];
  data: Array<any>;
  loader?: boolean;
  uniqueID?: string;
  setOrder: Function;
  setOrderBy: Function;
  pagination: PaginationDTO;
  height?: string;
  setPagination: Function;
}) => {
  function generateRows(rows: Array<any>) {
    return rows.map((row, index) => {
      return {
        ...row,
        sr_no: index + 1,
      };
    });
  }

  const [sortedRows, setSortedRows] = useState(generateRows(data));

  const handleSort = (sortModel: GridSortModel) => {
    const sortField = sortModel[0]?.field;
    const sortDirection = sortModel[0]?.sort;

    setOrder(sortDirection);
    setOrderBy(sortField);
  };
  const handlePageChange = (sortModel: GridPaginationModel) => {
    setPagination((prev: any) => ({ ...prev, page: sortModel.page + 1 }));
  };

  useEffect(() => {
    setSortedRows(generateRows(data));
  }, [data]);

  return (
    <Box sx={{ height: height, width: "100%" }}>
      <DataGrid
        rowCount={pagination.count}
        paginationMode="server"
        getRowId={(header) => header[uniqueID]}
        rows={sortedRows}
        getRowHeight={() => "auto"}
        columnHeaderHeight={44.5}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          color: "#000",
        }}
        columns={header.map((column) => {
          // Rename the first column to "Sr. No."
          if (column.field === "id") {
            return {
              ...column,
              field: "sr_no",
              headerName: "Sr. No.",
              width: 100,
            };
          }
          return column;
        })}
        initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
        loading={loader}
        onSortModelChange={handleSort}
        onPaginationModelChange={(e) => handlePageChange(e)}
      />
    </Box>
  );
};

export default UGTableServerSorting;
