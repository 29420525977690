import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 750,
      lg: 1025,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: `"Josefin Sans", sans-serif`,
    body2: {
      fontFamily: `"Roboto", san-serif`,
      color: "#96A1B2",
      "@media (max-width:700px)": {
        fontSize: "12px",
      },
    },
    h5: {
      fontSize: "32px",
      fontWeight: 700,
      "@media (max-width:700px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "20px",
      fontWeight: 700,
      "@media (max-width:700px)": {
        fontSize: "16px",
      },
    },
    h4: {
      fontSize: "20px",
      fontWeight: 500,
      "@media (max-width:700px)": {
        fontSize: "16px",
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#6F5FD9",
      dark: "rgb(58, 44, 127)",
      light: "#00D4F3",
    },
    secondary: {
      main: "rgb(124, 110, 237)",
      dark: "#008AC0",
      light: "#00D4F3",
    },
    text: {
      primary: "#000000",
      secondary: "gray",
      disabled: "gray",
    },
    error: {
      main: "#EB5757",
    },
    divider: "rgb(70, 59, 154)",
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& th:last-child": {
            borderRadius: "0 16px 0 0",
          },
          "& th:first-of-type": {
            borderRadius: "16px 0 0 0",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          ":disabled": {
            backgroundColor: "#96A1B2",
          },
        },
      },
    },
  },
});
