import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import CustomInput from "../Custom/CustomInput";
import { CustomPopup } from "../Custom/CustomPopup";
import { SelectOption, selectOptionsMapper } from "../Custom/CustomSelect";
import FormActionButton from "../Actions/FormActionButton";
import CalendarIconBlack from "../../assets/images/CalendarIconBlack";
import { CDRFilters } from "../../services/dto/GetAgentCdrDTO";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { filterFormSlice } from "../../state/filter-form/filterFormSlice";
import { FILTER_KEYS, TFilterForm } from "../../state/types/filterForm";
import CustomAutocomplete from "../Custom/CustomAutocomplete";
import DatePicker from "../EVCustoms/DatePicker/DatePicker";
import {
  calcualteDaysBwDates,
  toDesiredFormat,
} from "../../SupportingFiles/HelpingFunction";
import { PerformaceGaugeAgents } from "../../services/dto/GetPerformanceGaugeDTO";

const FilterPopupContent = ({
  filters,
  setOpen,
  type,
  agentList,
}: {
  filters?: CDRFilters;
  setOpen: Function;
  type: string;
  agentList: Array<SelectOption>;
}) => {
  const params = useAppSelector(
    (state: { filterForm: any }) => state.filterForm
  );
  const [filterParams, setFilterParams] = useState({ ...params });
  const dispatch = useAppDispatch();
  const styledField = { width: { xs: 200, md: 257, maxHeight: "50px" } };
  const styledAutoComplete = {
    width: { xs: 200, md: 259, maxHeight: "50px" },
  };
  const styledDateInput = { width: { xs: 174, md: 232 }, height: "25px" };
  const gridItemPadding = "20px";
  const agents =
    filters && filters[FILTER_KEYS.filterAgent]
      ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterAgent])
      : [];
  const campaigns = filters
    ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterCampaign])
    : [];
  const processes = filters
    ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterProcess])
    : [];
  const leadsets = filters
    ? selectOptionsMapper("value", "id", filters?.[FILTER_KEYS.filterLeadset])
    : [];
  const dispositions = filters
    ? selectOptionsMapper(
        "value",
        "id",
        filters?.[FILTER_KEYS.filterDisposition]
      )
    : [];

  const handleInputChange = (key: keyof TFilterForm, value: number) => {
    // if (!value) return;
    setFilterParams((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getOptionLabel = (option: any) => {
    return option?.label;
  };

  const handleSubmit = () => {
    const isValid = Object.entries(filterParams).some(
      ([key, val]) => val !== ""
    );
    if (!isValid) return;

    const difference = calcualteDaysBwDates(
      filterParams["filter-startAfter"],
      filterParams["filter-startBefore"]
    );
    if (difference >= 0 && difference <= 30) {
      dispatch(filterFormSlice.actions.setFilterForm(filterParams));
      setOpen(false);
    }
  };

  const clearFilterForm = () => {
    setFilterParams(filterFormSlice.getInitialState());
  };

  const updateMasterState = (attrName: any, value: any) => {
    switch (attrName) {
      case "date_from":
        setFilterParams((prev: any) => ({
          ...prev,
          [FILTER_KEYS.filterStartAfter]: toDesiredFormat(value),
        }));
        break;
      case "date_to":
        setFilterParams((prev: any) => ({
          ...prev,
          [FILTER_KEYS.filterStartBefore]: toDesiredFormat(value),
        }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "24px",
          height: "30px",
          top: "-10px",
          lineHeight: 1,
        }}
      >
        Filter Details
      </Typography>
      <Typography
        color="error"
        sx={{
          fontFamily: "Roboto",
          fontSize: { xs: "14px", md: "16px" },
        }}
      >
        {calcualteDaysBwDates(
          filterParams[FILTER_KEYS.filterStartAfter],
          filterParams[FILTER_KEYS.filterStartBefore]
        ) > 30
          ? "* Max 30 days data can be fetched, Please try entering a sorter range."
          : calcualteDaysBwDates(
              filterParams[FILTER_KEYS.filterStartAfter],
              filterParams[FILTER_KEYS.filterStartBefore]
            ) < 0
          ? "* From date cannot be greater than To date"
          : ""}
      </Typography>
      <Divider
        sx={{ marginBottom: "20px", borderColor: "rgba(0, 0, 0, 0.1)" }}
      />
      <Grid spacing={3} container marginBottom={"15px"} marginTop={"10px"}>
        {type === "admin" ? (
          <Grid md={4}>
            <CustomAutocomplete
              id="agent"
              sx={styledAutoComplete}
              onChange={(ev: any, value: any) =>
                handleInputChange(FILTER_KEYS["filterName"], value.value)
              }
              data={agentList}
              getOptionLabel={getOptionLabel}
              label="Agent"
              selectedValue={filterParams[FILTER_KEYS.filterName]}
              limitOptions={3}
            />
          </Grid>
        ) : null}
        <Grid md={4} paddingBottom={gridItemPadding}>
          <DatePicker
            title="Date From"
            value={
              filterParams[FILTER_KEYS.filterStartAfter] !== ""
                ? new Date(filterParams[FILTER_KEYS.filterStartAfter])
                : null
            }
            attrName={"date_from"}
            value_update={updateMasterState}
            max_date={new Date()}
          />
        </Grid>
        <Grid md={4} paddingBottom={gridItemPadding}>
          <DatePicker
            title="Date To"
            value={
              filterParams[FILTER_KEYS.filterStartBefore] !== ""
                ? new Date(filterParams[FILTER_KEYS.filterStartBefore])
                : null
            }
            attrName={"date_to"}
            value_update={updateMasterState}
            max_date={new Date()}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          mb: "25px",
        }}
      >
        <FormActionButton onClick={clearFilterForm} title="Reset" />
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={handleSubmit}
        />
      </Box>
    </>
  );
};

const FilterPopupHeader = () => {
  return <></>;
};

export const PerformanceGaugeFilterPopup = ({
  open,
  setOpen,
  filters,
  type,
  agentList,
}: {
  open: boolean;
  setOpen: Function;
  filters?: CDRFilters;
  type: string;
  agentList: Array<SelectOption>;
}) => {
  return (
    <CustomPopup
      divider={false}
      maxWidth={872}
      minWidth={872}
      Header={FilterPopupHeader}
      open={open}
      setOpen={setOpen}
      Component={() =>
        FilterPopupContent({ filters, setOpen, type, agentList })
      }
    ></CustomPopup>
  );
};
