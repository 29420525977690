import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import AgentCDRTable from "../../components/AgentCDR/AgentCDRTable";
import AgentLoggerActions from "../../components/AgentCDR/AgentLoggerActions";
import { SearchBar } from "../../components/SearchBar";
import TablePagination from "../../components/Table/TablePagination";
import { CALL_SERVICE } from "../../services/CallService";
import { GetAgentCDRDTO } from "../../services/dto/GetAgentCdrDTO";
import { PAGINATION_SERVICE } from "../../services/PaginationService";
import { useAppSelector } from "../../state/hooks";
import { FILTER_KEYS, TFilterForm } from "../../state/types/filterForm";

const AgentCDR = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [agentCDRs, setAgentCDRs] = useState<GetAgentCDRDTO | null>(null);
  const params = useAppSelector((state) => state.filterForm);
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });

  const loadAgentCDR = useCallback((text: string) => {
    const onSuccess = (data: GetAgentCDRDTO) => {
      const { count, endCount, startCount, pages } =
        PAGINATION_SERVICE.mapPagination(data.info);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
      setLoading(false);
      setAgentCDRs(data);
    };

    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);

    let data: TFilterForm = params;
    data = {
      ...data,
      [FILTER_KEYS.filterStartAfter]: data["filter-startAfter"] + "Z",
      [FILTER_KEYS.filterStartBefore]: data["filter-startBefore"] + "Z",
    };

    CALL_SERVICE.getAgentCDR(onSuccess, onError, {
      search: text,
      startIndex: 0,
      limit,
      ...data,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadAgentCDR, 500), [
    loadAgentCDR,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getAgentCDR() {
    const onSuccess = (data: GetAgentCDRDTO) => {
      const { count, startCount, endCount, pages } =
        PAGINATION_SERVICE.mapPagination(data.info);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
      setLoading(false);
      setAgentCDRs(data);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    let data: TFilterForm = params;
    data = {
      ...data,
      [FILTER_KEYS.filterStartAfter]: data["filter-startAfter"] + "Z",
      [FILTER_KEYS.filterStartBefore]: data["filter-startBefore"] + "Z",
    };
    CALL_SERVICE.getAgentCDR(onSuccess, onError, {
      search,
      startIndex: (pagination.page - 1) * limit,
      limit,
      ...data,
    });
  }

  useEffect(() => {
    getAgentCDR();
  }, [params, pagination.page]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h5" marginRight={"20px"}>
            Agent CDR
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
            }}
            text={search}
            onChange={handleSearch}
          />
        </Box>
        <AgentLoggerActions
          filters={agentCDRs?.filters}
          search={search}
          type="agent"
        />
      </Box>
      <Box
        sx={{
          pt: "23px",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <AgentCDRTable
          cdrs={agentCDRs?.cdrs ?? []}
          loading={loading}
          startIndex={(pagination.page - 1) * limit}
        />
      </Box>
      <TablePagination page={pagination} setPage={setPagination} />
    </>
  );
};

export default AgentCDR;
