import { IconButton, Theme, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { CustomPopup } from "../Custom/CustomPopup";
import PersonalDetails from "./PersonalDetails";
import CommentsTable from "./CommentsTable";
import CallButton from "../Actions/CallButton";
import PreviewButton from "../Actions/PreviewButton";
import { ALL_ROUTES } from "../../shared/routes";
import { useNavigate } from "react-router-dom";
import CloseIcons from "../Actions/CloseIcons";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { callbackSlice } from "../../state/callback/callbackSlice";
import { CALLBACK_SERVICE } from "../../services/CallbackService";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import { useState } from "react";
import { callSlice } from "../../state/call/callSlice";
import { leadSlice } from "../../state/lead/leadSlice";
import { userSlice } from "../../state/user/userSlice";
import { callbackLogsSlice } from "../../state/callbackLogs/callbackLogsSlice";

const LeadPopupContent = ({ openPopup }: { openPopup: boolean }) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box sx={{ padding: { xs: 0, md: "0px 7px 0px 10px" } }}>
      <PersonalDetails
        showBoxShadow={mobileView ? false : true}
        loader={false}
        sx={{ overflow: "scroll", height: "150px" }}
      />
      <Typography
        sx={{
          marginTop: { md: "25px" },
          marginBottom: { xs: "6px", md: "7px" },
          fontWeight: 600,
          fontSize: { xs: "16px", md: "18px" },
        }}
        variant="h6">
        Comments
      </Typography>
      <CommentsTable open={openPopup} />
    </Box>
  );
};

const LeadPopupHeader = (
  setOpenPopup: any,
  callback: GetCallbackDTO,
  onClose?: () => void
) => {
  const { upcomingCallbackLogs } = useAppSelector(
    (state) => state.callbackLogs
  );
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleClose = () => {
    setOpenPopup(false);
    onClose && onClose();
  };

  // const removeCurrentCallbackFromRedux = () => {
  //   if (index !== undefined) {
  //     const updatedData = [...upcomingCallbackLogs];
  //     updatedData.splice(index, 1);

  //     dispatch(callbackLogsSlice.actions.updateUpcomingCallbacks(updatedData));
  //   }
  // };

  const handleCall = () => {
    const onSuccess = (data: any) => {
      const response = data.data;
      const leadData = response.leadData;
      const callCode = leadData.callCode;

      const callId = response.callId;
      const leadId = response.callback.leadId;

      dispatch(callSlice.actions.updateCallCode(callCode));
      dispatch(callSlice.actions.updateCallId(callId));
      dispatch(leadSlice.actions.updateLead({ key: "id", value: leadId }));
      //removeCurrentCallbackFromRedux();
      // setLoader(false);

      //Remove callback from redux if user called on it
      const dataUpcomingCallbacks = [...upcomingCallbackLogs];
      if (
        dataUpcomingCallbacks.findIndex((data) => data.callId === callback.id)
      ) {
        const index = dataUpcomingCallbacks.findIndex(
          (data) => data.callId === callback.id
        );
        dataUpcomingCallbacks.splice(index, 1);

        dispatch(
          callbackLogsSlice.actions.updateUpcomingCallbacks(
            dataUpcomingCallbacks
          )
        );
      }

      dispatch(userSlice.actions.goToCall());
    };
    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };

    setLoader(true);
    CALLBACK_SERVICE.callCallback(onSuccess, onError, callback.id.toString());
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "right",
        flexDirection: "column",
        marginTop: { xs: "-10px", md: 0 },
        padding: { xs: 0, md: "0px 7px 0px 10px" },
      }}>
      <Box
        sx={{
          textAlign: "end",
          display: { xs: "block", md: "none" },
          marginBottom: "6px",
        }}>
        <CloseIcons onClick={handleClose} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: { xs: 0, md: "auto" },
          width: { md: "63%", xs: "100%" },
        }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", md: "24px" },
            fontWeight: { xs: 700, md: 400 },
          }}>
          Lead Information
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: 1.5, md: "15px" },
          }}>
          <CallButton
            sx={{ height: mobileView ? 32 : 38, width: mobileView ? 32 : 38 }}
            iconSize={20}
            onClick={handleCall}
            isCalling={loader}
          />

          <IconButton
            onClick={() => {
              dispatch(callbackSlice.actions.setCallback(callback));
              navigate(ALL_ROUTES.PREVIEW);
            }}
            sx={{ padding: 0 }}>
            <PreviewButton
              sx={{ height: mobileView ? 32 : 38, width: mobileView ? 32 : 40 }}
              iconSize={22}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const LeadInformationPopup = ({
  openPopup,
  setOpenPopup,
  onClose,
  callback,
}: {
  openPopup: boolean;
  setOpenPopup: Function;
  onClose?: () => void;
  callback: GetCallbackDTO;
}) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <CustomPopup
      Header={() => LeadPopupHeader(setOpenPopup, callback, onClose)}
      open={openPopup}
      setOpen={setOpenPopup}
      hideCloseButton={mobileView ? true : false}
      Component={() => LeadPopupContent({ openPopup })}
      onClose={onClose}
      maxWidth="872px"
      minWidth="872px"
      minHeight="722px"
      sx={{
        maxHeight: {
          xs: "100vh",
          md: "610px",
        },
      }}
      divider={mobileView ? false : true}
    />
  );
};

export default LeadInformationPopup;
