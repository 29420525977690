import React from "react";

const StopIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#E2606C" fillOpacity="0.2" />
      <path
        d="M23.9 20.741V12.525C23.9 12.2664 23.7972 12.0184 23.6144 11.8355C23.4315 11.6527 23.1835 11.55 22.925 11.55C22.6664 11.55 22.4184 11.6527 22.2355 11.8355C22.0527 12.0184 21.95 12.2664 21.95 12.525V19.35H20.65V10.575C20.65 10.4469 20.6247 10.3202 20.5757 10.2019C20.5267 10.0836 20.4549 9.97608 20.3644 9.88555C20.2738 9.79501 20.1664 9.72319 20.0481 9.67419C19.9298 9.62519 19.803 9.59998 19.675 9.59998C19.5469 9.59998 19.4201 9.62519 19.3018 9.67419C19.1835 9.72319 19.0761 9.79501 18.9855 9.88555C18.895 9.97608 18.8232 10.0836 18.7742 10.2019C18.7252 10.3202 18.7 10.4469 18.7 10.575V19.35H17.4V12.525C17.4 12.2664 17.2972 12.0184 17.1144 11.8355C16.9315 11.6527 16.6835 11.55 16.425 11.55C16.1664 11.55 15.9184 11.6527 15.7355 11.8355C15.5527 12.0184 15.45 12.2664 15.45 12.525V20.65H14.15V15.125C14.15 14.8664 14.0472 14.6184 13.8644 14.4355C13.6815 14.2527 13.4335 14.15 13.175 14.15C12.9164 14.15 12.6684 14.2527 12.4855 14.4355C12.3027 14.6184 12.2 14.8664 12.2 15.125V23.7297C12.2002 25.218 12.6981 26.6634 13.6144 27.8361C14.5308 29.0089 15.813 29.8415 17.2571 30.2015C18.7011 30.5616 20.2241 30.4284 21.5838 29.8231C22.9434 29.2179 24.0617 28.1754 24.7606 26.8614L28.5995 19.6418C28.6733 19.5059 28.7197 19.3568 28.736 19.203C28.7522 19.0492 28.738 18.8937 28.6942 18.7453C28.6503 18.597 28.5777 18.4588 28.4804 18.3385C28.3831 18.2183 28.2631 18.1183 28.1272 18.0445C27.9913 17.9706 27.8422 17.9242 27.6884 17.9079C27.5346 17.8917 27.3791 17.9059 27.2307 17.9497C27.0824 17.9936 26.9442 18.0662 26.8239 18.1635C26.7037 18.2608 26.6037 18.3808 26.5299 18.5167L25.1181 21.0575C24.7931 21.6438 23.9 21.4124 23.9 20.7416V20.741Z"
        fill="#E2606C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7C27.1799 7 33 12.8201 33 20C33 27.1799 27.1799 33 20 33C12.8201 33 7 27.1799 7 20C7 12.8201 12.8201 7 20 7ZM31.7617 20C31.7617 13.5039 26.4961 8.23825 20 8.23825C13.5039 8.23825 8.23825 13.5039 8.23825 20C8.23825 26.4961 13.5039 31.7617 20 31.7617C26.4961 31.7617 31.7617 26.4961 31.7617 20Z"
        fill="#E2606C"
      />
    </svg>
  );
};

export default StopIcon;
