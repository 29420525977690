import { Typography } from "@mui/material";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { CustomPopup } from "../Custom/CustomPopup";
import EditCalbackDetailsContent from "./EditCallbackDetailscontent";

const EditCallbackDetailsHeader = () => {
  return (
    <Typography fontSize={"24px"} mb="8px">
      Edit Callback Details
    </Typography>
  );
};

const EditCallbackDetailsPopUp = ({
  open,
  setOpen,
  agents = [],
  onAction,
}: {
  open: boolean;
  setOpen: Function;
  agents: GetUserResponseDTO[];
  onAction: Function;
}) => {
  return (
    <CustomPopup
      open={open}
      setOpen={setOpen}
      Header={EditCallbackDetailsHeader}
      Component={() => EditCalbackDetailsContent(setOpen, agents, onAction)}
      minWidth={872}
    />
  );
};

export default EditCallbackDetailsPopUp;
