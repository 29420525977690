import { Modal, Box, Typography, Link } from "@mui/material";
import ProcessTable from "./ProcessTable";
import CloseIcons from "../Actions/CloseIcons";
import { useEffect, useState } from "react";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { USER_SERVICE } from "../../services/UserService";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const ProcessInfoPopup = ({
  open_status,
  attrName,
  value_update,
  selectedUser,
}: {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  selectedUser: GetUserResponseDTO | null;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<
    Array<{
      name: any;
      description: any;
      campaign: any;
      process_description: any;
    }>
  >([]);

  useEffect(() => {
    getProcessData();
  }, [selectedUser]);

  function getProcessData() {
    const onSuccess = (data: any) => {
      setLoading(false);
      const response = data.process;

      const table_data = [];
      for (let i = 0; i < response.length; i++) {
        const data = {
          name: response[i].name,
          description: response[i].campaign.description,
          process_description: response[i].description,
          campaign: response[i].campaign.name,
          id: response[i].id,
        };
        table_data.push(data);
      }
      setTableData(table_data);
      //  setTableData(users);
    };

    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };

    setLoading(true);
    USER_SERVICE.getProcessAgainstUser(
      onSuccess,
      onError,
      selectedUser?.id === undefined ? 0 : selectedUser?.id
    );
  }

  return (
    <Modal
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={open_status}
      onClose={() => {
        value_update(attrName, false);
      }}>
      <Box
        style={{
          minWidth: "300px",
          maxWidth: "70%",
          minHeight: "400px",
          maxHeight: "70%",
          backgroundColor: "#FFF",
          borderRadius: "15px",
          padding: "15px",
        }}>
        <Box
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}>
          <Typography
            fontSize={"20px"}
            style={{
              height: "49px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}>
            {selectedUser?.username}
          </Typography>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "rgba(0,0,0,0.2)",
            }}
          />
          <CloseIcons
            sx={{
              marginRight: 2,
              width: "31px",
              height: "32px",
              boxSizing: "border-box",
              position: "absolute",
              top: "-29px",
              right: "-44px",
            }}
            onClick={() => {
              value_update(attrName, false);
            }}
          />
        </Box>
        <ProcessTable selectedUser={selectedUser} />
      </Box>
    </Modal>
  );
};

export default ProcessInfoPopup;
