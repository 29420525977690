import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { LEADSET_SERVICE } from "../../../services/LeadsetService";
import { PROCESS_SERVICE } from "../../../services/ProcessService";
import { GetLeadsetDTO } from "../../../services/dto/GetLeadsetDTO";
import { GetProcessDTO } from "../../../services/dto/GetProcessDTO";
import FormActionButton from "../../Actions/FormActionButton";
import CustomMultiSelectFixed from "../../Custom/CustomMultiSelectFixed";
import { CustomPopup2 } from "../../Custom/CustomPopup2";

const LeadsetSelectionHeader = (name: string) => {
  return (
    <Typography fontSize={20} fontWeight={600}>
      {"Add/Remove New Leadset (Process: " + name + ")"}
    </Typography>
  );
};

const LeadsetSelectionContent = ({
  selectedProcess,
  onAction,
  setOpen,
}: {
  selectedProcess?: GetProcessDTO;
  onAction?: () => void;
  setOpen: Function;
}) => {
  const [leadsets, setLeadsets] = useState<GetLeadsetDTO[]>([]);
  const [selectedLeadsets, setSelectedLeadsets] = useState<GetLeadsetDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function getLeadsets() {
    const onSuccess = (data: any) => setLeadsets(data.leadsets);
    const onError = (err: any) => console.log(err);
    LEADSET_SERVICE.getAllLeadset(onSuccess, onError);
  }

  useEffect(() => {
    const data_selected_leadset_without_default_leadset =
      selectedProcess?.processLeadsets.filter(
        (item) => item.id !== selectedProcess.defaultLeadset.id
      );
    setSelectedLeadsets(
      data_selected_leadset_without_default_leadset === undefined
        ? []
        : data_selected_leadset_without_default_leadset
    );

    getLeadsets();
  }, []);

  const updateProcessLeadsets = () => {
    const leadsetIds = selectedLeadsets.map((leadset) => {
      if (typeof leadset.id === "number") return leadset.id;
      return parseInt(leadset.id);
    });
    if (selectedProcess?.defaultLeadset.id) {
      leadsetIds.push(selectedProcess?.defaultLeadset.id);
    }
    const onSuccess = (data: any) => {
      setLoading(false);
      setOpen(false);
      onAction && onAction();
    };
    const onError = (err: any) => {
      setLoading(false);
      console.log(err);
    };
    setLoading(true);
    PROCESS_SERVICE.updateLeadsets(
      onSuccess,
      onError,
      selectedProcess?.id.toString() ?? "",
      {
        leadsetId: leadsetIds,
      }
    );
  };

  return (
    <Box sx={{ overflow: "visible" }}>
      <CustomMultiSelectFixed
        options={leadsets}
        defaultValues={[selectedProcess?.defaultLeadset]}
        selectedValue={selectedLeadsets}
        onChange={(value: any) => {
          const defaultValues = [selectedProcess?.defaultLeadset];
          setSelectedLeadsets([
            ...value.filter(
              (option: any) => defaultValues.indexOf(option) === -1
            ),
          ]);
        }}
        label="Add/Remove Leadset"
      />
      <Box
        mt="30px"
        mb="14px"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}>
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={updateProcessLeadsets}
          loading={loading}
        />
        <FormActionButton
          title="Cancel"
          onClick={() => setOpen(false)}
          disabled={loading}
        />
      </Box>
    </Box>
  );
};

const LeadsetSelectionPopup = ({
  open,
  setOpen,
  selectedProcess,
  onAction,
}: {
  open: boolean;
  setOpen: Function;
  selectedProcess?: GetProcessDTO;
  onAction?: () => void;
}) => {
  const defaultValues = selectedProcess?.processLeadsets.map((leadset) => ({
    label: leadset.name,
    value: leadset.id.toString(),
  }));
  return (
    <CustomPopup2
      Header={LeadsetSelectionHeader(selectedProcess?.name ?? "")}
      open={open}
      setOpen={setOpen}
      Component={() =>
        LeadsetSelectionContent({
          selectedProcess,
          setOpen,
          onAction: onAction,
        })
      }
      minWidth={"959px"}
    />
  );
};

export default LeadsetSelectionPopup;
