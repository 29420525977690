import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Box } from "@mui/system";
export const SearchBar = ({
  sx,
  text = "",
  onChange = () => {},
}: {
  sx?: object;
  text?: string;
  onChange?: (text: string) => void;
}) => {
  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      sx={{
        borderRadius: "50px",
        background: "#F5F5F5",
        paddingY: 2,
        paddingX: 2,
        width: "190px",
        maxWidth: "366px",
        height: { xs: "22px", md: "30px", lg: "30px" },
        display: "flex",
        justifyContent: "center",
        ...sx,
        "& .MuiInputBase-input": {
          "&.MuiInput-input": {
            fontSize: { xs: "12px", md: "16px" },
            fontFamily: { xs: "Roboto", md: "Josefin Sans" },
            paddingTop: "10px",
          },
        },
      }}
      onChange={handleChange}
      value={text}
      id="input-with-icon-textfield"
      placeholder="Search here..."
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <Box mt={{ xs: "7px", md: "10px" }}>
              <SearchOutlinedIcon style={{ fontSize: "19px", color: "#000" }} />
            </Box>
          </InputAdornment>
        ),
      }}
      variant="standard"
    />
  );
};
