import { PayloadAction } from "@reduxjs/toolkit";
import {
  TUserState,
  UserAccessLevel,
  UserBreakReason,
  UserDetails,
  UserStateStatus,
} from "../types/user";

function reDialCall(state: TUserState) {
  state.status = UserStateStatus.REDIAL_CALL;
}

function goToReady(state: TUserState) {
  state.status = UserStateStatus.PRE_READY;
}

function goToReadyCallback(state: TUserState) {
  state.status = UserStateStatus.CALLBACK;
}

function goToPause(state: TUserState) {
  state.status = UserStateStatus.PAUSE;
}

function goToStop(state: TUserState) {
  state.status = UserStateStatus.STOP;
}

function goToGeneralBreak(state: TUserState) {
  state.breakReason = UserBreakReason.GENERAL;
}

function goToLunchBreak(state: TUserState) {
  state.breakReason = UserBreakReason.LUNCH;
}

function goToMeetingBreak(state: TUserState) {
  state.breakReason = UserBreakReason.MEETING;
}

function goToTeaBreak(state: TUserState) {
  state.breakReason = UserBreakReason.TEA;
}

function goToBioBreak(state: TUserState) {
  state.breakReason = UserBreakReason.BIO;
}

function goToMiscBreak(state: TUserState) {
  state.breakReason = UserBreakReason.MISC;
}

function goToCall(state: TUserState) {
  state.status = UserStateStatus.CALL;
}

function goToCallback(state: TUserState) {
  state.status = UserStateStatus.CALLBACK;
}

function setUserDetails(state: TUserState, action: PayloadAction<UserDetails>) {
  state.userDetails = action.payload;
}

function updateUserStatus(
  state: TUserState,
  action: PayloadAction<UserStateStatus>
) {
  state.status = action.payload;
}

function updateShortcutEnableStatus(
  state: TUserState,
  action: PayloadAction<boolean>
) {
  return { ...state, shortcutKeys: action.payload };
}

function updateManualCallStatus(
  state: TUserState,
  action: PayloadAction<boolean>
) {
  return { ...state, manualCallStatus: action.payload };
}

function updateMicErrorStatus(
  state: TUserState,
  action: PayloadAction<boolean>
) {
  return { ...state, micErrorStatus: action.payload };
}

function updateWebRTCConnStatus(
  state: TUserState,
  action: PayloadAction<boolean>
) {
  return { ...state, webRTCConnStatus: action.payload };
}

export const USER_REDUCERS = {
  reDialCall,
  goToReady,
  goToPause,
  goToStop,
  goToGeneralBreak,
  goToLunchBreak,
  goToMeetingBreak,
  goToTeaBreak,
  goToBioBreak,
  goToMiscBreak,
  setUserDetails,
  goToCall,
  goToCallback,
  updateUserStatus,
  goToReadyCallback,
  updateManualCallStatus,
  updateMicErrorStatus,
  updateWebRTCConnStatus,
  updateShortcutEnableStatus,
};

export type TUserReducer = typeof USER_REDUCERS;
