import Axios from "axios";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../shared/constants";
import { ALL_ROUTES } from "../shared/routes";
import { AUTH_SERVICE } from "./AuthService";

export default class AxiosAPIInstance {
  public api;
  public cancelApi;
  constructor() {
    const token = localStorage.getItem(LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN);
    if (!token) {
      window.location.pathname = ALL_ROUTES.LOGIN;
    }
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    this.api = Axios.create({
      cancelToken: source.token,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    this.api.interceptors.response.use(
      (config) => config,
      (err) => {
        const originalConfig = err.config;
        if (err?.response?.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const onSuccess = (data: any) => {
            const newToken = data.token;
            localStorage.setItem(
              LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN,
              newToken
            );
            localStorage.setItem(
              LOCALSOTRAGE_KEYS_CONSTANTS.SOCKET_TOKEN,
              data.socketCode
            );
            originalConfig.headers["authorization"] = `Bearer ${newToken}`;
            return this.api(originalConfig);
          };
          const onError = (error: any) => {
            location.href = ALL_ROUTES.LOGOUT;
          };
          return AUTH_SERVICE.getRefreshToken(onSuccess, onError);
        }
        if (err?.response?.status === 400) {
          return Promise.reject(err);
        }
      }
    );
    this.cancelApi = source.cancel;
  }
}
