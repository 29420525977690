function CloseIcon({ size = "12" }: { size?: string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.37905 0.22511C1.22781 0.0790326 1.02524 -0.00179681 0.814978 3.03149e-05C0.604716 0.00185744 0.403584 0.0861952 0.2549 0.234879C0.106217 0.383562 0.0218792 0.584694 0.0200521 0.794956C0.018225 1.00522 0.0990544 1.20779 0.245131 1.35903L4.87625 5.99015L0.24433 10.6213C0.167738 10.6952 0.106645 10.7837 0.0646171 10.8816C0.022589 10.9794 0.000467271 11.0846 -0.000458003 11.1911C-0.00138328 11.2976 0.0189066 11.4032 0.0592281 11.5017C0.0995496 11.6003 0.159095 11.6898 0.23439 11.7651C0.309685 11.8404 0.399221 11.9 0.497775 11.9403C0.596328 11.9806 0.701925 12.0009 0.808404 12C0.914883 11.999 1.02011 11.9769 1.11795 11.9349C1.21579 11.8929 1.30428 11.8318 1.37825 11.7552L6.01017 7.12407L10.6413 11.7552C10.7925 11.9013 10.9951 11.9821 11.2054 11.9803C11.4156 11.9784 11.6168 11.8941 11.7654 11.7454C11.9141 11.5967 11.9985 11.3956 12.0003 11.1853C12.0021 10.9751 11.9213 10.7725 11.7752 10.6213L7.14409 5.99015L11.7752 1.35903C11.9213 1.20779 12.0021 1.00522 12.0003 0.794956C11.9985 0.584694 11.9141 0.383562 11.7654 0.234879C11.6168 0.0861952 11.4156 0.00185744 11.2054 3.03149e-05C10.9951 -0.00179681 10.7925 0.0790326 10.6413 0.22511L6.01017 4.85622L1.37905 0.224308V0.22511Z"
        fill="white"
      />
    </svg>
  );
}

export default CloseIcon;
