import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI, GetAPIToast, PostAPI } from "./API";

function getNextLead(onSuccess: Function, onError: Function) {
  return GetAPI.call(
    URL_CONSTANTS.GET_PREVIEW_NEXT_LEAD,
    (data: any) => onSuccess(data.data),
    {},
    onError
  );
}

function skipPreviewLead(onSuccess: Function, onError: Function) {
  return GetAPIToast.call(
    URL_CONSTANTS.SKIP_PREVIEW_LEAD,
    (data: any) => onSuccess(data.data),
    {},
    onError
  );
}

function callPreviewLead(onSuccess: Function, onError: Function) {
  PostAPI.call(URL_CONSTANTS.CALL_PREVIEW_LEAD, {}, onSuccess, {}, onError);
}

export const PREVIEW_SERVICE = {
  getNextLead,
  callPreviewLead,
  skipPreviewLead,
};
