import { createSlice } from "@reduxjs/toolkit";
import { toDesiredFormat } from "../../SupportingFiles/HelpingFunction";
import { FILTER_KEYS, TFilterForm } from "../types/filterForm";
import { FILTER_FORM_REDUCERS, TFilterFormReducer } from "./filterFormReducers";

const initialState: TFilterForm = {
  [FILTER_KEYS.filterName]: "",
  [FILTER_KEYS.filterPhone]: "",
  [FILTER_KEYS.filterDisconnectedBy]: "",
  [FILTER_KEYS.filterStartAfter]: toDesiredFormat(
    new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000)
  ),
  [FILTER_KEYS.filterStartBefore]: toDesiredFormat(new Date()),
  [FILTER_KEYS.filterDirection]: "",
};

export const filterFormSlice = createSlice<TFilterForm, TFilterFormReducer>({
  name: "filter-form",
  initialState: initialState,
  reducers: FILTER_FORM_REDUCERS,
});
