import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Theme, useMediaQuery, useTheme } from "@mui/material";

const BackButton = ({ onClick }: any) => {
  const theme = useTheme();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <IconButton
      onClick={onClick}
      sx={{
        border: "1px solid #96A1B2",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        p: { xs: "6px", md: "4px 5px" },
        ":hover": {
          cursor: "pointer",
        },
        width: "30px",
        height: "30px",
      }}>
      <ArrowBackIcon
        sx={{
          color: theme.palette.common.black,
          fontSize: mobileView ? 16 : 20,
        }}
      />
    </IconButton>
  );
};

export default BackButton;
