import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

function CustomTabButtons({
  tabs,
  btnBgColor,
  btnHeight,
  btnPadding,
  containerPadding,
  borderRadius,
  containerWidth,
  btnMinWidth,
  containerBgColor,
  fontSize,
  containerBorder,
  btnBorderRadius,
  onClick,
  value,
}: {
  tabs: any[];
  btnBgColor?: string;
  btnHeight?: string;
  btnPadding?: string;
  containerPadding?: string;
  borderRadius?: string;
  containerWidth?: string;
  btnMinWidth?: string;
  containerBgColor?: string;
  fontSize?: string;
  containerBorder?: string;
  btnBorderRadius?: string;
  onClick: (index: number) => void;
  value?: any;
  sx?: any;
}) {
  const [show, setShow] = useState(0);
  const customFunction = (index: number) => {
    onClick(index);
    setShow(index);
  };

  const btnCommonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Josefin sans",
    fontSize: fontSize,
    minWidth: btnMinWidth,
    fontWeight: 400,
    borderRadius: btnBorderRadius ? btnBorderRadius : borderRadius,
    padding: btnPadding,
    height: btnHeight,
    cursor: "pointer",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          overflow: "hidden",
          border: containerBorder,
          borderRadius: borderRadius,
          width: containerWidth,
          padding: containerPadding,
          backgroundColor: containerBgColor,
          boxSizing: "border-box",
        }}
      >
        {tabs &&
          tabs.map((item: any, index: number) =>
            show === index ? (
              <Typography
                variant="h1"
                sx={{
                  ...btnCommonStyles,
                  backgroundColor: btnBgColor,
                  color: "#fff",
                  boxSizing: "border-box",
                }}
                onClick={() => customFunction(index)}
                key={item}
              >
                {item}
              </Typography>
            ) : (
              <Typography
                variant="h1"
                sx={{
                  ...btnCommonStyles,
                  background: "transparent",
                  boxSizing: "border-box",
                }}
                onClick={() => customFunction(index)}
                key={item}
              >
                {item}
              </Typography>
            )
          )}
      </Box>
    </>
  );
}

export default CustomTabButtons;
