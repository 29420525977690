import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { CustomPopup } from "../Custom/CustomPopup";
import { Numpad } from "../Dialpad/Numpad";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import InputField from "../Dialpad/InputField";
import { Microphone } from "../Dialpad/Microphone";
import { useCallProvider } from "../../shared/hooks/useCallProvider";

const BasicDtmfHeader = () => {
  return (
    <Box
      display={{ xs: "flex", md: "none" }}
      width="100%"
      justifyContent="flex-start"
      alignItems={"center"}
      ml="-7px">
      <Typography sx={{ fontSize: 20, fontWeight: 700 }}>Dialpad</Typography>
    </Box>
  );
};

const BasicDTMFContent = () => {
  const [inputNumber, setInputNumber] = useState("");
  const callProvider = useCallProvider();
  const styledButton = {
    height: 60,
    width: 105,
    textAlign: "center",
    lineHeight: 3.2,
    borderRadius: "10px",
    margin: 1.2,
    fontSize: 20,
    boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.2)",
    color: "black",
  };
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box
        display={{ xs: "flex", md: "none" }}
        flexDirection="column"
        alignItems="center">
        <InputField
          inputNumber={inputNumber}
          margin={"0 0 6px"}
          onChange={(e: string) => {}}
        />
        <Typography
          pt="10px"
          pb="12px"
          sx={{ fontSize: "16px", fontWeight: 600 }}>
          Calling: +91 {inputNumber}
        </Typography>
      </Box>
      <Box display={{ xs: "none", md: "flex" }} height="100px">
        <Typography pt="40px" variant="h5" sx={{ fontWeight: 600 }}>
          {inputNumber}
        </Typography>
      </Box>
      <Box
        pb="80px"
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}>
        <Numpad
          setInputNumber={(value) => {
            setInputNumber((prev) => prev + value);
            callProvider?.sendDTMF(parseInt(value));
          }}
        />
        <Box display="flex">
          <Button sx={styledButton} onClick={() => setInputNumber("")}>
            CE
          </Button>
          <Button
            sx={styledButton}
            onClick={() =>
              setInputNumber(inputNumber.slice(0, inputNumber.length - 1))
            }>
            <ArrowBackIosNewIcon />
          </Button>
        </Box>
        <Box display={{ xs: "flex", md: "none" }}>
          <Microphone />
        </Box>
      </Box>
    </Box>
  );
};

const BasicDTMFPopUp = ({ open, setOpen }: any) => {
  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <CustomPopup
      Header={BasicDtmfHeader}
      open={open}
      setOpen={setOpen}
      Component={BasicDTMFContent}
      fullScreen={fullScreen}
      hideCloseButton={!fullScreen}
      borderRadius="10px"
      divider={false}
      backButton={fullScreen ? true : false}
      minWidth={338}
      maxWidth={338}
      closeIconWidth={18.3}
      closeIconHeight={19.3}
    />
  );
};

export default BasicDTMFPopUp;
