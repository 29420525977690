import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import CustomSelect from "../Custom/CustomSelect";
import CustomTextField from "../Custom/CustomTextField";
import { ChangeEvent, useState } from "react";
import FormRow from "./FormRow";
import CustomRadioButtonsGroup from "../Custom/CustomRadioButtonsGroup";
import { PostUserRequestDTO } from "../../services/dto/PostUserRequestDTO";
import FormActionButton from "../Actions/FormActionButton";
import { USER_DETAIL_CONSTANTS } from "./constants";
import { USER_SERVICE } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "./CustomDatePicker";
import CustomAutocomplete from "../Custom/CustomAutocomplete";
import { VALIDATOR } from "../../helper/validator";
import LightTooltip from "../LightToolTip";

const UserDetailsDTO: PostUserRequestDTO = {
  firstName: "",
  lastName: "",
  username: "",
  panCard: "",
  aadharCard: "",
  accessLevel: "",
  gender: "",
  email: "",
  mobile: "",
  address: "",
  city: "",
  pincode: "",
  nationality: "",
  bloodGroup: "",
  qualification: "",
  dob: "",
  password: "",
};

const UserDetailsForm = () => {
  const navigate = useNavigate();
  const styledSelect = { height: "50px" };
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorDetails, setErrorDetails] = useState({
    ...UserDetailsDTO,
    confirmPassword: "",
  });
  const [userDetailsForm, setUserDetailsForm] =
    useState<PostUserRequestDTO>(UserDetailsDTO);
  const mandatoryFields = ["username", "firstName", "password", "accessLevel"];
  const [loading, setLoading] = useState(false);

  const setError = (key: string, error: boolean) => {
    setErrorDetails((prev) => ({
      ...prev,
      [key]: error ? "Invalid Format" : "",
    }));
  };

  const validateFields = (value: string, id: string, name: string) => {
    let isValid = true;
    if (value === "" || value === null) {
      if (mandatoryFields.includes(id ?? name)) {
        setErrorDetails((prev) => ({
          ...prev,
          [id ?? name]: "Error",
        }));
      } else {
        setErrorDetails((prev) => ({ ...prev, [id ?? name]: "" }));
      }
    } else {
      switch (id ?? name) {
        case "firstName":
          isValid = VALIDATOR.validateAlphabetical(value, 50);
          setError(id ?? name, !isValid);
          break;
        case "lastName":
          isValid = VALIDATOR.validateAlphabetical(value, 50);
          setError(id ?? name, !isValid);
          break;
        case "username":
          isValid = VALIDATOR.validateAlphaNumerical(value, 80);
          setError(id ?? name, !isValid);
          break;
        case "panCard":
          isValid = VALIDATOR.validatePanCard(value);
          setError(id ?? name, !isValid);
          break;
        case "aadharCard":
          isValid = VALIDATOR.validateAadharCard(value);
          setError(id ?? name, !isValid);
          break;
        case "email":
          isValid = VALIDATOR.validateEmail(value);
          setError(id ?? name, !isValid);
          break;
        case "mobile":
          isValid = VALIDATOR.validateMobile(value);
          setError(id ?? name, !isValid);
          break;
        case "pincode":
          isValid = VALIDATOR.validateNumerical(value, 6);
          setError(id ?? name, !isValid);
          break;
        case "password":
          isValid = validatePassword(value);
          break;
        default:
          isValid = true;
          setErrorDetails((prev) => ({ ...prev, [id ?? name]: "" }));
      }
    }
    return isValid;
  };

  const validatePassword = (password: string): boolean => {
    if (password !== confirmPassword) {
      setErrorDetails((prev) => ({
        ...prev,
        password: "Passwords do not match!",
        confirmPassword: "Passwords do not match!",
      }));
      return false;
    }
    setErrorDetails((prev) => ({ ...prev, password: "", confirmPassword: "" }));
    return true;
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(userDetailsForm)) {
      if (mandatoryFields.includes(key) && value.toString() === "") {
        setErrorDetails((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    for (const [key, value] of Object.entries(errorDetails)) {
      if (!mandatoryFields.includes(key) && value === "") continue;
      if (value.toString() !== "" || value == null) {
        isValid = false;
      }
    }
    return isValid;
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { name } = (event as SelectChangeEvent).target;
    const { value } = event.target;
    setUserDetailsForm({
      ...userDetailsForm,
      [id ?? name]: value as string,
    });
    if (id === "address" || name === "city") return;
    validateFields(value, id, name);
  };

  function addUser() {
    setLoading(true);
    const onSuccess = (res: any) => {
      setLoading(false);
      navigate(-1);
    };
    const onError = (err: any) => {
      setLoading(false);
      if (err?.response?.data?.statusCode === 400) {
        if (err?.response?.data?.message.includes("Username")) {
          setErrorDetails((prev) => ({ ...prev, username: "Already in use" }));
        }
        if (err?.response?.data?.message.includes("Email")) {
          setErrorDetails((prev) => ({
            ...prev,
            email: "Already in use",
          }));
        }
      }
    };
    USER_SERVICE.postUser(onSuccess, onError, userDetailsForm);
  }

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    addUser();
  };

  const clearForm = () => {
    setUserDetailsForm(UserDetailsDTO);
    setErrorDetails({
      ...UserDetailsDTO,
      confirmPassword: "",
    });
    setSelectedDate(null);
    setConfirmPassword("");
  };

  return (
    <>
      {Object.entries(errorDetails).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}
        >
          Please fill all fields
        </Typography>
      ) : null}
      <FormRow sx={{ paddingTop: { xs: "10px", md: "20px" } }}>
        <CustomTextField
          error={errorDetails.firstName}
          onChange={handleInputChange}
          id="firstName"
          label="First Name"
          required
          fullWidth
          value={userDetailsForm.firstName}
          errorText={
            errorDetails.firstName === "Error" ? "" : errorDetails.firstName
          }
        />
        <CustomTextField
          error={errorDetails.lastName}
          onChange={handleInputChange}
          id="lastName"
          label="Last Name"
          fullWidth
          value={userDetailsForm.lastName}
          errorText={
            errorDetails.lastName === "Error" ? "" : errorDetails.lastName
          }
        />
        <CustomTextField
          error={errorDetails.username}
          onChange={handleInputChange}
          id="username"
          label="Username"
          required
          fullWidth
          value={userDetailsForm.username}
          errorText={
            errorDetails.username === "Error" ? "" : errorDetails.username
          }
        />
      </FormRow>
      <FormRow>
        <CustomTextField
          error={errorDetails.panCard}
          onChange={handleInputChange}
          id="panCard"
          label="Pan Card"
          fullWidth
          value={userDetailsForm.panCard}
          errorText={
            errorDetails.panCard === "Error" ? "" : errorDetails.panCard
          }
        />
        <CustomTextField
          error={errorDetails.aadharCard}
          onChange={handleInputChange}
          id="aadharCard"
          label="Aadhar Card"
          fullWidth
          value={userDetailsForm.aadharCard}
          errorText={
            errorDetails.aadharCard === "Error" ? "" : errorDetails.aadharCard
          }
        />
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            label="Select Access Level"
            values={["ADMIN", "AGENT"]}
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.accessLevel}
            onChange={handleInputChange}
            name="accessLevel"
            error={Boolean(errorDetails.accessLevel)}
          />
        </Box>
      </FormRow>
      <Grid
        container
        spacing={3}
        display="flex"
        alignItems={"flex-start"}
        pt="10px"
        pb="12px"
      >
        <Grid item md={4}>
          <CustomRadioButtonsGroup
            title="Gender"
            id="gender"
            selectedValue={userDetailsForm.gender}
            onChange={handleInputChange}
            error={Boolean(errorDetails.gender)}
            values={USER_DETAIL_CONSTANTS.genders}
          />
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            error={errorDetails.email}
            onChange={handleInputChange}
            id="email"
            label="Email"
            fullWidth
            value={userDetailsForm.email}
            errorText={errorDetails.email === "Error" ? "" : errorDetails.email}
          />
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            error={errorDetails.mobile}
            onChange={handleInputChange}
            id="mobile"
            label="Mobile"
            fullWidth
            value={userDetailsForm.mobile}
            errorText={
              errorDetails.mobile === "Error" ? "" : errorDetails.mobile
            }
          />
        </Grid>
      </Grid>
      <FormRow>
        <CustomTextField
          error={errorDetails.address}
          onChange={handleInputChange}
          id="address"
          label="Address"
          fullWidth
          value={userDetailsForm.address}
          errorText={
            errorDetails.address === "Error" ? "" : errorDetails.address
          }
        />
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            values={["Delhi", "Mumbai"]}
            label="City"
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.city}
            onChange={handleInputChange}
            name="city"
          />
        </Box>
        <CustomTextField
          error={errorDetails.pincode}
          onChange={handleInputChange}
          id="pincode"
          label="Pincode"
          fullWidth
          value={userDetailsForm.pincode}
          errorText={
            errorDetails.pincode === "Error" ? "" : errorDetails.pincode
          }
        />
      </FormRow>
      <FormRow>
        <Box sx={{ width: "100%" }}>
          <CustomAutocomplete
            id="nationality"
            onChange={(event: any, value: any) => {
              setUserDetailsForm((prev) => ({ ...prev, nationality: value }));
              if (value !== null) {
                setErrorDetails((prev) => ({ ...prev, nationality: "" }));
              } else {
                setErrorDetails((prev) => ({ ...prev, nationality: "Error" }));
              }
            }}
            data={USER_DETAIL_CONSTANTS.nationalities}
            error={Boolean(errorDetails.nationality)}
            label="Nationality"
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            values={USER_DETAIL_CONSTANTS.bloodGroups}
            label="Blood Group"
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.bloodGroup}
            onChange={handleInputChange}
            name="bloodGroup"
            error={Boolean(errorDetails.bloodGroup)}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            values={USER_DETAIL_CONSTANTS.qualifications}
            label="Qualification"
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.qualification}
            onChange={handleInputChange}
            name="qualification"
            error={Boolean(errorDetails.qualification)}
          />
        </Box>
      </FormRow>
      <FormRow sx={{ paddingTop: "20px" }}>
        <Box width="100%">
          <CustomDatePicker
            selected={selectedDate}
            setSelected={(date: Date) => {
              setSelectedDate(date);
              setUserDetailsForm({
                ...userDetailsForm,
                dob: date.toISOString(),
              });
              setErrorDetails({ ...errorDetails, dob: "" });
            }}
            error={Boolean(errorDetails.dob)}
          />
        </Box>
        <CustomTextField
          error={errorDetails.password}
          onChange={handleInputChange}
          id="password"
          label="Password"
          required
          fullWidth
          type="password"
          value={userDetailsForm.password}
          autoComplete="new-password"
          errorText={
            errorDetails.password == "Error" ? "" : errorDetails.password
          }
        />
        <CustomTextField
          error={errorDetails.confirmPassword}
          id="confirmPassword"
          label="Confirm Password"
          required
          fullWidth
          type="password"
          errorText={
            errorDetails.confirmPassword == "Error"
              ? ""
              : errorDetails.confirmPassword
          }
          onChange={(e: any) => {
            setConfirmPassword(e.target.value);
            if (
              e.target.value === "" ||
              e.target.value !== userDetailsForm.password
            ) {
              setErrorDetails({
                ...errorDetails,
                password: "Passwords do not match!",
                confirmPassword: "Passwords do not match!",
              });
            } else {
              setErrorDetails({
                ...errorDetails,
                password: "",
                confirmPassword: "",
              });
            }
          }}
          value={confirmPassword}
          autoComplete="new-password"
        />
      </FormRow>
      <Box display="flex" gap={2} justifyContent="center" p="20px 0">
        <FormActionButton
          title="Create"
          tooltip="Click to create the user"
          onClick={handleSubmit}
          variant="contained"
          loading={loading}
        />
        <FormActionButton
          disabled={loading}
          title="Cancel"
          tooltip="Click to cancel and go back"
          onClick={() => navigate(-1)}
        />
        <FormActionButton
          disabled={loading}
          title="Reset"
          tooltip="Reset form values"
          onClick={clearForm}
        />
      </Box>
    </>
  );
};

export default UserDetailsForm;
