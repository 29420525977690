import { Box, SelectChangeEvent, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DISPOSITION_SERVICE } from "../../../services/DispositionService";
import { GetDispositionDTO } from "../../../services/dto/GetDispositionDTO";
import {
  PostProcessDTO,
  postProcessDTOMapper,
} from "../../../services/dto/PostProcessDTO";
import { PROCESS_SERVICE } from "../../../services/ProcessService";
import {
  CallingMode,
  CallingModeAuto,
} from "../../../state/types/defaultProcess";
import { toDesiredFormat } from "../../../SupportingFiles/HelpingFunction";
import FormActionButton from "../../Actions/FormActionButton";
import CustomMultiSelect from "../../Custom/CustomMultiSelect";
import CustomSelect, { selectOptionsMapper } from "../../Custom/CustomSelect";
import CustomTextField from "../../Custom/CustomTextField";
import DatePicker from "../../EVCustoms/DatePicker/DatePicker";

const EditProcessForm = () => {
  const navigate = useNavigate();
  const [disable, setDisable] = useState<boolean>(false);
  const [selectedDispositions, setSelectedDispositions] = useState([]);
  const [dispositions, setDispositions] = useState<GetDispositionDTO[]>([]);
  const [startDate, setStartDate] = useState<Date | null | string>(null);
  const [endDate, setEndDate] = useState<Date | null | string>(null);
  const { id, processId } = useParams();
  const location = useLocation();
  const { state }: any = location;
  const [loading, setLoading] = useState(false);
  const [processForm, setProcessForm] = useState<PostProcessDTO>({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    callingMode: "",
    campaignId: parseInt(id ?? ""),
    client: "",
    ratio: 0,
    dispositionIds: [],
  });
  const [validationError, setValidationError] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    callingMode: "",
    ratio: "",
  });
  const mandatoryFields =
    processForm.callingMode == CallingModeAuto.AUTO
      ? [
          "name",
          "callingMode",
          "ratio",
          "startDate",
          "endDate",
          "dispositionIds",
          "description",
        ]
      : [
          "name",
          "callingMode",
          "startDate",
          "endDate",
          "dispositionIds",
          "description",
        ];

  useEffect(() => {
    let isValid = true;
    for (const [key, value] of Object.entries(processForm)) {
      if (!mandatoryFields.includes(key)) continue;
      if (value.toString() === "" || value.toString() === "0") {
        isValid = false;
      }
    }
    setDisable(!isValid);
  }, [processForm]);

  useEffect(() => {
    const onSuccess = ({
      dispositions,
    }: {
      dispositions: GetDispositionDTO[];
    }) => setDispositions(dispositions);
    const onError = (err: any) => console.log(err);
    DISPOSITION_SERVICE.getAllDispositions(onSuccess, onError);
  }, []);

  useEffect(() => {
    if (state == null) {
      navigate(-1);
    } else {
      setSelectedDispositions(state.processDispositions);
      setProcessForm(postProcessDTOMapper(state));
      setStartDate(new Date(state.processDetails.startDateTime));
      setEndDate(new Date(state.processDetails.endDateTime));
    }
  }, [state]);

  const handleSelectedDispostions = (value: any) => {
    const newDispositionds = value.map((item: any) => item.value);
    setProcessForm((prev) => ({
      ...prev,
      dispositionIds: newDispositionds,
    }));

    const data = value.map((item: any) => ({
      id: item.value,
      name: item.label,
    }));
    setSelectedDispositions(data);
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { name } = (event as SelectChangeEvent).target;
    const { value } = event.target;
    setProcessForm((prev) => ({
      ...prev,
      [id ?? name]: id === "ratio" ? parseInt(value) : value,
    }));
    if (event.target.value === "" || event.target.value === null) {
      if (mandatoryFields.includes(id ?? name)) {
        setValidationError((prev) => ({
          ...prev,
          [id ?? name]: "Error",
        }));
      } else {
        setValidationError((prev) => ({
          ...prev,
          [id ?? name]: "",
        }));
      }
    } else {
      setValidationError({
        ...validationError,
        [id ?? name]: "",
      });
    }
  };

  const handleDateTimeChange = (id: string, date: Date | string) => {
    setProcessForm({
      ...processForm,
      [id]: date,
    });
    setValidationError({ ...validationError, [id]: "" });
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(processForm)) {
      if (!mandatoryFields.includes(key)) continue;
      if (value.toString() === "" || value.toString() === "0") {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  const updateProcess = () => {
    setLoading(true);
    const onSuccess = (data: any) => {
      setLoading(false);
      navigate(-1);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };

    let data = processForm;
    data = {
      ...data,
      startDate: data.startDate.includes("Z")
        ? data.startDate
        : data.startDate + "Z",
      endDate: data.endDate.includes("Z") ? data.endDate : data.endDate + "Z",
    };
    PROCESS_SERVICE.updateProcess(onSuccess, onError, processId ?? "", data);
  };

  const handleSubmit = () => {
    if (validateForm()) updateProcess();
  };

  const updateMasterState = (attrName: any, value: any) => {
    const date = toDesiredFormat(value);

    if (attrName === setStartDate) {
      setStartDate(date);
      handleDateTimeChange("startDate", date);
    } else if (attrName === setEndDate) {
      setEndDate(date);
      handleDateTimeChange("endDate", date);
    }
  };

  return (
    <>
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}
        >
          Please fill all fields
        </Typography>
      ) : null}
      <Grid container spacing={3}>
        <Grid
          xs={12}
          sm={6}
          pr={{ xs: 0, sm: "10px" }}
          // py={{ xs: "10px", sm: "20px" }}
        >
          <CustomTextField
            id="name"
            label="Process Name"
            fullWidth
            onChange={handleInputChange}
            error={validationError.name}
            value={processForm.name}
          />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          pl={{ xs: 0, sm: "10px" }}
          // py={{ xs: "10px", sm: "20px" }}
        >
          <CustomTextField
            id="description"
            label="Description"
            fullWidth
            onChange={handleInputChange}
            error={validationError.description}
            value={processForm.description}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <DatePicker
            title="Start Date"
            value={startDate}
            attrName={setStartDate}
            value_update={updateMasterState}
            error_message="Please select Start Date"
            warn_status={false}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <DatePicker
            title="End Date"
            value={endDate}
            attrName={setEndDate}
            value_update={updateMasterState}
            error_message="Please select End Date"
            warn_status={false}
          />
        </Grid>
        <Grid xs={12} sm={6} pr={{ xs: 0, sm: "10px" }}>
          {/* <CustomSelect
            error={Boolean(validationError.callingMode)}
            values={Object.values(CallingMode)}
            label="Calling Mode"
            fullWidth
            name="callingMode"
            sx={{ height: "50px" }}
            onChange={handleInputChange}
            selectedValue={processForm.callingMode}
          /> */}
        </Grid>
        {/* <---------------temporary disabling---------------------> */}
        {/* <Grid
          xs={12}
          sm={6}
          pl={{ xs: 0, sm: "10px" }}
          pt={{ xs: "0px", md: "20px" }}
          display={
            processForm.callingMode === CallingMode.AUTO ? "flex" : "none"
          }>
          <CustomTextField
            id="ratio"
            label="Ratio"
            fullWidth
            type="number"
            required
            onChange={handleInputChange}
            error={validationError.ratio}
            value={processForm.ratio}
          />
        </Grid> */}
      </Grid>
      <CustomMultiSelect
        selectedValue={selectOptionsMapper("name", "id", selectedDispositions)}
        options={selectOptionsMapper("name", "id", dispositions)}
        onChange={handleSelectedDispostions}
        label="Select Dispose"
      />
      <Box display="flex" p="30px 0" gap={2} justifyContent="flex-start">
        <FormActionButton
          title="Save"
          variant="contained"
          tooltip="Click to save changes"
          onClick={handleSubmit}
          loading={loading}
          disabled={disable}
        />
        <FormActionButton
          title="Cancel"
          tooltip="Click to cancel and go back"
          onClick={() => navigate(-1)}
          disabled={loading}
        />
      </Box>
    </>
  );
};

export default EditProcessForm;
