function EditIcon({ color = "white" }: { color?: string }) {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.14072 12.2H10.2407C10.4131 12.2 10.5784 12.2685 10.7003 12.3904C10.8222 12.5123 10.8907 12.6776 10.8907 12.85C10.8907 13.0224 10.8222 13.1877 10.7003 13.3096C10.5784 13.4315 10.4131 13.5 10.2407 13.5H1.14072C0.968332 13.5 0.803002 13.4315 0.681103 13.3096C0.559205 13.1877 0.490723 13.0224 0.490723 12.85C0.490723 12.6776 0.559205 12.5123 0.681103 12.3904C0.803002 12.2685 0.968332 12.2 1.14072 12.2ZM0.490723 8.95L6.99072 2.45L8.94072 4.4L2.44072 10.9H0.490723V8.95ZM7.64072 1.8L8.94072 0.5L10.8907 2.45L9.59007 3.75065L7.64072 1.8Z"
        fill={color}
      />
    </svg>
  );
}

export default EditIcon;
