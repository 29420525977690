function UploadFileIcon() {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3973 8.9V4.7L7.19727 0.5H1.59727C1.22596 0.5 0.869867 0.6475 0.607316 0.91005C0.344765 1.1726 0.197266 1.5287 0.197266 1.9V13.1C0.197266 13.4713 0.344765 13.8274 0.607316 14.0899C0.869867 14.3525 1.22596 14.5 1.59727 14.5H9.99727C10.3686 14.5 10.7247 14.3525 10.9872 14.0899C11.2498 13.8274 11.3973 13.4713 11.3973 13.1V10.3H6.49727V12.4L2.99727 9.6L6.49727 6.8V8.9H11.3973ZM6.49727 1.9L9.99727 5.4H6.49727V1.9Z"
        fill="white"
      />
    </svg>
  );
}

export default UploadFileIcon;
