import { CustomPopup } from "../../Custom/CustomPopup";
import DispositionPopUpContent from "./DispositionPopUpContent";
import { Theme, useMediaQuery } from "@mui/material";

const DispositionPopUp = ({
  open,
  setOpen,
  handleBreak,
}: {
  open: boolean;
  setOpen: Function;
  handleBreak?: Function;
}) => {
  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <CustomPopup
      open={open}
      Component={() => DispositionPopUpContent({ setOpen, handleBreak })}
      minWidth={870}
      hideCloseButton={!fullScreen}
      minHeight={500}
    />
  );
};

export default DispositionPopUp;
