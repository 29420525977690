import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Box, Typography } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import AddIcon from "../assets/images/AddIcon";
import EditIcon from "../assets/images/EditIcon";
import BackdropLoader from "../components/BackdropLoader";
import ClockHeader from "../components/ClockHeader";
import BackButton from "../components/Custom/BackButton";
import RoundedButtons from "../components/Custom/RoundedButtons";
import ProcessDetailsTable from "../components/ProcessDetails/ProcessDetailsTable";
import { SearchBar } from "../components/SearchBar";
import TablePagination from "../components/Table/TablePagination";
import { DOWNLOADER } from "../helper/downloader";
import { GetProcessDTO } from "../services/dto/GetProcessDTO";
import { PROCESS_SERVICE } from "../services/ProcessService";
import { ALL_ROUTES } from "../shared/routes";
import { useAppSelector } from "../state/hooks";

const ProcessDetails = () => {
  const [loaderRemainingLeads, setLoaderRemainingLeads] =
    useState<boolean>(false);
  const { name } = useAppSelector((state) => state.campaign);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("name");
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });
  const [processes, setProcesses] = useState<GetProcessDTO[]>([]);
  const navigate = useNavigate();
  const { id: campaignId } = useParams();
  const [loader, setLoader] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);

  const loadProcesses = useCallback((text: string) => {
    setLoader(true);
    const onSuccess = ({
      info,
      processes,
    }: {
      info: any;
      processes: GetProcessDTO[];
    }) => {
      setLoader(false);
      setProcesses(processes);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };

    const onError = () => {
      setLoader(false);
    };

    PROCESS_SERVICE.getProcesses(onSuccess, onError, campaignId ?? "", {
      startIndex: 0,
      limit,
      search: text,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadProcesses, 500), [
    loadProcesses,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getProcesses() {
    setLoader(true);
    const onSuccess = ({
      info,
      processes,
    }: {
      info: any;
      processes: GetProcessDTO[];
    }) => {
      setLoader(false);
      setProcesses(processes);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination({
        ...pagination,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      });
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROCESS_SERVICE.getProcesses(onSuccess, onError, campaignId ?? "", {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search,
      sortDirection: order,
      sort: orderBy,
    });
  }

  function reloadRemainingLeads() {
    const onSuccess = ({
      info,
      processes,
    }: {
      info: any;
      processes: GetProcessDTO[];
    }) => {
      setLoaderRemainingLeads(false);
      setProcesses(processes);
      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination({
        ...pagination,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      });
    };

    setLoaderRemainingLeads(true);
    const onError = (err: any) => {
      setLoaderRemainingLeads(false);
      console.log(err);
    };
    PROCESS_SERVICE.getProcesses(onSuccess, onError, campaignId ?? "", {
      startIndex: (pagination.page - 1) * limit,
      limit,
      search,
      sortDirection: order,
      sort: orderBy,
    });
  }

  function handleExport() {
    const onSuccess = (data: any) => {
      setExportLoader(false);
      DOWNLOADER.download(data.data, `processes-${new Date().valueOf()}.xlsx`);
    };
    const onError = (err: any) => {
      console.log(err);
      setExportLoader(false);
    };
    setExportLoader(true);
    if (campaignId === undefined) {
      return;
    }
    PROCESS_SERVICE.exportProcesses(onSuccess, onError, campaignId, {
      search,
    });
  }

  useEffect(() => {
    getProcesses();
  }, [pagination.page, order, orderBy]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          gap={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {true && <BackButton onClick={() => navigate(-1)} />}
          <Typography height={"34px"} variant="h5">
            Process Details
          </Typography>
          <Typography style={{ height: "19px" }} variant="h4">
            {"(" + localStorage.getItem("campaign_name") + ")"}
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
            }}
            text={search}
            onChange={(text) => handleSearch(text)}
          />
        </Box>
        <Box display={"flex"} alignItems="center" gap={1.2}>
          {/* <RoundedButtons
            title="Export"
            color="#5ECE62"
            tooltip="Export the process list"
            Icon={CloudDownloadIcon}
            onClick={handleExport}
          /> */}
          <RoundedButtons
            title="Add"
            color="#F2994A"
            Icon={AddIcon}
            tooltip="Click to add new process"
            onClick={() =>
              navigate(
                generatePath(ALL_ROUTES.CREATE_PROCESS, { id: campaignId })
              )
            }
          />
        </Box>
      </Box>
      <Box style={{ marginTop: "20px" }}>
        <ProcessDetailsTable
          processDetails={processes}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          onAction={getProcesses}
          startIndex={(pagination.page - 1) * limit}
          loader={loader}
          loaderRemainingLeads={loaderRemainingLeads}
          onReloadRemaingLeads={reloadRemainingLeads}
        />
        <TablePagination page={pagination} setPage={setPagination} />
        {exportLoader && <BackdropLoader loading={exportLoader} />}
      </Box>
    </>
  );
};

export default ProcessDetails;
