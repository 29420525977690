export enum COMMENT_VISIBILITY {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export type PostCommentDTO = {
  comment: string;
  visibility: COMMENT_VISIBILITY;
  callId: number | null;
};
