import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { UserAccessLevel } from "../../state/types/user";
import { CustomNavLink } from "./SideNavBar";
import { useAppSelector } from "../../state/hooks";

export const SideNavMenu = ({
  title,
  navLink,
  isDrawerOpen,
  setDrawerOpen,
  userAccessLevel,
}: {
  title: string;
  navLink: CustomNavLink;
  isDrawerOpen: boolean;
  setDrawerOpen: Function;
  userAccessLevel: string;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isExpandedMenu, setExpandedMenu] = useState(false);
  const userState = useAppSelector((state) => state.user.status);
  const isChildActive = useMemo(() => {
    let active = false;
    navLink.children?.map((child) => {
      if (
        location.pathname == child.path ||
        location.pathname.includes(title.toLowerCase())
      )
        active = true;
    });
    return active;
  }, [location]);

  const isNestedChildActive = useCallback(
    (path: string) => {
      if (location.pathname.includes(path.split("/")[2])) return true;
      else return false;
    },
    [location.pathname]
  );

  useEffect(() => {
    if (isChildActive) setExpandedMenu(true);
    else setExpandedMenu(false);
  }, [isDrawerOpen, location]);

  const checkActive = (path: string): boolean => {
    if (path == location.pathname) return true;
    else return false;
  };
  if (!navLink.accessLevel.includes(userAccessLevel)) return <></>;
  if (
    userAccessLevel == UserAccessLevel.AGENT &&
    !navLink.link.allowedStates?.includes(userState)
  )
    return <></>;
  return (
    <>
      <Box display="flex" alignItems="center" p="10px 20px">
        <Box
          display="flex"
          alignItems={"center"}
          flexGrow={1}
          onClick={() => {
            setDrawerOpen(true);
            setExpandedMenu(!isExpandedMenu);
          }}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          <IconButton
            sx={{
              height: 45,
              width: 45,
              backgroundColor: isChildActive ? "primary.main" : "transparent",
              ":hover": {
                backgroundColor: isChildActive ? "primary.main" : "none",
              },
            }}
          >
            <navLink.Icon
              color={isChildActive ? "white" : "#A3ACBA"}
              style={{
                fontSize: "25px",
                color: isChildActive ? "white" : "grey",
              }}
            />
          </IconButton>
          {isDrawerOpen && (
            <Typography
              color={"#000"}
              sx={{
                marginLeft: "10px",
                fontWeight: 600,
                fontSize: { xs: "14px", md: "20px" },
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </Typography>
          )}
        </Box>
        {isDrawerOpen && (
          <IconButton onClick={() => setExpandedMenu(!isExpandedMenu)}>
            <ChevronRightIcon
              sx={{
                transform: isExpandedMenu ? "rotate(90deg)" : "rotate(0deg)",
                transition: "0.3s",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Collapse in={isDrawerOpen && isExpandedMenu}>
        {navLink.children?.map((child) => (
          <Box
            pl="76px"
            pb="10px"
            key={child.id}
            onClick={() => {
              navigate(child.path);
              if (isDrawerOpen) setDrawerOpen(false);
            }}
            sx={{
              ":hover": { cursor: "pointer" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "20px" },
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                color:
                  checkActive(child.path) || isNestedChildActive(child.path)
                    ? "primary.main"
                    : "#000",
              }}
            >
              {child.title}
            </Typography>
          </Box>
        ))}
      </Collapse>
    </>
  );
};
