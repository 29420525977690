import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box } from "@mui/system";
import { useState } from "react";
import { DOWNLOADER } from "../../helper/downloader";
import { CALL_SERVICE } from "../../services/CallService";
import { CDRFilters } from "../../services/dto/GetAgentCdrDTO";
import { filterFormSlice } from "../../state/filter-form/filterFormSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { UserAccessLevel } from "../../state/types/user";
import BackdropLoader from "../BackdropLoader";
import { SelectOption } from "../Custom/CustomSelect";
import RoundedButtons from "../Custom/RoundedButtons";
import { AllLeadsFilterPopup } from "./AllLeadsFilterPopup";

const AllLeadActions = ({
  filters,
  search,
  type,
  agentList,
}: {
  filters?: CDRFilters;
  search?: string;
  type: string;
  agentList: Array<SelectOption>;
}) => {
  const [filterPopup, setFilterPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { accessLevel } = useAppSelector((state) => state.user.userDetails);
  const isAdmin = accessLevel === UserAccessLevel.ADMIN;

  const clearFilters = () => {
    dispatch(filterFormSlice.actions.resetForm());
  };
  const handleExport = () => {
    if (!isAdmin) return;
    const onSuccess = (data: any) => {
      setLoading(false);
      DOWNLOADER.download(data.data, `agent-logs-${new Date().valueOf()}.xlsx`);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    CALL_SERVICE.exportCDRs(onSuccess, onError, {
      search,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}>
        {/* <RoundedButtons
          title="Clear"
          Icon={CloseIcon}
          color="#c1bdbd"
          sx={{
            color: "black",
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.25)",
          }}
          IconColor="black"
          onClick={clearFilters}
        /> */}
        <RoundedButtons
          onClick={() => {
            setFilterPopup(true);
          }}
          title="Filter"
          Icon={FilterAltIcon}
          color="#E2606C"
        />
        {/* {isAdmin && (
          <RoundedButtons
            title="Export"
            Icon={CloudDownloadIcon}
            color="#5ECE62"
            onClick={handleExport}
            disabled={!isAdmin}
          />
        )} */}
      </Box>
      <AllLeadsFilterPopup
        agentList={agentList}
        filters={filters}
        open={filterPopup}
        setOpen={setFilterPopup}
        type={type}
      />
      {loading && <BackdropLoader loading={loading} />}
    </>
  );
};

export default AllLeadActions;
