import {
  Box,
  CircularProgress,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import LightTooltip from "../LightToolTip";
import MediaPlayer from "./MediaPlayer";
import React from "react";
import { CommentType, GetCommentDTO } from "../../services/dto/GetCommentDTO";
import { FORMATTER } from "../../helper/formatter";

const CommentsTableMobile = ({
  comments,
  onScroll,
  loading = false,
}: {
  comments: GetCommentDTO[];
  onScroll: (className: string) => void;
  loading: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const containerClass = "comments-table-small";

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    onScroll(containerClass);
  };

  return mobileView ? (
    <div
      className={containerClass}
      onScroll={handleScroll}
      style={{
        overflow: "scroll",
        flexWrap: "wrap",
        maxWidth: "100%",
        justifyContent: "center",
        maxHeight: "500px",
        padding: "4px 3px",
      }}
    >
      {comments.map((comment: GetCommentDTO, index: number) => (
        <Box
          key={comment.id}
          sx={{
            boxSizing: "border-box",
            padding: "16px",
            borderRadius: 2,
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
            width: "100%",
            height: "170px",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 700,
                  marginBottom: "21px",
                  fontSize: "14px",
                }}
              >
                {index < 9 ? "0" + (index + 1) : index + 1}
              </Typography>
              <Typography
                sx={{ color: "#808080", fontWeight: 700, fontSize: "14px" }}
              >
                Disposition name
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  lineHeight: "22px",
                  marginTop: "4px",
                }}
              >
                {comment.disposition}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              {comment.type === CommentType.DISPOSITION && (
                <LightTooltip title="play Recording" placement="left">
                  <PlayCircleIcon
                    aria-describedby={id}
                    onClick={handleClick}
                    sx={{
                      fontSize: 28,
                      color: "#6F5FD9",
                      marginLeft: 2,
                    }}
                  />
                </LightTooltip>
              )}
              <Typography
                sx={{
                  marginTop: 2,
                  color: "gray",
                  fontWeight: 700,
                  textAlign: "right",
                  fontSize: "14px",
                }}
              >
                Date
              </Typography>

              <Typography
                sx={{
                  textAlign: "right",
                  fontSize: "12px",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  lineHeight: "22px",
                  marginTop: "4px",
                }}
              >
                {comment.createdOn &&
                  FORMATTER.formatDateTime(comment.createdOn)}
              </Typography>
            </Box>
          </Box>
          <Box>
            {" "}
            <Typography
              sx={{
                color: "#808080",
                fontWeight: 700,
                marginTop: 2,
                fontSize: "14px",
              }}
            >
              Remarks
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "Roboto",
                lineHeight: "22px",
                marginTop: "4px",
              }}
            >
              {comment.comment}
            </Typography>
          </Box>
        </Box>
      ))}
      {loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            pb: "10px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Popover
        sx={{
          ".MuiPopover-paper": {
            background: "none",
            boxShadow: "none",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MediaPlayer />
      </Popover>
    </div>
  ) : (
    <></>
  );
};
export default CommentsTableMobile;
