import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LEAD_SERVICE } from "../../services/LeadService";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import FormActionButton from "../Actions/FormActionButton";
import CustomTextField from "../Custom/CustomTextField";

const EditLeadForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const leadsetDetails = useAppSelector((state) => state.leadset);
  const [loading, setLoading] = useState(false);
  const mandatoryFields = ["phone"];
  const lead = useAppSelector((state) => state.lead);
  const [configurationFields, setConfigurationFields] = useState<
    { key: string; value: string }[]
  >(
    !lead.configurations
      ? []
      : Object.entries(lead.configurations).map(([key, value]) => {
          return { key: key, value: value };
        })
  );
  const [validationError, setValidationError] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    leadsetId: "",
  });

  const handleConfigurations = (event: any, index: number) => {
    const newConfig = [...configurationFields];
    if (event.target.name === "key") {
      newConfig[index].key = event.target.value;
      setConfigurationFields(newConfig);
    } else {
      newConfig[index].value = event.target.value;
      setConfigurationFields(newConfig);
    }
    const configurations: { [key: string]: string } = newConfig.reduce(
      (obj, cur) => {
        if (cur.key === "" || cur.value === "") return { ...obj };
        return { ...obj, [cur.key]: cur.value };
      },
      {}
    );

    dispatch(leadSlice.actions.updateLeadConfiguration(configurations));
  };

  const handleInputChange = (event: any) => {
    const { id, value } = event.target;
    dispatch(
      leadSlice.actions.updateLead({
        key: id,
        value: value,
      })
    );
    if (event.target.value === "" || null) {
      if (mandatoryFields.includes(id)) {
        setValidationError((prev) => ({
          ...prev,
          [id]: "Error",
        }));
      } else {
        setValidationError((prev) => ({
          ...prev,
          [id]: "",
        }));
      }
    } else {
      setValidationError({
        ...validationError,
        [id]: "",
      });
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(lead)) {
      if (mandatoryFields.includes(key) && value.toString() === "") {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) updateLead();
  };

  const updateLead = () => {
    setLoading(true);
    const onSuccess = () => {
      navigate(-1);
      setLoading(false);
    };
    const onError = () => setLoading(false);
    LEAD_SERVICE.updateLead(onSuccess, onError, lead, lead.id.toString());
  };

  return (
    <>
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{
            fontFamily: "Roboto",
            lineHeight: "30px",
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: { xs: 500, md: 400 },
          }}
        >
          Please fill all fields
        </Typography>
      ) : null}
      <Grid
        container
        maxWidth={{ xs: "100%", md: "900px" }}
        p={{ xs: "10px 0 20px", md: "20px 0" }}
      >
        <Grid
          item
          sm={6}
          xs={12}
          // pb="10px"
          pr={{ xs: 0, md: "15px" }}
          sx={{
            maxWidth: { xs: "100%", md: "450px" },
            minWidth: { xs: "100%", md: "auto" },
            height: "50px",
            marginBottom: { xs: "16px", md: "30px" },
          }}
        >
          <CustomTextField
            label="Name"
            id="name"
            required
            onChange={handleInputChange}
            value={lead.name}
            error={validationError.name}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          pb="10px"
          pl={{ xs: 0, md: "15px" }}
          sx={{
            maxWidth: { xs: "100%", md: "450px" },
            minWidth: { xs: "100%", md: "auto" },
            height: "50px",
            marginBottom: { xs: "16px", md: "30px" },
          }}
        >
          <CustomTextField
            value={lead.email}
            label="Email"
            required
            id="email"
            onChange={handleInputChange}
            error={validationError.email}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          pb="10px"
          pr={{ xs: 0, md: "15px" }}
          sx={{
            maxWidth: { xs: "100%", md: "450px" },
            minWidth: { xs: "100%", md: "auto" },
            height: "50px",
            marginBottom: { xs: "16px", md: "30px" },
          }}
        >
          <CustomTextField
            label="Phone"
            required
            id="phone"
            disabled
            value={lead.phone}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          pb="10px"
          pl={{ xs: 0, md: "15px" }}
          sx={{
            maxWidth: { xs: "100%", md: "450px" },
            minWidth: { xs: "100%", md: "auto" },
            height: "50px",
            marginBottom: { xs: "16px", md: "30px" },
          }}
        >
          <CustomTextField
            label="Address"
            required
            id="address"
            onChange={handleInputChange}
            value={lead.address}
            error={validationError.address}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          pb="10px"
          pr={{ xs: 0, md: "15px" }}
          sx={{
            maxWidth: { xs: "100%", md: "450px" },
            minWidth: { xs: "100%", md: "auto" },
            height: "50px",
            marginBottom: { xs: "16px", md: "30px" },
          }}
        >
          <CustomTextField
            label="Leadset"
            id="leadsetId"
            value={leadsetDetails.name}
            disabled
          />
        </Grid>
        <Grid item xs={12} sx={{ maxWidth: "900px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { sm: "space-between", xs: "center" },
              border: " 1px solid rgba(0, 0, 0, 0.1)",
              padding: "10px",
              borderRadius: 1,
              gap: 1,
              mt: { xs: 0 },
              mb: "10px",
              flexWrap: { sm: "nowrap", xs: "wrap" },
            }}
          >
            <>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography lineHeight="30px">Add Fields*</Typography>
                  <Button
                    onClick={() => {
                      setConfigurationFields((prev) => [
                        ...prev,
                        { key: "", value: "" },
                      ]);
                    }}
                    sx={{
                      color: "#0066FF",
                      fontSize: "16px",
                      textTransform: "capitalize",
                      fontWeight: 400,
                      textAlign: "end",
                      padding: 0,
                      display: "inline",
                    }}
                  >
                    +Add
                  </Button>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    sx={{
                      width: "50%",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "30px",
                    }}
                  >
                    Column
                  </Typography>
                  <Typography
                    sx={{
                      width: "50%",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "30px",
                      paddingLeft: "10px",
                    }}
                  >
                    Value
                  </Typography>
                </Box>
              </Box>
              {configurationFields.map((field, index: number) => (
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  key={index}
                  gap={"10px"}
                >
                  <TextField
                    id={index.toString()}
                    name="key"
                    size="small"
                    fullWidth
                    value={field.key}
                    onChange={(event: any) =>
                      handleConfigurations(event, index)
                    }
                    inputProps={{
                      style: {
                        fontSize: 14,
                        height: 30,
                        padding: "3px 10px",
                        borderRadius: "20px",
                      },
                    }}
                  />
                  <TextField
                    id={index.toString()}
                    name="value"
                    size="small"
                    fullWidth
                    value={field.value}
                    onChange={(event: any) =>
                      handleConfigurations(event, index)
                    }
                    inputProps={{
                      style: {
                        fontSize: 14,
                        height: 30,
                        padding: "3px 10px",
                        borderRadius: "20px",
                      },
                    }}
                  />
                </Box>
              ))}
            </>
          </Box>
        </Grid>

        <Box
          display={"flex"}
          justifyContent={{ xs: "center", md: "flex-start" }}
          width={"100%"}
        >
          <Box
            display="flex"
            gap={2}
            justifyContent="center"
            p={{ xs: "14px 0", md: "20px 0" }}
            flexWrap={"wrap"}
          >
            <FormActionButton
              title="Save"
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
              sx={{ height: { xs: "44px", md: "50px" } }}
            />
            <FormActionButton
              title="Cancel"
              onClick={() => navigate(-1)}
              sx={{ height: { xs: "44px", md: "50px" } }}
              disabled={loading}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default EditLeadForm;
