import { styled, TableSortLabel } from "@mui/material";
import { relative } from "path";
import ArrowDownIcon from "../../assets/images/ArrowDownIcon";

const CreateTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&.MuiTableSortLabel-root": {
    color: "#fff",
    paddingRight: "10px",
  },
  ".MuiTableSortLabel-icon": {
    color: "#fff !important",
    opacity: 1,
  },
}));

function StyledTableSortLabel({
  cell,
  order = "asc",
  setOrder,
  orderBy,
  setOrderBy,
}: {
  cell: any;
  order?: "desc" | "asc";
  orderBy?: string;
  setOrder?: any;
  setOrderBy?: any;
}) {
  const handleSort = (id: string) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === id && order === "asc";
    setOrderBy(id);
    setOrder(isAsc ? "desc" : "asc");
  };
  return (
    <CreateTableSortLabel
      active={orderBy === cell.id}
      direction={orderBy === cell.id ? order : "asc"}
      onClick={orderBy ? handleSort(cell.id) : () => {}}
      IconComponent={() =>
        ArrowDownIcon({
          rotate: orderBy === cell.id && order === "asc" ? true : false,
        })
      }
      sx={{
        ".MuiTableSortLabel-iconDirectionDesc": {
          transform: "rotateZ(180deg)",
        },
      }}>
      {cell.label}
    </CreateTableSortLabel>
  );
}

export default StyledTableSortLabel;
