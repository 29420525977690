import CloseIcon from "@mui/icons-material/Close";
const CloseIcons = ({ onClick, sx }: any) => {
  return (
    <CloseIcon
      onClick={onClick}
      sx={{
        position: { md: "absolute", xs: "relative" },
        right: { md: "-13px ", xs: 0 },
        top: { md: "-13px ", xs: 0 },
        padding: 0.8,
        borderRadius: "50%",
        color: "white",
        backgroundColor: "red",
        cursor: "pointer",
        fontSize: 15,
        marginLeft: { md: "0px", xs: "15px" },
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        width: { xs: "32px", md: "30px" },
        height: { xs: "32px", md: "30px" },
        boxSizing: "border-box",
        ...sx,
      }}>
      X
    </CloseIcon>
  );
};

export default CloseIcons;
