function ArrowDownIcon({
  color = "white",
  height = "8",
  width = "13",
  rotate = false,
}: {
  color?: string;
  width?: string;
  height?: string;
  rotate?: boolean;
}) {
  const style = rotate
    ? {
        transform: "rotateZ(180deg)",
      }
    : {};
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <path
        d="M6.56903 7.33061C6.71986 7.31303 6.86219 7.24394 6.97669 7.13273L12.1453 2.17737C12.3805 1.99961 12.5038 1.68681 12.4625 1.37271C12.4211 1.05861 12.2225 0.798805 11.9515 0.704389C11.6805 0.609972 11.3851 0.697654 11.1916 0.929943L6.49982 5.42933L1.80805 0.929943C1.61456 0.697654 1.31916 0.609972 1.04816 0.704389C0.777159 0.798805 0.578525 1.05861 0.537189 1.37271C0.495853 1.68681 0.619134 1.99961 0.854318 2.17737L6.02295 7.13273C6.17367 7.27836 6.37059 7.34972 6.56903 7.33061Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowDownIcon;
