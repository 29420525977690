export const LogoWhite = () => {
  return (
    <svg
      width="60"
      height="54"
      viewBox="0 0 60 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.713 28.3326L24.713 28.3325C24.5273 26.8957 23.4848 25.9628 22.3633 25.7255C21.2282 25.4853 19.97 25.9606 19.4937 27.3329C19.3066 27.8706 19.298 28.4897 19.4138 29.0588C19.5297 29.6278 19.7797 30.1945 20.1624 30.6167C21.0841 31.6353 22.3238 31.8175 23.3019 31.3171C24.2716 30.821 24.8899 29.704 24.713 28.3326ZM23.778 10.7981L23.5629 10.3486L23.778 10.7981C27.1282 9.19487 31.1894 9.13858 34.9335 10.3397C38.6767 11.5406 42.0561 13.9839 44.0513 17.315C46.2585 21.0003 47.0806 25.9008 46.5883 30.8707C46.0962 35.8372 44.2957 40.823 41.3191 44.6655C39.9783 46.3959 37.6613 48.6181 35.4728 50.4128C34.3808 51.3083 33.3327 52.0876 32.467 52.6401C32.0335 52.9167 31.6547 53.1307 31.3448 53.2738C31.1116 53.3814 30.9468 53.4348 30.8416 53.4565C30.8162 53.414 30.7844 53.3489 30.7516 53.2558C30.6559 52.9841 30.5873 52.5773 30.5873 52.1072C30.5873 50.3697 30.1699 48.9321 29.2424 47.7887C28.3198 46.6514 26.938 45.8617 25.1167 45.3265C17.974 43.2267 13.4587 36.5893 13.4587 27.9649C13.4587 20.5433 17.5427 13.7821 23.778 10.7981ZM30.8828 53.5132C30.8828 53.5132 30.8824 53.5129 30.8816 53.512L30.8828 53.5132ZM32.8695 28.3325L32.8695 28.3324C32.6883 26.9332 31.7405 25.9165 30.3654 25.7011C29.0054 25.4875 27.9421 26.1417 27.5072 27.1762C27.0804 28.1913 27.2922 29.4829 28.2371 30.5263C29.1816 31.5699 30.4341 31.8102 31.4355 31.333C32.4339 30.8574 33.0502 29.7321 32.8695 28.3325ZM39.9496 30.6868L39.9497 30.6867C40.3735 30.2185 40.6858 29.6203 40.8568 29.0313C41.0249 28.4522 41.0749 27.8162 40.8923 27.2908C40.7113 26.7695 40.3119 26.3334 39.8507 26.032C39.3868 25.7288 38.817 25.5328 38.2438 25.5328C36.9132 25.5328 35.9638 26.2876 35.6343 27.3532C35.3116 28.3971 35.6053 29.6558 36.5378 30.6866L36.538 30.6868C36.8448 31.0256 37.1134 31.3098 37.3598 31.5084C37.6059 31.7068 37.8959 31.8751 38.2438 31.8751C38.5917 31.8751 38.8817 31.7068 39.1278 31.5084C39.3742 31.3098 39.6428 31.0256 39.9496 30.6868Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0873 2.41615C13.8574 5.20346 9.11766 10.3005 6.59078 15.8543C4.66178 20.0925 2.89346 26.0141 3.39182 26.5656C4.48152 27.7687 6.51819 25.3535 7.68375 21.4749C9.27099 16.1941 15.1722 8.64411 19.7113 6.0857C23.8001 3.78051 30.3131 3.00371 34.6621 4.30319C41.7541 6.42093 49.2809 14.6062 51.4326 22.541C51.9766 24.5479 52.9685 26.0673 53.9122 26.3403C55.2971 26.7404 55.4203 26.4529 54.9399 23.936C54.0304 19.1679 50.5688 12.2758 47.1187 8.36474C42.2068 2.79554 37.3668 0.444401 30.0985 0.098353C24.7128 -0.158479 23.3956 0.119079 19.0873 2.41615ZM2.9375 28.4265C-0.070611 29.8242 -0.153807 29.9864 0.0827306 33.9516C0.325793 38.0032 0.361685 38.0492 4.5639 39.6226C9.97572 41.6484 10.6919 40.8419 10.3599 33.0954C10.1307 27.7398 9.98877 27.3721 8.07607 27.1856C6.95456 27.0766 4.6422 27.6344 2.9375 28.4265ZM48.5868 27.5352C47.5942 28.6311 47.9938 39.2865 49.0623 40.2318C50.7434 41.7187 57.7278 38.9828 58.7832 36.4252C60.2547 32.8566 59.0483 30.0108 55.3746 28.3869C51.8926 26.8477 49.4824 26.5449 48.5868 27.5352Z"
        fill="#6F5FD9"
      />
    </svg>
  );
};

export const LogoBlack = ({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.805 12.4425C23.2844 16.146 17.7393 24.5151 17.7393 33.6692C17.7393 44.276 23.8719 52.5462 33.6801 55.1651C38.3586 56.4137 40.4609 58.7677 40.4609 62.7567C40.4609 63.9902 40.8525 64.9999 41.3308 64.9999C43.4212 64.9999 52.2415 58.43 55.8845 54.1597C63.955 44.6973 65.592 29.6855 59.5535 20.5282C54.0711 12.2145 41.0549 8.42193 31.805 12.4425ZM32.6739 34.1892C33.0785 37.0383 29.8553 38.5366 27.7865 36.4596C26.9653 35.6366 26.6331 34.1534 27.0346 33.1056C28.0451 30.4596 32.2584 31.2696 32.6739 34.1892ZM43.4937 34.1892C43.9102 37.1186 40.6632 38.5236 38.4982 36.351C36.2931 34.1393 37.6975 31.1046 40.727 31.5367C42.2451 31.7528 43.2892 32.7549 43.4937 34.1892ZM54.1674 33.0546C54.5277 33.996 54.0257 35.5671 53.0519 36.5443C51.4116 38.1892 51.1498 38.1892 49.5095 36.5443C47.2979 34.3239 48.3128 31.3413 51.2807 31.3413C52.5087 31.3413 53.8071 32.1122 54.1674 33.0546Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8692 2.88696C18.9316 6.24522 12.6442 12.3863 9.29219 19.0778C6.73331 24.1842 4.38757 31.3187 5.04866 31.9832C6.49419 33.4327 9.19589 30.5229 10.742 25.8497C12.8476 19.4872 20.6757 10.3907 26.6969 7.30818C32.1209 4.5308 40.7605 3.59488 46.5296 5.16055C55.9375 7.71209 65.922 17.5741 68.7763 27.1342C69.4979 29.5522 70.8136 31.3828 72.0655 31.7118C73.9027 32.1938 74.066 31.8475 73.4288 28.815C72.2224 23.0702 67.6304 14.7663 63.0537 10.0541C56.538 3.34406 50.1175 0.511312 40.476 0.0943795C33.3316 -0.215063 31.5842 0.11935 25.8692 2.88696ZM4.44599 34.2253C0.455649 35.9093 0.345287 36.1048 0.659061 40.8821C0.981491 45.7637 1.0291 45.8191 6.60347 47.7148C13.7824 50.1556 14.7324 49.1838 14.292 39.8506C13.988 33.398 13.7997 32.955 11.2625 32.7302C9.77475 32.5988 6.70733 33.2709 4.44599 34.2253ZM65.0012 33.1515C63.6845 34.4718 64.2146 47.3098 65.632 48.4488C67.862 50.2403 77.127 46.9439 78.5271 43.8625C80.479 39.5629 78.8787 36.1341 74.0055 34.1775C69.3865 32.3231 66.1892 31.9582 65.0012 33.1515Z"
        fill="#6F5FD9"
      />
    </svg>
  );
};
