export const LOCALSOTRAGE_KEYS_CONSTANTS = {
  AUTH_TOKEN: "OSM_AUTH_TOKEN",
  SOCKET_TOKEN: "OSM_SOCKET_TOKEN",
  USER: "OSM_USER_DETAILS",
  USER_STATE: "OSM_USER_STATE",
  TIMER_STATE: "OSM_TIMER_STATE",
  DISPOSE_FORM_STATE: "OSM_DISPOSE_FORM_STATE",
  DEFAULT_PROCESS: "OSM_DEFAULT_PROCESS",
  CLIENT_CONFIG: "OSM_CLIENT_CONFIG",
};

export const SOCKET_EVENTS = {
  USER_STATE_ACTIVITY: "userStateActivity",
  CALLBACK_INITIATING: "CALLBACK_INITIATING",
  CALL_CONNECTED: "CALL_CONNECTED",
  CALL_DISCONNECTED: "CALL_DISCONNECTED",
  CALL_DISCONNECT: "CALL_DISCONNECT",
  CALL_REJECTED: "CALL_REJECTED",
  CALL_CREATED: "CALL_CREATED",
  CALL_RINGING: "CALL_RINGING",
  PRE_READY: "PRE_READY",
  PRE_READY_NEXT: "PRE_READY_NEXT",
  READY_AUTOCALLING: "READY_AUTOCALLING",
  EXIT_AUTOCALLING: "EXIT_AUTOCALLING",
  ALIVE: "ALIVE",
  LOGOUT: "logout",
  CONNECT: "connect",
  CONNECT_ERROR: "connect_error",
  DISCONNECT: "disconnect",
  CLIENT_SOCKET_ID: "CLIENT_SOCKET_ID",
  SERVER_SOCKET_ID: "SERVER_SOCKET_ID",
};

export const AUTO_CALL = {
  AUTO_CALLING: true,
  ORIGIN: window.origin,
};
