import {
  Button,
  CircularProgress,
  Icon,
  Menu,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Fade from "@mui/material/Fade";
import ArrowDownIcon from "../../assets/images/ArrowDownIcon";

const CustomMenuButton = ({
  title,
  Options,
  onClick,
  loading = false,
}: {
  title: string;
  Options: any;
  onClick: Function;
  loading?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const matches = useMediaQuery(`(max-height:732px)`);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        backgroundColor: "#6F5FD9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 140,
        borderRadius: "6px",
        padding: 0.6,
      }}
    >
      {loading ? (
        <Box
          height="40px"
          width="115px"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress size={24} sx={{ color: "common.white" }} />
        </Box>
      ) : (
        <Button
          sx={{
            color: "white",
            textTransform: "capitalize",
            marginLeft: 2,
            fontSize: 16,
            flexGrow: 1,
          }}
          onClick={() => {
            if (loading) return;
            onClick && onClick();
          }}
        >
          {title}
        </Button>
      )}
      <Box
        sx={{
          borderLeft: "1px solid white",
          maxHeight: "20px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Icon
          onClick={(event) => {
            if (loading) return;
            handleOpenMenu(event);
          }}
          sx={{ color: "white", cursor: "pointer", pl: "1px" }}
        >
          <ArrowDownIcon />
        </Icon>
      </Box>
      <Menu
        sx={{
          marginTop: 2,
          "& .MuiPaper-root": {
            borderRadius: "10px",
            "::-webkit-scrollbar": {
              width: "4px",
              "&-thumb": {
                borderRadius: "10px",
              },
            },
          },
        }}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            height: matches ? "15%" : "153px",
            minHeight: "88px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {<Options />}
      </Menu>
    </Box>
  );
};

export default CustomMenuButton;
