import { Box, Typography } from "@mui/material";
import CustomTime from "../Custom/CustomTime";

const LoginTime = ({ time }: { time: number }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}>
      <Box
        sx={{
          backgroundColor: "#6F5FD9",
          borderRadius: { xs: "8px", md: "20px" },
          width: { xs: "100%", md: "333px" },
        }}>
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            fontWeight: 600,
            pt: { xs: "16px", md: "19px" },
            pb: { xs: "8px", md: "9px" },
            fontSize: { xs: "14px", md: "18px" },
          }}>
          Total Login Hours
        </Typography>
        <Box display="flex" justifyContent="center" pb="20px">
          <Box
            sx={{
              maxWidth: { xs: "110px", md: "144px" },
              width: "290px",
              borderRadius: "10px",
              p: { xs: "5px 28px 3px 28px", md: "4px 1px 1px" },
              backgroundColor: "white",
            }}>
            <CustomTime
              timeInMillis={time}
              sx={{
                textAlign: "center",
                fontSize: { xs: 16, md: 24 },
                fontWeight: 600,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginTime;
