import { Box, Typography, useMediaQuery, Theme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "../assets/images/AddIcon";
import ClockHeader from "../components/ClockHeader";
import RoundedButtons from "../components/Custom/RoundedButtons";
import ManageDisposeTable from "../components/Dispose/ManageDisposeTable";
import TablePagination from "../components/Table/TablePagination";
import { DISPOSITION_SERVICE } from "../services/DispositionService";
import { GetDispositionDTO } from "../services/dto/GetDispositionDTO";
import { ALL_ROUTES } from "../shared/routes";
import { RadioOptions } from "../components/Custom/CustomRadioButtonsGroup";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import { SearchBar } from "../components/SearchBar";

export const disposeLevels: RadioOptions[] = [
  { label: "Single Level", value: "single-level" },
  { label: "Multilevel", value: "multi-level" },
];

export const disposeTypes: RadioOptions[] = [
  { label: "Standard", value: "standard" },
  { label: "Sale", value: "sale" },
  { label: "Callback", value: "none" },
  { label: "DNC", value: "DNC" },
];

const ManageDispose = () => {
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const [dispositions, setDispositions] = useState<GetDispositionDTO[]>([]);
  const [dispositions_data, set_disposition_data] = useState<
    GetDispositionDTO[]
  >([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [loader, setLoader] = useState(false);
  const limit = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });

  function getDispositions() {
    setLoader(true);
    const onSuccess = ({
      info,
      dispositions,
    }: {
      info: any;
      dispositions: GetDispositionDTO[];
    }) => {
      setLoader(false);

      set_disposition_data(dispositions);
      setDispositions(dispositions);

      const count = info.count;
      const startCount = info.startIndex + 1;
      const endCount = info.startIndex + info.limit;
      const pages = Math.ceil(info.count / info.limit);
      setPagination((prev) => ({
        ...prev,
        count,
        startCount,
        endCount: count > endCount ? endCount : count,
        pages,
      }));
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    DISPOSITION_SERVICE.getDispositions(onSuccess, onError, {
      startIndex: (pagination.page - 1) * limit,
      limit,
      sortDirection: order,
      sort: orderBy,
    });
  }

  useEffect(() => {
    getDispositions();
  }, [pagination.page, order, orderBy]);

  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleSearch = (text: string) => {
    setSearch(text);

    const filteredData = dispositions_data.filter((parent) => {
      // Check if parent name matches search value
      if (parent.name.toLowerCase().includes(text)) {
        return true;
      }

      // Check if any child name matches search value
      const childMatch = parent.children.find((child) => {
        if (child.name.toLowerCase().includes(text)) {
          return true;
        }

        // Check if any grandchild name matches search value
        if (child.children) {
          return child.children.find((grandchild) =>
            grandchild.name.toLowerCase().includes(text)
          );
        }

        return false;
      });

      return Boolean(childMatch);
    });

    setDispositions(filteredData);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h5" marginRight={"20px"}>
            Manage Dispose
          </Typography>
          <SearchBar
            sx={{
              background: "white",
              border: "1px solid #96A1B3",
              marginTop: "5px",
            }}
            text={search}
            onChange={(text) => {
              handleSearch(text);
            }}
          />
        </Box>
        <Box display="flex" gap={2}>
          <RoundedButtons
            tooltip="Create Level 1 Dispose"
            color="#EB5757"
            title="Level 1"
            Icon={AddIcon}
            onClick={() => navigate(ALL_ROUTES.LEVEL_ONE_DISPOSE)}
          />
          <RoundedButtons
            tooltip="Create Level 2 Dispose"
            color="#0066FF"
            title="Level 2"
            Icon={AddIcon}
            onClick={() => navigate(ALL_ROUTES.LEVEL_TWO_DISPOSE)}
          />
          <RoundedButtons
            tooltip="Create Level 3 Dispose"
            color="#5ECE62"
            title="Level 3"
            Icon={AddIcon}
            onClick={() => navigate(ALL_ROUTES.LEVEL_THREE_DISPOSE)}
          />
        </Box>
      </Box>
      <Box style={{ marginTop: "20px" }}>
        <ManageDisposeTable
          dispositions={dispositions}
          onAction={getDispositions}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          loader={loader}
        />
        <TablePagination page={pagination} setPage={setPagination} />
      </Box>
    </>
  );
};

export default ManageDispose;
