import { Box, Typography } from "@mui/material";
import { formatDateTime } from "../../helper/dateFormatter";
import { FORMATTER } from "../../helper/formatter";

export const Timestamp = ({ date }: { date: Date }) => {
  const styledText = {
    fontSize: "10px",
    fontFamily: "Roboto",
    textAlign: "right",
    color: "#555",
  };

  return (
    <Box display={"flex"} flexDirection="column" pr="10px">
      <Typography sx={styledText}>{formatDateTime(date)}</Typography>
      <Typography sx={styledText}>
        {FORMATTER.formatTimeStamp(new Date(), date, "", "ago")}
      </Typography>
    </Box>
  );
};
