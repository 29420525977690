import { Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import EditIcon from "../../assets/images/EditIcon";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import { useAppDispatch } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import ContactDetailsPopUp from "../ContactDetails/ContactDetailsPopUp";
import CustomIconButton from "../Custom/CustomIconButton";
import LightTooltip from "../LightToolTip";

const EditLeadInfoButton = ({
  color = "white",
  onAction,
  callback,
  onOpen,
  onClose,
}: {
  color?: string;
  onAction?: () => void;
  callback: GetCallbackDTO;
  onOpen?: () => void;
  onClose?: () => void;
}) => {
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const handleClick = () => {
    dispatch(leadSlice.actions.setLead(callback.Lead));
    onOpen && onOpen();
    setOpenContactDetails(true);
  };
  return (
    <>
      <LightTooltip title="Edit Lead Info" placement="top">
        <CustomIconButton
          Icon={EditIcon}
          backgroundColor="#6F5FD9"
          sx={{
            width: mobileView ? 32 : 30,
            height: mobileView ? 32 : 30,
            color: color,
          }}
          onClick={handleClick}
        />
      </LightTooltip>
      <ContactDetailsPopUp
        open={openContactDetails}
        setOpen={setOpenContactDetails}
        title="Update Details"
        onAction={onAction}
        onClose={onClose}
      />
    </>
  );
};

export default EditLeadInfoButton;
