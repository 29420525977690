import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import SettingsActionIcon from "../../assets/images/SettingsActionIcon";

function SettingsDropDown() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <IconButton onClick={handleClick}>
        <SettingsActionIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
        <MenuItem sx={{ minWidth: 180 }}>CallerId</MenuItem>
        <MenuItem sx={{ minWidth: 180 }}>Prefix</MenuItem>
      </Menu>
    </>
  );
}

export default SettingsDropDown;
