import { createSlice } from "@reduxjs/toolkit";
import { TClientConfig } from "../types/TClientConfig";
import {
  CLIENT_CONFIG_REDUCERS,
  TClientConfigReducers,
} from "./clientConfigReducers";

export const clientConfigSlice = createSlice<
  TClientConfig,
  TClientConfigReducers
>({
  name: "client_config",
  initialState: {
    CLIENT_SOCKET_PORT: 0,
    CLIENT_SOCKET_URL: "",
    DIALPAD_DOMAIN: "",
    JSSIP_URI: "",
    JSSIP_WS: "",
    OTHER_SSP: "",
  },
  reducers: CLIENT_CONFIG_REDUCERS,
});
