import React from "react";

const SecondaryCallEndIcon = ({ color = "white" }: { color?: string }) => {
  return (
    <svg
      width="33"
      height="26"
      viewBox="0 0 33 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5734 6.54647L30.704 4.67709C24.1205 -1.74381 8.35276 -0.849761 2.66335 4.67709L0.79397 6.54647C0.306307 7.03413 0.306307 7.84691 0.79397 8.41585L4.53272 12.0733C5.02039 12.561 5.91444 12.561 6.4021 12.0733L10.5472 8.09074L10.2221 3.78304C11.5226 2.48261 21.9261 2.48261 23.2265 3.78304L22.9827 8.25329L26.9652 12.0733C27.4529 12.561 28.347 12.561 28.8346 12.0733L32.5734 8.41585C33.1423 7.84691 33.1423 7.03413 32.5734 6.54647Z"
        fill={color}
      />
      <path
        d="M16.7239 25.8102L10.2217 18.0889H23.226L16.7239 25.8102Z"
        fill={color}
      />
      <path
        d="M14.2856 12.3994H19.1623V18.4952H14.2856V12.3994Z"
        fill={color}
      />
    </svg>
  );
};

export default SecondaryCallEndIcon;
