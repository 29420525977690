import { TCampaignState } from "../types/campaign";
import { PayloadAction } from "@reduxjs/toolkit";

function setCampaign(
  state: TCampaignState,
  action: PayloadAction<TCampaignState>
) {
  return action.payload;
}

function update(
  state: TCampaignState,
  action: PayloadAction<{ key: any; value: any }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

export const CAMPAIGN_REDUCERS = {
  setCampaign,
  update,
};

export type TCampaignReducer = typeof CAMPAIGN_REDUCERS;
