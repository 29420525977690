export enum CallingModeAuto {
  AUTO = "AUTO",
  PREVIEW = "PREVIEW",
  MANUAL = "MANUAL",
}

export enum CallingMode {
  PREVIEW = "PREVIEW",
  MANUAL = "MANUAL",
}

export type TDefaultProcess = {
  ProcessId: number;
  callingMode: CallingModeAuto | null;
  clientId: number;
  ratio: number;
  running: boolean;
  startDateTime: string;
  endDateTime: string;
};
