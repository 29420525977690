import { PayloadAction } from "@reduxjs/toolkit";
import { CallStatus, TCallState } from "../types/call";
import { TLeadState } from "../types/lead";

function setCall(state: TCallState, action: PayloadAction<TCallState>) {
  return action.payload;
}

function updateCallId(state: TCallState, action: PayloadAction<number>) {
  const id = action.payload;
  return { ...state, callId: id };
}

function updateProcessId(state: TCallState, action: PayloadAction<number>) {
  const id = action.payload;
  return { ...state, processId: id };
}

function updateCallCode(state: TCallState, action: PayloadAction<string>) {
  return { ...state, callCode: action.payload };
}

function updateLeadDetails(
  state: TCallState,
  action: PayloadAction<TLeadState>
) {
  let leadDetails = state.lead;
  leadDetails = action.payload;
  return { ...state, lead: leadDetails };
}

function updateLeadId(state: TCallState, action: PayloadAction<number>) {
  const leadDetails = state.lead;

  return { ...state, lead: { ...leadDetails, id: action.payload } };
}

function updateCallStatus(
  state: TCallState,
  action: PayloadAction<CallStatus>
) {
  const newCallStatus = action.payload;
  return { ...state, callStatus: newCallStatus };
}
function updateCallOutcome(state: TCallState, action: PayloadAction<string>) {
  const newCallOutCome = action.payload;
  return { ...state, callOutCome: newCallOutCome };
}

function updateLead(
  state: TCallState,
  action: PayloadAction<{ key: any; value: any }>
) {
  const { key, value } = action.payload;
  const lead = { ...state.lead, [key]: value };
  return { ...state, lead: lead };
}

function updateCallType(state: TCallState, action: PayloadAction<string>) {
  return { ...state, call_type: action.payload };
}

type ValueTypes = keyof TCallState;
function updateDispositionCallData(
  state: TCallState,
  action: PayloadAction<{ key: ValueTypes; value: any }[]>
) {
  let updatedState: TCallState = { ...state };

  action.payload.forEach(({ key, value }) => {
    updatedState = { ...updatedState, [key]: value };
  });

  return updatedState;
}

export const PENDING_DISPOSITION_CALL_REDUCERS = {
  setCall,
  updateCallId,
  updateLeadDetails,
  updateCallCode,
  updateCallStatus,
  updateLead,
  updateProcessId,
  updateCallOutcome,
  updateCallType,
  updateLeadId,
  updateDispositionCallData,
};

export type TPenidngCallReducer = typeof PENDING_DISPOSITION_CALL_REDUCERS;
