import { createSlice } from "@reduxjs/toolkit";
import { TLeadState } from "../types/lead";
import { LEAD_REDUCERS, TLeadReducer } from "./leadReducers";

export const leadSlice = createSlice<TLeadState, TLeadReducer>({
  name: "lead",
  initialState: {
    id: 0,
    name: "",
    email: "",
    phone: "",
    secondary: "",
    dob: "",
    gender: "",
    address: "",
    createdOn: "",
    leadsetId: 0,
    configurations: {},
  },
  reducers: LEAD_REDUCERS,
});
