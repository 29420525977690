import Moment from "moment";
import { generate } from "@wcj/generate-password";
import moment from "moment";

export function add_day_in_date(date, days) {
  let new_date = Moment(date, "DD-MM-YYYY")
    .add(days, "day")
    .format("DD-MM-YYYY");
  return new_date;
}

export function add_months_in_date(date, months) {
  let new_date = Moment(date, "DD-MM-YYYY")
    .add(months, "month")
    .format("DD-MM-YYYY");
  return new_date;
}

export function add_years_in_date(date, years) {
  let new_date = Moment(date, "DD-MM-YYYY")
    .add(years, "year")
    .format("DD-MM-YYYY");
  return new_date;
}

export function date_diff_in_days(date1, date2) {
  var date_one = Moment(date1, "DD-MM-YYYY").format("YYYY-MM-DD");
  var date_two = Moment(date2, "DD-MM-YYYY").format("YYYY-MM-DD");
  var msDiff = new Date(date_one).getTime() - new Date(date_two).getTime(); //Future date - current date
  var diff = parseInt(Math.floor(msDiff / (1000 * 60 * 60 * 24)));

  return diff;
}

export function validateEmail(email) {
  var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
  return re.test(email);
}

export function validateMobileNumber(mobile_no) {
  if (mobile_no != undefined && mobile_no != null) {
    var mobile = mobile_no.toString().trim();
    if (
      mobile.toString().length == 10 &&
      parseInt(mobile.substring(0, 1)) >= 6
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function toDesiredFormat(value) {
  var date = new Date(value);
  var formatedDate = Moment(date).format("YYYY-MM-DD[T]HH:mm:ss.SSS");

  return formatedDate;
}

export function formatTimeHHHMMSSS(value) {
  let roundedValue = Math.round(value === undefined ? 0 : value);
  let time = moment.utc(roundedValue * 1000).format("HHH:mm:ss");

  return time;
}

export function generatePassword() {
  var randomstring = generate({ length: 8 });

  return randomstring;
}

export function calcualteDaysBwDates(from, to) {
  let from_date = moment(from);
  let to_date = moment(to);
  let duration = moment.duration(to_date.diff(from_date));
  let days = duration.asDays();

  return days;
}

export function inputDeviceStatus() {
  navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      const microphones = devices.filter(
        (device) => device.kind === "audioinput"
      );
      if (microphones.length === 0) {
        return false;
      } else {
        return true;
      }
    })
    .catch((error) => {
      console.error("Error while checking for microphone: ", error);
      return false;
    });
  return false;
}

export function formatDuration(duration) {
  if (isNaN(duration) || duration < 0) {
    return "00:00:00";
  }

  const totalSeconds = Math.floor(duration / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}
export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
