import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Dialog } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD_SERVICE } from "../../services/ChangePasswordService";
import { changePasswordDTO } from "../../services/dto/ChangePasswordDTO";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import FormActionButton from "../Actions/FormActionButton";
import RKTextField from "../EVCustoms/RKTextField/RKTextField";

const postCampaignDTO = {
  name: "",
  description: "",
  supervisorId: 0,
};

const ChangePasswordForm = ({
  supervisors,
}: {
  supervisors: GetUserResponseDTO[];
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>(
    "Please enter old password"
  );
  const [confStatus, setConfStatus] = useState<boolean>(false);
  const [formData, setFormData] = useState<{
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    warnOldPassword: boolean;
    warnNewPassword: boolean;
    warnConfirmNewPassword: boolean;
  }>({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    warnOldPassword: false,
    warnNewPassword: false,
    warnConfirmNewPassword: false,
  });

  const updateMasterState = (attrName: string, value: string) => {
    if (value.indexOf(" ") === -1) {
      setFormData((prevState) => {
        switch (attrName) {
          case "oldPassword":
            return {
              ...prevState,
              [attrName]: value,
              warnOldPassword: value.toString().length > 0 ? false : true,
            };
          case "newPassword":
            return {
              ...prevState,
              [attrName]: value,
              warnNewPassword: value.toString().length >= 8 ? false : true,
              warnConfirmNewPassword:
                prevState.confirmNewPassword === value ? false : true,
            };
          case "confirmNewPassword":
            return {
              ...prevState,
              [attrName]: value,
              warnConfirmNewPassword:
                value.toString().length >= 8 ? false : true,
              warnNewPassword: prevState.newPassword === value ? false : true,
            };
          default:
            return prevState;
        }
      });
    }
  };

  function changePasswordAPI() {
    setLoading(true);

    const onSuccess = (data: any) => {
      setLoading(false);
      const response = data.data;
      const errorStatus = response.errorStatus;
      if (errorStatus === true) {
        const message = response.message;

        setErrorMessage(message);

        setFormData((prevState) => ({
          ...prevState,
          warnOldPassword: true,
        }));
      } else {
        setConfStatus(true);
      }

      setTimeout(() => {
        navigate(-1);
      }, 2000);
    };

    const onError = (error: any) => {
      console.log(error);
      setLoading(false);
    };

    const dto: changePasswordDTO = {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    };
    CHANGE_PASSWORD_SERVICE.setNewPassowrd(onSuccess, onError, dto);
  }

  const handleSubmit = () => {
    const warnOldPassword =
      formData?.oldPassword.toString().length >= 8 ? false : true;
    let warnNewPassword = false;
    let warnConfirmNewPassword = false;

    if (formData?.newPassword !== formData?.confirmNewPassword) {
      warnNewPassword = true;
      warnConfirmNewPassword = true;
    } else {
      warnNewPassword = false;
      warnConfirmNewPassword = false;
    }
    warnNewPassword =
      formData?.newPassword.toString().length >= 8 ? false : true;
    warnConfirmNewPassword =
      formData?.confirmNewPassword.toString().length >= 8 ? false : true;

    setFormData((prevState) => ({
      ...prevState,
      warnOldPassword: warnOldPassword,
      warnNewPassword: warnNewPassword,
      warnConfirmNewPassword: warnConfirmNewPassword,
    }));

    if (
      warnOldPassword === false &&
      warnNewPassword === false &&
      warnConfirmNewPassword === false
    ) {
      changePasswordAPI();
    }
  };

  return (
    <>
      <Dialog
        open={confStatus}
        sx={{
          "& .css-6wzpae-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: "transparent",
            boxShadow:
              "0px 0px 0px 0px rgb(0 0 0 / 100%), 0px 0px 0px 0px rgb(0 0 0 / 100%), 0px 0px 0px 0px rgb(0 0 0 / 100%)",
          },
        }}>
        <div>
          <Player
            autoplay
            src="./JSON/green_check.json"
            style={{
              height: "170px",
              width: "300px",
            }}
          />
          <p
            style={{
              color: "#FFFFFF",
              width: "100%",
              textAlign: "center",
              position: "absolute",
              top: "128px",
            }}>
            Your password has been changed.
          </p>
        </div>
      </Dialog>
      <Box
        width={{ xs: "100%", md: "400px" }}
        p={{ xs: "10px 0 20px", md: "20px 0" }}>
        <RKTextField
          title="Old Password*"
          value={formData?.oldPassword}
          attrName={"oldPassword"}
          value_update={updateMasterState}
          container_style={{ marginTop: "10px" }}
          warn_status={formData?.warnOldPassword}
          error_messg={errorMessage}
          showPasswordToggle={true}
        />
        <RKTextField
          title="New Password*"
          value={formData?.newPassword}
          attrName={"newPassword"}
          value_update={updateMasterState}
          warn_status={formData?.warnNewPassword}
          error_messg={
            formData?.newPassword === formData?.confirmNewPassword
              ? "Please enter new password"
              : "Passwords do not match!"
          }
          showPasswordToggle={true}
        />
        <RKTextField
          title="Confirm New Password*"
          value={formData?.confirmNewPassword}
          attrName={"confirmNewPassword"}
          value_update={updateMasterState}
          warn_status={formData?.warnConfirmNewPassword}
          error_messg={
            formData?.newPassword === formData?.confirmNewPassword
              ? "Please confirm new password"
              : "Passwords do not match!"
          }
          showPasswordToggle={true}
        />
        <Box display="flex" gap={2} justifyContent="flex-start" p="10px 0">
          <FormActionButton
            title="Change"
            onClick={handleSubmit}
            variant="contained"
            loading={loading}
          />
          <FormActionButton
            title="Cancel"
            onClick={() => navigate(-1)}
            disabled={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default ChangePasswordForm;
