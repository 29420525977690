import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Button,
  CircularProgress,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CommentType, GetCommentDTO } from "../../services/dto/GetCommentDTO";
import { COMMENT_VISIBILITY } from "../../services/dto/PostCommentDTO";
import { LEAD_SERVICE } from "../../services/LeadService";
import { commentSlice } from "../../state/comment/commentSlice";
import { disposeCallSlice } from "../../state/dispose/disposeCallSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { SelectOption } from "../Custom/CustomSelect";
import LightTooltip from "../LightToolTip";
import CommentHeader from "./CommentHeader";
import { Note } from "./Note";
import TextBox from "./TextBox";
import VisibilityButton from "./VisibilityButton";

const CommentBox = ({
  setChecked,
  header = true,
  open = false,
  callId,
}: {
  setChecked: any;
  header?: boolean;
  open?: boolean;
  callId?: number;
}) => {
  const [comments, setComments] = useState<GetCommentDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const { lead } = useAppSelector((state) => state);
  const commentForm = useAppSelector((state) => state.comment);
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [pagination, setPagination] = useState({
    startIndex: 0,
    limit: 10,
  });

  function getComments() {
    if (lead.id === 0) return;
    const onSuccess = (data: GetCommentDTO[]) => {
      setComments(data);
      setLoading(false);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    LEAD_SERVICE.getComments(onSuccess, onError, lead.id.toString(), {
      startIndex: pagination.startIndex,
      limit: pagination.limit,
      sortDirection: "desc",
    });
  }

  useEffect(() => {
    if (!open) return;
    getComments();
  }, [pagination, open]);

  const handleInputChange = (key: string, value: string) => {
    dispatch(
      commentSlice.actions.updateComment({
        key,
        value,
      })
    );
  };

  function clearCommentForm() {
    dispatch(commentSlice.actions.resetComment());
  }

  function addComment() {
    const onSuccess = () => {
      clearCommentForm();
      getComments();

      dispatch(
        disposeCallSlice.actions.updateDisposeCall({
          key: "comments",
          value: "",
        })
      );
    };

    const onError = (err: any) => console.log(err);

    LEAD_SERVICE.addComment(
      onSuccess,
      onError,
      {
        comment: commentForm.comment,
        visibility: COMMENT_VISIBILITY.PUBLIC,
        callId: callId ? callId : null,
      },
      lead?.id.toString()
    );
  }

  const handleScroll = () => {
    if (comments.length !== pagination.limit) return;
    const commentWindow =
      document.getElementsByClassName("comment-box-window")[0];
    if (commentWindow.scrollTop === 0 && !loading) {
      setPagination((prev) => ({ ...prev, limit: prev.limit + 10 }));
    }
  };

  useEffect(() => {
    const commentWindow =
      document.getElementsByClassName("comment-box-window")[0];
    commentWindow.scrollTop =
      pagination.limit === 10
        ? commentWindow.scrollHeight
        : (commentWindow.scrollHeight / pagination.limit) * 10;
  }, [comments, pagination.limit]);

  const visibilityOptions: SelectOption[] = [
    { label: "Visible to everyone", value: COMMENT_VISIBILITY.PUBLIC },
    { label: "Visible to only me", value: COMMENT_VISIBILITY.PRIVATE },
  ];

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "372px" },
        maxHeight: "525px",
        borderRadius: "16px",
        position: { md: "fixed", xs: "relative" },
        bottom: { md: 70 },
        right: { md: 125 },
        margin: "0 auto",
        background: "white",
        boxShadow: { md: "-2px 5px 15px rgba(0, 0, 0, 0.15)" },
      }}>
      {header && <CommentHeader setChecked={setChecked} />}
      <div
        className="comment-box-window"
        onScroll={handleScroll}
        style={{
          height: mobileView ? "60vh" : "347px",
          width: mobileView ? "100%" : "378px",
          overflow: "scroll",
        }}>
        {loading && <CommentLoader />}
        {comments
          .sort(
            (first, second) =>
              new Date(first.createdOn).valueOf() -
              new Date(second.createdOn).valueOf()
          )
          .map((comment: GetCommentDTO) =>
            comment.type == CommentType.DISPOSITION ? (
              <TextBox comment={comment} key={comment.id} />
            ) : (
              <Note note={comment} key={comment.id} />
            )
          )}
      </div>
      <VisibilityButton
        options={visibilityOptions}
        onChange={(event: SelectChangeEvent) =>
          handleInputChange("visibility", event.target.value)
        }
      />
      <Box
        sx={{
          borderTop: "1px solid #EEEEEE",
          height: "90px",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}>
        <TextField
          multiline
          rows={4}
          sx={{ height: "90px" }}
          placeholder="Type your note here..."
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: {
              fontFamily: "Roboto, sans",
              padding: "8px 10px",
            },
          }}
          onKeyDown={(event: any) => {
            if (event.keyCode == 13 && event.target.value.trim().length > 0) {
              event.preventDefault();
              addComment();
            }
          }}
          onChange={(event: any) => {
            handleInputChange("comment", event.target.value);
            dispatch(
              disposeCallSlice.actions.updateDisposeCall({
                key: "comments",
                value: event.target.value,
              })
            );
          }}
          value={commentForm.comment}
        />
        <LightTooltip title="Submit Note">
          <Button
            onClick={() => {
              if (comments !== undefined) {
                if (comments.toString().trim().length > 0) {
                  addComment();
                }
              }
            }}
            sx={{
              height: "40px",
              width: "40px",
              borderRadius: "20px",
            }}>
            <SendOutlinedIcon />
          </Button>
        </LightTooltip>
      </Box>
    </Box>
  );
};

const CommentLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}>
      <CircularProgress size={34} />
    </Box>
  );
};

export default CommentBox;
