import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";

const CustomPageHeading = ({
  backButton = true,
  title,
  marginBottom = { xs: backButton ? "16px" : "22px", md: "30px" },
}: {
  backButton?: boolean;
  title: string;
  marginBottom?: any;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box mb={marginBottom} display={"flex"} alignItems="center" gap={"10px"}>
        {backButton && <BackButton onClick={() => navigate(-1)} />}
        <Typography
          variant="h5"
          sx={{ lineHeight: { xs: "20px", md: "32px" } }}
        >
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default CustomPageHeading;
