function PauseStatusIcon() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 0C15.9492 0 18.208 0.603516 20.2764 1.81055C22.3447 3.01758 23.9824 4.65527 25.1895 6.72363C26.3965 8.79199 27 11.0508 27 13.5C27 15.9492 26.3965 18.208 25.1895 20.2764C23.9824 22.3447 22.3447 23.9824 20.2764 25.1895C18.208 26.3965 15.9492 27 13.5 27C11.0508 27 8.79199 26.3965 6.72363 25.1895C4.65527 23.9824 3.01758 22.3447 1.81055 20.2764C0.603516 18.208 0 15.9492 0 13.5C0 11.0508 0.603516 8.79199 1.81055 6.72363C3.01758 4.65527 4.65527 3.01758 6.72363 1.81055C8.79199 0.603516 11.0508 0 13.5 0ZM13.5 23.0625C15.2344 23.0625 16.834 22.6348 18.2988 21.7793C19.7637 20.9238 20.9238 19.7637 21.7793 18.2988C22.6348 16.834 23.0625 15.2344 23.0625 13.5C23.0625 11.7656 22.6348 10.166 21.7793 8.70117C20.9238 7.23633 19.7637 6.07617 18.2988 5.2207C16.834 4.36523 15.2344 3.9375 13.5 3.9375C11.7656 3.9375 10.166 4.36523 8.70117 5.2207C7.23633 6.07617 6.07617 7.23633 5.2207 8.70117C4.36523 10.166 3.9375 11.7656 3.9375 13.5C3.9375 15.2344 4.36523 16.834 5.2207 18.2988C6.07617 19.7637 7.23633 20.9238 8.70117 21.7793C10.166 22.6348 11.7656 23.0625 13.5 23.0625ZM15.1875 19.125C15.0234 19.125 14.8887 19.0723 14.7832 18.9668C14.6777 18.8613 14.625 18.7266 14.625 18.5625V8.4375C14.625 8.27344 14.6777 8.13867 14.7832 8.0332C14.8887 7.92773 15.0234 7.875 15.1875 7.875H18.5625C18.7266 7.875 18.8613 7.92773 18.9668 8.0332C19.0723 8.13867 19.125 8.27344 19.125 8.4375V18.5625C19.125 18.7266 19.0723 18.8613 18.9668 18.9668C18.8613 19.0723 18.7266 19.125 18.5625 19.125H15.1875ZM8.4375 19.125C8.27344 19.125 8.13867 19.0723 8.0332 18.9668C7.92773 18.8613 7.875 18.7266 7.875 18.5625V8.4375C7.875 8.27344 7.92773 8.13867 8.0332 8.0332C8.13867 7.92773 8.27344 7.875 8.4375 7.875H11.8125C11.9766 7.875 12.1113 7.92773 12.2168 8.0332C12.3223 8.13867 12.375 8.27344 12.375 8.4375V18.5625C12.375 18.7266 12.3223 18.8613 12.2168 18.9668C12.1113 19.0723 11.9766 19.125 11.8125 19.125H8.4375Z"
        fill="white"
      />
    </svg>
  );
}

export default PauseStatusIcon;
