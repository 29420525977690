import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { FORMATTER } from "../../helper/formatter";
import { AgentCDRDTO } from "../../services/dto/GetAgentCdrDTO";
import { PaginationDTO } from "../../state/types/PaginationDTO";
import UGTableServerSorting from "../EVCustoms/UGTableServerSorting/UGTableServerSorting";

const headCells: GridColDef[] = [
  {
    field: "Sr.",
    headerName: "Sr.",
    width: 25,
    renderCell: (ValueFormatterParams: any) => {
      const { row } = ValueFormatterParams;
      return <Typography style={{ fontSize: "14px" }}>{row.sr_no}</Typography>;
    },
  },
  {
    field: "agentName",
    headerName: "Agent Name",
    width: 140,
    renderCell: (ValueFormatterParams: any) => {
      const { row } = ValueFormatterParams;
      return (
        <Typography style={{ fontSize: "14px" }}>
          {(row?.agentName?.length === 0 ? "No Name" : row?.agentName) +
            " (" +
            row?.agentUsername +
            ")"}
        </Typography>
      );
    },
  },
  { field: "leadName", headerName: "Customer Name", width: 130 },
  { field: "leadId", headerName: "Lead Id", width: 100 },
  { field: "leadsetName", headerName: "Leadset", width: 180 },
  {
    field: "createdOn",
    headerName: "Date",
    width: 200,
    renderCell: (ValueFormatterParams: any) => {
      const { row } = ValueFormatterParams;
      return (
        <Typography style={{ fontSize: "14px" }}>
          {moment(row.createdOn)
            .locale("en-IR")
            .format("ddd, DD MMM YYYY HH:mm:ss")}
        </Typography>
      );
    },
  },
  // {
  //   field: "callingMode",
  //   headerName: "Calling Mode",
  //   width: 115,
  // },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 125,
  },
  {
    field: "talktime",
    headerName: "Talktime",
    width: 85,
    renderCell: (ValueFormatterParams: any) => {
      const { row } = ValueFormatterParams;
      return (
        <Typography style={{ fontSize: "14px" }}>
          {FORMATTER.formatTimer(row.talktime)}
        </Typography>
      );
    },
  },
  { field: "campaignName", headerName: "Campaign", width: 95 },
  { field: "processName", headerName: "Process", width: 80 },
  {
    field: "currentDispose",
    headerName: "Dispose",
    width: 135,
  },
  {
    field: "callBackDetails",
    headerName: "Call Back Date",
    width: 127,
    renderCell: (ValueFormatterParams: any) => {
      const { row } = ValueFormatterParams;
      return (
        <Typography style={{ fontSize: "14px" }}>
          {row.callBackDetails === null
            ? ""
            : moment(row.callBackDetails)
                .locale("en-IR")
                .format("ddd, DD MMM YYYY HH:mm:ss")}
        </Typography>
      );
    },
  },
  { field: "comment", headerName: "Comment", width: 95 },
  // {
  //   field: "disconnectedBy",
  //   headerName: "Disconnected By",
  //   width: 140,
  // },
];

const AgentLoggerTable = ({
  cdrs,
  loader,
  setOrder,
  setOrderBy,
  pagination,
  setPagination,
}: {
  cdrs: AgentCDRDTO[];
  loader: boolean;
  setOrder: Function;
  setOrderBy: Function;
  pagination: PaginationDTO;
  setPagination: Function;
}) => {
  const ScreenHeight = window.innerHeight;
  return (
    <UGTableServerSorting
      header={headCells}
      data={cdrs}
      loader={loader}
      uniqueID="callId"
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      pagination={pagination}
      height={`calc(${ScreenHeight}px - 200px)`}
      setPagination={setPagination}
    />
  );
};

export default AgentLoggerTable;
