import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import CustomMultiSelect, {
  selectOptionsMapper,
} from "../../Custom/CustomMultiSelect";
import { CustomPopup } from "../../Custom/CustomPopup";
import ProcessCheckList from "../ProcessCheckList";
import FormActionButton from "../../Actions/FormActionButton";
import CustomCheckBox from "../../Custom/CustomCheckBox";
import { GetUserResponseDTO } from "../../../services/dto/GetUserResponseDTO";
import { USER_SERVICE } from "../../../services/UserService";
import { SelectOption } from "../../Custom/CustomSelect";
import { PROCESS_SERVICE } from "../../../services/ProcessService";
import { GetProcessDTO } from "../../../services/dto/GetProcessDTO";

const AgentSelectionPopup = ({
  open,
  setOpen,
  title,
  processId,
  onAction,
  selectedProcess,
}: {
  open: boolean;
  setOpen: Function;
  title?: string;
  processId: number | string;
  onAction?: () => void;
  selectedProcess?: GetProcessDTO;
}) => {
  const defaultValues = selectedProcess?.processAgents.map((agent) => ({
    label: agent.username,
    value: agent.id.toString(),
  }));
  return (
    <CustomPopup
      Header={() => AgentSelectionHeader(selectedProcess?.name ?? "")}
      open={open}
      setOpen={setOpen}
      Component={() =>
        AgentSelectionContent({
          setOpen,
          processId,
          onAction,
          defaultValues,
        })
      }
      minWidth="959px"
    />
  );
};

const AgentSelectionHeader = (title: string) => {
  return (
    <Typography fontSize={24}>
      {"Add/Remove Agent (Process: " + title + ")"}
    </Typography>
  );
};

const AgentSelectionContent = ({
  setOpen,
  processId,
  onAction,
  defaultValues,
}: {
  onAction?: () => void;
  setOpen: Function;
  processId: string | number;
  defaultValues?: SelectOption[];
}) => {
  const [selectedAgents, setSelectedAgents] = useState<SelectOption[]>(
    defaultValues ?? []
  );
  const [agents, setAgents] = useState<GetUserResponseDTO[]>([]);
  const [showProcesses, setShowProcesses] = useState(false);
  const [loading, setLoading] = useState(false);

  function getAgents() {
    const onSuccess = (data: any) => {
      const errorStatus = data.errorStatus;

      if (errorStatus === false) {
        const users: GetUserResponseDTO[] = data.data;
        setAgents(users);
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    USER_SERVICE.getActiveUsers(onSuccess, onError);
  }

  useEffect(() => {
    getAgents();
  }, []);

  function updateAgents() {
    const userIds = selectedAgents.map((agent) => {
      if (typeof agent.value === "number") return agent.value;
      return parseInt(agent.value);
    });
    const onSuccess = (data: any) => {
      setLoading(false);
      setOpen(false);
      onAction && onAction();
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    PROCESS_SERVICE.updateAgents(onSuccess, onError, processId.toString(), {
      userIds,
    });
  }

  return (
    <Box>
      <Box
        mt="-6px"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}>
        <CustomCheckBox title="Select All" paddingBottom="9px" />
      </Box>
      <CustomMultiSelect
        options={agents.map((agent: GetUserResponseDTO) => ({
          label: agent.username,
          value: agent.id,
        }))}
        selectedValue={
          selectedAgents !== null
            ? selectOptionsMapper("label", "value", selectedAgents)
            : []
        }
        onChange={setSelectedAgents}
        label="Select Agent To Add"
      />
      {showProcesses && (
        <Box>
          <Typography sx={{ color: "red", mt: 2, fontWeight: 400, mb: 1 }}>
            <b>Attention: </b>Please select default process as you are trying to
            add the agent in Multiple AUTO PROCESS
          </Typography>
          <ProcessCheckList />
        </Box>
      )}
      <Box
        mt={showProcesses ? "30px" : "90px"}
        mb="10px"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}>
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={updateAgents}
          loading={loading}
        />
        <FormActionButton
          title="Cancel"
          onClick={() => {
            setOpen(false);
          }}
          disabled={loading}
        />
      </Box>
    </Box>
  );
};

export default AgentSelectionPopup;
