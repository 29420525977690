function AddIcon({ color = "white" }: { color: string }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.65964 1.76316C9.65964 1.42815 9.52656 1.10686 9.28967 0.86997C9.05278 0.633082 8.73149 0.5 8.39648 0.5C8.06147 0.5 7.74018 0.633082 7.5033 0.86997C7.26641 1.10686 7.13333 1.42815 7.13333 1.76316V7.23684H1.65964C1.32463 7.23684 1.00334 7.36992 0.766455 7.60681C0.529567 7.8437 0.396484 8.16499 0.396484 8.5C0.396484 8.83501 0.529567 9.1563 0.766455 9.39319C1.00334 9.63008 1.32463 9.76316 1.65964 9.76316H7.13333V15.2368C7.13333 15.5719 7.26641 15.8931 7.5033 16.13C7.74018 16.3669 8.06147 16.5 8.39648 16.5C8.73149 16.5 9.05278 16.3669 9.28967 16.13C9.52656 15.8931 9.65964 15.5719 9.65964 15.2368V9.76316H15.1333C15.4683 9.76316 15.7896 9.63008 16.0265 9.39319C16.2634 9.1563 16.3965 8.83501 16.3965 8.5C16.3965 8.16499 16.2634 7.8437 16.0265 7.60681C15.7896 7.36992 15.4683 7.23684 15.1333 7.23684H9.65964V1.76316Z"
        fill={color}
      />
    </svg>
  );
}

export default AddIcon;
