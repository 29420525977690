import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI } from "./API";

const getPerformanceGauge = (
  onSuccess: Function,
  onError: Function,
  startDate: string | Date,
  endDate: string | Date
) => {
  GetAPI.call(
    URL_CONSTANTS.PERFORMANCE_GAUGE(startDate, endDate),
    (data: any) => {
      onSuccess(data.data);
    },
    {},
    onError
  );
};

// const exportPerfomaceGauge = (
//   onSuccess: Function,
//   onError: Function,
//   query: any
// ) => {
//   GetAPI.call(URL_CONSTANTS.EXPORT_PERFOMANCE_GAUGE),
//     onSuccess,
//     { responseType: "blob", params: query },
//     onError;
// };

function exportPerfomaceGauge(onSuccess: any, onError: any, query: any) {
  GetAPI.call(
    `${URL_CONSTANTS.EXPORT_PERFOMANCE_GAUGE}`,
    (data: any) => {
      onSuccess(data.data);
    },
    { responseType: "blob", params: query },
    onError
  );
}

export const PERFORMACNE_GAUGE_SERVICE = {
  getPerformanceGauge,
  exportPerfomaceGauge,
};
