import { PaginationDataDTO } from "./dto/PaginationDataDTO";

function mapPagination(info: PaginationDataDTO) {
  const count = info.count;
  const startCount = info.startIndex + 1;
  const endCount = info.startIndex + info.limit;
  const pages = Math.ceil(info.count / info.limit);
  return { count, startCount, endCount, pages };
}

export const PAGINATION_SERVICE = {
  mapPagination,
};
