import { Theme, useMediaQuery } from "@mui/material";
import ClockHeader from "../components/ClockHeader";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import CreateLeadForm from "../components/Leadset/CreateLeadForm";

const CreateLead = () => {
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title="Create Lead"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <CreateLeadForm />
    </>
  );
};

export default CreateLead;
