import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../shared/routes";

export const NotFound = ({}) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ALL_ROUTES.LOGIN);
  }, []);
  return <></>;
};
