import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { DISPOSITION_SERVICE } from "../../services/DispositionService";
import { GetDispositionDTO } from "../../services/dto/GetDispositionDTO";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import { DarkTooltip } from "../DarkToolTip";
import DataNotFound from "../DataNotfound/DataNotFound";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import TableProgress from "../Table/TableProgress";
import LightTooltip from "../LightToolTip";

const headCells = [
  { id: "name", label: "Dispose", allowSort: true, width: "1185px" },
  { id: "action", label: "Action", allowSort: false, width: "75px" },
];

const ManageDisposeTable = ({
  dispositions,
  onAction,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  loader,
}: {
  dispositions: GetDispositionDTO[];
  onAction: Function;
  order: "asc" | "desc";
  orderBy: string;
  setOrder: any;
  setOrderBy: any;
  loader: boolean;
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedDisposition, setSelectedDisposition] =
    useState<GetDispositionDTO>();
  const [loading, setLoading] = useState(false);

  const onClickDelete = (disposition: GetDispositionDTO) => {
    setSelectedDisposition(disposition);
    setOpenConfirmation(true);
  };

  const handleDelete = () => {
    setLoading(true);
    const onSuccess = () => {
      setOpenConfirmation(false);
      setLoading(false);
      onAction();
    };
    const onError = (err: any) => {
      console.log(err);
      setOpenConfirmation(false);
      setLoading(false);
    };
    DISPOSITION_SERVICE.deleteDisposition(
      onSuccess,
      onError,
      selectedDisposition?.id.toString() ?? ""
    );
  };
  return (
    <>
      <TableContainer sx={{ width: "100%" }}>
        <Table>
          <StyledTableHead headColor={"primary.main"}>
            {headCells.map((cell) => (
              <StyledTableCell
                key={cell.id}
                align={cell.id == "action" ? "center" : "left"}
                width={cell.width}
              >
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {loader ? (
            <TableProgress colSpans={6} />
          ) : dispositions.length === 0 ? (
            <DataNotFound colSpans={6} />
          ) : (
            <TableBody>
              {dispositions.map((disposition: GetDispositionDTO) => {
                return (
                  <Fragment key={disposition.id}>
                    <DispositionRow
                      onClick={onClickDelete}
                      disposition={disposition}
                    />
                    {disposition.children.length > 0 &&
                      disposition.children.map(
                        (dispositionLevelTwo: GetDispositionDTO) => (
                          <Fragment key={dispositionLevelTwo.id}>
                            <DispositionRow
                              onClick={onClickDelete}
                              disposition={dispositionLevelTwo}
                            />
                            {dispositionLevelTwo.children.length > 0 &&
                              dispositionLevelTwo.children.map(
                                (dispositionLevelThree) => (
                                  <DispositionRow
                                    onClick={onClickDelete}
                                    key={dispositionLevelThree.id}
                                    disposition={dispositionLevelThree}
                                  />
                                )
                              )}
                          </Fragment>
                        )
                      )}
                  </Fragment>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {openConfirmation && (
        <SimpleConfirmationPopUp
          value={selectedDisposition?.name ?? ""}
          action={"Delete"}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          onAction={handleDelete}
          loading={loading}
        />
      )}
    </>
  );
};

const DispositonTypeTag = ({
  type,
  callback,
}: {
  type: string;
  callback: boolean;
}) => {
  return (
    <DarkTooltip
      title={callback === true ? "Callback" : type}
      placement="right"
    >
      <Box
        sx={{
          height: "24px",
          width: "29px",
          backgroundColor: "#96A1B3",
          borderRadius: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ":hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography
          sx={{
            color: "common.white",
            fontSize: "14px",
            pt: "3px",
          }}
        >
          {callback === true ? "C" : type.slice(0, 1).toUpperCase()}
        </Typography>
      </Box>
    </DarkTooltip>
  );
};

const DispositionRow = ({
  onClick,
  disposition,
}: {
  onClick: Function;
  disposition: GetDispositionDTO;
}) => {
  const showTag =
    disposition.disposeType !== null &&
    disposition.disposeType.toLowerCase() !== "none" &&
    disposition.disposeType.toLowerCase() !== "standard";
  return (
    <StyledTableRow>
      <StyledTableCell
        sx={{
          padding: "0",
          color:
            disposition.level === 1
              ? "#EB5757"
              : disposition.level === 2
              ? "#0066FF"
              : "#5ECE62",
        }}
      >
        <Box
          sx={{
            height: "42px",
            display: "flex",
            alignItems: "center",
            pl:
              disposition.level === 1
                ? "10px"
                : disposition.level === 2
                ? "25px"
                : "40px",
            gap: "10px",
          }}
        >
          {disposition.name}
          {showTag || disposition.callback === true ? (
            <DispositonTypeTag
              type={disposition.disposeType ?? ""}
              callback={disposition.callback}
            />
          ) : null}
        </Box>
      </StyledTableCell>
      <StyledTableCell
        align="left"
        sx={{
          padding: "0",
          overflow: "visible",
        }}
      >
        <Box>
          {disposition.disposeType === "system" ? null : (
            <LightTooltip title="Delete disposition">
              <IconButton onClick={() => onClick(disposition)}>
                <LeadsetDeleteIcon
                  color={
                    disposition.level === 1
                      ? "#EB5757"
                      : disposition.level === 2
                      ? "#0066FF"
                      : "#5ECE62"
                  }
                />
              </IconButton>
            </LightTooltip>
          )}
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ManageDisposeTable;
