import { Box, Typography } from "@mui/material";
import { GetCommentDTO } from "../../services/dto/GetCommentDTO";
import { Timestamp } from "./Timestamp";

export const Note = ({ note }: { note: GetCommentDTO }) => {
  return (
    <>
      <Box display="flex" marginX={"12px"}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Typography fontSize={"10px"} fontFamily="Roboto" color={"#555"}>
            <b>{note.createdByUser.firstName || note.createdByUser.username}</b>{" "}
            added a note
          </Typography>
        </Box>
        <Timestamp date={new Date(note.createdOn)} />
      </Box>
      <Box
        sx={{
          marginX: "12px",
          marginBottom: 2,
          padding: "6px",
          backgroundColor: "#FFEEC3",
          position: "relative",
          borderRadius: "10px",
        }}>
        <Typography
          sx={{
            color: "black",
            fontSize: "13px",
            fontWeight: 400,
            fontFamily: "Roboto",
          }}>
          {note.comment}
        </Typography>
      </Box>
    </>
  );
};
