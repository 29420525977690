import React from "react";

const MediaPlayer = ({
  sx,
  hidden = false,
  src = "./images/music.mp3",
}: {
  sx?: React.CSSProperties;
  hidden?: boolean;
  src?: string;
}) => {
  return (
    <>
      <audio
        style={{ height: 30, width: 280, borderRadius: "15px", ...sx }}
        src={src}
        autoPlay
        hidden={hidden}
        controls
        controlsList="nodownload novolume"
      />
    </>
  );
};

export default MediaPlayer;
