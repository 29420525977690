import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VALIDATOR } from "../../helper/validator";
import { PostUserRequestDTO } from "../../services/dto/PostUserRequestDTO";
import { USER_SERVICE } from "../../services/UserService";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { userDetailsFormSlice } from "../../state/user-form/userFormSlice";
import FormActionButton from "../Actions/FormActionButton";
import CustomAutocomplete from "../Custom/CustomAutocomplete";
import CustomSelect from "../Custom/CustomSelect";
import CustomTextField from "../Custom/CustomTextField";
import { USER_DETAIL_CONSTANTS } from "./constants";
import CustomDatePicker from "./CustomDatePicker";
import CustomRadioButtonsGroup from "../Custom/CustomRadioButtonsGroup";
import FormRow from "./FormRow";

const UserDetailsDTO: PostUserRequestDTO = {
  firstName: "",
  lastName: "",
  username: "",
  panCard: "",
  aadharCard: "",
  accessLevel: "",
  gender: "",
  email: "",
  mobile: "",
  address: "",
  city: "",
  pincode: "",
  nationality: "",
  bloodGroup: "",
  qualification: "",
  dob: "",
  password: "",
};

const EditUserDetailsForm = () => {
  const navigate = useNavigate();
  const styledSelect = { height: "50px" };
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [errorDetails, setErrorDetails] = useState({
    ...UserDetailsDTO,
    confirmPassword: "",
  });
  const userDetailsForm = useAppSelector((state) => state.userDetailsForm);
  const dispatch = useAppDispatch();
  const mandatoryFields = [
    "username",
    "firstName",
    "password",
    "dob",
    "accessLevel",
  ];
  const [loading, setLoading] = useState(false);

  const setError = (key: string, error: boolean) => {
    setErrorDetails((prev) => ({
      ...prev,
      [key]: error ? "Invalid Format" : "",
    }));
  };

  const validateFields = (value: string, id: string, name: string) => {
    let isValid = true;
    if (value === "" || value === null) {
      if (mandatoryFields.includes(id ?? name)) {
        setErrorDetails((prev) => ({
          ...prev,
          [id ?? name]: "Error",
        }));
      } else {
        setErrorDetails((prev) => ({ ...prev, [id ?? name]: "" }));
      }
    } else {
      switch (id ?? name) {
        case "firstName":
          isValid = VALIDATOR.validateAlphabetical(value, 50);
          setError(id ?? name, !isValid);
          break;
        case "lastName":
          isValid = VALIDATOR.validateAlphabetical(value, 50);
          setError(id ?? name, !isValid);
          break;
        case "username":
          isValid = VALIDATOR.validateAlphaNumerical(value, 80);
          setError(id ?? name, !isValid);
          break;
        case "panCard":
          isValid = VALIDATOR.validatePanCard(value);
          setError(id ?? name, !isValid);
          break;
        case "aadharCard":
          isValid = VALIDATOR.validateAadharCard(value);
          setError(id ?? name, !isValid);
          break;
        case "email":
          isValid = VALIDATOR.validateEmail(value);
          setError(id ?? name, !isValid);
          break;
        case "mobile":
          isValid = VALIDATOR.validateMobile(value);
          setError(id ?? name, !isValid);
          break;
        case "pincode":
          isValid = VALIDATOR.validateNumerical(value, 6);
          setError(id ?? name, !isValid);
          break;
        default:
          isValid = true;
          setErrorDetails((prev) => ({ ...prev, [id ?? name]: "" }));
      }
    }
    return isValid;
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(userDetailsForm)) {
      if (mandatoryFields.includes(key) && value?.toString() === "") {
        setErrorDetails((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    for (const [key, value] of Object.entries(errorDetails)) {
      if (!mandatoryFields.includes(key) && value === "") continue;
      if (value.toString() !== "" || value == null) {
        isValid = false;
      }
    }
    return isValid;
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { name } = (event as SelectChangeEvent).target;
    const { value } = event.target;
    dispatch(
      userDetailsFormSlice.actions.updateUserDetailsForm({
        key: id ?? name,
        value: value,
      })
    );
    if (id === "address" || name === "city") return;
    validateFields(value, id, name);
  };

  function updateUser() {
    setLoading(true);
    const onSuccess = (res: any) => {
      setLoading(false);
      navigate(-1);
    };
    const onError = (err: any) => {
      setLoading(false);
      if (err?.response?.data?.statusCode === 400) {
        if (err?.response?.data?.message.includes("Username")) {
          setErrorDetails((prev) => ({ ...prev, username: "Already in use" }));
        }
        if (err?.response?.data?.message.includes("Email")) {
          setErrorDetails((prev) => ({
            ...prev,
            email: "Already in use",
          }));
        }
      }
    };
    USER_SERVICE.updateUser(
      onSuccess,
      onError,
      userDetailsForm,
      userDetailsForm.id.toString()
    );
  }

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    updateUser();
  };

  const clearForm = () => {
    // dispatch(
    //   userDetailsFormSlice.actions.setUserDetailsForm(
    //     userDetailsFormSlice.getInitialState()
    //   )
    // );
    // setSelectedDate(null);
  };

  return (
    <>
      {Object.entries(errorDetails).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}>
          Please fill all fields
        </Typography>
      ) : null}
      <FormRow sx={{ paddingTop: { xs: "10px", md: "20px" } }}>
        <CustomTextField
          error={errorDetails.firstName}
          onChange={handleInputChange}
          id="firstName"
          label="First Name"
          required
          fullWidth
          value={userDetailsForm.firstName}
          errorText={
            errorDetails.firstName === "Error" ? "" : errorDetails.firstName
          }
        />
        <CustomTextField
          error={errorDetails.lastName}
          onChange={handleInputChange}
          id="lastName"
          label="Last Name"
          fullWidth
          value={userDetailsForm.lastName}
          errorText={
            errorDetails.lastName === "Error" ? "" : errorDetails.lastName
          }
        />
        <CustomTextField
          id="username"
          label="Username"
          disabled
          fullWidth
          value={userDetailsForm.username}
        />
      </FormRow>
      <FormRow>
        <CustomTextField
          error={errorDetails.panCard}
          onChange={handleInputChange}
          id="panCard"
          label="Pan Card"
          fullWidth
          value={userDetailsForm.panCard}
          errorText={
            errorDetails.panCard === "Error" ? "" : errorDetails.panCard
          }
        />
        <CustomTextField
          error={errorDetails.aadharCard}
          onChange={handleInputChange}
          id="aadharCard"
          label="Aadhar Card"
          fullWidth
          value={userDetailsForm.aadharCard}
          errorText={
            errorDetails.aadharCard === "Error" ? "" : errorDetails.aadharCard
          }
        />
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            label="Select Access Level"
            values={["ADMIN", "AGENT"]}
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.accessLevel ?? ""}
            onChange={handleInputChange}
            name="accessLevel"
            error={Boolean(errorDetails.accessLevel)}
          />
        </Box>
      </FormRow>
      <Grid
        container
        spacing={3}
        display="flex"
        alignItems={"flex-start"}
        pt="10px"
        pb="12px">
        <Grid item md={4}>
          <CustomRadioButtonsGroup
            title="Gender"
            id="gender"
            selectedValue={userDetailsForm.gender}
            onChange={handleInputChange}
            error={Boolean(errorDetails.gender)}
            values={USER_DETAIL_CONSTANTS.genders}
          />
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            error={errorDetails.email}
            onChange={handleInputChange}
            id="email"
            label="Email"
            fullWidth
            value={userDetailsForm.email}
            errorText={errorDetails.email === "Error" ? "" : errorDetails.email}
          />
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            error={errorDetails.mobile}
            onChange={handleInputChange}
            id="mobile"
            label="Mobile"
            fullWidth
            value={userDetailsForm.mobile}
            errorText={
              errorDetails.mobile === "Error" ? "" : errorDetails.mobile
            }
          />
        </Grid>
      </Grid>
      <FormRow>
        <CustomTextField
          error={errorDetails.address}
          onChange={handleInputChange}
          id="address"
          label="Address"
          fullWidth
          value={userDetailsForm.address}
          errorText={
            errorDetails.address === "Error" ? "" : errorDetails.address
          }
        />
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            values={["Delhi", "Mumbai"]}
            label="City"
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.city}
            onChange={handleInputChange}
            name="city"
          />
        </Box>
        <CustomTextField
          error={errorDetails.pincode}
          onChange={handleInputChange}
          id="pincode"
          label="Pincode"
          fullWidth
          value={userDetailsForm.pincode}
          errorText={
            errorDetails.pincode === "Error" ? "" : errorDetails.pincode
          }
        />
      </FormRow>
      <FormRow>
        <Box sx={{ width: "100%" }}>
          <CustomAutocomplete
            id="nationality"
            onChange={(event: any, value: any) => {
              dispatch(
                userDetailsFormSlice.actions.updateUserDetailsForm({
                  key: "nationality",
                  value: value,
                })
              );
              if (value !== null) {
                setErrorDetails((prev) => ({ ...prev, nationality: "" }));
              } else {
                setErrorDetails((prev) => ({ ...prev, nationality: "Error" }));
              }
            }}
            data={USER_DETAIL_CONSTANTS.nationalities}
            error={Boolean(errorDetails.nationality)}
            label="Nationality"
            selectedValue={
              userDetailsForm.nationality ? userDetailsForm.nationality : ""
            }
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            values={USER_DETAIL_CONSTANTS.bloodGroups}
            label="Blood Group"
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.bloodGroup}
            onChange={handleInputChange}
            name="bloodGroup"
            error={Boolean(errorDetails.bloodGroup)}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <CustomSelect
            values={USER_DETAIL_CONSTANTS.qualifications}
            label="Qualification"
            sx={styledSelect}
            fullWidth
            selectedValue={userDetailsForm.qualification}
            onChange={handleInputChange}
            name="qualification"
            error={Boolean(errorDetails.qualification)}
          />
        </Box>
      </FormRow>
      <Grid
        container
        spacing={3}
        display="flex"
        alignItems={"flex-start"}
        pt="20px">
        <Grid item md={4}>
          <Box width="100%">
            <CustomDatePicker
              selected={selectedDate ?? new Date(userDetailsForm.dob)}
              setSelected={(date: Date) => {
                setSelectedDate(date);
                dispatch(
                  userDetailsFormSlice.actions.updateUserDetailsForm({
                    key: "dob",
                    value: date.toISOString(),
                  })
                );
                setErrorDetails({ ...errorDetails, dob: "" });
              }}
              error={Boolean(errorDetails.dob)}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            id="password"
            label="Password"
            fullWidth
            type="password"
            disabled
          />
        </Grid>
      </Grid>
      <Box display="flex" gap={2} justifyContent="center" p="20px 0">
        <FormActionButton
          title="Save"
          onClick={handleSubmit}
          variant="contained"
          loading={loading}
        />
        <FormActionButton
          title="Cancel"
          disabled={loading}
          onClick={() => navigate(-1)}
        />
        <FormActionButton
          title="Reset"
          disabled={loading}
          onClick={() => clearForm()}
        />
      </Box>
    </>
  );
};

export default EditUserDetailsForm;
