import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { GetCommentDTO } from "../../services/dto/GetCommentDTO";
import { LEAD_SERVICE } from "../../services/LeadService";
import { useAppSelector } from "../../state/hooks";
import CommentsTableLarge from "./CommentsTableLarge";
import CommentsTableMobile from "./CommentsTableMobile";

const CommentsTable = ({ open }: { open: boolean }) => {
  const { id } = useAppSelector((state) => state.lead);
  const [comments, setComments] = useState<GetCommentDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    startIndex: 0,
    limit: 10,
  });

  function getComments() {
    const onSuccess = (data: GetCommentDTO[]) => {
      setComments(data);
      setLoading(false);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
    };
    setLoading(true);
    LEAD_SERVICE.getComments(onSuccess, onError, id.toString(), {
      startIndex: pagination.startIndex,
      limit: pagination.limit,
    });
  }

  useEffect(() => {
    if (!open) return;
    getComments();
  }, [open, pagination.limit]);

  const handleScroll = (className: string) => {
    const element = document.getElementsByClassName(className)[0];
    const { scrollTop, scrollHeight, clientHeight } = element;
    const isDekstop = className.includes("large");
    const height = scrollHeight - clientHeight;
    if (comments.length !== pagination.limit) return;
    if (
      scrollTop === height ||
      (scrollTop + Number(isDekstop) === height && loading === false)
    ) {
      setPagination((prev) => ({ ...prev, limit: prev.limit + 10 }));
    }
  };

  return (
    <Box>
      <CommentsTableLarge
        comments={comments}
        onScroll={handleScroll}
        loading={loading}
      />
      <CommentsTableMobile
        comments={comments}
        onScroll={handleScroll}
        loading={loading}
      />
    </Box>
  );
};

export default CommentsTable;
