import { ALL_ROUTES } from "./../shared/routes";
import { useEffect } from "react";
import { useSocket } from "./../shared/hooks/useSocket";
import { useAuth } from "./../shared/hooks/useAuth";
import { useDispatch } from "react-redux";
import { userSlice } from "../state/user/userSlice";
import { UserAccessLevel } from "../state/types/user";
import { AUTH_SERVICE } from "./../services/AuthService";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const { socket } = useSocket();
  const { setAuthToken } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    socket?.disconnect();
    localStorage.clear();
    setAuthToken("");
    dispatch(
      userSlice.actions.setUserDetails({
        id: 0,
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        bridge: "",
        accessLevel: UserAccessLevel.AGENT,
        webrtc_password: "",
        webrtc_username: "",
      })
    );
    AUTH_SERVICE.logout(
      () => {},
      () => {}
    );
    setTimeout(() => {
      navigate(ALL_ROUTES.LOGIN);
    }, 3000);
  }, []);

  return <p>Redirecting....</p>;
};

export default Logout;
