import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import Skeleton from "react-loading-skeleton";
import { AGENT_DASHBOARD_SERVICE } from "../../services/AgentDashboardService";
import { useAppSelector } from "../../state/hooks";
import { TCampaigns } from "../../state/types/campaigns";
import { toDesiredFormat } from "../../SupportingFiles/HelpingFunction";
import SearchableDropdown from "../Custom/SearchableDropdown";
import UGDateRangePicker from "../EVCustoms/UGDateRangePicker/UGDateRangePicker";
import "./LeadsPieChart.css";

const LeadsPieChart = () => {
  const theme = useTheme();

  const optionsDonutChart = {
    pieSliceText: "value",
    pieHole: 0.4,
    is3D: false,
    legend: {
      position: "right",
      alignment: "center",
      textStyle: {
        color: "#666",
        fontSize: 8,
      },
    },
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [campaign, setCampaign] = useState<any>(null);
  const [process, setProcess] = useState<any>(null);

  const campaigns = useAppSelector((state) => state.campaigns);
  const [processData, setProcessData] = useState<any[]>([]);
  const [loader, setLoader] = useState(true);

  const [leadsData, setLeadsData] = useState<{
    leadTaken: number;
    workAbleLeads: number;
    averageDays: number;
    disposition: Array<Array<any>>;
  }>({ leadTaken: 0, workAbleLeads: 0, averageDays: 0, disposition: [] });

  const dispositionOptionsMapper = (dispositions: TCampaigns | undefined) => {
    const options: { label: string; value: number; processes: any[] }[] = [];
    if (dispositions === undefined) return options;
    dispositions.map((disposition) => {
      options.push({
        label: disposition.name,
        value: disposition.id,
        processes: disposition.processes,
      });
    });
    return options;
  };

  const loadLeadsTaken = () => {
    const onSuccess = (data: any) => {
      const errorStatus = data.errorStatus;

      if (errorStatus === false) {
        const dataResponse = data.data;
        const disposition = dataResponse.disposition;
        disposition.unshift({ name: "Name", count: "Count" });

        let disposition_data: Array<Array<any>> = [];
        disposition_data = disposition.map((item: any) => [
          item.name,
          item.count,
        ]);

        setLeadsData({
          leadTaken: dataResponse.leadTaken,
          averageDays: dataResponse.averageDays,
          workAbleLeads: dataResponse.workAbleLeads,
          disposition: disposition_data,
        });
      }
      setLoader(false);
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    setLoader(true);
    AGENT_DASHBOARD_SERVICE.getLeadsTaken(onSuccess, onError, {
      "filter-campaign": campaign?.value ?? "",
      "filter-process": process?.value ?? "",
      "filter-startDate":
        toDesiredFormat(startEndDate[0]).substring(0, 10) + "T00:00:00.000Z",
      "filter-endDate":
        toDesiredFormat(startEndDate[1]).substring(0, 10) + "T23:59:00.000Z",
    });
  };

  const [startEndDate, setStartEndDate] = useState([new Date(), new Date()]);

  useEffect(() => {
    if (startEndDate[0] !== null && startEndDate[1] !== null) {
      loadLeadsTaken();
    }
  }, [campaign, process, startEndDate]);

  return (
    <Grid container spacing={isMobile ? 2 : 4} sx={{ marginTop: "30px" }}>
      <Grid item xs={3}>
        <Typography variant="h6" fontSize={isMobile ? "16px" : "20px"}>
          Leads Taken
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          paddingRight: "20px",
          flexWrap: "wrap",
          zIndex: 999,
        }}>
        <SearchableDropdown
          size="small"
          sx={{ width: "20%", margin: "8px" }}
          label="Campaign"
          disabled={false}
          error={false}
          values={dispositionOptionsMapper(campaigns)}
          minWidth={"130px"}
          selectedValue={campaign}
          onChange={(value: any) => {
            setCampaign(value);
            setProcessData(value.processes);
            setProcess(null);
          }}
          onClear={() => {
            setCampaign(null);
            setProcessData([]);
            setProcess(null);
          }}
        />
        <SearchableDropdown
          size="small"
          sx={{ width: "20%", margin: "8px" }}
          label="Process"
          disabled={false}
          error={false}
          values={dispositionOptionsMapper(processData)}
          minWidth={"130px"}
          selectedValue={process}
          onChange={(value: any) => {
            setProcess(value);
          }}
          onClear={() => {
            setProcess(null);
          }}
        />
        <UGDateRangePicker
          sx={{ marginTop: "8px" }}
          value={startEndDate}
          setValue={setStartEndDate}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid item xs={12} md={2}>
            <Grid container spacing={0}>
              <Grid item xs={4} md={12}>
                <Box mt={isMobile ? "20px" : "40px"} width="100%">
                  {loader ? (
                    <Skeleton
                      baseColor="rgba(0,0,0,0.2)"
                      width="80px"
                      height={"25px"}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                  ) : (
                    <Typography fontSize={isMobile ? "24px" : "30px"}>
                      {leadsData.leadTaken === null ? 0 : leadsData.leadTaken}
                    </Typography>
                  )}
                  <Typography fontSize={isMobile ? "12px" : "16px"}>
                    Total Leads
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} md={12}>
                <Box mt={isMobile ? "20px" : "40px"} width="100%">
                  {loader ? (
                    <Skeleton
                      baseColor="rgba(0,0,0,0.2)"
                      width="80px"
                      height={"25px"}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                  ) : (
                    <Typography fontSize={isMobile ? "24px" : "30px"}>
                      {leadsData.workAbleLeads === null
                        ? 0
                        : leadsData.workAbleLeads}
                    </Typography>
                  )}
                  <Typography fontSize={isMobile ? "12px" : "16px"}>
                    Workable Leads
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} md={12}>
                <Box mt={isMobile ? "20px" : "40px"} width="100%">
                  {loader ? (
                    <Skeleton
                      baseColor="rgba(0,0,0,0.2)"
                      width="80px"
                      height={"25px"}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                  ) : (
                    <Typography fontSize={isMobile ? "24px" : "30px"}>
                      {leadsData.averageDays === null
                        ? 0
                        : leadsData.averageDays}
                    </Typography>
                  )}
                  <Typography fontSize={isMobile ? "12px" : "16px"}>
                    Average / day
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={0} md={1.5} />
          <Grid
            className="pieChart"
            item
            xs={12}
            md={8.5}
            justifyContent="flex-start">
            {loader ? (
              <div
                style={{
                  height: "285px",
                  width: "285px",
                  borderRadius: "500px",
                  backgroundColor: "rgba(0,0,0,0.15)",
                  marginLeft: "20px",
                  marginTop: "50px",
                }}
              />
            ) : leadsData.disposition.length > 1 ? (
              <Chart
                chartType="PieChart"
                height="400px"
                width="500px"
                data={leadsData.disposition}
                options={optionsDonutChart}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadsPieChart;
