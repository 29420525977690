import { MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import PauseIcon from "../../assets/images/dispose-menu/PauseIcon";
import RedialIcon from "../../assets/images/dispose-menu/RedialIcon";
import StopIcon from "../../assets/images/dispose-menu/StopIcon";
import { CALL_SERVICE } from "../../services/CallService";
import { PREVIEW_SERVICE } from "../../services/PreviewService";
import { AUTO_CALL } from "../../shared/constants";
import { callSlice } from "../../state/call/callSlice";
import { callbackLogsSlice } from "../../state/callbackLogs/callbackLogsSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import { pendingDispositionCallSlice } from "../../state/pendingDispositionCall/pendingDispositionCallSlice";
import { CallStatus } from "../../state/types/call";
import { CallingModeAuto } from "../../state/types/defaultProcess";
import { TLeadState } from "../../state/types/lead";
import { UserStateStatus } from "../../state/types/user";
import { userSlice } from "../../state/user/userSlice";
import CustomMenuButton from "../Custom/CustomMenuButton";

function DisposeActions(
  onSuccess: (callback?: () => void) => void,
  handleBreak?: Function
) {
  const dispatch = useAppDispatch();

  const lead = useAppSelector((state) => state.lead);
  const call = useAppSelector((state) => state.call);

  const redial_call = () => {
    const onSuccess = (data: any) => {
      const response = data.data;

      const leadData = response.leadData;
      const callCode = leadData.callCode;
      const leadId = response.leadId;

      const processId = response.processId;

      dispatch(
        callSlice.actions.updateCallData([
          { key: "callCode", value: callCode },
          { key: "processId", value: processId },
          { key: "callId", value: response.callId },
          { key: "call_type", value: "redial" },
        ])
      );
      dispatch(
        callSlice.actions.updateLead({
          key: "id",
          value: leadId,
        })
      );
    };
    const onError = () => {
      dispatch(
        callSlice.actions.updateCallData([
          { key: "callStatus", value: CallStatus.IDLE },
        ])
      );
    };

    dispatch(
      callSlice.actions.updateCallData([
        { key: "callStatus", value: CallStatus.CALL_CREATED },
      ])
    );
    dispatch(userSlice.actions.updateUserStatus(UserStateStatus.REDIAL_CALL));

    const dto = {
      leadId: lead.id,
      processId: call.processId,
    };
    CALL_SERVICE.redialCall(onSuccess, onError, dto);
  };

  return (
    <>
      <Box>
        <MenuItem
          onClick={() => {
            onSuccess(() => {
              handleBreak && handleBreak();
            });
          }}>
          <Typography mr="20px">Dispose &#38; Pause</Typography>
          <PauseIcon />
        </MenuItem>
        <MenuItem
          sx={{ paddingY: "20px" }}
          onClick={() => {
            onSuccess(() => {
              dispatch(userSlice.actions.goToStop());
            });
          }}>
          <Typography mr="30px">Dispose &#38; Stop here</Typography>
          <StopIcon />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSuccess(() => {
              redial_call();
            });
          }}>
          <Typography mr="15px">Dispose &#38; Re-dial</Typography>
          <RedialIcon />
        </MenuItem>
      </Box>
    </>
  );
}
const DisposeMenu = ({
  validate,
  handleBreak,
  callbackStatus,
}: {
  validate: () => boolean;
  handleBreak?: Function;
  callbackStatus: boolean;
}) => {
  const defaultProcess = useAppSelector((state) => state.defaultProcess);
  const { call, disposeCall, pending_disposition_call } = useAppSelector(
    (state) => state
  );
  const { upcomingCallbackLogs, missedCallbackLogs } = useAppSelector(
    (state) => state.callbackLogs
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function handleDisposeCall(callback?: () => void) {
    if (validate() === false) return;

    const onSuccess = (data: any) => {
      const response = data.data;
      dispatch(
        pendingDispositionCallSlice.actions.updateDispositionCallData([
          { key: "callId", value: 0 },
        ])
      );
      dispatch(callSlice.actions.updateCallType(""));

      // Add data in redux if user arrange a callback
      if (callbackStatus === true) {
        const newState = [...upcomingCallbackLogs, response];
        dispatch(callbackLogsSlice.actions.updateUpcomingCallbacks(newState));
      }

      callback && callback();
    };
    const onError = (err: any) => {
      console.log({ err });
      setLoading(false);
    };
    setLoading(true);

    const disposition_data: any = disposeCall.dispositionId;
    const data = {
      callBackTime: disposeCall.callBackTime,
      comments: disposeCall.comments,
      dispositionId: disposition_data.value,
    };
    if (callbackStatus === false) {
      data.callBackTime = null;
    }

    CALL_SERVICE.disposeCall(
      onSuccess,
      onError,
      pending_disposition_call.callId === 0
        ? call.callId
        : pending_disposition_call.callId,
      data
    );
  }

  const checkCallbackAndPreview = () => {
    const onSuccess = (data: any) => {
      // setLoading(false);
      const callback: boolean = data.callback;
      const lead: TLeadState = data.lead;

      dispatch(callSlice.actions.updateCallType(""));

      if (callback === true && AUTO_CALL.AUTO_CALLING === true) {
        dispatch(userSlice.actions.goToReady());
      } else {
        if (lead !== null) {
          dispatch(leadSlice.actions.setLead(lead));
          dispatch(userSlice.actions.updateUserStatus(UserStateStatus.PREVIEW));
        } else {
          dispatch(userSlice.actions.goToReady());
        }
      }
    };
    const onError = (err: any) => {
      console.log(err);
      setLoading(false);
      dispatch(callSlice.actions.updateCallType(""));
      dispatch(userSlice.actions.goToReady());
    };

    PREVIEW_SERVICE.getNextLead(onSuccess, onError);
  };

  return (
    <CustomMenuButton
      title={"Dispose"}
      Options={() => DisposeActions(handleDisposeCall, handleBreak)}
      onClick={() => {
        handleDisposeCall(() => {
          setLoading(true);
          switch (defaultProcess.callingMode) {
            case CallingModeAuto.PREVIEW:
              {
                checkCallbackAndPreview();
              }
              break;
            default: {
              dispatch(callSlice.actions.updateCallType(""));
              dispatch(userSlice.actions.goToReady());
            }
          }
        });
      }}
      loading={loading}
    />
  );
};

export default DisposeMenu;
