import React from "react";

import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import "./DatePicker.css";

interface Props {
  title: string;
  value: string | Date | null;
  attrName: any;
  value_update: Function;
  error_message?: string;
  warn_status?: any;
  class_name?: string;
  min_date?: Date | null;
  max_date?: Date | null;
  sx?: any;
  mandatory?: boolean;
}

const DatePicker: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  error_message,
  warn_status,
  class_name,
  min_date,
  max_date,
  sx,
  mandatory,
}) => {
  return (
    <div>
      <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          minDate={min_date}
          maxDate={max_date}
          label={
            <span className={mandatory ? "red-asterisk mandatory" : ""}>
              {title}
            </span>
          }
          value={value}
          onChange={(e) => {
            value_update(attrName, e);
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              error={warn_status}
              sx={{
                svg: { color: "#000000" },
                "& .MuiInputBase-input": {
                  height: "23px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "6px",
                    ...sx,
                  },
                },
              }}
            />
          )}
          inputFormat="dd/MM/yyyy"
        />
      </LocalizationProvider>
      {/* {warn_status == true ? (
        <span className="error">{error_message}</span>
      ) : null} */}
    </div>
  );
};

export default DatePicker;
