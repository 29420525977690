import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import UGTable from "../EVCustoms/UGTable/UGTable";

const MissedCallCDRTable = ({
  data,
  loader,
}: {
  data: any[];
  loader: boolean;
}) => {
  const headCells: GridColDef[] = [
    {
      field: "sr",
      headerName: "Sr.",
      width: 40,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Typography style={{ fontSize: "14px" }}>{row.sr_no}</Typography>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 150,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Typography style={{ fontSize: "14px" }}>
            {(row.firstName.length === 0 ? "No Name" : row.firstName + " ") +
              row.lastName +
              " (" +
              row.username +
              ")"}
          </Typography>
        );
      },
    },
    { field: "did", headerName: "Did Number", width: 150 },
    { field: "campaign", headerName: "Campaign", width: 150 },
    { field: "process", headerName: "Process", width: 150 },
    { field: "reason", headerName: "Reason", width: 220 },
    { field: "queueTime", headerName: "In Queue Time", width: 150 },
    { field: "time", headerName: "Time", width: 150 },
    {
      field: "lastAgent",
      headerName: "Last Agent",
      width: 150,
    },
    { field: "disposeName", headerName: "Dispose Name", width: 150 },
  ];
  return (
    <>
      <UGTable header={headCells} data={data} loader={loader} />
    </>
  );
};

export default MissedCallCDRTable;
