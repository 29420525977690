import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import StyledTableSortLabel from "../Table/StyledTableSortLabel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TableProgress from "../Table/TableProgress";
import DataNotFound from "../DataNotfound/DataNotFound";
import { RecordingLogDTO } from "../../services/dto/GetRecordingDTO";
import { FORMATTER } from "../../helper/formatter";
import LightTooltip from "../LightToolTip";
import MediaPlayer from "../LeadInformation/MediaPlayer";
import React, { useState } from "react";
import PlayCirlcleIcon from "../../assets/images/PlayCirlceIcon";

const headCells = [
  { id: "sr", label: "Sr.", allowSort: false, width: "25px" },
  { id: "customerName", label: "Customer", allowSort: false, width: "120px" },
  { id: "leadId", label: "Lead ID", allowSort: true, width: "90px" },
  { id: "agentId", label: "Agent", allowSort: true, width: "120px" },
  {
    id: "callingMode",
    label: "Calling Mode",
    allowSort: false,
    width: "110px",
  },
  { id: "directions", label: "Directions", allowSort: false, width: "100px" },
  { id: "phone", label: "Phone Number", allowSort: false, width: "120px" },
  { id: "action", label: "Action", allowSort: false, width: "70px" },
  { id: "processId", label: "Process", allowSort: true, width: "90px" },
  { id: "campaignId", label: "Campaign", allowSort: true, width: "100px" },
  { id: "leadset", label: "Leadset", allowSort: false, width: "80px" },
  { id: "talkTime", label: "Agent Talktime", allowSort: false, width: "120px" },
  { id: "date", label: "Date", allowSort: false, width: "75px" },
  { id: "time", label: "Time", allowSort: false, width: "80px" },
  {
    id: "disposition",
    label: "Current Disposition",
    allowSort: false,
    width: "150px",
  },
  {
    id: "callbackDate",
    label: "Call-Back Date",
    allowSort: false,
    width: "120px",
  },
  { id: "comment", label: "Comment", allowSort: false, width: "120px" },
  {
    id: "disconnectedBy",
    label: "Disconnected By",
    allowSort: false,
    width: "130px",
  },
];

const RecordingLogTable = ({
  recordings,
  startIndex,
  recordingLogLoader,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}: {
  recordings: RecordingLogDTO[];
  startIndex: number;
  recordingLogLoader: boolean;
  order: "asc" | "desc";
  orderBy: string;
  setOrder: Function;
  setOrderBy: Function;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedRecording, setSelectedRecording] =
    useState<RecordingLogDTO | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    recording: RecordingLogDTO
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecording(recording);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <TableContainer>
        <Table>
          <StyledTableHead headColor="primary.main">
            {headCells.map((cell, i) => (
              <StyledTableCell key={cell.id} minWidth={headCells[i].width}>
                {!cell.allowSort ? (
                  cell.label
                ) : (
                  <StyledTableSortLabel
                    cell={cell}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                  />
                )}
              </StyledTableCell>
            ))}
          </StyledTableHead>
          {recordingLogLoader ? (
            <TableProgress colSpans={18} />
          ) : recordings.length === 0 ? (
            <DataNotFound colSpans={18} />
          ) : (
            <TableBody>
              {recordings.map((recording: RecordingLogDTO, index: number) => (
                <StyledTableRow key={index} minHeight="46px">
                  <StyledTableCell width={headCells[0].width}>
                    {startIndex == 0 && index < 9
                      ? "0" + (index + 1)
                      : startIndex + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[1].width}>
                    {recording?.customerName}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[2].width}>
                    {recording?.leadId}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[3].width}>
                    {recording?.agent?.firstName
                      ? `${recording?.agent?.firstName} ${recording?.agent?.lastName}`
                      : recording?.agent?.username}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[4].width}>
                    {recording?.callingMode}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[5].width}>
                    {recording?.direction}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[6].width}>
                    {recording?.phoneNumber}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[7].width}>
                    <Box
                      gap={"10px"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent="flex-start">
                      <LightTooltip title="Play Recording" placement="bottom">
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleClick(event, recording)
                          }
                          sx={{ padding: "0" }}>
                          <PlayCirlcleIcon width="16" height="16" />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip
                        title="Download Recording"
                        placement="bottom">
                        <a href={recording.recordingUrl} download>
                          <IconButton sx={{ p: 0 }}>
                            <CloudDownloadIcon
                              sx={{ fontSize: "18px", color: "#61C048" }}
                            />
                          </IconButton>
                        </a>
                      </LightTooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell width={headCells[8].width}>
                    {recording?.process?.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[9].width}>
                    {recording?.campaign?.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[10].width}>
                    {recording?.leadset?.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[11].width}>
                    {FORMATTER.formatTimer(recording?.talkTime)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[12].width}>
                    {recording?.createdOn &&
                      FORMATTER.formatDate(recording?.createdOn)}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[13].width}>
                    {recording?.createdOn.split("T")[1].split(".")[0]}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[14].width}>
                    {recording?.disposition?.name}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[15].width}>
                    {recording?.callBackDetails?.dateTime &&
                      FORMATTER.formatDate(
                        recording?.callBackDetails?.dateTime
                      )}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[16].width}>
                    {recording?.comment}
                  </StyledTableCell>
                  <StyledTableCell width={headCells[17].width}>
                    {recording?.disconnectedBy}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Popover
        sx={{
          ".MuiPopover-paper": {
            background: "none",
            boxShadow: "none",
            paddingRight: 5,
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}>
        <MediaPlayer src={selectedRecording?.recordingUrl} />
      </Popover>
    </>
  );
};

export default RecordingLogTable;
