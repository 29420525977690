import React, { useEffect, useState } from "react";
import LightTooltip from "../../LightToolTip";
interface Props {
  toggleStatus: string | boolean;
  value_update: Function;
  firstOption?: string;
  secondOption?: string;
  firstOptionColor?: string;
  secondOptionColor?: string;
  secondOptionTooltip?: string;
  firstOptionTooltip?: string;
}

const defaultProps: Props = {
  firstOption: "In-Active",
  secondOption: "Active",
  firstOptionColor: "#FF0000",
  secondOptionColor: "#32CD32",
  value_update: () => {},
  toggleStatus: "",
  secondOptionTooltip: "",
  firstOptionTooltip: "",
};

const ToggleButton: React.FC<Props> = ({
  toggleStatus,
  value_update,
  firstOption,
  secondOption,
  firstOptionColor,
  secondOptionColor,
  firstOptionTooltip,
  secondOptionTooltip,
}) => {
  return (
    <div
      style={{
        width: 100,
        borderWidth: 2,
        borderColor: "#000000",
        display: "flex",
        flexDirection: "row",
        borderRadius: 50,
        backgroundColor: "rgba(0,0,0,0.1)",
        height: 25,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <LightTooltip title={firstOptionTooltip}>
        <p
          onClick={() => {
            value_update(false);
          }}
          style={{
            width: "50%",
            marginTop: 0,
            height: 25,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 1,
            fontSize: 10,
            backgroundColor:
              toggleStatus === "DISABLED" || toggleStatus === false
                ? firstOptionColor
                : "transparent",
            color:
              toggleStatus === "DISABLED" || toggleStatus === false
                ? "#fff"
                : "#000",
            cursor: "pointer",
          }}
        >
          {firstOption}
        </p>
      </LightTooltip>
      <LightTooltip title={secondOptionTooltip}>
        <p
          onClick={() => {
            value_update(true);
          }}
          style={{
            width: "50%",
            marginTop: 0,
            height: 25,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 1,
            fontSize: 10,
            backgroundColor:
              toggleStatus === "ENABLED" || toggleStatus === true
                ? secondOptionColor
                : "transparent",
            color:
              toggleStatus === "ENABLED" || toggleStatus === true
                ? "#fff"
                : "#000",
            cursor: "pointer",
          }}
        >
          {secondOption}
        </p>
      </LightTooltip>
    </div>
  );
};

ToggleButton.defaultProps = defaultProps;
export default ToggleButton;
