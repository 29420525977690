import { Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CheckBoxCheckedIcon from "../../assets/images/CheckBoxCheckedIcon";
import CheckBoxIcon from "../../assets/images/CheckBoxIcon";

const CustomCheckBox = ({
  disabled,
  checked,
  id,
  onChange,
  title,
  paddingBottom = "11px",
  sx,
}: {
  disabled?: boolean;
  checked?: boolean;
  id?: string;
  onChange?: (event: any) => void;
  sx?: object | React.CSSProperties;
  paddingBottom?: string;
  title?: string;
}) => {
  return (
    <>
      {" "}
      <Box display={"flex"} alignItems={"center"} pb={paddingBottom}>
        <Checkbox
          disabled={disabled}
          checked={checked}
          id={id}
          onChange={onChange}
          icon={<CheckBoxIcon />}
          checkedIcon={<CheckBoxCheckedIcon />}
          sx={{ marginLeft: "-10px", ...sx }}
        />

        {title && (
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Roboto, sans",
              marginBottom: "-2px",
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default CustomCheckBox;
