import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogTitle, Divider } from "@mui/material";
import CloseIcons from "../Actions/CloseIcons";
import { Box } from "@mui/material";
import BackButton from "./BackButton";

export const CustomPopup2 = ({
  open,
  setOpen,
  Header,
  Component,
  maxWidth,
  hideCloseButton = false,
  backButton = false,
  borderRadius = "20px",
  minWidth,
  minHeight,
  sx,
  divider = true,
  visibility = true,
  fullScreen,
  headerPaddingBottom = "16px",
  onClose,
}: any) => {
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen ?? mobileView}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: visibility ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "visible",
        ".MuiPaper-root": {
          maxWidth: maxWidth,
          minWidth: { xs: "100vw", md: minWidth },
          borderRadius: { md: borderRadius, xs: 0 },
          width: "100%",
          maxHeight: "85%",
          overflowY: "visible",
          minHeight: minHeight,
        },
      }}>
      <Box style={{ height: "100%" }}>
        {Header && (
          <DialogTitle
            sx={{
              textAlign: "center",
              marginTop: { md: 0, xs: 5 },
              paddingX: { xs: 2, md: 3 },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: headerPaddingBottom,
            }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: backButton ? "flex" : "block",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}>
                {backButton && <BackButton onClick={handleClose} />}
                <Box>{Header}</Box>
              </Box>
            </Box>
            {!hideCloseButton && (
              <CloseIcons onClick={handleClose}></CloseIcons>
            )}
          </DialogTitle>
        )}
        {divider && (
          <Divider
            sx={{ margin: "0 15px", borderColor: "rgba(0, 0, 0, 0.1)" }}
          />
        )}

        <DialogContent
          sx={{
            "::-webkit-scrollbar": {
              width: { md: "6px", xs: 0 },
            },
            // overflowX: "hidden",
            padding: "16px",
            maxHeight: "calc(100% - 90px)",
            overflowY: "scroll",
            ...sx,
          }}>
          <Component />
        </DialogContent>
      </Box>
    </Dialog>
  );
};
