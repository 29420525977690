import React, { useState } from "react";
import { CallProvider } from "../lib/CallProvider";
import { CallProviderContext } from "../shared/context/CallProviderContext";

const CallProviderContainer = ({ children }: { children: React.ReactNode }) => {
  const [callProvider, setCallProvider] = useState<CallProvider | null>(null);
  return (
    <CallProviderContext.Provider value={{ callProvider, setCallProvider }}>
      {children}
    </CallProviderContext.Provider>
  );
};

export default CallProviderContainer;
