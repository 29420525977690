import { CircularProgress, TableBody, TableCell } from "@mui/material";
import StyledTableRow from "./StyledTableRow";

function TableProgress({ colSpans }: { colSpans: number }) {
  return (
    <>
      <TableBody>
        <StyledTableRow>
          <TableCell colSpan={colSpans} align="center" sx={{ border: "none" }}>
            <CircularProgress />
          </TableCell>
        </StyledTableRow>
      </TableBody>
    </>
  );
}

export default TableProgress;
