import { Theme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditCampaignForm from "../components/Campaigns/EditCampaignForm";
import ClockHeader from "../components/ClockHeader";
import CustomPageHeading from "../components/Custom/CustomPageHeading";
import { GetUserResponseDTO } from "../services/dto/GetUserResponseDTO";
import { USER_SERVICE } from "../services/UserService";

const EditCampaign = () => {
  const navigate = useNavigate();
  const [supervisors, setSupervisors] = useState<GetUserResponseDTO[]>([]);

  const getSupervisors = () => {
    const onSuccess = ({ users }: { users: GetUserResponseDTO[] }) => {
      setSupervisors(users);
    };
    const onError = (err: any) => console.log(err);

    USER_SERVICE.getUsers(onSuccess, onError, { accessLevel: "supervisor" });
  };

  useEffect(() => {
    getSupervisors();
  }, []);
  const mobileview = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <ClockHeader />
      <CustomPageHeading
        title="Edit Campaign"
        marginBottom={mobileview ? "6px" : "10px"}
      />
      <EditCampaignForm supervisors={supervisors} />
    </>
  );
};

export default EditCampaign;
