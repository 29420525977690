import React from "react";
//import "../Supporting.scss"
import { TextField } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface Props {
  title: string;
  value: string | Date | null;
  attrName: any;
  value_update: Function;
  error_message?: string;
  warn_status?: any;
  class_name?: string;
  mandatory?: boolean;
}

const TimePicker: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  error_message,
  warn_status,
  class_name,
  mandatory,
}) => {
  return (
    <div className={class_name}>
      <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
        <DesktopTimePicker
          label={
            <span className={mandatory ? "red-asterisk mandatory" : ""}>
              {title}
            </span>
          }
          value={value}
          onChange={(e) => {
            value_update(attrName, e);
          }}
          renderInput={(params) => (
            <TextField fullWidth {...params} error={warn_status} />
          )}
        />
      </LocalizationProvider>
      {/* {warn_status == true ? (
        <span className="error">{error_message}</span>
      ) : null} */}
    </div>
  );
};

export default TimePicker;
