import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Divider, IconButton, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import { DISPOSITION_SERVICE } from "../../services/DispositionService";
import { LEADSET_SERVICE } from "../../services/LeadsetService";
import { GetDispositionDTO } from "../../services/dto/GetDispositionDTO";
import { GetLeadsetDTO } from "../../services/dto/GetLeadsetDTO";
import { GetProcessDTO } from "../../services/dto/GetProcessDTO";
import FormActionButton from "../Actions/FormActionButton";
import CustomMultiSelect from "../Custom/CustomMultiSelect";
import { CustomPopup2 } from "../Custom/CustomPopup2";
import { SelectOption } from "../Custom/CustomSelect";
import LightTooltip from "../LightToolTip";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ChurnProcessHeader = (name: string) => {
  return (
    <Typography fontSize={20} fontWeight={600}>
      {"Churn (Process: " + name + ")"}
    </Typography>
  );
};

const ChurnProcessContent = ({
  processId,
  defaultValues,
  onAction,
  setOpen,
  selectedProcess,
}: {
  processId?: string | number;
  defaultValues?: SelectOption[];
  onAction?: () => void;
  setOpen: Function;
  selectedProcess?: GetProcessDTO;
}) => {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [churnStatus, setChurnStatus] = useState(false);
  const [leadsChurnCount, setLeadsChurnCount] = useState(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedDispositions, setSelectedDispositions] = useState<
    SelectOption[]
  >([]);
  const [dispositionList, setDispositionList] = useState<GetDispositionDTO[]>(
    []
  );
  const [leadsets, setLeadsets] = useState<GetLeadsetDTO[]>([]);
  const [selectedLeadsets, setSelectedLeadsets] = useState<SelectOption[]>(
    defaultValues ?? []
  );
  const [search, setSearch] = useState<string>("");
  const startIndex = 0,
    limit = 3;

  function getLeadsets() {
    const onSuccess = (data: any) => setLeadsets(data.leadsets);
    const onError = (err: any) => console.log(err);
    LEADSET_SERVICE.getLeadset(onSuccess, onError, {
      startIndex,
      limit,
      search,
    });
  }

  useEffect(() => {
    getLeadsets();
    getDispositionListById();
  }, [selectedProcess]);

  const loadLeadsets = useCallback((search: string) => {
    const onSuccess = (data: any) => setLeadsets(data.leadsets);
    const onError = (err: any) => console.log(err);
    LEADSET_SERVICE.getLeadset(onSuccess, onError, {
      startIndex,
      limit,
      search,
    });
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadLeadsets, 500), [
    loadLeadsets,
  ]);

  const handleSearch = (text: string) => {
    if (text == search) return;
    setSearch(text);
    getSearchData(text);
  };

  const churnLeadsAPI = () => {
    const onSuccess = (data: any) => {
      const response = data.data;
      const errorStatus = response.errorStatus;
      if (errorStatus === false) {
        const leads = response.leads;
        const count = leads;

        setLeadsChurnCount(count);
        setChurnStatus(true);
      } else {
        // const leads = response.leads;
        // const count = leads;
        // setLeadsChurnCount(count);
      }

      setLoader(false);
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const selectedDispositionIds = selectedDispositions.map(
      (dispositions) => dispositions.value
    );
    const selectedLeadsetsIds = selectedLeadsets.map((leadset) =>
      parseInt(leadset.value.toString())
    );

    setLoader(true);
    LEADSET_SERVICE.churnLeads(
      onSuccess,
      onError,
      {
        leadsetIds: selectedLeadsetsIds,
        dispositionIds: selectedDispositionIds,
      },
      processId?.toString() ?? ""
    );
  };

  const getDispositionListById = () => {
    const onSuccess = (data: any) => {
      const errorStatus = data.errorStatus;
      setErrorStatus(errorStatus);
      if (errorStatus === false) {
        setDispositionList(data.dispositions);
      } else {
        const message = data.message;
        setErrorMsg(message);
      }
      setLoader(false);
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    setLoader(true);
    DISPOSITION_SERVICE.getDispositionsByProcessId(
      onSuccess,
      onError,
      selectedProcess?.id
    );
  };

  const handleCheckboxClick = (disposition: any) => {
    const index = selectedDispositions.findIndex(
      (selectedDisposition) => selectedDisposition.value === disposition.value
    );

    if (index === -1) {
      setSelectedDispositions([
        ...selectedDispositions,
        { label: disposition.name, value: disposition.id },
      ]);
    } else {
      const updatedDispositions = [...selectedDispositions];
      updatedDispositions.splice(index, 1);
      setSelectedDispositions(updatedDispositions);
    }
  };

  const updateOpenStatus = (index: Array<number>) => {
    const data = [...dispositionList];

    if (index.length === 1) {
      data[index[0]].sub_menu_open_status =
        !data[index[0]].sub_menu_open_status;
    } else if (index.length === 2) {
      data[index[0]].children[index[1]].sub_menu_open_status =
        !data[index[0]].children[index[1]].sub_menu_open_status;
    }
    setDispositionList(data);
  };

  return (
    <Box
      sx={{
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {churnStatus === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CheckCircleIcon fontSize="large" sx={{ color: "#32CD32" }} />
            <Typography
              fontSize={20}
              fontWeight={600}
              sx={{ marginTop: "5px", marginLeft: "20px" }}
            >
              Leads Churned
            </Typography>
          </Box>
          <Typography
            fontSize={14}
            fontWeight={400}
            sx={{ display: "flex", alignItems: "center", marginTop: "30px" }}
          >
            You've successfully churned the leads in process "
            {selectedProcess?.name} "
          </Typography>
          <Divider
            sx={{
              borderColor: "rgba(0, 0, 0, 0.1)",
              width: "80%",
              marginTop: "15px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              width: "70%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize={16} fontWeight={600}>
              Leads churned :
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              sx={{ marginLeft: "4px" }}
            >
              {leadsChurnCount}
            </Typography>
          </Box>
          <Box
            mt="20px"
            mb="5px"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <FormActionButton
              title="Close"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
              loading={false}
            />
          </Box>
        </div>
      ) : (
        <>
          <Box>
            <CustomMultiSelect
              options={leadsets.map((leadset: GetLeadsetDTO) => ({
                label: leadset.name,
                value: leadset.id,
              }))}
              selectedValue={selectedLeadsets}
              onChange={setSelectedLeadsets}
              onInputChange={handleSearch}
              label="Add/Remove Leadset you want to churn leads from"
            />
            {errorStatus === true ? null : (
              <Typography my={2}>Select dispositions to churn</Typography>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "scroll",
              height: 200
            }}
          >
            {dispositionList.length > 0 ? (
              dispositionList.map((level_one, level_one_index) => (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {level_one.children.length > 0 ? (
                      <Box sx={{ height: "38px", width: "38px" }}></Box>
                    ) : (
                      <LightTooltip
                        title={
                          level_one.callback
                            ? "Disposition Type CALLBACK Cannot Be Churned"
                            : level_one.disposeType === "DNC"
                              ? "Disposition Type DNC Cannot Be Churned"
                              : level_one.disposeType === "sale"
                                ? "Disposition Type SALE Cannot Be Churned"
                                : "Select"
                        }
                      >
                        <Box>
                          <Checkbox
                            disabled={
                              level_one.callback ||
                              level_one.disposeType === "DNC" ||
                              level_one.disposeType === "sale"
                            }
                            icon={
                              <CheckBoxOutlineBlankOutlinedIcon
                                sx={{
                                  color:
                                    level_one.callback ||
                                      level_one.disposeType === "DNC" ||
                                      level_one.disposeType === "sale"
                                      ? "gray"
                                      : "#EB5757",
                                  fontSize: 20,
                                }}
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon
                                sx={{
                                  fontSize: 20,
                                  color: "#EB5757",
                                }}
                              />
                            }
                            onClick={() => handleCheckboxClick(level_one)}
                          />
                        </Box>
                      </LightTooltip>
                    )}
                    <Typography sx={{ marginTop: "2px", color: "#EB5757" }}>
                      {level_one.name}
                    </Typography>
                    {level_one.children.length > 0 ? (
                      <LightTooltip
                        title={
                          level_one.sub_menu_open_status ? "Collapse" : "Expand"
                        }
                      >
                        <IconButton
                          onClick={() => {
                            updateOpenStatus([level_one_index]);
                          }}
                          sx={{ marginLeft: "10px" }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          {level_one.sub_menu_open_status === true ? (
                            <KeyboardArrowUpIcon
                              sx={{ fontSize: 20, color: "#000" }}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              sx={{ fontSize: 20, color: "#000" }}
                            />
                          )}
                        </IconButton>
                      </LightTooltip>
                    ) : null}
                  </Box>
                  {level_one.sub_menu_open_status === true
                    ? level_one.children.map((level_two, level_two_index) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "30px",
                          }}
                        >
                          {level_two.children.length > 0 ? (
                            <Box sx={{ height: "38px", width: "38px" }}></Box>
                          ) : (
                            <LightTooltip
                              title={
                                level_two.callback
                                  ? "Disposition Type CALLBACK Cannot Be Churned"
                                  : level_two.disposeType === "DNC"
                                    ? "Disposition Type DNC Cannot Be Churned"
                                    : level_two.disposeType === "sale"
                                      ? "Disposition Type SALE Cannot Be Churned"
                                      : "Select"
                              }
                            >
                              <Box>
                                <Checkbox
                                  disabled={
                                    level_two.callback ||
                                    level_two.disposeType === "DNC" ||
                                    level_two.disposeType === "sale"
                                  }
                                  icon={
                                    <CheckBoxOutlineBlankOutlinedIcon
                                      sx={{
                                        color:
                                          level_two.callback ||
                                            level_two.disposeType === "DNC" ||
                                            level_two.disposeType === "sale"
                                            ? "gray"
                                            : "#0066FF",
                                        fontSize: 20,
                                      }}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon
                                      sx={{
                                        fontSize: 20,
                                        color: "#0066FF",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    handleCheckboxClick(level_two)
                                  }
                                />
                              </Box>
                            </LightTooltip>
                          )}
                          <Typography
                            sx={{ marginTop: "2px", color: "#0066FF" }}
                          >
                            {level_two.name}
                          </Typography>
                          {level_two.children.length > 0 ? (
                            <LightTooltip
                              title={
                                level_two.sub_menu_open_status
                                  ? "Collapse"
                                  : "Expand"
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  updateOpenStatus([
                                    level_one_index,
                                    level_two_index,
                                  ]);
                                }}
                                sx={{ marginLeft: "10px" }}
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                              >
                                {level_two.sub_menu_open_status === true ? (
                                  <KeyboardArrowUpIcon
                                    sx={{ fontSize: 20, color: "#000" }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    sx={{ fontSize: 20, color: "#000" }}
                                  />
                                )}
                              </IconButton>
                            </LightTooltip>
                          ) : null}
                        </Box>
                        {level_two.sub_menu_open_status === true
                          ? level_two.children.map(
                            (level_three, level_three_index) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "60px",
                                }}
                              >
                                <LightTooltip
                                  title={
                                    level_three.callback
                                      ? "Disposition Type CALLBACK Cannot Be Churned"
                                      : level_three.disposeType === "DNC"
                                        ? "Disposition Type DNC Cannot Be Churned"
                                        : level_three.disposeType === "sale"
                                          ? "Disposition Type SALE Cannot Be Churned"
                                          : "Select"
                                  }
                                >
                                  <Box>
                                    <Checkbox
                                      disabled={
                                        level_three.callback ||
                                        level_three.disposeType === "DNC" ||
                                        level_three.disposeType === "sale"
                                      }
                                      onChange={(e) => {
                                        handleCheckboxClick(level_three);
                                      }}
                                      icon={
                                        <CheckBoxOutlineBlankOutlinedIcon
                                          sx={{
                                            color:
                                              level_three.callback ||
                                                level_three.disposeType ===
                                                "DNC" ||
                                                level_three.disposeType ===
                                                "sale"
                                                ? "gray"
                                                : "#5ECE62",
                                            fontSize: 20,
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon
                                          sx={{
                                            fontSize: 20,
                                            color: "#5ECE62",
                                          }}
                                        />
                                      }
                                    />
                                  </Box>
                                </LightTooltip>
                                <Typography
                                  sx={{
                                    marginTop: "2px",
                                    color: "#5ECE62",
                                  }}
                                >
                                  {level_three.name}
                                </Typography>
                                {level_three.children.length > 0 ? (
                                  <LightTooltip
                                    title={
                                      level_three.sub_menu_open_status
                                        ? "Collapse"
                                        : "Expand"
                                    }
                                  >
                                    <IconButton
                                      onClick={() => {
                                        updateOpenStatus([
                                          level_three_index,
                                        ]);
                                      }}
                                      sx={{ marginLeft: "10px" }}
                                      color="primary"
                                      aria-label="upload picture"
                                      component="label"
                                    >
                                      {level_three.sub_menu_open_status ===
                                        true ? (
                                        <KeyboardArrowUpIcon
                                          sx={{
                                            fontSize: 20,
                                            color: "#000",
                                          }}
                                        />
                                      ) : (
                                        <KeyboardArrowDownIcon
                                          sx={{
                                            fontSize: 20,
                                            color: "#000",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </LightTooltip>
                                ) : null}
                              </Box>
                            )
                          )
                          : null}
                      </>
                    ))
                    : null}
                </>
              ))
            ) : (
              <Typography
                sx={{
                  mt: "50px",
                  width: "100%",
                  textAlign: "center",
                  color: "#FF0000",
                }}
              >
                {errorMsg}
              </Typography>
            )}
          </Box>

          <Box
            mt="30px"
            mb="14px"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <FormActionButton
              title="Save"
              variant="contained"
              onClick={churnLeadsAPI}
              loading={loader}
              disabled={errorStatus}
            />
            <FormActionButton
              title="Cancel"
              onClick={() => setOpen(false)}
              disabled={loader}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const ChurnProcessPopup = ({
  open,
  setOpen,
  selectedProcess,
  onAction,
}: {
  open: boolean;
  setOpen: Function;
  selectedProcess?: GetProcessDTO;
  onAction?: () => void;
}) => {
  const defaultValues = selectedProcess?.processLeadsets.map((leadset) => ({
    label: leadset.name,
    value: leadset.id.toString(),
  }));
  return (
    <CustomPopup2
      Header={ChurnProcessHeader(selectedProcess?.name ?? "")}
      open={open}
      setOpen={setOpen}
      Component={() =>
        ChurnProcessContent({
          processId: selectedProcess?.id,
          defaultValues,
          setOpen,
          onAction: onAction,
          selectedProcess,
        })
      }
      minWidth={"800px"}
    />
  );
};

export default ChurnProcessPopup;
