import { Button, CircularProgress, useTheme } from "@mui/material";
import LightTooltip from "../LightToolTip";

function FormActionButton({
  onClick,
  title,
  sx,
  loading = false,
  variant = "outlined",
  disabled = false,
  tooltip = "",
}: {
  onClick?: any;
  title: string;
  sx?: any;
  loading?: boolean;
  variant?: "contained" | "outlined" | "text";
  disabled?: boolean;
  tooltip?: string;
}) {
  const theme = useTheme();

  return (
    <LightTooltip title={tooltip}>
      <span>
        <Button
          onClick={onClick}
          variant={variant}
          sx={{
            minWidth: "147px",
            height: "50px",
            textTransform: "capitalize",
            fontSize: "16px",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
            color:
              variant === "outlined"
                ? theme.palette.common.black
                : theme.palette.common.white,
            ...sx,
          }}
          disabled={disabled ? disabled : loading}
        >
          {!disabled && loading ? (
            <CircularProgress sx={{ color: "white" }} />
          ) : (
            title
          )}
        </Button>
      </span>
    </LightTooltip>
  );
}

export default FormActionButton;
