import { Box, Typography } from "@mui/material";
import React from "react";

function CallDetailsRectangleCard({
  sx,
  value,
  title,
  image_url,
}: {
  sx?: any;
  value: string;
  title: string;
  image_url: string;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "16px",
        padding: "20px",
        display: "flex",
        ...sx,
      }}>
      <Box>
        <Typography sx={{ fontSize: "32px" }}>{value}</Typography>
        <Typography sx={{ fontSize: "16px" }}>{title}</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
        <img
          style={{
            alignSelf: "center",
            marginTop: "10px",
            height: "70%",
          }}
          src={image_url}
        />
      </Box>
    </Box>
  );
}

export default CallDetailsRectangleCard;
