import { Keyboard } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import {
  AppBar,
  Box,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AvatarIcon from "../../assets/images/AvatarIcon";
import SettingIcon from "../../assets/images/SettingIcon";
import MenuIcon from "../../assets/images/side-nav/MenuIcon";
import ShortcutDesc from "../../pages/ShortcutDesc";
import { useClientSocket } from "../../shared/hooks/useClientSocket";
import { useSocket } from "../../shared/hooks/useSocket";
import { useAppSelector } from "../../state/hooks";
import { UserAccessLevel } from "../../state/types/user";
import { userSlice } from "../../state/user/userSlice";
import { DarkTooltip } from "../DarkToolTip";
import { AliveBlinker } from "./ActiveBlinker";
import ProfileAvatar from "./ProfileAvatar";
import { AUTH_SERVICE } from "../../services/AuthService";
const AppHeader = ({
  onClickMenu,
}: {
  open: boolean;
  onClickMenu: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu = Boolean(anchorEl);
  const [openBlinker, setOpenBlinker] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { micErrorStatus, webRTCConnStatus } = useAppSelector(
    (state) => state.user
  );
  const theme = useTheme();
  const { shortcutKeys } = useAppSelector((state) => state.user);
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const [openShortcut, setOpenShortcut] = useState(false);

  const { socket } = useSocket();
  const { clientSocket } = useClientSocket();

  useEffect(() => {
    const updateDevices = () => {
      navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
        const microphones = deviceInfos.filter(
          (device) => device.kind === "audioinput"
        );

        dispatch(
          userSlice.actions.updateMicErrorStatus(
            microphones.length > 0 ? false : true
          )
        );
      });
    };

    updateDevices();

    navigator.mediaDevices.ondevicechange = updateDevices;
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const onSuccess = (data: any) => {
      // console.log("<---------------------------------------->", data);
    };
    const onError = (err: any) => {};
    AUTH_SERVICE.updateShortcutEnableStatus(onSuccess, onError, {
      status: shortcutKeys,
    });
    // console.log("userDetails.shortcutKeys", shortcutKeys);
  }, [shortcutKeys]);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        width: "100%",
      }}
      elevation={0}
    >
      {/* <SettingPopup
        openStatus={settingStatus}
        attrName="settingStatus"
        value_update={updateMasterState}
      /> */}
      <Toolbar
        sx={{
          p: { xs: "44px 12px 16px 12px", md: "20px" },
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box flexGrow={1} display={{ xs: "flex", lg: "none" }}>
          <IconButton
            onClick={onClickMenu}
            sx={{
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              height: "32px",
              width: "32px",
              borderRadius: "100px",
              ":hover": { backgroundColor: theme.palette.primary.main },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          display={{ xs: "none", md: "flex" }}
          flexGrow={1}
          gap={2}
          justifyContent="left"
        >
          <IconButton
            onClick={() => {
              setOpenBlinker(!openBlinker);
            }}
            sx={{
              height: "32px",
              width: "32px",
            }}
          >
            {openBlinker ? (
              <ChevronLeftIcon style={{ color: "#000" }} />
            ) : (
              <ChevronRightOutlinedIcon style={{ color: "#fff" }} />
            )}
          </IconButton>
          {openBlinker ? (
            <Box
              display={{ xs: "none", md: "flex" }}
              flexGrow={1}
              gap={2}
              justifyContent="left"
            >
              <AliveBlinker socket={socket} label="Server" />
              <AliveBlinker socket={clientSocket} label="Client" />
            </Box>
          ) : null}
        </Box>
        <Box
          id="action-buttons"
          sx={{ display: "flex", gap: 0.4, alignItems: "Center" }}
        >
          {/* <Notifications /> */}
          <Link
            style={{ height: 40, width: 40, cursor: "pointer" }}
            onClick={handleClick}
          >
            <SettingIcon height={40} width={40} />
          </Link>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              disableRipple
              sx={{
                minWidth: 180,
                bgcolor: "transparent",
                background: "none",
                ":focus": {
                  backgroundColor: "transparent",
                },
                ":hover": {
                  // backgroundColor: "#fff",
                  bgcolor: "transparent",
                  background: "none",
                },
                ".Mui-selected": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <DarkTooltip title="Shortcut List" placement="top">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();
                    setOpenShortcut(true);
                  }}
                >
                  <ListItemIcon
                    sx={{ background: "transparent", bgcolor: "transparent" }}
                  >
                    <Keyboard />
                  </ListItemIcon>
                  <Link sx={{ paddingTop: "3px", color: colors.blue }}>
                    Short Keys
                  </Link>
                </Box>
              </DarkTooltip>
              <Switch
                defaultChecked={shortcutKeys}
                value={shortcutKeys}
                onChange={(e, updatedStatus) => {
                  dispatch(
                    userSlice.actions.updateShortcutEnableStatus(updatedStatus)
                  );
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </MenuItem>
          </Menu>
          <Box display={{ xs: "none", lg: "flex" }} alignItems="center" gap={2}>
            {/* <SettingIcon /> */}
            <ProfileAvatar
              name={
                userDetails.firstName !== ""
                  ? userDetails.firstName
                  : userDetails.username
              }
              accessLevel={userDetails.accessLevel}
              avatarIcon={<AvatarIcon />}
            />
          </Box>
          <Box
            maxHeight="50px"
            maxWidth="50px"
            display={{ xs: "flex", lg: "none" }}
            alignItems="center"
          >
            <ProfileAvatar
              avatarIcon={<AvatarIcon />}
              accessLevel={userDetails.accessLevel}
            />
          </Box>
        </Box>
      </Toolbar>
      {userDetails.accessLevel === UserAccessLevel.AGENT ? (
        micErrorStatus === true || webRTCConnStatus === false ? (
          <Typography
            style={{
              alignSelf: "center",
              position: "absolute",
              top: "71px",
              width: "85%",
              color: "#E5404F",
              maxLines: 1,
              textAlign: "center",
            }}
          >
            {micErrorStatus === true
              ? "Mic not found. Voice features require a working microphone to function properly."
              : webRTCConnStatus === false || !clientSocket?.connected
              ? "Error: Unable to establish a connection to the server. Please check your network connection and try 'Start Dialing'."
              : ""}
          </Typography>
        ) : null
      ) : null}
      <ShortcutDesc visible={openShortcut} setVisible={setOpenShortcut} />
    </AppBar>
  );
};

export default AppHeader;
