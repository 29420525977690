import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AGENT_DASHBOARD_SERVICE } from "../../services/AgentDashboardService";
import {
  DispositionCount,
  GetTodayAlertsDTO,
} from "../../services/dto/GetTodayAlertsDTO";
import Alert from "./Alert";

const todayAlerys: GetTodayAlertsDTO = {
  dispositionCounts: [],
};

const AlertBox = () => {
  const [todayAlerts, setTodayAlerts] =
    useState<GetTodayAlertsDTO>(todayAlerys);
  function getAlerts() {
    const onSuccess = (data: any) => {
      setTodayAlerts(data);
    };
    const onError = (err: any) => console.log(err);
    AGENT_DASHBOARD_SERVICE.getTodayAlerts(onSuccess, onError);
  }

  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <Box
      display={{ xs: "flex", lg: "block" }}
      alignItems="center"
      justifyContent={{ xs: "space-between", sm: "space-evenly" }}
      sx={{
        borderRadius: "30px",
        backgroundColor: "#D0E6FA",
        maxWidth: "100%",
        height: { xs: "279px", lg: "374px" },
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "17px 0",
        }}>
        <img
          src="/images/dashboard-alert-icon.png"
          alt=""
          style={{ maxHeight: "180px" }}
        />
      </Box>
      <Box>
        <Typography
          variant="h6"
          sx={{ textAlign: "center" }}
          pt={{ xs: "15px" }}>
          Today's Upcoming Alerts
        </Typography>
        <Box
          display={{ xs: "block", md: "flex" }}
          justifyContent="center"
          alignItems={"center"}
          pt={{ md: "20px" }}
          pb="20px">
          {todayAlerts.dispositionCounts.length > 0 &&
            todayAlerts.dispositionCounts
              .slice(0, 3)
              .map((dispositionAlert: DispositionCount) => (
                <Alert
                  key={dispositionAlert.disposition.id}
                  title={dispositionAlert.disposition.name}
                  value={`${dispositionAlert.count}`}
                />
              ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AlertBox;
