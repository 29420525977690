import { Box, Typography } from "@mui/material";
import lodash from "lodash";
import { useCallback, useEffect, useState } from "react";
import AllLeadActions from "../components/AllLeads/AllLeadsActions";
import AllLeadTable from "../components/AllLeads/AllLeadsTable";
import { SelectOption } from "../components/Custom/CustomSelect";
import { SearchBar } from "../components/SearchBar";
import TablePagination from "../components/Table/TablePagination";
import {
  GetPerformaceGaugeDTO,
  PerformaceGaugeDTO,
} from "../services/dto/GetPerformanceGaugeDTO";
import { PERFORMACNE_GAUGE_SERVICE } from "../services/PerformanceGauageService";
import { useAppDispatch, useAppSelector } from "../state/hooks";

const AllLeads = () => {
  let perfGaugeData: Array<PerformaceGaugeDTO>;
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [performaceLogs, setPerformanceLogs] =
    useState<GetPerformaceGaugeDTO | null>(null);
  const [recordingLogLoader, setRecordingLogLoader] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("id");
  const limit = 10;
  const params = useAppSelector((state) => state.filterForm);
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    count: 0,
    startCount: 0,
    endCount: 0,
  });
  const [filterParams, setFilterParams] = useState({ ...params });

  const [performanceGaugeData, setPerformanceGaugeData] =
    useState<Array<PerformaceGaugeDTO> | null>(null);
  const [agentList, setAgentList] = useState<Array<SelectOption>>([]);

  const loadAgentCDR = useCallback((text: string) => {
    setPerformanceGaugeData(
      perfGaugeData.filter(
        (item) =>
          (item.user.firstName &&
            item.user.firstName.toLowerCase().includes(text.toLowerCase())) ||
          (item.user.lastName &&
            item.user.lastName.toLowerCase().includes(text.toLowerCase())) ||
          (item.user.username &&
            item.user.username.toLowerCase().includes(text.toLowerCase()))
      )
    );
  }, []);

  const getSearchData = useCallback(lodash.debounce(loadAgentCDR, 500), [
    loadAgentCDR,
  ]);

  const handleSearch = (text: string) => {
    setSearch(text);
    getSearchData(text);
  };

  function getPerformanceGauageData() {
    const onSuccess = (data: any) => {
      const response = data;
      const error_status = response.errorStatus;
      const performanceGaugeData = response.data;

      const agentData: Array<SelectOption> = [];
      setLoader(false);
      if (performanceGaugeData.length > 0) {
        perfGaugeData = performanceGaugeData;
        setPerformanceGaugeData(performanceGaugeData);

        perfGaugeData.map((value) =>
          agentData.push({
            label: value.user.username ?? "",
            value: value.user.username ?? "",
          })
        );

        getSearchData(params["filter-name"]);
      } else {
        perfGaugeData = [];
        setPerformanceGaugeData([]);
      }

      setAgentList(agentData);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };
    setLoader(true);
    PERFORMACNE_GAUGE_SERVICE.getPerformanceGauge(
      onSuccess,
      onError,
      params["filter-startAfter"] + "Z",
      params["filter-startBefore"] + "Z"
    );
  }

  useEffect(() => {
    getPerformanceGauageData();
  }, [params, pagination.page]);

  return (
    <Box>
      <Typography variant="h5">All Leads</Typography>
      <Box
        sx={{
          pt: "23px",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          gap: 2,
        }}>
        <SearchBar
          sx={{
            background: "white",
            border: "1px solid #96A1B3",
          }}
          text={search}
          onChange={handleSearch}
        />
        <AllLeadActions search={search} type="admin" agentList={agentList} />
      </Box>
      <Box>
        <Box sx={{ display: { md: "flex", xs: "none" }, marginTop: "20px" }}>
          <AllLeadTable
            data={performanceGaugeData ?? []}
            startIndex={(pagination.page - 1) * limit}
            recordingLogLoader={recordingLogLoader}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          {/* <TablePagination page={pagination} setPage={setPagination} /> */}
        </Box>
        {/* <Box sx={{ display: { md: "none", xs: "block" } }}>
          <AgentLoggerTableMobile cdrs={agentCDRs?.cdrs ?? []} />
        </Box> */}
      </Box>
      <TablePagination page={pagination} setPage={setPagination} />
    </Box>
  );
};
export default AllLeads;
