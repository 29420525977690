import { Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { CustomPopup } from "../Custom/CustomPopup";
import ConfirmationPopUpContent from "./ConfiramtionPopUpContent";

const ConfirmationPopUp = ({
  open,
  setOpen,
  campaignName,
  title,
  body,
  onClick,
  loading = false,
}: {
  open: boolean;
  setOpen: Function;
  campaignName: string;
  title?: string;
  body?: React.ReactNode;
  onClick: any;
  loading?: boolean;
}) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <CustomPopup
      fullScreen={mobileView}
      open={open}
      setOpen={setOpen}
      divider={false}
      Component={() =>
        ConfirmationPopUpContent({
          campaignName,
          title,
          body,
          setOpen,
          onClick,
          loading,
        })
      }
      minWidth={685}
      sx={{ padding: 0 }}
    />
  );
};

export default ConfirmationPopUp;
