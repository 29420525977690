import "primeflex/primeflex.css";
import "primeicons/primeicons.css"; // icons
import { Calendar } from "primereact/calendar";
import "primereact/resources/primereact.css"; // core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme                            // icons
import "./flags.css";
import "./UGDateRangePicker.scss";

function UGDateRangePicker({
  value,
  setValue,
  sx,
}: {
  value: string | Date | undefined | Date[];
  setValue: any;
  sx?: any;
}) {
  return (
    <div className="my-calendar">
      <Calendar
        style={{ height: "40px", width: "202px", ...sx }}
        value={value}
        onChange={(e) => {
          setValue(e.value === null ? [] : e.value);
        }}
        selectionMode="range"
        readOnlyInput
        dateFormat="dd/mm/yy"
      />
    </div>
  );
}
export default UGDateRangePicker;
