import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import SearchableDropdown from "../../../components/Custom/SearchableDropdown";
import UGTable from "../../../components/EVCustoms/UGTable/UGTable";
import CallDetailsRectangleCard from "../../../components/QueueMatric/CallDetailsRectangleCard/CallDetailsRectangleCard";
import CallDetailsSquareCard from "../../../components/QueueMatric/CallDetailsSquareCard/CallDetailsSquareCard";
import { SearchBar } from "../../../components/SearchBar";
import imageDirectory from "../../../shared/imageDirectory";

const QueueMetric = () => {
  const [campaign, setCampaign] = useState<any>(null);
  const [process, setProcess] = useState<any>(null);
  const [processData, setProcessData] = useState<any[]>([]);
  const [search, setSearch] = useState("");

  const headCells: GridColDef[] = [
    {
      field: "agents_waiting_for_calls",
      headerName: "Agent Waiting For Calls",
      flex: 3,
    },
    {
      field: "access_level",
      headerName: "Access Level",
      flex: 2,
    },
    { field: "time", headerName: "Time", flex: 1 },
  ];
  const data: any[] = [
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
    {
      agents_waiting_for_calls: "Umesh Gemini",
      access_level: "Agent",
      time: "01:00:00",
      id: 0,
    },
  ];

  const handleSearch = (text: string) => {
    setSearch(text);
    // if (text.toString().length > 0) {
    //   setBrakeLogsData(
    //     break_logs_data.filter(
    //       (item) =>
    //         (item?.firstName &&
    //           item.firstName.toLowerCase().includes(text.toLowerCase())) ||
    //         (item?.lastName &&
    //           item.lastName.toLowerCase().includes(text.toLowerCase())) ||
    //         (item?.username &&
    //           item.username.toLowerCase().includes(text.toLowerCase()))
    //     )
    //   );
    // } else {
    //   setBrakeLogsData(break_logs_data);
    // }
  };
  return (
    <Box height={`calc(${window.innerHeight}px - 200px)`}>
      <Grid container height={"100%"}>
        <Grid xs={12}>
          <Typography variant="h5" marginRight={"20px"}>
            Queue Metric
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Grid
            container
            sx={{
              backgroundColor: "#F5F5F5",
              padding: "18px",
              borderRadius: "16px",
              marginTop: "10px",
            }}>
            <Grid xs={12}>
              <Typography
                fontSize={"16px"}
                display="flex"
                justifyContent={"center"}
                marginBottom={"12px"}>
                Process Details
              </Typography>
            </Grid>
            <Grid xs={4.5}>
              <SearchableDropdown
                size="small"
                sx={{ margin: "8px" }}
                label="Campaign"
                disabled={false}
                error={false}
                values={[]}
                minWidth={"130px"}
                selectedValue={campaign}
                onChange={(value: any) => {
                  setCampaign(value);
                  setProcessData(value.processes);
                  setProcess(null);
                }}
                onClear={() => {
                  setCampaign(null);
                  setProcessData([]);
                  setProcess(null);
                }}
              />
            </Grid>
            <Grid xs={4.5}>
              <SearchableDropdown
                size="small"
                sx={{ margin: "8px" }}
                label="Process"
                disabled={false}
                error={false}
                values={[]}
                minWidth={"130px"}
                selectedValue={process}
                onChange={(value: any) => {
                  setProcess(value);
                }}
                onClear={() => {
                  setProcess(null);
                }}
              />
            </Grid>
            <Grid xs={3}>
              <Button
                variant="contained"
                sx={{
                  margin: "8px",
                  width: "calc(100% - 16px)",
                  height: "40px",
                }}>
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={6}>
              <CallDetailsSquareCard
                sx={{
                  backgroundColor: "#F1EFFB",
                  marginTop: "32px",
                  height: "250px",
                }}
                value="16"
                title="Active Calls"
                image_url={imageDirectory.active_calls}
              />
            </Grid>
            <Grid xs={6}>
              <Grid container>
                <Grid xs={12}>
                  <CallDetailsRectangleCard
                    sx={{
                      backgroundColor: "#E0F0FF",
                      height: "calc(100% - 20px)",
                      marginTop: "32px",
                      marginLeft: "32px",
                      width: "calc(20%) - 32px",
                    }}
                    value="16"
                    title="Ringing Calls"
                    image_url={imageDirectory.ringing_calls}
                  />
                </Grid>
                <Grid xs={12}>
                  <CallDetailsRectangleCard
                    sx={{
                      backgroundColor: "#E0F0FF",
                      height: "calc(100% - 20px)",
                      marginTop: "32px",
                      marginLeft: "32px",
                      width: "calc(20%) - 32px",
                    }}
                    value="16"
                    title="Agents on Calls"
                    image_url={imageDirectory.agent_on_calls}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={4} sx={{ marginTop: "16px" }}>
                <Grid xs={4}>
                  <CallDetailsSquareCard
                    sx={{
                      backgroundColor: "#FFF5CF",
                      height: "190px",
                    }}
                    value="0"
                    title="Calls Waiting"
                    image_url={imageDirectory.calls_waiting}
                  />
                </Grid>
                <Grid xs={4}>
                  <CallDetailsSquareCard
                    sx={{
                      backgroundColor: "#DFFFF9",
                      height: "190px",
                    }}
                    value="1"
                    title="Dropped Calls"
                    image_url={imageDirectory.dropped_calls}
                  />
                </Grid>
                <Grid xs={4}>
                  <CallDetailsSquareCard
                    sx={{
                      backgroundColor: "#E0F0FF",
                      height: "190px",
                    }}
                    value="3"
                    title="Idle Agents"
                    image_url={imageDirectory.idle_agents}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={6}
          paddingTop={"8px"}
          paddingLeft={"41px"}
          sx={{ height: "100%" }}>
          <Grid container>
            <Grid xs={6}>
              <Typography fontSize={"18px"}>Queue Metric Table</Typography>
            </Grid>
            <Grid
              xs={6}
              display="flex"
              justifyContent={"flex-end"}
              marginBottom={"12px"}>
              <SearchBar
                sx={{
                  background: "white",
                  border: "1px solid #96A1B3",
                  marginTop: "3px",
                }}
                text={search}
                onChange={handleSearch}
              />
            </Grid>
            <Grid xs={12}>
              <UGTable
                header={headCells}
                data={data}
                sx={{ height: `calc(${window.innerHeight}px - 250px)` }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default QueueMetric;
