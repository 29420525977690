export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export type GetUserResponseDTO = {
  id: number;
  clientId: number;
  createdBy: number;
  status: string;
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  panCard: string;
  aadharCard: string;
  accessLevel: string;
  gender: Gender | string;
  email: string;
  mobile: string;
  address?: string;
  city?: string;
  pincode: string;
  nationality: string;
  bloodGroup: string;
  qualification: string;
  dob: string;
  isOnline?: boolean;
  summaryStatus?: string;
};
