const CallIconYellow = ({ color = "white" }: { color?: string }) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4507 0.824953C13.4507 0.606162 13.3638 0.396332 13.2091 0.241623C13.0544 0.0869143 12.8446 0 12.6258 0C12.407 0 12.1972 0.0869143 12.0424 0.241623C11.8877 0.396332 11.8008 0.606162 11.8008 0.824953V9.07448C11.8008 9.29328 11.8877 9.5031 12.0424 9.65781C12.1972 9.81252 12.407 9.89944 12.6258 9.89944C12.8446 9.89944 13.0544 9.81252 13.2091 9.65781C13.3638 9.5031 13.4507 9.29328 13.4507 9.07448V0.824953ZM17.8505 0.824953C17.8505 0.606162 17.7636 0.396332 17.6089 0.241623C17.4541 0.0869143 17.2443 0 17.0255 0C16.8067 0 16.5969 0.0869143 16.4422 0.241623C16.2875 0.396332 16.2006 0.606162 16.2006 0.824953V9.07448C16.2006 9.29328 16.2875 9.5031 16.4422 9.65781C16.5969 9.81252 16.8067 9.89944 17.0255 9.89944C17.2443 9.89944 17.4541 9.81252 17.6089 9.65781C17.7636 9.5031 17.8505 9.29328 17.8505 9.07448V0.824953ZM7.64965 3.67379L6.70371 1.44422C6.22854 0.322282 5.0428 -0.249686 3.93407 0.104494L2.74833 0.483973C1.5747 0.859051 0.674951 1.86549 0.384568 3.12822C-0.305093 6.13105 0.52206 9.78504 2.86823 14.0924C5.21109 18.3932 7.77835 20.9802 10.5711 21.8503C11.7546 22.2198 13.0327 21.8943 13.9281 20.9956L14.8278 20.0915C15.6671 19.25 15.7881 17.8784 15.1127 16.8851L13.7565 14.8943C13.1746 14.0385 12.1418 13.6755 11.1937 13.9923L8.6902 14.8261C7.67538 14.0512 6.82634 13.0806 6.19334 11.9717C5.52568 10.8256 5.1286 9.66075 4.99991 8.47722L7.08319 6.49953C7.83114 5.78897 8.06103 4.64614 7.64855 3.67489L7.64965 3.67379Z"
        fill={color}
      />
    </svg>
  );
};

export default CallIconYellow;
