import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0,1)",
    boxShadow: "0 -1px 4px rgba(0,0,0,0.25)",
    fontSize: 11,
    padding: 6,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

export default LightTooltip;
