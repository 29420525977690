import green_check from "../assets/JSON/green_check.json";
import call_center from "../assets/JSON/call_center.json";
import active_calls from "../assets/images/QueueMatric/activeCalls.png";
import ringing_calls from "../assets/images/QueueMatric/ringingCalls.png";
import agent_on_calls from "../assets/images/QueueMatric/agentOnCalls.png";
import calls_waiting from "../assets/images/QueueMatric/callsWaiting.png";
import dropped_calls from "../assets/images/QueueMatric/droppedCalls.png";
import idle_agents from "../assets/images/QueueMatric/idleAgents.png";
const imageDirectory = {
  green_check,
  call_center,
  active_calls,
  ringing_calls,
  agent_on_calls,
  calls_waiting,
  dropped_calls,
  idle_agents,
};

export default imageDirectory;
