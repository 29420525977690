const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatDateTime(dateTime: Date) {
  const date = dateTime.getDate();
  const month = dateTime.getMonth();
  const hours =
    dateTime.getHours() > 12 ? dateTime.getHours() - 12 : dateTime.getHours();
  const mins = dateTime.getMinutes();
  const day = dateTime.getDay();
  const period = dateTime.getHours() > 12 ? "PM" : "AM";
  return `${days[day].slice(0, day == 4 ? 4 : 3)}, ${date} ${months[
    month
  ].slice(0, 3)} at ${`0${hours}`.slice(-2)}:${`0${mins}`.slice(-2)} ${period}`;
}
