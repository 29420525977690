import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ChangeEvent, useState } from "react";
import { SOCKET_EVENTS } from "../../shared/constants";
import { useSocket } from "../../shared/hooks/useSocket";
import { useAppDispatch } from "../../state/hooks";
import { UserBreakReason, UserStateStatus } from "../../state/types/user";
import { userSlice } from "../../state/user/userSlice";
import FormActionButton from "../Actions/FormActionButton";
import { CustomPopup } from "../Custom/CustomPopup";
import CustomSelect from "../Custom/CustomSelect";
import CustomTextField from "../Custom/CustomTextField";

const breakFormDTO = {
  breakStatus: "",
  breakReason: "",
};

const BreakStatusContent = () => {
  const [breakForm, setBreakForm] = useState(breakFormDTO);
  const [validationError, setValidationError] = useState(breakFormDTO);
  const { socket } = useSocket();
  const dispatch = useAppDispatch();
  const specifyReason: boolean =
    breakForm.breakStatus === UserBreakReason.OTHER;
  const breakReasons = [
    UserBreakReason.LUNCH,
    UserBreakReason.MEETING,
    UserBreakReason.TEA,
    UserBreakReason.BIO,
    UserBreakReason.GENERAL,
    UserBreakReason.OTHER,
  ];

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { name } = (event as SelectChangeEvent).target;
    const { value } = event.target;
    if (!value) {
      setValidationError((prev) => ({
        ...prev,
        [id ?? name]: "Error",
      }));
      return;
    }
    setBreakForm((prev) => ({
      ...prev,
      [id ?? name]: value,
    }));
    setValidationError((prev) => ({
      ...prev,
      [id ?? name]: "",
    }));
  };

  const validateForm = () => {
    if (!breakForm.breakStatus) {
      setValidationError((prev) => ({
        ...prev,
        breakStatus: "Error",
      }));
      return false;
    }
    if (specifyReason && !breakForm.breakReason) {
      setValidationError((prev) => ({
        ...prev,
        breakReason: "Error",
      }));
      return false;
    }
    if (validationError.breakStatus.length > 0) {
      setValidationError((prev) => ({
        ...prev,
        breakStatus: "",
      }));
    }
    if (validationError.breakReason.length > 0) {
      setValidationError((prev) => ({
        ...prev,
        breakReason: "",
      }));
    }
    return true;
  };

  const handlePauseStatus = () => {
    if (!validateForm()) return;
    const reason =
      breakForm.breakStatus === UserBreakReason.OTHER
        ? breakForm.breakStatus.toUpperCase() + " - " + breakForm.breakReason
        : breakForm.breakStatus.toUpperCase();
    dispatch(userSlice.actions.goToPause());

    switch (breakForm.breakStatus) {
      case UserBreakReason.LUNCH:
        dispatch(userSlice.actions.goToLunchBreak());
        break;
      case UserBreakReason.MEETING:
        dispatch(userSlice.actions.goToMeetingBreak());
        break;
      case UserBreakReason.TEA:
        dispatch(userSlice.actions.goToTeaBreak());
        break;
      case UserBreakReason.BIO:
        dispatch(userSlice.actions.goToBioBreak());
        break;
      case UserBreakReason.GENERAL:
        dispatch(userSlice.actions.goToGeneralBreak());
        break;
      default:
        dispatch(userSlice.actions.goToMiscBreak());
        break;
    }

    socket?.emit(
      SOCKET_EVENTS.USER_STATE_ACTIVITY,
      UserStateStatus.PAUSE,
      reason
    );
    console.log({
      emits: UserStateStatus.PAUSE + ", " + reason,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "14px",
      }}>
      <Box width="100%" py="10px">
        <CustomSelect
          name="breakStatus"
          label="Reason"
          fullWidth
          values={breakReasons}
          selectedValue={breakForm.breakStatus}
          onChange={handleInputChange}
          error={Boolean(validationError.breakStatus)}
        />
      </Box>
      <Box width="100%" pt="5px" mx="5px">
        {specifyReason && (
          <CustomTextField
            id="breakReason"
            label="Specify Reason"
            fullWidth
            onChange={handleInputChange}
            error={Boolean(validationError.breakReason)}
          />
        )}
      </Box>
      <FormActionButton
        title="Start"
        variant="contained"
        onClick={handlePauseStatus}
        sx={{
          marginTop: "14px",
        }}
      />
    </Box>
  );
};

const BreakStatusHeader = () => {
  return (
    <Typography
      sx={{
        textAlign: { xs: "left", md: "center" },
        marginTop: "3px",
        fontSize: { xs: "20px", md: "24px" },
        fontWeight: { xs: 700, md: 400 },
      }}>
      Break Status
    </Typography>
  );
};

export const BreakStatusPopup = ({ openPopup, setOpenPopup, onClose }: any) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <CustomPopup
      maxWidth={{ md: 532 }}
      minWidth="532px"
      Header={BreakStatusHeader}
      open={openPopup}
      setOpen={setOpenPopup}
      Component={BreakStatusContent}
      divider={mobileView ? false : true}
      onClose={onClose}
    />
  );
};
