import { Box, Button } from "@mui/material";
import StartDialingIcon from "../../assets/images/StartDialingIcon";
import { useAppDispatch } from "../../state/hooks";
import { userSlice } from "../../state/user/userSlice";
import LightTooltip from "../LightToolTip";
import { DarkTooltip } from "../DarkToolTip";

const StartDialingButton = ({ disable = false }: { disable?: boolean }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(userSlice.actions.goToReady());
  };

  return (
    <DarkTooltip title="Start" placement="bottom">
      <Button
        onClick={() => {
          if (disable == false) handleClick();
        }}
        startIcon={
          <Box
            display={"flex"}
            alignItems="center"
            height={{ xs: "14px", md: "19px" }}
            width={{ xs: "14px", md: "19px" }}
          >
            <StartDialingIcon />
          </Box>
        }
        size="small"
        sx={{
          textTransform: "capitalize",
          m: { xs: "10px 0", md: "0px 24px" },
          p: { xs: "6px 10px", md: "9px 30px 5px 30px" },
          background: disable === false ? "#5ECE62" : "rgba(0,0,0,0.2)",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: { xs: "8px", md: "10px" },
          fontWeight: 600,
          fontSize: { xs: "12px", md: "20px" },
          ":hover": {
            backgroundColor: "#5ECE62",
            boxShadow: "none",
          },
        }}
        variant="contained"
      >
        Start Dialing
      </Button>
    </DarkTooltip>
  );
};

export default StartDialingButton;
