import { Box, CircularProgress, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import { LoadingContext } from "../shared/context/LoadingContext";
import { Player } from "@lottiefiles/react-lottie-player";
import imageDirectory from "../shared/imageDirectory";

export const LoadingContainer = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100vw"}
      minHeight={"100vh"}
      flexDirection={"column"}
      sx={{ marginY: "-100px" }}>
      <Player
        autoplay
        loop={true}
        src={imageDirectory.call_center}
        style={{
          height: "300px",
          width: "300px",
        }}
      />
      <Box sx={{ width: "50%", marginTop: "200px" }}>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export const LoadingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loadingContext, setLoadingContext] = useState(false);
  return (
    <LoadingContext.Provider value={{ loadingContext, setLoadingContext }}>
      {children}
    </LoadingContext.Provider>
  );
};
