import { Box, Typography, useTheme } from "@mui/material";

const Alert = ({ title, value }: { title: string; value: string }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "6px",
        py: { xs: "9px", md: "8px" },
        px: "11px",
        m: { xs: "8px 3px 0 3px", md: "0 3px" },
      }}
    >
      <Typography variant="h6" fontSize="16px">
        {value}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.common.black,
          fontFamily: { xs: "Roboto, sans", md: "Josefin sans, sans" },
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Alert;
