import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import React from "react";
import CallIconGreen from "../../assets/images/CallIconGreen";
import CustomIconButton from "../Custom/CustomIconButton";

function CallButtonRounded({
  sx,
  iconSize,
}: {
  sx?: React.CSSProperties;
  iconSize?: number;
}) {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <CustomIconButton
      Icon={CallIconGreen}
      IconSize={iconSize ? iconSize : 16}
      backgroundColor="#FF9B04"
      sx={{
        width: mobileView ? 30 : 30,
        height: mobileView ? 30 : 30,
        borderRadius: 50,
        ...sx,
      }}
    />
  );
}

export default CallButtonRounded;
