import PreviewIcon from "@mui/icons-material/Preview";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import React from "react";
import CustomIconButton from "../Custom/CustomIconButton";
import LightTooltip from "../LightToolTip";
const PreviewButton = ({
  iconSize,
  sx,
}: {
  iconSize?: number;
  sx?: React.CSSProperties;
}) => {
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <LightTooltip title="View Callback Preview" placement="bottom-end">
      <CustomIconButton
        Icon={PreviewIcon}
        IconSize={iconSize ? iconSize : 16}
        backgroundColor="#93CE15"
        sx={{
          width: mobileView ? 32 : 30,
          height: mobileView ? 32 : 30,
          ...sx,
        }}
      />
    </LightTooltip>
  );
};

export default PreviewButton;
