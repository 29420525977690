import { createSlice } from "@reduxjs/toolkit";
import { TDefaultProcess } from "../types/defaultProcess";
import {
  DEFAULT_PROCESS_REDUCERS,
  TDefaultProcessReducer,
} from "./defaultProcessReducers";

export const defaultProcessSlice = createSlice<
  TDefaultProcess,
  TDefaultProcessReducer
>({
  name: "defaultProcess",
  initialState: {
    ProcessId: 0,
    callingMode: null,
    clientId: 0,
    ratio: 0,
    running: false,
    startDateTime: "",
    endDateTime: "",
  },
  reducers: DEFAULT_PROCESS_REDUCERS,
});
