import { createContext } from "react";
import { CallProvider } from "../../lib/CallProvider";

type TCallProvider = {
  callProvider: CallProvider | null;
  setCallProvider: Function;
};

export const CallProviderContext = createContext<TCallProvider>({
  callProvider: null,
  setCallProvider: () => {},
});
