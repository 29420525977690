import { Box, Typography } from "@mui/material";
import { FORMATTER } from "../../helper/formatter";
import { TCallStat } from "../../services/dto/GetCallStatsDTO";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CallingStatusTile = ({
  stat,
  showCount = false,
  loader,
}: {
  stat: TCallStat;
  showCount?: boolean;
  loader: boolean;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      p="10px"
      sx={{
        backgroundColor: "#F4F6FE",
        borderRadius: "10px",
        mt: { xs: "14px", md: "4px" },
      }}>
      <StatsTile
        title={"Manual"}
        value={stat.manual}
        showCount={showCount}
        loader={loader}
      />
      <StatsTile
        title={"Callbacks"}
        value={stat.callbacks}
        showCount={showCount}
        loader={loader}
      />
      <StatsTile
        title={"New Leads"}
        value={stat.newLeads}
        showCount={showCount}
        loader={loader}
      />
    </Box>
  );
};

const StatsTile = ({
  title,
  value,
  showCount = false,
  loader,
}: {
  title: string;
  value: number;
  showCount?: boolean;
  loader: boolean;
}) => {
  return (
    <Box
      key={title}
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}>
      <Typography
        variant="body2"
        textTransform="capitalize"
        textAlign="center"
        color="rgba(0,0,0,0.6)"
        fontSize={{ xs: "12px", md: "14px" }}>
        {title}
      </Typography>
      {loader === true ? (
        <Skeleton baseColor="rgba(0,0,0,0.2)" width={"57px"} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            color: "common.black",
            fontWeight: 700,
            fontFamily: { xs: "Josefin Sans, sans-serif", md: "Roboto" },
            textAlign: "center",
            fontSize: { xs: "16px", md: "14px" },
            mt: { xs: "6px", md: "0px" },
          }}>
          {showCount ? value : FORMATTER.formatTimer(value)}
        </Typography>
      )}
    </Box>
  );
};

export default CallingStatusTile;
