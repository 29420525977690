import { PayloadAction } from "@reduxjs/toolkit";
import { TLeadState } from "../types/lead";

function setLead(state: TLeadState, action: PayloadAction<TLeadState>) {
  return action.payload;
}

function updateLead(
  state: TLeadState,
  action: PayloadAction<{ key: any; value: any }>
) {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

function updateLeadConfiguration(
  state: TLeadState,
  action: PayloadAction<{ [key: string]: string }>
) {
  const config = action.payload;
  return { ...state, configurations: config };
}

export const PENDING_DISPOSITION_LEAD_REDUCERS = {
  setLead,
  updateLead,
  updateLeadConfiguration,
};

export type TPendingLeadReducer = typeof PENDING_DISPOSITION_LEAD_REDUCERS;
