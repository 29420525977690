import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "./API";
import { URL_CONSTANTS } from "./../shared/urls";

function getLeadset(onSuccess: any, onError: any, query: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_LEADSETS,
    (res: any) => {
      onSuccess(res.data);
    },
    { params: query },
    onError
  );
}

function getAllLeadset(onSuccess: any, onError: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_ALL_LEADSETS,
    (res: any) => {
      onSuccess(res.data);
    },
    onError
  );
}

function getLeadsetDetails(
  onSuccess: Function,
  onError: Function,
  id: string | number
) {
  GetAPI.call(`${URL_CONSTANTS.GET_LEADSETS}/${id}`, onSuccess, {}, onError);
}

function postLeadset(onSuccess: any, onError: any, dto: any) {
  PostAPI.call(URL_CONSTANTS.GET_LEADSETS, dto, onSuccess, {}, onError);
}

function deleteLeadset(onSuccess: any, onError: any, id: string) {
  DeleteAPI.call(`${URL_CONSTANTS.GET_LEADSETS}/${id}`, onSuccess, {}, onError);
}

function exportLeadsets(onSuccess: Function, onError: Function, query: any) {
  GetAPI.call(
    URL_CONSTANTS.EXPORT_LEADSETS,
    onSuccess,
    { responseType: "blob", params: query },
    onError
  );
}

function churnLeads(
  onSuccess: Function,
  onError: Function,
  data: any,
  processId: string
) {
  PatchAPI.call(
    URL_CONSTANTS.CHURN_LEADS(processId),
    data,
    onSuccess,
    {},
    onError
  );
}

export const LEADSET_SERVICE = {
  getLeadset,
  getAllLeadset,
  getLeadsetDetails,
  postLeadset,
  deleteLeadset,
  exportLeadsets,
  churnLeads,
};
