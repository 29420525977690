import React from "react";

const CallEndIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H42C46.4183 0 50 3.58172 50 8V42C50 46.4183 46.4183 50 42 50H8C3.58172 50 0 46.4183 0 42V8Z"
        fill="#F57A69"
        fillOpacity="0.2"
      />
      <path
        d="M30.3234 26.5918C30.0968 26.3859 29.7992 26.2761 29.4932 26.2856C29.1872 26.295 28.8969 26.423 28.6835 26.6425L25.8624 29.5435C25.1834 29.4138 23.8183 28.9883 22.413 27.5867C21.0078 26.1804 20.5822 24.8118 20.4561 24.1375L23.355 21.3155C23.5747 21.1023 23.7029 20.8119 23.7123 20.5059C23.7218 20.1998 23.6118 19.9021 23.4056 19.6758L19.0497 14.8863C18.8434 14.6592 18.5567 14.5214 18.2506 14.5023C17.9444 14.4832 17.6428 14.5841 17.4098 14.7837L14.8516 16.9775C14.6478 17.182 14.5262 17.4542 14.5098 17.7425C14.4921 18.0372 14.1549 25.0181 19.5684 30.4335C24.291 35.1546 30.2066 35.5 31.8359 35.5C32.074 35.5 32.2202 35.4929 32.2591 35.4906C32.5473 35.4744 32.8194 35.3523 33.023 35.1475L35.2157 32.5884C35.4155 32.3556 35.5167 32.0541 35.4978 31.7479C35.4788 31.4418 35.3413 31.155 35.1144 30.9486L30.3234 26.5918Z"
        fill="#F57A69"
      />
    </svg>
  );
};

export default CallEndIcon;
