import { createSlice } from "@reduxjs/toolkit";
import { TDisposeCallState } from "../types/disposeCall";
import {
  DISPOSE_CALL_REDUCERS,
  TDisposeCallReducer,
} from "./disposeCallReducers";

export const disposeCallSlice = createSlice<
  TDisposeCallState,
  TDisposeCallReducer
>({
  name: "disposeCall",
  initialState: {
    dispositionId: 0,
    callBackTime: new Date(new Date().getTime() + 5 * 60000).getTime(),
    comments: "",
  },
  reducers: DISPOSE_CALL_REDUCERS,
});
