import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { AUTH_SERVICE } from "../services/AuthService";
import { CALL_SERVICE } from "../services/CallService";
import { CALLBACK_SERVICE } from "../services/CallbackService";
import { CAMPAIGN_SERVICE } from "../services/CampaignService";
import { GetCallbackDTO } from "../services/dto/GetCallbackDTO";
import { GetCampaignDTO } from "../services/dto/GetCampaignDTO";
import {
  LOCALSOTRAGE_KEYS_CONSTANTS,
  SOCKET_EVENTS,
} from "../shared/constants";
import { useCallProvider } from "../shared/hooks/useCallProvider";
import { ALL_ROUTES } from "../shared/routes";
import { callSlice } from "../state/call/callSlice";
import { callbackLogsSlice } from "../state/callbackLogs/callbackLogsSlice";
import { campaignsSlice } from "../state/campaigns/campaignsSlice";
import { clientConfigSlice } from "../state/clientConfig/clientConfigSlice";
import { useAppSelector } from "../state/hooks";
import { leadSlice } from "../state/lead/leadSlice";
import { pendingDispositionCallSlice } from "../state/pendingDispositionCall/pendingDispositionCallSlice";
import { pendingDispositionLeadSlice } from "../state/pendingDispositionLead/pendingDispositionLeadSlice";
import { defaultProcessSlice } from "../state/process/defaultProcessSlice";
import { TClientConfig } from "../state/types/TClientConfig";
import { CallStatus } from "../state/types/call";
import { UserDetails, UserStateStatus } from "../state/types/user";
import { userSlice } from "../state/user/userSlice";
import { useAuth } from "./../shared/hooks/useAuth";
import { useSocket } from "./../shared/hooks/useSocket";
import DashboardContainer from "./DashboardContainer";
import { LoadingContainer } from "./LoadingProvider";
import { resetTimer } from "./Stopwatch/useTimer";

export const ProtectedRoute = () => {
  const { setAuthToken, authToken } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userStatus = useAppSelector((state) => state.user.status);
  const { socket } = useSocket();
  const callProvider = useCallProvider();
  const user = useAppSelector((state) => state.user);
  // const { loadingContext, setLoadingContext } = useLoading();
  const existingAuthToken = localStorage.getItem(
    LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN
  );
  const [mainLoader, setMainLoader] = useState<boolean>(true);
  const [loadingContext, setLoadingContext] = useState<boolean>(true);
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(true);
  const [loadingMissedCallback, setLoadingMissedCallback] =
    useState<boolean>(true);
  const [loadingUpcomingCallback, setLoadingUpcomingCallback] =
    useState<boolean>(true);
  const client_config = useAppSelector((state) => state.clientConfig);

  useEffect(() => {
    if (callProvider?.isConnected()) {
      return;
    }
    const onConnect = () => {
      callProvider?.joinSelfBridge(
        user.userDetails.bridge,
        client_config.OTHER_SSP
      );
    };

    const user_details_str = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.USER
    );
    const user_details: UserDetails = JSON.parse(
      user_details_str === null ? "{}" : user_details_str
    );

    callProvider?.connect(onConnect, user_details.accessLevel);
    return () => {
      callProvider?.disconnect();
    };
  }, [callProvider]);

  const getProfileDetails = () => {
    const onSuccess = (data: any) => {
      const error_status = data.errorStatus;
      if (error_status === false) {
        const shortcutKeys = data.data.shortcutKeys;

        dispatch(userSlice.actions.updateShortcutEnableStatus(shortcutKeys));
      }
    };
    const onError = (err: any) => {};
    AUTH_SERVICE.getProfileDetails(onSuccess, onError);
  };

  const handleUserState = () => {
    const existingState = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.USER_STATE
    );
    const navigateOnChange = (path: any) => {
      if (path === location.pathname) return;
      navigate(path);
    };
    if (userStatus !== existingState) {
      localStorage.setItem(LOCALSOTRAGE_KEYS_CONSTANTS.USER_STATE, userStatus);
      if (
        userStatus !== UserStateStatus.DISPOSITION &&
        userStatus !== UserStateStatus.PAUSE
      ) {
        socket?.emit(SOCKET_EVENTS.USER_STATE_ACTIVITY, [
          userStatus === UserStateStatus.MANUAL_PREVIEW
            ? UserStateStatus.MANUAL
            : userStatus === UserStateStatus.CALLBACK_PREVIEW
            ? UserStateStatus.CALLBACK
            : userStatus,
        ]);
      }
      resetTimer();
    }

    switch (userStatus) {
      case UserStateStatus.PRE_READY:
        {
          navigateOnChange(ALL_ROUTES.READY);
        }
        break;
      case UserStateStatus.MANUAL:
        {
          navigateOnChange(ALL_ROUTES.READY);
        }
        break;
      case UserStateStatus.PAUSE:
        {
          navigateOnChange(ALL_ROUTES.AGENT_DASHBAORD);
        }
        break;
      case UserStateStatus.STOP:
        {
          navigateOnChange(ALL_ROUTES.AGENT_DASHBAORD);
        }
        break;
      case UserStateStatus.CALL:
        {
          navigateOnChange(ALL_ROUTES.CALL);
        }
        break;
      case UserStateStatus.DISPOSITION:
        {
          navigateOnChange(ALL_ROUTES.CALL);
        }
        break;
      case UserStateStatus.CALLBACK:
        {
          navigateOnChange(ALL_ROUTES.CALLBACKS);
        }
        break;
      case UserStateStatus.PREVIEW:
        {
          navigateOnChange(ALL_ROUTES.PREVIEW);
        }
        break;
      case UserStateStatus.MANUAL_PREVIEW:
        {
          navigateOnChange(ALL_ROUTES.PREVIEW);
        }
        break;
      case UserStateStatus.CALLBACK_PREVIEW:
        {
          navigate(ALL_ROUTES.PREVIEW);
        }
        break;
      default:
        {
          if (userStatus.includes("READY")) {
            navigateOnChange(ALL_ROUTES.READY);
          }
        }
        break;
    }
  };

  useEffect(() => {
    handleUserState();
  }, [userStatus]);

  function checkPendingDispositions() {
    const onSuccess = (data: any) => {
      const existingState = localStorage.getItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.USER_STATE
      );
      setLoadingContext(false);
      if (data.length == 0) {
        if (existingState === UserStateStatus.DISPOSITION)
          dispatch(userSlice.actions.goToReady());
        return;
      }

      dispatch(
        pendingDispositionLeadSlice.actions.updateLead({
          key: "id",
          value: data[0].leadId,
        })
      );
      dispatch(
        pendingDispositionCallSlice.actions.updateCallId(
          data[0].CallDetails.callId
        )
      );
      dispatch(
        pendingDispositionCallSlice.actions.updateProcessId(data[0].processId)
      );
      dispatch(
        pendingDispositionCallSlice.actions.updateCallCode(
          data[0].CallDetails.callCode
        )
      );
      dispatch(
        leadSlice.actions.updateLead({
          key: "id",
          value: data[0].leadId,
        })
      );
      dispatch(callSlice.actions.updateCallId(data[0].CallDetails.callId));
      dispatch(callSlice.actions.updateProcessId(data[0].processId));
      dispatch(callSlice.actions.updateCallCode(data[0].CallDetails.callCode));
      dispatch(userSlice.actions.updateUserStatus(UserStateStatus.DISPOSITION));
      dispatch(
        callSlice.actions.updateCallStatus(CallStatus.CALL_DISCONNECTED)
      );
    };
    const onError = (err: any) => {
      console.log(err);
      setLoadingContext(false);
    };
    CALL_SERVICE.getPendingDispositions(onSuccess, onError);
  }

  function getCampaigns() {
    const onSuccess = ({ campaigns }: { campaigns: GetCampaignDTO[] }) => {
      dispatch(campaignsSlice.actions.setCampaigns(campaigns));
      if (campaigns.length > 0) {
        dispatch(userSlice.actions.updateManualCallStatus(true));
      } else if (campaigns.length === 0) {
        dispatch(userSlice.actions.updateManualCallStatus(false));
      }
      setLoadingCampaign(false);
    };

    const onError = (err: any) => {
      console.log(err);
      setLoadingCampaign(false);
    };

    CAMPAIGN_SERVICE.getCampaignsManual(onSuccess, onError);
  }

  function getMissedCallbacks() {
    const onSuccess = ({
      missedCallbacks,
    }: {
      missedCallbacks: GetCallbackDTO[];
    }) => {
      dispatch(
        callbackLogsSlice.actions.updateMissedCallbacks(missedCallbacks)
      );
      setLoadingMissedCallback(false);
    };
    const onError = (err: any) => {
      console.log({ err });
      setLoadingMissedCallback(false);
    };

    setLoadingMissedCallback(true);
    CALLBACK_SERVICE.getMissedCallbacks(onSuccess, onError);
  }

  function getUpcomingCallbacks() {
    const onSuccess = ({
      upcomingCallbacks,
    }: {
      upcomingCallbacks: GetCallbackDTO[];
    }) => {
      dispatch(
        callbackLogsSlice.actions.updateUpcomingCallbacks(upcomingCallbacks)
      );
      setLoadingUpcomingCallback(false);
    };
    const onError = (err: any) => {
      console.log({ err });
      setLoadingUpcomingCallback(false);
    };

    setLoadingUpcomingCallback(true);
    CALLBACK_SERVICE.getUpcomingCallbacks(onSuccess, onError);
  }

  useEffect(() => {
    setLoadingContext(true);
    if (!authToken && authToken !== "") {
      return;
    }
    const existingUserDetails = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.USER
    );
    const existingState = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.USER_STATE
    );
    const client_config_str = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.CLIENT_CONFIG
    );
    const client_config: TClientConfig = JSON.parse(
      client_config_str === null ? "{}" : client_config_str
    );

    dispatch(clientConfigSlice.actions.setClientConfigData(client_config));
    if (
      !existingAuthToken ||
      existingAuthToken === "" ||
      !existingUserDetails
    ) {
      navigate(ALL_ROUTES.LOGIN);
    }

    if (existingAuthToken && existingUserDetails) {
      setAuthToken(existingAuthToken);
      dispatch(
        userSlice.actions.setUserDetails(JSON.parse(existingUserDetails))
      );
      checkPendingDispositions();
      getCampaigns();
      getMissedCallbacks();
      getUpcomingCallbacks();
      getProfileDetails();
    }
  }, [existingAuthToken]);

  useEffect(() => {
    const existingState = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.USER_STATE
    );

    socket?.on(SOCKET_EVENTS.CONNECT, () => {
      socket.emit(SOCKET_EVENTS.USER_STATE_ACTIVITY, [
        existingState || UserStateStatus.PRE_READY,
      ]);
      console.log("Socket Connected");
    });
  }, [socket?.id]);

  useEffect(() => {
    const existingProcess = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.DEFAULT_PROCESS
    );
    if (existingProcess) {
      dispatch(
        defaultProcessSlice.actions.setDefaultProcess(
          JSON.parse(existingProcess)
        )
      );
    }
  }, []);

  useEffect(() => {
    const loader =
      loadingCampaign &&
      loadingContext &&
      loadingMissedCallback &&
      loadingUpcomingCallback
        ? true
        : false;

    setMainLoader(loader);
  }, [
    loadingContext,
    loadingCampaign,
    loadingMissedCallback,
    loadingUpcomingCallback,
  ]);

  return mainLoader ? <LoadingContainer /> : <DashboardContainer />;
};

export default ProtectedRoute;
