import { Box, ListItemText, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomTime from "../Custom/CustomTime";
import { TProductivityStat } from "./ProductivityStats";

const ProductivityStatsTile = ({
  stat,
  index,
  display = "block",
  spaceBetween = "12px",
  loader = true,
}: {
  stat: TProductivityStat;
  index?: number;
  display?: string;
  spaceBetween?: string;
  loader: boolean;
}) => {
  return (
    <Box
      key={index}
      display={{ xs: display, md: "flex" }}
      alignItems="center"
      paddingTop={{ xs: "0px", md: "4px" }}
      paddingBottom={{ xs: "18px", md: "0px" }}
    >
      <Box
        display="flex"
        alignItems={"center"}
        maxHeight={{ xs: "60px", md: "60px" }}
        maxWidth={{ xs: "60px", md: "60px" }}
        // minHeight={{ xs: "60px" }}
        // minWidth={{ xs: "60px" }}
        pr={spaceBetween}
      >
        <stat.icon />
      </Box>
      <ListItemText>
        {loader === true ? (
          <Skeleton
            baseColor="rgba(0,0,0,0.2)"
            width="80px"
            style={{ marginLeft: "1px", marginBottom: "7px" }}
          />
        ) : (
          <CustomTime
            variant="h6"
            timeInMillis={stat.time < 0 ? 0 : stat.time}
            sx={{
              fontWeight: { xs: 700, md: 600 },
              marginBottom: { xs: "", md: "-5px" },
              fontSize: { xs: "20px", md: "20px" },
            }}
          />
        )}
        <Box display={"flex"}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: {
                xs: "Roboto, sans-serif",
                md: "Josefin Sans, sans-serif",
              },
              fontWeight: { xs: 400 },
              fontSize: { xs: "12px", md: "14px" },
              color: "#7E8897",
            }}
          >
            Time on <b>{stat.screen}</b> {stat.isScreen !== false && ""}
          </Typography>
        </Box>
      </ListItemText>
    </Box>
  );
};

export default ProductivityStatsTile;
