import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./RKTextField.css";
interface Props {
  title: string;
  value?: any;
  attrName: any;
  value_update: Function;
  warn_status?: boolean;
  class_name?: string;
  error_messg?: string;
  sx?: any;
  container_style?: any;
  showPasswordToggle?: boolean;
  id?: string;
  mandatory?: boolean;
}

const defaultProps: Props = {
  title: "",
  value: "",
  attrName: "",
  value_update: () => {},
  warn_status: false,
  class_name: "inputField",
  error_messg: "Please enter valid value",
  showPasswordToggle: false,
  id: "",
  mandatory: false,
};

const RKTextField: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  warn_status,
  class_name,
  error_messg,
  sx,
  container_style,
  showPasswordToggle,
  id,
  mandatory,
}) => {
  const [showPassword, setShowPassword] = useState(true);
  return (
    <div style={{ ...container_style }}>
      <TextField
        autoComplete="off"
        id={id}
        sx={sx}
        fullWidth
        label={
          <span className={mandatory ? "red-asterisk mandatory" : ""}>
            {title}
          </span>
        }
        variant="outlined"
        value={value}
        type={showPassword ? "text" : "password"}
        onChange={(e) => {
          value_update(attrName, e.target.value);
        }}
        InputProps={
          showPasswordToggle
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { height: "50px", marginBottom: "20px" },
              }
            : undefined
        }
      />
      {warn_status == true ? (
        <span style={{ fontSize: "12px", color: "#EB5757" }}>
          {error_messg}
        </span>
      ) : null}
    </div>
  );
};

RKTextField.defaultProps = defaultProps;
export default RKTextField;
