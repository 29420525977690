import { Box, Link, Modal, Typography, useTheme } from "@mui/material";
import FileSaver from "file-saver";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UploadFileIcon from "../assets/images/UploadFileIcon";
import ClockHeader from "../components/ClockHeader";
import BackButton from "../components/Custom/BackButton";
import RoundedButtons from "../components/Custom/RoundedButtons";
import UploadLeadTable from "../components/Leads/UploadLeadTable";
import { DOWNLOADER } from "../helper/downloader";
import { LEAD_SERVICE } from "../services/LeadService";
import { LEADSET_SERVICE } from "../services/LeadsetService";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { leadsetSlice } from "../state/leadset/leadsetSlice";
import { validateMobileNumber } from "../SupportingFiles/HelpingFunction";

function UploadLead() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [leads, setLeads] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [uploadLoader, setUploadLoader] = useState<boolean>(false);
  const [warnCSV, setWarnCSV] = useState<boolean>(false);
  const [warnAPI, setWarnAPI] = useState<boolean>(false);
  const leadset = useAppSelector((state) => state.leadset);
  const { id } = useParams();
  const [duplicateData, setDuplicateData] = useState<
    Array<
      | {
          name: string;
          phone: string;
          status: string;
        }
      | undefined
    >
  >([]);

  const [uniqueData, setUniqueData] = useState<
    Array<
      | {
          name: string;
          phone: string;
          address: string;
          email: string;
          dob: string;
          gender: string;
          leadsetId: number;
          configurations: string;
        }
      | undefined
    >
  >([]);

  const [duplicateHeaderValues, setDuplicateHeaderValues] = useState<
    Array<string> | undefined
  >([]);
  const [duplicateHeaderStatus, setDuplicateHeaderStatus] = useState<
    boolean | undefined
  >(false);

  useEffect(() => {
    getLeadsetDetails();
  }, []);

  const getLeadsetDetails = () => {
    const onSuccess = (data: any) => {
      dispatch(leadsetSlice.actions.setLeadset(data.data));
    };
    const onError = (err: any) => console.log(err);
    LEADSET_SERVICE.getLeadsetDetails(onSuccess, onError, id ?? "");
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setWarnAPI(false);

    if (file) {
      setLoader(true);
      setLeads([]);
      Papa.parse(file, {
        header: true,
        preview: 5000,
        complete: function (results) {
          const headerFields = results.meta.fields;
          const duplicateDataStatus =
            headerFields &&
            headerFields?.filter(
              (item, index) => headerFields?.indexOf(item) != index
            ).length > 0;
          setDuplicateHeaderStatus(duplicateDataStatus);
          if (duplicateDataStatus === true) {
            const duplicateHeaderValues = headerFields?.filter(
              (item, index) => headerFields?.indexOf(item) != index
            );
            setDuplicateHeaderValues(duplicateHeaderValues);
          }
          if (
            headerFields?.includes("phone") &&
            duplicateDataStatus === false
          ) {
            const json = results.data as any[];
            const keys = ["name", "phone", "address", "email", "dob", "gender"];

            const filteredData = json.map((row) => {
              const configurations = Object.entries(row).filter(
                ([key, value]) => {
                  return !keys.includes(key) && value !== "";
                }
              );
              return {
                name: row.name,
                phone: row.phone,
                address: row.address,
                email: row.email,
                dob: row.dob,
                gender: row.gender,
                leadsetId: leadset.id,
                configurations: JSON.parse(
                  JSON.stringify(Object.fromEntries(configurations))
                ),
              };
            });

            setUniqueData(
              filteredData.filter(
                (item, index) =>
                  validateMobileNumber(item.phone) === true &&
                  filteredData.every(
                    (i, j) => i.phone !== item.phone || j === index
                  )
              )
            );

            const duplicate_data: Array<
              | {
                  name: string;
                  phone: string;
                  status: string;
                }
              | undefined
            > = [];
            filteredData.forEach((item, index) => {
              const duplicateIndex = filteredData.findIndex(
                (i, j) =>
                  (i.phone === item.phone && j !== index) ||
                  validateMobileNumber(item.phone) === false
              );
              if (duplicateIndex !== -1) {
                duplicate_data.push({
                  name: item.name,
                  phone: item.phone,
                  status:
                    validateMobileNumber(item.phone) === false
                      ? !item.phone
                        ? "Phone column is blank, please correct and upload again."
                        : "Invalid mobile number or format."
                      : "Duplicate phone number in file, please correct and upload again.",
                });
              }
            });
            setDuplicateData(duplicate_data);

            setLeads(filteredData);
            setWarnCSV(false);
          } else {
            setWarnCSV(true);
          }
          setLoader(false);
        },
      });
    }
  };

  const handleOnSubmit = (e: any) => {
    const onSuccess = (data: any) => {
      setUploadLoader(false);
      if (data.status === 201) {
        // navigate(-1);
        const response = data.data;
        let json_data = response.data;
        const errorStatus = response.errorStatus;

        json_data = json_data.concat(duplicateData);

        if (errorStatus === true) {
          setWarnAPI(true);
        } else {
          setLeads([]);
        }
        if (json_data && json_data.length > 0) {
          const csvData = Papa.unparse(json_data);
          const blob = new Blob([csvData], {
            type: "text/csv;charset=utf-8",
          });
          FileSaver.saveAs(blob, "InCorrectData.csv");
        }
      }
    };
    const onError = (err: any) => {
      console.log(err);
      setUploadLoader(false);
    };

    setWarnAPI(false);
    setUploadLoader(true);

    setTimeout(() => {
      LEAD_SERVICE.postBulkLeads(onSuccess, onError, uniqueData);
    }, 1000);
  };

  const sampleLeads = () => {
    //setLoading(true);
    const onSuccess = (data: any) => {
      DOWNLOADER.download(data.data, `sampleFile-${new Date().valueOf()}.csv`);
      //setLoading(false);
    };
    const onError = () => {
      //setLoading(false);
    };
    LEAD_SERVICE.sampleLeads(onSuccess, onError);
  };

  return (
    <>
      <Modal
        open={loader}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography sx={{ color: "#fff", fontSize: "25px" }}>
          Loading file data...
        </Typography>
      </Modal>
      <Modal
        open={uploadLoader}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography sx={{ color: "#fff", fontSize: "25px" }}>
          Uploading file data...
        </Typography>
      </Modal>
      <ClockHeader />
      <Box mb={"20px"} display={"flex"} alignItems="center" gap={"10px"}>
        <BackButton onClick={() => navigate(-1)} />
        <Typography
          variant="h5"
          sx={{ lineHeight: { xs: "20px", md: "32px" } }}>
          Upload Leads
        </Typography>
        <Typography
          variant="h4"
          sx={{ lineHeight: { xs: "20px", md: "32px" } }}>
          {"(" + leadset.name + ")"}
        </Typography>
      </Box>
      <Typography p="0 0 30px">
        {/* Import a XLS, XLSX or CSV. file below. */}
        Import a CSV. file below.
      </Typography>
      {warnCSV === true ? (
        <Typography p="0 0 7px" color="#EB5757">
          {duplicateHeaderStatus === true
            ? "* Duplicate columns found for the file, columns " +
              duplicateHeaderValues +
              " are duplicate. Please correct and upload again."
            : "* No field name phone found in the file uploaded by you, please make sure to download and use sample file to uploading the data."}
        </Typography>
      ) : null}
      {warnAPI === true ? (
        <Typography p="0 0 7px" color="#EB5757">
          * Your data is partially uploaded, please check downloaded CSV file
          for status
        </Typography>
      ) : null}

      <Box display={{ xs: "block", md: "flex" }} alignItems="center">
        <input
          onChange={handleFileChange}
          type={"file"}
          accept=".csv"
          style={{ marginTop: "20px" }}
        />
        <RoundedButtons
          title="Upload Leads"
          Icon={UploadFileIcon}
          color={theme.palette.primary.main}
          onClick={handleOnSubmit}
          disabled={leads.length === 0 || loader === true ? true : false}
          sx={{ mt: { xs: "20px", md: "" }, ml: "20px" }}
        />
      </Box>
      <Typography p="30px 0">Upload Lead</Typography>
      <UploadLeadTable leads={leads} />
      {leads.length > 0 ? null : (
        <Typography p="10px 0">
          <Link
            href="#"
            style={{ textDecoration: "none" }}
            onClick={() => {
              sampleLeads();
            }}>
            Download Sample File
          </Link>
        </Typography>
      )}
    </>
  );
}

export default UploadLead;
