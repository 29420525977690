import { Table, TableBody, TableCell, TableContainer } from "@mui/material";
import { FORMATTER } from "../../helper/formatter";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import StyledTableCell from "../Table/StyledTableCell";
import StyledTableHead from "../Table/StyledTableHead";
import StyledTableRow from "../Table/StyledTableRow";
import TableProgress from "../Table/TableProgress";
import CallbackActions from "./CallbackActions";

const headCells = [
  { id: "name", label: "Name", allowSort: false, width: "184.5px" },
  {
    id: "disposition",
    label: "Disposition",
    allowSort: false,
    width: "184.5px",
  },
  {
    id: "campaign",
    label: "Campaign Name",
    allowSort: false,
    width: "184.5px",
  },
  { id: "process", label: "Process", allowSort: false, width: "184.5px" },
  {
    id: "date-time",
    label: "Date & Time",
    allowSort: false,
    width: "184.5px",
  },
  { id: "status", label: "Status", allowSort: false, width: "184.5px" },
  { id: "action", label: "Actions", allowSort: false, width: "137px" },
];

const CallbacksTable = ({
  color,
  callbacks,
  onOpen,
  onClose,
  onAction,
  calling,
  loading,
  noDataText,
}: {
  color: string;
  callbacks: GetCallbackDTO[];
  onOpen?: () => void;
  onClose?: () => void;
  onAction?: () => void;
  calling?: boolean;
  loading: boolean;
  noDataText: string;
}) => {
  return (
    <TableContainer sx={{ maxWidth: "97%" }}>
      <Table>
        <StyledTableHead headColor={color}>
          {headCells.map((cell) => (
            <StyledTableCell
              key={cell.id}
              width={cell.width}
              sx={{ backgroundColor: color }}>
              {cell.label}
            </StyledTableCell>
          ))}
        </StyledTableHead>
        {loading ? (
          <TableProgress colSpans={7} />
        ) : callbacks.length === 0 ? (
          <TableBody>
            <StyledTableRow>
              <TableCell colSpan={7} align="center" sx={{ border: "none" }}>
                {noDataText}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <TableBody>
            {callbacks.map((callback, index) => (
              <StyledTableRow key={callback?.id}>
                <StyledTableCell width={headCells[0].width}>
                  {callback?.Lead?.name}
                </StyledTableCell>
                <StyledTableCell width={headCells[1].width}>
                  {callback?.ParentCall?.CallDetails?.Disposition?.name}
                </StyledTableCell>
                <StyledTableCell width={headCells[2].width}>
                  {callback?.ParentCall?.Campaign?.name}
                </StyledTableCell>
                <StyledTableCell width={headCells[3].width}>
                  {callback?.ParentCall?.Process?.name}
                </StyledTableCell>
                <StyledTableCell width={headCells[4].width}>
                  {FORMATTER.formatDateTime(callback?.dateTime)}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ textTransform: "initial" }}
                  width={headCells[5].width}>
                  {FORMATTER.formatTimeStamp(
                    new Date(),
                    new Date(callback?.dateTime),
                    "left",
                    "late"
                  )}
                </StyledTableCell>
                <StyledTableCell width={headCells[6].width}>
                  <CallbackActions
                    onAction={onAction}
                    onOpen={onOpen}
                    onClose={onClose}
                    callback={callback}
                    calling={calling}
                    index={index}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default CallbacksTable;
