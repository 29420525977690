import { Box, styled, Tab, Tabs, useTheme } from "@mui/material";
import CloseIcons from "../../Actions/CloseIcons";
import CustomTabButton from "../../Custom/CustomTabButton";
import CustomTabButtons from "../../Custom/CustomTabButtons";

const DispositionPopUpTabs = ({
  value,
  onChange,
  setOpen,
}: {
  value: number;
  onChange: any;
  setOpen: any;
}) => {
  const theme = useTheme();
  const StyledTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
  });

  const StyledTab = styled(Tab)({
    textTransform: "capitalize",
    color: theme.palette.common.black,
    fontSize: "16px",
    fontWeight: 400,
    "&.Mui-selected": {
      borderRadius:
        value === 0 ? "10px 0 0 0" : value === 3 ? "0 10px 0 0" : "0px",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  });

  return (
    <>
      <Box
        display={{ xs: "none", md: "flex" }}
        sx={{
          position: "absolute",
          top: "-50px",
          left: "29px",
        }}
      >
        <CustomTabButtons
          tabs={["Dispose", "Edit"]}
          onClick={onChange}
          containerWidth={"161px"}
          borderRadius={"10px 10px 0px 0px"}
          btnBorderRadius={"0px"}
          containerPadding={"0px"}
          btnPadding={"10px 21.4px"}
          btnHeight={"50px"}
          btnBgColor={"#6F5FD9"}
          btnMinWidth={"70px"}
          containerBgColor={"#EEEEEE"}
          fontSize={"16px"}
          containerBorder={"none"}
        />
      </Box>

      <Box
        display={{ xs: "flex", md: "none" }}
        justifyContent="flex-start"
        alignItems={"center"}
        flexDirection={"column"}
        pt="50px"
      >
        {/* <Box
          display={{ xs: "flex", md: "none" }}
          width="100%"
          justifyContent="flex-end"
        >
          <CloseIcons
            sx={{ position: "relative", top: "-16px", right: 0 }}
            onClick={() => setOpen(false)}
          />
        </Box> */}

        <CustomTabButtons
          tabs={["Dispose", "Edit"]}
          onClick={onChange}
          containerWidth={"164px"}
          borderRadius={"8px"}
          containerPadding={"0px"}
          btnPadding={"5px 10px"}
          btnHeight={"38px"}
          btnBgColor={"#6F5FD9"}
          btnMinWidth={"70px"}
          containerBgColor={"#F4F6FE"}
          fontSize={"12px"}
          containerBorder={"none"}
        />
      </Box>
    </>
  );
};

export default DispositionPopUpTabs;
