import { URL_CONSTANTS } from "../shared/urls";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI, PutAPI } from "./API";
import { PostCampaignDTO } from "./dto/PostCampaignDTO";

function getCampaigns(onSuccess: Function, onError: Function, query: any) {
  GetAPI.call(
    URL_CONSTANTS.GET_CAMPAIGNS,
    (data: any) => {
      onSuccess(data.data);
    },
    { params: query },
    onError
  );
}

function postCampaign(
  onSuccess: Function,
  onError: Function,
  dto: PostCampaignDTO
) {
  PostAPI.call(URL_CONSTANTS.GET_CAMPAIGNS, dto, onSuccess, {}, onError);
}

function update(
  onSuccess: Function,
  onError: Function,
  dto: PostCampaignDTO,
  id: string
) {
  PutAPI.call(`${URL_CONSTANTS.UPDATE_CAMPAIGN(id)}`, dto, onSuccess, onError);
}

function enableCampaign(onSuccess: Function, onError: Function, id: string) {
  PatchAPI.call(
    `${URL_CONSTANTS.GET_CAMPAIGNS}/${id}/enable`,
    {},
    onSuccess,
    {},
    onError
  );
}

function disableCampaign(onSuccess: Function, onError: Function, id: string) {
  PatchAPI.call(
    `${URL_CONSTANTS.GET_CAMPAIGNS}/${id}/disable`,
    {},
    onSuccess,
    {},
    onError
  );
}

function deleteCampaign(onSuccess: Function, onError: Function, id: string) {
  DeleteAPI.call(
    `${URL_CONSTANTS.GET_CAMPAIGNS}/${id}`,
    onSuccess,
    {},
    onError
  );
}

function exportCampaigns(onSuccess: Function, onError: Function, query: any) {
  GetAPI.call(
    URL_CONSTANTS.EXPORT_CAMPAIGNS,
    onSuccess,
    { responseType: "blob", params: query },
    onError
  );
}

function getCampaignsManual(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.GET_CAMPAIGNS_MANUAL,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}

export const CAMPAIGN_SERVICE = {
  getCampaigns,
  postCampaign,
  enableCampaign,
  disableCampaign,
  deleteCampaign,
  update,
  exportCampaigns,
  getCampaignsManual,
};
