function TotalCallsMadeIcon() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0607 1.68673C24.1564 1.59755 24.2332 1.49002 24.2864 1.37053C24.3397 1.25104 24.3683 1.12206 24.3706 0.991266C24.3729 0.860476 24.3488 0.73056 24.2998 0.60927C24.2509 0.48798 24.1779 0.377799 24.0854 0.285302C23.9929 0.192805 23.8828 0.119885 23.7615 0.0708943C23.6402 0.0219032 23.5103 -0.00215605 23.3795 0.000151596C23.2487 0.00245924 23.1197 0.0310867 23.0002 0.0843261C22.8807 0.137565 22.7732 0.214326 22.684 0.310029L15.255 7.73903L12.8263 5.31032C12.6416 5.13826 12.3974 5.04458 12.1451 5.04904C11.8927 5.05349 11.6519 5.15572 11.4735 5.33419C11.295 5.51266 11.1928 5.75343 11.1883 6.00578C11.1839 6.25814 11.2775 6.50237 11.4496 6.68702L14.5667 9.80408C14.7493 9.9865 14.9969 10.089 15.255 10.089C15.5131 10.089 15.7607 9.9865 15.9434 9.80408L24.0607 1.68673V1.68673ZM4.66739 0.147682L3.2699 0.59446C1.88281 1.03734 0.821711 2.22572 0.477535 3.71672C-0.336797 7.26238 0.641182 11.5769 3.41147 16.6629C6.17786 21.7411 9.20921 24.7959 12.5068 25.8232C13.903 26.2596 15.4135 25.8751 16.4707 24.814L17.5318 23.7464C18.5227 22.7529 18.6669 21.1333 17.8694 19.9605L16.2681 17.6097C15.5797 16.5993 14.3615 16.1707 13.2419 16.5447L10.2859 17.5292C9.08716 16.6144 8.08419 15.4683 7.33637 14.1589C6.54931 12.8056 6.07916 11.4302 5.9272 10.0327L8.38838 7.69747C9.27155 6.85846 9.5417 5.50903 9.05595 4.36221L7.939 1.72959C7.37793 0.404839 5.97785 -0.270524 4.66739 0.147682V0.147682Z"
        fill="#6F5FD9"
      />
    </svg>
  );
}

export default TotalCallsMadeIcon;
