import { useContext, useEffect } from "react";
import { io } from "socket.io-client";
import { CallProvider } from "../../lib/CallProvider";
import { callSlice } from "../../state/call/callSlice";
import { useAppDispatch } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import { CallStatus } from "../../state/types/call";
import { TClientConfig } from "../../state/types/TClientConfig";
import { LOCALSOTRAGE_KEYS_CONSTANTS, SOCKET_EVENTS } from "../constants";
import { ClientSocketContext } from "../context/ClientSocketContext";
import { DOMAIN_CONSTANTS } from "../domain";
import { UserDetails } from "../../state/types/user";

export const useClientSocket = () => {
  const { clientSocket, setClientSocket } = useContext(ClientSocketContext);
  const existingSocketToken = localStorage.getItem(
    LOCALSOTRAGE_KEYS_CONSTANTS.SOCKET_TOKEN
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const callCreatedListener = (data: any) => {
      dispatch(callSlice.actions.updateCallCode(data?.callCode));
      dispatch(leadSlice.actions.setLead(data?.Lead));
      dispatch(callSlice.actions.updateCallStatus(CallStatus.CALL_CREATED));
      if (data?.lead?.id !== undefined) {
        // dispatch(userSlice.actions.goToCall());
      }
    };
    if (clientSocket === null) {
      const client_config_str = localStorage.getItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.CLIENT_CONFIG
      );
      const client_config: TClientConfig = JSON.parse(
        client_config_str === null ? "{}" : client_config_str
      );

      const newClientSocket = io(client_config.CLIENT_SOCKET_URL, {
        withCredentials: true,
        port: DOMAIN_CONSTANTS.CLIENT_SOCKET_PORT,
        auth: { token: existingSocketToken },
      });

      newClientSocket?.on(SOCKET_EVENTS.CONNECT_ERROR, () => {
        const timeout = setTimeout(() => {
          if (newClientSocket.connected) {
            clearTimeout(timeout);
          } else {
            newClientSocket.connect();
          }
        }, 500);
      });

      newClientSocket?.on(SOCKET_EVENTS.CONNECT, () => {
        setClientSocket(newClientSocket);

        const client_config_str = localStorage.getItem(
          LOCALSOTRAGE_KEYS_CONSTANTS.CLIENT_CONFIG
        );
        const client_config: TClientConfig = JSON.parse(
          client_config_str === null ? "{}" : client_config_str
        );

        const user_details_str = localStorage.getItem(
          LOCALSOTRAGE_KEYS_CONSTANTS.USER
        );
        const user_details: UserDetails = JSON.parse(
          user_details_str === null ? "{}" : user_details_str
        );

        const newCallProvider = new CallProvider(
          client_config.JSSIP_WS,
          client_config.JSSIP_URI,
          user_details.webrtc_password,
          user_details.webrtc_username,
          user_details.accessLevel
        );
        if (newCallProvider.isConnected() === false) {
          newCallProvider.ua.start();
        }
      });

      newClientSocket?.on(SOCKET_EVENTS.DISCONNECT, () => {
        console.log({ listensToClient: "disconnect" });
        console.log("Client Socket Disconnected");
      });
    } else {
      clientSocket?.on(SOCKET_EVENTS.CALL_CREATED, callCreatedListener);
    }
    return () => {
      clientSocket?.removeListener(
        SOCKET_EVENTS.CALL_CREATED,
        callCreatedListener
      );
    };
  }, [clientSocket?.id, existingSocketToken]);
  return { clientSocket };
};
