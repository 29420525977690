import { Fade, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "../../assets/images/CloseIcon";
import CommentsBoxIcon from "../../assets/images/CommentsBoxIcon";
import { CustomPopup } from "../Custom/CustomPopup";
import CommentBox from "./CommentBox";

const CommentBoxIcon = ({ callId }: { callId?: number }) => {
  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const Header = () => {
    return (
      <Typography mt={0.5} fontSize="22px" fontWeight={700}>
        Comments
      </Typography>
    );
  };
  const Content = () => {
    return (
      <CommentBox
        callId={callId}
        header={false}
        setChecked={setChecked}
        open={open}
      />
    );
  };
  const content = (
    <Box>
      <CommentBox callId={callId} setChecked={setChecked} open={open} />
    </Box>
  );
  const handleChange = () => {
    setChecked((prev) => !prev);
    setOpen(true);
  };

  return (
    <Box>
      <Box
        onClick={handleChange}
        sx={{
          borderRadius: "50%",
          width: { xs: 52, md: 60 },
          height: { xs: 52, md: 60 },
          background: "#EFD053",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
          position: { md: "fixed", xs: "relative" },
          bottom: { lg: 20, md: 10, xs: "-10px" },
          right: { md: 70, xs: "-85%" },
          marginLeft: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ":hover": {
            cursor: "pointer",
          },
        }}>
        {checked ? <CloseIcon size="23" /> : <CommentsBoxIcon />}
      </Box>
      <Box
        sx={{ display: { md: "flex", xs: "none" }, justifyContent: "center" }}>
        <Fade in={checked}>{content}</Fade>
      </Box>

      <CustomPopup
        Header={Header}
        visibility={fullScreen ? true : false}
        open={open}
        setOpen={setOpen}
        Component={Content}
        maxWidth={900}
        minWidth={900}
        divider={false}
        backButton={true}
      />
    </Box>
  );
};

export default CommentBoxIcon;
