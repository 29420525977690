const CallIconBlue = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H42C46.4183 0 50 3.58172 50 8V42C50 46.4183 46.4183 50 42 50H8C3.58172 50 0 46.4183 0 42V8Z"
        fill="#5AA1EF"
        fillOpacity="0.2"
      />
      <path
        d="M34.4737 24.3672H37C37 17.8872 32.1079 13 25.6191 13V15.5263C30.75 15.5263 34.4737 19.2438 34.4737 24.3672Z"
        fill="#5AA1EF"
      />
      <path
        d="M25.6318 20.5789C28.2881 20.5789 29.4212 21.712 29.4212 24.3684H31.9475C31.9475 20.2947 29.7054 18.0526 25.6318 18.0526V20.5789ZM29.9542 27.4543C29.7115 27.2337 29.3926 27.116 29.0647 27.1262C28.7369 27.1363 28.4258 27.2735 28.1972 27.5086L25.1745 30.6173C24.4469 30.4783 22.9842 30.0223 21.4786 28.5204C19.9729 27.0135 19.5169 25.5469 19.3817 24.8244L22.4878 21.8004C22.7233 21.5719 22.8606 21.2608 22.8707 20.9329C22.8809 20.605 22.763 20.2859 22.5421 20.0434L17.8748 14.9112C17.6538 14.6678 17.3467 14.5202 17.0186 14.4997C16.6905 14.4792 16.3674 14.5874 16.1178 14.8013L13.3768 17.152C13.1584 17.3712 13.028 17.6629 13.0105 17.9718C12.9915 18.2876 12.6303 25.768 18.4306 31.5709C23.4907 36.6299 29.8292 37 31.5748 37C31.83 37 31.9866 36.9924 32.0283 36.9899C32.3372 36.9726 32.6287 36.8417 32.8468 36.6223L35.1963 33.88C35.4103 33.6306 35.5187 33.3075 35.4984 32.9794C35.4781 32.6514 35.3308 32.3441 35.0876 32.1229L29.9542 27.4543Z"
        fill="#5AA1EF"
      />
    </svg>
  );
};

export default CallIconBlue;
