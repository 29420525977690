import { Box, Checkbox, SelectChangeEvent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../Custom/CustomTextField";
import CustomRadioButtonsGroup from "../Custom/CustomRadioButtonsGroup";
import { ChangeEvent, useEffect, useState } from "react";
import FormActionButton from "../Actions/FormActionButton";
import DisposeLevelHeader from "./DisposeLevelHeader";
import { PostDispositionDTO } from "../../services/dto/PostDispositionDTO";
import { DISPOSITION_SERVICE } from "../../services/DispositionService";
import { disposeLevels, disposeTypes } from "../../pages/ManageDispose";
import CustomCheckBox from "../Custom/CustomCheckBox";

const dispositionDTO: PostDispositionDTO = {
  name: "",
  isMultiLevel: false,
  callback: false,
  level: 1,
  parentId: null,
  disposeType: "standard",
};

const DisposeLevelOneForm = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [dispositionForm, setDispositionForm] = useState(dispositionDTO);
  const [validationError, setValidationError] = useState({
    name: "",
    isMultiLevel: "",
    callback: "",
    level: "",
    parentId: "",
    disposeType: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = (event as ChangeEvent<HTMLInputElement>).target;
    const { value } = event.target;
    if (id == "isMultiLevel") {
      const isSingleLevel = value == "single-level";
      setDispositionForm((prev) => ({
        ...prev,
        isMultiLevel: !isSingleLevel,
        callback: dispositionForm.disposeType === "none" ? true : false,
        disposeType: !isSingleLevel ? "standard" : prev.disposeType,
      }));
    } else {
      setDispositionForm((prev) => ({
        ...prev,
        [id]: value == "" ? null : value,
      }));
    }
    if (value === "" || value === null) {
      setValidationError((prev) => ({
        ...prev,
        [id]: "Error",
      }));
    } else {
      setValidationError((prev) => ({
        ...prev,
        [id]: "",
      }));
    }
    setErrorStatus(false);
  };

  const validateForm = (): boolean => {
    let isValid = true;
    for (const [key, value] of Object.entries(dispositionForm)) {
      if (value === "") {
        setValidationError((prev) => ({ ...prev, [key]: "Error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  function addDisposition(data: any) {
    setLoading(true);
    const onSuccess = (data: any) => {
      const response = data.data;
      const error_status = response.error_status;
      if (error_status === true) {
        const message = response.message;
        setErrorStatus(true);
        setErrorMessage(message);
      } else {
        setErrorStatus(false);
        navigate(-1);
      }
      setLoading(false);
    };
    const onError = (err: any) => setLoading(false);
    DISPOSITION_SERVICE.postDisposition(onSuccess, onError, data);
  }

  const handleSubmit = () => {
    if (!validateForm()) return;
    addDisposition({
      ...dispositionForm,
      callback: dispositionForm.disposeType === "none" ? true : false,
    });
  };

  return (
    <>
      <DisposeLevelHeader disposeLevel="1" />
      {Object.entries(validationError).some(
        ([key, value]) => value === "Error"
      ) ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}>
          Please fill required fields
        </Typography>
      ) : null}
      {errorStatus === true ? (
        <Typography
          color="error"
          sx={{ fontFamily: "Roboto", fontSize: { xs: "14px", md: "16px" } }}>
          {errorMessage}
        </Typography>
      ) : null}

      <Box
        maxWidth={{ xs: "100%", sm: "413px" }}
        pt={{ xs: "10px", md: "20px" }}
        pb="8px">
        <CustomTextField
          id="name"
          label="Name"
          required
          onChange={handleInputChange}
          value={dispositionForm.name}
          error={validationError.name}
        />
      </Box>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <CustomRadioButtonsGroup
          title="Dispose Level*"
          id="isMultiLevel"
          values={disposeLevels}
          gap={11}
          onChange={handleInputChange}
          error={Boolean(validationError.isMultiLevel)}
          selectedValue={
            Boolean(dispositionForm.isMultiLevel)
              ? "multi-level"
              : "single-level"
          }
        />
        <span style={{ height: 20 }} />
        {!dispositionForm.isMultiLevel && (
          <>
            {/* <CustomCheckBox
            disabled={dispositionForm.isMultiLevel}
            checked={dispositionForm.callback}
            id="callback"
            onChange={(event: any) => {
              if (dispositionForm.isMultiLevel) return;
              setDispositionForm((prev) => ({
                ...prev,
                callback: event.target.checked,
              }));
            }}
            title="CallBacks"
          /> */}

            <CustomRadioButtonsGroup
              title="Dispose type*"
              id="disposeType"
              values={disposeTypes}
              gap={11}
              onChange={handleInputChange}
              selectedValue={dispositionForm.disposeType}
            />
          </>
        )}
      </Box>
      <Box display="flex" gap={2} pt="25px">
        <FormActionButton
          title="Save"
          variant="contained"
          onClick={handleSubmit}
          loading={loading}
        />
        <FormActionButton
          disabled={loading}
          title="Cancel"
          variant="outlined"
          onClick={() => navigate(-1)}
        />
      </Box>
    </>
  );
};

export default DisposeLevelOneForm;
