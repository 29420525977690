import { PayloadAction } from "@reduxjs/toolkit";
import { TAssignCallback } from "../types/assignCallback";

function set(state: TAssignCallback, action: PayloadAction<TAssignCallback>) {
  return action.payload;
}

function updateAgentId(state: TAssignCallback, action: PayloadAction<number>) {
  return { ...state, agentId: action.payload };
}

function updateCallbacks(
  state: TAssignCallback,
  action: PayloadAction<number[]>
) {
  return { ...state, id: action.payload };
}

function updateAgentFilter(
  state: TAssignCallback,
  action: PayloadAction<number>
) {
  return { ...state, agentFilterId: action.payload };
}

export const ASSIGN_CALLBACK_REDUCERS = {
  set,
  updateAgentId,
  updateCallbacks,
  updateAgentFilter,
};

export type TAssignCallbackReducer = typeof ASSIGN_CALLBACK_REDUCERS;
