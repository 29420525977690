import { Box, Typography, useTheme } from "@mui/material";

const VerticalTableCell = ({
  position,
  title,
  subtitle,
  textTransform = "capitalize",
  onClick,
}: {
  position: "l" | "r";
  title?: string;
  subtitle?: string;
  textTransform?: "lowercase" | "uppercase" | "initial" | "capitalize";
  onClick?: any;
}) => {
  const theme = useTheme();
  return (
    <Box flexGrow={position === "l" ? 1 : 0} display="block">
      <Typography
        onClick={onClick}
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "14px",
          textAlign: position === "l" ? "left" : "right",
          textTransform: textTransform,
          ":hover": { cursor: onClick && "pointer" },
        }}
      >
        {title}
      </Typography>
      <Typography
        onClick={onClick}
        variant="body2"
        sx={{
          pt: title && "2px",
          color: theme.palette.common.black,
          textAlign: position === "l" ? "left" : "right",
          lineHeight: "22px",
          textTransform: textTransform,
          ":hover": { cursor: onClick && "pointer" },
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default VerticalTableCell;
