import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CallProvider } from "../../lib/CallProvider";
import { useAppSelector } from "../../state/hooks";
import { TClientConfig } from "../../state/types/TClientConfig";
import { userSlice } from "../../state/user/userSlice";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../constants";
import { CallProviderContext } from "../context/CallProviderContext";
import { UserDetails } from "../../state/types/user";

export const useCallProvider = () => {
  const { setCallProvider, callProvider } = useContext(CallProviderContext);
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);

  const attemptReconnect = () => {
    if (!callProvider?.ua.isConnected() || !callProvider?.ua.isRegistered()) {
      callProvider?.ua.start();
    }
    if (callProvider?.isRegistered()) {
      // callProvider.joinSelfBridge(user.userDetails.bridge);
    }
  };

  useEffect(() => {
    const client_config_str = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.CLIENT_CONFIG
    );
    const client_config: TClientConfig = JSON.parse(
      client_config_str === null ? "{}" : client_config_str
    );

    const user_details_str = localStorage.getItem(
      LOCALSOTRAGE_KEYS_CONSTANTS.USER
    );
    const user_details: UserDetails = JSON.parse(
      user_details_str === null ? "{}" : user_details_str
    );

    if (callProvider === null) {
      const newCallProvider = new CallProvider(
        client_config.JSSIP_WS,
        client_config.JSSIP_URI,
        user_details.webrtc_password,
        user_details.webrtc_username,
        user_details.accessLevel
      );
      setCallProvider(newCallProvider);

      newCallProvider.ua.on("connected", () => {
        dispatch(userSlice.actions.updateWebRTCConnStatus(true));
      });

      newCallProvider.ua.on("disconnected", () => {
        setTimeout(attemptReconnect, 10000);
        dispatch(userSlice.actions.updateWebRTCConnStatus(false));
      });
    }
  }, [callProvider]);

  return callProvider;
};
