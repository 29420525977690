import { Box } from "@mui/material";
import { useState } from "react";
import CustomTabPanel from "../../Custom/CustomTabPanel";
import DisposeTab from "../Tabs/DisposeTab";
import DispositionPopUpTabs from "./DispositionPopUpTabs";
import EditTab from "../Tabs/EditTab";
import DispositionPopUpHeader from "./DispositionPopUpHeader";

const DispositionPopUpContent = ({
  setOpen,
  handleBreak,
}: {
  setOpen: Function;
  handleBreak?: Function;
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (tabValue: number) => setSelectedTab(tabValue);

  return (
    <Box px="2%" pb="10px" height={"400px"}>
      <DispositionPopUpTabs
        value={selectedTab}
        onChange={handleTabChange}
        setOpen={setOpen}
      />
      <Box pt={{ xs: "20px", md: "0" }} pb={{ xs: "0", md: "30px" }}>
        <DispositionPopUpHeader
          title={selectedTab === 0 ? "SMS will be sent to the customer" : ""}
        />
      </Box>
      <CustomTabPanel value={selectedTab} index={0}>
        <DisposeTab handleBreak={handleBreak} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <EditTab />
      </CustomTabPanel>
    </Box>
  );
};

export default DispositionPopUpContent;
