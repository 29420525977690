import phone from "phone";

function validateRegex(value: string, regex: RegExp) {
  if (value.match(regex)) return true;
  return false;
}

function validateLength(value: string, length: number) {
  if (value.length <= length) return true;
  return false;
}

function validateEmail(email: string) {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return validateRegex(email, regex);
}

function validateAadharCard(aadharCard: string) {
  const regex = new RegExp("^[2-9]{1}[0-9]{11}$");
  return validateRegex(aadharCard, regex);
}

function validatePanCard(panCard: string) {
  const regex = new RegExp("[A-Z]{5}[0-9]{4}[A-Z]{1}");
  return validateRegex(panCard, regex) && validateLength(panCard, 10);
}

function validateMobile(mobile: string) {
  if (phone("+" + mobile).isValid) return true;
  return false;
}

function validateAlphabetical(value: string, length: number) {
  const regex = /^[A-Za-z]+$/;
  return validateRegex(value, regex) && validateLength(value, length);
}

function validateAlphaNumerical(value: string, length: number) {
  const regex = new RegExp("^[A-Za-z0-9? ,_-]+$");
  return validateRegex(value, regex) && validateLength(value, length);
}

function validateNumerical(value: string, length: number) {
  const regex = /^\d+$/;
  return validateRegex(value, regex) && validateLength(value, length);
}

export const VALIDATOR = {
  validateEmail,
  validateAadharCard,
  validatePanCard,
  validateMobile,
  validateAlphabetical,
  validateAlphaNumerical,
  validateNumerical,
};
