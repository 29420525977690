import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { callSlice } from "../../state/call/callSlice";
import { useAppDispatch } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import { CallStatus } from "../../state/types/call";
import { LOCALSOTRAGE_KEYS_CONSTANTS, SOCKET_EVENTS } from "../constants";
import { SocketContext } from "../context/SocketContext";
import { DOMAIN_CONSTANTS } from "../domain";
import { ALL_ROUTES } from "../routes";

export const useSocket = () => {
  const { socket, setSocket } = useContext(SocketContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const existingAuthToken = localStorage.getItem(
    LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN
  );

  useEffect(() => {
    const callCreatedListeneer = (data: any) => {
      dispatch(callSlice.actions.updateCallId(data?.call?.id));
      dispatch(callSlice.actions.updateCallCode(data?.call?.callCode));
      dispatch(leadSlice.actions.setLead(data?.call?.Lead));
      dispatch(callSlice.actions.updateCallStatus(CallStatus.CALL_CREATED));

      if (SOCKET_EVENTS.CALL_CREATED === "CALL_CREATED") {
        //localStorage.setItem("call_code", data?.call?.callCode);
      }
    };

    const logoutListener = () => {
      navigate(ALL_ROUTES.LOGOUT);
    };

    if (socket === null) {
      const newSocket = io(DOMAIN_CONSTANTS.SOCKET_URL, {
        withCredentials: true,
        auth: { token: existingAuthToken },
      });
      setSocket(newSocket);

      newSocket.on("disconnect", () => {
        console.log({ listens: "disconnect" });
      });

      newSocket.on(SOCKET_EVENTS.LOGOUT, logoutListener);
    } else {
      socket.on(SOCKET_EVENTS.CALL_CREATED, callCreatedListeneer);
    }

    return () => {
      socket?.off(SOCKET_EVENTS.CALL_CREATED, callCreatedListeneer);
    };
  }, [socket, existingAuthToken]);

  return { socket };
};
