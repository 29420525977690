import { DateTime } from "luxon";

type Timer = {
  days?: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliSeconds?: number;
};

const setZone = (dateInISO: string): DateTime => {
  return DateTime.fromISO(dateInISO).setZone("system");
};

const formatNumber = (value: number) => {
  return (`00` + value).slice(-2);
};

const addSuffix = (timeStamp: number, suffix: string) => {
  return `${timeStamp} ${suffix}`;
};

const millisToTime = (millis: number): Timer => {
  const floor = (number: number) => Math.floor(number);
  return {
    days: floor(millis / 1000 / 60 / 60 / 24),
    hours: floor(millis / 1000 / 60 / 60),
    minutes: floor((millis / 1000 / 60) % 60),
    seconds: floor((millis / 1000) % 60),
    milliSeconds: Math.round(millis / 10),
  };
};

function formatTimer(timeInMillis: number, showMillis?: boolean) {
  const { hours, minutes, seconds, milliSeconds } = millisToTime(timeInMillis);
  return showMillis
    ? `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(
        seconds
      )}:${formatNumber(milliSeconds ?? 0)}`
    : `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(
        seconds
      )}`;
}

function formatDate(dateInISO: string) {
  return setZone(dateInISO).toFormat("dd/LL/yyyy");
}

function formatDateTime(dateInISO: string) {
  return setZone(dateInISO).toFormat("dd/LL/yyyy - hh:mm a");
}

function formatTimeStamp(
  startTime: Date,
  endTime: Date,
  suffixPostTime?: string,
  suffixPastTime?: string
) {
  let deltaTime = endTime.getTime() - startTime.getTime();
  let affix = "";
  if (deltaTime < 0) {
    affix += suffixPastTime ?? "";
    deltaTime *= -1;
  } else {
    affix += suffixPostTime ?? "";
  }
  const { days, hours, minutes } = millisToTime(deltaTime);

  const format = (value: number, suffix: string): string => {
    if (value <= 1) return addSuffix(value, suffix);
    return addSuffix(value, `${suffix}s`);
  };
  const formatHandler = () => {
    if (days === 0) {
      if (hours === 0) return format(minutes, "min");
      else return format(hours, "hr");
    } else return format(days ? days : 0, "day");
  };
  return formatHandler() + ` ${affix}`;
}

const filterPassedtime = (time: Date) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate.getTime() < selectedDate.getTime();
};

export const FORMATTER = {
  setZone,
  formatDate,
  formatDateTime,
  formatTimer,
  formatTimeStamp,
  filterPassedtime,
};
