import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ActiveProcessIcon from "../../assets/images/admin-dash/ActiveProcessIcon";
import AverageHandilingIcon from "../../assets/images/admin-dash/AverageHandilingIcon";
import CallDisposedIcon from "../../assets/images/admin-dash/CallDisposedIcon";
import TotalCallsMadeIcon from "../../assets/images/admin-dash/TotalCallsMadeIcon";
import DashboardTile from "../../components/Custom/DashboardTile";
import UsersLoggedinTile from "../../components/Dashboard/UsersLoggedinTile";
import UGDateRangePicker from "../../components/EVCustoms/UGDateRangePicker/UGDateRangePicker";
import { ADMIN_SERVICES } from "../../services/AdminServices";
import { toDesiredFormat } from "../../SupportingFiles/HelpingFunction";

const AdminDashboard = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [startEndDate, setStartEndDate] = useState([new Date(), new Date()]);
  const [overViewData, setOverViewData] = useState<{
    loggedInUsers: number;
    activeProcess: number;
    totalCallMade: number;
    averageHandilingTime: number;
    callsDisposedbyAgents: number;
  }>({
    loggedInUsers: 0,
    activeProcess: 0,
    totalCallMade: 0,
    averageHandilingTime: 0,
    callsDisposedbyAgents: 0,
  });

  useEffect(() => {
    loadDashboardOverview();
  }, [startEndDate]);

  const loadDashboardOverview = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const errorStatus = data.errorStatus;

      if (errorStatus === false) {
        const dataResponse = data.data;
        setOverViewData(dataResponse);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    setLoader(true);
    ADMIN_SERVICES.getLeadsTaken(onSuccess, onError, {
      startDate:
        toDesiredFormat(startEndDate[0]).substring(0, 10) + "T00:00:00.000Z",
      endDate:
        toDesiredFormat(startEndDate[1]).substring(0, 10) + "T23:59:00.000Z",
    });
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginRight: "-11px",
        }}>
        <Box style={{ flexGrow: 1 }}>
          <Typography pb="30px" variant="h5">
            Dashboard
          </Typography>
        </Box>
        <UGDateRangePicker value={startEndDate} setValue={setStartEndDate} />
      </Box>
      <Box display="flex" flexWrap="wrap">
        <UsersLoggedinTile />
        <DashboardTile
          loader={loader}
          title="Active Process"
          value={overViewData.activeProcess}
          icon={<ActiveProcessIcon />}
        />
        <DashboardTile
          loader={loader}
          title="Total Calls Made"
          value={overViewData.totalCallMade}
          icon={<TotalCallsMadeIcon />}
        />
        <DashboardTile
          loader={loader}
          title="Calls Disposed by Agents"
          value={overViewData.callsDisposedbyAgents}
          icon={<CallDisposedIcon />}
        />
        <DashboardTile
          loader={loader}
          title="Average Handiling Time"
          value={overViewData.averageHandilingTime}
          icon={<AverageHandilingIcon />}
        />
      </Box>
    </>
  );
};

export default AdminDashboard;
