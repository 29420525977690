import {
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DarkTooltip } from "../DarkToolTip";

const FloatingButton = ({
  color,
  Icon,
  sx,
  show = true,
  onClick,
  isActive = false,
  loading = false,
  transform = "scale(1)",
  width = "50px",
  height = "50px",
  icon_sx,
  tooltip,
  activeToolTip,
}: {
  color: any;
  Icon: any;
  sx?: any;
  show?: boolean;
  onClick?: any;
  isActive?: boolean;
  loading?: boolean;
  transform?: any;
  width?: string;
  height?: string;
  icon_sx?: any;
  tooltip?: string;
  activeToolTip?: string;
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <DarkTooltip title={isActive ? activeToolTip : tooltip}>
      <IconButton
        onClick={() => {
          !loading && onClick && onClick();
        }}
        size="small"
        color="inherit"
        sx={{
          display: show ? "flex" : "none",
          backgroundColor: isActive ? color : theme.palette.common.white,
          border: `1px solid ${color}`,
          position: "fixed",
          top: "180px",
          color: "white",
          width: { md: width, xs: 36 },
          height: { md: height, xs: 36 },
          borderRadius: { xs: "10px", md: "10px 0 0 10px" },
          zIndex: 100,
          fontSize: 10,
          padding: { md: 1.5, xs: 1.2 },
          right: { xs: "-4px", md: "-1px" },
          ":hover": {
            backgroundColor: isActive ? color : theme.palette.common.white,
          },
          ...sx,
        }}
      >
        {
          <Box
            width={{ xs: "21px", md: "32px" }}
            height={{ xs: "16.5px", md: "25px" }}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            sx={{ transform: transform }}
          >
            {loading ? (
              <CircularProgress
                size={smallScreen ? "15px" : "25px"}
                sx={{
                  color: "common.white",
                }}
              />
            ) : (
              <Icon
                color={isActive ? theme.palette.common.white : color}
                fontSize={smallScreen ? "medium" : "large"}
                sx={{
                  color: isActive ? theme.palette.common.white : color,
                  ...icon_sx,
                }}
              />
            )}
          </Box>
        }
      </IconButton>
    </DarkTooltip>
  );
};

export default FloatingButton;
