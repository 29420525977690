import { useEffect, useState } from "react";
import { SOCKET_EVENTS } from "../../shared/constants";
import { useSocket } from "../../shared/hooks/useSocket";
import Loader from "./Loader";

const CallbackInitiatingLoader = () => {
  const [time, setTime] = useState<number | null>(null);
  const { socket } = useSocket();

  useEffect(() => {
    const listener = (data: any) => {
      setTime(data.time);
    };
    socket?.on(SOCKET_EVENTS.CALLBACK_INITIATING, listener);

    return () => {
      socket?.off(SOCKET_EVENTS.CALLBACK_INITIATING, listener);
    };
  }, []);

  return time !== null ? (
    <Loader title={`Dialing Callback in ${time}...`} />
  ) : (
    <></>
  );
};

export default CallbackInitiatingLoader;
