import axios from "axios";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../shared/constants";
import { URL_CONSTANTS } from "../shared/urls";
import { GetAPI, PatchAPI, PostAPI } from "./API";

async function loginUser(
  setAuthToken: any,
  setError: any,
  loginDTO: any,
  setUserDetails: any
) {
  await axios
    .post(URL_CONSTANTS.POST_LOGIN, loginDTO, {
      withCredentials: true,
      headers: {
        domain_name: window.location.hostname,
      },
    })
    .then((response) => {
      setAuthToken(response.data);
      localStorage.setItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN,
        response.data.token
      );
      setUserDetails(response.data);
    })
    .catch((error) => {
      setError(error);
    });
}

async function getRefreshToken(onSuccess: any, onError: any) {
  try {
    const response = await axios.post(
      URL_CONSTANTS.REFRESH_TOKEN,
      {},
      { withCredentials: true }
    );
    return onSuccess(response.data);
  } catch (ex) {
    console.log({ ex });
    onError(ex);
  }
}

function logout(onSuccess: any, setError: any) {
  PostAPI.call(
    URL_CONSTANTS.LOGOUT,
    {},
    () => {
      onSuccess();
    },
    {},
    (err: any) => {
      setError(err);
    }
  );
}

function logutOutUsers(onSuccess: any, onError: any, dto: any) {
  PostAPI.call(URL_CONSTANTS.LOGOUT_USERS, dto, onSuccess, {}, onError);
}

function getProfileDetails(onSuccess: Function, onError: Function) {
  GetAPI.call(
    URL_CONSTANTS.GET_PROFILE_DETAILS,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}
function updateShortcutEnableStatus(
  onSuccess: Function,
  onError: Function,
  dto: any
) {
  PatchAPI.call(
    URL_CONSTANTS.UPDATE_SHORTCUT_STATUS,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
}

export const AUTH_SERVICE = {
  logout,
  loginUser,
  logutOutUsers,
  getRefreshToken,
  getProfileDetails,
  updateShortcutEnableStatus,
};
