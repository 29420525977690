import { createSlice } from "@reduxjs/toolkit";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../../shared/constants";
import {
  TUserState,
  UserAccessLevel,
  UserBreakReason,
  UserStateStatus,
} from "../types/user";
import { TUserReducer, USER_REDUCERS } from "./userReducers";

const existingState = localStorage.getItem(
  LOCALSOTRAGE_KEYS_CONSTANTS.USER_STATE
);

function getExistingState() {
  let state = UserStateStatus.LOGIN;
  (Object.keys(UserStateStatus) as Array<keyof typeof UserStateStatus>).map(
    (key) => {
      if (key == existingState) {
        state = UserStateStatus[key];
      }
    }
  );
  return state;
}

export const userSlice = createSlice<TUserState, TUserReducer>({
  name: "user",
  initialState: {
    status: getExistingState(),
    breakReason: UserBreakReason.GENERAL,
    manualCallStatus: false,
    micErrorStatus: false,
    webRTCConnStatus: false,
    shortcutKeys: false,
    userDetails: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      bridge: "",
      accessLevel: UserAccessLevel.AGENT,
      webrtc_username: "",
      webrtc_password: "",
    },
  },
  reducers: USER_REDUCERS,
});
