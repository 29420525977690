import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Box, IconButton, Switch } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../assets/images/EditIcon";
import ForceLougoutIcon from "../../assets/images/ForceLougoutIcon";
import LeadsetDeleteIcon from "../../assets/images/LeadsetDeleteIcon";
import { UserState } from "../../pages/ManageUsers";
import { AUTH_SERVICE } from "../../services/AuthService";
import { USER_SERVICE } from "../../services/UserService";
import { GetUserResponseDTO } from "../../services/dto/GetUserResponseDTO";
import { ALL_ROUTES } from "../../shared/routes";
import SimpleConfirmationPopUp from "../ConfirmationPopUP/SimpleConfirmationPopUp";
import CustomActionButton from "../Custom/CustomActionButton";
import VerticalTableCell from "../Table/VerticalTableCell";
import VerticalTableRow from "../Table/VerticalTableRow";
import VerticalTableTile from "../Table/VerticalTableTile";
import LightTooltip from "../LightToolTip";
import ResetUserPass from "../ResetUserPass/ResetUserPass";
import ProcessInfoPopup from "./ProcessInfoPopup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import KeyIcon from "@mui/icons-material/Key";
import { useAppDispatch } from "../../state/hooks";
import { userDetailsFormSlice } from "../../state/user-form/userFormSlice";
import { TUserDetailsFormState } from "../../state/types/userForm";

const UsersTableMobile = ({
  userState,
  users,
  onAction,
  startIndex,
  onUpdate,
  getUsersAPI,
}: {
  userState: UserState;
  users: GetUserResponseDTO[];
  onAction: Function;
  startIndex: number;
  onUpdate: (users: GetUserResponseDTO[]) => void;
  getUsersAPI: Function;
}) => {
  const reportlabel = { inputProps: { "aria-label": "Daily Summary Report" } };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [infoPopupStatus, setInfoPopupStatus] = useState<boolean>(false);
  const [resetPassStatus, setResetPassStatus] = useState<boolean>(false);
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const [openStatusComfirmation, setOpenStatusConfirmation] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openRestoreconfirmation, setOpenRestoreConfirmation] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [restoreLoader, setRestoreLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState<GetUserResponseDTO | null>(
    null
  );
  function handleLogoutUsers(userIds: number[]) {
    setLogoutLoader(true);
    const dto = { userIds: userIds };
    const onSuccess = (data: any) => {
      setOpenLogoutConfirmation(false);
      setLogoutLoader(false);
      updateLoggedOutUser();
    };
    const onError = (err: any) => setLogoutLoader(false);
    AUTH_SERVICE.logutOutUsers(onSuccess, onError, dto);
  }

  const handleUserStatus = ({ user }: { user: GetUserResponseDTO }) => {
    setStatusLoader(true);
    const dto = { userIds: [user.id] };
    const onSuccess = () => {
      setOpenStatusConfirmation(false);
      setStatusLoader(false);
      onAction();
    };
    const onError = () => setStatusLoader(false);
    if (user.status === "ENABLED") {
      AUTH_SERVICE.logutOutUsers(() => {}, onError, dto);
      USER_SERVICE.disableUser(onSuccess, onError, dto);
    } else {
      USER_SERVICE.enableUser(onSuccess, onError, dto);
    }
  };
  const capitalize = (str: string) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
  };

  function handleDeleteUser() {
    setDeleteLoader(true);
    const onSuccess = () => {
      setOpenDeleteConfirmation(false);
      setDeleteLoader(false);
      onAction();
    };
    const onError = () => setDeleteLoader(false);
    USER_SERVICE.deleteUser(
      onSuccess,
      onError,
      selectedUser?.id.toString() ?? ""
    );
  }

  function handleRestoreUser(id: string) {
    setRestoreLoader(true);
    const onSuccess = () => {
      setOpenRestoreConfirmation(false);
      setRestoreLoader(false);
      onAction();
    };
    const onError = () => setRestoreLoader(false);
    USER_SERVICE.restoreUser(onSuccess, onError, id);
  }

  function updateLoggedOutUser() {
    const updatedUsers = users.map((user) => {
      if (user.id !== selectedUser?.id) {
        return { ...user };
      }
      return { ...user, isOnline: false };
    });
    onUpdate(updatedUsers);
  }

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const handleEdit = (userDetailsForm: TUserDetailsFormState) => {
    dispatch(userDetailsFormSlice.actions.setUserDetailsForm(userDetailsForm));
    navigate(ALL_ROUTES.EDIT_USER_DETAILS);
  };

  function updateUserDailySummaryStatus(id: number, status: string) {
    const onSuccess = (data: any) => {
      getUsersAPI();
    };
    const option_toastify = {
      error: "Uh-oh ! Can't Update, Check user email ID",
      pending: "Hang On ! Updating The Status",
      success: "Voila ! Status Updated",
      successDuration: 1000,
      errorDuration: 3000,
    };
    const onError = (err: any) => {
      console.log(err);
      getUsersAPI();
    };

    USER_SERVICE.updateUserDailySummaryStatus(
      onSuccess,
      onError,
      {
        id: id,
        status: status,
      },
      option_toastify
    );
  }

  return (
    <>
      <Box sx={{ display: { md: "none", xs: "block" } }}>
        {users.map((user, index) => (
          <VerticalTableTile key={index}>
            <VerticalTableRow>
              <VerticalTableCell
                position="l"
                title={
                  startIndex == 0 && index < 9
                    ? "0" + (index + 1)
                    : `${startIndex + (index + 1)}`
                }
              />
            </VerticalTableRow>
            <VerticalTableRow>
              <VerticalTableCell
                position="l"
                title="Name"
                subtitle={user.firstName + " " + user.lastName}
              />
              <VerticalTableCell
                position="r"
                title="User Name"
                subtitle={user.username}
                textTransform={"initial"}
              />
            </VerticalTableRow>
            <VerticalTableRow>
              <VerticalTableCell
                position="l"
                title="Access Level"
                subtitle={capitalize(user.accessLevel)}
              />
              <VerticalTableCell
                position="r"
                title="User Email"
                subtitle={capitalize(user.email)}
                textTransform={"lowercase"}
              />
              {/* <SettingsDropDown /> */}
            </VerticalTableRow>
            <VerticalTableRow>
              <Box display={"flex"} alignItems="center">
                <Box sx={{ mt: 2 }}>
                  <CustomActionButton
                    backgroundColor={
                      user.status === "DISABLED" ? "#5ECE62" : "#EB5757"
                    }
                    title={user.status === "ENABLED" ? "Disable" : "Enable"}
                    onClick={() => {
                      setOpenStatusConfirmation(true);
                      setSelectedUser(user);
                    }}
                  />
                </Box>
              </Box>
              <Box
                width="100%"
                display={"flex"}
                alignItems="flex-end"
                justifyContent={"flex-end"}>
                {user.status === "ENABLED" ? (
                  <>
                    {/* <IconButton
                      onClick={() =>
                        navigate(ALL_ROUTES.EDIT_USER_DETAILS, {
                          state: { dto: user },
                        })
                      }>
                      <EditIcon color="#5ECE62" />
                    </IconButton> */}
                    <>
                      {/* {user.isOnline ? (
                          <IconButton
                            onClick={() => {
                              setSelectedUser(user);
                              setOpenLogoutConfirmation(true);
                            }}>
                            <ForceLougoutIcon />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <ForceLougoutIcon color="grey" />
                          </IconButton>
                        )} */}
                      {user.status === "ENABLED" ? (
                        <Box>
                          <LightTooltip title="Edit User">
                            <IconButton
                              onClick={() => {
                                handleEdit(user);
                              }}>
                              <BorderColorIcon
                                sx={{ color: "#5ECE62", height: 18 }}
                              />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip title="Reset password">
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setResetPassStatus(true);
                              }}>
                              <KeyIcon height="18" width="18" />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip title="Click to view user processes">
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setInfoPopupStatus(true);
                              }}>
                              <InfoOutlinedIcon
                                sx={{ color: "#4666FF", height: 18 }}
                              />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip
                            title={
                              user.summaryStatus === "DISABLED"
                                ? "Enable Daily Summary"
                                : "Disable Daily Summary"
                            }>
                            <Switch
                              defaultChecked={
                                user.summaryStatus === "DISABLED" ? false : true
                              }
                              value={
                                user.summaryStatus === "DISABLED" ? "on" : "off"
                              }
                              {...reportlabel}
                              disabled={
                                user.accessLevel === "AGENT" ? true : false
                              }
                              onChange={(e) => {
                                updateUserDailySummaryStatus(
                                  user.id,
                                  e.target.value === "off"
                                    ? "DISABLED"
                                    : "ENABLED"
                                );
                              }}
                            />
                          </LightTooltip>
                          {user.accessLevel === "AGENT" ? (
                            <>
                              {user.isOnline ? (
                                <LightTooltip title="Force Logout The User">
                                  <IconButton
                                    onClick={() => {
                                      setSelectedUser(user);
                                      setOpenLogoutConfirmation(true);
                                    }}>
                                    <ForceLougoutIcon height="18" width="18" />
                                  </IconButton>
                                </LightTooltip>
                              ) : null}
                            </>
                          ) : null}
                        </Box>
                      ) : (
                        <Box>
                          {userState === UserState.DELETED ? (
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setOpenRestoreConfirmation(true);
                              }}>
                              <SettingsBackupRestoreIcon
                                sx={{ fontSize: "18px", color: "#5ECE62" }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setOpenDeleteConfirmation(true);
                              }}>
                              <LeadsetDeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                      )}
                    </>
                  </>
                ) : (
                  <>
                    {userState === UserState.DELETED ? (
                      <IconButton
                        onClick={() => {
                          setSelectedUser(user);
                          setOpenRestoreConfirmation(true);
                        }}>
                        <SettingsBackupRestoreIcon
                          sx={{ fontSize: "18px", color: "#5ECE62" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setSelectedUser(user);
                          setOpenDeleteConfirmation(true);
                        }}>
                        <LeadsetDeleteIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
            </VerticalTableRow>
          </VerticalTableTile>
        ))}
      </Box>
      {selectedUser && (
        <>
          <SimpleConfirmationPopUp
            loading={logoutLoader}
            open={openLogoutConfirmation}
            setOpen={setOpenLogoutConfirmation}
            value={selectedUser.username}
            action={"Logout"}
            onAction={() => {
              handleLogoutUsers([selectedUser.id]);
            }}
          />
          <SimpleConfirmationPopUp
            loading={statusLoader}
            action={selectedUser.status === "DISABLED" ? "Enable" : "Disable"}
            value={selectedUser.username}
            open={openStatusComfirmation}
            setOpen={setOpenStatusConfirmation}
            onAction={() => handleUserStatus({ user: selectedUser })}
          />
          <SimpleConfirmationPopUp
            loading={deleteLoader}
            action={"Delete"}
            value={selectedUser.username}
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            onAction={handleDeleteUser}
          />
          <SimpleConfirmationPopUp
            loading={restoreLoader}
            action="Restore"
            value={selectedUser.username}
            open={openRestoreconfirmation}
            setOpen={setOpenRestoreConfirmation}
            onAction={() => handleRestoreUser(selectedUser?.id.toString())}
          />
          <ResetUserPass
            user={selectedUser}
            open_status={resetPassStatus}
            attrName={setResetPassStatus}
            value_update={updateMasterState}
          />
          <ProcessInfoPopup
            open_status={infoPopupStatus}
            attrName={setInfoPopupStatus}
            value_update={updateMasterState}
            selectedUser={selectedUser}
          />
        </>
      )}
    </>
  );
};

export default UsersTableMobile;
