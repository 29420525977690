import { TableBody, TableCell } from "@mui/material";
import StyledTableRow from "../Table/StyledTableRow";

const DataNotFound = ({
  colSpans,
  value = "Data not found",
}: {
  colSpans: number;
  value?: string;
}) => {
  return (
    <>
      <TableBody>
        <StyledTableRow>
          <TableCell colSpan={colSpans} align="center" sx={{ border: "none" }}>
            {value}
          </TableCell>
        </StyledTableRow>
      </TableBody>
    </>
  );
};

export default DataNotFound;
