import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { toDesiredFormat } from "../../../SupportingFiles/HelpingFunction";
import { GetDispositionDTO } from "../../../services/dto/GetDispositionDTO";
import { disposeCallSlice } from "../../../state/dispose/disposeCallSlice";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { TDisposeCallState } from "../../../state/types/disposeCall";
import CustomTextField from "../../Custom/CustomTextField";
import SearchableDropdown from "../../Custom/SearchableDropdown";
import CustomDatePicker from "../CustomDatePicker";
import CustomTimePicker from "../CustomTimePicker";
import DisposeMenu from "../DisposeMenu";

const DisposeTab = ({ handleBreak }: { handleBreak?: Function }) => {
  const { disposition, message } = useAppSelector(
    (state) => state.dispositions
  );
  const disableDispositions = disposition.length === 0;
  const [selectedDispositionOne, setSelectedDispositionOne] =
    useState<GetDispositionDTO | null>(null);
  const [selectedDispositionTwo, setSelectedDispositionTwo] =
    useState<GetDispositionDTO | null>(null);
  const [selectedDispositionThree, setSelectedDispositionThree] =
    useState<GetDispositionDTO | null>(null);
  const dispatch = useAppDispatch();
  const disposeForm = useAppSelector((state) => state.disposeCall);
  const mobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [validationError, setValidationError] = useState({
    dispositionId: "",
    callBackTime: "",
    comments: "",
  });
  const hideLevelTwo =
    selectedDispositionOne === null ||
    selectedDispositionOne.children.length == 0;
  const hideLevelThree =
    selectedDispositionTwo === null ||
    selectedDispositionTwo.children.length == 0;

  const hideDateTime: boolean = useMemo(() => {
    let hide = true;
    // const disposition_data: any = disposeForm.dispositionId;
    // if (hideLevelTwo && hideLevelThree) {
    //   const selectedDisposition = dispositions.find(
    //     (val) => val.id === disposition_data?.value
    //   );
    //   if (selectedDisposition?.callback) {
    //     hide = false;
    //   }
    // }
    // if (!hideLevelTwo) {
    //   const selectedDisposition = selectedDispositionOne?.children.find(
    //     (val) => val.id === disposition_data?.value
    //   );
    //   if (selectedDisposition?.callback) {
    //     hide = false;
    //   }
    // }
    // if (!hideLevelThree) {
    //   const selectedDisposition = selectedDispositionTwo.children.find(
    //     (val) => val.id === disposition_data?.value
    //   );
    //   if (selectedDisposition?.callback) {
    //     hide = false;
    //   }
    // }

    if (
      selectedDispositionOne?.callback === true ||
      selectedDispositionTwo?.callback === true ||
      selectedDispositionThree?.callback === true
    ) {
      hide = false;
    } else {
      hide = true;
    }
    return hide;
  }, [
    disposeForm,
    selectedDispositionOne,
    selectedDispositionTwo,
    selectedDispositionThree,
  ]);

  const inputChangeHandler = (
    key: keyof TDisposeCallState,
    value: string | number
  ) => {
    dispatch(
      disposeCallSlice.actions.updateDisposeCall({
        key: key,
        value: value,
      })
    );
    if (value === "") {
      setValidationError((prev) => ({
        ...prev,
        [key]: "Error",
      }));
    } else {
      setValidationError((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    const mandatoryFields: any[] = ["dispositionId"];
    if (!hideDateTime) mandatoryFields.push("callBackTime");
    for (const [key, value] of Object.entries(disposeForm)) {
      if (!mandatoryFields.includes(key)) {
        setValidationError((prev) => ({ ...prev, [key]: "" }));
        continue;
      }
      if (key === "callBackTime") {
        if (disposeForm?.callBackTime) {
          if (new Date(disposeForm?.callBackTime) < new Date()) {
            setValidationError((prev) => ({
              ...prev,
              [key]: "Error",
            }));
            isValid = false;
          } else {
            setValidationError((prev) => ({
              ...prev,
              [key]: "",
            }));
          }
        }
      } else {
        if (value == "" || value == 0) {
          setValidationError((prev) => ({ ...prev, [key]: "Error" }));
          isValid = false;
        }
      }
    }
    return isValid;
  };

  useEffect(() => {
    console.log(
      "disposeForm.callBackTime",
      disposeForm.callBackTime,
      validationError,
      Boolean(validationError.callBackTime)
    );
    if (disposeForm?.callBackTime) {
      setValidationError({
        ...validationError,
        callBackTime:
          new Date(disposeForm.callBackTime) < new Date() ? "Error" : "",
      });
    }
  }, [disposeForm.callBackTime]);

  const clearDispositions = () => {
    setSelectedDispositionTwo(null);
    setSelectedDispositionThree(null);
  };

  const getSelectedDisposition = (
    dispositions: GetDispositionDTO[],
    id: string
  ): GetDispositionDTO | null => {
    let selectedDisposition = null;
    dispositions.map((disposition) => {
      if (disposition.id.toString() == id) {
        selectedDisposition = disposition;
      }
    });
    return selectedDisposition;
  };

  const dispositionOptionsMapper = (
    dispositions: GetDispositionDTO[] | undefined
  ) => {
    const options: { label: string; value: number; callback: boolean }[] = [];
    if (dispositions === undefined) return options;
    dispositions.map((disposition) => {
      if (disposition.disposeType !== "system") {
        options.push({
          label: disposition.name,
          value: disposition.id,
          callback: disposition.callback,
        });
      }
    });
    return options;
  };

  // useEffect(() => {
  //   getDispositionListById();
  // }, []);

  // const getDispositionListById = () => {
  //   const onSuccess = (data: any) => {
  //     const errorStatus = data.errorStatus;
  //     if (errorStatus === false) {
  //       const dispositions_data = data.dispositions;
  //       setErrorMessage("");
  //       dispatch(dispositionsSlice.actions.setDispositions(dispositions_data));
  //     } else {
  //       setErrorMessage(data.message);
  //       dispatch(dispositionsSlice.actions.setDispositions([]));
  //     }
  //   };

  //   const onError = (err: any) => {
  //     console.log(err);
  //   };

  //   DISPOSITION_SERVICE.getDispositionsByProcessId(
  //     onSuccess,
  //     onError,
  //     processId
  //   );
  // };

  return (
    <Box>
      <Typography sx={{ color: "#E5404F", marginBottom: "20px" }}>
        {message}
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={hideLevelThree ? "flex-start" : "space-between"}
        gap={{ xs: 2, md: hideLevelThree ? 2 : 0 }}>
        <SearchableDropdown
          label="First Level"
          disabled={disableDispositions}
          error={
            selectedDispositionOne == null &&
            validationError.dispositionId !== ""
          }
          values={dispositionOptionsMapper(disposition)}
          minWidth={257}
          selectedValue={selectedDispositionOne?.name.toString()}
          onChange={(value: any) => {
            const selectedDisposition = getSelectedDisposition(
              disposition,
              value?.value
            );
            setSelectedDispositionOne(selectedDisposition);
            if (selectedDisposition?.children.length == 0) {
              inputChangeHandler("dispositionId", value);
            } else {
              inputChangeHandler("dispositionId", 0);
            }
            clearDispositions();
          }}
          onClear={() => {
            setSelectedDispositionOne(null);
            setSelectedDispositionTwo(null);
            setSelectedDispositionThree(null);
          }}
        />
        <SearchableDropdown
          label="Second Level"
          error={
            selectedDispositionTwo == null &&
            validationError.dispositionId !== ""
          }
          disabled={hideLevelTwo}
          hidden={hideLevelTwo}
          values={dispositionOptionsMapper(selectedDispositionOne?.children)}
          minWidth={{ xs: 307, md: 257 }}
          selectedValue={selectedDispositionTwo?.name.toString()}
          onChange={(value: any) => {
            const selectedDisposition = getSelectedDisposition(
              selectedDispositionOne?.children ?? [],
              value?.value
            );
            setSelectedDispositionTwo(selectedDisposition);
            if (selectedDisposition?.children.length == 0) {
              inputChangeHandler("dispositionId", value);
            }
            setSelectedDispositionThree(null);
          }}
          onClear={() => {
            setSelectedDispositionThree(null);
            setSelectedDispositionTwo(null);
          }}
        />
        <SearchableDropdown
          disabled={hideLevelThree}
          hidden={hideLevelThree}
          label="Third Level"
          error={validationError.dispositionId !== ""}
          values={dispositionOptionsMapper(selectedDispositionTwo?.children)}
          minWidth={{ xs: 307, md: 257 }}
          selectedValue={selectedDispositionThree?.name.toString()}
          onChange={(value: any) => {
            inputChangeHandler("dispositionId", value);
            const selectedDisposition = getSelectedDisposition(
              selectedDispositionTwo?.children ?? [],
              value?.value
            );
            setSelectedDispositionThree(selectedDisposition);
          }}
          onClear={() => {
            setSelectedDispositionThree(null);
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        p="20px 0"
        justifyContent="center"
        alignItems={{ xs: "flex-start", md: "center" }}>
        <Typography
          sx={{
            pr: "30px",
            pb: { xs: "16px", md: 0 },
            fontSize: { xs: "16px", md: "18px" },
            display: hideDateTime ? "none" : { xs: "flex", md: "none" },
          }}>
          Set Time
        </Typography>
        <Box sx={{ width: "100%", height: "50px" }}>
          <Box
            display={"flex"}
            justifyContent="center"
            width="100%"
            alignItems={"center"}>
            <Typography
              sx={{
                pr: "30px",
                pb: { xs: "16px", md: 0 },
                fontSize: { xs: "16px", md: "18px" },
                display: hideDateTime ? "none" : { xs: "none", md: "flex" },
              }}>
              Set Time
            </Typography>
            <Box
              display={hideDateTime ? "none" : "flex"}
              justifyContent={{ xs: "flex-start", md: "center" }}
              width={{ xs: "100%", md: "350px" }}>
              <Box
                width={{
                  xs: "234px",
                  md: "205px",
                }}>
                <CustomDatePicker
                  label={!disposeForm.callBackTime ? "DD-MM-YYYY" : ""}
                  error={Boolean(validationError.callBackTime)}
                  selectedDate={disposeForm?.callBackTime ?? ""}
                  disabled={hideDateTime}
                  setSelectedDate={(date: Date) => {
                    inputChangeHandler("callBackTime", toDesiredFormat(date));
                  }}
                  mobileView={mobileView}
                  minDate={new Date()}
                />
              </Box>
              <Box
                width={{
                  xs: "121px",
                  md: "143px",
                }}>
                <CustomTimePicker
                  label={!disposeForm.callBackTime ? "00:00" : ""}
                  error={Boolean(validationError.callBackTime)}
                  selectedTime={disposeForm?.callBackTime ?? ""}
                  disabled={hideDateTime}
                  setSelectedTime={(date: Date) =>
                    inputChangeHandler("callBackTime", toDesiredFormat(date))
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <CustomTextField
        label="Comments..."
        id="comments"
        onChange={(event: any) => {
          inputChangeHandler("comments", event.target.value);
        }}
        multiline
        value={disposeForm.comments}
      />
      <Box display="flex" justifyContent={"center"}>
        <DisposeMenu
          validate={validateForm}
          handleBreak={handleBreak}
          callbackStatus={!hideDateTime}
        />
      </Box>
    </Box>
  );
};

export default DisposeTab;
