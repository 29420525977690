import { Box } from "@mui/material";
import { useState } from "react";
import { CALLBACK_SERVICE } from "../../services/CallbackService";
import { GetCallbackDTO } from "../../services/dto/GetCallbackDTO";
import { callSlice } from "../../state/call/callSlice";
import { callbackLogsSlice } from "../../state/callbackLogs/callbackLogsSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { leadSlice } from "../../state/lead/leadSlice";
import { userSlice } from "../../state/user/userSlice";
import CallButton from "../Actions/CallButton";
import CallButtonRounded from "../Actions/CallButtonRounded";
import EditLeadInfoButton from "../Actions/EditLeadInfoButton";
import ViewLeadInfoButton from "../Actions/ViewLeadInfoButton";

const CallbackActions = ({
  onOpen,
  onClose,
  callback,
  onAction,
  calling,
  index,
}: {
  onOpen?: () => void;
  onClose?: () => void;
  callback: GetCallbackDTO;
  onAction?: () => void;
  calling?: boolean;
  index?: number;
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const { id } = useAppSelector((state) => state.callback);
  const { upcomingCallbackLogs } = useAppSelector(
    (state) => state.callbackLogs
  );
  const dispatch = useAppDispatch();

  const removeCurrentCallbackFromRedux = () => {
    if (index !== undefined) {
      const updatedData = [...upcomingCallbackLogs];
      updatedData.splice(index, 1);

      dispatch(callbackLogsSlice.actions.updateUpcomingCallbacks(updatedData));
    }
  };

  const handleCall = (id: string | number) => {
    const onSuccess = (data: any) => {
      const response = data.data;

      const lead = response.callback.Lead;
      const leadData = response.leadData;
      const callCode = leadData.callCode;

      const callId = response.callId;
      const processId = response.processId;
      const leadId = response.callback.leadId;

      dispatch(leadSlice.actions.setLead(lead));
      dispatch(callSlice.actions.updateLeadDetails(lead));
      dispatch(callSlice.actions.updateCallType("CALLBACK"));

      dispatch(callSlice.actions.updateCallCode(callCode));
      dispatch(callSlice.actions.updateCallId(callId));
      dispatch(callSlice.actions.updateProcessId(processId));
      dispatch(leadSlice.actions.updateLead({ key: "id", value: leadId }));
      // removeCurrentCallbackFromRedux();
      setLoader(false);

      //Remove callback from redux if user called on it
      const dataUpcomingCallbacks = [...upcomingCallbackLogs];
      if (dataUpcomingCallbacks.findIndex((data) => data.callId === id)) {
        const index = dataUpcomingCallbacks.findIndex(
          (data) => data.callId === id
        );
        dataUpcomingCallbacks.splice(index, 1);

        dispatch(
          callbackLogsSlice.actions.updateUpcomingCallbacks(
            dataUpcomingCallbacks
          )
        );
      }

      dispatch(userSlice.actions.goToCall());
    };

    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };

    setLoader(true);
    CALLBACK_SERVICE.callCallback(onSuccess, onError, id);
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems={"center"}
      gap={2}>
      <ViewLeadInfoButton
        onOpen={onOpen}
        onClose={onClose}
        callback={callback}
      />
      {calling && callback.id === id ? (
        <CallButtonRounded />
      ) : (
        <CallButton
          onClick={() => handleCall(callback.id)}
          isCalling={loader}
        />
      )}
      <EditLeadInfoButton
        callback={callback}
        onAction={onAction}
        onOpen={onOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default CallbackActions;
