import { useEffect, useState } from "react";
import UsersLoggedInIcon from "../../assets/images/admin-dash/UsersLoggedInIcon";
import { DASHBOARD_SERVICE } from "../../services/DashboardService";
import { useAuth } from "../../shared/hooks/useAuth";
import { useSocket } from "../../shared/hooks/useSocket";
import DashboardTile from "../Custom/DashboardTile";

const UsersLoggedinTile = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const { socket } = useSocket();
  const { authToken } = useAuth();
  const [loggedInUserCount, setLoggedInUserCount] = useState<number | null>(
    null
  );

  function getLoggedInUsersAPI() {
    const onSuccess = (response: any) => {
      setLoader(false);
      setLoggedInUserCount(response.data.data.loggedInUsers);
    };
    const onError = (data: any) => {
      setLoader(false);
    };

    setLoader(true);
    DASHBOARD_SERVICE.getDashboardMetrices(onSuccess, onError);
  }
  function getLoggedInUsersSocket() {
    if (socket === null) return;
    socket.on("loggedInUsers", (value) => {
      setLoggedInUserCount(value.loggedInUsers);
    });
  }

  useEffect(() => {
    getLoggedInUsersAPI();
  }, [authToken]);

  useEffect(() => {
    getLoggedInUsersSocket();
  }, [socket?.id]);

  return (
    <DashboardTile
      loader={loader}
      title="Agents Logged In"
      value={loggedInUserCount ?? ""}
      icon={<UsersLoggedInIcon />}
    />
  );
};

export default UsersLoggedinTile;
